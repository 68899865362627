@import './assets/css/custom.css';
@import './assets/css/helpers.css';
@import './assets/css/legacy-template.css';
@font-face {
  font-family: chivoBold;
  src: url(assets/font/chivoBold/Chivo-Bold.ttf);
}
@font-face {
  font-family: chivo;
  src: url(assets/font/Chivo/Chivo-Regular.ttf);
}

/* ::selection {
  color: #fff;
  background: rgb(239 62 39);
} */
a.link-text-white {
  text-decoration: none;
}

.menu_overlay {
  position: fixed;
  width: calc(100% - 280px);
  height: 100%;
  right: 0;
  top: 0;
  z-index: 999;
  opacity: 0;
  cursor: e-resize;
  transition: all 0.3s;
  transform: translateX(calc(-100% - 280px));
}

.menu_open .menu_overlay {
  transform: translateX(0);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

input[autocomplete='off']::-webkit-contacts-auto-fill-button,
input[autocomplete='off']::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none;
  height: 0;
  width: 0;
  margin: 0;
  pointer-events: none;
}
h6.sub.bold {
  line-height: 1;
  vertical-align: top;
  margin-bottom: 0.5rem;
  color: #3c4b5c;
  font-weight: bold;
}
.checkout-button-bottom {
  position: absolute;
  bottom: 0;
}
.ql-snow .ql-tooltip {
  z-index: 9999 !important;
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border: 1px solid #e6e4e9 !important;
  border-right-width: 1px !important;
  z-index: 1;
}
.mini-btn {
  padding: 6px 9px;
}
.sub.bold.italic {
  font-weight: bold;
  font-style: italic;
  margin-left: 17%;
}
/* ...................................................FA icon.......................................... */
.delete-svg-icon {
  font-size: 16px !important;
}

svg.svg-inline--fa.fa-image.fa-w-16.fa-3x {
  width: 100% !important;
  height: 100%;
  color: #9e9e9e;
}
.popup-share-icon {
  color: #fff !important;
  font-size: 24px !important;
}

svg.svg-inline--fa.fa-cogs.fa-w-20.menu-icon.setting {
  width: 1rem !important;
}
svg.feather.feather-log-out {
  margin-left: 4px;
}
.svg-inline--fa.fa-w-16.far.fa-user-circle.fa-2x {
  width: 30px !important;
}
.svg-inline--fa.fa-w-16.far.fa-user-circle.login-home {
  width: 45px !important;
}

i.fas.fa-film {
  font-size: 13px !important;
}
i.fas.fa-envelope {
  font-size: 20px;
  cursor: pointer;
}
i.fas.fa-copy {
  font-size: 20px;
  cursor: pointer;
}
i.fas.fa-copy:hover {
  font-size: 24px;
  color: #f4f4f4;
  cursor: pointer;
}
i.fas.fa-envelope:hover {
  font-size: 24px;
  color: #f4f4f4;
  cursor: pointer;
}
svg.svg-inline--fa.fa-times-circle {
  cursor: pointer !important;
  color: red !important;
}
i.fa.fa-plus {
  font-size: 9px !important;
}
.fa-home {
  font-size: 18px;
}
svg.svg-inline--fa.fa-w-16.far.fa-user-circle.fa-2x {
  height: 32px;
}

i.fa.fa-facebook-official.mr-1 {
  font-size: 17px !important;
}
i.fas.fa-plus {
  line-height: 1rem !important;
  font-size: 1rem !important;
  margin-right: 3px;
}
i.fa.fa-minus {
  font-size: 10px !important;
}
i.fa.fa-plus.add-input {
  font-size: 13px !important;
  margin-right: 9px;
}
i.fas.fa-minus.remove-input {
  font-size: 12px !important;
  margin-right: 4px;
}

svg.svg-inline--fa.fa-w-16.far.fa-user-circle.fa-2x.login-home {
  color: #fff;
  font-size: 46px;
}
i.fa.fa-sort {
  cursor: pointer;
}
.fa.fa-heart {
  color: red;
}
.fa.fa-comment.comment-icon {
  color: #00cb8e;
}
i.fa.fa-user.follower {
  color: #ffc555;
}

i.fa.fa-trash-alt {
  cursor: pointer;
}
i.fa.fa-circle {
  position: absolute;
  top: 20px;
  left: -5px;
  font-size: 4px;
}
.fas.fa-cloud-upload-alt {
  font-size: 43px;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-27%, -96%);
  margin: 0;
}
i.fa.fa-angle-double-down {
  margin-left: 9px;
  font-size: 14px !important;
}
i.fa.fa-home.menu-icon {
  position: absolute;
  left: 3px;
}
.menu_icon {
  font-size: 15px;
}
i.fa.fa-receipt {
  font-size: 17px !important;
}
i.far.fa-user {
  font-size: 16px;
}

.fa-user-headset:hover {
  width: 15px;
  fill: red;
  height: 15px;
}
.svg-inline--fa.fa-w-20 {
  width: 1rem;
}
svg.svg-inline--fa.fa-trash-alt.fa-w-14 {
  cursor: pointer;
}
i.far.fa-image.x {
  font-size: 13px !important;
}
i.fa.fa-chevron-left {
  position: absolute;
  margin-top: 7px;
  text-align: center;
  color: #ef3e27 !important;
}
i.fa.fa-chevron-right {
  position: absolute;
  margin-top: 3px;
  text-align: center;
  color: #ef3e27 !important;
}
.fa-heart.mr-1 {
  cursor: pointer;
}
i.fas.fa-paper-plane {
  color: #9e9e9e;
  font-size: 20px;
  position: absolute;
  bottom: 63px;
  right: 11px;
}
i.fas.fa-upload {
  font-size: 14px !important;
  margin-right: 10px;
}
i.fab.fa-linkedin-in {
  font-size: 18px;
}
.popup-youtube i {
  position: absolute;
  font-size: 80px;
  color: #fff;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
i.far.fa-check-square {
  margin-left: 10px;
  margin-right: 15px;
}
svg.svg-inline--fa.fa-warn.fa-w-18 {
  height: 50px;

  color: #faad14 !important;
}

i.fas.fa-play-circle.newsfeed-video {
  font-size: 50px;
}

.fa-w-14 {
  width: 14px !important;
}
.fa-w-18 {
  width: 18px !important;
}
.fa-w-20 {
  width: 20px !important;
}
.fa-w-12 {
  width: 12px !important;
}
.fa-w-16 {
  width: 16px !important;
}
i.fas.fa-square.mr-2 {
  color: #242a34 !important;
  font-size: 5px !important;
  top: 1;
}
.cross-dropzone-icon.cm_red svg {
  width: 22px !important;
}
/* ................................................Nav side bar............................................ */
.sidebar ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.sidebar ul li {
  position: relative;
  padding: 12px;
  padding-left: 50px;
}

.sidebar {
  position: fixed;
  background: #231f20;
  height: 100vh;
  width: 270px;
  left: 0;
  top: 0;
  max-height: 100vh;
  overflow-y: auto;
  transition: all 0.3s;
  padding: 0;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
}
.ant-collapse-icon-position-right
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  right: 12% !important;
  left: auto;
  font-size: 10px;
  color: #9e9e9e;
}
.cm_close {
  width: 21px;
  right: 20px;
  cursor: pointer;
  position: absolute;
  color: #fff;
  font-size: 24px;
  top: 16px;
}

img.logo-white {
  height: calc(6rem - 1.5rem);
  margin-left: 27%;
  margin-top: 14px;
  margin-bottom: 24px;
}

@media (max-width: 1024px) {
  .navbar .navbar-menu-container .navbar-nav .nav-item .nav-link {
    color: inherit;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.25rem -0.875rem;
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 35px;

    text-align: center;
    border-radius: 100%;
    transition: all 0.2s;
  }
  .page-title {
    display: none !important;
  }
  .menu_open .sidebar {
    left: 0px !important;
  }
  .sidebar {
    left: -330px;
    z-index: 999;
    top: 0px;
    max-height: (100vh - 75px);
    transition: all 0.3s;
  }
  .second-nav {
    display: block;
    padding: 0 0 0 50px;
    height: 80px;
    z-index: 12;
    position: relative;
    width: 100%;
    background-color: #fff;
  }
  .active {
    right: 0;
  }
  .show.search {
    display: block;
    padding: 0 0 0 50px;
    height: 80px;
    z-index: 12;
    position: relative;
    width: 100%;
    background-color: #fff;
  }

  .mobile_trigger {
    position: absolute;
    right: 0;
    display: block;
    z-index: 99;
    cursor: pointer;
    color: inherit;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.25rem 0.125rem;
    width: 2.5rem;
    height: 2.5rem;
    text-align: center;
    border-radius: 50%;
    transition: all 0.2s;
  }

  .mobile_trigger:hover {
    color: #ef3e27;
  }

  .mobile_trigger span {
    color: inherit;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.25rem 0.125rem;
    width: 2.5rem;
    height: 2.5rem;
    text-align: center;
    border-radius: 50%;
    transition: all 0.2s;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 99;
    cursor: pointer;
  }
}

.nav-item.nav-profile {
  align-self: stretch !important;
  margin-right: 25px;
}
@media (min-width: 1331px) {
  .cm_close,
  .mobile_trigger {
    display: none !important;
  }
}
.navbar .navbar-menu-container .navbar-nav .nav-item .nav-link {
  color: inherit;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.25rem -0.875rem;
  width: 2.5rem;
  height: 2.5rem;
  text-align: center;
  border-radius: 100%;
  transition: all 0.2s;
}

.sidebar-menu a {
  color: #fff;
  display: inline-block;
  font-size: 0.7997rem;
  vertical-align: middle;
  text-transform: uppercase;
  font-weight: 700;
}
.sidebar-menu li:hover {
  color: #ef3e27;
}

.sidebar-menu a:hover,
.sidebar-menu a:active {
  color: #ef3e27;
  text-decoration: none;
}

@media only screen and (max-width: 1025px) {
  .second-nav1 {
    right: 0;
  }
}

/*--------------------------------------------home page OwlCarousel-------------------------------------------------------------- */
.event-owl .owl-prev,
.event-owl .owl-next {
  padding: 0.5rem 1rem !important;
  width: 40px;
  height: 40px;
  border: 1px solid #404040 !important;
  border-radius: 0;
  margin: 0;
  outline: none !important;
}
.event-owl.owl-theme .owl-nav [class*='owl-'] {
  color: #fff;
  font-size: 14px;
  margin: 0px !important;
  padding: 4px 7px;
  background: #d6d6d6;
  display: inline-block;
  cursor: pointer;
  border-radius: 0 !important ;
}

.event-owl.owl-theme .owl-nav [class*='owl-']:hover {
  background: red !important;
  color: #fff;
  text-decoration: none;
}

.autocomplete-dropdown-container {
  z-index: 1000;
  padding: 0px 2px 1px;
}

.bs-stepper-header {
  display: flex;
  margin-right: -5px;
  margin-left: -4px;
  align-items: center;
}

.step.col-lg-3.col-sm-6.mb-3.d-flex.justify-content-center.active
  .step-trigger {
  width: 100%;
  background: #000 !important ;
  color: #fff !important;
}

.bs-stepper .step-trigger:hover {
  width: 100%;
  color: #fff !important;
  background: red !important;
}
.bs-stepper .step-trigger:focus {
  color: #000 !important;
}

.bs-stepper .step-trigger {
  width: 100%;
  letter-spacing: 0.3px;
  font-size: 13px !important;
  border-radius: 0 !important;
  padding: 14px 0 !important;
  color: #000 !important;
  background: #e9edf3 !important;
}

button.btn.btn-primary.mr-2.previous {
  color: #212529;
  background-color: #d3dae6;
  border-color: #d3dae6;
}
button.btn.btn-primary.mr-2.previous:hover {
  color: #212529;
  background-color: #bbc5d8;
  border-color: #b2bfd4;
}

.ant-btn-primary {
  color: #fff;
  background: #ef3e27;
  border-color: #ef3e27;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-primary:hover {
  color: #fff;
  background: #000;
  border-color: #000;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.rdtPicker {
  padding: 12px 16px !important;
  color: rgba(0, 0, 0, 0.85) !important;
  margin-top: 0 !important;
  position: absolute;
  font-size: 0.875rem !important;
  box-shadow: 0px 0px 40px 0px rgb(79 61 101 / 15%) !important;
  border-radius: 0.25rem !important;
  background-color: #ffffff !important;
  min-width: 160px !important;
}
.rdtCounter .rdtCount {
  color: #3f3f3f;
  font-weight: 100;
  font-size: 14px !important;
}
.rdtCounterSeparator {
  line-height: 96px !important;
}
.rdtCounter .rdtBtn:hover {
  /* background: #eee; */

  border: none;
  border-radius: 3px !important;
}
.bs-stepper-content .string-check .string-check-label {
  min-height: 18px;
  margin-left: 1.375rem;
  display: inline-flex !important;
  margin-bottom: 0;
  font-size: 0.8125rem;
  line-height: 1.5;
}
.bs-stepper-content {
  color: #3f3f3f;
  background: #ffffff;
  display: block;
  margin: 0.5em;
  min-height: 24em;
  /* overflow: hidden; */
  position: relative;
  width: auto;
  padding: 30px !important;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px !important;
  border: 1px solid #e6e4e9;
}

.item {
  width: 448px;
  margin-right: 20px;
}
.navbar-container {
  transition: all 0.3s;
  min-height: calc(100vh - 3.75rem);
  padding: 0;
  position: fixed;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: -1 !important;
  display: flex;
  box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
  -ms-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
}
.ant-picker.form-control.home-datepicker.ant-picker-focused {
  border-right: 0 !important;
}
.cm_check {
  width: 50px;
  height: 50px;
  background: #ef3e27;
  border-radius: 100%;
  margin: 0 auto;
  margin-bottom: 30px;
  position: relative;
}
.cm_check:hover {
  background: #000;
}

.btn-primary:focus,
.wizard > .actions a:focus,
.btn-primary.focus,
.wizard > .actions a.focus {
  box-shadow: 0 !important;
}
.cm_check:before {
  content: '';
  width: 16px;
  height: 25px;
  border-right: 3px solid #000;
  border-bottom: 3px solid #000;
  position: absolute;
  top: 9px;
  transform: rotate(45deg);
  left: 16px;
}

.btn-primary.focus,
.btn-primary:focus {
  color: #fff;
  background-color: #000 !important;
  border-color: #000 !important;
  box-shadow: 0 !important;
}

.cm_check:hover::before {
  content: '';
  width: 16px;
  height: 25px;
  border-right: 3px solid #ef3e27;
  border-bottom: 3px solid #ef3e27;
  position: absolute;
  top: 9px;
  transform: rotate(45deg);
  left: 16px;
}

.eye_icon {
  position: absolute;
  width: 22px;
  top: 12px;
  right: 15px;
  cursor: pointer;
  z-index: 99;
}
.eye_icon svg {
  fill: #ced4da;
}

.form-group.inner-addon {
  position: relative;
}

.eye_icon.slesh:after {
  content: '';
  position: absolute;
  width: 26px;
  height: 3px;
  background: #ced4da;
  left: -2px;
  top: 8px;
  transform: rotate(-45deg);
  border-radius: 3px;
}

.center {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}

/* Simple CSS3 Fade-in Animation */
.underlineHover:after {
  display: block;
  left: 50%;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: #000;
  content: '';
  transition: width 0.2s;
}

.underlineHover:hover:after {
  width: 100%;
}

button.nav-link.dropdown-toggle.dropdown-toggle.btn.btn-primary,
button.nav-link.dropdown-toggle.dropdown-toggle.btn.btn-primary:focus {
  background: none;
  border: none;
}

.delete-content .cm_modal {
  padding: 10px 15px;
}
.delete-content {
  width: 30% !important;
  border-radius: 2px;
}

@media (max-width: 767px) {
  .delete-content {
    width: 90% !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .delete-content {
    width: 50% !important;
  }
  /* .event-card-img {
    height: 180px !important;
  } */
}

.main-panel {
  transition: all 0.25s ease, margin 0.25s ease;
  width: 100%;
  min-height: calc(100vh - 3.75rem);
  top: 3.75rem;
  right: 0;
  display: flex;
  flex-direction: column;
  padding-left: 270px;
}

.white-footer {
  background: #ffffff;
  color: #ababbb;
  padding: 1.5rem;
  font-size: calc(0.875rem - 0.05rem);
  font-weight: 400;
}

.error {
  filter: Shadow(Color=#666666, Direction=135, Strength=5) !important;
  color: red;
  text-align: left;
  margin-top: 10px !important;
  margin-left: 12px;
}

.event-form-home.card {
  bottom: -50px !important;
}

.page-title {
  text-transform: uppercase;
  font-weight: 500 !important;
  font-family: chivoBold;
  color: #3f3f3f !important;
  width: 270px !important;
  padding-left: 1.875rem !important;
}
.card .card-header .card-title {
  color: #3f3f3f;
  margin-bottom: 0;
  font-weight: 500 !important;
  font-family: chivoBold !important;
  text-transform: uppercase;
}
.ant-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50);
}

.modal-header {
  border: 0 !important;
}

form.home-form {
  padding: 0.7rem 0.5rem;
}

.modal .modal-dialog .modal-content .modal-header .close span {
  font-size: 28px;
  font-weight: 400;
  color: #333 !important;
}

button.btn.btn-brand.form {
  padding: 19px 25px;
  position: absolute;
  right: 0;
  background-color: #ef3e27;
  color: #fff;
  border-radius: 0px;
  bottom: 0;
}

.btn i,
.wizard > .actions a i,
.fc button i {
  font-size: 1.25rem !important;
}

label.m-0.text-capitalize.font-size-12 {
  color: #737373 !important;
  font-size: 12px !important;
}

p.text-white.font-size-16 {
  line-height: 1.5 !important;
  margin-bottom: 1rem;
}

h1.text-uppercase.text-white.font-weight-bold {
  font-size: 2.5rem;
  max-width: 45% !important;
  font-weight: bold !important;
  letter-spacing: 1px !important;
}

/*.......................................... loader ............................................*/

.loader_wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  z-index: 999999;
  transform: translate(-50%, -50%);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.5);
}

.lds-roller {
  display: inline-block;
  width: 64px;
  position: absolute;
  height: 64px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 32px 32px;
}

.lds-roller div:after {
  content: ' ';
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #fff;
  margin: -3px 0 0 -3px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 50px;
  left: 50px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 54px;
  left: 45px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 57px;
  left: 39px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 58px;
  left: 32px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 57px;
  left: 25px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 54px;
  left: 19px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 50px;
  left: 14px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 45px;
  left: 10px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader_wrapper.with_message {
 background:#fff;
}
.loader_wrapper.with_message h3 {
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: center;
  height: 100%;
  align-items: center;
  margin-top: -100px;
  padding:0px 20px;
 }
.loader_wrapper.with_message .lds-roller div:after {
  background: rgba(0,0,0,0.6);
}
/*,......................................event pages...........................................  */

.event .event-banner {
  position: relative;
  margin-top: 5rem;
  border-radius: 1px;
  height: 530px;
  z-index: 22;
  overflow: hidden;
  background: no-repeat center center;
  background-size: cover;
}

.event .event-banner a:hover {
  color: #fff;
}

.event-banner-mb .shadow,
.event .event-banner .shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  -moz-box-shadow: inset 0 -90px 100px #000000;
  -webkit-box-shadow: inset 0 -90px 100px #000000;
  box-shadow: inset 0 -90px 100px #000000 !important  ;
  /* border-radius: 20px; */
  top: 0;
  left: 0;
}

.event .event-banner .event-title {
  padding: 2rem;
  position: relative;
  z-index: 111;
}

.event .event-banner .event-price-container {
  height: 70px;
  position: relative;
  z-index: 111;
}

.event .event-banner .event-price-container a:hover {
  background-color: #e1250d;
  transition: all 0.3s;
}

.event .event-banner .event-price-container .event-price {
  background-color: #ebebeb;
  padding: 1.2rem;
}

.event-banner-mb {
  height: 450px;
  border-radius: 20px;
  z-index: 1;
  position: relative;
  -webkit-box-shadow: 0px 0px 43px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 43px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 43px -10px rgba(0, 0, 0, 0.75);
}

.event-banner-mb .event-title {
  margin: 2rem;
  position: relative;
  z-index: 111;
}

.event-banner-mb .event-price-container {
  height: 70px;
  position: relative;
  z-index: 111;
}

.event-banner-mb .event-price-container .event-price {
  background-color: #ebebeb;
  padding: 1.2rem;
}

.event .event-info {
  margin-left: 2rem;
}

.buy-tickets .btn:hover {
  background-color: #e1250d !important;
  transition: all 0.3s;
}

.feed .feed-body .user-img {
  height: 70px;
  width: 70px;
}

.feed .feed-footer .action-link.active {
  text-decoration: underline !important;
}

.feed .feed-footer .action-link {
  text-decoration: none;
  color: #404040;
}

.feed .feed-footer .action-link:hover {
  color: #ef3e27;
  transition: all 0.3s;
}

.feed-comment .user-img {
  height: 50px;
  width: 50px;
}

.feed-comment .comment-panel {
  background-color: #f4f4f4;
}
.event-location img {
  width: 100%;
}

.event-address {
  position: relative;
  top: -45px;
  box-shadow: 1px -1px 4px 1px rgb(230 219 219 / 59%);
}
.event-addresses {
  position: relative;
}
.box-shadow {
  box-shadow: 1px -1px 4px 1px rgb(230 219 219 / 59%);
}
.text-black {
  color: #000 !important;
}
.custom-item {
  padding: 0px 30px;
}
.custom-item > a {
  color: #404040 !important;
  position: relative;
  padding: 32px 0px !important;
  line-height: initial;
}
.custom-item > a:hover {
  background: #fff !important;
  text-decoration: none !important;
}
.custom-item > a:after {
  content: '';
  position: absolute;
  height: 4px;
  width: 0px;
  opacity: 0;
  transition: 0.3s ease;
  background: #ef3e27;
  left: 0;
  bottom: 0;
}
.custom-item > a:hover:after,
.custom-item > .active:after {
  width: 100%;
  opacity: 1;
}
.custom-item > .active {
  color: #404040 !important;
}
.event-address-container {
  padding: 0 230px;
  background: linear-gradient(180deg, #f4f4f4 67%, #ffffff 67%);
}

.manage-bg {
  background: linear-gradient(180deg, #f4f4f4 50%, #fff 50%) !important;
}

.promoter-img {
  width: 100px;
  height: 100px;
  flex-shrink: 0;
}

.related-events {
  margin-bottom: 4rem;
}

.related-events .item {
  height: 400px;
}
.related-events .item .event-body {
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  -moz-box-shadow: inset 0 -250px 700px #000000;
  -webkit-box-shadow: inset 0 -250px 700px #000000;
  box-shadow: inset 0 -250px 700px #000000;
}

.related-events .item .event-link {
  text-decoration: none;
  color: #fff;
}

.related-events .item .event-link:hover,
.related-events .item .event-body a:hover {
  color: #ef3e27;
  transition: all 0.3s;
}

.related-events .item .event-body p {
  word-wrap: break-word;
  width: 100%;
}

.related-events .item .event-body .action-link {
  color: white;
  text-decoration: none;
}

.event-img-gallery {
  display: flex;
  flex-wrap: wrap;
}

.event-img-gallery a {
  width: 33.33%;
  padding: 2px;
}

.event-img-gallery img {
  width: 100%;
}

.event-img-gallery img:hover {
  opacity: 1;
}
body.event-bdy {
  background-size: 100% 630px;
}

body.event-list-bdy {
  background-size: 100% 630px;
  background-color: #1c1c1c;
}

.overlay-bg {
  position: absolute;
  width: 100%;
  height: 630px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.base-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.content-wrapper {
  overflow: hidden;
  min-height: 100vh;
}

.navbar-mb-menu-container {
  z-index: 12;
  position: relative;
  width: 100%;
  height: 80px;
}

.navbar-mb-more {
  position: absolute;
  top: -1000px;
  width: 280px;
  z-index: 1;
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}

.navbar-mb-more.show {
  top: 80px;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  right: 0;
}

.banner-front {
  background-color: #000;
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.banner-front form {
  width: 500px;
}

.banner-front button {
  position: absolute;
  left: 0;
  padding: 0;
  bottom: 1rem;
}
.banner-front .form-control:focus {
  color: #808080;
}

.banner-front input {
  padding: 1rem 2rem;
  color: #808080;
}

.accordion .card-header {
  padding: 1.1rem 0;
}

.accordion .card-header .card-title {
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  color: #404040;
}

.accordion .card-header:after {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: '\f068';
  float: right;
  color: #ef3e27;
}
.accordion .card-header.collapsed:after {
  content: '\f067';
}

/* */
.success-content {
  margin: auto !important;
  min-height: calc(100vh - 400px);
  max-width: 1045px;
  width: 90%;
}
.success-content .content-title {
  font-weight: 700;
  font-size: 30px;
  text-transform: uppercase;
  padding: 40px 0;
  margin: 0;
  text-align: center;
  color: #404040;
}
.content-event {
  margin: 0 230px;
  min-height: calc(100vh - 0px - 320px);
}

.content .content-title {
  font-weight: 700;
  font-size: 30px;
  text-transform: uppercase;
  padding: 40px 0;
  margin: 0;
  text-align: center;
  color: #404040;
}

.card {
  border-radius: 0px;
}
.mb-cart-info {
  display: flex;
  align-items: center;
  position: relative;
  height: 80px;
  -webkit-box-shadow: 0px -6px 34px -14px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px -6px 34px -14px rgba(0, 0, 0, 0.75);
  box-shadow: 0px -6px 34px -14px rgba(0, 0, 0, 0.75);
}
.mb-cart-info .cart,
.mb-cart-info .checkout {
  padding: 1.25rem;
}
.mb-cart-info .cart .cart-toggle::after {
  content: none;
}
.mb-cart-info .cart .count {
  position: absolute;
  top: 12px;
  left: 35px;
}
.mb-cart-info .cart .cart-container {
  top: -375px !important;
  border-radius: 0;
  width: 300px;
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.tagline {
  color: #fff !important;
}

.sidebar-overlay {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 99;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.sidebar-overlay.active {
  display: block;
  opacity: 0.1;
}

.footer {
  background-color: #000;
  padding: 0 230px;
  bottom: 0;
  position: relative;
  width: 100%;
}

.footer .footer-body {
  padding: 40px 0;
  border-bottom: 2px solid #1c1c1c;
}
.footer .footer-body ul li a:hover,
.footer .footer-privacy a:hover {
  color: #ef3e27 !important;
  transition: all 0.3s;
}
.footer .footer-body ul li a {
  color: #aeaeae;
  text-decoration: none;
  line-height: 1.7;
}

.footer .footer-privacy span {
  color: #aeaeae;
}
.footer .footer-privacy i {
  font-size: 14px;
}
.footer-support {
  background-color: #f4f4f4;
  padding: 3rem;
}
/* @media screen and (max-width:1800px){
  .custom-item {
    padding: 0px 17px;
}
.navbar-home .navbar-nav > .nav-item a {
    padding: 32px 17px !important;
}
} */
.mobile-show {
  display: none !important;
}
@media screen and (max-width: 1800px) {
  .custom-item {
    padding: 0px 13px;
  }
  .navbar-home .navbar-nav > .nav-item a {
    padding: 32px 13px !important;
  }
}
@media screen and (max-width: 1600px) {
  .custom-item {
    padding: 0px 11px;
  }
  .navbar-home .navbar-nav > .nav-item a {
    padding: 32px 11px !important;
  }
}
@media screen and (max-width: 1440px) {
  .custom-item {
    padding: 0px 10px;
  }
  .navbar-home .navbar-nav > .nav-item a {
    padding: 32px 10px !important;
  }
  .navbar-home .navbar-nav > .nav-item input {
    width: 140px;
  }
}
@media screen and (max-width: 1380px) {
  .custom-item {
    padding: 0px 8px;
  }
}
@media screen and (max-width: 1280px) {
  .mobile-show {
    display: block !important;
  }
  .navbar-mb-container.active {
    right: 0;
  }
  .mobile-hide {
    display: none !important;
  }
}

@media only screen and (max-width: 1025px) {
  .navbar .navbar-nav .nav-item {
    margin-right: 20px;
  }
  .success-content,
  .content {
    margin: 0 100px;
  }
  .mb-cart-info {
    padding: 0 100px;
  }
  .mb-cart-info .cart {
    padding-left: 0;
  }
  .mb-cart-info .cart .count {
    position: absolute;
    top: 12px;
    left: 114px;
  }

  .footer {
    padding: 0 100px;
  }
}

label {
  color: #737373;
  font-size: 15px;
}

.event .event-banner {
  position: relative;
  margin-top: 5rem;
  height: 530px;
  z-index: 22;
  overflow: hidden;
  background-size: 100% !important;
}
a.link:hover {
  color: black;
}
h2.text-uppercase.text-white.font-weight-bold.mb-0 {
  font-size: 2rem;
}
h3.text-uppercase.border-bottom {
  color: #404040;
  font-family: chivofont;
  font-weight: 100 !important;
  font-size: 1.75rem;
}
p {
  line-height: 1.5 !important;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

a.btn.btn-outline-brand.border-brand {
  border-radius: 0px;
  color: #ef3e27;
  border-color: #dddddd;
  background-color: #fff;
}
text-brand {
  color: #ef3e27 !important;
}
.border-brand {
  border-color: #ef3e27 !important;
}
.btn-outline-brand:hover {
  color: #fff !important;
  background-color: #ef3e27 !important;
}

h4.font-weight-bold.text-capitalize {
  font-size: 1.5rem;
  color: #404040;
}
button.btn-lg.btn-brand {
  font-size: 1.25rem;
}

.border-brand {
  color: #ef3e27 !important;
  border-color: #ef3e27 !important;
}

.border-3 {
  border-width: 3px !important;
}

button.btn.btn-outline-brand.bg-transparent.border-brand.border-3.pl-4.pr-4 {
  font-size: 15px;
  font-weight: 100;
}
button.btn.btn-brand.pl-3.pr-3.mr-1 {
  font-size: 15px;
  font-weight: 100;
}
.item.evnt-details-owl {
  background: no-repeat;
  background-size: 100% 100% !important;
}
.item.evnt-details-owl {
  width: 100%;
}
h4.text-capitalize.font-weight-bold.event-link-text {
  color: #fff !important;
}
h4.text-capitalize.font-weight-bold.event-link-text:hover {
  color: #ef3e27 !important;
}
h3.text-uppercase.font-weight-bold.pt-4.pb-2.mb-3.text-center {
  color: #404040;
  font-family: chivofont;
  font-weight: 100 !important;
  font-size: 1.75rem;
}

.event-detail {
  background: linear-gradient(180deg, #f4f4f4 41.4%, #ffffff 41.4%);
  font-family: 'Chivo', sans-serif;
  letter-spacing: 0.3px;
  color: #404040;
  font-size: 0.875rem;
}

button.nav-link-home.navbar-toggler.navbar-toggler-right.align-self-center {
  color: #fff !important;
}

img.widget-8-img {
  width: 82px !important;
  height: 42px !important;
}

.content-wrapper.manage-event {
  min-height: 80vh !important;
}

ul.ant-dropdown-menu.ant-dropdown-menu-light.ant-dropdown-menu-root.ant-dropdown-menu-vertical {
  position: absolute;
  top: 0px;
  left: 0px;
  margin: 0px;
  right: auto;
  bottom: auto;
  transform: translate(-97px, -5px) !important;
  width: 200px !important;
}

.link-home {
  text-decoration: none !important;
}
.link-home:hover {
  color: #ef3e27 !important;
  text-decoration: none !important;
}

.menu-item:hover {
  color: #ef3e27 !important;
  text-decoration: none !important;
}
.ant-picker.form-control.home-datepicker {
  border-right: 0 !important;
}

.navbar-nav.navbar-nav-right {
  outline: none !important;
}
button.nav-link.dropdown-toggle.dropdown-toggle.btn.btn-primary {
  outline: none !important;
}
button.nav-link.dropdown-toggle.dropdown-toggle.btn.btn-primary,
button.nav-link.dropdown-toggle.dropdown-toggle.btn.btn-primary:focus {
  background: none !important;
  outline: none;
  box-shadow: none !important;
  border: none !important;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background: #ef3e27 !important;
  border: #ef3e27 !important;
  outline: #ef3e27 !important;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  border: 1px solid #ef3e27 !important;
  border-radius: 2px;
  content: '';
}

.ant-picker-cell-inner:hover {
  background: #ffdcda !important;
}

.ant-picker-header-view button:hover {
  color: #ef3e27 !important;
}
.ant-picker:hover,
.ant-picker-focused {
  border-color: #ef3e27;
  box-shadow: none !important;
  border-right-width: 1px !important;
}

.event-list .event-item .event-schedule .event-day {
  font-size: 70px;
  font-weight: 100;

  font-family: chivoLight !important ;
}
.event-list .event-item .event-schedule .event-month-time span {
  font-weight: 100;

  font-family: chivoLight !important ;
}
a.active {
  color: #ef3e27 !important;
}
.pagination li a {
  text-decoration: none;
  position: relative;
  display: block;
  border-radius: 2px !important;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  margin: 3px;
  line-height: 1.25;
  color: #404040;

  border: 0px solid #ef3e27;
}
.pagination {
  list-style: none;
}

.pagination li.disabled a {
  cursor: not-allowed;
  z-index: 1;
  color: rgba(0, 0, 0, 0.25);

  border-radius: 0;
}
.pagination li.active a {
  z-index: 1;
  color: #fff;
  background-color: #ef3e27;
  border-color: #ef3e27;
}
.pagination li:hover a {
  z-index: 1;
  color: #fff;
  background-color: #ef3e27;
  border-color: #ef3e27;
}

/* p.card-p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
} */

.select-limit span.ant-select-arrow {
  color: #000;
  font-weight: bold;
  font-family: chivoBold;
}
.ant-select-disabled.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  color: rgba(0, 0, 0, 0.25);
  background: none !important;
  cursor: default;
  color: #404040 !important;
}
.ant-select-disabled span.ant-select-arrow {
  display: none;
}
/* .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border: 1px solid #d9d9d9 !important;
  box-shadow: 0 !important;
} */
.ant-select {
  box-shadow: none !important;
}

li.page-change a {
  background-color: #ececec;
  border-radius: 2px !important;
}

li.page-change.active a {
  background-color: #ececec;
  border-radius: 2px !important;
}

li.page-change:hover a {
  background-color: #ef3e27;
}

.event-lable-ckeck {
  color: #3f3f3f;
}

span.tab-head {
  font-family: ChivoLight !important;
  font-size: 12px;
}
button.btn.btn-primary.filter {
  padding: 7px 12px !important;
  font-size: 12px !important;
  letter-spacing: 0.1px;
}
.card .card-header .card-title .card-title-secondary {
  color: #8d8a89;
  font-family: chivoLight !important;
  padding-left: 0.5rem;
  font-weight: 300;
}

.ant-tabs-nav {
  left: 20px;
  top: 10px;
  color: #000 !important;
}
.ant-tabs-tab.ant-tabs-tab-active {
  color: #ef3e27 !important;
  font-weight: 500;
}
.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  background: #ef3e27 !important;
}
.ant-tabs-ink-bar.ant-tabs-ink-bar-animated:hover {
  background: #ef3e27 !important;
}
.ant-tabs-tab:hover {
  color: #ef3e27;
}
.mini_loader {
  width: 40px;
  height: 40px;
  border: 2px #347bde solid;
  border-radius: 50%;
  animation: rotation 1s ease-in-out infinite;
  margin: 30px auto;
  position: absolute;
  left: 75px;
  bottom: -8px;
}
.mini_loader:after {
  width: 8px;
  height: 8px;
  background-color: rgb(0, 0, 0);
  border-radius: 100%;
  position: absolute;
  content: '';
}
.modal-body-popup div:first-child {
  color: #000 !important;
  background: #fff !important;
}
.modal-body-popup svg {
  fill: #000 !important;
}

.autocomplete-dropdown-container {
  z-index: 1000;
  padding: 0px 2px 1px;
}

.upload_doc {
  display: block;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  height: 314px;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 22px;
  color: #777;
  background-color: #fff;
  background-image: none;
  text-align: center;
  border: 2px solid #e5e5e5;
  -webkit-transition: border-color 0.15s linear;
  transition: border-color 0.15s linear;
}
img.ReactCrop__image {
  background-repeat: no-repeat !important;
  background-position: 50% 50% !important;
  background-size: contain !important;
  height: 314px;
}

.upload_doc p {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
}

.ant-select.form-control-select.ant-select-single.ant-select-show-arrow {
  width: 100%;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}
.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .form-control-select {
  height: 39px !important;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  height: 39px !important;
  padding: 0 11px;
}
.ant-select-selector {
  outline: none !important;
  box-shadow: none !important;
}
/* .ant-select-focused.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: #ef3e27 !important;
} */

/* .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #ef3e27 !important;
} */

/* .ant-select-focused.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: #ef3e27 !important;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 !important;
  box-shadow: 0 !important;
} */

.status_button.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  position: relative;
  background-color: #ef3e27 !important;
  border: 1px solid #ef3e27;
  border-radius: 0px;
  color: #fff !important;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  font-weight: bold !important;
  font-size: 12px !important;
  height: 33px !important;
  padding: 0 11px;
}
.status_button.ant-select-single.ant-select-show-arrow
  .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 18px;
  top: 0px !important;
}
.status_button span.ant-select-arrow {
  display: none;
}
.status_button.ant-select-single.ant-select-show-arrow
  .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 0px !important;
}
.ant-select-item:hover {
  color: #ef3e27 !important;
}
.ant-select-item.ant-select-item-option.ant-select-item-option-active.ant-select-item-option-selected {
  color: #ef3e27 !important;
  font-weight: 100 !important;
  background-color: none !important;
  background: none !important;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #ef3e27 !important;
  font-weight: 100 !important;
  background-color: #ececec !important;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 18px;
  top: 3px !important;
}
.pagination li.disabled a {
  cursor: not-allowed;
  z-index: 1;
  color: #404040;
  border-radius: 0;
}
.dropdown-arrow {
  font-size: 11px !important;
}

li.sub-menu {
  font-weight: 100;
  text-transform: none;
  padding: 0.9em 0.5em 0.5em 1em !important;
  position: relative;
  font-size: 0.8125rem;
}
.ant-picker-range .ant-picker-active-bar {
  bottom: -1px;
  height: 2px;
  margin-left: 11px;
  background: #8f8c8b;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  pointer-events: none;
}
.ant-picker.ant-picker-range {
  width: 100%;
  height: 39px;
}
.rang-picker .ant-picker-suffix {
  position: absolute;
  background: #f5f5f7;
  padding: 11px 10px;
  right: 0;
  border-left: 1px solid #e6e4e9 !important;
  padding-left: 12px;
}
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
  position: absolute;
  top: 50%;
  z-index: 0;
  height: 24px;
  border-top: 1px dashed #ef3e27;
  border-bottom: 1px dashed #ef3e27;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  content: '';
}

.invoice .invoice-header {
  display: flex;
  padding: 4rem;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #ef3e27;
}
.invoice .invoice-body table thead th {
  border-top: 0;
  font-weight: 500;
  font-size: 1rem;
  color: #ef3e27;
  border-color: #ef3e27;
  text-transform: uppercase;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: #ffdcda !important;
}

.menu_icon {
  position: absolute;
  width: 40px;
  height: 40px;
  padding: 7px;
  border-radius: 7px;
  line-height: 25px;
  left: 29px;
  top: 0;
}
.sidebar ul li {
  position: relative;
  padding: 12px;
  padding-left: 63px;
}

li.sub-menu {
  padding-left: 10px !important;
}

button.btn.btn-light {
  margin-right: 4px;
}

.login_wrapper {
  height: 100vh;
  min-height: 630px;
}
.bg_white {
  background: #fff;
  border-radius: 20px;
  padding: 50px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  min-height: 500px;
}

@media (max-width: 1023px) {
  .bg_white {
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
    padding: 15px 15px;
    min-height: 400px;
  }
}

.page_content {
  margin: 0 auto;
  max-width: 511px;
}

h4.cm_heading {
  font-size: 5rem;
}

.sidebar ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.cm_col {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: 250px;
}

.ant-select.cm-select-box.ant-select-single.ant-select-show-arrow.ant-select-open {
  width: 100%;
}
.ant-select.cm-select-box.ant-select-single.ant-select-show-arrow {
  width: 100%;
}
.ant-select.cm-select-box-2.ant-select-single.ant-select-show-arrow.ant-select-open {
  width: 100%;
}
.ant-select.cm-select-box-2.ant-select-single.ant-select-show-arrow {
  width: 100%;
}

p.event-content.dashboar {
  word-wrap: break-word;
}

h5.text-white.font-weight-bold.mb-3 {
  word-wrap: break-word;
}

span.search {
  position: absolute;
  right: 215px;
  top: 29px;
}
input.form-control.search-box {
  height: 35px;
  max-width: 202px;
  float: right;
  margin-bottom: 15px;
}

.ant-time-picker-input::placeholder,
.ant-calendar-range-picker-input::placeholder,
.ant-time-picker-input::placeholder {
  color: #000000ad;
  opacity: 1; /* Firefox */
}
.ant-time-picker-input:-ms-input-placeholder,
.ant-time-picker-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000000ad;
}
.selectHeight {
  height: auto !important;
}
.ant-time-picker-input::-ms-input-placeholder,
.ant-time-picker-input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #000000ad;
}

.ant-calendar-input::placeholder,
.ant-calendar-picker-input::placeholder {
  color: #000000ad;
  opacity: 1; /* Firefox */
}
.ant-calendar-picker-input:-ms-input-placeholder,
.ant-calendar-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000000ad;
}

.ant-calendar-picker-input::-ms-input-placeholder,
.ant-calendar-input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #000000ad;
}

.ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
  font-size: 17px;

  color: rgba(0, 0, 0, 0.59) !important;
}
.ant-select-selection__placeholder {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.68) !important;
}

.ant-picker-input::placeholder,
.ant-picker-input > input::placeholder {
  color: #8f8c8b !important;
  font-size: 13px;
  opacity: 1; /* Firefox */
}
.home-datepicker .ant-picker-input::placeholder,
.home-datepicker .ant-picker-input > input::placeholder {
  color: #8f8c8b !important;
  font-size: 15px !important;
  opacity: 1; /* Firefox */
}
.select-place::placeholder {
  color: #8f8c8b !important;
  font-size: 15px !important;
}
.widget-status-desc {
  width: 814px;
  display: flex;
  align-items: center;
}

span.cancel-btn {
  padding: 0.53125rem 0.8125rem;
}

span.btn.btn-back.btn-outline-brand.border-brand.remove-btn {
  position: absolute;
  top: 0;
  right: 0;
  color: #737373 !important;
}
span.btn.btn-back.btn-outline-brand.border-brand.remove-btn:hover {
  color: #212529 !important;
  background-color: #e2e7ec !important;
  border-color: #737373 !important;
}
.ReactCrop {
  position: relative;
  display: inline-block;
  cursor: crosshair;
  overflow: hidden;
  top: -5px !important;
  max-width: 100%;
}

.mange-evnt {
  font-weight: 200 !important;
  font-family: chivoBold !important;
}

h3.text-white.text-uppercase.font-weight-bold.pb-3.event-list-title {
  font-size: 1.75rem !important;
}

.event-body .event-list .event-owl.owl-carousel .event-card .event-date {
  position: absolute;
  font-size: 1rem !important;
  font-family: chivoBold !important;
  background-color: white;
  font-weight: 500 !important;
  width: 70px;
  height: 70px;
  color: #000;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  top: 0;
  left: 0;
}

.related-events .item .event-date {
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ef3e27;
  color: white;
  width: 80px;
  position: absolute;
  padding: 0.4rem 1rem;
  top: 0;
  left: 0;
  font-size: 20px;
  text-transform: uppercase;
}

.table-bordered {
  border-bottom: 2px solid #e6e4e9 !important;
}
.card-news {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.table tr th {
  color: #3f3f3f;
  border-color: #e6e4e9;
}

.menu-link {
  font-family: chivoBold !important;
  color: #fff !important;
  text-decoration: none !important;
}

h5.font-weight-bold {
  color: #404040;
  font-size: 1.22rem;
}

.event-step {
  padding-left: 51px !important;
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.select-limit.ant-select-single.ant-select-show-arrow
  .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 18px;
  top: -2px !important;
}

span.tab-head {
  font-family: ChivoLight !important;
  font-weight: 600 !important;
  font-size: 12px;
}

.home-datepicker .ant-picker-suffix {
  display: none !important;
}

h3.widget-20-figure {
  font-family: chivoBold !important;
}

.ant-select-item-option-content {
  color: #8d8a89 !important;
  font-size: 0.8125rem;
  font-weight: 400;
}

.filter-icon {
  margin-right: 5px !important;
}

input.dropify.p-0 {
  display: block;
  border: 1px solid #e6e4e9;
}

.imagePreview:hover .overlay {
  opacity: 1;
}
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  background: rgba(0, 0, 0, 0.705);
}

.text {
  color: white;
  font-size: 13px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
.file-name {
  font-family: chivoBold !important;
  font-size: 15px !important;
}
.line::before {
  content: '';
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  background: #fff;
  width: 30px;
  height: 2px;
}
.upload_doc.imagePreview {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  /* max-width: 800px; */
}

.upload_doc {
  outline: none !important;
  box-shadow: none !important;
}

button.dropify-clear {
  z-index: 9999 !important;
}

.dropify-wrapper:hover {
  background-size: 30px 30px;
  background-image: -webkit-linear-gradient(
    135deg,
    #f6f6f6 25%,
    transparent 25%,
    transparent 50%,
    #f6f6f6 50%,
    #f6f6f6 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    -45deg,
    #f6f6f6 25%,
    transparent 25%,
    transparent 50%,
    #f6f6f6 50%,
    #f6f6f6 75%,
    transparent 75%,
    transparent
  ) !important;
  -webkit-animation: stripes 2s linear infinite;
  animation: stripes 2s linear infinite;
}
@-webkit-keyframes stripes {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 60px 30px;
  }
}
@keyframes stripes {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 60px 30px;
  }
}

.bootstrap-timepicker-widget table td span {
  border: solid #3f3f3f;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 4px;
  width: 7px;
  cursor: pointer;
}
.bootstrap-timepicker-widget table td span.menu-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.bootstrap-timepicker-widget table td span.menu-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.bootstrap-timepicker-widget table td input {
  width: 25px;
  margin: 0;
  text-align: center;
}

.bootstrap-timepicker-widget table td input {
  border: none;
  color: #3f3f3f;
  font-weight: 400;
  background-color: #ffffff;
}

.btn-up-down:hover {
  background-color: #ececec;
  border: none;
  border-radius: 4px !important;
}

.ant-popover-inner {
  margin-top: 0;
  font-size: 0.875rem;
  box-shadow: 0px 0px 40px 0px rgba(79, 61, 101, 0.15);
  border-radius: 0.25rem;
  background-color: #ffffff;
}

.bootstrap-timepicker-widget table td:not(.separator) {
  min-width: 30px;
}
.bootstrap-timepicker-widget table td input {
  width: 38px;
  margin: 0;
  height: 40px;
  text-align: center;
}

.bootstrap-timepicker-widget table td {
  text-align: center;
  height: 30px;
  margin: 0;
  padding: 2px;
}
.event-date {
  text-transform: uppercase;
}
.placholder {
  color: #8f8c8b !important;
}
.ant-select.select-limit.mr-1.ml-1.ant-select-single.ant-select-show-arrow {
  position: relative;
}
.select-limit.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  top: 4px;
  font-family: chivoBold !important;
  columns: #000;
  height: 25px !important;
  padding: 0 11px;
}
.select-limit span.ant-select-arrow {
  margin-top: -2px !important;
  color: #000;
  font-weight: bold;
  font-family: chivoBold;
}
.white {
  color: #fff !important;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
  object-fit: cover;
  height: 160px;
}
.owl-carousel .owl-stage,
.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: auto !important;
  touch-action: auto !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ef3e27 !important;
  font-weight: 500;
}
.autocomplete-dropdown-container {
  line-height: 1.5rem !important;
}
input.form-control {
  position: relative !important;
}

button.btn.btn-sm.btn-dark.dark-black {
  position: absolute;
  top: 33px;
}

input.form-control.price-input {
  position: relative !important;
  padding: 0.53125rem 1.5rem !important;
}
/* ...................svg icons..................................... */

.sidebar ul li a svg path {
  stroke: #fff;
}

.sidebar ul li a:hover svg circle,
.sidebar ul li a:hover svg path {
  stroke: #ef3e27;
}

.sidebar ul li a.active svg circle,
.sidebar ul li a.active svg path {
  stroke: #ef3e27;
}

svg.menu-icon {
  display: inline-block;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 0.75rem;
  width: 1rem !important;
  height: 1rem;
}

.svg-filter {
  margin-left: 10px;
  margin-right: 15px;
  width: 10px;
}
.svg-active {
  margin-left: 10px;
  margin-right: 15px;
  width: 13px;
}
td.btn-up-down {
  cursor: pointer;
}

.cropper-drag-box.cropper-crop.cropper-modal {
  top: -6px;
  height: 314px !important;
}

.modal-backdrop.show {
  opacity: 0.75 !important;
}
.string-check input {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 5px;
  margin-top: 5px;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}

.cropper-bg {
  background-image: none !important;
}

button.cm_hide_upload {
  position: relative;
}
button.cm_hide_upload input {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.newsfeed img.ReactCrop__image {
  background-repeat: no-repeat !important;
  background-position: 50% 50% !important;
  background-size: contain !important;
  height: 200px;
}

h5.card-title {
  text-transform: uppercase;
  font-weight: bold;
}

.autocomplete-dropdown-container {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  width: 100%;
  top: -9999px;
  left: -9999px;
  z-index: 9999;
  overflow: hidden;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 0px 0px 0px rgba(0, 0, 0, 0.05);
}

.suggestion-item {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 12px !important;
  color: #8d8a89 !important;
  font-size: 0.8125rem;
  font-weight: 400;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}
.suggestion-item--active:hover {
  color: #ef3e27 !important;
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 12px !important;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  background-color: #ced4da;
  cursor: pointer;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}

.ant-select-item-option-content:hover {
  color: #ef3e27 !important;
  font-size: 0.8125rem;
  font-weight: 400;
}

.autocomplete-dropdown-container :hover {
  background-color: #f5f5f5 !important;
}
.text-primary,
.task-list-wrapper .completed .remove {
  color: #ef3e27 !important;
}

.filled-success {
  fill: #00cb8e;
  font-size: 20px;
}
.event-list .event-item .event-schedule .event-icon {
  stroke-width: 1;
  width: 18px;
  font-size: 18px;
  height: 18px;
  margin: 0.6875rem 1rem 0 0;
}

.cm_col2 {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: 306px !important;
}
.ql-editor {
  box-sizing: border-box;
  line-height: 1.42;
  height: 100%;
  outline: 0;
  overflow-y: auto;
  padding: 12px 15px;
  tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.ql-editor.ql-blank::before {
  color: #0000006e !important;
  content: attr(data-placeholder);
  font-style: italic;
  left: 15px;
  font-size: 14px;
  pointer-events: none;
  position: absolute;
  right: 15px;
}

.ql-container {
  color: #3f3f3f;
}

.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow.ql-toolbar button:focus,
.ql-snow.ql-toolbar button:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow.ql-toolbar button:focus,
.ql-snow.ql-toolbar button:hover {
  color: #ef3e27 !important;
}

label.checkbox-checkoutFields {
  color: #3f3f3f;
  font-size: 13px;
  display: inline-block;
  margin-bottom: 0.5rem;
  margin-left: 0.2rem;
}
.event-location.mt-5 {
  height: 600px !important;
}
.gm-style .gm-style-cc span,
.gm-style .gm-style-cc a,
.gm-style .gm-style-mtc div {
  font-size: 10px;
  box-sizing: border-box;
}

img.event-banner-img {
  width: 100%;
  /* height: 100%; */
}
h2.text-uppercase.font-weight-bold.mb-0 {
  font-size: 2rem;
}
/* .event-card-img {
  height: 200px;
} */

.break {
  word-wrap: break-word;
}
.prefix {
  font-size: 15px;
  left: 21px;
  top: 31px;
  position: absolute;
  z-index: 99;
}

[id='heart'] {
  position: absolute;
  left: -100vw;
}

[for='heart'] {
  color: #aab8c2;
  cursor: pointer;
  font-size: 1em;
  align-self: center;
  transition: color 0.2s ease-in-out;
}

[for='heart']:hover {
  color: grey;
}

[for='heart']::selection {
  color: none;
  background: transparent;
}

[for='heart']::moz-selection {
  color: none;
  background: transparent;
}

[id='heart']:checked + label {
  color: #e2264d;
  will-change: font-size;
  animation: heart 1s cubic-bezier(0.17, 0.89, 0.32, 1.49);
}

@keyframes heart {
  0%,
  17.5% {
    font-size: 0;
  }
}
span.action-link.pl-3.pr-3 {
  cursor: pointer;
}

span.post-btn.mt-2 {
  color: #ef3e27 !important;
  cursor: pointer;
}
span.cancel-btn.mt-2 {
  color: #9e9e9e;
  cursor: pointer;
}
span.cancel-btn.mt-2:hover {
  color: #404040 !important;
}
button.btn.btn-link:hover {
  color: #404040 !important;
  cursor: pointer;
}

button.btn.btn-link {
  font-weight: 100 !important;
  color: #ef3e27 !important;
  text-decoration: none;
}

a.btn.btn-block.btn-lg.btn-brand.pt-3.pb-3.pl-5.pr-5.font-weight-bold {
  background: #ef3e27 !important;
  font-size: 20px;
  border: none;
  text-decoration: none;
}

a.btn.btn-block.btn-lg.btn-brand.pt-3.pb-3.pl-5.pr-5.font-weight-bold:hover {
  background-color: #e1250d !important;
  transition: all 0.3s;
  font-size: 20px;
  color: #fff;
  border: none;
  text-decoration: none;
  border: red;
}

a.btn.btn-block.btn-brand.h-100.font-weight-bold.font-size-20:hover {
  background-color: #e1250d !important;
  transition: all 0.3s;
  color: #fff;
}

.dropdown-menu.cart-container.show {
  position: absolute;
  top: 0;
  transform: translate3d(0px, -26px, 0px);
  left: 0px;
  will-change: transform;
}

span#dropdownMenuButton {
  cursor: pointer;
}
span.footer-li {
  color: #aeaeae;
  text-decoration: none;
  line-height: 1.7;
}

span.footer-li:hover {
  color: #ef3e27 !important;
  transition: all 0.3s;
}

.upload_doc.imagePreview {
  background-repeat: no-repeat !important;
  background-position: 50% 50% !important;
  background-size: contain !important;
}
.dropify-wrapper:hover {
  background-image: linear-gradient(
    -45deg,
    #f6f6f6 25%,
    transparent 25%,
    transparent 50%,
    #f6f6f6 50%,
    #f6f6f6 75%,
    transparent 75%,
    transparent
  ) !important;
  animation: stripes 2s linear infinite;
}

.upload_img {
  background-repeat: no-repeat !important;
  background-position: 50% 50% !important;
  background-size: contain !important;
  border: 1px solid #e6e4e9;
  height: 200px;
  text-align: center;
  border-radius: 5px;
  position: relative;
  outline: none;
  cursor: pointer;
}

.upload_img p {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  word-wrap: break-word;
}

.nesweed-crop img.ReactCrop__image {
  background-repeat: no-repeat !important;
  background-position: 50% 50% !important;
  background-size: contain !important;
  height: 200px !important;
  border-radius: 5px !important;
}
.reply-list {
  list-style: none;
}

ul.reply-list.close {
  display: none;
}

span.text-brand.mr-3.font-size-11 {
  cursor: pointer;
}
span.text-brand.mr-2.font-size-11 {
  cursor: pointer;
}

.btn.btn-block.btn-brand:not(:disabled):not(.disabled) {
  cursor: pointer;
  color: #fff;
}
input.form-control.create-account {
  padding: 12px;
  border: 1px solid #e6e4e9 !important;
  position: relative;
}
.eye_icon1 {
  position: absolute;
  width: 22px;
  top: 31px;
  right: 15px;
  z-index: 99;
  cursor: pointer;
}
.eye_icon1 svg {
  fill: #ced4da;
}

.eye_icon1.slesh:after {
  content: '';
  position: absolute;
  width: 26px;
  height: 3px;
  background: #ced4da;
  left: -2px;
  top: 8px;
  transform: rotate(-45deg);
  border-radius: 3px;
}
.word-break {
  word-wrap: break-word;
}

.btn-flash-primary:not(.btn-flash-light) {
  color: #8d8a89;
}

.sc-bdVaJa.ixTfPP {
  width: 100%;
  border: none !important;
}

img.QRCodeImage {
  width: 150px;
}

.columns {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.uplodeImgContainer {
  position: relative;
  display: flex;
}

div#player {
  height: 100% !important;
}

.vp-center .player {
  height: 100% !important;
}

.uplodeImgContainer-item {
  width: 170px;
  position: relative;
  height: 135px;
  border-radius: 8px !important;
  border: 2px solid #e6e4e9 !important;
  margin-right: 6px;
  display: inline-block !important;
}

.uplodeImgContainer p {
  line-height: 1.5 !important;
  width: 100px;
  color: #ef3e27 !important;
  margin-top: 52px;
  font-weight: bolder;
  font-size: 0.875rem;
  cursor: pointer;
}

div#addeventatc1 {
  z-index: 0 !important;
}
div#addeventatc2 {
  z-index: 0 !important;
}
.uplodeImgContainer p:hover {
  color: #242a34 !important;
}

img.uplodeImg {
  border-radius: 8px;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.overlayImage {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5); /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  transition: 0.5s ease;
  opacity: 0;
  color: white;
  font-size: 20px;
  padding: 20px;
  text-align: center;
}
.uplodeImgContainer-item:hover .overlayImage {
  opacity: 1;
}
.scroll-item:hover .overlayImage {
  opacity: 1;
}
.scroll-item .overlayImage {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgb(0 0 0 / 62%);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 24px;
  text-align: center;
}

.scroll {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}
li.dropdown-item {
  display: block;
  height: 31px !important;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #8d8a89;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-menu .dropdown-item .dropdown-title {
  color: #ef3e27;
  font-weight: 500;
  line-height: 1.8 !important;
}
.btn-flash-border-primary:hover:not(.btn-flash-border-light) {
  color: #ef3e27;
}
.btn-flash-border-primary.disabled,
.btn-flash-border-primary:disabled {
  color: #8d8a89 !important;
  border: 1px solid #8d8a89 !important;
}

ul.dropdown-menu.show {
  max-height: 300px;
  overflow-y: auto;
}
img.rounded {
  max-width: 173px !important;
  min-height: 173px !important;
}

.sortingASC::before {
  content: '\2191';
}
.sortingASC,
.sortingDESC {
  cursor: pointer;
}
.sortingASC::after {
  content: '\2193';
  opacity: 0.3;
}
.sortingDESC::before {
  content: '\2191';
  opacity: 0.3;
}
.sortingDESC::after {
  content: '\2193';
}

.dropdow:hover {
  background-color: #ececec;
  color: #070a1a;
  border: none;
}

.widget-13 .widget-13-body .widget-13-left-section img {
  width: 130px;
  height: 130px;
}
.dropdown-show button.dropdown-toggle.btn.btn-primary {
  background-color: #ffffff !important;
  border: none;
  color: #a09fa7;
  box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
}

.dropdown-show .dropdown-toggle::after {
  display: none;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-add .dropdown-toggle::after {
  display: none;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.title.event-title-name {
  text-transform: uppercase;
}
svg.feather.feather-calendar {
  color: #8d8a89;
}
button.btn.btn-block.btn-brand.h-100.font-weight-bold.font-size-20.disable-checkout {
  color: #fff;
}
.table.table-striped.table-bordered tr {
  height: 55px;
}

img.react-images__view-image.react-images__view-image--isModal {
  display: flex;
  align-items: center;
  transform: 0 !important;
  width: 800%;
}
main.react-images__pager {
  flex: 1 1 auto;
  position: inherit !important;
}

img.react-images__view-image.react-images__view-image--isModal {
  width: 100%;
  height: 100%;
}
.react-images__view.react-images__view--isModal.css-4o9ng5.css-1ycyyax {
  background: #f7f7f7;
}

.css-9s8aw7 {
  display: inline-block;
  fill: currentColor;
  height: 22px !important;
  stroke: currentColor;
  stroke-width: 0;
  width: 22px !important;
}

.css-rg177k *:focus {
  outline: none !important;
}

button.css-xfk84m.css-1ycyyax,
button.css-1h82jk3.css-1ycyyax {
  outline: none !important;
}

button.react-images__header_button.react-images__header_button--close.css-umpcke.css-1ycyyax {
  outline: none !important;
}

span.action-link {
  cursor: pointer;
}
.dropdown-menu .dropdown-item {
  cursor: pointer;
  font-size: 0.8125rem;
  font-weight: 400;
  padding: 0.25rem 1.5rem;
}

.dropdown-menu .dropdown-item {
  font-size: 0.8125rem;
  font-weight: 400;
  padding: 0.25rem 0.5rem !important;
}
input#checkbox {
  cursor: pointer;
}
span.remove-icon {
  margin-top: 40px !important;
  position: absolute;
  font-size: 18px;
}
.coupon-popup .modal-dialog.modal-lg {
  max-width: 825px !important;
  width: auto;
  border-radius: none;
  background: #fff;
}

span.cm_col_tickets {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: 400px;
}
.cm_col_show::after {
  content: '  ,  ';
}

.cm_col_show:last-child::after {
  content: '';
}

.popup-youtube {
  position: relative !important;
}

.modal-dialog.modal-md.modal-dialog-centered {
  max-width: 840px !important;
}

.modal-content .modal-dialog-centered {
  outline: none !important;
  background: transparent;
  border-radius: 0;
  border: none;
}

.modal .modal-dialog-centered .modal-content {
  background-color: transparent;
  border: none !important;
}

.modal .modal-dialog-centered .modal-content .modal-header .close span {
  font-size: 28px;
  font-weight: 400;
  color: #fff !important;
}
iframe {
  border: none;
}
.react-images__dialog.css-18xmup8.css-1ycyyax {
  width: 100%;
  box-sizing: border-box;
  height: 640px;
  width: 570px;
}

.event-price-container {
  height: 76px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fade-in {
  animation: fadeIn 0.5s ease-in;
}

.select-limit.ant-select-single.ant-select-show-arrow
  .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  color: #8f8c8b !important;
  font-size: 13px;
  padding-top: 5px;
  opacity: 1;
}

.placeholder {
  color: #8f8c8b !important;
  font-size: 13px;
  padding-top: 4px;
  opacity: 1;
}

.ql-container {
  box-sizing: border-box;
  font-family: ChivoLight !important;
  font-size: 13px;
  height: 100%;
  margin: 0px;
  position: relative;
}

.quill > .ql-container > .ql-editor.ql-blank::before {
  /* font-family: ChivoLight !important; */
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.btn-sm.dropdown button.dropdown-toggle.btn.btn-primary {
  padding: 0.40625rem 0.8125rem;
  font-size: 0.71875rem;
  outline-color: none !important;
  font-size: 0.71875rem;
  cursor: pointer;
  line-height: 1.5;
  border-radius: 0;
}

.btn-primary:not(:disabled):not(.disabled):active,
.wizard > .actions a:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.wizard > .actions a:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle,
.wizard > .actions .show > a.dropdown-toggle {
  color: #ffffff;
  background-color: #14181d;
  border-color: #14181d !important;
}
img.widget-8-img {
  border: 1px solid #8f8c8b;
}

ul.ant-dropdown-menu.ant-dropdown-menu-light.ant-dropdown-menu-root.ant-dropdown-menu-vertical {
  margin-top: 0;
  font-size: 0.875rem !important;
  box-shadow: 0px 0px 40px 0px rgba(79, 61, 101, 0.15) !important;
  border-radius: 0.25rem !important;
  background-color: #ffffff !important;
}

.ant-dropdown.ant-dropdown-placement-bottomLeft {
  margin-top: 0;
  font-size: 0.875rem !important;
  box-shadow: 0px 0px 40px 0px rgba(79, 61, 101, 0.15) !important;
  border-radius: 0.25rem !important;
  background-color: #ffffff !important;
}
li.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child {
  color: #8d8a89;
  display: block;
  padding: 5px 30px;
  font-size: 13px !important;
}
li.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child:hover {
  color: #ef3e27 !important;
  display: block;
}

.special-label {
  display: none !important;
}

.event-owl.owl-carousel .event-card {
  background-color: #262626;
  text-align: left !important;
}

.event-owl.owl-carousel .event-card .event-date {
  position: absolute;
  font-size: 1rem !important;
  font-family: chivoBold !important;
  background-color: #fff;
  font-weight: 500 !important;
  width: 70px;
  height: 70px;
  color: #000;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  top: 0;
  left: 0;
}

a.link-home {
  color: #8d8a89;
}

.select-Quantity .ant-select-selector {
  background-color: #ef3e27 !important;
}

.select-Quantity span.ant-select-arrow {
  user-select: none;
  position: absolute;
  left: 7px;
  font-weight: 900 !important;
  width: 10px;
  color: #fff;
}

.ant-select-dropdown.ant-select-dropdown-placement-bottomLeft
  ::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.ant-select-dropdown.ant-select-dropdown-placement-bottomLeft
  ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.ant-select-dropdown.ant-select-dropdown-placement-bottomLeft
  ::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}
.content {
  transition: max-height 0.3s ease-out;
}

.ant-collapse-header {
  color: #fff !important;
  font-size: 0.8125rem !important;
  vertical-align: middle !important;
  text-transform: uppercase !important;
  font-weight: 700 !important;
}

.ant-collapse-ghost {
  background-color: transparent;
  border: 0;
  padding-left: 20px;
}
.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content {
  background-color: transparent;
  padding-left: 35px;
  border-top: 0;
}
.ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box {
  padding-top: 0px !important;
  padding-bottom: 12px;
}

.ant-collapse-icon-position-right
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  right: 12% !important;
  left: auto;
}
span.ant-input-password.ant-input-password-large.ant-input-affix-wrapper.ant-input-affix-wrapper-lg {
  outline: none;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #ef3e27;
  border-right-width: 1px !important;
  outline: 0 !important;
  -webkit-box-shadow: 0 !important ;
  box-shadow: none !important;
}

.ant-input-affix-wrapper:hover {
  border-color: #ef3e27;
  border-right-width: 1px !important;
}

.event .event-banner {
  position: relative;
  margin-top: 2rem !important;
  height: 530px;
  z-index: 22;
  overflow: hidden;
  background-size: 100% !important;
}

.event-title {
  opacity: 1;
  z-index: 999;
  color: #fff;
  text-align: center;
  padding-top: 25px;
  padding-bottom: 20px;
  position: relative;
}
.event-title h2.text-uppercase.font-weight-bold.mb-0 {
  font-size: 2rem;
  margin-top: 16px !important;
  color: #fff;
}

.event-title {
  opacity: 1;
  z-index: 999;
  color: #000;
  text-align: center;
  margin-top: 43px;
  position: relative;
}

.widget-8 .widget-8-desc {
  width: 438px;
  display: flex;
  align-items: center;
}

.promoCode label {
  display: block;
  width: 100%;
  font-style: italic;
  font-size: 1.15em;
  margin-bottom: 0.5em;
  letter-spacing: -0.025em;
}
.promoCode input {
  width: 70%;
  float: left;
  border-radius: 0 !important;
}

.promoCode a.btnApply, button.btnApply {
  float: left;
  width: 30%;
  padding: 0.85em 3px !important;
  border-radius: 0 1.5em 1.5em 0;
  text-align: center;
  color: #fff;
  border: 1px solid #242a34 !important;
  background: #242a34 !important;
}
.row.promoCode.mt-5 {
  margin-top: 5rem !important;
}

.promoCode a.btnApply:hover, button.btnApply:hover {
  border: 1px solid #ef3e27 !important;
  background: #ef3e27 !important;
  color: #fff;
}

.btnApply:after {
  content: '\276f';
  padding: 0.5em;
  position: relative;
  right: 0;
  -webkit-transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -ms-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
  transition: all 0.15s linear;
}

.btnApply:hover:after,
.btnApply:focus:after,
.btnApply:active:after {
  right: -13px;
}
.promoCode .btnApply {
  font-size: 0.9em;
  padding: 0.5em 2em;
}
.video-input input {
  width: 82%;
  float: left;
  border-radius: 0 !important;
}

.video-input .btn {
  float: left;
  width: 11%;
  text-align: center;
  color: #fff;
  border: 1px solid #242a34 !important;
  background: #242a34 !important;
}
.video-input .btn:hover {
  border: 1px solid #ef3e27 !important;
  background: #ef3e27 !important;
  color: #fff;
}
.video-input label {
  display: block;
  width: 100%;
  font-size: 0.8125rem;
  line-height: 1;
  font-weight: bolder;
  margin-bottom: 0.5rem;
  color: #3c4b5c;
}

iframe.ql-video {
  background: #000;
  border: none;
  width: 100% !important;
  height: 300px;
  /* max-width: 228px; */
  margin: auto;
  border: 1px solid rgb(255 255 255 / 32%);
  max-height: 100% !important;
  /* border-radius: 7px !important; */
}
iframe.qlh-video {
  width: 100% !important;
}

.text-gray-colour {
  color: #8d8a89;
}
.text-overflow {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
p.event-content.dashboar {
  margin: 0px;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.event-address-container.bg-white {
  background: #f4f4f4 !important;
}

.ant-collapse-header:hover {
  color: #ef3e27 !important;
  font-size: 0.8125rem !important;
  vertical-align: middle !important;
  text-transform: uppercase !important;
  font-weight: 700 !important;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  display: table-cell;
  background-color: #ef3e27 !important;
  color: #f0f8ff;
  border-radius: 0 !important;
  border: 3px solid #fff;
}
.DayPicker-Day {
  display: table-cell;
  padding: 0.4em !important;
  border-radius: 0 !important;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}
.DayPicker-Day:hover {
  display: table-cell;
  background-color: #ef3e2728 !important;
}
.bootstrap-timepicker-widget.time_pic_wrapper,
.DayPicker,
.DayPicker-wrapper,
.DayPicker-Months,
.DayPicker-Caption,
.DayPicker-Weekdays,
.DayPicker-Body,
.DayPicker-Week {
  outline: none !important;
}

.DayPicker-NavButton--prev {
  margin-right: 1.5em;
  outline: none !important;
}
.DayPicker-NavButton--next {
  outline: none !important;
}

p.text-muted {
  word-wrap: break-word;
}

.addeventatc {
  display: inherit !important;
  font-family: inherit !important;
  font-weight: 100 !important;
  background: transparent !important;
  border: 0 !important;
  padding: 0 !important;
  border-radius: 0 !important;
  font-size: 14px !important;
  padding-top: 12px !important;
  outline: none !important;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  background-image: url(https://www.addevent.com/gfx/icon-calendar-t5.png),
    url(https://www.addevent.com/gfx/icon-calendar-t1.svg),
    url(https://www.addevent.com/gfx/icon-apple-t5.svg),
    url(https://www.addevent.com/gfx/icon-facebook-t5.svg),
    url(https://www.addevent.com/gfx/icon-google-t5.svg),
    url(https://www.addevent.com/gfx/icon-office365-t5.svg),
    url(https://www.addevent.com/gfx/icon-outlook-t5.svg),
    url(https://www.addevent.com/gfx/icon-outlookcom-t5.svg),
    url(https://www.addevent.com/gfx/icon-yahoo-t5.svg);
  background-position: none !important;
}

div#addeventatc1,
div#addeventatc2 {
  box-shadow: none !important;
}

.addeventatc:hover {
  background-color: transparent !important;
  color: #ef3e27 !important;
  box-shadow: none !important;
  font-weight: 100 !important;
  font-size: 14px !important;
  text-decoration: none;
}

span.addeventatc_icon.atc_node.notranslate {
  display: none !important;
}

.addeventatc .start, .addeventatc .end, .addeventatc .timezone, .addeventatc .title, .addeventatc .description, .addeventatc .location, .addeventatc .organizer, .addeventatc .organizer_email, .addeventatc .facebook_event, .addeventatc .all_day_event, .addeventatc .date_format, .addeventatc .alarm_reminder, .addeventatc .recurring, .addeventatc .attendees, .addeventatc .calname, .addeventatc .uid, .addeventatc .sequence, .addeventatc .status, .addeventatc .method, .addeventatc .client, .addeventatc .transp {
  display: none!important;
}
/* .navbar .navbar-menu-container .navbar-nav .nav-item.nav-profile img {
  width: 35px;
  height: 35px;
  border-radius: 100%;
} */

img.user-img.topbar {
  width: 38px;
  height: 38px;
  border-radius: 100%;
}

.add-Calendar {
  box-shadow: none !important;
}

.order-filter.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  color: #8d8a89;
  border: 1px solid #8d8a89;
  font-size: 0.8125rem;
  line-height: 1.5;
  font-weight: 700;
  border-radius: 0 !important;
}
.order-filter.ant-select-single:not(.ant-select-customize-input):hover
  .ant-select-selector:hover {
  color: #ef3e27 !important;
  background-color: #ececec;
  border: 1px solid transparent !important;
}
.order-filter .ant-select-selector:hover .ant-select-selection-placeholder {
  color: #ef3e27 !important;
  font-size: 13px;
  padding-top: 5px;
  opacity: 1;
}

.select-limit.ant-select-single.ant-select-show-arrow
  .ant-select-selection-item {
  color: #8f8c8b !important;
  font-size: 13px;
  padding-top: 0px;
  opacity: 1;
}

.order-filter span.ant-select-arrow {
  user-select: none;
  font-weight: 900 !important;
  width: 10px;
  color: #8d8a89;
}

.order-filter span.ant-select-arrow:hover {
  color: #ef3e27 !important;
}

.privacy-terms-page h1 {
  font-size: 46px;
  text-align: center;
  color: rgb(44, 48, 52);
  margin: 0;
  padding: 0 0 6px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.privacy-terms-page h2 {
  font-size: 26px;
  line-height: 20px;
  text-align: left;
  color: rgb(44, 48, 52);
  margin: 0;
  padding: 20px 0 0 0;
}

.privacy-terms-page p {
  font-size: 14px;
  letter-spacing: 1px;
  color: #8d8a89;
  margin: 0;
  line-height: 30px !important;
}

.privacy-terms-page span {
  color: #242a34;
  font-weight: bold;
}

.term-hr {
  width: 63%;
  color: #ef3e27;
  border: none;
  border-top: 2px solid;
  margin: 1em auto;
  margin-left: 17px;
}
.privacy-terms-page {
  text-align: justify;
  text-justify: inter-word;
}
.website-link:hover {
  background-color: #ef3e2710;
  color: #242a34;
  text-decoration: none;
}

.modal .modal-dialog .modal-content .modal-body-popup {
  padding: 5rem 4rem;
  font-weight: 300;
  color: #3f3f3f;
  height: 500px;
}
.modal-body-popup img {
  max-width: 80% !important;
  max-height: 85% !important;
}
a.website-link-open {
  color: #fff;
  text-decoration: none;
}

a.website-link-open:hover {
  color: #fff;
  text-decoration: none;
}

.uplodeImgContainer-myfeed {
  width: 181px;
  position: relative;
  height: 191px;
  border-radius: 8px;
  border: 2px solid #e6e4e9;
  margin-right: 20px;
  display: inline-block;
}

.fb-btn {
  display: flex;
  width: 100%;
}
.reset-card {
  width: 30%;
  padding: 20px;
}

.page-msg h5,
.page-msg h4,
.page-msg h3,
.page-msg h2,
.page-msg h6,
.page-msg h1,
.page-msg p {
  display: block;
  margin-block-start: 0.5em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  line-height: 1.5;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  font-family: 'Chivo', sans-serif;
  letter-spacing: 0.3px;
  color: #404040;
}
/* ........................cart page add or remove button.......................... */

.CartItem-count {
  margin: 0px;
  padding: 9px 18px;
  border: 1px solid #ccc;
  background: #f4f4f4;
}
.CartItem-addOne,
.CartItem-removeOne {
  padding: 9px 15px;
  color: #ef3e27;
  border: 1px solid #ccc;
  background: #fff;
}
.CartItem-addOne {
  border-left: 0;
}
.CartItem-removeOne {
  border-right: 0;
}

/* ..............................................media.............................. */

@media (max-width: 991px) {
  .d-hide-img {
    display: none;
  }
}

@media (max-width: 1450px) {
  .owl-carousel .owl-item img {
    height: 130px;
  }
  .success-content {
    margin: 0px 250px;
    min-height: calc(100vh - 80px - 320px);
  }
}
@media (max-width: 995px) {
  .success-content {
    margin: 0px 162px;
    min-height: calc(100vh - 80px - 320px);
  }
}
@media (max-width: 795px) {
  .success-content {
    margin: 0px 62px;
    min-height: calc(100vh - 80px - 320px);
  }
}

@media (min-width: 1160px) and (max-width: 1320px) {
  .content-event {
    margin: 0 80px;
    min-height: calc(100vh - 80px - 320px);
  }
}
@media (min-width: 992px) and (max-width: 1382px) {
  .promoCode a.btnApply {
    float: left;
    width: 30%;
    padding: 0.85em 0;
    border-radius: 0 1.5em 1.5em 0;
    text-align: center;
    color: #fff;
    border: 1px solid #242a34;
    background: #242a34;
  }
  .promoCode input {
    width: 70%;
    float: left;
    border-radius: 0;
  }
}

@media (min-width: 1320px) and (max-width: 1420px) {
  .content-event {
    margin: 0 162px;
    min-height: calc(100vh - 80px - 320px);
  }
}
@media (max-width: 575px) {
  .event-title h2.text-uppercase.font-weight-bold.mb-0 {
    font-size: 1.25rem;
  }
}
@media (min-width: 1390px) and (max-width: 1497px) {
  .event-list .event-item .event-schedule .event-day {
    font-size: 50px;
    font-weight: 100;
    font-family: chivoLight !important;
  }
  .event-list .event-item .event-schedule .event-month-time {
    font-weight: 200;
    font-size: 20px;
    display: flex;
    line-height: 118%;
    flex-direction: column;
    justify-content: center;
  }
}
@media (min-width: 1200px) {
  .container-fluid {
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (max-width: 1600px) {
  .reset-card {
    width: 50%;
    padding: 20px;
  }
}
@media (max-width: 700px) {
  reset-card {
    width: 90%;
    padding: 20px;
  }
}
@media only screen and (max-width: 1025px) {
  .event-address-container {
    padding: 0 100px;
  }
}
@media (max-width: 575px) {
  .user-login.login-v2 .login-left-section {
    padding: 1rem;
    min-height: 190px !important;
  }
}

/* Extra small devices Portrait */
@media (max-width: 575.98px) {
  .card-header-block {
    display: block !important;
  }
  body.event-bdy {
    background: none;
  }
  .overlay-bg {
    display: none;
  }
  .event .event-banner .event-title {
    margin: 0 2rem;
  }
  .event .event-banner {
    margin: 0;
    position: absolute;
    left: 0;
    z-index: 1;
  }
  .event .event-info {
    margin-left: 0;
  }
  .event-address-container {
    padding: 0;
  }

  .mb-cart-info {
    padding: 1.25rem;
  }
  .mb-cart-info .cart .count {
    position: absolute;
    top: 12px;
    left: 35px;
  }
  .success-content,
  .content {
    margin: 0 1.25rem;
  }
  .footer {
    padding: 0 4.25rem;
  }
  .banner-front {
    padding: 1rem;
    text-align: center;
  }
  .banner-front form {
    width: 90%;
  }
  .navbar {
    padding: 0 0 0 0rem;
    height: 60px;
  }
}
.event-owl.owl-carousel .owl-item img {
  /* height: 100%; */
  width: 100%;
  object-fit: fill;
}
.owl-carousel.owl-banner .owl-item img {
  display: block;
  width: 100%;
  height: auto;
  max-width: 60%;
  right: 0;
  left: auto;
  object-fit: cover;
}
.owl-carousel.owl-theme.owl-banner.owl-loaded.owl-drag {
  position: relative;
}

.owl-carousel.owl-theme.owl-banner.owl-loaded.owl-drag
  .owl-nav.disabled
  + .owl-dots {
  margin-top: 10px;
  position: absolute;
  right: 10%;
  bottom: 6%;
  color: #fff;
}
.owl-carousel.owl-theme.owl-banner.owl-loaded.owl-drag
  .owl-dots
  .owl-dot.active
  span,
.owl-carousel.owl-theme.owl-banner.owl-loaded.owl-drag
  .owl-dots
  .owl-dot:hover
  span {
  background: #ef3e27;
}
.overlay-bg-home {
  position: absolute;
  width: 100%;
  background-image: linear-gradient(to right, #000 39%, transparent 78%);
  /* height: 630px; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.157);
  cursor: pointer;
}
@media (max-width: 700px) {
  .overlay-bg-home {
    position: absolute;
    width: 100%;
    background-image: linear-gradient(to right, #000 39%, transparent 78%);
    /* height: 630px; */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.157);
    cursor: pointer;
  }
}
.banner-item {
  width: 100%;
  height: 630px;
}
.overlay-bg-home2 {
  position: absolute;
  width: 100%;
  height: 630px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}
.btn-event-info {
  letter-spacing: 1.2px;
  padding: 10px 18px;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
}
.d-block-banner {
  width: 470px;
}

.date-dash .anticon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  color: #ef3e27;
  font-size: 25px;
  text-transform: none;
  vertical-align: -1px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.about-feature-event {
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.event-card-content {
  min-height: 70px;
}
@media only screen and (max-width: 1025px) {
  .content {
    margin: 0px 0px !important;
  }
}

.widget-8-img {
  width: 80px;
  height: 41px;
  border: 1px solid #8f8c8b;
}
.widget-8 .widget-8-desc {
  width: 100% !important;
  display: flex;
}

img.ticket-page-qr {
  max-width: 100%;
}

span.website-link {
  color: #ef3e27 !important;
}
.widget-13-left-section.profile {
  background: #f4f4f4;
}
.mt-05 {
  margin-top: 1.5rem !important;
}

.view_page svg {
  fill: #9e9e9e;
  width: 18px;
  color: #9e9e9e !important;
  cursor: pointer;
}
iframe.ql-video.colsize-6 {
  background: #000;
  border: none;
  width: 100% !important;
  height: 250px !important;

  margin: auto;
  border: 1px solid rgb(255 255 255 / 32%);
  max-height: 100% !important;
  /* border-radius: 7px !important; */
}

img.placeholder-gallery {
  max-width: 200px;
  max-width: 100%;
  max-height: 170px;
  width: auto;
  margin-top: 26px;
}
.btn-down-arrow:after {
  content: '';
  position: absolute;
  height: 9.2px;
  width: 9.2px;
  border: none;
  right: 11px;
  border-left: 1.9px solid #8d8a89;
  border-bottom: 1.9px solid #8d8a89;
  top: 12px;
  transform: scale(0.75, 1) rotate(-45deg);
}
.btn-down-arrow {
  padding: 0.53125rem 2.5rem 0.53125rem 0.8125rem;
}
span.activeAccountsetting {
  color: #ef3e27;
}
.ant-collapse-header .activeAccountsetting:hover {
  color: #ef3e27 !important;
  /* font-size: 0.8125rem !important; */
  /* vertical-align: middle !important; */
  text-transform: uppercase !important;
  font-weight: 700 !important;
}
.ant-select.order-filter.ant-select-single.ant-select-show-arrow.ant-select-disabled.ant-select-show-search:hover {
  background: rgb(236 236 236);
}

.ant-select-disabled.order-filter span.ant-select-arrow {
  display: block !important;
}

.ant-select-disabled.order-filter.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  cursor: not-allowed;
  color: #8d8a89 !important;
}
li.nav-header-li {
  padding-left: 20px !important;
  color: rgba(210, 213, 228, 0.5) !important;
  margin-bottom: 22px;
  list-style: none;
}
.social-anchor span {
  display: flex;
  align-items: center;
  min-width: 36px;
  justify-content: center;
  border: 1px solid #e6e4e9 !important;
}
.social-anchor a {
  color: inherit !important;
  text-decoration: none;
  background-color: transparent;
}
.related-events .owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  text-align: -webkit-center;
  -webkit-transform: translate3d(0px, 0px, 0px);
}
.mxw-100 {
  max-width: 100%;
  max-height: 100%;
}
.content.content-event.event.event-auto-hg.auto-height {
  overflow: inherit !important;
}
.social-icon-btn {
  background-color: transparent;
  border: none;
  padding: 0px;
  font: inherit;
  color: inherit;
  cursor: pointer;
}

.social-icon-btn a:hover {
  text-decoration: none;
  color: inherit;
}
.preview-size {
  width: 100%;
  height: 50px;
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 10px;
}

.preview-size img {
  height: 100%;
  display: block;
  margin-right: 10px;
  width: 80px;
  object-fit: cover;
  border-radius: 3px;
}

.preview-size span {
  display: inline-block;
  font-size: 12px;
}

.preview-size span.cross-dropzone-icon {
  margin-left: auto;
  margin-right: 10px;
  color: #cc2105;
}
.color-orng {
  color: #ef3e27;
}
.overlaylayer {
  position: absolute;
  height: 100%;
  width: 100%;
  background: transparent;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 24px;
}
.upload-img-list {
  position: relative;
  background: #f7f7f7;
  margin: auto;
  text-align: center;
  cursor: pointer;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.upload-img-list img {
  height: 100%;
  width: auto;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.event-img-gallery.row {
  margin-right: -2px;
  margin-left: -2px;
}
.event-img-gallery.row > [class*='col-'] {
  padding-left: 2px;
  padding-right: 2px;
  margin-bottom: 4px;
}

svg.svg-inline--fa.fa-plus.fa-w-12.fa-2x,
svg.svg-inline--fa.fa-minus.fa-w-12.fa-2x {
  width: 10px !important;
}

.navbar-mb-container .secondary .nav-wrapper .nav .nav-item .nav-link.active {
  background-color: #ef3e27;
  color: #fff !important;
}

.banner-main {
  position: relative;
  z-index: 1;
  margin-top: -43px;
}

.event-auto-hg {
  min-height: initial !important;
  /* max-height: 530px; */
  overflow: hidden;
}
img.event-banner-img {
  width: 100%;
  max-height: 530px;
  /* height: 100%; */
}
.banner-main:after {
  content: '';
  position: absolute;
  height: 85%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  z-index: -1;
}
.banner-main:before {
  content: '';
  position: absolute;
  height: 85%;
  width: 100%;
  background: url('./assets/images/EventPages/event-list.jpg') no-repeat;
  background-size: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}
.user-login.login-v2.reset-card .card {
  box-shadow: 0 7px 34px 1px #c1bed1;
  -webkit-box-shadow: 0 7px 34px 1px #c1bed1;
  -moz-box-shadow: 0 7px 34px 1px #c1bed1;
  -ms-box-shadow: 0 7px 34px 1px #c1bed1;
  width: 100%;
  max-width: 536px;
}
.table-overflow .table-responsive {
  min-width: 650px;
}
a.btn.btn-sm.btn-dark {
  cursor: pointer;
}
.table-overflow {
  overflow: auto;
}
/* .gallery-view {
  max-width: 330px;
} */
.viewmore-list {
  position: relative;
}
.more-list {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgb(0 0 0 / 44%);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  color: #fff;
  font-size: 24px;
}

@media (min-width: 992px) {
  .navbar-mb-more.show {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  .order-select {
    display: block !important;
  }
  /* .ant-picker-panel-container {
    max-width: 280px;
  } */
}

.select-rang {
  align-items: baseline;
}

/* .ant-picker-panels {
  overflow: auto !important;
  width: 100% !important;
} */

/* @media (max-width: 400px){
  .ant-picker-dropdown-placement-bottomLeft {
    max-width: 280px;
  }
} */
.card-new-header {
  display: block !important;
}
/* .at-expanding-share-button {
  position: absolute !important;
  left: 12% !important;
  bottom: -11% !important;
} */
.addthis_toolbox {
  display: inline-block;
}
.addthis_toolbox a {
  display: inline-block;
  vertical-align: middle;
}
.addthis_toolbox a svg {
  width: 23px !important;
}
.at-expanding-share-button-toggle-bg {
  border-radius: 50%;
  box-shadow: 0px 0px 1px 1px #0000001a;
  animation: pulse-animation 1s infinite !important;
  margin-left: 20px !important;
  margin-bottom: 20px !important;
}
.at-expanding-share-button-services-list {
  margin-left: 25px !important;
  margin-bottom: 10px !important;
}
@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(190, 0, 0, 0.5);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}
@media (max-width: 670px) {
  .ant-picker-panels {
    display: block !important;
  }

  .ant-picker-panel {
    width: 100%;
  }

  .ant-picker-date-panel {
    width: 100%;
  }

  table.ant-picker-content {
    width: 100% !important;
  }

  .ant-picker-dropdown.ant-picker-dropdown-range.ant-picker-dropdown-placement-bottomLeft {
    left: 55px;
    right: 55px;
  }
}

@media only screen and (max-width: 1025px) {
  .content {
    margin: 0px 100px !important;
  }
}
@media only screen and (max-width: 1025px) {
  .content-create-form {
    margin: 0px 20px !important;
  }
}

.share {
  right: 20px;
  bottom: 20px;
}
@media (min-width: 768px) {
  .share {
    right: unset;
    bottom: unset;
  }
}
.share__toggle:checked ~ .share__button {
  transform: rotate(-180deg);
}
.share__toggle:checked ~ .share__icon--facebook {
  transform: translateX(55px) rotate(0);
}
.share__toggle:checked ~ .share__icon--twitter {
  transform: translateX(110px) rotate(0);
}
.share__toggle:checked ~ .share__icon--pinterest {
  transform: translateX(165px) rotate(0);
}
.share__toggle:checked ~ .share__icon--linkedin {
  transform: translateX(220px) rotate(0);
}
.share__button {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 53px;
  height: 52px;
  color: white;
  background-color: #ef3e27;
  border-radius: 50px;

  cursor: pointer;
  transition: 0.3s ease;
  margin-left: 1px;
  margin-top: 1px;
}
.share__icon {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  transform: translateX(0) rotate(180deg);
  cursor: pointer;
  transition: 0.3s ease-in-out;
  margin-left: 2px;
  margin-top: 2px;
}
.share__icon--facebook {
  background-color: #3b5998;
}
.share__icon--twitter {
  background-color: #1da1f2;
}
.share__icon--pinterest {
  background-color: #bd081c;
}
.share__icon--linkedin {
  background-color: #0077b5;
}

a.downloadIcon {
  color: #fff;
  text-decoration: none;
}

.galler-main {
  height: 290px !important;
}

.galler-main {
  margin: auto !important;
  text-align: center !important ;
  background: #fff !important;
}

.gallery-view img {
  max-width: 100% !important;
}

.galler-main {
  margin: auto !important;

  max-height: 250px;
  text-align: center !important;
  background: #fff !important;
}

@media (max-width: 1650px) {
  .upload-img-list {
    height: 210px !important;
  }

  .galler-main {
    max-height: 210px !important;
  }
  .galler-list img {
    object-fit: cover;
    min-height: 210px !important;
    height: 100% !important;
    width: 100% !important;
  }
  iframe.ql-video.colsize-6 {
    background: #000;
    border: none;
    width: 100% !important;
    height: 210px !important;

    margin: auto;
    border: 1px solid rgb(255 255 255 / 32%);
    max-height: 100% !important;
    /* border-radius: 7px !important; */
  }
}
@media (max-width: 1200px) {
  iframe.ql-video.colsize-6 {
    background: #000;
    border: none;
    width: 100% !important;
    height: 200px !important;

    margin: auto;
    border: 1px solid rgb(255 255 255 / 32%);
    max-height: 100% !important;
    /* border-radius: 7px !important; */
  }

  .galler-main {
    margin: auto !important;
    max-height: 200px !important;
    text-align: center !important;
    background: #fff !important;
  }
  .upload-img-list {
    height: 180px !important;
  }
  .content-event {
    margin: 0 65px;
    min-height: calc(100vh - 80px - 320px);
  }
}
@media (max-width: 1020px) {
  .update-feed-image {
    height: 219px !important;
  }

  .upload-img-list {
    height: 138px !important;
  }
}

@media (max-width: 985px) {
  .upload-img-list {
    height: 238px !important;
  }
}
@media (max-width: 885px) {
  .upload-img-list {
    height: 178px !important;
  }
}
@media (max-width: 768px) {
  .upload-img-list {
    height: 180px !important;
  }
}
@media (max-width: 639px) {
  .upload-img-list {
    height: 139px !important;
  }
}
@media (max-width: 578px) {
  .upload-img-list {
    height: 129px !important;
  }
}
@media screen and (max-width: 991px) {
  .event-header-home {
    padding-left: 20px;
  }
}
@media (max-width: 500px) {
 
  .update-feed-image {
    height: 200px !important;
  }
}
@media (max-width: 700px) {
  .mb-view .d-block {
    padding: 4px 20px 10px 0px !important;
  }
  .mb-view .event-header-home {
    flex-direction: row !important;
    padding-bottom: 12px !important;
    padding-top: 0px !important;
  }
  .mb-view .about-feature-event {
    max-width: 100% !important;
  }
  .mb-view .font-weight-bold {
    max-width: 100% !important;
  }

  .owl-stage-outer.owl-height {
    height: auto !important;
  }
  .mb-view .overlay-bg-home {
    
    background-image: linear-gradient(0deg, #000 57%, #000000cf 63%,#000000c5 46%, #00000000 40%);
  }
  .bg-change{
    background:black !important;
    /* height: 270px; */
  
    display: -webkit-box;
  }
  .bg-change {
    /* height: 270px; */
}
  p.text-white.font-size-16.about-feature-event {
    max-width: 75% !important;
  }
  h1.text-uppercase.text-white.font-weight-bold {
    max-width: 100% !important;
  }

  .content-home {
    margin: 0px !important;
  }

  .content-home-inner img {
    position: inherit !important;
    left: 0;
    max-width: 100% !important;
  }
  .owl-carousel.owl-banner .owl-item img {
    max-width: 100% !important;
  }
  .update-feed-image {
    height: 200px !important;
  }
}
@media (max-width: 420px) {
  .update-feed-image {
    height: 168px !important;
  }
  .upload-img-list {
    height: 106px !important;
  }
}
.cm-select-box-2 .ant-select-selector {
  font-size: 13px !important;
  border-width: 0 0 1px 0 !important;
  background-color: transparent !important;
}

.ant-select:not(.cm-select-box-2 .ant-select-disabled):hover
  .cm-select-box-2
  .ant-select-selector {
  border-width: 0 0 1px 0 !important;
}
.ant-select.ant-select-borderless.cm-select-box-2.ant-select-single.ant-select-show-arrow:hover {
  border: none !important;
}

.ant-select.ant-select-borderless.cm-select-box-2.ant-select-single.ant-select-show-arrow {
  outline: #e6e4e9 !important;
  border-bottom: 1px solid #e6e4e9 !important;
}
.ant-select.ant-select-borderless.cm-select-box-2.ant-select-single.ant-select-show-arrow:hover {
  outline: #e6e4e9 !important;
  border-bottom: 1px solid #ef3e27 !important;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #e6e4e9 !important;
  border-right-width: 1px !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: #e6e4e9 !important;
  border-right-width: 1px !important;
}

.csm-check .string-check-label {
  display: inline-flex !important;
}

p.text-white.font-size-16.about-feature-event {
  max-width: 100%;
}
.home-page p.about-feature-event.default-event {
  max-width: 278px !important;
  -webkit-line-clamp:initial;
}
@media screen and (max-width:330px){
  .home-page p.about-feature-event.default-event {
    max-width: 220px !important;
  }
}


.event-question-form {
  display: flex;
  margin: 116px auto;
  flex-direction: column;
}
.event-question-form form {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 438px;
  padding: 20px 36px;
  width: 100%;
  box-shadow: 0 7px 34px 1px #c1bed1;
}

.event-question-form h4 {
  text-align: center;
  margin-bottom: 20px;
}
.event-question-form input {
  width: 100%;
  margin-bottom: 20px;
  height: 45px;
  border-radius: 20px;
  border: 1px solid #ced4da;
  padding: 0.53125rem 0.8125rem;
}
.event-question-form textarea {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 20px;
  border: 1px solid #ced4da;
  min-height: 213px;
  padding: 0.53125rem 0.8125rem;
}
.event-question-form select {
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid #ced4da;
  padding: 0.53125rem 0.8125rem;
  color: rgba(0, 0, 0,0.65);
}
.event-bdy .content-event a.event-info {
  text-decoration: none;
  color:#404040;
}

.content-event .event-detail-wrapper a.btn {
  padding-left:52px !important;
}

.content-event .event-detail-wrapper a.btn .fa-envelope {
  margin: 0px !important;
}

.hidden {
  display:none !important;
}

.card.quick-stats {
  padding:20px;
  
}
.card.quick-stats .quick-stats-title {
  display:flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}
.card.quick-stats .quick-stats-title h3 {
  display:flex;
  justify-content: center;
  align-items: center;
}
.card.quick-stats .left-arrow,.card.quick-stats .right-arrow {
  padding: 8px 12px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
}
.card.quick-stats .left-arrow:active,.card.quick-stats .right-arrow:active {
  box-shadow: 1px 1px 4px 0px rgba(0,0,0,0.1);
}
.quick-stats-body {
  padding-top:20px;
  display:flex;
  justify-content: flex-start;
}
.quick-stats-body .image {
  display:flex;
  justify-content:center;
  width:50%;
  margin-right:20px;
  height:auto;
}
.quick-stats-body .stats {
  display:flex;
  flex-direction: column;
  width:50%;
}
.quick-stats-body .image img {
  width: 100% !important;
  height: auto !important;
  margin-bottom: 20px;
  object-fit: cover;

}
.quick-stats-body ul li,.quick-stats-body ul {
  margin-left:0px;
  padding-left:0px;
  list-style: none;
}
.quick-stats-body ul {
  display:flex;
  flex-wrap:wrap;
}

.quick-stats-body ul li {
  margin-bottom:6px;
  padding-right:10px;
  display:flex;
  justify-content: space-between;
  width:calc( 100% );
}
.quick-stats-body ul li div {
  display:flex;
  justify-content: space-between;
}
.quick-stats-section h3 {
  display:flex;
  justify-content:space-between;
}
.stat-section-export {
  font-size:16px;
}
.stat-scroll-box {
  display: flex;
  flex-wrap: wrap;
  max-height: 200px;
  overflow-y: auto;
}