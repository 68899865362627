.datepicker {
  width: 260px;
  padding: 10px;
}
.delete-cart-item {
  text-align: center;
  justify-content: center;
 
}
.ant-picker-input > input[disabled] {
  background: transparent;
  color: rgba(0, 0, 0, 0.85) !important;
}
.ant-picker.ant-picker-disabled {
  background: #f5f5f5;
  
  border: 1px solid #e6e4e9 !important;
  cursor: not-allowed;
}

.col-4.mt-1.scroll-item {
  height: 160px !important;
  
}
.videoPoster:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
  margin: -40px 0 0 -40px;
  background: #fff;
  cursor: pointer;
  border-radius: 100%;
  -webkit-transition: border-color 300ms;
  -moz-transition: border-color 300ms;
  transition: border-color 300ms;
}

.videoPoster:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  width: 0;
  justify-content: left;
  height: 0;
  cursor: pointer;
  margin: -12px 0 0 -6px;
  border-left: 15px solid #ef3e27;
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  transition: border-color 300ms;
}

.owl-carousel .owl-stage,
.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: auto !important;
  touch-action: auto !important;
}
.update-feed-image {
  position: relative;
  background: #f7f7f7;
  margin: auto;
  text-align: center;
  cursor: pointer;
  height: 280px;
  display: flex;
  align-items: center;
  justify-content: center;

}
.cm-list-col {
  width: 20%;
  padding: 5px;
}

.col-sm-4.col-md-4.col-lg-3.col-xl-2.mt-1.scroll-item {
  max-height: 202px;
}
.row.mb-4.scroll.main-update {
  max-height: 100% !important;
}
/* .gallery-view img {
  width: 100%;
} */
img.placeholder-gallery {
  max-width: 200px;
}
.galler-main {
  height: 300px !important;
}
.galler-list img {
  max-height: 150px;
}
.galler-main img {
  max-height: 300px !important;
}
html {
  position: relative;
  min-height: 100%;
  font-family: 'Chivo', sans-serif;
}
.css-1vmnjpn-skeletonStyles-Skeleton {
  background-color: #eee;
  background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 0px !important;
  display: inline-block;
  line-height: 1;
  width: 100%;
  -webkit-animation: animation-16jpnkj 1.2s ease-in-out infinite;
  animation: animation-16jpnkj 1.2s ease-in-out infinite;
}
.css-1ykbi8a-SkeletonTheme .react-loading-skeleton {
  border-radius: 0px !important;
  background-color: #cccccc9c !important;
  background-image: linear-gradient(
    90deg,
    #cccccc00,
    #dddddd00,
    #cccccc08
  ) !important;
}
.invoice.my-ticket .table-responsive {
  overflow-x: hidden !important;
}
.status_button.ant-select-single.ant-select-show-arrow
  .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  text-align: left !important;
  top: 0px !important;
}
.badge-brand {
  background-color: #ef3e27;
  color: #fff;
}
.badge-count {
  padding: 7px 7px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
h1.text-white.font-weight-bold {
  font-size: 43px;
  font-weight: 800;
  letter-spacing: 2.9px;
  font-family: chivoBold;
  margin-bottom: 1.5rem;
  font-weight: 500 !important;
  line-height: 1.2;
}
.home-form .form-control:focus {
  box-shadow: none;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-color: #ef3e27;
  background-color: transparent;
}
h2.react-reveal {
  line-height: 1.2;
}
.event-owl.owl-carousel .owl-item img {
  /* height: auto; */
  width: 100%;
  object-fit: fill;
}
.datepicker.datepicker-orient-top {
  margin-top: 8px;
}

.datepicker table {
  width: 100%;
}

.datepicker td,
.datepicker th {
  font-weight: 300;
  width: 35px;
  height: 35px;
  border-radius: 4px;
}

.datepicker thead th {
  color: #394044;
}

.datepicker thead th.prev,
.datepicker thead th.datepicker-switch,
.datepicker thead th.next {
  font-weight: 500;
  color: #727686;
}

.datepicker thead th.prev i,
.datepicker thead th.datepicker-switch i,
.datepicker thead th.next i {
  font-size: 1.2rem;
  color: #727686;
}

.datepicker thead th.prev i:before,
.datepicker thead th.datepicker-switch i:before,
.datepicker thead th.next i:before {
  line-height: 0;
  vertical-align: middle;
}

.datepicker thead th.prev:hover,
.datepicker thead th.datepicker-switch:hover,
.datepicker thead th.next:hover {
  background: #e9f8ff !important;
}

.datepicker thead th.dow {
  color: #394044;
  font-weight: 500;
}

.datepicker tbody tr > td.highlighted {
  background-color: #ffdcda;
}

.datepicker tbody tr > td.day {
  color: #394044;
}

.datepicker tbody tr > td.day:hover {
  background: #ffdcda;
  color: #394044;
}

.datepicker tbody tr > td.day.old {
  color: #394044;
}

.datepicker tbody tr > td.day.new {
  color: #394044;
}

.datepicker tbody tr > td.day.selected,
.datepicker tbody tr > td.day.selected:hover,
.datepicker tbody tr > td.day.active,
.datepicker tbody tr > td.day.active:hover {
  background: #ef3e27;
  color: #ffffff;
}
.navbar .navbar-menu-container .navbar-nav .nav-item.nav-profile img {
  width: 30px;
  height: 30px;
  z-index: 99 !important;
  border-radius: 100%;
}
.css-yk16xz-control:active {
  border: 1px solid #e6e4e9 !important;
  box-shadow: none !important;
}
.css-2b097c-container {
  position: relative;
  box-shadow: none !important;
  box-sizing: border-box;
  border-radius: 0.25rem;
  border: 0 !important;
}
.css-yk16xz-control {
  display: block;
  width: 100%;
  box-shadow: none !important;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3f3f3f;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #e6e4e9 !important;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.css-yk16xz-control:active {
  box-shadow: none !important;
  border: 1px solid #e6e4e9 !important;
}
.css-yk16xz-control:hover,
.css-1pahdxg-control:hover {
  border-color: #ef3e27 !important;
}
.datepicker tbody tr > td.day.today {
  position: relative;
  background: rgba(239, 62, 39, 0.7) !important;
  color: #ffffff;
}
.css-1n7v3ny-option {
  font-weight: 100 !important;
  background-color: #ececec !important;
}

.datepicker tbody tr > td.day.today:before {
  content: '';
  display: inline-block;
  border: solid transparent;
  border-width: 0 0 7px 7px;
  border-bottom-color: #ffffff;
  border-top-color: kt-base-color(grey, 2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}

.datepicker tbody tr > td.day.range {
  background: kt-base-color(grey, 1);
}

.datepicker tbody tr > td span.year,
.datepicker tbody tr > td span.hour,
.datepicker tbody tr > td span.minute,
.datepicker tbody tr > td span.month {
  color: #394044;
}

.datepicker tbody tr > td span.year:hover,
.datepicker tbody tr > td span.hour:hover,
.datepicker tbody tr > td span.minute:hover,
.datepicker tbody tr > td span.month:hover {
  background: #e9f8ff;
}

.datepicker tbody tr > td span.year.focused,
.datepicker tbody tr > td span.year.focused:hover,
.datepicker tbody tr > td span.year.active:hover,
.datepicker tbody tr > td span.year.active.focused:hover,
.datepicker tbody tr > td span.year.active,
.datepicker tbody tr > td span.hour.focused,
.datepicker tbody tr > td span.hour.focused:hover,
.datepicker tbody tr > td span.hour.active:hover,
.datepicker tbody tr > td span.hour.active.focused:hover,
.datepicker tbody tr > td span.hour.active,
.datepicker tbody tr > td span.minute.focused,
.datepicker tbody tr > td span.minute.focused:hover,
.datepicker tbody tr > td span.minute.active:hover,
.datepicker tbody tr > td span.minute.active.focused:hover,
.datepicker tbody tr > td span.minute.active,
.datepicker tbody tr > td span.month.focused,
.datepicker tbody tr > td span.month.focused:hover,
.datepicker tbody tr > td span.month.active:hover,
.datepicker tbody tr > td span.month.active.focused:hover,
.datepicker tbody tr > td span.month.active {
  background: #22b9ff;
  color: #ffffff;
}

.datepicker tfoot tr > th.today,
.datepicker tfoot tr > th.clear {
  border-radius: 3px;
  font-weight: 500;
}

.datepicker tfoot tr > th.today:hover,
.datepicker tfoot tr > th.clear:hover {
  background: #e9f8ff;
}

.datepicker.datepicker-inline {
  border: 1px solid kt-base-color(grey, 2);
}

.input-daterange .input-group-addon {
  min-width: 44px;
}

.input-daterange input {
  border-radius: 0px;
  text-align: left;
}
.blog-bg {
  background: #fff;
}
/* .event-bdy {
  background: url('../../assets/images/EventPages/event-list.jpg') no-repeat;
  background-size: 100% 630px;
} */
.event-list-bdy {
  background: url('../../assets/images/EventPages/event-list.jpg') no-repeat;
  background-size: 100% 630px;
  background-color: #1c1c1c;
}
.content-home-inner .overlay-bg-home {
  z-index: 1;
}

.content-home-inner .event-header-home {
  z-index: 9;
}


.base-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
}


.navbar-home {
  display: block;
  padding: 0 0 0 50px;
  height: 80px;
  z-index: 12;
  position: relative;
  width: 100%;
  background-color: #fff;
}
.navbar-mb-menu-container {
  z-index: 12;
  position: relative;
  width: 100%;
  height: 80px;
}
.navbar-mb-more {
  position: absolute;
  top: -1000px;
  width: 280px;
  z-index: 99;
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}
.navbar-mb-more.show {
  top: 80px;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  right: 0;
}

.navbar-home .logo-container-home {
  position: absolute;
  z-index: 12;
  top: 0;
  width: 180px;
  height: 120px;
  background-color: #ef3e27;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar-home .logo-container-home img {
  position: relative;
  width: 80%;
}
.navbar-home .navbar-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  line-height: 80px;
}
.navbar-home .navbar-nav > .nav-item form {
  height: 80px;
}
.navbar-home .navbar-nav > .nav-item input {
  color: #808080;
  border: none;
  padding: 0;
  font-size: 14px;
  box-shadow: none;
}
.navbar-home .navbar-nav > .nav-item a {
  padding: 30px 30px;

  color: #808080;
  font-size: 14px;
}

.navbar-home .navbar-nav > .nav-item a:hover {
  background-color: #ef3e27;
  color: #fff;
  transition: all 0.2s;
}
.navbar-home .navbar-nav > .nav-item a.text-brand:hover {
  background-color: #ef3e27;
  color: #fff !important;
  transition: all 0.2s;
}
.navbar-home .navbar-nav .nav-item button {
  color: #434343;
}
.navbar-home .navbar-nav > .nav-item.nav-user {
  background-color: #ef3e27;
  color: #fff;
}
.navbar-home .navbar-nav > .nav-item.nav-user a {
  /* font-weight: 700; */
  font-family: 'Chivo', sans-serif;
}
.navbar-home .navbar-nav > .nav-item a.general {
  font-family: 'Chivo', sans-serif;
  color: #404040;
  padding: 0;
  margin: 0 30px;
  line-height: 78px;
}
.navbar-home .navbar-nav > .nav-item a.general:hover {
  background-color: #fff;
}
.navbar-home .navbar-nav > .nav-item a.general.active {
  background-color: #fff;
}
.navbar-home .navbar-nav > .nav-item a.general::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background-color: #ef3e27 !important;
  transition: width 0.3s;
}
.navbar-home .navbar-nav > .nav-item a.general:hover::after,
.navbar .navbar-nav > .nav-item a.general.active::after {
  width: 100%;
}
.banner-front {
  background-color: #000;
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.banner-front form {
  width: 500px;
}
.banner-front button {
  position: absolute;
  left: 0;
  padding: 0;
  bottom: 1rem;
}
.banner-front .form-control:focus {
  color: #808080;
}
.banner-front input {
  padding: 1rem 2rem;
  color: #808080;
}
.accordion .card-header {
  padding: 1.1rem 0;
}
.accordion .card-header .card-title {
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  color: #404040;
}
.accordion .card-header:after {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: '\f068';
  float: right;
  color: #ef3e27;
}
.accordion .card-header.collapsed:after {
  content: '\f067';
}
.success-content {
  margin: 0 400px;
  min-height: calc(100vh - 80px - 320px);
}
.success-content .content-title {
  font-weight: 700;
  font-size: 30px;
  text-transform: uppercase;
  padding: 40px 0;
  margin: 0;
  text-align: center;
  color: #404040;
}
.content-home {
  margin: 0 230px;
  /* min-height: calc(100vh - 80px - 320px); */
}
.content-home .content-title {
  font-weight: 700;
  font-size: 30px;
  text-transform: uppercase;
  padding: 40px 0;
  margin: 0;
  text-align: center;
  color: #404040;
}

.card {
  border-radius: 0px;
}
/* .mb-cart-info{
            display: flex;
            align-items: center;
            position: relative;
            height: 80px;
            -webkit-box-shadow: 0px -6px 34px -14px rgba(0,0,0,0.75);
            -moz-box-shadow: 0px -6px 34px -14px rgba(0,0,0,0.75);
            box-shadow: 0px -6px 34px -14px rgba(0,0,0,0.75);
        } */
.mb-cart-info .cart,
.mb-cart-info .checkout {
  padding: 1.25rem;
}
.mb-cart-info .cart .cart-toggle::after {
  content: none;
}
.mb-cart-info .cart .count {
  position: absolute;
  top: 12px;
  left: 35px;
}
.mb-cart-info .cart .cart-container {
  top: -375px !important;
  border-radius: 0;
  width: 300px;
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
.navbar-mb-container {
  transition: all 0.3s;
  min-height: 100vh;
  padding: 0;
  position: fixed;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: -300px;
  z-index: 999;
}
.navbar-mb-container .secondary {
  background: #fff;
  width: 250px;
}
.navbar-mb-container .secondary .nav-wrapper {
  height: 100vh;
  position: relative;
  overflow: auto;
  padding-top: 3em;
}
.navbar-mb-container .secondary .nav-wrapper .nav {
  overflow: hidden;
  flex-wrap: nowrap;
  flex-direction: column;
}
.navbar-mb-container .secondary .nav-wrapper .nav .nav-item {
  transition: all 0.3s;
}
.navbar-mb-container .secondary .nav-wrapper .nav .nav-item .nav-link {
  display: flex;
  white-space: nowrap;
  padding: 0.5625rem 0.875rem;
  color: #ef3e27;
  transition: all 0.3s;
  align-items: center;
  font-weight: 700;
  font-size: 1rem;
}
.navbar-mb-container .secondary .nav-wrapper .nav .nav-item .nav-link.active {
  background-color: #ef3e27;
  color: #fff;
}
.navbar-mb-container .secondary .nav-wrapper .nav .nav-item.collapse {
  z-index: 999;
}
.sidebar-overlay-home {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 99;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}
.sidebar-overlay-home.active {
  display: block;
  opacity: 0.1;
}
.footer {
  background-color: #000;
  padding: 0 230px;
  bottom: 0;
  position: relative;
  width: 100%;
}

.footer .footer-body {
  padding: 40px 0;
  border-bottom: 2px solid #1c1c1c;
}
.footer .footer-body ul li a:hover,
.footer .footer-privacy a:hover {
  color: #ef3e27 !important;
  transition: all 0.3s;
}
.footer .footer-body ul li a {
  color: #aeaeae;
  text-decoration: none;
  line-height: 1.7;
}

.footer .footer-privacy span {
  color: #aeaeae;
}
.footer .footer-privacy i {
  font-size: 14px;
}
.footer-support {
  background-color: #f4f4f4;
  padding: 3rem;
}
@media only screen and (max-width: 1225px) {
  .footer {
    background-color: #000;
    padding: 0px 25px;
    bottom: 0;
    position: relative;
    width: 100%;
  }}


@media only screen and (max-width: 1025px) {
  .footer {
    background-color: #000;
    padding: 0px 25px;
    bottom: 0;
    position: relative;
    width: 100%;
  }
 
  .navbar-home .logo-container-home {
    width: 160px;
    height: 100px;
  }
 
  .success-content,
  .content-home {
    margin: 0 100px;
  }
  .mb-cart-info {
    padding: 0 100px;
  }
  .mb-cart-info .cart {
    padding-left: 0;
  }
  .mb-cart-info .cart .count {
    position: absolute;
    top: 12px;
    left: 114px;
  }
  .navbar-mb-container.active {
    right: 0;
  }
  .footer {
    padding: 0 100px;
  }
}

/* Extra small devices Portrait */
@media (max-width: 575.98px) {
  a.brand-logo.login-page img {
    margin: 25px 0 !important;
  }
  .navbar-home {
    padding: 0 0 0 1.25rem;
    height: 60px;
  }
  .navbar-home .logo-container-home {
    width: 100px;
    height: 70px;
  }
  .navbar-home .navbar-nav {
    line-height: 60px;
  }
  .navbar-home .navbar-nav .nav-item {
    margin-right: 15px;
  }
  .navbar-home .navbar-nav .nav-item button {
    font-size: 15px;
  }
  .navbar-mb-menu-container {
    height: 60px;
  }
  .navbar-mb-more.show {
    top: 60px;
  }
  .mb-cart-info {
    padding: 1.25rem;
  }
  .mb-cart-info .cart .count {
    position: absolute;
    top: 12px;
    left: 35px;
  }
  .success-content,
  .content-home {
    margin: 0 1.25rem;
  }
  .footer {
    padding: 0 1.25rem;
  }
  .banner-front {
    padding: 1rem;
    text-align: center;
  }
  .banner-front form {
    width: 90%;
  }
}
p.text-white.font-size-16.about-feature-event {
  max-width: 45%;
}
.event-content {
  margin: 0 0 0 230px;
}
.fa-user-headset {
  width: 15px;
  height: 15px;
}
.select {
  background: transparent !important;
}
.event-header-home {
  min-height: auto;
  z-index: 0;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}
@font-face {
  font-family: chivofont;
  src: url(../../assets/font/chivofont/Chivo-Bold.ttf);
}
@font-face {
  font-family: chivoLight;
  src: url(../../assets/font/chivoLight/Chivo-Light.ttf);
}
i.fa.fa-search.text-dark {
  font-size: 16px !important;
}

.event-form-home {
  position: absolute;
  right: 0 !important;
  bottom: 0px;
  border: 0;
  width: 400px;
}

.event-body .event-list:first-child {
  margin-top: 100px;
}
.event-body .event-list {
  margin: 0 0 4rem 0;
}
.event-body .event-list .event-list-title {
  margin: 0 230px 30px 0;
  border-bottom: 1px solid #404040;
}
.event-body .event-list .event-owl.owl-carousel .event-card {
  background-color: #262626;
}

.card-p {
  line-height: 1.5 !important;
}
h5.text-white.font-weight-bold.mb-3 {
  font-size: 1.25rem !important;
}
/* Owl Carousel Navigation */
.event-owl.owl-theme .owl-nav {
  position: absolute;
  top: -80px;
  right: 12%;
}
.event-owl.owl-carousel .owl-nav button.owl-prev,
.event-owl.owl-carousel .owl-nav button.owl-next {
  padding: 0.5rem 1rem !important;
  width: 40px;
  height: 40px;
  border: 1px solid #404040 !important;
  border-radius: 0;
  margin: 0;
  outline: none !important;
}
.event-owl.owl-carousel .owl-nav button.owl-prev:hover,
.event-owl.owl-carousel .owl-nav button.owl-next:hover {
  background: #ef3e27 !important;
  transition: all 0.3s;
}
.owl-theme.event-owl .owl-nav [class*='owl-'] {
  background: none !important;
}
@media only screen and (max-width: 1025px) {
  .event-content {
    margin: 0 0 0 100px;
  }
  .event-body .event-list .event-list-title {
    margin: 0 100px 30px 0;
  }

  .event-header-home {
    /* flex-direction: column; */
    padding-top: 45px;
  }
  .event-form-home {
    position: relative;
    width: 100%;
  }
}

@media only screen and (min-width: 1280px) {
  .home-bg-wrapper {
    height: 500px !important;
  }
}
@media only screen and (max-width: 1280px) {
  .home-bg-wrapper {
    height: 400px !important;
  }
  .feature-event-title{
    font-size: 1.5rem !important;
    font-weight: bold;
    text-transform: uppercase;
  }
}

@media screen and (max-width: 1000px) and (min-width: 700px) {
  .home-bg-wrapper {
    height: 300px !important;
  }
  .feature-event-title{
    font-size: 1rem !important;
    font-weight: bold;
    text-transform: uppercase;
  }
}
@media only screen and (max-width: 1500px) {
  .event-owl.owl-theme .owl-nav {
    right: 20% !important;
  }
}
@media (max-width: 575.98px) {
  .event-content {
    margin: 0 0 0 1.25rem;
  }
  .event-body .event-list .event-list-title {
    margin-right: 1.25rem;
  }
  .event-owl.owl-theme .owl-nav {
    right: 10% !important;
  }
  .event-header-home {
    /* flex-direction: column; */
    padding-top: 40px;
  }
  .event-form-home {
    position: relative;
    width: 100%;
  }
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.content-home-inner img {
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}
body.home-bg {
  background: none;
}
.home-banner,
.discover-event,
.create-event {
  min-height: 600px;
}
.discover-event {
  background-size: 100% 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}
.discover-event a {
  border-radius: 0;
}
.create-event {
  background-size: 100% 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}
.create-event a {
  color: white;
}
.create-event a:hover {
  background-color: #fff;
  color: #ef3e27;
  transition: all 0.3s;
}
.content-slot-1 {
  margin: 50px 0;
  height: 400px;
  position: relative;
}
.content-slot-1 img {
  width: 145%;
}
.content-float-title {
  position: absolute;
  color: #f7f7f7;
  font-size: 150px;
  font-weight: 700;
  letter-spacing: -16px;
  bottom: -98px;
  left: -20px;
}
.content-faq {
  padding: 50px 230px;
  background-color: #f7f7f7;
}
.blog a {
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  color: #404040;
}
.blog a:hover {
  color: #ef3e27;
  transition: all 0.3s;
}
.testimonials .card {
  border-top: 2px solid #ef3e27;
}
.testimonials .card .card-footer {
  padding: 1.4rem 1rem;
}
.testimonials .card .card-footer a {
  color: #404040;
}
.testimonials .card .card-footer a:hover {
  color: #ef3e27 !important;
  transition: all 0.3s;
  text-decoration: none;
}
.testimonials .card .card-footer.dark {
  background-color: #000;
}
.testimonials .card .card-footer img {
  width: 45px;
  height: 45px;
}
.testimonials .card .card-footer .location {
  color: #808080;
}
.owl-theme.event-owl .owl-dots .owl-dot.active span,
.owl-theme.event-owl .owl-dots .owl-dot:hover span {
  background: #ef3e27 !important;
}
.featured {
  padding-bottom: 100px;
  position: relative;
  bottom: 0;
}
.content-contact {
  padding: 0 230px;
  margin-top: 50px;
  background: linear-gradient(90deg, #ffffff 10.4%, #f4f4f4 10.4%) no-repeat;
  background-size: 100% 70%;
}

@media only screen and (max-width: 1025px) {
  .cm-list-col {
    width: 25%;
    
  }
  .content {
    min-height: 100%;
  }
  .content-slot-1 {
    height: 100%;
  }
  .content-slot-1 img {
    width: 100%;
    height: auto;
  }
  .content-faq {
    padding: 50px 100px;
  }
  .content-contact {
    padding: 0 100px;
  }
}

@media (max-width: 575.98px) {
  .content {
    min-height: 100%;
  }
  .content-slot-1 {
    height: 100%;
  }
  .create-event,
  .discover-event {
    padding: 0 1rem;
  }
  .content-slot-1 img {
    width: 100%;
    height: auto;
  }
  .content-faq {
    padding: 1.25rem;
  }
  .content-contact {
    margin-top: 0;
    padding: 0 1.25rem;
  }
  .featured {
    padding-bottom: 50px;
  }
}

.btn-event {
  letter-spacing: 1.2px;
  padding: 0.7rem 1.6rem;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
}
.btn-event:hover {
  color: #404040;
}
.text-brand {
  color: #ef3e27 !important;
}

.btn.btn-brand.btn-event.text-white {
  background: #ef3e27;
}
.btn.btn-brand.btn-event.text-white:hover {
  background: red;
}
.event-owl.owl-carousel .owl-nav button.owl-next,
.event-owl.owl-carousel .owl-nav button.owl-prev,
.event-owl.owl-carousel button.owl-dot {
  background: 0 0;
  color: inherit;
  color: #fff;
  border: none;
  padding: 0 !important;
  font: inherit;
}
/* .content-wrapper-home {
    overflow: hidden;
    min-height: 100vh;
} */
.owl-theme .owl-nav [class*='owl-'] {
  color: #fff;
  font-size: 14px;
  margin: 0px;

  padding: 11px 7px !important;
  background: #d6d6d6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}
.owl-theme .owl-nav [class*='owl-']:hover {
  background: #ef3e27 !important;
  color: #fff;
  text-decoration: none;
}

label.home-form {
  color: #737373;
  font-size: 15px;
}
.home-form .form-control:focus {
  box-shadow: none;
  border-color: #ef3e27;
  background-color: transparent;
}

.home-form .form-control {
  border-radius: 0;
  box-shadow: none;
  padding: 0.53125rem 0.8125rem;
  font-size: 14px !important;
  border-width: 0 0 1px 0;
  background-color: transparent;
}

.btn-brand {
  background-color: #ef3e27;
  color: #fff;
  border-radius: 0px;
}
.menu {
  font-size: 13px !important;
}

.btn-back {
  border-color: #dddddd !important;
}
.modal-dialog.modal-lg {
  max-width: 700px !important;
  width: auto;
  border-radius: none;
  background: #fff;
}

.mr-1px {
  margin-right: 1px !important;
}
.mt-1px {
  margin-top: 0.5px !important;
}
.btn-gray {
  background: #3a3a3a;
  position: relative;
}
.btn-gray:after {
  content: '';
  position: absolute;
  height: 2px;
  width: 16px;
  background: #ef3e27;
  top: 47%;
  right: 0;
}
span.CartItem-count.bg-white {
  color: #ef3e27;
  cursor: pointer;
}
.CartItem-count svg {
  height: auto;
}
@media (max-width: 575.98px) {
  .content-event-cart {
    min-height: 100%;
    margin: 0 20px !important;
  }
}
.invoice.my-ticket .invoice-body table tbody td {
  font-weight: 500;
  color: #88939d;
  font-size: 14px;
  line-height: 3.5;
}

p.mb-3.card-p {
  font-size: 1rem;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.event-card-content{
 max-height: 200px;

}
.home-gray-bg {
  background: #1c1c1c;
}
.card-new-header {
  padding: 1.1rem 1.25rem;
}
.uplodeImgContainer-cutsom {
  width: 200px;
  vertical-align: top;
}
.uplodeImgContainer-custom {
  display: block;
  vertical-align: top;
}
.ql-editor {
  min-height: 70px;
}
.ql-editor strong {
  color: #000;
  font-weight: bolder !important;
}
@media screen and (max-width: 991px) {

  .widget-20 .widget-20-header {
    display: block;
  }
  .home-banner-main .overlay-bg-home {
    /* height: 100%; */
  }
  .home-banner-main .event-header-home {
    min-height: auto;
    padding-bottom: 40px;
  }
  .home-banner-main .event-list-bdy {
    background-size: cover;
  }
  .home-banner-main {
    position: relative;
  }
}
@media screen and (max-width: 479px) {
  .event-owl.owl-carousel .event-card {
    background-color: #262626;
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  .home-page .event-owl.owl-carousel .event-card {
    background-color: #262626;
    margin-left: 0px !important;
    margin-right: 20px !important;
  }
}
@media screen and (max-width: 767px) {
  .popup-youtube i {
    font-size: 55px;
  }
  .content .content-title {
    font-weight: 600;
    font-size: 25px;
    text-transform: uppercase;
    padding: 30px 0;
    margin: 0;
    text-align: center;
    color: #404040;
  }
  h4.mt-2.price {
    font-size: 17px;
  }
  .name-ticket {
    color: #404040;
    font-size: 1.22rem;
  }

  .widget-20 .widget-20-header {
    display: flex;
  }
  h4.text-white.text-uppercase.mb-md-4 {
    font-size: 14px;
  }
  h1.text-uppercase.text-white.font-weight-bold {
    font-size: 1.25rem;
  }
  .font-size-16,
  p.mb-3.card-p {
    font-size: 14px !important;
  }
  .home-gray-bg .event-list {
    margin-top: 40px !important;
  }
  h3.text-white.text-uppercase.font-weight-bold.pb-3.event-list-title {
    font-size: 1.1rem !important;
  }
  h5.text-white.font-weight-bold.mb-3 {
    font-size: 1rem !important;
  }
  .footer-body img,
  a.brand-logo.login-page img {
    max-width: 120px;
  }
  .creat-event-form .dstepper-block {
    margin: 0px !important;
  }
  .bs-stepper-content {
    padding: 12px !important;
  }
  .bs-stepper-content h3 {
    font-size: 1.25rem;
  }
  .dashboard-owl .owl-prev,
  .dashboard-owl .owl-next {
    background: none !important;
  }
  .event-list .event-item .event-schedule .event-day {
    font-size: 40px;
  }
  .event-list .event-item .event-schedule .event-month-time {
    font-size: 15px;
  }
  .order-select .ant-select {
    width: 100% !important;
  }
  .uplodeImgContainer-cutsom {
    width: 120px;
    height: auto;
    margin-bottom: 10px;
    vertical-align: top;
  }
  .uplodeImgContainer-cutsom .uplodeImg {
    height: auto;
  }
}

@media screen and (max-width: 575px) {

  .content-event {
    margin: 0px 10px !important;
    min-height: calc(100vh - 80px - 320px);
  }
  .event-owl.owl-carousel .event-card {
    background-color: #262626;
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  .content,
  .success-content {
    margin: 0px 25px !important;
  }
  /* p.text-white.font-size-16.about-feature-event {
    max-width: 88%;
  } */
  .step.col-lg-3.col-sm-6.mb-3.d-flex.justify-content-center .step-trigger {
    display: inline-block;
  }
  input.form-control.search-box {
    float: none;
    margin-top: 10px;
  }
  .btn-event {
    letter-spacing: 1.2px;
    padding: 0.5rem 1.2rem;
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
  }
  .font-size-12 {
    font-size: 11px !important;
  }
  .font-size-20 {
    font-size: 18px !important;
  }
  .event-price-container {
    height: 52px;
  }
  a.btn.btn-block.btn-lg.btn-brand.pt-3.pb-3.pl-5.pr-5.font-weight-bold {
    font-size: 18px;
  }
  h4.font-weight-bold.text-capitalize {
    font-size: 1.2rem !important;
    color: #404040;
  }
  h3.text-uppercase {
    font-size: 1.5rem !important ;
  }
  .btn,
  .wizard > .actions a,
  .fc button {
    font-size: 0.8125rem;
    line-height: 1rem !important;
    font-weight: 700;
  }
  .btn-lg,
  .btn-group-lg > .btn,
  .wizard > .actions .btn-group-lg > a,
  .fc .btn-group-lg > button {
    padding: -0.125rem 0.3125rem;
  }
  .map-container-div {
    height: 540px !important;
  }
 
  nav.pagination-nav {
    text-align: center;
    width: 265px;
  }
  .tab-content {
    padding: 0.5rem !important;
  }
}
@media screen and (max-width: 1440px) {
  .cm-list-col {
    width: 25%;
 
  }
  .update-feed-image {
    height: 195px;
    position: relative !important;
  }
}

@media screen and (max-width: 1200px) {
  .cm-list-col {
    width: 32%;
    
  }
}
@media screen and (max-width: 700px) {
  .cm-list-col {
    width: 50%;
  
  }
}
