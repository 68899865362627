.border-right {
  border-right: 1px solid #f9f9f9;
}
.pull-right {
  float: right !important;
}
.form-group label {
  font-weight: bold;
}
table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc_disabled:before {
  top: 23%;
}
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_desc_disabled:after {
  top: 23%;
}
.clearfix {
  clear: both;
}
a.brand-logo.login-page img {
  margin: 40px 0;
  width: 200px;
}
.user-login.login-v2 .user-login-content .form-control {
  border-radius: 0.25rem;
}

/* Helpers */
.gap {
  clear: both;
  height: 40px;
}
.gap-50 {
  clear: both;
  height: 50px;
}
.gap-60 {
  clear: both;
  height: 50px;
}
.gap-75 {
  clear: both;
  height: 75px;
}
.gap-100 {
  clear: both;
  height: 100px;
}
.gap-30 {
  clear: both;
  height: 30px;
}
.gap-35 {
  clear: both;
  height: 35px;
}
.gap-33 {
  clear: both;
  height: 33px;
}
.gap-45 {
  clear: both;
  height: 45px;
}
.gap-40 {
  clear: both;
  height: 40px;
}
.gap-25 {
  clear: both;
  height: 25px;
}
.gap-20 {
  clear: both;
  height: 20px;
}
.gap-10 {
  clear: both;
  height: 10px;
}
.gap-clear {
  clear: both;
  height: 10px;
}

m-0 {
  margin: 0;
}

.m-t-0 {
  margin-top: 0;
}

.m-b-0 {
  margin-bottom: 0;
}

.m-r-0 {
  margin-right: 0;
}

.m-l-0 {
  margin-left: 0 !important;
}
.m-l-2 {
  margin-left: 2px !important;
}

.m-v-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.m-h-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.m-5 {
  margin: 5px !important;
}

.m-10 {
  margin: 10px !important;
}

.m-15 {
  margin: 15px !important;
}

.m-20 {
  margin: 20px !important;
}

.m-25 {
  margin: 25px !important;
}

.m-20 {
  margin: 20px !important;
}

.m-25 {
  margin: 25px !important;
}

.m-30 {
  margin: 30px !important;
}

.m-35 {
  margin: 35px !important;
}

.m-40 {
  margin: 40px !important;
}

.m-45 {
  margin: 45px !important;
}

.m-50 {
  margin: 50px !important;
}

.m-55 {
  margin: 55px !important;
}

.m-60 {
  margin: 60px !important;
}

.m-65 {
  margin: 65px !important;
}

.m-70 {
  margin: 70px !important;
}

.m-75 {
  margin: 75px !important;
}

.m-80 {
  margin: 80px !important;
}

.m-85 {
  margin: 85px !important;
}

.m-90 {
  margin: 90px !important;
}

.m-95 {
  margin: 95px !important;
}

.m-100 {
  margin: 100px !important;
}

.m-105 {
  margin: 105px !important;
}

.m-110 {
  margin: 110px !important;
}

.m-115 {
  margin: 115px !important;
}

.m-120 {
  margin: 120px !important;
}

.m-125 {
  margin: 125px !important;
}

.m-130 {
  margin: 130px !important;
}

.m-135 {
  margin: 135px !important;
}

.m-140 {
  margin: 140px !important;
}

.m-145 {
  margin: 145px !important;
}

.m-150 {
  margin: 150px !important;
}

@media only screen and (max-width: 767px) {
  .m-150,
  .m-145,
  .m-140,
  .m-135,
  .m-130,
  .m-125,
  .m-120,
  .m-115,
  .m-110,
  .m-100,
  .m-95,
  .m-90,
  .m-85,
  .m-80,
  .m-75,
  .m-70,
  .m-65,
  .m-60,
  .m-55,
  .m-50,
  .m-45,
  .m-40,
  .m-35 {
    margin: 30px !important;
  }
}

.m-h-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.m-l-auto {
  margin-left: auto !important;
}

.m-r-auto {
  margin-right: auto !important;
}

.m-v-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.m-v-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.m-v-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.m-v-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.m-v-25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.m-v-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.m-v-35 {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}

.m-v-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.m-v-45 {
  margin-top: 45px !important;
  margin-bottom: 45px !important;
}

.m-v-50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.m-v-55 {
  margin-top: 55px !important;
  margin-bottom: 55px !important;
}

.m-v-60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

.m-v-65 {
  margin-top: 65px !important;
  margin-bottom: 65px !important;
}

.m-v-70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important;
}

.m-v-75 {
  margin-top: 75px !important;
  margin-bottom: 75px !important;
}

.m-v-80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

.m-v-85 {
  margin-top: 85px !important;
  margin-bottom: 85px !important;
}

.m-v-90 {
  margin-top: 90px !important;
  margin-bottom: 90px !important;
}

.m-v-95 {
  margin-top: 95px !important;
  margin-bottom: 95px !important;
}

.m-v-100 {
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}

.m-v-105 {
  margin-top: 105px !important;
  margin-bottom: 105px !important;
}

.m-v-110 {
  margin-top: 110px !important;
  margin-bottom: 110px !important;
}

.m-v-115 {
  margin-top: 115px !important;
  margin-bottom: 115px !important;
}

.m-v-120 {
  margin-top: 120px !important;
  margin-bottom: 120px !important;
}

.m-v-125 {
  margin-top: 125px !important;
  margin-bottom: 125px !important;
}

.m-v-130 {
  margin-top: 130px !important;
  margin-bottom: 130px !important;
}

.m-v-135 {
  margin-top: 135px !important;
  margin-bottom: 135px !important;
}

.m-v-140 {
  margin-top: 140px !important;
  margin-bottom: 140px !important;
}

.m-v-145 {
  margin-top: 145px !important;
  margin-bottom: 145px !important;
}

.m-v-150 {
  margin-top: 150px !important;
  margin-bottom: 150px !important;
}

@media only screen and (max-width: 767px) {
  .m-v-150,
  .m-v-145,
  .m-v-140,
  .m-v-135,
  .m-v-130,
  .m-v-125,
  .m-v-120,
  .m-v-115,
  .m-v-110,
  .m-v-100,
  .m-v-95,
  .m-v-90,
  .m-v-85,
  .m-v-80,
  .m-v-75,
  .m-v-70,
  .m-v-65,
  .m-v-60,
  .m-v-55,
  .m-v-50,
  .m-v-45,
  .m-v-40,
  .m-v-35 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
}

.m-h-5 {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.m-h-10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.m-h-15 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.m-h-20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.m-h-25 {
  margin-left: 25px !important;
  margin-right: 25px !important;
}

.m-h-30 {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.m-h-35 {
  margin-left: 35px !important;
  margin-right: 35px !important;
}

.m-h-40 {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

.m-h-45 {
  margin-left: 45px !important;
  margin-right: 45px !important;
}

.m-h-50 {
  margin-left: 50px !important;
  margin-right: 50px !important;
}

.m-h-55 {
  margin-left: 55px !important;
  margin-right: 55px !important;
}

.m-h-60 {
  margin-left: 60px !important;
  margin-right: 60px !important;
}

.m-h-65 {
  margin-left: 65px !important;
  margin-right: 65px !important;
}

.m-h-70 {
  margin-left: 70px !important;
  margin-right: 70px !important;
}

.m-h-75 {
  margin-left: 75px !important;
  margin-right: 75px !important;
}

.m-h-80 {
  margin-left: 80px !important;
  margin-right: 80px !important;
}

.m-h-85 {
  margin-left: 85px !important;
  margin-right: 85px !important;
}

.m-h-90 {
  margin-left: 90px !important;
  margin-right: 90px !important;
}

.m-h-95 {
  margin-left: 95px !important;
  margin-right: 95px !important;
}

.m-h-100 {
  margin-left: 100px !important;
  margin-right: 100px !important;
}

.m-h-105 {
  margin-left: 105px !important;
  margin-right: 105px !important;
}

.m-h-110 {
  margin-left: 110px !important;
  margin-right: 110px !important;
}

.m-h-115 {
  margin-left: 115px !important;
  margin-right: 115px !important;
}

.m-h-120 {
  margin-left: 120px !important;
  margin-right: 120px !important;
}

.m-h-125 {
  margin-left: 125px !important;
  margin-right: 125px !important;
}

.m-h-130 {
  margin-left: 130px !important;
  margin-right: 130px !important;
}

.m-h-135 {
  margin-left: 135px !important;
  margin-right: 135px !important;
}

.m-h-140 {
  margin-left: 140px !important;
  margin-right: 140px !important;
}

.m-h-145 {
  margin-left: 145px !important;
  margin-right: 145px !important;
}

.m-h-150 {
  margin-left: 150px !important;
  margin-right: 150px !important;
}

@media only screen and (max-width: 767px) {
  .m-h-150,
  .m-h-145,
  .m-h-140,
  .m-h-135,
  .m-h-130,
  .m-h-125,
  .m-h-120,
  .m-h-115,
  .m-h-110,
  .m-h-100,
  .m-h-95,
  .m-h-90,
  .m-h-85,
  .m-h-80,
  .m-h-75,
  .m-h-70,
  .m-h-65,
  .m-h-60,
  .m-h-55,
  .m-h-50,
  .m-h-45,
  .m-h-40,
  .m-h-35 {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
}

.m-t-5 {
  margin-top: 5px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-t-25 {
  margin-top: 25px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.m-t-35 {
  margin-top: 35px !important;
}

.m-t-40 {
  margin-top: 40px !important;
}

.m-t-45 {
  margin-top: 45px !important;
}

.m-t-50 {
  margin-top: 50px !important;
}

.m-t-55 {
  margin-top: 55px !important;
}

.m-t-60 {
  margin-top: 60px !important;
}

.m-t-65 {
  margin-top: 65px !important;
}

.m-t-70 {
  margin-top: 70px !important;
}

.m-t-75 {
  margin-top: 75px !important;
}

.m-t-80 {
  margin-top: 80px !important;
}

.m-t-85 {
  margin-top: 85px !important;
}

.m-t-90 {
  margin-top: 90px !important;
}

.m-t-95 {
  margin-top: 95px !important;
}

.m-t-100 {
  margin-top: 100px !important;
}

.m-t-105 {
  margin-top: 105px !important;
}

.m-t-110 {
  margin-top: 110px !important;
}

.m-t-115 {
  margin-top: 115px !important;
}

.m-t-120 {
  margin-top: 120px !important;
}

.m-t-125 {
  margin-top: 125px !important;
}

.m-t-130 {
  margin-top: 130px !important;
}

.m-t-135 {
  margin-top: 135px !important;
}

.m-t-140 {
  margin-top: 140px !important;
}

.m-t-145 {
  margin-top: 145px !important;
}

.m-t-150 {
  margin-top: 150px !important;
}

@media only screen and (max-width: 767px) {
  .m-t-150,
  .m-t-145,
  .m-t-140,
  .m-t-135,
  .m-t-130,
  .m-t-125,
  .m-t-120,
  .m-t-115,
  .m-t-110,
  .m-t-100,
  .m-t-95,
  .m-t-90,
  .m-t-85,
  .m-t-80,
  .m-t-75,
  .m-t-70,
  .m-t-65,
  .m-t-60,
  .m-t-55,
  .m-t-50,
  .m-t-45,
  .m-t-40,
  .m-t-35 {
    margin-top: 30px !important;
  }
}

.m-b-5 {
  margin-bottom: 5px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-b-25 {
  margin-bottom: 25px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.m-b-35 {
  margin-bottom: 35px !important;
}

.m-b-40 {
  margin-bottom: 40px !important;
}

.m-b-45 {
  margin-bottom: 45px !important;
}

.m-b-50 {
  margin-bottom: 50px !important;
}

.m-b-55 {
  margin-bottom: 55px !important;
}

.m-b-60 {
  margin-bottom: 60px !important;
}

.m-b-65 {
  margin-bottom: 65px !important;
}

.m-b-70 {
  margin-bottom: 70px !important;
}

.m-b-75 {
  margin-bottom: 75px !important;
}

.m-b-80 {
  margin-bottom: 80px !important;
}

.m-b-85 {
  margin-bottom: 85px !important;
}

.m-b-90 {
  margin-bottom: 90px !important;
}

.m-b-95 {
  margin-bottom: 95px !important;
}

.m-b-100 {
  margin-bottom: 100px !important;
}

.m-b-105 {
  margin-bottom: 105px !important;
}

.m-b-110 {
  margin-bottom: 110px !important;
}

.m-b-115 {
  margin-bottom: 115px !important;
}

.m-b-120 {
  margin-bottom: 120px !important;
}

.m-b-125 {
  margin-bottom: 125px !important;
}

.m-b-130 {
  margin-bottom: 130px !important;
}

.m-b-135 {
  margin-bottom: 135px !important;
}

.m-b-140 {
  margin-bottom: 140px !important;
}

.m-b-145 {
  margin-bottom: 145px !important;
}

.m-b-150 {
  margin-bottom: 150px !important;
}

@media only screen and (max-width: 767px) {
  .m-b-150,
  .m-b-145,
  .m-b-140,
  .m-b-135,
  .m-b-130,
  .m-b-125,
  .m-b-120,
  .m-b-115,
  .m-b-110,
  .m-b-100,
  .m-b-95,
  .m-b-90,
  .m-b-85,
  .m-b-80,
  .m-b-75,
  .m-b-70,
  .m-b-65,
  .m-b-60,
  .m-b-55,
  .m-b-50,
  .m-b-45,
  .m-b-40,
  .m-b-35 {
    margin-bottom: 30px !important;
  }
}

.m-l-5 {
  margin-left: 5px !important;
}

.m-l-10 {
  margin-left: 10px !important;
}

.m-l-15 {
  margin-left: 15px !important;
}

.m-l-20 {
  margin-left: 20px !important;
}

.m-l-25 {
  margin-left: 25px !important;
}

.m-l-30 {
  margin-left: 30px !important;
}

.m-l-35 {
  margin-left: 35px !important;
}

.m-l-40 {
  margin-left: 40px !important;
}

.m-l-45 {
  margin-left: 45px !important;
}

.m-l-50 {
  margin-left: 50px !important;
}

.m-l-55 {
  margin-left: 55px !important;
}

.m-l-60 {
  margin-left: 60px !important;
}

.m-l-65 {
  margin-left: 65px !important;
}

.m-l-70 {
  margin-left: 70px !important;
}

.m-l-75 {
  margin-left: 75px !important;
}

.m-l-80 {
  margin-left: 80px !important;
}

.m-l-85 {
  margin-left: 85px !important;
}

.m-l-90 {
  margin-left: 90px !important;
}

.m-l-95 {
  margin-left: 95px !important;
}

.m-l-100 {
  margin-left: 100px !important;
}

.m-l-105 {
  margin-left: 105px !important;
}

.m-l-110 {
  margin-left: 110px !important;
}

.m-l-115 {
  margin-left: 115px !important;
}

.m-l-120 {
  margin-left: 120px !important;
}

.m-l-125 {
  margin-left: 125px !important;
}

.m-l-130 {
  margin-left: 130px !important;
}

.m-l-135 {
  margin-left: 135px !important;
}

.m-l-140 {
  margin-left: 140px !important;
}

.m-l-145 {
  margin-left: 145px !important;
}

.m-l-150 {
  margin-left: 150px !important;
}

@media only screen and (max-width: 767px) {
  .m-l-150,
  .m-l-145,
  .m-l-140,
  .m-l-135,
  .m-l-130,
  .m-l-125,
  .m-l-120,
  .m-l-115,
  .m-l-110,
  .m-l-100,
  .m-l-95,
  .m-l-90,
  .m-l-85,
  .m-l-80,
  .m-l-75,
  .m-l-70,
  .m-l-65,
  .m-l-60,
  .m-l-55,
  .m-l-50,
  .m-l-45,
  .m-l-40,
  .m-l-35 {
    margin-left: 30px !important;
  }
}

.m-r-5 {
  margin-right: 5px !important;
}

.m-r-10 {
  margin-right: 10px !important;
}

.m-r-15 {
  margin-right: 15px !important;
}

.m-r-20 {
  margin-right: 20px !important;
}

.m-r-25 {
  margin-right: 25px !important;
}

.m-r-30 {
  margin-right: 30px !important;
}

.m-r-35 {
  margin-right: 35px !important;
}

.m-r-40 {
  margin-right: 40px !important;
}

.m-r-45 {
  margin-right: 45px !important;
}

.m-r-50 {
  margin-right: 50px !important;
}

.m-r-55 {
  margin-right: 55px !important;
}

.m-r-60 {
  margin-right: 60px !important;
}

.m-r-65 {
  margin-right: 65px !important;
}

.m-r-70 {
  margin-right: 70px !important;
}

.m-r-75 {
  margin-right: 75px !important;
}

.m-r-80 {
  margin-right: 80px !important;
}

.m-r-85 {
  margin-right: 85px !important;
}

.m-r-90 {
  margin-right: 90px !important;
}

.m-r-95 {
  margin-right: 95px !important;
}

.m-r-100 {
  margin-right: 100px !important;
}

.m-r-105 {
  margin-right: 105px !important;
}

.m-r-110 {
  margin-right: 110px !important;
}

.m-r-115 {
  margin-right: 115px !important;
}

.m-r-120 {
  margin-right: 120px !important;
}

.m-r-125 {
  margin-right: 125px !important;
}

.m-r-130 {
  margin-right: 130px !important;
}

.m-r-135 {
  margin-right: 135px !important;
}

.m-r-140 {
  margin-right: 140px !important;
}

.m-r-145 {
  margin-right: 145px !important;
}

.m-r-150 {
  margin-right: 150px !important;
}

@media only screen and (max-width: 767px) {
  .m-r-150,
  .m-r-145,
  .m-r-140,
  .m-r-135,
  .m-r-130,
  .m-r-125,
  .m-r-120,
  .m-r-115,
  .m-r-110,
  .m-r-100,
  .m-r-95,
  .m-r-90,
  .m-r-85,
  .m-r-80,
  .m-r-75,
  .m-r-70,
  .m-r-65,
  .m-r-60,
  .m-r-55,
  .m-r-50,
  .m-r-45,
  .m-r-40,
  .m-r-35 {
    margin-right: 30px !important;
  }
}

.p-0 {
  padding: 0 !important;
}

.p-t-0 {
  padding-top: 0 !important;
}

.p-b-0 {
  padding-bottom: 0 !important;
}

.p-l-0 {
  padding-left: 0 !important;
}

.p-r-0 {
  padding-right: 0 !important;
}

.p-v-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.p-h-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.p-5 {
  padding: 5px !important;
}

.p-10 {
  padding: 10px !important;
}

.p-15 {
  padding: 15px !important;
}

.p-20 {
  padding: 20px !important;
}

.p-25 {
  padding: 25px !important;
}

.p-30 {
  padding: 30px !important;
}

.p-35 {
  padding: 35px !important;
}

.p-40 {
  padding: 40px !important;
}

.p-45 {
  padding: 45px !important;
}

.p-50 {
  padding: 50px !important;
}

.p-55 {
  padding: 55px !important;
}

.p-60 {
  padding: 60px !important;
}

.p-65 {
  padding: 65px !important;
}

.p-70 {
  padding: 70px !important;
}

.p-75 {
  padding: 75px !important;
}

.p-80 {
  padding: 80px !important;
}

.p-85 {
  padding: 85px !important;
}

.p-90 {
  padding: 90px !important;
}

.p-95 {
  padding: 95px !important;
}

.p-100 {
  padding: 100px !important;
}

.p-105 {
  padding: 105px !important;
}

.p-110 {
  padding: 110px !important;
}

.p-115 {
  padding: 115px !important;
}

.p-120 {
  padding: 120px !important;
}

.p-125 {
  padding: 125px !important;
}

.p-130 {
  padding: 130px !important;
}

.p-135 {
  padding: 135px !important;
}

.p-140 {
  padding: 140px !important;
}

.p-145 {
  padding: 145px !important;
}

.p-150 {
  padding: 150px !important;
}

@media only screen and (max-width: 767px) {
  .p-150,
  .p-145,
  .p-140,
  .p-135,
  .p-130,
  .p-125,
  .p-120,
  .p-115,
  .p-110,
  .p-100,
  .p-95,
  .p-90,
  .p-85,
  .p-80,
  .p-75,
  .p-70,
  .p-65,
  .p-60,
  .p-55,
  .p-50,
  .p-45,
  .p-40,
  .p-35 {
    padding: 30px !important;
  }
}

.p-v-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.p-v-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.p-v-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.p-v-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.p-v-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.p-v-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.p-v-35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}

.p-v-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.p-v-45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}

.p-v-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.p-v-55 {
  padding-top: 55px !important;
  padding-bottom: 55px !important;
}

.p-v-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.p-v-65 {
  padding-top: 65px !important;
  padding-bottom: 65px !important;
}

.p-v-70 {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}

.p-v-75 {
  padding-top: 75px !important;
  padding-bottom: 75px !important;
}

.p-v-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.p-v-85 {
  padding-top: 85px !important;
  padding-bottom: 85px !important;
}

.p-v-90 {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
}

.p-v-95 {
  padding-top: 95px !important;
  padding-bottom: 95px !important;
}

.p-v-100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

.p-v-105 {
  padding-top: 105px !important;
  padding-bottom: 105px !important;
}

.p-v-110 {
  padding-top: 110px !important;
  padding-bottom: 110px !important;
}

.p-v-115 {
  padding-top: 115px !important;
  padding-bottom: 115px !important;
}

.p-v-120 {
  padding-top: 120px !important;
  padding-bottom: 120px !important;
}

.p-v-125 {
  padding-top: 125px !important;
  padding-bottom: 125px !important;
}

.p-v-130 {
  padding-top: 130px !important;
  padding-bottom: 130px !important;
}

.p-v-135 {
  padding-top: 135px !important;
  padding-bottom: 135px !important;
}

.p-v-140 {
  padding-top: 140px !important;
  padding-bottom: 140px !important;
}

.p-v-145 {
  padding-top: 145px !important;
  padding-bottom: 145px !important;
}

.p-v-150 {
  padding-top: 150px !important;
  padding-bottom: 150px !important;
}

@media only screen and (max-width: 767px) {
  .p-v-150,
  .p-v-145,
  .p-v-140,
  .p-v-135,
  .p-v-130,
  .p-v-125,
  .p-v-120,
  .p-v-115,
  .p-v-110,
  .p-v-100,
  .p-v-95,
  .p-v-90,
  .p-v-85,
  .p-v-80,
  .p-v-75,
  .p-v-70,
  .p-v-65,
  .p-v-60,
  .p-v-55,
  .p-v-50,
  .p-v-45,
  .p-v-40,
  .p-v-35 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
}

.p-h-5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.p-h-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.p-h-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.p-h-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.p-h-25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.p-h-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.p-h-35 {
  padding-left: 35px !important;
  padding-right: 35px !important;
}

.p-h-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.p-h-45 {
  padding-left: 45px !important;
  padding-right: 45px !important;
}

.p-h-50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.p-h-55 {
  padding-left: 55px !important;
  padding-right: 55px !important;
}

.p-h-60 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.p-h-65 {
  padding-left: 65px !important;
  padding-right: 65px !important;
}

.p-h-70 {
  padding-left: 70px !important;
  padding-right: 70px !important;
}

.p-h-75 {
  padding-left: 75px !important;
  padding-right: 75px !important;
}

.p-h-80 {
  padding-left: 80px !important;
  padding-right: 80px !important;
}

.p-h-85 {
  padding-left: 85px !important;
  padding-right: 85px !important;
}

.p-h-90 {
  padding-left: 90px !important;
  padding-right: 90px !important;
}

.p-h-95 {
  padding-left: 95px !important;
  padding-right: 95px !important;
}

.p-h-100 {
  padding-left: 100px !important;
  padding-right: 100px !important;
}

.p-h-105 {
  padding-left: 105px !important;
  padding-right: 105px !important;
}

.p-h-110 {
  padding-left: 110px !important;
  padding-right: 110px !important;
}

.p-h-115 {
  padding-left: 115px !important;
  padding-right: 115px !important;
}

.p-h-120 {
  padding-left: 120px !important;
  padding-right: 120px !important;
}

.p-h-125 {
  padding-left: 125px !important;
  padding-right: 125px !important;
}

.p-h-130 {
  padding-left: 130px !important;
  padding-right: 130px !important;
}

.p-h-135 {
  padding-left: 135px !important;
  padding-right: 135px !important;
}

.p-h-140 {
  padding-left: 140px !important;
  padding-right: 140px !important;
}

.p-h-145 {
  padding-left: 145px !important;
  padding-right: 145px !important;
}

.p-h-150 {
  padding-left: 150px !important;
  padding-right: 150px !important;
}

@media only screen and (max-width: 767px) {
  .p-h-150,
  .p-h-145,
  .p-h-140,
  .p-h-135,
  .p-h-130,
  .p-h-125,
  .p-h-120,
  .p-h-115,
  .p-h-110,
  .p-h-100,
  .p-h-95,
  .p-h-90,
  .p-h-85,
  .p-h-80,
  .p-h-75,
  .p-h-70,
  .p-h-65,
  .p-h-60,
  .p-h-55,
  .p-h-50,
  .p-h-45,
  .p-h-40,
  .p-h-35 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}
.p-t-4 {
  padding-top: 4px !important;
}
.p-t-5 {
  padding-top: 5px !important;
}

.p-t-10 {
  padding-top: 10px !important;
}

.p-t-15 {
  padding-top: 15px !important;
}

.p-t-20 {
  padding-top: 20px !important;
}

.p-t-25 {
  padding-top: 25px !important;
}

.p-t-30 {
  padding-top: 30px !important;
}

.p-t-35 {
  padding-top: 35px !important;
}

.p-t-40 {
  padding-top: 40px !important;
}

.p-t-45 {
  padding-top: 45px !important;
}

.p-t-50 {
  padding-top: 50px !important;
}

.p-t-55 {
  margin-top: 55px !important;
}

.p-t-60 {
  padding-top: 60px !important;
}

.p-t-65 {
  padding-top: 65px !important;
}

.p-t-70 {
  padding-top: 70px !important;
}

.p-t-75 {
  padding-top: 75px !important;
}

.p-t-80 {
  padding-top: 80px !important;
}

.p-t-85 {
  padding-top: 85px !important;
}

.p-t-90 {
  padding-top: 90px !important;
}

.p-t-95 {
  padding-top: 95px !important;
}

.p-t-100 {
  padding-top: 100px !important;
}

.p-t-105 {
  padding-top: 105px !important;
}

.p-t-110 {
  padding-top: 110px !important;
}

.p-t-115 {
  padding-top: 115px !important;
}

.p-t-120 {
  padding-top: 120px !important;
}

.p-t-125 {
  padding-top: 125px !important;
}

.p-t-130 {
  padding-top: 130px !important;
}

.p-t-135 {
  padding-top: 135px !important;
}

.p-t-140 {
  padding-top: 140px !important;
}

.p-t-145 {
  padding-top: 145px !important;
}

.p-t-150 {
  padding-top: 150px !important;
}

@media only screen and (max-width: 767px) {
  .p-t-150,
  .p-t-145,
  .p-t-140,
  .p-t-135,
  .p-t-130,
  .p-t-125,
  .p-t-120,
  .p-t-115,
  .p-t-110,
  .p-t-100,
  .p-t-95,
  .p-t-90,
  .p-t-85,
  .p-t-80,
  .p-t-75,
  .p-t-70,
  .p-t-65,
  .p-t-60,
  .p-t-55,
  .p-t-50,
  .p-t-45,
  .p-t-40,
  .p-t-35 {
    padding-top: 30px !important;
  }
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.p-b-10 {
  padding-bottom: 10px !important;
}

.p-b-15 {
  padding-bottom: 15px !important;
}

.p-b-20 {
  padding-bottom: 20px !important;
}

.p-b-25 {
  padding-bottom: 25px !important;
}

.p-b-30 {
  padding-bottom: 30px !important;
}

.p-b-35 {
  padding-bottom: 35px !important;
}

.p-b-40 {
  padding-bottom: 40px !important;
}

.p-b-45 {
  padding-bottom: 45px !important;
}

.p-b-50 {
  padding-bottom: 50px !important;
}

.p-b-55 {
  margin-bottom: 55px !important;
}

.p-b-60 {
  padding-bottom: 60px !important;
}

.p-b-65 {
  padding-bottom: 65px !important;
}

.p-b-70 {
  padding-bottom: 70px !important;
}

.p-b-75 {
  padding-bottom: 75px !important;
}

.p-b-80 {
  padding-bottom: 80px !important;
}

.p-b-85 {
  padding-bottom: 85px !important;
}

.p-b-90 {
  padding-bottom: 90px !important;
}

.p-b-95 {
  padding-bottom: 95px !important;
}

.p-b-100 {
  padding-bottom: 100px !important;
}

.p-b-105 {
  padding-bottom: 105px !important;
}

.p-b-110 {
  padding-bottom: 110px !important;
}

.p-b-115 {
  padding-bottom: 115px !important;
}

.p-b-120 {
  margin-bottom: 120px !important;
}

.p-b-125 {
  padding-bottom: 125px !important;
}

.p-b-130 {
  padding-bottom: 130px !important;
}

.p-b-135 {
  padding-bottom: 135px !important;
}

.p-b-140 {
  padding-bottom: 140px !important;
}

.p-b-145 {
  padding-bottom: 145px !important;
}

.p-b-150 {
  padding-bottom: 150px !important;
}

@media only screen and (max-width: 767px) {
  .p-b-150,
  .p-b-145,
  .p-b-140,
  .p-b-135,
  .p-b-130,
  .p-b-125,
  .p-b-120,
  .p-b-115,
  .p-b-110,
  .p-b-100,
  .p-b-95,
  .p-b-90,
  .p-b-85,
  .p-b-80,
  .p-b-75,
  .p-b-70,
  .p-b-65,
  .p-b-60,
  .p-b-55,
  .p-b-50,
  .p-b-45,
  .p-b-40,
  .p-b-35 {
    padding-bottom: 30px !important;
  }
}

.p-l-5 {
  padding-left: 5px !important;
}

.p-l-10 {
  padding-left: 10px !important;
}

.p-l-15 {
  padding-left: 15px !important;
}

.p-l-20 {
  padding-left: 20px !important;
}

.p-l-25 {
  padding-left: 25px !important;
}

.p-l-30 {
  padding-left: 30px !important;
}

.p-l-35 {
  padding-left: 35px !important;
}

.p-l-40 {
  padding-left: 40px !important;
}

.p-l-45 {
  padding-left: 45px !important;
}

.p-l-50 {
  padding-left: 50px !important;
}

.p-l-55 {
  margin-left: 55px !important;
}

.p-l-60 {
  padding-left: 60px !important;
}

.p-l-65 {
  padding-left: 65px !important;
}

.p-l-70 {
  padding-left: 70px !important;
}

.p-l-75 {
  padding-left: 75px !important;
}

.p-l-80 {
  padding-left: 80px !important;
}

.p-l-85 {
  padding-left: 85px !important;
}

.p-l-90 {
  padding-left: 90px !important;
}

.p-l-95 {
  padding-left: 95px !important;
}

.p-l-100 {
  padding-left: 100px !important;
}

.p-l-105 {
  padding-left: 105px !important;
}

.p-l-110 {
  padding-left: 110px !important;
}

.p-l-115 {
  padding-left: 115px !important;
}

.p-l-120 {
  margin-left: 120px !important;
}

.p-l-125 {
  padding-left: 125px !important;
}

.p-l-130 {
  padding-left: 130px !important;
}

.p-l-135 {
  padding-left: 135px !important;
}

.p-l-140 {
  padding-left: 140px !important;
}

.p-l-145 {
  padding-left: 145px !important;
}

.p-l-150 {
  padding-left: 150px !important;
}

@media only screen and (max-width: 767px) {
  .p-l-150,
  .p-l-145,
  .p-l-140,
  .p-l-135,
  .p-l-130,
  .p-l-125,
  .p-l-120,
  .p-l-115,
  .p-l-110,
  .p-l-100,
  .p-l-95,
  .p-l-90,
  .p-l-85,
  .p-l-80,
  .p-l-75,
  .p-l-70,
  .p-l-65,
  .p-l-60,
  .p-l-55,
  .p-l-50,
  .p-l-45,
  .p-l-40,
  .p-l-35 {
    padding-left: 30px !important;
  }
}

.p-r-5 {
  padding-right: 5px !important;
}

.p-r-10 {
  padding-right: 10px !important;
}

.p-r-15 {
  padding-right: 15px !important;
}

.p-r-20 {
  padding-right: 20px !important;
}

.p-r-25 {
  padding-right: 25px !important;
}

.p-r-30 {
  padding-right: 30px !important;
}

.p-r-35 {
  padding-right: 35px !important;
}

.p-r-40 {
  padding-right: 40px !important;
}

.p-r-45 {
  padding-right: 45px !important;
}

.p-r-50 {
  padding-right: 50px !important;
}

.p-r-55 {
  margin-right: 55px !important;
}

.p-r-60 {
  padding-right: 60px !important;
}

.p-r-65 {
  padding-right: 65px !important;
}

.p-r-70 {
  padding-right: 70px !important;
}

.p-r-75 {
  padding-right: 75px !important;
}

.p-r-80 {
  padding-right: 80px !important;
}

.p-r-85 {
  padding-right: 85px !important;
}

.p-r-90 {
  padding-right: 90px !important;
}

.p-r-95 {
  padding-right: 95px !important;
}

.p-r-100 {
  padding-right: 100px !important;
}

.p-r-105 {
  padding-right: 105px !important;
}

.p-l-110 {
  padding-left: 110px !important;
}

.p-r-115 {
  padding-right: 115px !important;
}

.p-r-120 {
  margin-right: 120px !important;
}

.p-r-125 {
  padding-right: 125px !important;
}

.p-r-130 {
  padding-right: 130px !important;
}

.p-r-135 {
  padding-right: 135px !important;
}

.p-r-140 {
  padding-right: 140px !important;
}

.p-r-145 {
  padding-right: 145px !important;
}

.p-r-150 {
  padding-right: 150px !important;
}

@media only screen and (max-width: 767px) {
  .p-r-150,
  .p-r-145,
  .p-r-140,
  .p-r-135,
  .p-r-130,
  .p-r-125,
  .p-r-120,
  .p-r-115,
  .p-r-110,
  .p-r-100,
  .p-r-95,
  .p-r-90,
  .p-r-85,
  .p-r-80,
  .p-r-75,
  .p-r-70,
  .p-r-65,
  .p-r-60,
  .p-r-55,
  .p-r-50,
  .p-r-45,
  .p-r-40,
  .p-r-35 {
    padding-right: 30px !important;
  }
}

.w-0 {
  width: 0px;
}

.w-10 {
  width: 10%;
}

.w-15 {
  width: 15%;
}

.w-20 {
  width: 20%;
}

.w-25 {
  width: 25%;
}

.w-30 {
  width: 30%;
}

.w-35 {
  width: 35%;
}

.w-40 {
  width: 40%;
}

.w-45 {
  width: 45%;
}

.w-50 {
  width: 50%;
}

.w-55 {
  width: 55%;
}

.w-60 {
  width: 60%;
}

.w-65 {
  width: 65%;
}

.w-70 {
  width: 70%;
}

.w-75 {
  width: 75%;
}

.w-80 {
  width: 80%;
}

.w-85 {
  width: 85%;
}

.w-90 {
  width: 90%;
}

.w-95 {
  width: 95%;
}

.w-100 {
  width: 100%;
}

.height-max {
  height: 100%;
}

.full-height {
  min-height: 100vh;
}

.ls-0 {
  letter-spacing: 0px !important;
}

.ls-0-5 {
  letter-spacing: 0.5px !important;
}

.ls-1 {
  letter-spacing: 1px !important;
}

.ls-1-5 {
  letter-spacing: 1.5px !important;
}

.ls-2 {
  letter-spacing: 2px !important;
}

.ls-2-5 {
  letter-spacing: 2.5px !important;
}

.ls-3 {
  letter-spacing: 3px !important;
}

.ls-3-5 {
  letter-spacing: 3.5px !important;
}

.ls-4 {
  letter-spacing: 4px !important;
}

.ls-4-5 {
  letter-spacing: 4.5px !important;
}

.ls-5 {
  letter-spacing: 5px !important;
}

.ls-5-5 {
  letter-spacing: 5.5px !important;
}

.ls-6 {
  letter-spacing: 6px !important;
}

.ls-6-5 {
  letter-spacing: 6.5px !important;
}

.ls-7 {
  letter-spacing: 7px !important;
}

.ls-7-5 {
  letter-spacing: 7.5px !important;
}

.ls-8 {
  letter-spacing: 8px !important;
}

.ls-8-5 {
  letter-spacing: 8.5px !important;
}

.ls-9 {
  letter-spacing: 9px !important;
}

.ls-9-5 {
  letter-spacing: 9.5px !important;
}

.ls-10 {
  letter-spacing: 10px !important;
}

.ls-11 {
  letter-spacing: 11px !important;
}

.ls-12 {
  letter-spacing: 12px !important;
}

.ls-13 {
  letter-spacing: 13px !important;
}

.ls-14 {
  letter-spacing: 14px !important;
}

.ls-15 {
  letter-spacing: 15px !important;
}

.lh-0 {
  line-height: 0 !important;
}

.lh-0-5 {
  line-height: 0.5 !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-1-1 {
  line-height: 1.1 !important;
}

.lh-1-2 {
  line-height: 1.2 !important;
}

.lh-1-3 {
  line-height: 1.3 !important;
}

.lh-1-4 {
  line-height: 1.4 !important;
}

.lh-1-5 {
  line-height: 1.5 !important;
}

.lh-1-6 {
  line-height: 1.6 !important;
}

.lh-1-7 {
  line-height: 1.7 !important;
}

.lh-1-8 {
  line-height: 1.8 !important;
}

.lh-1-9 {
  line-height: 1.9 !important;
}

.lh-2 {
  line-height: 2 !important;
}

.lh-2-1 {
  line-height: 2.1 !important;
}

.lh-2-2 {
  line-height: 2.2 !important;
}

.lh-2-3 {
  line-height: 2.3 !important;
}

.lh-2-4 {
  line-height: 2.4 !important;
}

.lh-2-5 {
  line-height: 2.5 !important;
}

.lh-3 {
  line-height: 3 !important;
}

.lh-4 {
  line-height: 4 !important;
}

.font-size-8 {
  font-size: 8px !important;
}

.font-size-9 {
  font-size: 9px !important;
}

.font-size-10 {
  font-size: 10px !important;
}

.font-size-11 {
  font-size: 11px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-13 {
  font-size: 13px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-15 {
  font-size: 15px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-17 {
  font-size: 17px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-19 {
  font-size: 19px !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.font-size-21 {
  font-size: 21px !important;
}

.font-size-22 {
  font-size: 22px !important;
}

.font-size-23 {
  font-size: 23px !important;
}

.font-size-24 {
  font-size: 24px !important;
}

.font-size-25 {
  font-size: 25px !important;
}

.font-size-26 {
  font-size: 26px !important;
}

.font-size-27 {
  font-size: 27px !important;
}

.font-size-28 {
  font-size: 28px !important;
}

.font-size-29 {
  font-size: 29px !important;
}

.font-size-30 {
  font-size: 30px !important;
}

.font-size-35 {
  font-size: 35px !important;
}

.font-size-40 {
  font-size: 40px !important;
}

.font-size-45 {
  font-size: 45px !important;
}

.font-size-50 {
  font-size: 50px !important;
}

.font-size-55 {
  font-size: 55px !important;
}

.font-size-60 {
  font-size: 60px !important;
}

.font-size-65 {
  font-size: 65px !important;
}

.font-size-70 {
  font-size: 70px !important;
}

.font-size-75 {
  font-size: 75px !important;
}

.font-size-80 {
  font-size: 80px !important;
}

.font-size-85 {
  font-size: 85px !important;
}

.font-size-90 {
  font-size: 90px !important;
}

.font-size-95 {
  font-size: 95px !important;
}

.font-size-100 {
  font-size: 100px !important;
}

.font-size-105 {
  font-size: 105px !important;
}

.font-size-110 {
  font-size: 110px !important;
}

.font-size-115 {
  font-size: 115px !important;
}

.font-size-120 {
  font-size: 120px !important;
}

.font-size-125 {
  font-size: 125px !important;
}

.font-size-130 {
  font-size: 130px !important;
}

.font-size-135 {
  font-size: 135px !important;
}

.font-size-140 {
  font-size: 140px !important;
}

.font-size-145 {
  font-size: 145px !important;
}

.font-size-150 {
  font-size: 150px !important;
}

.font-size-155 {
  font-size: 155px !important;
}

.font-size-160 {
  font-size: 160px !important;
}

.font-size-165 {
  font-size: 165px !important;
}

.font-size-170 {
  font-size: 170px !important;
}

.font-size-175 {
  font-size: 175px !important;
}

.font-size-180 {
  font-size: 180px !important;
}

.font-size-185 {
  font-size: 185px !important;
}

.font-size-190 {
  font-size: 190px !important;
}

.font-size-195 {
  font-size: 195px !important;
}

.font-size-200 {
  font-size: 195px !important;
}

m-0 {
  margin: 0 !important;
}

.m-t-0 {
  margin-top: 0 !important;
}

.m-b-0 {
  margin-bottom: 0 !important;
}

.m-r-0 {
  margin-right: 0 !important;
}

.m-l-0 {
  margin-left: 0 !important;
}

.m-v-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.m-h-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.m-5 {
  margin: 5px !important;
}

.m-10 {
  margin: 10px !important;
}

.m-15 {
  margin: 15px !important;
}

.m-20 {
  margin: 20px !important;
}

.m-25 {
  margin: 25px !important;
}

.m-20 {
  margin: 20px !important;
}

.m-25 {
  margin: 25px !important;
}

.m-30 {
  margin: 30px !important;
}

.m-35 {
  margin: 35px !important;
}

.m-40 {
  margin: 40px !important;
}

.m-45 {
  margin: 45px !important;
}

.m-50 {
  margin: 50px !important;
}

.m-55 {
  margin: 55px !important;
}

.m-60 {
  margin: 60px !important;
}

.m-65 {
  margin: 65px !important;
}

.m-70 {
  margin: 70px !important;
}

.m-75 {
  margin: 75px !important;
}

.m-80 {
  margin: 80px !important;
}

.m-85 {
  margin: 85px !important;
}

.m-90 {
  margin: 90px !important;
}

.m-95 {
  margin: 95px !important;
}

.m-100 {
  margin: 100px !important;
}

.m-105 {
  margin: 105px !important;
}

.m-110 {
  margin: 110px !important;
}

.m-115 {
  margin: 115px !important;
}

.m-120 {
  margin: 120px !important;
}

.m-125 {
  margin: 125px !important;
}

.m-130 {
  margin: 130px !important;
}

.m-135 {
  margin: 135px !important;
}

.m-140 {
  margin: 140px !important;
}

.m-145 {
  margin: 145px !important;
}

.m-150 {
  margin: 150px !important;
}

@media only screen and (max-width: 767px) {
  .m-150,
  .m-145,
  .m-140,
  .m-135,
  .m-130,
  .m-125,
  .m-120,
  .m-115,
  .m-110,
  .m-100,
  .m-95,
  .m-90,
  .m-85,
  .m-80,
  .m-75,
  .m-70,
  .m-65,
  .m-60,
  .m-55,
  .m-50,
  .m-45,
  .m-40,
  .m-35 {
    margin: 30px !important;
  }
}

.m-h-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.m-l-auto {
  margin-left: auto !important;
}

.m-r-auto {
  margin-right: auto !important;
}

.m-v-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.m-v-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.m-v-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.m-v-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.m-v-25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.m-v-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.m-v-35 {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}

.m-v-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.m-v-45 {
  margin-top: 45px !important;
  margin-bottom: 45px !important;
}

.m-v-50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.m-v-55 {
  margin-top: 55px !important;
  margin-bottom: 55px !important;
}

.m-v-60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

.m-v-65 {
  margin-top: 65px !important;
  margin-bottom: 65px !important;
}

.m-v-70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important;
}

.m-v-75 {
  margin-top: 75px !important;
  margin-bottom: 75px !important;
}

.m-v-80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

.m-v-85 {
  margin-top: 85px !important;
  margin-bottom: 85px !important;
}

.m-v-90 {
  margin-top: 90px !important;
  margin-bottom: 90px !important;
}

.m-v-95 {
  margin-top: 95px !important;
  margin-bottom: 95px !important;
}

.m-v-100 {
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}

.m-v-105 {
  margin-top: 105px !important;
  margin-bottom: 105px !important;
}

.m-v-110 {
  margin-top: 110px !important;
  margin-bottom: 110px !important;
}

.m-v-115 {
  margin-top: 115px !important;
  margin-bottom: 115px !important;
}

.m-v-120 {
  margin-top: 120px !important;
  margin-bottom: 120px !important;
}

.m-v-125 {
  margin-top: 125px !important;
  margin-bottom: 125px !important;
}

.m-v-130 {
  margin-top: 130px !important;
  margin-bottom: 130px !important;
}

.m-v-135 {
  margin-top: 135px !important;
  margin-bottom: 135px !important;
}

.m-v-140 {
  margin-top: 140px !important;
  margin-bottom: 140px !important;
}

.m-v-145 {
  margin-top: 145px !important;
  margin-bottom: 145px !important;
}

.m-v-150 {
  margin-top: 150px !important;
  margin-bottom: 150px !important;
}

@media only screen and (max-width: 767px) {
  .m-v-150,
  .m-v-145,
  .m-v-140,
  .m-v-135,
  .m-v-130,
  .m-v-125,
  .m-v-120,
  .m-v-115,
  .m-v-110,
  .m-v-100,
  .m-v-95,
  .m-v-90,
  .m-v-85,
  .m-v-80,
  .m-v-75,
  .m-v-70,
  .m-v-65,
  .m-v-60,
  .m-v-55,
  .m-v-50,
  .m-v-45,
  .m-v-40,
  .m-v-35 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
}

.m-h-5 {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.m-h-10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.m-h-15 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.m-h-20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.m-h-25 {
  margin-left: 25px !important;
  margin-right: 25px !important;
}

.m-h-30 {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.m-h-35 {
  margin-left: 35px !important;
  margin-right: 35px !important;
}

.m-h-40 {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

.m-h-45 {
  margin-left: 45px !important;
  margin-right: 45px !important;
}

.m-h-50 {
  margin-left: 50px !important;
  margin-right: 50px !important;
}

.m-h-55 {
  margin-left: 55px !important;
  margin-right: 55px !important;
}

.m-h-60 {
  margin-left: 60px !important;
  margin-right: 60px !important;
}

.m-h-65 {
  margin-left: 65px !important;
  margin-right: 65px !important;
}

.m-h-70 {
  margin-left: 70px !important;
  margin-right: 70px !important;
}

.m-h-75 {
  margin-left: 75px !important;
  margin-right: 75px !important;
}

.m-h-80 {
  margin-left: 80px !important;
  margin-right: 80px !important;
}

.m-h-85 {
  margin-left: 85px !important;
  margin-right: 85px !important;
}

.m-h-90 {
  margin-left: 90px !important;
  margin-right: 90px !important;
}

.m-h-95 {
  margin-left: 95px !important;
  margin-right: 95px !important;
}

.m-h-100 {
  margin-left: 100px !important;
  margin-right: 100px !important;
}

.m-h-105 {
  margin-left: 105px !important;
  margin-right: 105px !important;
}

.m-h-110 {
  margin-left: 110px !important;
  margin-right: 110px !important;
}

.m-h-115 {
  margin-left: 115px !important;
  margin-right: 115px !important;
}

.m-h-120 {
  margin-left: 120px !important;
  margin-right: 120px !important;
}

.m-h-125 {
  margin-left: 125px !important;
  margin-right: 125px !important;
}

.m-h-130 {
  margin-left: 130px !important;
  margin-right: 130px !important;
}

.m-h-135 {
  margin-left: 135px !important;
  margin-right: 135px !important;
}

.m-h-140 {
  margin-left: 140px !important;
  margin-right: 140px !important;
}

.m-h-145 {
  margin-left: 145px !important;
  margin-right: 145px !important;
}

.m-h-150 {
  margin-left: 150px !important;
  margin-right: 150px !important;
}

@media only screen and (max-width: 767px) {
  .m-h-150,
  .m-h-145,
  .m-h-140,
  .m-h-135,
  .m-h-130,
  .m-h-125,
  .m-h-120,
  .m-h-115,
  .m-h-110,
  .m-h-100,
  .m-h-95,
  .m-h-90,
  .m-h-85,
  .m-h-80,
  .m-h-75,
  .m-h-70,
  .m-h-65,
  .m-h-60,
  .m-h-55,
  .m-h-50,
  .m-h-45,
  .m-h-40,
  .m-h-35 {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
}

.m-t-5 {
  margin-top: 5px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-t-25 {
  margin-top: 25px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.m-t-35 {
  margin-top: 35px !important;
}

.m-t-40 {
  margin-top: 40px !important;
}

.m-t-45 {
  margin-top: 45px !important;
}

.m-t-50 {
  margin-top: 50px !important;
}

.m-t-55 {
  margin-top: 55px !important;
}

.m-t-60 {
  margin-top: 60px !important;
}

.m-t-65 {
  margin-top: 65px !important;
}

.m-t-70 {
  margin-top: 70px !important;
}

.m-t-75 {
  margin-top: 75px !important;
}

.m-t-80 {
  margin-top: 80px !important;
}

.m-t-85 {
  margin-top: 85px !important;
}

.m-t-90 {
  margin-top: 90px !important;
}

.m-t-95 {
  margin-top: 95px !important;
}

.m-t-100 {
  margin-top: 100px !important;
}

.m-t-105 {
  margin-top: 105px !important;
}

.m-t-110 {
  margin-top: 110px !important;
}

.m-t-115 {
  margin-top: 115px !important;
}

.m-t-120 {
  margin-top: 120px !important;
}

.m-t-125 {
  margin-top: 125px !important;
}

.m-t-130 {
  margin-top: 130px !important;
}

.m-t-135 {
  margin-top: 135px !important;
}

.m-t-140 {
  margin-top: 140px !important;
}

.m-t-145 {
  margin-top: 145px !important;
}

.m-t-150 {
  margin-top: 150px !important;
}

@media only screen and (max-width: 767px) {
  .m-t-150,
  .m-t-145,
  .m-t-140,
  .m-t-135,
  .m-t-130,
  .m-t-125,
  .m-t-120,
  .m-t-115,
  .m-t-110,
  .m-t-100,
  .m-t-95,
  .m-t-90,
  .m-t-85,
  .m-t-80,
  .m-t-75,
  .m-t-70,
  .m-t-65,
  .m-t-60,
  .m-t-55,
  .m-t-50,
  .m-t-45,
  .m-t-40,
  .m-t-35 {
    margin-top: 30px !important;
  }
}

.m-b-5 {
  margin-bottom: 5px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-b-25 {
  margin-bottom: 25px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.m-b-35 {
  margin-bottom: 35px !important;
}

.m-b-40 {
  margin-bottom: 40px !important;
}

.m-b-45 {
  margin-bottom: 45px !important;
}

.m-b-50 {
  margin-bottom: 50px !important;
}

.m-b-55 {
  margin-bottom: 55px !important;
}

.m-b-60 {
  margin-bottom: 60px !important;
}

.m-b-65 {
  margin-bottom: 65px !important;
}

.m-b-70 {
  margin-bottom: 70px !important;
}

.m-b-75 {
  margin-bottom: 75px !important;
}

.m-b-80 {
  margin-bottom: 80px !important;
}

.m-b-85 {
  margin-bottom: 85px !important;
}

.m-b-90 {
  margin-bottom: 90px !important;
}

.m-b-95 {
  margin-bottom: 95px !important;
}

.m-b-100 {
  margin-bottom: 100px !important;
}

.m-b-105 {
  margin-bottom: 105px !important;
}

.m-b-110 {
  margin-bottom: 110px !important;
}

.m-b-115 {
  margin-bottom: 115px !important;
}

.m-b-120 {
  margin-bottom: 120px !important;
}

.m-b-125 {
  margin-bottom: 125px !important;
}

.m-b-130 {
  margin-bottom: 130px !important;
}

.m-b-135 {
  margin-bottom: 135px !important;
}

.m-b-140 {
  margin-bottom: 140px !important;
}

.m-b-145 {
  margin-bottom: 145px !important;
}

.m-b-150 {
  margin-bottom: 150px !important;
}

@media only screen and (max-width: 767px) {
  .m-b-150,
  .m-b-145,
  .m-b-140,
  .m-b-135,
  .m-b-130,
  .m-b-125,
  .m-b-120,
  .m-b-115,
  .m-b-110,
  .m-b-100,
  .m-b-95,
  .m-b-90,
  .m-b-85,
  .m-b-80,
  .m-b-75,
  .m-b-70,
  .m-b-65,
  .m-b-60,
  .m-b-55,
  .m-b-50,
  .m-b-45,
  .m-b-40,
  .m-b-35 {
    margin-bottom: 30px !important;
  }
}

.m-l-5 {
  margin-left: 5px !important;
}

.m-l-10 {
  margin-left: 10px !important;
}

.m-l-15 {
  margin-left: 15px !important;
}
.m-l-17 {
  margin-left: 17px !important;
}

.m-l-20 {
  margin-left: 20px !important;
}

.m-l-25 {
  margin-left: 25px !important;
}

.m-l-30 {
  margin-left: 30px !important;
}

.m-l-35 {
  margin-left: 35px !important;
}

.m-l-40 {
  margin-left: 40px !important;
}

.m-l-45 {
  margin-left: 45px !important;
}

.m-l-50 {
  margin-left: 50px !important;
}

.m-l-55 {
  margin-left: 55px !important;
}

.m-l-60 {
  margin-left: 60px !important;
}

.m-l-65 {
  margin-left: 65px !important;
}

.m-l-70 {
  margin-left: 70px !important;
}

.m-l-75 {
  margin-left: 75px !important;
}

.m-l-80 {
  margin-left: 80px !important;
}

.m-l-85 {
  margin-left: 85px !important;
}

.m-l-90 {
  margin-left: 90px !important;
}

.m-l-95 {
  margin-left: 95px !important;
}

.m-l-100 {
  margin-left: 100px !important;
}

.m-l-105 {
  margin-left: 105px !important;
}

.m-l-110 {
  margin-left: 110px !important;
}

.m-l-115 {
  margin-left: 115px !important;
}

.m-l-120 {
  margin-left: 120px !important;
}

.m-l-125 {
  margin-left: 125px !important;
}

.m-l-130 {
  margin-left: 130px !important;
}

.m-l-135 {
  margin-left: 135px !important;
}

.m-l-140 {
  margin-left: 140px !important;
}

.m-l-145 {
  margin-left: 145px !important;
}

.m-l-150 {
  margin-left: 150px !important;
}

@media only screen and (max-width: 767px) {
  .m-l-150,
  .m-l-145,
  .m-l-140,
  .m-l-135,
  .m-l-130,
  .m-l-125,
  .m-l-120,
  .m-l-115,
  .m-l-110,
  .m-l-100,
  .m-l-95,
  .m-l-90,
  .m-l-85,
  .m-l-80,
  .m-l-75,
  .m-l-70,
  .m-l-65,
  .m-l-60,
  .m-l-55,
  .m-l-50,
  .m-l-45,
  .m-l-40,
  .m-l-35 {
    margin-left: 30px !important;
  }
}

.m-r-5 {
  margin-right: 5px !important;
}

.m-r-10 {
  margin-right: 10px !important;
}

.m-r-15 {
  margin-right: 15px !important;
}

.m-r-20 {
  margin-right: 20px !important;
}

.m-r-25 {
  margin-right: 25px !important;
}

.m-r-30 {
  margin-right: 30px !important;
}

.m-r-35 {
  margin-right: 35px !important;
}

.m-r-40 {
  margin-right: 40px !important;
}

.m-r-45 {
  margin-right: 45px !important;
}

.m-r-50 {
  margin-right: 50px !important;
}

.m-r-55 {
  margin-right: 55px !important;
}

.m-r-60 {
  margin-right: 60px !important;
}

.m-r-65 {
  margin-right: 65px !important;
}

.m-r-70 {
  margin-right: 70px !important;
}

.m-r-75 {
  margin-right: 75px !important;
}

.m-r-80 {
  margin-right: 80px !important;
}

.m-r-85 {
  margin-right: 85px !important;
}

.m-r-90 {
  margin-right: 90px !important;
}

.m-r-95 {
  margin-right: 95px !important;
}

.m-r-100 {
  margin-right: 100px !important;
}

.m-r-105 {
  margin-right: 105px !important;
}

.m-r-110 {
  margin-right: 110px !important;
}

.m-r-115 {
  margin-right: 115px !important;
}

.m-r-120 {
  margin-right: 120px !important;
}

.m-r-125 {
  margin-right: 125px !important;
}

.m-r-130 {
  margin-right: 130px !important;
}

.m-r-135 {
  margin-right: 135px !important;
}

.m-r-140 {
  margin-right: 140px !important;
}

.m-r-145 {
  margin-right: 145px !important;
}

.m-r-150 {
  margin-right: 150px !important;
}

@media only screen and (max-width: 767px) {
  .m-r-150,
  .m-r-145,
  .m-r-140,
  .m-r-135,
  .m-r-130,
  .m-r-125,
  .m-r-120,
  .m-r-115,
  .m-r-110,
  .m-r-100,
  .m-r-95,
  .m-r-90,
  .m-r-85,
  .m-r-80,
  .m-r-75,
  .m-r-70,
  .m-r-65,
  .m-r-60,
  .m-r-55,
  .m-r-50,
  .m-r-45,
  .m-r-40,
  .m-r-35 {
    margin-right: 30px !important;
  }
}

.p-0 {
  padding: 0 !important;
}

.p-t-0 {
  padding-top: 0 !important;
}

.p-b-0 {
  padding-bottom: 0 !important;
}

.p-l-0 {
  padding-left: 0 !important;
}

.p-r-0 {
  padding-right: 0 !important;
}

.p-v-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.p-h-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.p-5 {
  padding: 5px !important;
}

.p-10 {
  padding: 10px !important;
}

.p-15 {
  padding: 15px !important;
}

.p-20 {
  padding: 20px !important;
}

.p-25 {
  padding: 25px !important;
}

.p-30 {
  padding: 30px !important;
}

.p-35 {
  padding: 35px !important;
}

.p-40 {
  padding: 40px !important;
}

.p-45 {
  padding: 45px !important;
}

.p-50 {
  padding: 50px !important;
}

.p-55 {
  padding: 55px !important;
}

.p-60 {
  padding: 60px !important;
}

.p-65 {
  padding: 65px !important;
}

.p-70 {
  padding: 70px !important;
}

.p-75 {
  padding: 75px !important;
}

.p-80 {
  padding: 80px !important;
}

.p-85 {
  padding: 85px !important;
}

.p-90 {
  padding: 90px !important;
}

.p-95 {
  padding: 95px !important;
}

.p-100 {
  padding: 100px !important;
}

.p-105 {
  padding: 105px !important;
}

.p-110 {
  padding: 110px !important;
}

.p-115 {
  padding: 115px !important;
}

.p-120 {
  padding: 120px !important;
}

.p-125 {
  padding: 125px !important;
}

.p-130 {
  padding: 130px !important;
}

.p-135 {
  padding: 135px !important;
}

.p-140 {
  padding: 140px !important;
}

.p-145 {
  padding: 145px !important;
}

.p-150 {
  padding: 150px !important;
}

@media only screen and (max-width: 767px) {
  .p-150,
  .p-145,
  .p-140,
  .p-135,
  .p-130,
  .p-125,
  .p-120,
  .p-115,
  .p-110,
  .p-100,
  .p-95,
  .p-90,
  .p-85,
  .p-80,
  .p-75,
  .p-70,
  .p-65,
  .p-60,
  .p-55,
  .p-50,
  .p-45,
  .p-40,
  .p-35 {
    padding: 30px !important;
  }
}

.p-v-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.p-v-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.p-v-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.p-v-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.p-v-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.p-v-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.p-v-35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}

.p-v-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.p-v-45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}

.p-v-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.p-v-55 {
  padding-top: 55px !important;
  padding-bottom: 55px !important;
}

.p-v-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.p-v-65 {
  padding-top: 65px !important;
  padding-bottom: 65px !important;
}

.p-v-70 {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}

.p-v-75 {
  padding-top: 75px !important;
  padding-bottom: 75px !important;
}

.p-v-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.p-v-85 {
  padding-top: 85px !important;
  padding-bottom: 85px !important;
}

.p-v-90 {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
}

.p-v-95 {
  padding-top: 95px !important;
  padding-bottom: 95px !important;
}

.p-v-100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

.p-v-105 {
  padding-top: 105px !important;
  padding-bottom: 105px !important;
}

.p-v-110 {
  padding-top: 110px !important;
  padding-bottom: 110px !important;
}

.p-v-115 {
  padding-top: 115px !important;
  padding-bottom: 115px !important;
}

.p-v-120 {
  padding-top: 120px !important;
  padding-bottom: 120px !important;
}

.p-v-125 {
  padding-top: 125px !important;
  padding-bottom: 125px !important;
}

.p-v-130 {
  padding-top: 130px !important;
  padding-bottom: 130px !important;
}

.p-v-135 {
  padding-top: 135px !important;
  padding-bottom: 135px !important;
}

.p-v-140 {
  padding-top: 140px !important;
  padding-bottom: 140px !important;
}

.p-v-145 {
  padding-top: 145px !important;
  padding-bottom: 145px !important;
}

.p-v-150 {
  padding-top: 150px !important;
  padding-bottom: 150px !important;
}

@media only screen and (max-width: 767px) {
  .p-v-150,
  .p-v-145,
  .p-v-140,
  .p-v-135,
  .p-v-130,
  .p-v-125,
  .p-v-120,
  .p-v-115,
  .p-v-110,
  .p-v-100,
  .p-v-95,
  .p-v-90,
  .p-v-85,
  .p-v-80,
  .p-v-75,
  .p-v-70,
  .p-v-65,
  .p-v-60,
  .p-v-55,
  .p-v-50,
  .p-v-45,
  .p-v-40,
  .p-v-35 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
}

.p-h-5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.p-h-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.p-h-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.p-h-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.p-h-25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.p-h-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.p-h-35 {
  padding-left: 35px !important;
  padding-right: 35px !important;
}

.p-h-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.p-h-45 {
  padding-left: 45px !important;
  padding-right: 45px !important;
}

.p-h-50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.p-h-55 {
  padding-left: 55px !important;
  padding-right: 55px !important;
}

.p-h-60 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.p-h-65 {
  padding-left: 65px !important;
  padding-right: 65px !important;
}

.p-h-70 {
  padding-left: 70px !important;
  padding-right: 70px !important;
}

.p-h-75 {
  padding-left: 75px !important;
  padding-right: 75px !important;
}

.p-h-80 {
  padding-left: 80px !important;
  padding-right: 80px !important;
}

.p-h-85 {
  padding-left: 85px !important;
  padding-right: 85px !important;
}

.p-h-90 {
  padding-left: 90px !important;
  padding-right: 90px !important;
}

.p-h-95 {
  padding-left: 95px !important;
  padding-right: 95px !important;
}

.p-h-100 {
  padding-left: 100px !important;
  padding-right: 100px !important;
}

.p-h-105 {
  padding-left: 105px !important;
  padding-right: 105px !important;
}

.p-h-110 {
  padding-left: 110px !important;
  padding-right: 110px !important;
}

.p-h-115 {
  padding-left: 115px !important;
  padding-right: 115px !important;
}

.p-h-120 {
  padding-left: 120px !important;
  padding-right: 120px !important;
}

.p-h-125 {
  padding-left: 125px !important;
  padding-right: 125px !important;
}

.p-h-130 {
  padding-left: 130px !important;
  padding-right: 130px !important;
}

.p-h-135 {
  padding-left: 135px !important;
  padding-right: 135px !important;
}

.p-h-140 {
  padding-left: 140px !important;
  padding-right: 140px !important;
}

.p-h-145 {
  padding-left: 145px !important;
  padding-right: 145px !important;
}

.p-h-150 {
  padding-left: 150px !important;
  padding-right: 150px !important;
}

@media only screen and (max-width: 767px) {
  .p-h-150,
  .p-h-145,
  .p-h-140,
  .p-h-135,
  .p-h-130,
  .p-h-125,
  .p-h-120,
  .p-h-115,
  .p-h-110,
  .p-h-100,
  .p-h-95,
  .p-h-90,
  .p-h-85,
  .p-h-80,
  .p-h-75,
  .p-h-70,
  .p-h-65,
  .p-h-60,
  .p-h-55,
  .p-h-50,
  .p-h-45,
  .p-h-40,
  .p-h-35 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}

.p-t-5 {
  padding-top: 5px !important;
}

.p-t-10 {
  padding-top: 10px !important;
}

.p-t-15 {
  padding-top: 15px !important;
}

.p-t-20 {
  padding-top: 20px !important;
}

.p-t-25 {
  padding-top: 25px !important;
}

.p-t-30 {
  padding-top: 30px !important;
}

.p-t-35 {
  padding-top: 35px !important;
}

.p-t-40 {
  padding-top: 40px !important;
}

.p-t-45 {
  padding-top: 45px !important;
}

.p-t-50 {
  padding-top: 50px !important;
}

.p-t-55 {
  margin-top: 55px !important;
}

.p-t-60 {
  padding-top: 60px !important;
}

.p-t-65 {
  padding-top: 65px !important;
}

.p-t-70 {
  padding-top: 70px !important;
}

.p-t-75 {
  padding-top: 75px !important;
}

.p-t-80 {
  padding-top: 80px !important;
}

.p-t-85 {
  padding-top: 85px !important;
}

.p-t-90 {
  padding-top: 90px !important;
}

.p-t-95 {
  padding-top: 95px !important;
}

.p-t-100 {
  padding-top: 100px !important;
}

.p-t-105 {
  padding-top: 105px !important;
}

.p-t-110 {
  padding-top: 110px !important;
}

.p-t-115 {
  padding-top: 115px !important;
}

.p-t-120 {
  padding-top: 120px !important;
}

.p-t-125 {
  padding-top: 125px !important;
}

.p-t-130 {
  padding-top: 130px !important;
}

.p-t-135 {
  padding-top: 135px !important;
}

.p-t-140 {
  padding-top: 140px !important;
}

.p-t-145 {
  padding-top: 145px !important;
}

.p-t-150 {
  padding-top: 150px !important;
}

@media only screen and (max-width: 767px) {
  .p-t-150,
  .p-t-145,
  .p-t-140,
  .p-t-135,
  .p-t-130,
  .p-t-125,
  .p-t-120,
  .p-t-115,
  .p-t-110,
  .p-t-100,
  .p-t-95,
  .p-t-90,
  .p-t-85,
  .p-t-80,
  .p-t-75,
  .p-t-70,
  .p-t-65,
  .p-t-60,
  .p-t-55,
  .p-t-50,
  .p-t-45,
  .p-t-40,
  .p-t-35 {
    padding-top: 30px !important;
  }
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.p-b-10 {
  padding-bottom: 10px !important;
}

.p-b-15 {
  padding-bottom: 15px !important;
}

.p-b-20 {
  padding-bottom: 20px !important;
}

.p-b-25 {
  padding-bottom: 25px !important;
}

.p-b-30 {
  padding-bottom: 30px !important;
}

.p-b-35 {
  padding-bottom: 35px !important;
}

.p-b-40 {
  padding-bottom: 40px !important;
}

.p-b-45 {
  padding-bottom: 45px !important;
}

.p-b-50 {
  padding-bottom: 50px !important;
}

.p-b-55 {
  margin-bottom: 55px !important;
}

.p-b-60 {
  padding-bottom: 60px !important;
}

.p-b-65 {
  padding-bottom: 65px !important;
}

.p-b-70 {
  padding-bottom: 70px !important;
}

.p-b-75 {
  padding-bottom: 75px !important;
}

.p-b-80 {
  padding-bottom: 80px !important;
}

.p-b-85 {
  padding-bottom: 85px !important;
}

.p-b-90 {
  padding-bottom: 90px !important;
}

.p-b-95 {
  padding-bottom: 95px !important;
}

.p-b-100 {
  padding-bottom: 100px !important;
}

.p-b-105 {
  padding-bottom: 105px !important;
}

.p-b-110 {
  padding-bottom: 110px !important;
}

.p-b-115 {
  padding-bottom: 115px !important;
}

.p-b-120 {
  margin-bottom: 120px !important;
}

.p-b-125 {
  padding-bottom: 125px !important;
}

.p-b-130 {
  padding-bottom: 130px !important;
}

.p-b-135 {
  padding-bottom: 135px !important;
}

.p-b-140 {
  padding-bottom: 140px !important;
}

.p-b-145 {
  padding-bottom: 145px !important;
}

.p-b-150 {
  padding-bottom: 150px !important;
}

@media only screen and (max-width: 767px) {
  .p-b-150,
  .p-b-145,
  .p-b-140,
  .p-b-135,
  .p-b-130,
  .p-b-125,
  .p-b-120,
  .p-b-115,
  .p-b-110,
  .p-b-100,
  .p-b-95,
  .p-b-90,
  .p-b-85,
  .p-b-80,
  .p-b-75,
  .p-b-70,
  .p-b-65,
  .p-b-60,
  .p-b-55,
  .p-b-50,
  .p-b-45,
  .p-b-40,
  .p-b-35 {
    padding-bottom: 30px !important;
  }
}

.p-l-5 {
  padding-left: 5px !important;
}

.p-l-10 {
  padding-left: 10px !important;
}

.p-l-15 {
  padding-left: 15px !important;
}

.p-l-20 {
  padding-left: 20px !important;
}

.p-l-25 {
  padding-left: 25px !important;
}

.p-l-30 {
  padding-left: 30px !important;
}

.p-l-35 {
  padding-left: 35px !important;
}

.p-l-40 {
  padding-left: 40px !important;
}

.p-l-45 {
  padding-left: 45px !important;
}

.p-l-50 {
  padding-left: 50px !important;
}

.p-l-55 {
  margin-left: 55px !important;
}

.p-l-60 {
  padding-left: 60px !important;
}

.p-l-65 {
  padding-left: 65px !important;
}

.p-l-70 {
  padding-left: 70px !important;
}

.p-l-75 {
  padding-left: 75px !important;
}

.p-l-80 {
  padding-left: 80px !important;
}

.p-l-85 {
  padding-left: 85px !important;
}

.p-l-90 {
  padding-left: 90px !important;
}

.p-l-95 {
  padding-left: 95px !important;
}

.p-l-100 {
  padding-left: 100px !important;
}

.p-l-105 {
  padding-left: 105px !important;
}

.p-l-110 {
  padding-left: 110px !important;
}

.p-l-115 {
  padding-left: 115px !important;
}

.p-l-120 {
  margin-left: 120px !important;
}

.p-l-125 {
  padding-left: 125px !important;
}

.p-l-130 {
  padding-left: 130px !important;
}

.p-l-135 {
  padding-left: 135px !important;
}

.p-l-140 {
  padding-left: 140px !important;
}

.p-l-145 {
  padding-left: 145px !important;
}

.p-l-150 {
  padding-left: 150px !important;
}

@media only screen and (max-width: 767px) {
  .p-l-150,
  .p-l-145,
  .p-l-140,
  .p-l-135,
  .p-l-130,
  .p-l-125,
  .p-l-120,
  .p-l-115,
  .p-l-110,
  .p-l-100,
  .p-l-95,
  .p-l-90,
  .p-l-85,
  .p-l-80,
  .p-l-75,
  .p-l-70,
  .p-l-65,
  .p-l-60,
  .p-l-55,
  .p-l-50,
  .p-l-45,
  .p-l-40,
  .p-l-35 {
    padding-left: 30px !important;
  }
}

.p-r-5 {
  padding-right: 5px !important;
}

.p-r-10 {
  padding-right: 10px !important;
}

.p-r-15 {
  padding-right: 15px !important;
}

.p-r-20 {
  padding-right: 20px !important;
}

.p-r-25 {
  padding-right: 25px !important;
}

.p-r-30 {
  padding-right: 30px !important;
}

.p-r-35 {
  padding-right: 35px !important;
}

.p-r-40 {
  padding-right: 40px !important;
}

.p-r-45 {
  padding-right: 45px !important;
}

.p-r-50 {
  padding-right: 50px !important;
}

.p-r-55 {
  margin-right: 55px !important;
}

.p-r-60 {
  padding-right: 60px !important;
}

.p-r-65 {
  padding-right: 65px !important;
}

.p-r-70 {
  padding-right: 70px !important;
}

.p-r-75 {
  padding-right: 75px !important;
}

.p-r-80 {
  padding-right: 80px !important;
}

.p-r-85 {
  padding-right: 85px !important;
}

.p-r-90 {
  padding-right: 90px !important;
}

.p-r-95 {
  padding-right: 95px !important;
}

.p-r-100 {
  padding-right: 100px !important;
}

.p-r-105 {
  padding-right: 105px !important;
}

.p-l-110 {
  padding-left: 110px !important;
}

.p-r-115 {
  padding-right: 115px !important;
}

.p-r-120 {
  margin-right: 120px !important;
}

.p-r-125 {
  padding-right: 125px !important;
}

.p-r-130 {
  padding-right: 130px !important;
}

.p-r-135 {
  padding-right: 135px !important;
}

.p-r-140 {
  padding-right: 140px !important;
}

.p-r-145 {
  padding-right: 145px !important;
}

.p-r-150 {
  padding-right: 150px !important;
}

@media only screen and (max-width: 767px) {
  .p-r-150,
  .p-r-145,
  .p-r-140,
  .p-r-135,
  .p-r-130,
  .p-r-125,
  .p-r-120,
  .p-r-115,
  .p-r-110,
  .p-r-100,
  .p-r-95,
  .p-r-90,
  .p-r-85,
  .p-r-80,
  .p-r-75,
  .p-r-70,
  .p-r-65,
  .p-r-60,
  .p-r-55,
  .p-r-50,
  .p-r-45,
  .p-r-40,
  .p-r-35 {
    padding-right: 30px !important;
  }
}

.w-0 {
  width: 0px;
}

.w-10 {
  width: 10%;
}

.w-15 {
  width: 15%;
}

.w-20 {
  width: 20%;
}

.w-25 {
  width: 25%;
}

.w-30 {
  width: 30%;
}

.w-35 {
  width: 35%;
}

.w-40 {
  width: 40%;
}

.w-45 {
  width: 45%;
}

.w-50 {
  width: 50%;
}

.w-55 {
  width: 55%;
}

.w-60 {
  width: 60%;
}

.w-65 {
  width: 65%;
}

.w-70 {
  width: 70%;
}

.w-75 {
  width: 75%;
}

.w-80 {
  width: 80%;
}

.w-85 {
  width: 85%;
}

.w-90 {
  width: 90%;
}

.w-95 {
  width: 95%;
}

.w-100 {
  width: 100%;
}

.height-max {
  height: 100%;
}

.full-height {
  min-height: 100vh;
}

.ls-0 {
  letter-spacing: 0px !important;
}

.ls-0-5 {
  letter-spacing: 0.5px !important;
}

.ls-1 {
  letter-spacing: 1px !important;
}

.ls-1-5 {
  letter-spacing: 1.5px !important;
}

.ls-2 {
  letter-spacing: 2px !important;
}

.ls-2-5 {
  letter-spacing: 2.5px !important;
}

.ls-3 {
  letter-spacing: 3px !important;
}

.ls-3-5 {
  letter-spacing: 3.5px !important;
}

.ls-4 {
  letter-spacing: 4px !important;
}

.ls-4-5 {
  letter-spacing: 4.5px !important;
}

.ls-5 {
  letter-spacing: 5px !important;
}

.ls-5-5 {
  letter-spacing: 5.5px !important;
}

.ls-6 {
  letter-spacing: 6px !important;
}

.ls-6-5 {
  letter-spacing: 6.5px !important;
}

.ls-7 {
  letter-spacing: 7px !important;
}

.ls-7-5 {
  letter-spacing: 7.5px !important;
}

.ls-8 {
  letter-spacing: 8px !important;
}

.ls-8-5 {
  letter-spacing: 8.5px !important;
}

.ls-9 {
  letter-spacing: 9px !important;
}

.ls-9-5 {
  letter-spacing: 9.5px !important;
}

.ls-10 {
  letter-spacing: 10px !important;
}

.ls-11 {
  letter-spacing: 11px !important;
}

.ls-12 {
  letter-spacing: 12px !important;
}

.ls-13 {
  letter-spacing: 13px !important;
}

.ls-14 {
  letter-spacing: 14px !important;
}

.ls-15 {
  letter-spacing: 15px !important;
}

.lh-0 {
  line-height: 0 !important;
}

.lh-0-5 {
  line-height: 0.5 !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-1-1 {
  line-height: 1.1 !important;
}

.lh-1-2 {
  line-height: 1.2 !important;
}

.lh-1-3 {
  line-height: 1.3 !important;
}

.lh-1-4 {
  line-height: 1.4 !important;
}

.lh-1-5 {
  line-height: 1.5 !important;
}

.lh-1-6 {
  line-height: 1.6 !important;
}

.lh-1-7 {
  line-height: 1.7 !important;
}

.lh-1-8 {
  line-height: 1.8 !important;
}

.lh-1-9 {
  line-height: 1.9 !important;
}

.lh-2 {
  line-height: 2 !important;
}

.lh-2-1 {
  line-height: 2.1 !important;
}

.lh-2-2 {
  line-height: 2.2 !important;
}

.lh-2-3 {
  line-height: 2.3 !important;
}

.lh-2-4 {
  line-height: 2.4 !important;
}

.lh-2-5 {
  line-height: 2.5 !important;
}

.lh-3 {
  line-height: 3 !important;
}

.lh-4 {
  line-height: 4 !important;
}

.font-size-8 {
  font-size: 8px !important;
}

.font-size-9 {
  font-size: 9px !important;
}

.font-size-10 {
  font-size: 10px !important;
}

.font-size-11 {
  font-size: 11px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-13 {
  font-size: 13px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-15 {
  font-size: 15px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-17 {
  font-size: 17px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-19 {
  font-size: 19px !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.font-size-21 {
  font-size: 21px !important;
}

.font-size-22 {
  font-size: 22px !important;
}

.font-size-23 {
  font-size: 23px !important;
}

.font-size-24 {
  font-size: 24px !important;
}

.font-size-25 {
  font-size: 25px !important;
}

.font-size-26 {
  font-size: 26px !important;
}

.font-size-27 {
  font-size: 27px !important;
}

.font-size-28 {
  font-size: 28px !important;
}

.font-size-29 {
  font-size: 29px !important;
}

.font-size-30 {
  font-size: 30px !important;
}

.font-size-35 {
  font-size: 35px !important;
}

.font-size-40 {
  font-size: 40px !important;
}

.font-size-45 {
  font-size: 45px !important;
}

.font-size-50 {
  font-size: 50px !important;
}

.font-size-55 {
  font-size: 55px !important;
}

.font-size-60 {
  font-size: 60px !important;
}

.font-size-65 {
  font-size: 65px !important;
}

.font-size-70 {
  font-size: 70px !important;
}

.font-size-75 {
  font-size: 75px !important;
}

.font-size-80 {
  font-size: 80px !important;
}

.font-size-85 {
  font-size: 85px !important;
}

.font-size-90 {
  font-size: 90px !important;
}

.font-size-95 {
  font-size: 95px !important;
}

.font-size-100 {
  font-size: 100px !important;
}

.font-size-105 {
  font-size: 105px !important;
}

.font-size-110 {
  font-size: 110px !important;
}

.font-size-115 {
  font-size: 115px !important;
}

.font-size-120 {
  font-size: 120px !important;
}

.font-size-125 {
  font-size: 125px !important;
}

.font-size-130 {
  font-size: 130px !important;
}

.font-size-135 {
  font-size: 135px !important;
}

.font-size-140 {
  font-size: 140px !important;
}

.font-size-145 {
  font-size: 145px !important;
}

.font-size-150 {
  font-size: 150px !important;
}

.font-size-155 {
  font-size: 155px !important;
}

.font-size-160 {
  font-size: 160px !important;
}

.font-size-165 {
  font-size: 165px !important;
}

.font-size-170 {
  font-size: 170px !important;
}

.font-size-175 {
  font-size: 175px !important;
}

.font-size-180 {
  font-size: 180px !important;
}

.font-size-185 {
  font-size: 185px !important;
}

.font-size-190 {
  font-size: 190px !important;
}

.font-size-195 {
  font-size: 195px !important;
}

.font-size-200 {
  font-size: 200px !important;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #222222;
  text-align: left;
  background-color: #ffffff;
}

[tabindex='-1']:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #ef3e27;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #0029fa;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
    'Courier New', monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
  color: #8d8a89;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

/* h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	margin-bottom: 0.5rem;
	font-weight: 500;
	line-height: 1.2;
} */

h1,
.h1 {
  font-size: 2.5rem;
}

h2,
.h2 {
  font-size: 2rem;
}

h3,
.h3 {
  font-size: 1.75rem;
}

h4,
.h4 {
  font-size: 1.5rem;
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}

.blockquote-footer::before {
  content: '\2014\00A0';
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #ea5198;
  word-break: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #ffffff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

.table,
.jsgrid .jsgrid-table {
  width: 100%;
  margin-bottom: 1rem;
  color: #222222;
}

.table th,
.jsgrid .jsgrid-table th,
.table td,
.jsgrid .jsgrid-table td {
  padding: 0.4375rem;
  vertical-align: top;
  border-top: 1px solid #e6e4e9;
}

.table thead th,
.jsgrid .jsgrid-table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #e6e4e9;
}

.table tbody + tbody,
.jsgrid .jsgrid-table tbody + tbody {
  border-top: 2px solid #e6e4e9;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #e6e4e9;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #e6e4e9;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(249, 249, 254, 0.74);
}

.table-hover tbody tr:hover {
  color: #222222;
  background-color: #ffffff;
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #cbd4ff;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #9fafff;
}

.table-hover .table-primary:hover {
  background-color: #b2bfff;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #b2bfff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #f3f5f8;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #e8ecf2;
}

.table-hover .table-secondary:hover {
  background-color: #e3e8ef;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #e3e8ef;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #b8f0df;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #7ae4c4;
}

.table-hover .table-success:hover {
  background-color: #a3ecd6;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #a3ecd6;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #b8e7ff;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #7ad3ff;
}

.table-hover .table-info:hover {
  background-color: #9fdeff;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #9fdeff;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffefcf;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffe1a7;
}

.table-hover .table-warning:hover {
  background-color: #ffe7b6;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe7b6;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #ffd0d4;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ffa7af;
}

.table-hover .table-danger:hover {
  background-color: #ffb7bd;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #ffb7bd;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfefe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fcfcfd;
}

.table-hover .table-light:hover {
  background-color: #ecf6f6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ecf6f6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c2c3c6;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #8d9095;
}

.table-hover .table-dark:hover {
  background-color: #b5b6ba;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b5b6ba;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: #ffffff;
}

.table-hover .table-active:hover {
  background-color: #f2f2f2;
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: #f2f2f2;
}

.table .thead-dark th,
.jsgrid .jsgrid-table .thead-dark th {
  color: #ffffff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th,
.jsgrid .jsgrid-table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #e6e4e9;
}

.table-dark {
  color: #ffffff;
  background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control,
.jsgrid .jsgrid-table .jsgrid-filter-row input[type='text'],
.jsgrid .jsgrid-table .jsgrid-filter-row select,
.jsgrid .jsgrid-table .jsgrid-edit-row input[type='text'],
.jsgrid .jsgrid-table .jsgrid-edit-row select,
.jsgrid .jsgrid-table .jsgrid-filter-row input[type='number'],
.jsgrid .jsgrid-table .jsgrid-edit-row input[type='number'] {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.0625rem + 2px);
  padding: 0.53125rem 0.8125rem;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3f3f3f;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #e6e4e9;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control,
  .jsgrid .jsgrid-table .jsgrid-filter-row input[type='text'],
  .jsgrid .jsgrid-table .jsgrid-filter-row select,
  .jsgrid .jsgrid-table .jsgrid-edit-row input[type='text'],
  .jsgrid .jsgrid-table .jsgrid-edit-row select,
  .jsgrid .jsgrid-table .jsgrid-filter-row input[type='number'],
  .jsgrid .jsgrid-table .jsgrid-edit-row input[type='number'] {
    transition: none;
  }
}

.form-control::-ms-expand,
.jsgrid .jsgrid-table .jsgrid-filter-row input[type='text']::-ms-expand,
.jsgrid .jsgrid-table .jsgrid-filter-row select::-ms-expand,
.jsgrid .jsgrid-table .jsgrid-edit-row input[type='text']::-ms-expand,
.jsgrid .jsgrid-table .jsgrid-edit-row select::-ms-expand,
.jsgrid .jsgrid-table .jsgrid-filter-row input[type='number']::-ms-expand,
.jsgrid .jsgrid-table .jsgrid-edit-row input[type='number']::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row input:focus[type='text'],
.jsgrid .jsgrid-table .jsgrid-filter-row select:focus,
.jsgrid .jsgrid-table .jsgrid-edit-row input:focus[type='text'],
.jsgrid .jsgrid-table .jsgrid-edit-row select:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row input:focus[type='number'],
.jsgrid .jsgrid-table .jsgrid-edit-row input:focus[type='number'] {
  color: #3f3f3f;
  background-color: #ffffff;
  border-color: #ef3e27;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(71, 101, 255, 0.25);
}

.form-control::-webkit-input-placeholder, .jsgrid .jsgrid-table .jsgrid-filter-row input[type='text']::-webkit-input-placeholder, .jsgrid .jsgrid-table .jsgrid-filter-row select::-webkit-input-placeholder, .jsgrid .jsgrid-table .jsgrid-edit-row input[type='text']::-webkit-input-placeholder, .jsgrid .jsgrid-table .jsgrid-edit-row select::-webkit-input-placeholder, .jsgrid .jsgrid-table .jsgrid-filter-row input[type='number']::-webkit-input-placeholder, .jsgrid .jsgrid-table .jsgrid-edit-row input[type='number']::-webkit-input-placeholder {
  color: #8d8a89;
  opacity: 1;
}

.form-control:-ms-input-placeholder, .jsgrid .jsgrid-table .jsgrid-filter-row input[type='text']:-ms-input-placeholder, .jsgrid .jsgrid-table .jsgrid-filter-row select:-ms-input-placeholder, .jsgrid .jsgrid-table .jsgrid-edit-row input[type='text']:-ms-input-placeholder, .jsgrid .jsgrid-table .jsgrid-edit-row select:-ms-input-placeholder, .jsgrid .jsgrid-table .jsgrid-filter-row input[type='number']:-ms-input-placeholder, .jsgrid .jsgrid-table .jsgrid-edit-row input[type='number']:-ms-input-placeholder {
  color: #8d8a89;
  opacity: 1;
}

.form-control::placeholder,
.jsgrid .jsgrid-table .jsgrid-filter-row input[type='text']::placeholder,
.jsgrid .jsgrid-table .jsgrid-filter-row select::placeholder,
.jsgrid .jsgrid-table .jsgrid-edit-row input[type='text']::placeholder,
.jsgrid .jsgrid-table .jsgrid-edit-row select::placeholder,
.jsgrid .jsgrid-table .jsgrid-filter-row input[type='number']::placeholder,
.jsgrid .jsgrid-table .jsgrid-edit-row input[type='number']::placeholder {
  color: #8d8a89;
  opacity: 1;
}

.form-control:disabled,
.jsgrid .jsgrid-table .jsgrid-filter-row input:disabled[type='text'],
.jsgrid .jsgrid-table .jsgrid-filter-row select:disabled,
.jsgrid .jsgrid-table .jsgrid-edit-row input:disabled[type='text'],
.jsgrid .jsgrid-table .jsgrid-edit-row select:disabled,
.jsgrid .jsgrid-table .jsgrid-filter-row input:disabled[type='number'],
.jsgrid .jsgrid-table .jsgrid-edit-row input:disabled[type='number'],
.form-control[readonly],
.jsgrid .jsgrid-table .jsgrid-filter-row input[readonly][type='text'],
.jsgrid .jsgrid-table .jsgrid-filter-row select[readonly],
.jsgrid .jsgrid-table .jsgrid-edit-row input[readonly][type='text'],
.jsgrid .jsgrid-table .jsgrid-edit-row select[readonly],
.jsgrid .jsgrid-table .jsgrid-filter-row input[readonly][type='number'],
.jsgrid .jsgrid-table .jsgrid-edit-row input[readonly][type='number'] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value,
.jsgrid .jsgrid-table .jsgrid-filter-row select:focus::-ms-value,
.jsgrid .jsgrid-table .jsgrid-edit-row select:focus::-ms-value {
  color: #3f3f3f;
  background-color: #ffffff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.53125rem + 1px);
  padding-bottom: calc(0.53125rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.875rem + 1px);
  padding-bottom: calc(0.875rem + 1px);
  font-size: 0.96875rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.40625rem + 1px);
  padding-bottom: calc(0.40625rem + 1px);
  font-size: 0.71875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.53125rem;
  padding-bottom: 0.53125rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #222222;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.8125rem + 2px);
  padding: 0.40625rem 0.8125rem;
  font-size: 0.71875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1.75rem + 2px);
  padding: 0.875rem 1.3125rem;
  font-size: 0.96875rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size],
.jsgrid .jsgrid-table .jsgrid-filter-row select[size],
.jsgrid .jsgrid-table .jsgrid-edit-row select[size],
select.form-control[multiple],
.jsgrid .jsgrid-table .jsgrid-filter-row select[multiple],
.jsgrid .jsgrid-table .jsgrid-edit-row select[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*='col-'] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label {
  color: #8d8a89;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #00cb8e;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.4rem 0.75rem;
  margin-top: 0.1rem;
  font-size: 0.75rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(0, 203, 142, 0.9);
  border-radius: 0.375rem;
}

.was-validated .form-control:valid,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input:valid[type='text'],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .was-validated
  input:valid[type='text'],
.was-validated .jsgrid .jsgrid-table .jsgrid-filter-row select:valid,
.jsgrid .jsgrid-table .jsgrid-filter-row .was-validated select:valid,
.was-validated .jsgrid .jsgrid-table .jsgrid-edit-row input:valid[type='text'],
.jsgrid .jsgrid-table .jsgrid-edit-row .was-validated input:valid[type='text'],
.was-validated .jsgrid .jsgrid-table .jsgrid-edit-row select:valid,
.jsgrid .jsgrid-table .jsgrid-edit-row .was-validated select:valid,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input:valid[type='number'],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .was-validated
  input:valid[type='number'],
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  input:valid[type='number'],
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .was-validated
  input:valid[type='number'],
.form-control.is-valid,
.jsgrid .jsgrid-table .jsgrid-filter-row input.is-valid[type='text'],
.jsgrid .jsgrid-table .jsgrid-filter-row select.is-valid,
.jsgrid .jsgrid-table .jsgrid-edit-row input.is-valid[type='text'],
.jsgrid .jsgrid-table .jsgrid-edit-row select.is-valid,
.jsgrid .jsgrid-table .jsgrid-filter-row input.is-valid[type='number'],
.jsgrid .jsgrid-table .jsgrid-edit-row input.is-valid[type='number'] {
  border-color: #00cb8e;
  padding-right: calc(1.5em + 1.0625rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300CB8E' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.26563rem);
  background-size: calc(0.75em + 0.53125rem) calc(0.75em + 0.53125rem);
}

.was-validated .form-control:valid:focus,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input:valid:focus[type='text'],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .was-validated
  input:valid:focus[type='text'],
.was-validated .jsgrid .jsgrid-table .jsgrid-filter-row select:valid:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row .was-validated select:valid:focus,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  input:valid:focus[type='text'],
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .was-validated
  input:valid:focus[type='text'],
.was-validated .jsgrid .jsgrid-table .jsgrid-edit-row select:valid:focus,
.jsgrid .jsgrid-table .jsgrid-edit-row .was-validated select:valid:focus,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input:valid:focus[type='number'],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .was-validated
  input:valid:focus[type='number'],
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  input:valid:focus[type='number'],
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .was-validated
  input:valid:focus[type='number'],
.form-control.is-valid:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row input.is-valid:focus[type='text'],
.jsgrid .jsgrid-table .jsgrid-filter-row select.is-valid:focus,
.jsgrid .jsgrid-table .jsgrid-edit-row input.is-valid:focus[type='text'],
.jsgrid .jsgrid-table .jsgrid-edit-row select.is-valid:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row input.is-valid:focus[type='number'],
.jsgrid .jsgrid-table .jsgrid-edit-row input.is-valid:focus[type='number'] {
  border-color: #00cb8e;
  box-shadow: 0 0 0 0.2rem rgba(0, 203, 142, 0.25);
}

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input:valid[type='text']
  ~ .valid-feedback,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .was-validated
  input:valid[type='text']
  ~ .valid-feedback,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  select:valid
  ~ .valid-feedback,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .was-validated
  select:valid
  ~ .valid-feedback,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  input:valid[type='text']
  ~ .valid-feedback,
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .was-validated
  input:valid[type='text']
  ~ .valid-feedback,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  select:valid
  ~ .valid-feedback,
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .was-validated
  select:valid
  ~ .valid-feedback,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input:valid[type='number']
  ~ .valid-feedback,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .was-validated
  input:valid[type='number']
  ~ .valid-feedback,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  input:valid[type='number']
  ~ .valid-feedback,
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .was-validated
  input:valid[type='number']
  ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input:valid[type='text']
  ~ .valid-tooltip,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .was-validated
  input:valid[type='text']
  ~ .valid-tooltip,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  select:valid
  ~ .valid-tooltip,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .was-validated
  select:valid
  ~ .valid-tooltip,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  input:valid[type='text']
  ~ .valid-tooltip,
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .was-validated
  input:valid[type='text']
  ~ .valid-tooltip,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  select:valid
  ~ .valid-tooltip,
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .was-validated
  select:valid
  ~ .valid-tooltip,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input:valid[type='number']
  ~ .valid-tooltip,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .was-validated
  input:valid[type='number']
  ~ .valid-tooltip,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  input:valid[type='number']
  ~ .valid-tooltip,
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .was-validated
  input:valid[type='number']
  ~ .valid-tooltip,
.form-control.is-valid ~ .valid-feedback,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input.is-valid[type='text']
  ~ .valid-feedback,
.jsgrid .jsgrid-table .jsgrid-filter-row select.is-valid ~ .valid-feedback,
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  input.is-valid[type='text']
  ~ .valid-feedback,
.jsgrid .jsgrid-table .jsgrid-edit-row select.is-valid ~ .valid-feedback,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input.is-valid[type='number']
  ~ .valid-feedback,
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  input.is-valid[type='number']
  ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input.is-valid[type='text']
  ~ .valid-tooltip,
.jsgrid .jsgrid-table .jsgrid-filter-row select.is-valid ~ .valid-tooltip,
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  input.is-valid[type='text']
  ~ .valid-tooltip,
.jsgrid .jsgrid-table .jsgrid-edit-row select.is-valid ~ .valid-tooltip,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input.is-valid[type='number']
  ~ .valid-tooltip,
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  input.is-valid[type='number']
  ~ .valid-tooltip {
  display: block;
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 1.0625rem);
  background-position: top calc(0.375em + 0.26563rem) right
    calc(0.375em + 0.26563rem);
}

.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #00cb8e;
  padding-right: calc((1em + 1.0625rem) * 3 / 4 + 1.8125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      no-repeat right 0.8125rem center/8px 10px,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300CB8E' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")
      #ffffff no-repeat center right 1.8125rem / calc(0.75em + 0.53125rem)
      calc(0.75em + 0.53125rem);
}

.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #00cb8e;
  box-shadow: 0 0 0 0.2rem rgba(0, 203, 142, 0.25);
}

.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip,
.form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #00cb8e;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip,
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
  color: #00cb8e;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #00cb8e;
}

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip,
.custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated
  .custom-control-input:valid:checked
  ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #00feb2;
  background-color: #00feb2;
}

.was-validated
  .custom-control-input:valid:focus
  ~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 203, 142, 0.25);
}

.was-validated
  .custom-control-input:valid:focus:not(:checked)
  ~ .custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #00cb8e;
}

.was-validated .custom-file-input:valid ~ .custom-file-label,
.custom-file-input.is-valid ~ .custom-file-label {
  border-color: #00cb8e;
}

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip,
.custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label,
.custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #00cb8e;
  box-shadow: 0 0 0 0.2rem rgba(0, 203, 142, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #ef3e27;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.4rem 0.75rem;
  margin-top: 0.1rem;
  font-size: 0.75rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(255, 86, 102, 0.9);
  border-radius: 0.375rem;
}

.was-validated .form-control:invalid,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input:invalid[type='text'],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .was-validated
  input:invalid[type='text'],
.was-validated .jsgrid .jsgrid-table .jsgrid-filter-row select:invalid,
.jsgrid .jsgrid-table .jsgrid-filter-row .was-validated select:invalid,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  input:invalid[type='text'],
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .was-validated
  input:invalid[type='text'],
.was-validated .jsgrid .jsgrid-table .jsgrid-edit-row select:invalid,
.jsgrid .jsgrid-table .jsgrid-edit-row .was-validated select:invalid,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input:invalid[type='number'],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .was-validated
  input:invalid[type='number'],
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  input:invalid[type='number'],
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .was-validated
  input:invalid[type='number'],
.form-control.is-invalid,
.jsgrid .jsgrid-table .jsgrid-filter-row input.is-invalid[type='text'],
.jsgrid .jsgrid-table .jsgrid-filter-row select.is-invalid,
.jsgrid .jsgrid-table .jsgrid-edit-row input.is-invalid[type='text'],
.jsgrid .jsgrid-table .jsgrid-edit-row select.is-invalid,
.jsgrid .jsgrid-table .jsgrid-filter-row input.is-invalid[type='number'],
.jsgrid .jsgrid-table .jsgrid-edit-row input.is-invalid[type='number'] {
  border-color: #ef3e27;
  padding-right: calc(1.5em + 1.0625rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23FF5666' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23FF5666' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.26563rem);
  background-size: calc(0.75em + 0.53125rem) calc(0.75em + 0.53125rem);
}

.was-validated .form-control:invalid:focus,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input:invalid:focus[type='text'],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .was-validated
  input:invalid:focus[type='text'],
.was-validated .jsgrid .jsgrid-table .jsgrid-filter-row select:invalid:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row .was-validated select:invalid:focus,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  input:invalid:focus[type='text'],
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .was-validated
  input:invalid:focus[type='text'],
.was-validated .jsgrid .jsgrid-table .jsgrid-edit-row select:invalid:focus,
.jsgrid .jsgrid-table .jsgrid-edit-row .was-validated select:invalid:focus,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input:invalid:focus[type='number'],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .was-validated
  input:invalid:focus[type='number'],
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  input:invalid:focus[type='number'],
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .was-validated
  input:invalid:focus[type='number'],
.form-control.is-invalid:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row input.is-invalid:focus[type='text'],
.jsgrid .jsgrid-table .jsgrid-filter-row select.is-invalid:focus,
.jsgrid .jsgrid-table .jsgrid-edit-row input.is-invalid:focus[type='text'],
.jsgrid .jsgrid-table .jsgrid-edit-row select.is-invalid:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row input.is-invalid:focus[type='number'],
.jsgrid .jsgrid-table .jsgrid-edit-row input.is-invalid:focus[type='number'] {
  border-color: #ef3e27;
  box-shadow: 0 0 0 0.2rem rgba(255, 86, 102, 0.25);
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input:invalid[type='text']
  ~ .invalid-feedback,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .was-validated
  input:invalid[type='text']
  ~ .invalid-feedback,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  select:invalid
  ~ .invalid-feedback,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .was-validated
  select:invalid
  ~ .invalid-feedback,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  input:invalid[type='text']
  ~ .invalid-feedback,
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .was-validated
  input:invalid[type='text']
  ~ .invalid-feedback,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  select:invalid
  ~ .invalid-feedback,
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .was-validated
  select:invalid
  ~ .invalid-feedback,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input:invalid[type='number']
  ~ .invalid-feedback,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .was-validated
  input:invalid[type='number']
  ~ .invalid-feedback,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  input:invalid[type='number']
  ~ .invalid-feedback,
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .was-validated
  input:invalid[type='number']
  ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input:invalid[type='text']
  ~ .invalid-tooltip,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .was-validated
  input:invalid[type='text']
  ~ .invalid-tooltip,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  select:invalid
  ~ .invalid-tooltip,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .was-validated
  select:invalid
  ~ .invalid-tooltip,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  input:invalid[type='text']
  ~ .invalid-tooltip,
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .was-validated
  input:invalid[type='text']
  ~ .invalid-tooltip,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  select:invalid
  ~ .invalid-tooltip,
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .was-validated
  select:invalid
  ~ .invalid-tooltip,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input:invalid[type='number']
  ~ .invalid-tooltip,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .was-validated
  input:invalid[type='number']
  ~ .invalid-tooltip,
.was-validated
  .jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  input:invalid[type='number']
  ~ .invalid-tooltip,
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .was-validated
  input:invalid[type='number']
  ~ .invalid-tooltip,
.form-control.is-invalid ~ .invalid-feedback,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input.is-invalid[type='text']
  ~ .invalid-feedback,
.jsgrid .jsgrid-table .jsgrid-filter-row select.is-invalid ~ .invalid-feedback,
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  input.is-invalid[type='text']
  ~ .invalid-feedback,
.jsgrid .jsgrid-table .jsgrid-edit-row select.is-invalid ~ .invalid-feedback,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input.is-invalid[type='number']
  ~ .invalid-feedback,
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  input.is-invalid[type='number']
  ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input.is-invalid[type='text']
  ~ .invalid-tooltip,
.jsgrid .jsgrid-table .jsgrid-filter-row select.is-invalid ~ .invalid-tooltip,
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  input.is-invalid[type='text']
  ~ .invalid-tooltip,
.jsgrid .jsgrid-table .jsgrid-edit-row select.is-invalid ~ .invalid-tooltip,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input.is-invalid[type='number']
  ~ .invalid-tooltip,
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  input.is-invalid[type='number']
  ~ .invalid-tooltip {
  display: block;
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 1.0625rem);
  background-position: top calc(0.375em + 0.26563rem) right
    calc(0.375em + 0.26563rem);
}

.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #ef3e27;
  padding-right: calc((1em + 1.0625rem) * 3 / 4 + 1.8125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      no-repeat right 0.8125rem center/8px 10px,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23FF5666' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23FF5666' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E")
      #ffffff no-repeat center right 1.8125rem / calc(0.75em + 0.53125rem)
      calc(0.75em + 0.53125rem);
}

.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #ef3e27;
  box-shadow: 0 0 0 0.2rem rgba(255, 86, 102, 0.25);
}

.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip,
.form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #ef3e27;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label {
  color: #ef3e27;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #ef3e27;
}

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip,
.custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated
  .custom-control-input:invalid:checked
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #ff8994;
  background-color: #ff8994;
}

.was-validated
  .custom-control-input:invalid:focus
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(255, 86, 102, 0.25);
}

.was-validated
  .custom-control-input:invalid:focus:not(:checked)
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #ef3e27;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label,
.custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #ef3e27;
}

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip,
.custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #ef3e27;
  box-shadow: 0 0 0 0.2rem rgba(255, 86, 102, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control,
  .form-inline .jsgrid .jsgrid-table .jsgrid-filter-row input[type='text'],
  .jsgrid .jsgrid-table .jsgrid-filter-row .form-inline input[type='text'],
  .form-inline .jsgrid .jsgrid-table .jsgrid-filter-row select,
  .jsgrid .jsgrid-table .jsgrid-filter-row .form-inline select,
  .form-inline .jsgrid .jsgrid-table .jsgrid-edit-row input[type='text'],
  .jsgrid .jsgrid-table .jsgrid-edit-row .form-inline input[type='text'],
  .form-inline .jsgrid .jsgrid-table .jsgrid-edit-row select,
  .jsgrid .jsgrid-table .jsgrid-edit-row .form-inline select,
  .form-inline .jsgrid .jsgrid-table .jsgrid-filter-row input[type='number'],
  .jsgrid .jsgrid-table .jsgrid-filter-row .form-inline input[type='number'],
  .form-inline .jsgrid .jsgrid-table .jsgrid-edit-row input[type='number'],
  .jsgrid .jsgrid-table .jsgrid-edit-row .form-inline input[type='number'] {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn,
.wizard > .actions a,
.fc button {
  display: inline-block;
  font-weight: 400;
  color: #222222;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.53125rem 0.8125rem;
  font-size: 0.8125rem;
  line-height: 1.5;
  border-radius: 0px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn,
  .wizard > .actions a,
  .fc button {
    transition: none;
  }
}

.btn:hover,
.wizard > .actions a:hover,
.fc button:hover {
  color: #222222;
  text-decoration: none;
}

.btn:focus,
.wizard > .actions a:focus,
.fc button:focus,
.btn.focus,
.wizard > .actions a.focus,
.fc button.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(71, 101, 255, 0.25);
}

.btn.disabled,
.wizard > .actions a.disabled,
.fc button.disabled,
.btn:disabled,
.wizard > .actions a:disabled,
.fc button:disabled {
  opacity: 0.65;
}

a.btn.disabled,
.wizard > .actions a.disabled,
fieldset:disabled a.btn,
fieldset:disabled .wizard > .actions a,
.wizard > .actions fieldset:disabled a {
  pointer-events: none;
}

.btn-primary,
.wizard > .actions a {
  color: #ffffff;
  background-color: #ef3e27;
  border-color: #ef3e27;
}

.btn-primary:hover,
.wizard > .actions a:hover {
  color: #ffffff;
  background-color: #242a34;
  border-color: #242a34;
}

/* .btn-primary:focus, .wizard > .actions a:focus, .btn-primary.focus, .wizard > .actions a.focus {
	box-shadow: 0 0 0 0.2rem rgba(99, 124, 255, 0.5);
} */

.btn-primary.disabled,
.wizard > .actions a.disabled,
.btn-primary:disabled,
.wizard > .actions a:disabled {
  color: #ffffff;
  background-color: #ef3e27;
  border-color: #ef3e27;
}

/* .btn-primary:not(:disabled):not(.disabled):active, .wizard > .actions a:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .wizard > .actions a:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle,
.wizard > .actions .show > a.dropdown-toggle {
	color: #ffffff;
	background-color: #14181d;
	border-color: #0730ff;
} */

/* .btn-primary:not(:disabled):not(.disabled):active:focus, .wizard > .actions a:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .wizard > .actions a:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus,
.wizard > .actions .show > a.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(99, 124, 255, 0.5);
} */

.btn-secondary,
.daterangepicker .drp-buttons .btn.cancelBtn,
.daterangepicker .drp-buttons .wizard > .actions a.cancelBtn,
.wizard > .actions .daterangepicker .drp-buttons a.cancelBtn,
.daterangepicker .drp-buttons .fc button.cancelBtn,
.fc .daterangepicker .drp-buttons button.cancelBtn,
.wizard > .actions .disabled a {
  color: #212529;
  background-color: #d3dae6;
  border-color: #d3dae6;
}

.btn-secondary:hover,
.daterangepicker .drp-buttons .btn.cancelBtn:hover,
.daterangepicker .drp-buttons .wizard > .actions a.cancelBtn:hover,
.wizard > .actions .daterangepicker .drp-buttons a.cancelBtn:hover,
.daterangepicker .drp-buttons .fc button.cancelBtn:hover,
.fc .daterangepicker .drp-buttons button.cancelBtn:hover,
.wizard > .actions .disabled a:hover {
  color: #212529;
  background-color: #bbc5d8;
  border-color: #b2bfd4;
}

.btn-secondary:focus,
.daterangepicker .drp-buttons .btn.cancelBtn:focus,
.daterangepicker .drp-buttons .wizard > .actions a.cancelBtn:focus,
.wizard > .actions .daterangepicker .drp-buttons a.cancelBtn:focus,
.daterangepicker .drp-buttons .fc button.cancelBtn:focus,
.fc .daterangepicker .drp-buttons button.cancelBtn:focus,
.wizard > .actions .disabled a:focus,
.btn-secondary.focus,
.daterangepicker .drp-buttons .focus.btn.cancelBtn,
.daterangepicker .drp-buttons .wizard > .actions a.focus.cancelBtn,
.wizard > .actions .daterangepicker .drp-buttons a.focus.cancelBtn,
.daterangepicker .drp-buttons .fc button.focus.cancelBtn,
.fc .daterangepicker .drp-buttons button.focus.cancelBtn,
.wizard > .actions .disabled a.focus {
  box-shadow: 0 0 0 0.2rem rgba(184, 191, 202, 0.5);
}

.btn-secondary.disabled,
.daterangepicker .drp-buttons .disabled.btn.cancelBtn,
.daterangepicker .drp-buttons .wizard > .actions a.disabled.cancelBtn,
.wizard > .actions .daterangepicker .drp-buttons a.disabled.cancelBtn,
.daterangepicker .drp-buttons .fc button.disabled.cancelBtn,
.fc .daterangepicker .drp-buttons button.disabled.cancelBtn,
.wizard > .actions .disabled a.disabled,
.btn-secondary:disabled,
.daterangepicker .drp-buttons .btn.cancelBtn:disabled,
.daterangepicker .drp-buttons .wizard > .actions a.cancelBtn:disabled,
.wizard > .actions .daterangepicker .drp-buttons a.cancelBtn:disabled,
.daterangepicker .drp-buttons .fc button.cancelBtn:disabled,
.fc .daterangepicker .drp-buttons button.cancelBtn:disabled,
.wizard > .actions .disabled a:disabled {
  color: #212529;
  background-color: #d3dae6;
  border-color: #d3dae6;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.daterangepicker
  .drp-buttons
  .btn.cancelBtn:not(:disabled):not(.disabled):active,
.daterangepicker
  .drp-buttons
  .wizard
  > .actions
  a.cancelBtn:not(:disabled):not(.disabled):active,
.wizard
  > .actions
  .daterangepicker
  .drp-buttons
  a.cancelBtn:not(:disabled):not(.disabled):active,
.daterangepicker
  .drp-buttons
  .fc
  button.cancelBtn:not(:disabled):not(.disabled):active,
.fc
  .daterangepicker
  .drp-buttons
  button.cancelBtn:not(:disabled):not(.disabled):active,
.wizard > .actions .disabled a:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.daterangepicker
  .drp-buttons
  .btn.cancelBtn:not(:disabled):not(.disabled).active,
.daterangepicker
  .drp-buttons
  .wizard
  > .actions
  a.cancelBtn:not(:disabled):not(.disabled).active,
.wizard
  > .actions
  .daterangepicker
  .drp-buttons
  a.cancelBtn:not(:disabled):not(.disabled).active,
.daterangepicker
  .drp-buttons
  .fc
  button.cancelBtn:not(:disabled):not(.disabled).active,
.fc
  .daterangepicker
  .drp-buttons
  button.cancelBtn:not(:disabled):not(.disabled).active,
.wizard > .actions .disabled a:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle,
.daterangepicker .drp-buttons .show > .dropdown-toggle.btn.cancelBtn,
.daterangepicker
  .drp-buttons
  .wizard
  > .actions
  .show
  > a.dropdown-toggle.cancelBtn,
.wizard
  > .actions
  .daterangepicker
  .drp-buttons
  .show
  > a.dropdown-toggle.cancelBtn,
.daterangepicker .drp-buttons .fc .show > button.dropdown-toggle.cancelBtn,
.fc .daterangepicker .drp-buttons .show > button.dropdown-toggle.cancelBtn,
.wizard > .actions .disabled .show > a.dropdown-toggle {
  color: #212529;
  background-color: #b2bfd4;
  border-color: #aab8cf;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.daterangepicker
  .drp-buttons
  .btn.cancelBtn:not(:disabled):not(.disabled):active:focus,
.daterangepicker
  .drp-buttons
  .wizard
  > .actions
  a.cancelBtn:not(:disabled):not(.disabled):active:focus,
.wizard
  > .actions
  .daterangepicker
  .drp-buttons
  a.cancelBtn:not(:disabled):not(.disabled):active:focus,
.daterangepicker
  .drp-buttons
  .fc
  button.cancelBtn:not(:disabled):not(.disabled):active:focus,
.fc
  .daterangepicker
  .drp-buttons
  button.cancelBtn:not(:disabled):not(.disabled):active:focus,
.wizard > .actions .disabled a:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.daterangepicker
  .drp-buttons
  .btn.cancelBtn:not(:disabled):not(.disabled).active:focus,
.daterangepicker
  .drp-buttons
  .wizard
  > .actions
  a.cancelBtn:not(:disabled):not(.disabled).active:focus,
.wizard
  > .actions
  .daterangepicker
  .drp-buttons
  a.cancelBtn:not(:disabled):not(.disabled).active:focus,
.daterangepicker
  .drp-buttons
  .fc
  button.cancelBtn:not(:disabled):not(.disabled).active:focus,
.fc
  .daterangepicker
  .drp-buttons
  button.cancelBtn:not(:disabled):not(.disabled).active:focus,
.wizard > .actions .disabled a:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus,
.daterangepicker .drp-buttons .show > .dropdown-toggle.btn.cancelBtn:focus,
.daterangepicker
  .drp-buttons
  .wizard
  > .actions
  .show
  > a.dropdown-toggle.cancelBtn:focus,
.wizard
  > .actions
  .daterangepicker
  .drp-buttons
  .show
  > a.dropdown-toggle.cancelBtn:focus,
.daterangepicker
  .drp-buttons
  .fc
  .show
  > button.dropdown-toggle.cancelBtn:focus,
.fc
  .daterangepicker
  .drp-buttons
  .show
  > button.dropdown-toggle.cancelBtn:focus,
.wizard > .actions .disabled .show > a.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(184, 191, 202, 0.5);
}

.btn-success {
  color: #ffffff;
  background-color: #00cb8e;
  border-color: #00cb8e;
}

.btn-success:hover {
  color: #ffffff;
  background-color: #00a573;
  border-color: #00986a;
}

.btn-success:focus,
.btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 211, 159, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
  color: #ffffff;
  background-color: #00cb8e;
  border-color: #00cb8e;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #ffffff;
  background-color: #00986a;
  border-color: #008b61;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 211, 159, 0.5);
}

.btn-info {
  color: #ffffff;
  background-color: #00aaff;
  border-color: #00aaff;
}

.btn-info:hover {
  color: #ffffff;
  background-color: #0091d9;
  border-color: #0088cc;
}

.btn-info:focus,
.btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 183, 255, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
  color: #ffffff;
  background-color: #00aaff;
  border-color: #00aaff;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #ffffff;
  background-color: #0088cc;
  border-color: #0080bf;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 183, 255, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc555;
  border-color: #ffc555;
}

.btn-warning:hover {
  color: #212529;
  background-color: #ffb82f;
  border-color: #ffb422;
}

.btn-warning:focus,
.btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 173, 78, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #ffc555;
  border-color: #ffc555;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffb422;
  border-color: #ffaf15;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 173, 78, 0.5);
}

.btn-danger {
  color: #ffffff;
  background-color: #ef3e27;
  border-color: #ef3e27;
}

.btn-danger:hover {
  color: #ffffff;
  background-color: #231f20;
  border-color: #231f20;
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #ffffff;
  background-color: #ef3e27;
  border-color: #ef3e27;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #ff2338;
  border-color: #ff162c;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 111, 125, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f9fafb;
  border-color: #f9fafb;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e7ec;
  border-color: #dae1e7;
}

.btn-light:focus,
.btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(217, 218, 220, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #f9fafb;
  border-color: #f9fafb;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae1e7;
  border-color: #d3dae2;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(217, 218, 220, 0.5);
}

.btn-dark {
  color: #ffffff !important;
  background-color: #242a34;
  border-color: #242a34;
}

.btn-dark:hover {
  color: #ffffff;
  background-color: #14181d;
  border-color: #0f1216;
}

.btn-dark:focus,
.btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(69, 74, 82, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: #ffffff;
  background-color: #242a34;
  border-color: #242a34;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #0f1216;
  border-color: #0a0c0e;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(69, 74, 82, 0.5);
}

.btn-outline-primary {
  color: #ef3e27;
  border-color: #ef3e27;
}

.btn-outline-primary:hover {
  color: #ffffff;
  background-color: #ef3e27;
  border-color: #ef3e27;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(71, 101, 255, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #ef3e27;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #ef3e27;
  border-color: #ef3e27;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(71, 101, 255, 0.5);
}

.btn-outline-secondary {
  color: #d3dae6;
  border-color: #d3dae6;
}

.btn-outline-secondary:hover {
  color: #212529;
  background-color: #d3dae6;
  border-color: #d3dae6;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(211, 218, 230, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #d3dae6;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #212529;
  background-color: #d3dae6;
  border-color: #d3dae6;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(211, 218, 230, 0.5);
}

.btn-outline-success {
  color: #00cb8e;
  border-color: #00cb8e;
}

.btn-outline-success:hover {
  color: #ffffff;
  background-color: #00cb8e;
  border-color: #00cb8e;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 203, 142, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #00cb8e;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #ffffff;
  background-color: #00cb8e;
  border-color: #00cb8e;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 203, 142, 0.5);
}

.btn-outline-info {
  color: #00aaff;
  border-color: #00aaff;
}

.btn-outline-info:hover {
  color: #ffffff;
  background-color: #00aaff;
  border-color: #00aaff;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 170, 255, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #00aaff;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #ffffff;
  background-color: #00aaff;
  border-color: #00aaff;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 170, 255, 0.5);
}

.btn-outline-warning {
  color: #ffc555;
  border-color: #ffc555;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc555;
  border-color: #ffc555;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 197, 85, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffc555;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc555;
  border-color: #ffc555;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 197, 85, 0.5);
}

.btn-outline-danger {
  color: #ef3e27;
  border-color: #ef3e27;
}

.btn-outline-danger:hover {
  color: #ffffff;
  background-color: #ef3e27;
  border-color: #ef3e27;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 86, 102, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #ef3e27;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #ef3e27;
  border-color: #ef3e27;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 86, 102, 0.5);
}

.btn-outline-light {
  color: #f9fafb;
  border-color: #f9fafb;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f9fafb;
  border-color: #f9fafb;
}

.btn-outline-light:focus,
.btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(249, 250, 251, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f9fafb;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f9fafb;
  border-color: #f9fafb;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(249, 250, 251, 0.5);
}

.btn-outline-dark {
  color: #242a34;
  border-color: #242a34;
}

.btn-outline-dark:hover {
  color: #ffffff;
  background-color: #242a34;
  border-color: #242a34;
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(36, 42, 52, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #242a34;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #242a34;
  border-color: #242a34;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(36, 42, 52, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #ef3e27;
  text-decoration: none;
}

.btn-link:hover {
  color: #0029fa;
  text-decoration: underline;
}

.btn-link:focus,
.btn-link.focus {
  text-decoration: underline;
  box-shadow: none;
}

.btn-link:disabled,
.btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg,
.btn-group-lg > .btn,
.wizard > .actions .btn-group-lg > a,
.fc .btn-group-lg > button {
  padding: 0.875rem 1.3125rem;
  font-size: 0.96875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.btn-sm,
.btn-group-sm > .btn,
.wizard > .actions .btn-group-sm > a,
.fc .btn-group-sm > button,
.fc button {
  padding: 0.40625rem 0.8125rem;
  font-size: 0.71875rem;
  line-height: 1.5;
  border-radius: 0;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type='submit'].btn-block,
input[type='reset'].btn-block,
input[type='button'].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #222222;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #e6e4e9;
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^='top'],
.dropdown-menu[x-placement^='right'],
.dropdown-menu[x-placement^='bottom'],
.dropdown-menu[x-placement^='left'] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e6e4e9;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #8d8a89;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #ffffff;
  text-decoration: none;
  background-color: #ef3e27;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #222222;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #8d8a89;
}

.btn-group,
.fc .fc-button-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.fc .fc-button-group > .btn,
.wizard > .actions .btn-group > a,
.fc .wizard > .actions .fc-button-group > a,
.wizard > .actions .fc .fc-button-group > a,
.fc .btn-group > button,
.fc .fc-button-group > button,
.btn-group-vertical > .btn,
.wizard > .actions .btn-group-vertical > a,
.fc .btn-group-vertical > button {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn:hover,
.fc .fc-button-group > .btn:hover,
.wizard > .actions .btn-group > a:hover,
.fc .wizard > .actions .fc-button-group > a:hover,
.wizard > .actions .fc .fc-button-group > a:hover,
.fc .btn-group > button:hover,
.fc .fc-button-group > button:hover,
.btn-group-vertical > .btn:hover,
.wizard > .actions .btn-group-vertical > a:hover,
.fc .btn-group-vertical > button:hover {
  z-index: 1;
}

.btn-group > .btn:focus,
.fc .fc-button-group > .btn:focus,
.wizard > .actions .btn-group > a:focus,
.fc .wizard > .actions .fc-button-group > a:focus,
.wizard > .actions .fc .fc-button-group > a:focus,
.fc .btn-group > button:focus,
.fc .fc-button-group > button:focus,
.btn-group > .btn:active,
.fc .fc-button-group > .btn:active,
.wizard > .actions .btn-group > a:active,
.fc .wizard > .actions .fc-button-group > a:active,
.wizard > .actions .fc .fc-button-group > a:active,
.fc .btn-group > button:active,
.fc .fc-button-group > button:active,
.btn-group > .btn.active,
.fc .fc-button-group > .btn.active,
.wizard > .actions .btn-group > a.active,
.fc .wizard > .actions .fc-button-group > a.active,
.wizard > .actions .fc .fc-button-group > a.active,
.fc .btn-group > button.active,
.fc .fc-button-group > button.active,
.btn-group-vertical > .btn:focus,
.wizard > .actions .btn-group-vertical > a:focus,
.fc .btn-group-vertical > button:focus,
.btn-group-vertical > .btn:active,
.wizard > .actions .btn-group-vertical > a:active,
.fc .btn-group-vertical > button:active,
.btn-group-vertical > .btn.active,
.wizard > .actions .btn-group-vertical > a.active,
.fc .btn-group-vertical > button.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.fc .fc-button-group > .btn:not(:first-child),
.wizard > .actions .btn-group > a:not(:first-child),
.fc .wizard > .actions .fc-button-group > a:not(:first-child),
.wizard > .actions .fc .fc-button-group > a:not(:first-child),
.fc .btn-group > button:not(:first-child),
.fc .fc-button-group > button:not(:first-child),
.btn-group > .btn-group:not(:first-child),
.fc .fc-button-group > .btn-group:not(:first-child),
.fc .btn-group > .fc-button-group:not(:first-child),
.fc .fc-button-group > .fc-button-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.fc .fc-button-group > .btn:not(:last-child):not(.dropdown-toggle),
.wizard > .actions .btn-group > a:not(:last-child):not(.dropdown-toggle),
.fc
  .wizard
  > .actions
  .fc-button-group
  > a:not(:last-child):not(.dropdown-toggle),
.wizard
  > .actions
  .fc
  .fc-button-group
  > a:not(:last-child):not(.dropdown-toggle),
.fc .btn-group > button:not(:last-child):not(.dropdown-toggle),
.fc .fc-button-group > button:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn,
.fc .fc-button-group > .btn-group:not(:last-child) > .btn,
.fc .btn-group > .fc-button-group:not(:last-child) > .btn,
.fc .fc-button-group > .fc-button-group:not(:last-child) > .btn,
.wizard > .actions .btn-group > .btn-group:not(:last-child) > a,
.fc .wizard > .actions .fc-button-group > .btn-group:not(:last-child) > a,
.wizard > .actions .fc .fc-button-group > .btn-group:not(:last-child) > a,
.fc .wizard > .actions .btn-group > .fc-button-group:not(:last-child) > a,
.wizard > .actions .fc .btn-group > .fc-button-group:not(:last-child) > a,
.fc .wizard > .actions .fc-button-group > .fc-button-group:not(:last-child) > a,
.wizard > .actions .fc .fc-button-group > .fc-button-group:not(:last-child) > a,
.fc .btn-group > .btn-group:not(:last-child) > button,
.fc .fc-button-group > .btn-group:not(:last-child) > button,
.fc .btn-group > .fc-button-group:not(:last-child) > button,
.fc .fc-button-group > .fc-button-group:not(:last-child) > button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.fc .fc-button-group > .btn:not(:first-child),
.wizard > .actions .btn-group > a:not(:first-child),
.fc .wizard > .actions .fc-button-group > a:not(:first-child),
.wizard > .actions .fc .fc-button-group > a:not(:first-child),
.fc .btn-group > button:not(:first-child),
.fc .fc-button-group > button:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn,
.fc .fc-button-group > .btn-group:not(:first-child) > .btn,
.fc .btn-group > .fc-button-group:not(:first-child) > .btn,
.fc .fc-button-group > .fc-button-group:not(:first-child) > .btn,
.wizard > .actions .btn-group > .btn-group:not(:first-child) > a,
.fc .wizard > .actions .fc-button-group > .btn-group:not(:first-child) > a,
.wizard > .actions .fc .fc-button-group > .btn-group:not(:first-child) > a,
.fc .wizard > .actions .btn-group > .fc-button-group:not(:first-child) > a,
.wizard > .actions .fc .btn-group > .fc-button-group:not(:first-child) > a,
.fc
  .wizard
  > .actions
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > a,
.wizard
  > .actions
  .fc
  .fc-button-group
  > .fc-button-group:not(:first-child)
  > a,
.fc .btn-group > .btn-group:not(:first-child) > button,
.fc .fc-button-group > .btn-group:not(:first-child) > button,
.fc .btn-group > .fc-button-group:not(:first-child) > button,
.fc .fc-button-group > .fc-button-group:not(:first-child) > button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.60938rem;
  padding-left: 0.60938rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split,
.wizard > .actions .btn-group-sm > a + .dropdown-toggle-split,
.fc .btn-group-sm > button + .dropdown-toggle-split,
.fc button + .dropdown-toggle-split {
  padding-right: 0.60938rem;
  padding-left: 0.60938rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split,
.wizard > .actions .btn-group-lg > a + .dropdown-toggle-split,
.fc .btn-group-lg > button + .dropdown-toggle-split {
  padding-right: 0.98438rem;
  padding-left: 0.98438rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.wizard > .actions .btn-group-vertical > a,
.fc .btn-group-vertical > button,
.btn-group-vertical > .btn-group,
.fc .btn-group-vertical > .fc-button-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.wizard > .actions .btn-group-vertical > a:not(:first-child),
.fc .btn-group-vertical > button:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child),
.fc .btn-group-vertical > .fc-button-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.wizard
  > .actions
  .btn-group-vertical
  > a:not(:last-child):not(.dropdown-toggle),
.fc .btn-group-vertical > button:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.fc .btn-group-vertical > .fc-button-group:not(:last-child) > .btn,
.wizard > .actions .btn-group-vertical > .btn-group:not(:last-child) > a,
.fc
  .wizard
  > .actions
  .btn-group-vertical
  > .fc-button-group:not(:last-child)
  > a,
.wizard
  > .actions
  .fc
  .btn-group-vertical
  > .fc-button-group:not(:last-child)
  > a,
.fc .btn-group-vertical > .btn-group:not(:last-child) > button,
.fc .btn-group-vertical > .fc-button-group:not(:last-child) > button {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.wizard > .actions .btn-group-vertical > a:not(:first-child),
.fc .btn-group-vertical > button:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.fc .btn-group-vertical > .fc-button-group:not(:first-child) > .btn,
.wizard > .actions .btn-group-vertical > .btn-group:not(:first-child) > a,
.fc
  .wizard
  > .actions
  .btn-group-vertical
  > .fc-button-group:not(:first-child)
  > a,
.wizard
  > .actions
  .fc
  .btn-group-vertical
  > .fc-button-group:not(:first-child)
  > a,
.fc .btn-group-vertical > .btn-group:not(:first-child) > button,
.fc .btn-group-vertical > .fc-button-group:not(:first-child) > button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.wizard > .actions .btn-group-toggle > a,
.fc .btn-group-toggle > button,
.btn-group-toggle > .btn-group > .btn,
.fc .btn-group-toggle > .fc-button-group > .btn,
.wizard > .actions .btn-group-toggle > .btn-group > a,
.fc .wizard > .actions .btn-group-toggle > .fc-button-group > a,
.wizard > .actions .fc .btn-group-toggle > .fc-button-group > a,
.fc .btn-group-toggle > .btn-group > button,
.fc .btn-group-toggle > .fc-button-group > button {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type='radio'],
.wizard > .actions .btn-group-toggle > a input[type='radio'],
.fc .btn-group-toggle > button input[type='radio'],
.btn-group-toggle > .btn input[type='checkbox'],
.wizard > .actions .btn-group-toggle > a input[type='checkbox'],
.fc .btn-group-toggle > button input[type='checkbox'],
.btn-group-toggle > .btn-group > .btn input[type='radio'],
.fc .btn-group-toggle > .fc-button-group > .btn input[type='radio'],
.wizard > .actions .btn-group-toggle > .btn-group > a input[type='radio'],
.fc
  .wizard
  > .actions
  .btn-group-toggle
  > .fc-button-group
  > a
  input[type='radio'],
.wizard
  > .actions
  .fc
  .btn-group-toggle
  > .fc-button-group
  > a
  input[type='radio'],
.fc .btn-group-toggle > .btn-group > button input[type='radio'],
.fc .btn-group-toggle > .fc-button-group > button input[type='radio'],
.btn-group-toggle > .btn-group > .btn input[type='checkbox'],
.fc .btn-group-toggle > .fc-button-group > .btn input[type='checkbox'],
.wizard > .actions .btn-group-toggle > .btn-group > a input[type='checkbox'],
.fc
  .wizard
  > .actions
  .btn-group-toggle
  > .fc-button-group
  > a
  input[type='checkbox'],
.wizard
  > .actions
  .fc
  .btn-group-toggle
  > .fc-button-group
  > a
  input[type='checkbox'],
.fc .btn-group-toggle > .btn-group > button input[type='checkbox'],
.fc .btn-group-toggle > .fc-button-group > button input[type='checkbox'] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.jsgrid .jsgrid-table .jsgrid-filter-row .input-group > input[type='text'],
.jsgrid .jsgrid-table .jsgrid-filter-row .input-group > select,
.jsgrid .jsgrid-table .jsgrid-edit-row .input-group > input[type='text'],
.jsgrid .jsgrid-table .jsgrid-edit-row .input-group > select,
.jsgrid .jsgrid-table .jsgrid-filter-row .input-group > input[type='number'],
.jsgrid .jsgrid-table .jsgrid-edit-row .input-group > input[type='number'],
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group
  > input[type='text']
  + .form-control,
.jsgrid .jsgrid-table .jsgrid-filter-row .input-group > select + .form-control,
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .input-group
  > input[type='text']
  + .form-control,
.jsgrid .jsgrid-table .jsgrid-edit-row .input-group > select + .form-control,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group
  > input[type='number']
  + .form-control,
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .input-group
  > input[type='number']
  + .form-control,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group
  > .form-control
  + input[type='text'],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group
  > input[type='text']
  + input[type='text'],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group
  > select
  + input[type='text'],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group
  > input[type='number']
  + input[type='text'],
.jsgrid .jsgrid-table .jsgrid-filter-row .input-group > .form-control + select,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group
  > input[type='text']
  + select,
.jsgrid .jsgrid-table .jsgrid-filter-row .input-group > select + select,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group
  > input[type='number']
  + select,
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .input-group
  > .form-control
  + input[type='text'],
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .input-group
  > input[type='text']
  + input[type='text'],
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .input-group
  > select
  + input[type='text'],
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .input-group
  > input[type='number']
  + input[type='text'],
.jsgrid .jsgrid-table .jsgrid-edit-row .input-group > .form-control + select,
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .input-group
  > input[type='text']
  + select,
.jsgrid .jsgrid-table .jsgrid-edit-row .input-group > select + select,
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .input-group
  > input[type='number']
  + select,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group
  > .form-control
  + input[type='number'],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group
  > input[type='text']
  + input[type='number'],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group
  > select
  + input[type='number'],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group
  > input[type='number']
  + input[type='number'],
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .input-group
  > .form-control
  + input[type='number'],
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .input-group
  > input[type='text']
  + input[type='number'],
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .input-group
  > select
  + input[type='number'],
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .input-group
  > input[type='number']
  + input[type='number'],
.input-group > .form-control + .custom-select,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group
  > input[type='text']
  + .custom-select,
.jsgrid .jsgrid-table .jsgrid-filter-row .input-group > select + .custom-select,
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .input-group
  > input[type='text']
  + .custom-select,
.jsgrid .jsgrid-table .jsgrid-edit-row .input-group > select + .custom-select,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group
  > input[type='number']
  + .custom-select,
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .input-group
  > input[type='number']
  + .custom-select,
.input-group > .form-control + .custom-file,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group
  > input[type='text']
  + .custom-file,
.jsgrid .jsgrid-table .jsgrid-filter-row .input-group > select + .custom-file,
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .input-group
  > input[type='text']
  + .custom-file,
.jsgrid .jsgrid-table .jsgrid-edit-row .input-group > select + .custom-file,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group
  > input[type='number']
  + .custom-file,
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .input-group
  > input[type='number']
  + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group
  > .form-control-plaintext
  + input[type='text'],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group
  > .form-control-plaintext
  + select,
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .input-group
  > .form-control-plaintext
  + input[type='text'],
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .input-group
  > .form-control-plaintext
  + select,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group
  > .form-control-plaintext
  + input[type='number'],
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .input-group
  > .form-control-plaintext
  + input[type='number'],
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group
  > .custom-select
  + input[type='text'],
.jsgrid .jsgrid-table .jsgrid-filter-row .input-group > .custom-select + select,
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .input-group
  > .custom-select
  + input[type='text'],
.jsgrid .jsgrid-table .jsgrid-edit-row .input-group > .custom-select + select,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group
  > .custom-select
  + input[type='number'],
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .input-group
  > .custom-select
  + input[type='number'],
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group
  > .custom-file
  + input[type='text'],
.jsgrid .jsgrid-table .jsgrid-filter-row .input-group > .custom-file + select,
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .input-group
  > .custom-file
  + input[type='text'],
.jsgrid .jsgrid-table .jsgrid-edit-row .input-group > .custom-file + select,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group
  > .custom-file
  + input[type='number'],
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .input-group
  > .custom-file
  + input[type='number'],
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group
  > input:focus[type='text'],
.jsgrid .jsgrid-table .jsgrid-filter-row .input-group > select:focus,
.jsgrid .jsgrid-table .jsgrid-edit-row .input-group > input:focus[type='text'],
.jsgrid .jsgrid-table .jsgrid-edit-row .input-group > select:focus,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group
  > input:focus[type='number'],
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .input-group
  > input:focus[type='number'],
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:last-child),
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group
  > input:not(:last-child)[type='text'],
.jsgrid .jsgrid-table .jsgrid-filter-row .input-group > select:not(:last-child),
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .input-group
  > input:not(:last-child)[type='text'],
.jsgrid .jsgrid-table .jsgrid-edit-row .input-group > select:not(:last-child),
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group
  > input:not(:last-child)[type='number'],
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .input-group
  > input:not(:last-child)[type='number'],
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group
  > input:not(:first-child)[type='text'],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group
  > select:not(:first-child),
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .input-group
  > input:not(:first-child)[type='text'],
.jsgrid .jsgrid-table .jsgrid-edit-row .input-group > select:not(:first-child),
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group
  > input:not(:first-child)[type='number'],
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .input-group
  > input:not(:first-child)[type='number'],
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-prepend .wizard > .actions a,
.wizard > .actions .input-group-prepend a,
.input-group-prepend .fc button,
.fc .input-group-prepend button,
.input-group-append .btn,
.input-group-append .wizard > .actions a,
.wizard > .actions .input-group-append a,
.input-group-append .fc button,
.fc .input-group-append button {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-prepend .wizard > .actions a:focus,
.wizard > .actions .input-group-prepend a:focus,
.input-group-prepend .fc button:focus,
.fc .input-group-prepend button:focus,
.input-group-append .btn:focus,
.input-group-append .wizard > .actions a:focus,
.wizard > .actions .input-group-append a:focus,
.input-group-append .fc button:focus,
.fc .input-group-append button:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .wizard > .actions a + .btn,
.wizard > .actions .input-group-prepend a + .btn,
.input-group-prepend .fc button + .btn,
.fc .input-group-prepend button + .btn,
.input-group-prepend .wizard > .actions .btn + a,
.wizard > .actions .input-group-prepend .btn + a,
.input-group-prepend .wizard > .actions a + a,
.wizard > .actions .input-group-prepend a + a,
.input-group-prepend .fc .wizard > .actions button + a,
.wizard > .actions .input-group-prepend .fc button + a,
.fc .input-group-prepend .wizard > .actions button + a,
.wizard > .actions .fc .input-group-prepend button + a,
.input-group-prepend .fc .btn + button,
.fc .input-group-prepend .btn + button,
.input-group-prepend .wizard > .actions .fc a + button,
.fc .input-group-prepend .wizard > .actions a + button,
.wizard > .actions .input-group-prepend .fc a + button,
.fc .wizard > .actions .input-group-prepend a + button,
.input-group-prepend .fc button + button,
.fc .input-group-prepend button + button,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .wizard > .actions a + .input-group-text,
.wizard > .actions .input-group-prepend a + .input-group-text,
.input-group-prepend .fc button + .input-group-text,
.fc .input-group-prepend button + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-prepend .wizard > .actions .input-group-text + a,
.wizard > .actions .input-group-prepend .input-group-text + a,
.input-group-prepend .fc .input-group-text + button,
.fc .input-group-prepend .input-group-text + button,
.input-group-append .btn + .btn,
.input-group-append .wizard > .actions a + .btn,
.wizard > .actions .input-group-append a + .btn,
.input-group-append .fc button + .btn,
.fc .input-group-append button + .btn,
.input-group-append .wizard > .actions .btn + a,
.wizard > .actions .input-group-append .btn + a,
.input-group-append .wizard > .actions a + a,
.wizard > .actions .input-group-append a + a,
.input-group-append .fc .wizard > .actions button + a,
.wizard > .actions .input-group-append .fc button + a,
.fc .input-group-append .wizard > .actions button + a,
.wizard > .actions .fc .input-group-append button + a,
.input-group-append .fc .btn + button,
.fc .input-group-append .btn + button,
.input-group-append .wizard > .actions .fc a + button,
.fc .input-group-append .wizard > .actions a + button,
.wizard > .actions .input-group-append .fc a + button,
.fc .wizard > .actions .input-group-append a + button,
.input-group-append .fc button + button,
.fc .input-group-append button + button,
.input-group-append .btn + .input-group-text,
.input-group-append .wizard > .actions a + .input-group-text,
.wizard > .actions .input-group-append a + .input-group-text,
.input-group-append .fc button + .input-group-text,
.fc .input-group-append button + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn,
.input-group-append .wizard > .actions .input-group-text + a,
.wizard > .actions .input-group-append .input-group-text + a,
.input-group-append .fc .input-group-text + button,
.fc .input-group-append .input-group-text + button {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.53125rem 0.8125rem;
  margin-bottom: 0;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3f3f3f;
  text-align: center;
  white-space: nowrap;
  background-color: #f5f5f7;
  border: 1px solid #e6e4e9;
  border-radius: 0.25rem;
}

.input-group-text input[type='radio'],
.input-group-text input[type='checkbox'] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group-lg
  > input:not(textarea)[type='text'],
.jsgrid .jsgrid-table .jsgrid-filter-row .input-group-lg > select:not(textarea),
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .input-group-lg
  > input:not(textarea)[type='text'],
.jsgrid .jsgrid-table .jsgrid-edit-row .input-group-lg > select:not(textarea),
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group-lg
  > input:not(textarea)[type='number'],
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .input-group-lg
  > input:not(textarea)[type='number'],
.input-group-lg > .custom-select {
  height: calc(1.5em + 1.75rem + 2px);
}

.input-group-lg > .form-control,
.jsgrid .jsgrid-table .jsgrid-filter-row .input-group-lg > input[type='text'],
.jsgrid .jsgrid-table .jsgrid-filter-row .input-group-lg > select,
.jsgrid .jsgrid-table .jsgrid-edit-row .input-group-lg > input[type='text'],
.jsgrid .jsgrid-table .jsgrid-edit-row .input-group-lg > select,
.jsgrid .jsgrid-table .jsgrid-filter-row .input-group-lg > input[type='number'],
.jsgrid .jsgrid-table .jsgrid-edit-row .input-group-lg > input[type='number'],
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.wizard > .actions .input-group-lg > .input-group-prepend > a,
.fc .input-group-lg > .input-group-prepend > button,
.input-group-lg > .input-group-append > .btn,
.wizard > .actions .input-group-lg > .input-group-append > a,
.fc .input-group-lg > .input-group-append > button {
  padding: 0.875rem 1.3125rem;
  font-size: 0.96875rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group-sm
  > input:not(textarea)[type='text'],
.jsgrid .jsgrid-table .jsgrid-filter-row .input-group-sm > select:not(textarea),
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .input-group-sm
  > input:not(textarea)[type='text'],
.jsgrid .jsgrid-table .jsgrid-edit-row .input-group-sm > select:not(textarea),
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .input-group-sm
  > input:not(textarea)[type='number'],
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .input-group-sm
  > input:not(textarea)[type='number'],
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.8125rem + 2px);
}

.input-group-sm > .form-control,
.jsgrid .jsgrid-table .jsgrid-filter-row .input-group-sm > input[type='text'],
.jsgrid .jsgrid-table .jsgrid-filter-row .input-group-sm > select,
.jsgrid .jsgrid-table .jsgrid-edit-row .input-group-sm > input[type='text'],
.jsgrid .jsgrid-table .jsgrid-edit-row .input-group-sm > select,
.jsgrid .jsgrid-table .jsgrid-filter-row .input-group-sm > input[type='number'],
.jsgrid .jsgrid-table .jsgrid-edit-row .input-group-sm > input[type='number'],
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.wizard > .actions .input-group-sm > .input-group-prepend > a,
.fc .input-group-sm > .input-group-prepend > button,
.input-group-sm > .input-group-append > .btn,
.wizard > .actions .input-group-sm > .input-group-append > a,
.fc .input-group-sm > .input-group-append > button {
  padding: 0.40625rem 0.8125rem;
  font-size: 0.71875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.8125rem;
}

.input-group > .input-group-prepend > .btn,
.wizard > .actions .input-group > .input-group-prepend > a,
.fc .input-group > .input-group-prepend > button,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.wizard > .actions .input-group > .input-group-append:not(:last-child) > a,
.fc .input-group > .input-group-append:not(:last-child) > button,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.wizard
  > .actions
  .input-group
  > .input-group-append:last-child
  > a:not(:last-child):not(.dropdown-toggle),
.fc
  .input-group
  > .input-group-append:last-child
  > button:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.wizard > .actions .input-group > .input-group-append > a,
.fc .input-group > .input-group-append > button,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.wizard > .actions .input-group > .input-group-prepend:not(:first-child) > a,
.fc .input-group > .input-group-prepend:not(:first-child) > button,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.wizard
  > .actions
  .input-group
  > .input-group-prepend:first-child
  > a:not(:first-child),
.fc .input-group > .input-group-prepend:first-child > button:not(:first-child),
.input-group
  > .input-group-prepend:first-child
  > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  border-color: #ef3e27;
  background-color: #ef3e27;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(71, 101, 255, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #ef3e27;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #ffffff;
  background-color: #fafaff;
  border-color: #fafaff;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: '';
  background-color: #ffffff;
  border: #adb5bd solid 1px;
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: '';
  background: no-repeat 50% / 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23ffffff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  border-color: #ef3e27;
  background-color: #ef3e27;
}

.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23ffffff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(71, 101, 255, 0.5);
}

.custom-checkbox
  .custom-control-input:disabled:indeterminate
  ~ .custom-control-label::before {
  background-color: rgba(71, 101, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}

.custom-radio
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(71, 101, 255, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #ffffff;
  -webkit-transform: translateX(0.75rem);
          transform: translateX(0.75rem);
}

.custom-switch
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(71, 101, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1.0625rem + 2px);
  padding: 0.53125rem 1.8125rem 0.53125rem 0.8125rem;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3f3f3f;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    no-repeat right 0.8125rem center/8px 10px;
  background-color: #ffffff;
  border: 1px solid #e6e4e9;
  border-radius: 0.25rem;
  -webkit-appearance: none;
          appearance: none;
}

.custom-select:focus {
  border-color: #ef3e27;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(71, 101, 255, 0.25);
}

.custom-select:focus::-ms-value {
  color: #3f3f3f;
  background-color: #ffffff;
}

.custom-select[multiple],
.custom-select[size]:not([size='1']) {
  height: auto;
  padding-right: 0.8125rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select-sm {
  height: calc(1.5em + 0.8125rem + 2px);
  padding-top: 0.40625rem;
  padding-bottom: 0.40625rem;
  padding-left: 0.8125rem;
  font-size: 0.71875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1.75rem + 2px);
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
  padding-left: 1.3125rem;
  font-size: 0.96875rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1.0625rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 1.0625rem + 2px);
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #ef3e27;
  box-shadow: 0 0 0 0.2rem rgba(71, 101, 255, 0.25);
}

.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: 'Browse';
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 1.0625rem + 2px);
  padding: 0.53125rem 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3f3f3f;
  background-color: #ffffff;
  border: 1px solid #e6e4e9;
  border-radius: 0.25rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 1.0625rem);
  padding: 0.53125rem 0.8125rem;
  line-height: 1.5;
  color: #3f3f3f;
  content: 'Browse';
  background-color: #f5f5f7;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
          appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(71, 101, 255, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(71, 101, 255, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(71, 101, 255, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #ef3e27;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
          appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #fafaff;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #ef3e27;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #fafaff;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #ef3e27;
  border: 0;
  border-radius: 1rem;
  -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    -ms-transition: none;
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #fafaff;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #e6e4e9;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e6e4e9 #e6e4e9 #e6e4e9;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #ffffff;
  border-color: #e6e4e9 #e6e4e9 #ffffff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: #ef3e27;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: '';
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #ffffff;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #ffffff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #ffffff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #ffffff;
}

.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #ffffff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid #e6e4e9;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid #e6e4e9;
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid #e6e4e9;
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck {
  display: flex;
  flex-direction: column;
}

.card-deck .card {
  margin-bottom: 10px;
}

@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -10px;
    margin-left: -10px;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0;
    flex-direction: column;
    margin-right: 10px;
    margin-bottom: 0;
    margin-left: 10px;
  }
}

.card-group {
  display: flex;
  flex-direction: column;
}

.card-group > .card {
  margin-bottom: 10px;
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
            column-count: 3;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
            column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}

.accordion > .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}

.accordion > .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card .card-header {
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.56rem 1.13rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: '/';
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination,
.jsgrid .jsgrid-pager {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .jsgrid-pager-page a {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #ef3e27;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
}

.page-link:hover,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button a:hover,
.jsgrid .jsgrid-pager .jsgrid-pager-page a:hover {
  z-index: 2;
  color: #0029fa;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button a:focus,
.jsgrid .jsgrid-pager .jsgrid-pager-page a:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(71, 101, 255, 0.25);
}

.page-item:first-child .page-link,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button:first-child .page-link,
.jsgrid .jsgrid-pager .jsgrid-pager-page:first-child .page-link,
.page-item:first-child .jsgrid .jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button .page-item:first-child a,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button:first-child a,
.page-item:first-child .jsgrid .jsgrid-pager .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .jsgrid-pager-page .page-item:first-child a,
.jsgrid .jsgrid-pager .jsgrid-pager-page:first-child a {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button:last-child .page-link,
.jsgrid .jsgrid-pager .jsgrid-pager-page:last-child .page-link,
.page-item:last-child .jsgrid .jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button .page-item:last-child a,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button:last-child a,
.page-item:last-child .jsgrid .jsgrid-pager .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .jsgrid-pager-page .page-item:last-child a,
.jsgrid .jsgrid-pager .jsgrid-pager-page:last-child a {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link,
.jsgrid .jsgrid-pager .active.jsgrid-pager-nav-button .page-link,
.jsgrid .jsgrid-pager .active.jsgrid-pager-page .page-link,
.page-item.active .jsgrid .jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button .page-item.active a,
.jsgrid .jsgrid-pager .active.jsgrid-pager-nav-button a,
.page-item.active .jsgrid .jsgrid-pager .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .jsgrid-pager-page .page-item.active a,
.jsgrid .jsgrid-pager .active.jsgrid-pager-page a {
  z-index: 1;
  color: #ffffff;
  background-color: #ef3e27;
  border-color: #ef3e27;
}

.page-item.disabled .page-link,
.jsgrid .jsgrid-pager .disabled.jsgrid-pager-nav-button .page-link,
.jsgrid .jsgrid-pager .disabled.jsgrid-pager-page .page-link,
.page-item.disabled .jsgrid .jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button .page-item.disabled a,
.jsgrid .jsgrid-pager .disabled.jsgrid-pager-nav-button a,
.page-item.disabled .jsgrid .jsgrid-pager .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .jsgrid-pager-page .page-item.disabled a,
.jsgrid .jsgrid-pager .disabled.jsgrid-pager-page a {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #ffffff;
  border-color: #dee2e6;
}

.pagination-lg .page-link,
.pagination-lg .jsgrid .jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button .pagination-lg a,
.pagination-lg .jsgrid .jsgrid-pager .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .jsgrid-pager-page .pagination-lg a {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link,
.pagination-lg
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:first-child
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination-lg
  .jsgrid-pager-nav-button:first-child
  .page-link,
.pagination-lg .jsgrid .jsgrid-pager .jsgrid-pager-page:first-child .page-link,
.jsgrid .jsgrid-pager .pagination-lg .jsgrid-pager-page:first-child .page-link,
.pagination-lg
  .page-item:first-child
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination-lg
  .page-item:first-child
  a,
.pagination-lg .jsgrid .jsgrid-pager .jsgrid-pager-nav-button:first-child a,
.jsgrid .jsgrid-pager .pagination-lg .jsgrid-pager-nav-button:first-child a,
.pagination-lg
  .page-item:first-child
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination-lg
  .page-item:first-child
  a,
.pagination-lg .jsgrid .jsgrid-pager .jsgrid-pager-page:first-child a,
.jsgrid .jsgrid-pager .pagination-lg .jsgrid-pager-page:first-child a {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link,
.pagination-lg
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:last-child
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination-lg
  .jsgrid-pager-nav-button:last-child
  .page-link,
.pagination-lg .jsgrid .jsgrid-pager .jsgrid-pager-page:last-child .page-link,
.jsgrid .jsgrid-pager .pagination-lg .jsgrid-pager-page:last-child .page-link,
.pagination-lg
  .page-item:last-child
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination-lg
  .page-item:last-child
  a,
.pagination-lg .jsgrid .jsgrid-pager .jsgrid-pager-nav-button:last-child a,
.jsgrid .jsgrid-pager .pagination-lg .jsgrid-pager-nav-button:last-child a,
.pagination-lg .page-item:last-child .jsgrid .jsgrid-pager .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .jsgrid-pager-page .pagination-lg .page-item:last-child a,
.pagination-lg .jsgrid .jsgrid-pager .jsgrid-pager-page:last-child a,
.jsgrid .jsgrid-pager .pagination-lg .jsgrid-pager-page:last-child a {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link,
.pagination-sm .jsgrid .jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button .pagination-sm a,
.pagination-sm .jsgrid .jsgrid-pager .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .jsgrid-pager-page .pagination-sm a {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link,
.pagination-sm
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:first-child
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination-sm
  .jsgrid-pager-nav-button:first-child
  .page-link,
.pagination-sm .jsgrid .jsgrid-pager .jsgrid-pager-page:first-child .page-link,
.jsgrid .jsgrid-pager .pagination-sm .jsgrid-pager-page:first-child .page-link,
.pagination-sm
  .page-item:first-child
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination-sm
  .page-item:first-child
  a,
.pagination-sm .jsgrid .jsgrid-pager .jsgrid-pager-nav-button:first-child a,
.jsgrid .jsgrid-pager .pagination-sm .jsgrid-pager-nav-button:first-child a,
.pagination-sm
  .page-item:first-child
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination-sm
  .page-item:first-child
  a,
.pagination-sm .jsgrid .jsgrid-pager .jsgrid-pager-page:first-child a,
.jsgrid .jsgrid-pager .pagination-sm .jsgrid-pager-page:first-child a {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link,
.pagination-sm
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:last-child
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination-sm
  .jsgrid-pager-nav-button:last-child
  .page-link,
.pagination-sm .jsgrid .jsgrid-pager .jsgrid-pager-page:last-child .page-link,
.jsgrid .jsgrid-pager .pagination-sm .jsgrid-pager-page:last-child .page-link,
.pagination-sm
  .page-item:last-child
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination-sm
  .page-item:last-child
  a,
.pagination-sm .jsgrid .jsgrid-pager .jsgrid-pager-nav-button:last-child a,
.jsgrid .jsgrid-pager .pagination-sm .jsgrid-pager-nav-button:last-child a,
.pagination-sm .page-item:last-child .jsgrid .jsgrid-pager .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .jsgrid-pager-page .pagination-sm .page-item:last-child a,
.pagination-sm .jsgrid .jsgrid-pager .jsgrid-pager-page:last-child a,
.jsgrid .jsgrid-pager .pagination-sm .jsgrid-pager-page:last-child a {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:hover,
a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge,
.wizard > .actions a .badge,
.fc button .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #ffffff;
  background-color: #ef3e27;
}

a.badge-primary:hover,
a.badge-primary:focus {
  color: #ffffff;
  background-color: #14181d;
}

a.badge-primary:focus,
a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(71, 101, 255, 0.5);
}

.badge-secondary {
  color: #212529;
  background-color: #d3dae6;
}

a.badge-secondary:hover,
a.badge-secondary:focus {
  color: #212529;
  background-color: #b2bfd4;
}

a.badge-secondary:focus,
a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(211, 218, 230, 0.5);
}

.badge-success {
  color: #ffffff;
  background-color: #00cb8e;
}

a.badge-success:hover,
a.badge-success:focus {
  color: #ffffff;
  background-color: #00986a;
}

a.badge-success:focus,
a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 203, 142, 0.5);
}

.badge-info {
  color: #ffffff;
  background-color: #00aaff;
}

a.badge-info:hover,
a.badge-info:focus {
  color: #ffffff;
  background-color: #0088cc;
}

a.badge-info:focus,
a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 170, 255, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc555;
}

a.badge-warning:hover,
a.badge-warning:focus {
  color: #212529;
  background-color: #ffb422;
}

a.badge-warning:focus,
a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 197, 85, 0.5);
}

.badge-danger {
  color: #ffffff;
  background-color: #ef3e27;
}

a.badge-danger:hover,
a.badge-danger:focus {
  color: #ffffff;
  background-color: #ff2338;
}

a.badge-danger:focus,
a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 86, 102, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f9fafb;
}

a.badge-light:hover,
a.badge-light:focus {
  color: #212529;
  background-color: #dae1e7;
}

a.badge-light:focus,
a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(249, 250, 251, 0.5);
}

.badge-dark {
  color: #ffffff;
  background-color: #242a34;
}

a.badge-dark:hover,
a.badge-dark:focus {
  color: #ffffff;
  background-color: #0f1216;
}

a.badge-dark:focus,
a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(36, 42, 52, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #253585;
  background-color: #ececec;
  border-color: #cbd4ff;
}

.alert-primary hr {
  border-top-color: #b2bfff;
}

.alert-primary .alert-link {
  color: #1a255d;
}

.alert-secondary {
  color: #6e7178;
  background-color: #f6f8fa;
  border-color: #f3f5f8;
}

.alert-secondary hr {
  border-top-color: #e3e8ef;
}

.alert-secondary .alert-link {
  color: #56585d;
}

.alert-success {
  color: #006a4a;
  background-color: #ccf5e8;
  border-color: #b8f0df;
}

.alert-success hr {
  border-top-color: #a3ecd6;
}

.alert-success .alert-link {
  color: #003726;
}

.alert-info {
  color: #005885;
  background-color: #cceeff;
  border-color: #b8e7ff;
}

.alert-info hr {
  border-top-color: #9fdeff;
}

.alert-info .alert-link {
  color: #003652;
}

.alert-warning {
  color: #85662c;
  background-color: #fff3dd;
  border-color: #ffefcf;
}

.alert-warning hr {
  border-top-color: #ffe7b6;
}

.alert-warning .alert-link {
  color: #5f491f;
}

.alert-danger {
  color: #852d35;
  background-color: #ffdde0;
  border-color: #ffd0d4;
}

.alert-danger hr {
  border-top-color: #ffb7bd;
}

.alert-danger .alert-link {
  color: #5f2026;
}

.alert-light {
  color: #818283;
  background-color: #fefefe;
  border-color: #fdfefe;
}

.alert-light hr {
  border-top-color: #ecf6f6;
}

.alert-light .alert-link {
  color: #686969;
}

.alert-dark {
  color: #13161b;
  background-color: #d3d4d6;
  border-color: #c2c3c6;
}

.alert-dark hr {
  border-top-color: #b5b6ba;
}

.alert-dark .alert-link {
  color: black;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: #ef3e27;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
          animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
            animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #222222;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #ffffff;
}

.list-group-item.active {
  z-index: 2;
  color: #ffffff;
  background-color: #ef3e27;
  border-color: #ef3e27;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal .list-group-item {
  margin-right: -1px;
  margin-bottom: 0;
}

.list-group-horizontal .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal .list-group-item:last-child {
  margin-right: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-sm .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-md .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-lg .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-xl .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.list-group-flush .list-group-item:last-child {
  margin-bottom: -1px;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}

.list-group-item-primary {
  color: #253585;
  background-color: #cbd4ff;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #253585;
  background-color: #b2bfff;
}

.list-group-item-primary.list-group-item-action.active {
  color: #ffffff;
  background-color: #253585;
  border-color: #253585;
}

.list-group-item-secondary {
  color: #6e7178;
  background-color: #f3f5f8;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #6e7178;
  background-color: #e3e8ef;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #ffffff;
  background-color: #6e7178;
  border-color: #6e7178;
}

.list-group-item-success {
  color: #006a4a;
  background-color: #b8f0df;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #006a4a;
  background-color: #a3ecd6;
}

.list-group-item-success.list-group-item-action.active {
  color: #ffffff;
  background-color: #006a4a;
  border-color: #006a4a;
}

.list-group-item-info {
  color: #005885;
  background-color: #b8e7ff;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #005885;
  background-color: #9fdeff;
}

.list-group-item-info.list-group-item-action.active {
  color: #ffffff;
  background-color: #005885;
  border-color: #005885;
}

.list-group-item-warning {
  color: #85662c;
  background-color: #ffefcf;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #85662c;
  background-color: #ffe7b6;
}

.list-group-item-warning.list-group-item-action.active {
  color: #ffffff;
  background-color: #85662c;
  border-color: #85662c;
}

.list-group-item-danger {
  color: #852d35;
  background-color: #ffd0d4;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #852d35;
  background-color: #ffb7bd;
}

.list-group-item-danger.list-group-item-action.active {
  color: #ffffff;
  background-color: #852d35;
  border-color: #852d35;
}

.list-group-item-light {
  color: #818283;
  background-color: #fdfefe;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #818283;
  background-color: #ecf6f6;
}

.list-group-item-light.list-group-item-action.active {
  color: #ffffff;
  background-color: #818283;
  border-color: #818283;
}

.list-group-item-dark {
  color: #13161b;
  background-color: #c2c3c6;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #13161b;
  background-color: #b5b6ba;
}

.list-group-item-dark.list-group-item-action.active {
  color: #ffffff;
  background-color: #13161b;
  border-color: #13161b;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.5;
}

.close:hover {
  color: #000000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
          appearance: none;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
  -webkit-transform: translate(0, -50px);
          transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  -webkit-transform: none;
          transform: none;
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 20px);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 20px);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 20px);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 20px);
  content: '';
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #e6e4e9;
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #e6e4e9;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 15px;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px;
  border-top: 1px solid #e6e4e9;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 30px auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 60px);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 60px);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 60px);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 60px);
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 90%;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.75rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: '';
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^='top'] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^='top'] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^='top'] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000000;
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^='right'] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^='right'] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^='right'] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^='bottom'] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^='bottom'] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^='bottom'] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000000;
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^='left'] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^='left'] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^='left'] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.4rem 0.75rem;
  color: #ffffff;
  text-align: center;
  background-color: #000000;
  border-radius: 0.375rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow::before,
.popover .arrow::after {
  position: absolute;
  display: block;
  content: '';
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top,
.bs-popover-auto[x-placement^='top'] {
  margin-bottom: 0.5rem;
}

.bs-popover-top > .arrow,
.bs-popover-auto[x-placement^='top'] > .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}

.bs-popover-top > .arrow::before,
.bs-popover-auto[x-placement^='top'] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .arrow::after,
.bs-popover-auto[x-placement^='top'] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #ffffff;
}

.bs-popover-right,
.bs-popover-auto[x-placement^='right'] {
  margin-left: 0.5rem;
}

.bs-popover-right > .arrow,
.bs-popover-auto[x-placement^='right'] > .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-right > .arrow::before,
.bs-popover-auto[x-placement^='right'] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right > .arrow::after,
.bs-popover-auto[x-placement^='right'] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #ffffff;
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^='bottom'] {
  margin-top: 0.5rem;
}

.bs-popover-bottom > .arrow,
.bs-popover-auto[x-placement^='bottom'] > .arrow {
  top: calc((0.5rem + 1px) * -1);
}

.bs-popover-bottom > .arrow::before,
.bs-popover-auto[x-placement^='bottom'] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .arrow::after,
.bs-popover-auto[x-placement^='bottom'] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #ffffff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^='bottom'] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: '';
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left,
.bs-popover-auto[x-placement^='left'] {
  margin-right: 0.5rem;
}

.bs-popover-left > .arrow,
.bs-popover-auto[x-placement^='left'] > .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-left > .arrow::before,
.bs-popover-auto[x-placement^='left'] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left > .arrow::after,
.bs-popover-auto[x-placement^='left'] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #ffffff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #222222;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: '';
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
          transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #ffffff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #ffffff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #ffffff;
  text-align: center;
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
          animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow 0.75s linear infinite;
          animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #ef3e27 !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #14181d !important;
}

.bg-secondary {
  background-color: #d3dae6 !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #b2bfd4 !important;
}

.bg-success {
  background-color: #00cb8e !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #00986a !important;
}

.bg-info {
  background-color: #00aaff !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #0088cc !important;
}

.bg-warning {
  background-color: #ffc555 !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #ffb422 !important;
}

.bg-danger {
  background-color: #ef3e27 !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ff2338 !important;
}

.bg-light {
  background-color: #f9fafb !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae1e7 !important;
}

.bg-dark {
  background-color: #242a34 !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #0f1216 !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #e6e4e9 !important;
}

.border-top {
  border-top: 1px solid #e6e4e9 !important;
}

.border-right {
  border-right: 1px solid #e6e4e9 !important;
}

.border-bottom {
  border-bottom: 1px solid #e6e4e9 !important;
}

.border-left {
  border-left: 1px solid #e6e4e9 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #ef3e27 !important;
}

.border-secondary {
  border-color: #d3dae6 !important;
}

.border-success {
  border-color: #00cb8e !important;
}

.border-info {
  border-color: #00aaff !important;
}

.border-warning {
  border-color: #ffc555 !important;
}

.border-danger {
  border-color: #ef3e27 !important;
}

.border-light {
  border-color: #f9fafb !important;
}

.border-dark {
  border-color: #242a34 !important;
}

.border-white {
  border-color: #ffffff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: '';
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block,
.highlight-list .highlight-item .highlight-item-content .highlight-detail {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex,
.card-viewport,
.navbar .navbar-menu-container .navbar-nav,
.navbar .navbar-menu-container .navbar-nav .nav-item.nav-profile,
.navbar
  .navbar-menu-container
  .navbar-nav
  .nav-item.dropdown
  .navbar-dropdown
  .dropdown-item,
.event-list .event-item .event-schedule,
.event-list .event-item .event-participants,
.event-list .event-item .event-participants .event-user,
.highlight-list .highlight-item,
.highlight-list .highlight-item .highlight-thumbnail .highlight-icon,
.task-list-container ul li,
.task-list-container ul li .card,
.task-list-container ul li .card .card-header,
.task-list-wrapper ul li,
.email .email-toolbars-wrapper .toolbar-body .contact-list .contact-list-item a,
.email
  .email-toolbars-wrapper
  .toolbar-body
  .contact-list
  .contact-list-item
  a
  .user,
.email
  .email-list-wrapper
  .email-list
  .email-list-item
  .email-content
  .recipient,
.email
  .email-desc-wrapper
  .email-desc-body-wrapper
  .email-header
  .email-recipient,
.email
  .email-desc-wrapper
  .email-desc-body-wrapper
  .email-header
  .email-recipient
  .email,
.email
  .email-desc-wrapper
  .email-desc-body-wrapper
  .email-body-wrapper
  .email-body
  .attachments-sections
  ul
  li
  .thumb,
.email
  .email-desc-wrapper
  .email-desc-body-wrapper
  .email-body-wrapper
  .email-body
  .attachments-sections
  ul
  li
  .details
  .buttons {
  display: flex !important;
}

.d-inline-flex,
.email
  .email-desc-wrapper
  .email-desc-body-wrapper
  .email-body-wrapper
  .email-body
  .attachments-sections
  ul
  li {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: '';
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row,
.navbar .navbar-menu-container .navbar-nav,
.event-list .event-item .event-participants,
.highlight-list .highlight-item,
.email
  .email-list-wrapper
  .email-list
  .email-list-item
  .email-content
  .recipient,
.email
  .email-desc-wrapper
  .email-desc-body-wrapper
  .email-body-wrapper
  .email-body
  .attachments-sections
  ul
  li {
  flex-direction: row !important;
}

.flex-column,
.nav-tabs.nav-tabs-vertical,
.nav-tabs.nav-tabs-vertical-custom,
.nav-pills.nav-pills-vertical,
.email
  .email-toolbars-wrapper
  .toolbar-body
  .contact-list
  .contact-list-item
  a
  .user,
.email
  .email-desc-wrapper
  .email-desc-body-wrapper
  .email-header
  .email-recipient
  .email {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill,
.task-list-container ul li {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1,
.task-list-container ul li .card,
.task-list-container ul li .card .card-header .task-header-right {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center,
.event-list .event-item .event-participants .event-user,
.highlight-list .highlight-item .highlight-thumbnail .highlight-icon,
.email
  .email-desc-wrapper
  .email-desc-body-wrapper
  .email-body-wrapper
  .email-body
  .attachments-sections
  ul
  li
  .thumb {
  justify-content: center !important;
}

.justify-content-between,
.email
  .email-list-wrapper
  .email-list
  .email-list-item
  .email-content
  .recipient,
.widget-14 .widget-14-header {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center,
.navbar .navbar-menu-container .navbar-nav,
.navbar .navbar-menu-container .navbar-nav .nav-item.nav-profile,
.navbar
  .navbar-menu-container
  .navbar-nav
  .nav-item.dropdown
  .navbar-dropdown
  .dropdown-item,
.event-list .event-item .event-participants .event-user,
.highlight-list .highlight-item .highlight-thumbnail .highlight-icon,
.task-list-wrapper ul li,
.email
  .email-desc-wrapper
  .email-desc-body-wrapper
  .email-body-wrapper
  .email-body
  .attachments-sections
  ul
  li
  .thumb,
.email
  .email-desc-wrapper
  .email-desc-body-wrapper
  .email-body-wrapper
  .email-body
  .attachments-sections
  ul
  li
  .details
  .buttons {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch,
.email
  .email-desc-wrapper
  .email-desc-body-wrapper
  .email-body-wrapper
  .email-body
  .attachments-sections
  ul
  li {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch,
.navbar .navbar-menu-container .navbar-nav .nav-item.nav-profile {
  align-self: stretch !important;
  margin-right: 25px;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: '';
  background-color: rgba(0, 0, 0, 0);
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.btn-toolbar .btn-group + .btn-group,
.btn-toolbar .fc .fc-button-group + .btn-group,
.fc .btn-toolbar .fc-button-group + .btn-group,
.btn-toolbar .fc .btn-group + .fc-button-group,
.fc .btn-toolbar .btn-group + .fc-button-group,
.btn-toolbar .fc .fc-button-group + .fc-button-group,
.fc .btn-toolbar .fc-button-group + .fc-button-group,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.task-list-wrapper .remove,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
    'Courier New', monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center,
.profile-overlay .title-head {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #ffffff !important;
}

.text-primary,
.task-list-wrapper .completed .remove {
  color: #ef3e27 !important;
}

a.text-primary:hover,
.task-list-wrapper .completed a.remove:hover,
a.text-primary:focus,
.task-list-wrapper .completed a.remove:focus {
  color: #0029fa !important;
}

.text-secondary,
.task-list-wrapper .remove {
  color: #d3dae6 !important;
}

a.text-secondary:hover,
.task-list-wrapper a.remove:hover,
a.text-secondary:focus,
.task-list-wrapper a.remove:focus {
  color: #a2b1ca !important;
}

.text-success {
  color: #00cb8e !important;
}

a.text-success:hover,
a.text-success:focus {
  color: #007f58 !important;
}

.text-info {
  color: #00aaff !important;
}

a.text-info:hover,
a.text-info:focus {
  color: #0077b3 !important;
}

.text-warning {
  color: #ffc555 !important;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #ffab09 !important;
}

.text-danger {
  color: #ef3e27 !important;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #ff0a21 !important;
}

.text-light {
  color: #f9fafb !important;
}

a.text-light:hover,
a.text-light:focus {
  color: #cbd4dc !important;
}

.text-dark {
  color: #242a34 !important;
}

a.text-dark:hover,
a.text-dark:focus {
  color: #050507 !important;
}

.text-body {
  color: #222222 !important;
}

.text-muted {
  color: #8d8a89 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: ' (' attr(title) ')';
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000000;
  }
  .table,
  .jsgrid .jsgrid-table {
    border-collapse: collapse !important;
  }

  .table td,
  .jsgrid .jsgrid-table td,
  .table th,
  .jsgrid .jsgrid-table th {
    background-color: #ffffff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #e6e4e9;
  }
  .table .thead-dark th,
  .jsgrid .jsgrid-table .thead-dark th {
    color: inherit;
    border-color: #e6e4e9;
  }
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0);
  }
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0);
  }
}

@-webkit-keyframes dropdownAnimation {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -30px, 0);
            transform: translate3d(0, -30px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
    -webkit-transform: translate3d(0, 0px, 0);
            transform: translate3d(0, 0px, 0);
  }
}

@keyframes dropdownAnimation {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -30px, 0);
            transform: translate3d(0, -30px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
    -webkit-transform: translate3d(0, 0px, 0);
            transform: translate3d(0, 0px, 0);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.dropdownAnimation {
  -webkit-animation-name: dropdownAnimation;
          animation-name: dropdownAnimation;
  -webkit-animation-duration: 0.25s;
  animation-duration: 0.25s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.datepicker {
  width: 260px;
  padding: 10px;
}

.datepicker.datepicker-orient-top {
  margin-top: 8px;
}

.datepicker table {
  width: 100%;
}

.datepicker td,
.datepicker th {
  font-weight: 300;
  width: 35px;
  height: 35px;
  border-radius: 4px;
}

.datepicker thead th {
  color: #3f3f3f;
}

.datepicker thead th.prev,
.datepicker thead th.datepicker-switch,
.datepicker thead th.next {
  font-weight: 500;
  color: #8d8a89;
}

.datepicker thead th.prev i,
.datepicker thead th.datepicker-switch i,
.datepicker thead th.next i {
  font-size: 1.2rem;
  color: #8d8a89;
}

.datepicker thead th.prev i:before,
.datepicker thead th.datepicker-switch i:before,
.datepicker thead th.next i:before {
  line-height: 0;
  vertical-align: middle;
}

.datepicker thead th.prev:hover,
.datepicker thead th.datepicker-switch:hover,
.datepicker thead th.next:hover {
  background: #ececec !important;
}

.datepicker thead th.dow {
  color: #3f3f3f;
  font-weight: 500;
}

.datepicker tbody tr > td.highlighted {
  background-color: #ececec;
}

.datepicker tbody tr > td.day {
  color: #3f3f3f;
}

.datepicker tbody tr > td.day:hover {
  background: #ececec;
  color: #3f3f3f;
}

.datepicker tbody tr > td.day.old {
  color: #3f3f3f;
}

.datepicker tbody tr > td.day.new {
  color: #3f3f3f;
}

.datepicker tbody tr > td.day.selected,
.datepicker tbody tr > td.day.selected:hover,
.datepicker tbody tr > td.day.active,
.datepicker tbody tr > td.day.active:hover {
  background: #ef3e27;
  color: #ffffff;
}

.datepicker tbody tr > td.day.today {
  position: relative;
  background: rgba(239, 62, 39, 0.7) !important;
  color: #ffffff;
}

.datepicker tbody tr > td.day.today:before {
  content: '';
  display: inline-block;
  border: solid transparent;
  border-width: 0 0 7px 7px;
  border-bottom-color: #ffffff;
  border-top-color: kt-base-color(grey, 2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}

.datepicker tbody tr > td.day.range {
  background: kt-base-color(grey, 1);
}

.datepicker tbody tr > td span.year,
.datepicker tbody tr > td span.hour,
.datepicker tbody tr > td span.minute,
.datepicker tbody tr > td span.month {
  color: #3f3f3f;
}

.datepicker tbody tr > td span.year:hover,
.datepicker tbody tr > td span.hour:hover,
.datepicker tbody tr > td span.minute:hover,
.datepicker tbody tr > td span.month:hover {
  background: #ececec;
}

.datepicker tbody tr > td span.year.focused,
.datepicker tbody tr > td span.year.focused:hover,
.datepicker tbody tr > td span.year.active:hover,
.datepicker tbody tr > td span.year.active.focused:hover,
.datepicker tbody tr > td span.year.active,
.datepicker tbody tr > td span.hour.focused,
.datepicker tbody tr > td span.hour.focused:hover,
.datepicker tbody tr > td span.hour.active:hover,
.datepicker tbody tr > td span.hour.active.focused:hover,
.datepicker tbody tr > td span.hour.active,
.datepicker tbody tr > td span.minute.focused,
.datepicker tbody tr > td span.minute.focused:hover,
.datepicker tbody tr > td span.minute.active:hover,
.datepicker tbody tr > td span.minute.active.focused:hover,
.datepicker tbody tr > td span.minute.active,
.datepicker tbody tr > td span.month.focused,
.datepicker tbody tr > td span.month.focused:hover,
.datepicker tbody tr > td span.month.active:hover,
.datepicker tbody tr > td span.month.active.focused:hover,
.datepicker tbody tr > td span.month.active {
  background: #ef3e27;
  color: #ffffff;
}

.datepicker tfoot tr > th.today,
.datepicker tfoot tr > th.clear {
  border-radius: 3px;
  font-weight: 500;
}

.datepicker tfoot tr > th.today:hover,
.datepicker tfoot tr > th.clear:hover {
  background: #ececec;
}

.datepicker.datepicker-inline {
  border: 1px solid kt-base-color(grey, 2);
}

.input-daterange .input-group-addon {
  min-width: 44px;
}

.input-daterange input {
  border-radius: 0px;
  text-align: left;
}

.ps__rail-y .ps__thumb-y {
  background-color: #aaaaaaa3;
}

.ps__rail-y .ps__thumb-y:hover {
  background-color: #99999959;
}

.dashboard-owl .owl-prev {
  width: 100px;
  height: 89%;
  position: absolute;
  top: 0;
  margin-left: 0px;
  margin-top: 20px;
  display: block !important;
  background-image: #ffffff00 !important;
}

.dashboard-owl .owl-prev .owl-nav-wrapper {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  position: absolute;
  top: 50%;
  align-items: center;
  justify-content: center;
}


.dashboard-owl .owl-prev .owl-nav-wrapper svg,
.dashboard-owl .owl-prev .owl-nav-wrapper i {
  color: #ffffff;
  width: 20px;
  height: 20px;
}

.dashboard-owl .owl-next {
  width: 100px;
  height: 89%;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 0px;
  margin-top: 20px;
  display: block !important;
  background-image: #ffffff00 !important;
}

.dashboard-owl .owl-next .owl-nav-wrapper {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  right: 0;
  justify-content: center;
  margin-left: auto;
}


.dashboard-owl .owl-next .owl-nav-wrapper svg,
.dashboard-owl .owl-next .owl-nav-wrapper i {
  color: #ffffff;
  width: 20px;
  height: 20px;
}

.item-video {
  height: 300px;
}
i.fa.fa-angle-left {
  color: red !important;
}
i.fa.fa-angle-right {
  color: red !important;
}
.daterangepicker {
  padding: 0;
  margin: 0;
  width: auto;
  border-radius: 5px;
  border: 0;
  box-shadow: 0px 0px 40px 0px rgba(79, 61, 101, 0.15);
  font-family: 'Chivo', sans-serif;
  z-index: 1;
  background: #ffffff;
}

.modal-open .daterangepicker {
  z-index: 10000;
}

.daterangepicker:after,
.daterangepicker:before {
  display: none;
}

.daterangepicker .ranges ul {
  padding: 1rem 0;
  width: 160px;
}

.daterangepicker .ranges li {
  font-weight: 400;
  font-size: 13px;
  color: #8d8a89;
  transition: color 0.3s ease;
  line-height: 1;
  padding: 0.625rem 1.25rem;
}

.daterangepicker .ranges li:hover,
.daterangepicker .ranges li.active {
  background-color: #f6f7ff;
  color: #ef3e27;
  transition: color 0.3s ease;
}

.daterangepicker.show-calendar .ranges {
  border-right: 1px solid #e6e4e9;
}

.daterangepicker.show-ranges.ltr .drp-calendar.left {
  border-left: 0;
}

.daterangepicker.show-calendar .ranges {
  margin-top: 0;
}

.daterangepicker .drp-buttons {
  padding: 0.75rem 1.25rem;
  border-top: 1px solid #e6e4e9;
  color: #8d8a89;
}

.daterangepicker .drp-buttons .btn,
.daterangepicker .drp-buttons .wizard > .actions a,
.wizard > .actions .daterangepicker .drp-buttons a,
.daterangepicker .drp-buttons .fc button,
.fc .daterangepicker .drp-buttons button {
  font-size: 0.9rem;
  font-weight: 400;
  padding: 0.5rem 1rem;
  border-radius: 0;
}

.daterangepicker .drp-calendar .calendar-table {
  background: #ffffff;
  border: 1px solid #ffffff;
}

.daterangepicker .drp-calendar .calendar-table.left,
.daterangepicker .drp-calendar .calendar-table.right {
  padding: 1rem 1.5rem 1rem 1.5rem;
}

.daterangepicker .drp-calendar .calendar-table th {
  font-weight: 500;
  font-size: 13px;
  color: #8d8a89;
}

.daterangepicker .drp-calendar .calendar-table th:hover {
  background-color: #ececec;
}

.daterangepicker .drp-calendar .calendar-table th.month {
  font-weight: 400;
  color: #8d8a89;
}

.daterangepicker .drp-calendar .calendar-table th.next span,
.daterangepicker .drp-calendar .calendar-table th.prev span {
  border-width: 0 1px 1px 0;
  border-color: #8d8a89;
}

.daterangepicker .drp-calendar .calendar-table th.next span {
  margin-right: 1px;
}

.daterangepicker .drp-calendar .calendar-table th.prev span {
  margin-left: 1px;
}

.daterangepicker .drp-calendar .calendar-table td {
  font-weight: 300;
  font-size: 13px;
  color: #8d8a89;
}

.daterangepicker .drp-calendar .calendar-table td.available:hover {
  background-color: #ececec;
}

.daterangepicker .drp-calendar .calendar-table td.available.off {
  color: #d0d3e2;
  background: #ffffff;
}

.daterangepicker .drp-calendar .calendar-table td.active {
  background-color: #ef3e27;
  color: #ffffff;
}

.daterangepicker .drp-calendar .calendar-table td.active.start-date {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.daterangepicker .drp-calendar .calendar-table td.active.end-date {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.daterangepicker .drp-calendar .calendar-table td.today,
.daterangepicker .drp-calendar .calendar-table td.today.active {
  border-radius: 4px;
  background: rgba(71, 101, 255, 0.7) !important;
  color: #ececec !important;
}

.daterangepicker
  .drp-calendar
  .calendar-table
  td.in-range.available:not(.active):not(.off):not(.today) {
  background-color: #ececec;
  color: #8d8a89;
}

.daterangepicker select.ampmselect,
.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect {
  background: #ffffff;
  border-radius: 3px;
  border-color: #e6e4e9;
}

.bootstrap-timepicker-widget table td input {
  border: none;
  color: #3f3f3f;
  font-weight: 400;
  background-color: #ffffff;
}

.bootstrap-timepicker-widget table td a span {
  border: solid #3f3f3f;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 4px;
  width: 7px;
}

.bootstrap-timepicker-widget table td a span.menu-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.bootstrap-timepicker-widget table td a span.menu-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.bootstrap-timepicker-widget table td a:hover {
  background-color: #ececec;
  border: none;
}

.carousel-control {
  display: flex;
  align-items: center;
}

.carousel-control .carousel-control-prev,
.carousel-control .carousel-control-next {
  position: relative;
  width: 25px;
  height: 25px;
  margin-left: 0.5rem;
  background-color: #ececec;
  opacity: 1;
  border-radius: 0.25rem;
  transition: all, 0.3s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control .carousel-control-prev,
  .carousel-control .carousel-control-next {
    transition: none;
  }
}

.carousel-control .carousel-control-prev:hover,
.carousel-control .carousel-control-next:hover {
  background-color: #ef3e27;
}

.carousel-control .carousel-control-prev:hover svg,
.carousel-control .carousel-control-next:hover svg {
  color: #ffffff;
}

.carousel-control .carousel-control-prev-icon,
.carousel-control .carousel-control-next-icon {
  color: #323232;
  background-image: unset;
  width: 13px;
  height: 13px;
  stroke-width: 4;
  transition: all, 0.3s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control .carousel-control-prev-icon,
  .carousel-control .carousel-control-next-icon {
    transition: none;
  }
}

.carousel-control .carousel-control-prev-icon:hover,
.carousel-control .carousel-control-next-icon:hover {
  color: #586dd9;
}

/* text-based popup styling */
.white-popup {
  position: relative;
  background: #fff;
  padding: 25px;
  width: auto;
  max-width: 400px;
  margin: 0 auto;
}

/*

====== Zoom effect ======

*/

.mfp-zoom-in .mfp-with-anim {
  opacity: 0;
  transition: all 0.2s ease-in-out;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
}

.mfp-zoom-in.mfp-bg {
  opacity: 0;
  transition: all 0.3s ease-out;
}

.mfp-zoom-in.mfp-ready .mfp-with-anim {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-zoom-in.mfp-removing .mfp-with-anim {
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
  opacity: 0;
}

.mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

/*

====== Newspaper effect ======

*/

.mfp-newspaper .mfp-with-anim {
  opacity: 0;
  transition: all 0.5s;
  -webkit-transform: scale(0) rotate(500deg);
          transform: scale(0) rotate(500deg);
}

.mfp-newspaper.mfp-bg {
  opacity: 0;
  transition: all 0.5s;
}

.mfp-newspaper.mfp-ready .mfp-with-anim {
  opacity: 1;
  -webkit-transform: scale(1) rotate(0deg);
          transform: scale(1) rotate(0deg);
}

.mfp-newspaper.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-newspaper.mfp-removing .mfp-with-anim {
  -webkit-transform: scale(0) rotate(500deg);
          transform: scale(0) rotate(500deg);
  opacity: 0;
}

.mfp-newspaper.mfp-removing.mfp-bg {
  opacity: 0;
}

/*

====== Move-horizontal effect ======

*/

.mfp-move-horizontal .mfp-with-anim {
  opacity: 0;
  transition: all 0.3s;
  -webkit-transform: translateX(-50px);
          transform: translateX(-50px);
}

.mfp-move-horizontal.mfp-bg {
  opacity: 0;
  transition: all 0.3s;
}

.mfp-move-horizontal.mfp-ready .mfp-with-anim {
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.mfp-move-horizontal.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-move-horizontal.mfp-removing .mfp-with-anim {
  -webkit-transform: translateX(50px);
          transform: translateX(50px);
  opacity: 0;
}

.mfp-move-horizontal.mfp-removing.mfp-bg {
  opacity: 0;
}

/*

====== Move-from-top effect ======

*/

.mfp-move-from-top .mfp-content {
  vertical-align: top;
}

.mfp-move-from-top .mfp-with-anim {
  opacity: 0;
  transition: all 0.2s;
  -webkit-transform: translateY(-100px);
          transform: translateY(-100px);
}

.mfp-move-from-top.mfp-bg {
  opacity: 0;
  transition: all 0.2s;
}

.mfp-move-from-top.mfp-ready .mfp-with-anim {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.mfp-move-from-top.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-move-from-top.mfp-removing .mfp-with-anim {
  -webkit-transform: translateY(-50px);
          transform: translateY(-50px);
  opacity: 0;
}

.mfp-move-from-top.mfp-removing.mfp-bg {
  opacity: 0;
}

/*

====== 3d unfold ======

*/

.mfp-3d-unfold .mfp-content {
  -webkit-perspective: 2000px;
          perspective: 2000px;
}

.mfp-3d-unfold .mfp-with-anim {
  opacity: 0;
  transition: all 0.3s ease-in-out;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-transform: rotateY(-60deg);
          transform: rotateY(-60deg);
}

.mfp-3d-unfold.mfp-bg {
  opacity: 0;
  transition: all 0.5s;
}

.mfp-3d-unfold.mfp-ready .mfp-with-anim {
  opacity: 1;
  -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg);
}

.mfp-3d-unfold.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-3d-unfold.mfp-removing .mfp-with-anim {
  -webkit-transform: rotateY(60deg);
          transform: rotateY(60deg);
  opacity: 0;
}

.mfp-3d-unfold.mfp-removing.mfp-bg {
  opacity: 0;
}

/*

====== Zoom-out effect ======

*/

.mfp-zoom-out .mfp-with-anim {
  opacity: 0;
  transition: all 0.3s ease-in-out;
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
}

.mfp-zoom-out.mfp-bg {
  opacity: 0;
  transition: all 0.3s ease-out;
}

.mfp-zoom-out.mfp-ready .mfp-with-anim {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.mfp-zoom-out.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-zoom-out.mfp-removing .mfp-with-anim {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
  opacity: 0;
}

.mfp-zoom-out.mfp-removing.mfp-bg {
  opacity: 0;
}

/*

====== "Hinge" close effect ======

*/
@-webkit-keyframes hinge {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: top left;
            transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
  }
  20%,
  60% {
    -webkit-transform: rotate(80deg);
            transform: rotate(80deg);
    -webkit-transform-origin: top left;
            transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
  }
  40% {
    -webkit-transform: rotate(60deg);
            transform: rotate(60deg);
    -webkit-transform-origin: top left;
            transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
  }
  80% {
    -webkit-transform: rotate(60deg) translateY(0);
            transform: rotate(60deg) translateY(0);
    opacity: 1;
    -webkit-transform-origin: top left;
            transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
  }
  100% {
    -webkit-transform: translateY(700px);
            transform: translateY(700px);
    opacity: 0;
  }
}
@keyframes hinge {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: top left;
            transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
  }
  20%,
  60% {
    -webkit-transform: rotate(80deg);
            transform: rotate(80deg);
    -webkit-transform-origin: top left;
            transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
  }
  40% {
    -webkit-transform: rotate(60deg);
            transform: rotate(60deg);
    -webkit-transform-origin: top left;
            transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
  }
  80% {
    -webkit-transform: rotate(60deg) translateY(0);
            transform: rotate(60deg) translateY(0);
    opacity: 1;
    -webkit-transform-origin: top left;
            transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
  }
  100% {
    -webkit-transform: translateY(700px);
            transform: translateY(700px);
    opacity: 0;
  }
}

.hinge {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-name: hinge;
          animation-name: hinge;
}

.mfp-with-fade .mfp-content,
.mfp-with-fade.mfp-bg {
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

.mfp-with-fade.mfp-ready .mfp-content {
  opacity: 1;
}

.mfp-with-fade.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-with-fade.mfp-removing.mfp-bg {
  opacity: 0;
}

/* preview styles */
/*a,
a:visited {
color: #1760BF;
text-decoration: none;
}
a:hover {
color: #c00;
}
.links {
ul {

}
li {
margin-bottom: 5px;
}
}
h4 {
margin: 24px 0 0 0;
}*/
.bottom-text {
  margin-top: 40px;
  border-top: 2px solid #ccc;
}

.bottom-text a {
  border-bottom: 1px solid #ccc;
}

.bottom-text p {
  max-width: 650px;
}

.ql-container {
 
  font-family: 'Poppins';
  color: #3f3f3f;
}

.ql-snow .ql-stroke {
  stroke: #3f3f3f;
}

.ql-snow .ql-picker {
  color: #3f3f3f;
}

.ql-snow.ql-toolbar {
  font-family: 'Poppins', Helvetica, Arial, sans-serif;
}

.ql-snow.ql-toolbar select,
.ql-snow.ql-toolbar button {
  color: #3f3f3f;
}

.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow.ql-toolbar button:focus,
.ql-snow.ql-toolbar button:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow.ql-toolbar button:focus,
.ql-snow.ql-toolbar button:hover {
  color: #ef3e27;
}

.is-valid + .note-editor.note-frame {
  border-color: #00cb8e;
}

.is-invalid + .note-editor.note-frame {
  border-color: #ef3e27;
}

.card .card-header.note-toolbar {
  display: block;
}

.bootstrap-select.is-valid > .dropdown-toggle {
  border-color: #00cb8e !important;
}

.bootstrap-select.is-invalid > .dropdown-toggle {
  border-color: #ef3e27 !important;
}

.bootstrap-select:not([class*='col-']):not([class*='form-control']):not(.input-group-btn) {
  width: 100%;
}

.bootstrap-select > .dropdown-toggle {
  position: relative;
  outline: none !important;
  padding: 0.375rem 0.75rem;
  width: 100%;
  line-height: 1.5;
  border-radius: 4px;
}

.bootstrap-select > .dropdown-toggle:after {
  border: 0;
  margin-right: -2px;
  font-size: 0.6rem;
  display: inline-block;
  border: solid #3f3f3f;
  border-width: 0 1.5px 1.5px 0;
  display: inline-block;
  padding: 3px;
  width: 7px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.bootstrap-select > .dropdown-toggle.btn-primary:after,
.wizard > .actions .bootstrap-select > a.dropdown-toggle:after,
.bootstrap-select > .dropdown-toggle.btn-success:after,
.bootstrap-select > .dropdown-toggle.btn-danger:after,
.bootstrap-select > .dropdown-toggle.btn-info:after,
.bootstrap-select > .dropdown-toggle.btn-dark:after {
  border-color: #ffffff;
}

.bootstrap-select > .dropdown-toggle:before {
  width: auto;
}

.bootstrap-select > .dropdown-toggle.btn-light {
  background-color: #ffffff !important;
  color: #3f3f3f !important;
  border-color: #e6e4e9;
}

.bootstrap-select > .dropdown-toggle.btn-light:focus {
  outline: none !important;
  box-shadow: none;
}

.bootstrap-select .dropdown-menu.inner {
  display: block;
}

.bootstrap-select .dropdown-menu.inner > li.active small {
  color: #8d8a89 !important;
}

.bootstrap-select .dropdown-menu.inner > li > a {
  display: block;
  position: relative;
  outline: none !important;
  padding: 8px 12px;
  color: #3f3f3f;
}

.bootstrap-select .dropdown-menu.inner > li > a.active,
.bootstrap-select .dropdown-menu.inner > li > a:hover {
  background-color: #f6f7ff;
  color: #3f3f3f;
}

.bootstrap-select .dropdown-menu.inner > li.selected > a span.check-mark {
  border: solid #3f3f3f;
  width: 6px;
  height: 10px;
  border-width: 0 1.5px 1.5px 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  top: 50%;
  position: absolute;
  margin-top: -0.425rem;
  font-size: 0.85rem;
  right: 0.8125rem;
  display: inline-block;
}

.bootstrap-select .dropdown-menu.inner > li.selected > a span.check-mark:after {
  display: none;
}

.bootstrap-select .dropdown-menu.inner > li.divider {
  margin: 10px 0;
}

.bootstrap-select .dropdown-menu.inner > li.hidden {
  display: none;
}

.bootstrap-select .dropdown-menu.inner > li.no-results {
  padding: 10px 12px;
}

.bootstrap-select .popover-header {
  background-color: #ffffff;
  color: #3f3f3f;
}

.bootstrap-select .popover-header .close {
  font-weight: 400;
  color: #3f3f3f;
}

.bootstrap-select .bs-searchbox,
.bootstrap-select .bs-actionsbox,
.bootstrap-select .bs-donebutton {
  padding: 10px 12px;
}

.dataTables_wrapper label {
  color: #3f3f3f;
}

.dataTables_wrapper label select.custom-select {
  -webkit-appearance: none;
  appearance: none;
}

.dataTables_wrapper .dataTables_info {
  color: #3f3f3f;
}

.dataTables_wrapper table tr th {
  color: #3f3f3f;
  border-color: #e6e4e9;
}

.dataTables_wrapper table tr td {
  color: #3f3f3f;
  border-color: #e6e4e9;
}

.dataTables_wrapper table tr td select {
  -webkit-appearance: none;
  appearance: none;
}

.dataTables_wrapper table tr td.highlight {
  background-color: #f6f7ff;
}

.dataTables_wrapper table.table-bordered tr th {
  border-top: 0;
  border-bottom: 0;
}

/* Js-grid */
.jsgrid .jsgrid-search-mode-button {
  /* background: url('../../assets/images/js-grid-icons/search.png') ; */

  background-position: 0;
  background-size: auto;
}

/* .jsgrid .jsgrid-edit-button {
	background-image: url('../../assets/images/js-grid-icons/edit.png');
	background-position: 0;
	background-size: auto;
} */

.jsgrid .jsgrid-delete-button {
  /* background-image: url('../../assets/images/js-grid-icons/trash.png'); */
  background-position: 0;
  background-size: auto;
}

.jsgrid .jsgrid-search-button {
  /* background-image: url('../../assets/images/js-grid-icons/search-mode.png'); */
  background-position: 0;
  background-size: auto;
}

.jsgrid .jsgrid-clear-filter-button {
  /* background-image: url('../../assets/images/js-grid-icons/filter.png'); */
  background-position: 0;
  background-size: auto;
}

.jsgrid .jsgrid-insert-mode-button {
  /* background-image: url('../../assets/images/js-grid-icons/plus.png'); */
  background-position: 0;
  background-size: auto;
}

.jsgrid .jsgrid-grid-header {
  border: 1px solid #e6e4e9;
  border-top: 0;
  background: none;
}

.jsgrid .jsgrid-grid-body {
  border: 1px solid #e6e4e9;
}

.jsgrid .jsgrid-table .jsgrid-header-row > .jsgrid-header-cell {
  background-color: #f9f9f9;
}

.jsgrid .jsgrid-table th {
  font-weight: initial;
  font-weight: 500;
  font-size: 0.875rem;
  color: #3f3f3f;
  border-color: #e6e4e9;
}

.jsgrid .jsgrid-table td {
  font-weight: 300;
  font-size: 0.875rem;
  color: #3f3f3f;
}

.jsgrid .jsgrid-table .jsgrid-filter-row select,
.jsgrid .jsgrid-table .jsgrid-edit-row select {
  color: #3f3f3f;
}

.jsgrid .jsgrid-table .jsgrid-filter-row input[type='number'],
.jsgrid .jsgrid-table .jsgrid-edit-row input[type='number'] {
  padding: 0.56rem 0;
}

.jsgrid .jsgrid-table .jsgrid-filter-row > .jsgrid-cell {
  background: #fcfcfc;
  border-color: #e6e4e9;
}

.jsgrid .jsgrid-table .jsgrid-row > .jsgrid-cell {
  background: #fff;
  border-color: #e6e4e9;
}

.jsgrid .jsgrid-table .jsgrid-alt-row > .jsgrid-cell {
  background: #fcfcfc;
  border-color: #e6e4e9;
}

.jsgrid .jsgrid-table .jsgrid-selected-row > .jsgrid-cell {
  background: #c4e2ff;
  border-color: #e6e4e9;
}

.jsgrid .jsgrid-table .jsgrid-edit-row > .jsgrid-cell {
  background: #fdffe3;
  border-color: #e6e4e9;
}

.jsgrid .jsgrid-header-sort:before {
  margin-top: 10px;
  float: right;
}

.jsgrid .jsgrid-header-sort-asc:before {
  border-color: transparent transparent #222222;
}

.jsgrid .jsgrid-header-sort-desc:before {
  border-color: #222222 transparent transparent;
}

.jsgrid .jsgrid-pager {
  line-height: 2;
  align-items: center;
}

.jsgrid .jsgrid-pager-current-page {
  padding: 0.5rem 0.75rem;
  font-weight: normal;
  font-weight: initial;
  line-height: 1.25;
}

.jqvmap-zoomin,
.jqvmap-zoomout {
  width: 16px;
  height: 16px;
}

.google-map {
  height: 400px;
}

/* Wizard */
.wizard {
  display: block;
  width: 100%;
  overflow: hidden;
}

.wizard a {
  outline: 0;
}

.wizard ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.wizard ul > li {
  display: block;
  padding: 0;
}

.wizard > .steps {
  position: relative;
  display: block;
  width: 100%;
}

.wizard > .steps .current-info {
  position: absolute;
  left: -999em;
}

.wizard > .steps .number {
  font-size: 0.875rem;
}

.wizard > .steps > ul > li {
  width: 25%;
  float: left;
  text-align: center;
}

@media (max-width: 767px) {
  .wizard > .steps > ul > li {
    width: 50%;
  }
}

.wizard > .steps a {
  transition: all, 0.3s;
  display: block;
  width: auto;
  margin: 0 0.5em 0.5em;
  padding: 1em 1em;
  text-decoration: none;
  border-radius: 0px;
  font-size: 14px;
  font-weight: 700;
}

@media (prefers-reduced-motion: reduce) {
  .wizard > .steps a {
    transition: none;
  }
}

.wizard > .steps a:hover {
  display: block;
  width: auto;
  margin: 0 0.5em 0.5em;
  padding: 1em;
  text-decoration: none;
  border-radius: 0px;
}

.wizard > .steps a:active {
  display: block;
  width: auto;
  margin: 0 0.5em 0.5em;
  padding: 1em;
  text-decoration: none;
  border-radius: 5px;
}

.wizard > .steps .disabled a {
  transition: all, 0.3s;
  background: #f9f9f9;
  color: #989898;
  cursor: default;
}

@media (prefers-reduced-motion: reduce) {
  .wizard > .steps .disabled a {
    transition: none;
  }
}

.wizard > .steps .disabled a:hover {
  background: #e9edf3;
  color: #3f3f3f;
  cursor: default;
}

.wizard > .steps .disabled a:active {
  color: #434a54;
  cursor: default;
}

.wizard > .steps .current a {
  transition: all, 0.3s;
  background: #231f20;
  color: #ffffff;
  cursor: default;
}

@media (prefers-reduced-motion: reduce) {
  .wizard > .steps .current a {
    transition: none;
  }
}

.wizard > .steps .current a:hover {
  background: #ef3e27;
  color: #ffffff;
  cursor: default;
}

.wizard > .steps .current a:active {
  background: #e9edf3;
  color: #3f3f3f;
  cursor: default;
}

.wizard > .steps .done a {
  transition: all, 0.3s;
  background: #e9edf3;
  color: #3f3f3f;
}

@media (prefers-reduced-motion: reduce) {
  .wizard > .steps .done a {
    transition: none;
  }
}

.wizard > .steps .done a:hover {
  background: #ef3e27;
  color: #ffffff;
}

.wizard > .steps .done a:active {
  background: #e9edf3;
  color: #ffffff;
}

.wizard > .steps .error a {
  transition: all, 0.3s;
  background: #ef3e27;
  color: #fff;
}

@media (prefers-reduced-motion: reduce) {
  .wizard > .steps .error a {
    transition: none;
  }
}

.wizard > .steps .error a:hover {
  background: #ef3e27;
  color: #fff;
}

.wizard > .steps .error a:active {
  background: #ef3e27;
  color: #fff;
}

.wizard > .content {
  color: #3f3f3f;
  background: #ffffff;
  display: block;
  margin: 0.5em;
  min-height: 24em;
  overflow: hidden;
  position: relative;
  width: auto;
  border-radius: 0px;
  border: 1px solid #e6e4e9;
}

.wizard > .content > .title {
  position: absolute;
  left: -999em;
}

.wizard > .content > .body {
  float: left;
  position: relative;
  width: 95%;
  height: 95%;
  padding: 2.5%;
}

.wizard > .content > .body ul {
  list-style: disc;
}

.wizard > .content > .body ul > li {
  display: list-item;
}

.wizard > .content > .body > iframe {
  border: 0 none;
  width: 100%;
  height: 100%;
}

.wizard > .content > .body input {
  display: block;
  border: 1px solid #e6e4e9;
}

.wizard > .content > .body input[type='checkbox'] {
  display: inline-block;
}

.wizard > .content > .body input.error {
  background: #fbe3e4;
  border: 1px solid #ffbcc2;
  color: #ef3e27;
}

.wizard > .content > .body label {
  display: inline-block;
  margin-bottom: 0.5em;
}

.wizard > .content > .body label.error {
  color: #ef3e27;
  display: inline-block;
  margin-left: 1.5em;
}

.wizard > .actions {
  padding-top: 20px;
  position: relative;
  display: block;
  text-align: right;
  width: 100%;
}

.wizard > .actions > ul {
  display: inline-block;
  text-align: right;
}

.wizard > .actions > ul > li {
  float: left;
  margin: 0 0.5em;
}

.wizard > .actions a:hover {
  padding: 0.53125rem 0.8125rem;
}

.wizard.vertical > .steps {
  display: inline;
  float: left;
  width: 30%;
}

@media (max-width: 767px) {
  .wizard.vertical > .steps {
    width: 42%;
  }
}

.wizard.vertical > .steps > ul > li {
  float: none;
  width: 100%;
  text-align: left;
}

.wizard.vertical > .content {
  display: inline;
  float: left;
  margin: 0 2.5% 0.5em 2.5%;
  width: 65%;
}

@media (max-width: 767px) {
  .wizard.vertical > .content {
    width: 52%;
  }
}

.wizard.vertical > .actions {
  display: inline;
  float: right;
  margin: 0 2.5%;
  width: 95%;
}

@media (max-width: 767px) {
  .wizard.vertical > .actions {
    margin: 0;
    width: 100%;
  }
}

.wizard.vertical > .actions > ul > li {
  margin: 0 0 0 1em;
}

.tabcontrol {
  display: block;
  width: 100%;
  overflow: hidden;
}

.tabcontrol a {
  outline: 0;
}

.tabcontrol ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.tabcontrol ul > li {
  display: block;
  padding: 0;
}

.tabcontrol > .steps {
  position: relative;
  display: block;
  width: 100%;
}

.tabcontrol > .steps .current-info {
  position: absolute;
  left: -999em;
}

.tabcontrol > .steps > ul {
  position: relative;
  margin: 6px 0 0 0;
  top: 1px;
  z-index: 1;
}

.tabcontrol > .steps > ul > li {
  float: left;
  margin: 5px 2px 0 0;
  padding: 1px;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.tabcontrol > .steps > ul > li:hover {
  background: #edecec;
  border: 1px solid #e6e4e9;
  padding: 0;
}

.tabcontrol > .steps > ul > li > a {
  display: inline-block;
  border: 0 none;
  margin: 0;
  padding: 10px 30px;
  text-decoration: none;
}

.tabcontrol > .steps > ul > li > a:hover {
  text-decoration: none;
}

.tabcontrol > .steps > ul > li.current {
  background: #ffffff;
  border: 1px solid #e6e4e9;
  border-bottom: 0 none;
  padding: 0 0 1px 0;
  margin-top: 0;
}

.tabcontrol > .steps > ul > li.current > a {
  padding: 15px 30px 10px 30px;
}

.tabcontrol > .content {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 35em;
  overflow: hidden;
  border-top: 1px solid #e6e4e9;
  padding-top: 20px;
}

.tabcontrol > .content > .title {
  position: absolute;
  left: -999em;
}

.tabcontrol > .content > .body {
  float: left;
  position: absolute;
  width: 95%;
  height: 95%;
  padding: 2.5%;
}

.tabcontrol > .content > .body ul {
  list-style: disc;
}

.tabcontrol > .content > .body ul > li {
  display: list-item;
}

.fc button {
  height: auto;
  background-image: none;
  text-shadow: none;
  font-weight: 400;
  background-color: #ffffff !important;
  border-color: #e6e4e9;
  text-transform: capitalize;
  color: #a8b2b9;
}

.fc button .fc-icon-right-single-arrow:after,
.fc button .fc-icon-left-single-arrow:after {
  color: #a8b2b9;
}

.fc .fc-button-group .fc-button {
  background-color: #ffffff;
  margin: 0;
}

.fc .fc-button-group .fc-button.fc-state-active {
  color: #ef3e27;
}

.fc .fc-toolbar {
  margin-top: 2rem;
}

.fc .fc-toolbar .fc-center h2 {
  font-size: 1.5rem;
  color: #3f3f3f;
}

@media (max-width: 767px) {
  .fc .fc-toolbar .fc-left,
  .fc .fc-toolbar .fc-right,
  .fc .fc-toolbar .fc-center {
    float: none;
    margin-bottom: 10px;
  }
  .fc .fc-toolbar .fc-left:after,
  .fc .fc-toolbar .fc-right:after,
  .fc .fc-toolbar .fc-center:after {
    content: '';
    clear: both;
    display: block;
  }
  .fc .fc-toolbar .fc-left .fc-today-button {
    float: right;
  }
  .fc .fc-toolbar .fc-right .fc-button-group {
    float: none;
  }
}

.fc .fc-widget-header {
  border: 0;
  padding: 0.5rem;
  background: #ffffff !important;
}

.fc .fc-widget-header .fc-title {
  color: #3f3f3f;
  font-weight: 500;
}

.fc .fc-widget-header table tr th {
  border-width: 0;
  text-align: center;
  padding: 0.5rem;
  color: #3f3f3f;
}

@media (max-width: 767px) {
  .fc .fc-widget-header table tr th {
    padding: 1rem 0.25rem;
  }
}

.fc .fc-widget-header table tr th span {
  font-size: 10px;
  text-transform: uppercase;
  color: #a8b2b9;
  letter-spacing: 1px;
}

.fc .fc-content-skeleton table tr td {
  padding: 0.5rem;
  font-size: 0.875rem;
  color: #3f3f3f;
  border-color: #e6e4e9 !important;
}

.fc .fc-event {
  border: 0;
  color: #ffffff;
  padding: 0.5rem;
}

.fc .fc-event.fc-start {
  background: #ef3e27;
  border-radius: 4px;
  border-left: 4px solid #ef3e27;
}

.fc .fc-event.fc-not-start,
.fc .fc-event.fc-not-end {
  background: #00cb8e;
  border-left: 4px solid #00cb8e;
  padding-left: 0.5rem;
}

.fc .fc-event.fc-not-end {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.fc .fc-event .fc-title,
.fc .fc-event .fc-time {
  color: #ffffff;
}

.fc table tbody td {
  border-color: #e6e4e9;
}

.fc-external-events .fc-event {
  background: #ffffff;
  color: #000000;
  border-width: 0 0 0 4px;
  padding: 16px;
  border-radius: 0;
  margin-bottom: 10px;
}

.fc-external-events .fc-event:nth-child(1) {
  border-color: #ef3e27;
}

.fc-external-events .fc-event:nth-child(2) {
  border-color: #00cb8e;
}

.fc-external-events .fc-event:nth-child(3) {
  border-color: #ef3e27;
}

.fc-external-events .fc-event:nth-child(4) {
  border-color: #ffc555;
}

.fc-external-events .fc-event:nth-child(4) {
  border-color: #00aaff;
}

html.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown),
body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
  overflow-y: visible !important;
  overflow-y: initial !important;
  /*@include kt-hack-ie {
	overflow: visible;
}*/
}

html.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown):not(.kt-sweetalert2--nopadding),
body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown):not(.kt-sweetalert2--nopadding) {
  padding-right: 0 !important;
}

.swal2-popup {
  padding: 3rem;
  border-radius: 4px;
}

.swal2-popup .swal2-title {
  font-weight: 500;
  font-size: 1rem;
  margin: 0 0 1rem;
}

.swal2-popup .swal2-content {
  font-weight: normal;
  font-size: 0.8125rem;
}

.swal2-popup .btn,
.swal2-popup .wizard > .actions a,
.wizard > .actions .swal2-popup a,
.swal2-popup .fc button,
.fc .swal2-popup button {
  margin: 15px 5px 0;
}

.swal2-popup .swal2-styled:focus {
  box-shadow: none;
}

.swal2-popup .swal2-actions button {
  font-size: 0.875rem;
}

.swal2-popup .swal2-footer a {
  color: #8d8a89;
  transition: all, 0.3s;
}

@media (prefers-reduced-motion: reduce) {
  .swal2-popup .swal2-footer a {
    transition: none;
  }
}

.swal2-popup .swal2-footer a:hover {
  text-decoration: none;
  color: #ef3e27;
}

.swal2-container {
  overflow-y: hidden !important;
}

.swal2-container.swal2-shown {
  background-color: rgba(0, 0, 0, 0.2);
}

.swal2-popup .swal2-title {
  color: #3f3f3f;
}

.swal2-popup .swal2-content {
  color: #ababbb;
}

body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

.form-control:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row input:focus[type='text'],
.jsgrid .jsgrid-table .jsgrid-filter-row select:focus,
.jsgrid .jsgrid-table .jsgrid-edit-row input:focus[type='text'],
.jsgrid .jsgrid-table .jsgrid-edit-row select:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row input:focus[type='number'],
.jsgrid .jsgrid-table .jsgrid-edit-row input:focus[type='number'] {
  outline: 0;
  box-shadow: none;
}

a,
div,
h1,
h2,
h3,
h4,
h5,
p,
span {
  text-shadow: none;
}

[type='button']:focus,
a:active,
a:focus,
a:visited,
button::-moz-focus-inner,
input[type='reset']::-moz-focus-inner,
input[type='button']::-moz-focus-inner,
input[type='submit']::-moz-focus-inner,
input[type='file'] > input[type='button']::-moz-focus-inner,
select::-moz-focus-inner {
  outline: 0;
}

input,
.form-control:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row input:focus[type='text'],
.jsgrid .jsgrid-table .jsgrid-filter-row select:focus,
.jsgrid .jsgrid-table .jsgrid-edit-row input:focus[type='text'],
.jsgrid .jsgrid-table .jsgrid-edit-row select:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row input:focus[type='number'],
.jsgrid .jsgrid-table .jsgrid-edit-row input:focus[type='number'],
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
  outline-width: 0;
  outline-color: transparent;
  box-shadow: none;
  outline-style: none;
}

textarea {
  resize: none;
  overflow-x: hidden;
}

.btn,
.wizard > .actions a,
.fc button,
.btn-group.open .dropdown-toggle,
.fc .open.fc-button-group .dropdown-toggle,
.btn:active,
.wizard > .actions a:active,
.fc button:active,
.btn:focus,
.wizard > .actions a:focus,
.fc button:focus,
.btn:hover,
.wizard > .actions a:hover,
.fc button:hover,
.btn:visited,
.wizard > .actions a:visited,
.fc button:visited,
a,
a:active,
a:checked,
a:focus,
a:hover,
a:visited,
body,
button,
button:active,
button:hover,
button:visited,
div,
input,
input:active,
input:focus,
input:hover,
input:visited,
select,
select:active,
select:focus,
select:visited,
textarea,
textarea:active,
textarea:focus,
textarea:hover,
textarea:visited {
  box-shadow: none;
}

.btn.active.focus,
.wizard > .actions a.active.focus,
.fc button.active.focus,
.btn.active:focus,
.wizard > .actions a.active:focus,
.fc button.active:focus,
.btn.focus,
.wizard > .actions a.focus,
.fc button.focus,
.btn:active.focus,
.wizard > .actions a:active.focus,
.fc button:active.focus,
.btn:active:focus,
.wizard > .actions a:active:focus,
.fc button:active:focus,
.btn:focus,
.wizard > .actions a:focus,
.fc button:focus,
button,
button:active,
button:checked,
button:focus,
button:hover,
button:visited {
  outline: 0;
  outline-offset: 0;
}

.bootstrap-select .dropdown-toggle:focus {
  outline: 0;
  outline-offset: 0;
}

.dropdown-menu > li > a:active,
.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:visited {
  outline: 0;
}

a:focus,
input:focus {
  border-color: transparent;
  outline: none;
}

/* Typography */
body {
  font-family: 'Chivo', sans-serif;

  color: #404040;
  font-size: 0.875rem;
  font-size: 0.8125rem;
  font-family: 'Chivo', sans-serif;
  font-weight: normal;
  font-weight: initial;
  line-height: normal;
  -webkit-font-smoothing: antialiased;
}

/* h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	font-weight: 700;
	text-transform: uppercase;
	line-height: 1;
} */
.page-title {
  font-weight: bolder !important;
}
.cm_bold {
  text-transform: uppercase;
  font-weight: bolder !important;
}
.cm_bold_str {
  font-weight: 500 !important;
  color: #3f3f3f !important;
}

.event-body .event-list .event-owl.owl-carousel .event-card .event-date {
  position: absolute;
  background-color: white;
  width: 70px;
  height: 70px;
  font-weight: bold;
  color: #000;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: 15px !important;
  top: 0;
  left: 0;
}
p.text-brand.text-capitalize.font-weight-bold {
  margin-bottom: 20px !important;
}

p {
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

h1,
.h1 {
  font-size: 2.19rem;
}

h2,
.h2 {
  font-size: 1.88rem;
}

h3,
.h3 {
  font-size: 1.56rem;
}

h4,
.h4 {
  font-size: 1.13rem;
}

h5,
.h5 {
  font-size: 1rem;
}

h6,
.h6 {
  font-size: 0.9375rem;
}

.display-1 {
  font-size: 3.75rem;
}

@media (max-width: 991px) {
  .display-1 {
    font-size: 3rem;
  }
}

.display-2 {
  font-size: 3.125rem;
}

@media (max-width: 991px) {
  .display-2 {
    font-size: 2.5rem;
  }
}

.display-3 {
  font-size: 2.5rem;
}

@media (max-width: 991px) {
  .display-3 {
    font-size: 2rem;
  }
}

.display-4 {
  font-size: 1.875rem;
}

@media (max-width: 991px) {
  .display-4 {
    font-size: 1.5rem;
  }
}

.display-5 {
  font-size: 1.25rem;
}

@media (max-width: 991px) {
  .display-5 {
    font-size: 1rem;
  }
}

.blockquote {
  padding: 1.25rem;
  border: 1px solid #e6e4e9;
}

/* Lists */
ul,
ol,
dl {
  padding-left: 1rem;
  font-size: 0.8125rem;
}

.blockquote-primary {
  border-color: #ef3e27;
}

.blockquote-primary .blockquote-footer {
  color: #ef3e27;
}

.blockquote-secondary {
  border-color: #d3dae6;
}

.blockquote-secondary .blockquote-footer {
  color: #d3dae6;
}

.blockquote-success {
  border-color: #00cb8e;
}

.blockquote-success .blockquote-footer {
  color: #00cb8e;
}

.blockquote-info {
  border-color: #00aaff;
}

.blockquote-info .blockquote-footer {
  color: #00aaff;
}

.blockquote-warning {
  border-color: #ffc555;
}

.blockquote-warning .blockquote-footer {
  color: #ffc555;
}

.blockquote-danger {
  border-color: #ef3e27;
}

.blockquote-danger .blockquote-footer {
  color: #ef3e27;
}

.blockquote-light {
  border-color: #f9fafb;
}

.blockquote-light .blockquote-footer {
  color: #f9fafb;
}

.blockquote-dark {
  border-color: #242a34;
}

.blockquote-dark .blockquote-footer {
  color: #242a34;
}

.text-twitter {
  color: #2caae1;
}

.text-facebook {
  color: #3b579d;
}

.text-google {
  color: #dc4a38;
}

.text-linkedin {
  color: #0177b5;
}

.text-instagram {
  color: #e4405f;
}

.text-pinterest {
  color: #cc2127;
}

.text-youtube {
  color: #e52d27;
}

.text-github {
  color: #333333;
}

.text-behance {
  color: #1769ff;
}

.text-dribbble {
  color: #ea4c89;
}

.text-reddit {
  color: #ff4500;
}

.text-soft-primary {
  color: #a3b2ff;
}

.text-soft-secondary {
  color: #e9edf3;
}

.text-soft-success {
  color: #80e5c7;
}

.text-soft-info {
  color: #80d5ff;
}

.text-soft-warning {
  color: #ffe2aa;
}

.text-soft-danger {
  color: #ffabb3;
}

.text-soft-light {
  color: #fcfdfd;
}

.text-soft-dark {
  color: #92959a;
}

.card-margin {
  margin-bottom: 1.25rem;
}

@media (max-width: 767px) {
  .card-margin {
    margin-bottom: 0.9375rem;
  }
}

.card-viewport {
  align-items: stretch;
  justify-content: stretch;
}

.card-viewport > .card {
  width: 100%;
  min-width: 100%;
}

small {
  font-size: 85%;
}

.page-body-wrapper {
  display: flex;
  flex-direction: row;
  padding-left: 0;
  padding-right: 0;
}

.page-body-wrapper.full-page-wrapper {
  width: 100%;
  min-height: 100vh;
  padding-top: 0;
}

.main-panel {
  transition: all 0.25s ease, margin 0.25s ease;
  width: 100%;
  min-height: calc(100vh - 3.75rem);
  position: absolute;
  top: 3.75rem;
  right: 0;
  display: flex;
  flex-direction: column;
  padding-left: 270px;
}

@media (max-width: 1024px) {
  .main-panel {
    margin-left: 0;
    width: 100% !important;
    top: 3.75rem;
    right: 0;
    position: absolute;
    padding-left: 0 !important;
  }
}

.minimized-sidebar .main-panel {
  padding-left: 90px;
}

.content-wrapper {
  background: #f9f9f9;
  padding: 1.25rem 1.875rem;
  width: 100%;
  flex-grow: 1;
}

@media (max-width: 767px) {
  .content-wrapper {
    padding: 0.75rem;
  }
}

.minimized-sidebar .navbar {
  padding: 0 0 0 90px;
}

.navbar {
  font-weight: 400;
  padding: 0 0 0 270px;
  width: 100%;
  margin-left: auto;
  transition: all, 0.3s;
}

@media (prefers-reduced-motion: reduce) {
  .navbar {
    transition: none;
  }
}

.navbar.non-sticky-top {
  position: absolute;
  z-index: 90;
}

.navbar.fixed-top {
  z-index: 90;
}

.navbar.dark-top .navbar-menu-container {
  background: #19202a;
}

.sub-header {
  color: #ffffff;
}

.navbar.dark-top .navbar-menu-container .navbar-nav .nav-item .nav-link:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar.dark-top
  .navbar-menu-container
  .navbar-nav
  .nav-item
  .nav-link:hover
  svg,
.navbar.dark-top
  .navbar-menu-container
  .navbar-nav
  .nav-item
  .nav-link:hover
  i {
  color: #ef3e27;
}

.navbar .navbar-menu-container {
  background: #ffffff;
  width: 100%;
  color: #9396a5;
  height: 3.75rem;
  box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
  -ms-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
}

.sub-header {
  display: flex;
}

.sub-header {
  color: #3f3f3f;
  width: 270px;
  padding-left: 1.875rem;
}

.navbar .navbar-menu-container .navbar-nav .nav-item {
  margin-right: 0.5rem;
}

.navbar .navbar-menu-container .navbar-nav .nav-item.mobile-sidebar {
  display: none;
}

.navbar .navbar-menu-container .navbar-nav .nav-item .nav-link {
  color: inherit;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.25rem 0.125rem;
  width: 2.5rem;
  height: 2.5rem;
  text-align: center;
  border-radius: 50%;
  transition: all 0.2s;
}

@media (max-width: 767px) {
  .navbar .navbar-menu-container .navbar-nav .nav-item .nav-link svg {
    width: 20px;
    height: 20px;
  }
}

.navbar .navbar-menu-container .navbar-nav .nav-item .nav-link:hover {
  background-color: #ececec;
}

.navbar .navbar-menu-container .navbar-nav .nav-item .nav-link:hover svg,
.navbar .navbar-menu-container .navbar-nav .nav-item .nav-link:hover i {
  color: #ef3e27;
}

@media (prefers-reduced-motion: reduce) {
  .navbar .navbar-menu-container .navbar-nav .nav-item .nav-link {
    transition: none;
  }
}

.navbar .navbar-menu-container .navbar-nav .nav-item .nav-notify-container {
  line-height: 24px;
  position: absolute;
  right: 5px;
  left: auto;
  top: 48px;
  width: 320px;
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}

.navbar
  .navbar-menu-container
  .navbar-nav
  .nav-item
  .nav-notify-container.animated {
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@media (max-width: 767px) {
  .navbar
    .navbar-menu-container
    .navbar-nav
    .nav-item
    .nav-notify-container:not([aria-labelledby='notificationProfile']) {
    min-width: 90%;
    right: 5%;
    left: 5%;
    top: 3.75rem;
  }
}

.navbar
  .navbar-menu-container
  .navbar-nav
  .nav-item
  .nav-notify-container.show {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

.navbar
  .navbar-menu-container
  .navbar-nav
  .nav-item
  .nav-notify-container
  .notify-header {
  line-height: 25px;
  font-size: 10px;
  font-weight: 600;
  border-bottom: 1px solid #e6e4e9;
  color: #3f3f3f;
}

.navbar
  .navbar-menu-container
  .navbar-nav
  .nav-item
  .nav-notify-container
  .notify-body {
  font-weight: 500;
}

.navbar
  .navbar-menu-container
  .navbar-nav
  .nav-item
  .nav-notify-container
  .notify-footer {
  font-size: 12px;
  font-weight: 500;
  color: #3f3f3f;
}

.navbar
  .navbar-menu-container
  .navbar-nav
  .nav-item
  .nav-notify-container
  .notify-footer
  a {
  color: inherit;
  text-decoration: underline;
}

.navbar
  .navbar-menu-container
  .navbar-nav
  .nav-item
  .nav-notify-container
  .dropdown-divider {
  margin: 0;
}

.navbar .navbar-menu-container .navbar-nav .nav-item.nav-profile img {
  width: 30px;
  height: 30px;
  border-radius: 100%;
}

.navbar
  .navbar-menu-container
  .navbar-nav
  .nav-item.dropdown
  .dropdown-toggle:after {
  display: none;
}

.navbar .navbar-menu-container .navbar-nav .nav-item.dropdown .dropdown-menu {
  border: none;
  box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
}

.navbar .navbar-menu-container .navbar-nav .nav-item.dropdown .navbar-dropdown {
  position: absolute;
  font-size: 0.9rem;
  margin-top: 0;
  right: 0;
  left: auto;
  top: 48px;
  padding: 0;
}

.navbar
  .navbar-menu-container
  .navbar-nav
  .nav-item.dropdown
  .navbar-dropdown
  .dropdown-item {
  margin-bottom: 0;
  padding: 11px 13px;
}

.navbar
  .navbar-menu-container
  .navbar-nav
  .nav-item.dropdown
  .navbar-dropdown
  .dropdown-item
  .ellipsis {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.navbar
  .navbar-menu-container
  .navbar-nav
  .nav-item.dropdown
  .navbar-dropdown
  .dropdown-divider {
  margin: 0;
}

@media (max-width: 991px) {
  .navbar .navbar-menu-container .navbar-nav .nav-item.dropdown {
    position: static;
  }
  .navbar
    .navbar-menu-container
    .navbar-nav
    .nav-item.dropdown
    .navbar-dropdown {
    left: 20px;
    right: 20px;
    top: 3.75rem;
    width: calc(100% - 40px);
  }
}

.navbar
  .navbar-menu-container
  .navbar-nav
  .nav-item.dropdown
  .count-highlighter {
  position: relative;
  text-align: center;
}

.navbar
  .navbar-menu-container
  .navbar-nav
  .nav-item.dropdown
  .count-highlighter
  .count {
  position: absolute;
  left: 43%;
  width: 5px;
  height: 5px;
  border-radius: 100%;
  background: #ef3e27;
  top: 3px;
}

.navbar
  .navbar-menu-container
  .navbar-nav
  .nav-item.dropdown
  .count-highlighter:after {
  display: none;
}

.navbar .navbar-menu-container .navbar-nav.navbar-nav-right {
  margin-left: auto;
}

@media (min-width: 1025px) {
  .navbar .navbar-menu-container .navbar-brand-container {
    display: none;
  }
}

@media (max-width: 1024px) {
  .navbar {
    width: 100%;
    padding: 0;
  }
  .navbar .navbar-menu-container {
    padding-top: 0;
  }
  .navbar .navbar-menu-container .navbar-nav .nav-item.mobile-sidebar {
    display: block;
  }
  .sub-header {
    display: none;
  }
  .navbar .navbar-menu-container .navbar-brand-container {
    width: 40px;
    height: 3.75rem;
    margin-left: 1rem;
    display: flex;
  }
  .navbar .navbar-menu-container .navbar-brand-container .brand-logo {
    padding-left: 0;
    text-align: center;
  }
  .navbar .navbar-menu-container .navbar-brand-container .brand-logo img {
    max-width: 100%;
    margin: auto;
  }
}

.dark-sidebar .navbar-container .primary {
  background: #231f20;
}

.sub-header {
  border-bottom: 0;
}

.sub-header {
  text-align: right;
  color: #ffffff;
}

.dark-sidebar .navbar-container .primary .nav-wrapper .nav .nav-header {
  color: rgba(210, 213, 228, 0.5);
}

.dark-sidebar .navbar-container .primary .nav-wrapper .nav .nav-item .nav-link {
  color: #fff;
}

.dark-sidebar
  .navbar-container
  .primary
  .nav-wrapper
  .nav
  .nav-item
  .nav-link
  svg.menu-icon,
.dark-sidebar
  .navbar-container
  .primary
  .nav-wrapper
  .nav
  .nav-item
  .nav-link
  svg.menu-arrow {
  color: #fff;
}
.dark-sidebar
  .navbar-container
  .primary
  .nav-wrapper
  .nav
  .nav-item.active
  .nav-link
  svg.menu-icon {
  color: #ef3e27;
}

.dark-sidebar
  .navbar-container
  .primary
  .nav-wrapper
  .nav
  .nav-item
  .nav-link:hover {
  color: #ef3e27;
}

.dark-sidebar
  .navbar-container
  .primary
  .nav-wrapper
  .nav
  .nav-item
  .nav-link:hover
  svg {
  color: inherit;
}

.dark-sidebar .navbar-container .primary .nav-wrapper .nav .nav-item.active {
  background: transparent;
  color: #ef3e27;
}

.dark-sidebar
  .navbar-container
  .primary
  .nav-wrapper
  .nav
  .nav-item.active
  .nav-link {
  color: #ef3e27 !important;
}

.dark-sidebar
  .navbar-container
  .primary
  .nav-wrapper
  .nav.sub-menu
  .nav-item
  .nav-link {
  color: #fff;
}

.dark-sidebar
  .navbar-container
  .primary
  .nav-wrapper
  .nav.sub-menu
  .nav-item
  .nav-link:hover {
  color: #d2d5e4;
}

.brand-sidebar .navbar-container .primary {
  background: #4a5ad3;
}

.sub-header {
  border-bottom: 0;
}

.sub-header {
  text-align: right;
  color: #ffffff;
}

.brand-sidebar .navbar-container .primary .nav-wrapper .nav .nav-header {
  color: rgba(255, 255, 255, 0.5);
}

.brand-sidebar
  .navbar-container
  .primary
  .nav-wrapper
  .nav
  .nav-item
  .nav-link {
  color: rgba(255, 255, 255, 0.8);
}

.brand-sidebar
  .navbar-container
  .primary
  .nav-wrapper
  .nav
  .nav-item
  .nav-link
  svg.menu-icon,
.brand-sidebar
  .navbar-container
  .primary
  .nav-wrapper
  .nav
  .nav-item
  .nav-link
  svg.menu-arrow {
  color: rgba(255, 255, 255, 0.5);
}

.brand-sidebar
  .navbar-container
  .primary
  .nav-wrapper
  .nav
  .nav-item
  .nav-link:hover {
  color: #ffffff;
}

.brand-sidebar
  .navbar-container
  .primary
  .nav-wrapper
  .nav
  .nav-item
  .nav-link:hover
  svg {
  color: inherit;
}

.brand-sidebar .navbar-container .primary .nav-wrapper .nav .nav-item.active {
  background: rgba(237, 240, 255, 0.2);
  border-radius: 4px;
}

.brand-sidebar
  .navbar-container
  .primary
  .nav-wrapper
  .nav
  .nav-item.active
  .nav-link {
  color: #d2d5e4 !important;
}

.brand-sidebar
  .navbar-container
  .primary
  .nav-wrapper
  .nav.sub-menu
  .nav-item
  .nav-link {
  color: rgba(255, 255, 255, 0.8);
}

.brand-sidebar
  .navbar-container
  .primary
  .nav-wrapper
  .nav.sub-menu
  .nav-item
  .nav-link:hover {
  color: #ffffff;
}

/* .navbar-container {
	transition: all 0.3s;
	min-height: calc(100vh - 3.75rem);
	padding: 0;
	position: fixed;
	overflow: hidden;
	top: 0;
	bottom: 0;
	left: 0;
	z-index: 99;
	display: flex;
	box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
	-webkit-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
	-moz-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
	-ms-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
} */

@media (prefers-reduced-motion: reduce) {
  .navbar-container {
    transition: none;
  }
}

.navbar-container .primary {
  background: #ffffff;
  width: 270px;
}

.sub-header {
  width: 270px;
  padding: 1rem;
  height: calc(3.75rem + 1rem);
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sub-header {
  height: calc(6rem - 1.5rem);
  margin-left: 42%;
  margin-top: 20px;
}

.sub-header {
  transition: all 0.3s ease;
  text-align: right;
  color: #9396a5;
}

@media (prefers-reduced-motion: reduce) {
  .sub-header {
    transition: none;
  }
}

.navbar-container .primary .nav-wrapper {
  top: calc(3.75rem + 1rem);
  height: calc(100vh - 100px);
  position: relative;
  overflow: auto;
}

.navbar-container .primary .nav-wrapper .nav {
  overflow: hidden;
  flex-wrap: nowrap;
  flex-direction: column;
  padding: 10px 10px 10px 20px;
}

.navbar-container .primary .nav-wrapper .nav .nav-header {
  text-transform: uppercase;
  font-size: 0.8125rem;
  color: #3f3f3f;
  margin: 1rem 0;
}

.navbar-container .primary .nav-wrapper .nav .nav-item {
  transition-property: 0.25s;
  transition-property: background;
}

.navbar-container .primary .nav-wrapper .nav .nav-item .collapse {
  z-index: 999;
}

.navbar-container .primary .nav-wrapper .nav .nav-item .nav-link {
  display: flex;
  white-space: nowrap;
  padding: 0.5625rem 0.875rem;
  color: #3f3f3f;
  transition-property: 0.45s;
  transition-property: color;
  align-items: center;
}

.navbar-container .primary .nav-wrapper .nav .nav-item .nav-link svg {
  flex-shrink: 0;
}

.navbar-container .primary .nav-wrapper .nav .nav-item .nav-link svg.menu-icon {
  display: inline-block;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 0.75rem;
  color: rgba(88, 94, 127, 0.5);
  width: 1rem;
  height: 1rem;
}

.navbar-container
  .primary
  .nav-wrapper
  .nav
  .nav-item
  .nav-link
  svg.menu-arrow {
  display: inline-block;
  text-align: right;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-left: auto;
  margin-right: 0;
  color: #3f3f3f;
  width: 0.75rem;
  height: 0.75rem;
  transition: all 0.2s ease-in;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-container
    .primary
    .nav-wrapper
    .nav
    .nav-item
    .nav-link
    svg.menu-arrow {
    transition: none;
  }
}

.navbar-container .primary .nav-wrapper .nav .nav-item .nav-link .menu-title {
  color: inherit;
  display: inline-block;
  font-size: 0.8125rem;
  vertical-align: middle;
  text-transform: uppercase;
  font-weight: 700;
}

.navbar-container .primary .nav-wrapper .nav .nav-item .nav-link .badge {
  margin-left: auto;
}

.navbar-container
  .primary
  .nav-wrapper
  .nav
  .nav-item
  .nav-link[aria-expanded='true']
  svg.menu-arrow {
  transition: all 0.2s ease-in;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

@media (prefers-reduced-motion: reduce) {
  .navbar-container
    .primary
    .nav-wrapper
    .nav
    .nav-item
    .nav-link[aria-expanded='true']
    svg.menu-arrow {
    transition: none;
  }
}

.navbar-container .primary .nav-wrapper .nav .nav-item .nav-link:hover {
  color: #ef3e27;
}

.navbar-container .primary .nav-wrapper .nav .nav-item .nav-link:hover svg {
  color: inherit;
}

.navbar-container .primary .nav-wrapper .nav .nav-item.active {
  background: rgba(237, 240, 255, 0.6);
  border-radius: 4px;
}

.navbar-container .primary .nav-wrapper .nav .nav-item.active .nav-link {
  color: #ef3e27;
}

.navbar-container .primary .nav-wrapper .nav.sub-menu {
  margin-bottom: 0;
  margin-top: 0;
  padding: 0.25rem 0 0 1.5rem;
}

.navbar-container .primary .nav-wrapper .nav.sub-menu .nav-item {
  padding: 0;
}

.navbar-container .primary .nav-wrapper .nav.sub-menu .nav-item .nav-link {
  padding: 0.5em 0.5em 0.5em 1em;
  position: relative;
  font-size: 0.8125rem;
  border-top: 0;
}

.navbar-container
  .primary
  .nav-wrapper
  .nav.sub-menu
  .nav-item
  .nav-link:before {
  content: '•';
  padding-right: 0.625rem;
}

.navbar-container
  .primary
  .nav-wrapper
  .nav.sub-menu
  .nav-item
  .nav-link:hover {
  color: #ef3e27;
}

.minimized-sidebar .navbar-container {
  transition: all 0.3s;
  position: fixed;
  overflow: hidden;
  top: 0;
  z-index: 99;
}

@media (prefers-reduced-motion: reduce) {
  .minimized-sidebar .navbar-container {
    transition: none;
  }
}

.minimized-sidebar .navbar-container .primary {
  transition: all 0.3s ease;
  width: 90px;
}

@media (prefers-reduced-motion: reduce) {
  .minimized-sidebar .navbar-container .primary {
    transition: none;
  }
}

.minimized-sidebar .navbar-container .primary:hover {
  transition: all 0.3s ease;
  width: 270px;
}

@media (prefers-reduced-motion: reduce) {
  .minimized-sidebar .navbar-container .primary:hover {
    transition: none;
  }
}

.sub-header {
  width: 270px;
}

.sub-header {
  opacity: 1;
  visibility: visible;
}

.sub-header {
  height: calc(3.75rem - 1.5rem);
  width: auto;
}

.minimized-sidebar
  .navbar-container
  .primary:hover
  .nav-wrapper
  .nav
  .nav-item {
  transition-property: 0.25s;
  transition-property: background;
}

.minimized-sidebar
  .navbar-container
  .primary:hover
  .nav-wrapper
  .nav
  .nav-item
  .collapse {
  z-index: 999;
}

.minimized-sidebar
  .navbar-container
  .primary:hover
  .nav-wrapper
  .nav
  .nav-item
  .nav-link {
  display: flex;
}

.minimized-sidebar
  .navbar-container
  .primary:hover
  .nav-wrapper
  .nav
  .nav-item
  .nav-link
  svg {
  flex-shrink: 0;
}

.minimized-sidebar
  .navbar-container
  .primary:hover
  .nav-wrapper
  .nav
  .nav-item
  .nav-link
  svg.menu-icon {
  margin-right: 0.75rem;
  display: inline-block;
}

.minimized-sidebar
  .navbar-container
  .primary:hover
  .nav-wrapper
  .nav
  .nav-item
  .nav-link
  svg.menu-arrow {
  display: inline-block;
}

.minimized-sidebar
  .navbar-container
  .primary:hover
  .nav-wrapper
  .nav
  .nav-item
  .nav-link
  .menu-title {
  display: inline-block;
}

.minimized-sidebar
  .navbar-container
  .primary:hover
  .nav-wrapper
  .nav
  .nav-item
  .nav-link
  .badge {
  display: flex;
  margin-left: auto;
}

.minimized-sidebar
  .navbar-container
  .primary:hover
  .nav-wrapper
  .nav
  .collapse.show {
  display: block;
}

.sub-header {
  width: 90px;
}

.sub-header {
  opacity: 0;
  transition: all 0.3s ease;
  visibility: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .sub-header {
    transition: none;
  }
}

.sub-header {
  width: 1px;
}

/* .minimized-sidebar .navbar-container .primary .nav-wrapper .nav .nav-item {
	-moz-transition-property: 0.25s;
	-o-transition-property: 0.25s;
	-webkit-transition-property: 0.25s;
	transition-property: 0.25s;
	-moz-transition-property: background;
	-o-transition-property: background;
	-webkit-transition-property: background;
	transition-property: background;
} */

.minimized-sidebar
  .navbar-container
  .primary
  .nav-wrapper
  .nav
  .nav-item
  .collapse {
  z-index: 999;
}

.minimized-sidebar
  .navbar-container
  .primary
  .nav-wrapper
  .nav
  .nav-item
  .nav-link
  svg.menu-icon {
  margin: 0.225rem 0.75rem 0.225rem 0;
}

.minimized-sidebar
  .navbar-container
  .primary
  .nav-wrapper
  .nav
  .nav-item
  .nav-link
  svg.menu-arrow {
  display: none;
}

.minimized-sidebar
  .navbar-container
  .primary
  .nav-wrapper
  .nav
  .nav-item
  .nav-link
  .menu-title {
  display: none;
}

.minimized-sidebar
  .navbar-container
  .primary
  .nav-wrapper
  .nav
  .nav-item
  .nav-link
  .badge {
  display: none;
}

.minimized-sidebar .navbar-container .primary .nav-wrapper .nav .collapse.show {
  display: none;
}

@media (max-width: 1024px) {
  .navbar-container {
    left: calc(-80px - 270px * 2);
    z-index: 999;
  }
  .navbar-container.active {
    left: 0;
  }
  .sub-header {
    display: none;
  }
  .navbar-container .primary .nav-wrapper {
    top: 0;
  }
}

.page-title {
  margin-right: 1rem;
}

.subheader-toolbar {
  margin-left: auto;
}

.subheader-toolbar > .btn-icon {
  height: 31px !important;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.subheader-toolbar > .btn-icon svg {
  width: 18px;
  height: 18px;
}

/* Profile Toolbar */
.profile-overlay {
  border-left: 1px solid #e6e4e9;
  display: block;
  position: fixed;
  top: 0;
  right: -340px;
  bottom: 0;
  width: 320px;
  height: 100vh;
  min-height: 100%;
  background: #ffffff;
  z-index: 9999;
  transition: all 0.3s;
  box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
  -ms-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
}

@media (prefers-reduced-motion: reduce) {
  .profile-overlay {
    transition: none;
  }
}

.profile-overlay.active {
  right: 0;
}

.profile-overlay .title-head {
  position: absolute;
  width: 100%;
  padding: 1rem;
  color: #ffffff;
  background: #ef3e27;
  min-height: 119px;
}

.profile-overlay .profile-header {
  padding: 1rem;
  min-height: 280px;
  position: relative;
}

.profile-overlay .profile-header .profile-close {
  width: 1.25rem;
  height: 1.25rem;
  line-height: 2.1875rem;
  text-align: center;
  cursor: pointer;
  display: block;
  transition: all 0.3s;
}

@media (prefers-reduced-motion: reduce) {
  .profile-overlay .profile-header .profile-close {
    transition: none;
  }
}

.profile-overlay .profile-header .user-info {
  padding-top: 1rem;
}

.profile-overlay .profile-header .user-info .user-pic {
  text-align: center;
  padding-bottom: 1rem;
}

.profile-overlay .profile-header .user-info .user-pic img {
  width: 130px;
  height: 130px;
}

.profile-overlay .profile-header .user-info .primary-info {
  padding-left: 1rem;
  color: #9da1b8;
}

.profile-overlay .profile-header .user-info .primary-info a {
  color: #3f3f3f;
}

.profile-overlay .profile-header .user-info .primary-info a:hover {
  color: #ef3e27;
  text-decoration: none;
}

.profile-overlay .profile-header .user-info .secondary-info {
  margin-top: 1.875rem;
}

.profile-overlay .profile-body {
  padding: 0 1rem;
  height: calc(100vh - 280px);
  position: relative;
  font-weight: 500;
}

.profile-overlay .profile-body .body-wrapper .project-stat,
.profile-overlay .profile-body .body-wrapper .account-stat {
  padding-top: 1.5rem;
}

.profile-overlay .profile-body .body-wrapper .project-stat ul li,
.profile-overlay .profile-body .body-wrapper .account-stat ul li {
  background-color: #ffffff;
}

.sidebar-overlay {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 998;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.sidebar-overlay.active {
  display: block;
  opacity: 0.1;
}

/* Settings Overlay */
.settings-overlay {
  display: block;
  position: fixed;
  top: 0;
  right: -340px;
  bottom: 0;
  width: 320px;
  height: 100vh;
  min-height: 100%;
  background: #ffffff;
  z-index: 9999;
  transition: all 0.3s;
  box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
  -ms-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
}

@media (prefers-reduced-motion: reduce) {
  .settings-overlay {
    transition: none;
  }
}

.settings-overlay.active {
  right: 0;
}

.settings-overlay .settings-header {
  padding: 1rem;
  text-align: center;
  border-bottom: 1px solid #e6e4e9;
  color: #3f3f3f;
  height: 50px;
}

.settings-overlay .setting-close {
  position: absolute;
  top: 1rem;
  left: 1rem;
  width: 1.25rem;
  height: 1.25rem;
  color: #3f3f3f;
  cursor: pointer;
}

@media (max-width: 400px) {
  .settings-overlay {
    width: 90%;
  }
}

.sticky-footer .footer {
  position: fixed;
  z-index: 99;
  bottom: 0;
  width: 100%;
}

.footer {
  background: #ffffff;
  color: #ababbb;
  padding: 1.5rem;
  font-size: calc(0.875rem - 0.05rem);
  font-weight: 400;
}

.footer a {
  color: #ef3e27;
  font-size: inherit;
}

@media (max-width: 991px) {
  .footer {
    margin-left: 0;
    width: 100%;
  }
}

/* Accordions */
.accordion .card {
  margin-bottom: 0;
  border-radius: 0.25rem;
  box-shadow: none;
}

.accordion .card .card-header {
  background-color: #eeeff5;
  padding: 0.9375rem;
  border: 1px solid #e6e4e9;
  line-height: 1.5;
  min-height: 40px;
  font-weight: 500;
  font-size: 0.875rem;
  color: #3f3f3f;
}

.accordion .card .card-header a {
  display: flex;
  flex-grow: 1;
  align-items: center;
  color: inherit;
  font-size: inherit;
  position: relative;
  cursor: pointer;
  padding-right: 0.5rem;
  transition: color 0.5s ease;
}

@media (prefers-reduced-motion: reduce) {
  .accordion .card .card-header a {
    transition: none;
  }
}

.accordion .card .card-header a .header-icon {
  width: 20px;
  height: 20px;
  margin-right: 1rem;
  flex-shrink: 0;
}

.accordion .card .card-header a .header-arrows {
  width: 20px;
  height: 20px;
  margin-left: auto;
  flex-shrink: 0;
  transition: all, 0.3s;
}

@media (prefers-reduced-motion: reduce) {
  .accordion .card .card-header a .header-arrows {
    transition: none;
  }
}

.accordion .card .card-header a:hover,
.accordion .card .card-header a:not(.collapsed) {
  color: #ef3e27;
}

.accordion .card .card-header a:hover .header-arrows,
.accordion .card .card-header a:not(.collapsed) .header-arrows {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  transition: all, 0.3s;
}

@media (prefers-reduced-motion: reduce) {
  .accordion .card .card-header a:hover .header-arrows,
  .accordion .card .card-header a:not(.collapsed) .header-arrows {
    transition: none;
  }
}

.accordion .card .card-body {
  border-left: 1px solid #e6e4e9;
  border-right: 1px solid #e6e4e9;
  color: #3f3f3f;
  font-size: 13px;
  padding: 1rem;
  font-weight: 300;
  line-height: 1.5;
}

.accordion .card .card-body i {
  font-size: 1.25rem;
}

.accordion.accordion-bordered {
  background: #ffffff;
}

.accordion.accordion-bordered .card {
  border-top: 1px solid #e6e4e9;
  box-shadow: none;
  border-radius: 0;
}

.accordion.accordion-bordered .card .card-header {
  background: #ffffff;
  border: 0;
}

.accordion.accordion-bordered .card .card-body {
  border: 0;
}

.accordion.accordion-bordered .card:first-child {
  border-top: 0;
}

.accordion.accordion-faq-bordered {
  background: #ffffff;
}

.accordion.accordion-faq-bordered .card {
  border-top: 1px solid #e6e4e9;
  box-shadow: none;
  border-radius: 0;
}

.accordion.accordion-faq-bordered .card .card-header {
  background: #ffffff;
  border: 0;
}

.accordion.accordion-faq-bordered .card .card-header a:hover,
.accordion.accordion-faq-bordered .card .card-header a:not(.collapsed) {
  color: #3f3f3f;
}

.accordion.accordion-faq-bordered .card .card-body {
  border: 0;
  font-weight: 400;
  color: #ababbb;
}

.accordion.accordion-faq-bordered .card:first-child {
  border-top: 0;
}

.accordion.accordion-boxed .card {
  margin-bottom: 1rem;
  border: 1px solid #e6e4e9 !important;
  border-radius: 4px !important;
}

.accordion.accordion-boxed .card .card-header {
  background: #ffffff;
  border: 0;
}

.accordion.accordion-boxed .card .card-body {
  border: 0;
}

.accordion.accordion-faq-boxed .card {
  margin-bottom: 1rem;
  border: 1px solid #e6e4e9 !important;
  border-radius: 4px !important;
}

.accordion.accordion-faq-boxed .card .card-header {
  background: #ffffff;
  border: 0;
}

.accordion.accordion-faq-boxed .card .card-body {
  border: 0;
  font-weight: 400;
  color: #ababbb;
}

.accordion.accordion-multi-colored .card {
  margin-bottom: 1rem;
  border-radius: 4px !important;
}

.accordion.accordion-multi-colored .card .card-header,
.accordion.accordion-multi-colored .card .card-body {
  background: transparent;
  color: #ffffff;
}

.accordion.accordion-multi-colored .card:nth-child(1) {
  background: #ef3e27;
}

.accordion.accordion-multi-colored .card:nth-child(2) {
  background: #00cb8e;
}

.accordion.accordion-multi-colored .card:nth-child(3) {
  background: #ef3e27;
}

.accordion.accordion-multi-colored .card:nth-child(4) {
  background: #ffc555;
}

.accordion.accordion-multi-colored .card:nth-child(5) {
  background: #00aaff;
}

.accordion.accordion-multi-colored .card .card-header a {
  color: inherit;
}

.accordion.accordion-multi-colored .card .card-header a:hover {
  color: inherit;
}

.bg-soft-primary {
  background-color: #ececec;
}

.bg-soft-primary-2 {
  color: #ffffff;
  background-color: #637cff;
}

.filled-primary {
  fill: #ef3e27;
}

.bg-soft-secondary {
  background-color: #f6f8fa;
}

.bg-soft-secondary-2 {
  color: #ffffff;
  background-color: #dae0ea;
}

.filled-secondary {
  fill: #d3dae6;
}

.bg-soft-success {
  background-color: #ccf5e8;
}

.bg-soft-success-2 {
  color: #ffffff;
  background-color: #26d39f;
}

.filled-success {
  fill: #00cb8e;
}

.bg-soft-info {
  background-color: #cceeff;
}

.bg-soft-info-2 {
  color: #ffffff;
  background-color: #26b7ff;
}

.filled-info {
  fill: #00aaff;
}

.bg-soft-warning {
  background-color: #fff3dd;
}

.bg-soft-warning-2 {
  color: #ffffff;
  background-color: #ffce6f;
}

.filled-warning {
  fill: #ffc555;
}

.bg-soft-danger {
  background-color: #ffdde0;
}

.bg-soft-danger-2 {
  color: #ffffff;
  background-color: #ff6f7d;
}

.filled-danger {
  fill: #ef3e27;
}

.bg-soft-light {
  background-color: #fefefe;
}

.bg-soft-light-2 {
  color: #ffffff;
  background-color: #fafbfc;
}

.filled-light {
  fill: #f9fafb;
}

.bg-soft-dark {
  background-color: #d3d4d6;
}

.bg-soft-dark-2 {
  color: #ffffff;
  background-color: #454a52;
}

.filled-dark {
  fill: #242a34;
}

/* Badges */
.badge {
  line-height: 1;
  padding: 0.375rem 0.5625rem;
  font-weight: 600;
}

.badge.badge-pill {
  border-radius: 10rem;
}

.badge svg {
  width: 13px;
  height: 13px;
  margin-right: 0.3125rem;
}

/*Badge outlined variations*/
.badge-outline-primary {
  color: #ef3e27;
  border: 1px solid #ef3e27;
}

.badge-skills-primary {
  color: #ffffff;
  font-weight: 400;
  font-size: 11px;
  background-color: #ef3e27;
  padding: 0.4375rem;
}

.badge-skills-primary span {
  padding-left: 0.625rem;
}

.badge-skills-primary span:before {
  content: '';
  padding-right: 0.625rem;
  border-left: 1px solid #354cbf;
}

.badge-ticket-primary {
  color: #ef3e27;
  background-color: #e3e8ff;
  font-weight: 500;
  border-radius: 0;
}

.badge-soft-primary {
  color: #ef3e27;
  background-color: #e3e8ff;
}

.badge-soft-primary-2 {
  color: #ffffff;
  background-color: #637cff;
}

.badge-primary {
  color: #ffffff;
  background-color: #ef3e27;
}

a.badge-primary:hover,
a.badge-primary:focus {
  color: #ffffff;
  background-color: #14181d;
}

a.badge-primary:focus,
a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(71, 101, 255, 0.5);
}

.badge-sb-primary {
  background: #ef3e27;
  border-radius: 50%;
  color: #ffffff;
  padding: 7px 7px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.badge-outline-secondary {
  color: #d3dae6;
  border: 1px solid #d3dae6;
}

.badge-skills-secondary {
  color: #ffffff;
  font-weight: 400;
  font-size: 11px;
  background-color: #d3dae6;
  padding: 0.4375rem;
}

.badge-skills-secondary span {
  padding-left: 0.625rem;
}

.badge-skills-secondary span:before {
  content: '';
  padding-right: 0.625rem;
  border-left: 1px solid #9ea4ad;
}

.badge-ticket-secondary {
  color: #d3dae6;
  background-color: #f8f9fb;
  font-weight: 500;
  border-radius: 0;
}

.badge-soft-secondary {
  color: #d3dae6;
  background-color: #f8f9fb;
}

.badge-soft-secondary-2 {
  color: #ffffff;
  background-color: #dae0ea;
}

.badge-secondary {
  color: #212529;
  background-color: #d3dae6;
}

a.badge-secondary:hover,
a.badge-secondary:focus {
  color: #212529;
  background-color: #b2bfd4;
}

a.badge-secondary:focus,
a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(211, 218, 230, 0.5);
}

.badge-sb-secondary {
  background: #d3dae6;
  border-radius: 50%;
  color: #ffffff;
  padding: 7px 7px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.badge-outline-success {
  color: #00cb8e;
  border: 1px solid #00cb8e;
}

.badge-skills-success {
  color: #ffffff;
  font-weight: 400;
  font-size: 11px;
  background-color: #00cb8e;
  padding: 0.4375rem;
}

.badge-skills-success span {
  padding-left: 0.625rem;
}

.badge-skills-success span:before {
  content: '';
  padding-right: 0.625rem;
  border-left: 1px solid #00986b;
}

.badge-ticket-success {
  color: #00cb8e;
  background-color: #d9f7ee;
  font-weight: 500;
  border-radius: 0;
}

.badge-soft-success {
  color: #00cb8e;
  background-color: #d9f7ee;
}

.badge-soft-success-2 {
  color: #ffffff;
  background-color: #26d39f;
}

.badge-success {
  color: #ffffff;
  background-color: #00cb8e;
}

a.badge-success:hover,
a.badge-success:focus {
  color: #ffffff;
  background-color: #00986a;
}

a.badge-success:focus,
a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 203, 142, 0.5);
}

.badge-sb-success {
  background: #00cb8e;
  border-radius: 50%;
  color: #ffffff;
  padding: 7px 7px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.badge-outline-info {
  color: #00aaff;
  border: 1px solid #00aaff;
}

.badge-skills-info {
  color: #ffffff;
  font-weight: 400;
  font-size: 11px;
  background-color: #00aaff;
  padding: 0.4375rem;
}

.badge-skills-info span {
  padding-left: 0.625rem;
}

.badge-skills-info span:before {
  content: '';
  padding-right: 0.625rem;
  border-left: 1px solid #0080bf;
}

.badge-ticket-info {
  color: #00aaff;
  background-color: #d9f2ff;
  font-weight: 500;
  border-radius: 0;
}

.badge-soft-info {
  color: #00aaff;
  background-color: #d9f2ff;
}

.badge-soft-info-2 {
  color: #ffffff;
  background-color: #26b7ff;
}

.badge-info {
  color: #ffffff;
  background-color: #00aaff;
}

a.badge-info:hover,
a.badge-info:focus {
  color: #ffffff;
  background-color: #0088cc;
}

a.badge-info:focus,
a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 170, 255, 0.5);
}

.badge-sb-info {
  background: #00aaff;
  border-radius: 50%;
  color: #ffffff;
  padding: 7px 7px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.badge-outline-warning {
  color: #ffc555;
  border: 1px solid #ffc555;
}

.badge-skills-warning {
  color: #ffffff;
  font-weight: 400;
  font-size: 11px;
  background-color: #ffc555;
  padding: 0.4375rem;
}

.badge-skills-warning span {
  padding-left: 0.625rem;
}

.badge-skills-warning span:before {
  content: '';
  padding-right: 0.625rem;
  border-left: 1px solid #bf9440;
}

.badge-ticket-warning {
  color: #ffc555;
  background-color: #fff6e6;
  font-weight: 500;
  border-radius: 0;
}

.badge-soft-warning {
  color: #ffc555;
  background-color: #fff6e6;
}

.badge-soft-warning-2 {
  color: #ffffff;
  background-color: #ffce6f;
}

.badge-warning {
  color: #212529;
  background-color: #ffc555;
}

a.badge-warning:hover,
a.badge-warning:focus {
  color: #212529;
  background-color: #ffb422;
}

a.badge-warning:focus,
a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 197, 85, 0.5);
}

.badge-sb-warning {
  background: #ffc555;
  border-radius: 50%;
  color: #ffffff;
  padding: 7px 7px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.badge-outline-danger {
  color: #ef3e27;
  border: 1px solid #ef3e27;
}

.badge-skills-danger {
  color: #ffffff;
  font-weight: 400;
  font-size: 11px;
  background-color: #ef3e27;
  padding: 0.4375rem;
}

.badge-skills-danger span {
  padding-left: 0.625rem;
}

.badge-skills-danger span:before {
  content: '';
  padding-right: 0.625rem;
  border-left: 1px solid #bf414d;
}

.badge-ticket-danger {
  color: #ef3e27;
  background-color: #ffe6e8;
  font-weight: 500;
  border-radius: 0;
}

.badge-soft-danger {
  color: #ef3e27;
  background-color: #ffe6e8;
}

.badge-soft-danger-2 {
  color: #ffffff;
  background-color: #ff6f7d;
}

.badge-danger {
  color: #ffffff;
  background-color: #ef3e27;
}

a.badge-danger:hover,
a.badge-danger:focus {
  color: #ffffff;
  background-color: #ff2338;
}

a.badge-danger:focus,
a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 86, 102, 0.5);
}

.badge-sb-danger {
  background: #ef3e27;
  border-radius: 50%;
  color: #ffffff;
  padding: 7px 7px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.badge-outline-light {
  color: #f9fafb;
  border: 1px solid #f9fafb;
}

.badge-skills-light {
  color: #ffffff;
  font-weight: 400;
  font-size: 11px;
  background-color: #f9fafb;
  padding: 0.4375rem;
}

.badge-skills-light span {
  padding-left: 0.625rem;
}

.badge-skills-light span:before {
  content: '';
  padding-right: 0.625rem;
  border-left: 1px solid #bbbcbc;
}

.badge-ticket-light {
  color: #f9fafb;
  background-color: #fefefe;
  font-weight: 500;
  border-radius: 0;
}

.badge-soft-light {
  color: #f9fafb;
  background-color: #fefefe;
}

.badge-soft-light-2 {
  color: #ffffff;
  background-color: #fafbfc;
}

.badge-light {
  color: #212529;
  background-color: #f9fafb;
}

a.badge-light:hover,
a.badge-light:focus {
  color: #212529;
  background-color: #dae1e7;
}

a.badge-light:focus,
a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(249, 250, 251, 0.5);
}

.badge-sb-light {
  background: #f9fafb;
  border-radius: 50%;
  color: #ffffff;
  padding: 7px 7px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.badge-outline-dark {
  color: #242a34;
  border: 1px solid #242a34;
}

.badge-skills-dark {
  color: #ffffff;
  font-weight: 400;
  font-size: 11px;
  background-color: #242a34;
  padding: 0.4375rem;
}

.badge-skills-dark span {
  padding-left: 0.625rem;
}

.badge-skills-dark span:before {
  content: '';
  padding-right: 0.625rem;
  border-left: 1px solid #1b2027;
}

.badge-ticket-dark {
  color: #242a34;
  background-color: #dedfe1;
  font-weight: 500;
  border-radius: 0;
}

.badge-soft-dark {
  color: #242a34;
  background-color: #dedfe1;
}

.badge-soft-dark-2 {
  color: #ffffff;
  background-color: #454a52;
}

.badge-dark {
  color: #ffffff;
  background-color: #242a34;
}

a.badge-dark:hover,
a.badge-dark:focus {
  color: #ffffff;
  background-color: #0f1216;
}

a.badge-dark:focus,
a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(36, 42, 52, 0.5);
}

.badge-sb-dark {
  background: #242a34;
  border-radius: 50%;
  color: #ffffff;
  padding: 7px 7px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Bootstrap Alerts */
.alert {
  font-size: 0.8125rem;
  font-weight: 300;
  display: flex;
  align-items: center;
}

.alert .alert-text {
  line-height: 2;
}

.alert .alert-text p {
  font-size: 0.8125rem;
}

.alert .alert-icon {
  width: 30px;
  height: 30px;
  margin-right: 1rem;
  stroke-width: 1;
  flex-shrink: 0;
}

.alert .alert-close {
  width: 20px;
  height: 20px;
  margin-left: auto;
  cursor: pointer;
  opacity: 0.4;
}

.alert .alert-close:hover {
  opacity: 0.6;
}

.alert-bordered-primary {
  color: #253585;
  background-color: rgba(71, 101, 255, 0.2);
  border-color: #415deb;
}

.alert-bordered-primary hr {
  border-top-color: #2a49e9;
}

.alert-bordered-primary .alert-link {
  color: #1a255d;
}

.alert-bordered-secondary {
  color: #6e7178;
  background-color: rgba(211, 218, 230, 0.2);
  border-color: #c2c9d4;
}

.alert-bordered-secondary hr {
  border-top-color: #b3bcc9;
}

.alert-bordered-secondary .alert-link {
  color: #56585d;
}

.alert-bordered-success {
  color: #006a4a;
  background-color: rgba(0, 203, 142, 0.2);
  border-color: #00bb83;
}

.alert-bordered-success hr {
  border-top-color: #00a271;
}

.alert-bordered-success .alert-link {
  color: #003726;
}

.alert-bordered-info {
  color: #005885;
  background-color: rgba(0, 170, 255, 0.2);
  border-color: #009ceb;
}

.alert-bordered-info hr {
  border-top-color: #008bd2;
}

.alert-bordered-info .alert-link {
  color: #003652;
}

.alert-bordered-warning {
  color: #85662c;
  background-color: rgba(255, 197, 85, 0.2);
  border-color: #ebb54e;
}

.alert-bordered-warning hr {
  border-top-color: #e8ab37;
}

.alert-bordered-warning .alert-link {
  color: #5f491f;
}

.alert-bordered-danger {
  color: #852d35;
  background-color: rgba(255, 86, 102, 0.2);
  border-color: #eb4f5e;
}

.alert-bordered-danger hr {
  border-top-color: #e83849;
}

.alert-bordered-danger .alert-link {
  color: #5f2026;
}

.alert-bordered-light {
  color: #818283;
  background-color: rgba(249, 250, 251, 0.2);
  border-color: #e5e6e7;
}

.alert-bordered-light hr {
  border-top-color: #d8d9db;
}

.alert-bordered-light .alert-link {
  color: #686969;
}

.alert-bordered-dark {
  color: #13161b;
  background-color: rgba(36, 42, 52, 0.2);
  border-color: #212730;
}

.alert-bordered-dark hr {
  border-top-color: #171b21;
}

.alert-bordered-dark .alert-link {
  color: black;
}

.alert-primary {
  color: white;
  background-color: #ef3e27;
  border-color: #ef3e27;
}

.alert-primary hr {
  border-top-color: #2e50ff;
}

.alert-primary .alert-link {
  color: #e6e6e6;
}

.alert-outline-primary {
  color: #ef3e27;
  background-color: rgba(71, 101, 255, 0);
  border-color: #415deb;
}

.alert-outline-primary hr {
  border-top-color: #2a49e9;
}

.alert-outline-primary .alert-link {
  color: #14181d;
}

.alert-secondary {
  color: white;
  background-color: #d3dae6;
  border-color: #d3dae6;
}

.alert-secondary hr {
  border-top-color: #c3ccdd;
}

.alert-secondary .alert-link {
  color: #e6e6e6;
}

.alert-outline-secondary {
  color: #d3dae6;
  background-color: rgba(211, 218, 230, 0);
  border-color: #c2c9d4;
}

.alert-outline-secondary hr {
  border-top-color: #b3bcc9;
}

.alert-outline-secondary .alert-link {
  color: #b2bfd4;
}

.alert-success {
  color: white;
  background-color: #00cb8e;
  border-color: #00cb8e;
}

.alert-success hr {
  border-top-color: #00b27c;
}

.alert-success .alert-link {
  color: #e6e6e6;
}

.alert-outline-success {
  color: #00cb8e;
  background-color: rgba(0, 203, 142, 0);
  border-color: #00bb83;
}

.alert-outline-success hr {
  border-top-color: #00a271;
}

.alert-outline-success .alert-link {
  color: #00986a;
}

.alert-info {
  color: white;
  background-color: #00aaff;
  border-color: #00aaff;
}

.alert-info hr {
  border-top-color: #0099e6;
}

.alert-info .alert-link {
  color: #e6e6e6;
}

.alert-outline-info {
  color: #00aaff;
  background-color: rgba(0, 170, 255, 0);
  border-color: #009ceb;
}

.alert-outline-info hr {
  border-top-color: #008bd2;
}

.alert-outline-info .alert-link {
  color: #0088cc;
}

.alert-warning {
  color: white;
  background-color: #ffc555;
  border-color: #ffc555;
}

.alert-warning hr {
  border-top-color: #ffbc3c;
}

.alert-warning .alert-link {
  color: #e6e6e6;
}

.alert-outline-warning {
  color: #ffc555;
  background-color: rgba(255, 197, 85, 0);
  border-color: #ebb54e;
}

.alert-outline-warning hr {
  border-top-color: #e8ab37;
}

.alert-outline-warning .alert-link {
  color: #ffb422;
}

.alert-danger {
  color: white;
  background-color: #ef3e27;
  border-color: #ef3e27;
}

.alert-danger hr {
  border-top-color: #ff3d4f;
}

.alert-danger .alert-link {
  color: #e6e6e6;
}

.alert-outline-danger {
  color: #ef3e27;
  background-color: rgba(255, 86, 102, 0);
  border-color: #eb4f5e;
}

.alert-outline-danger hr {
  border-top-color: #e83849;
}

.alert-outline-danger .alert-link {
  color: #ff2338;
}

.alert-light {
  color: black;
  background-color: #f9fafb;
  border-color: #f9fafb;
}

.alert-light hr {
  border-top-color: #eaedf1;
}

.alert-light .alert-link {
  color: black;
}

.alert-outline-light {
  color: #4e6174;
  background-color: rgba(249, 250, 251, 0);
  border-color: #e5e6e7;
}

.alert-outline-light hr {
  border-top-color: #d8d9db;
}

.alert-outline-light .alert-link {
  color: #394856;
}

.alert-dark {
  color: white;
  background-color: #242a34;
  border-color: #242a34;
}

.alert-dark hr {
  border-top-color: #1a1e25;
}

.alert-dark .alert-link {
  color: #e6e6e6;
}

.alert-outline-dark {
  color: #242a34;
  background-color: rgba(36, 42, 52, 0);
  border-color: #212730;
}

.alert-outline-dark hr {
  border-top-color: #171b21;
}

.alert-outline-dark .alert-link {
  color: #0f1216;
}

/* Bootstrap Progress */
.progress {
  border-radius: 3px;
}

.progress .progress-bar {
  border-radius: 3px;
}

.progress .progress-bar.no-radius {
  border-radius: 0;
}

.progress.progress-sm {
  height: 0.375rem;
}

.progress.progress-md {
  height: 8px;
}

.progress.progress-lg {
  height: 15px;
}

.progress.progress-xl {
  height: 18px;
}

/* Breadcrumbs */
.breadcrumb {
  border: 1px solid #e6e4e9;
  background-color: #e6e4e9;
}

.breadcrumb.no-bg {
  background-color: transparent;
}

.breadcrumb .breadcrumb-item {
  font-size: 0.8125rem;
}

.breadcrumb .breadcrumb-item a {
  color: #ef3e27;
  text-decoration: none;
}

.breadcrumb .breadcrumb-item a:hover {
  opacity: 0.8;
}

.breadcrumb.breadcrumb-arrow {
  padding: 0 0;
  border-color: #dbe3e6;
}

.breadcrumb.breadcrumb-arrow .breadcrumb-item {
  font-size: 0.8125rem;
  background: #dbe3e6;
  padding: 0.56rem 10px;
  color: #000000;
  display: inline-block;
  vertical-align: top;
}

.breadcrumb.breadcrumb-arrow .breadcrumb-item:last-child {
  background: transparent;
}

.breadcrumb.breadcrumb-arrow .breadcrumb-item:before {
  content: '';
}

.breadcrumb.breadcrumb-arrow .breadcrumb-item a {
  position: relative;
  color: inherit;
  border: 1px solid #dbe3e6;
  display: inline-block;
  vertical-align: top;
}

.breadcrumb.breadcrumb-arrow .breadcrumb-item a:before,
.breadcrumb.breadcrumb-arrow .breadcrumb-item a:after {
  position: absolute;
  top: -9px;
  width: 0;
  height: 0;
  content: '';
  border-top: 21px solid transparent;
  border-bottom: 21px solid transparent;
}

.breadcrumb.breadcrumb-arrow .breadcrumb-item a:before {
  right: -22px;
  z-index: 3;
  border-left-color: #dbe3e6;
  border-left-style: solid;
  border-left-width: 12px;
}

.breadcrumb.breadcrumb-arrow .breadcrumb-item a:after {
  border-top: 21px solid transparent;
  border-bottom: 22px solid transparent;
  border-left: 12px solid #ffffff;
  top: -9px;
  right: -23px;
}

.breadcrumb.breadcrumb-arrow .breadcrumb-item span {
  display: inline-block;
  vertical-align: top;
}

.breadcrumb.breadcrumb-soft {
  padding: 0;
  border: none;
}

.breadcrumb.breadcrumb-soft .breadcrumb-item {
  line-height: 1rem;
  font-size: 0.8125rem;
  color: #9396a5;
  display: inline-block;
  vertical-align: top;
  font-weight: 500;
}

.breadcrumb.breadcrumb-soft .breadcrumb-item .breadcrumb-icon {
  width: 0.9375rem;
  height: 0.875rem;
}

.breadcrumb.breadcrumb-soft .breadcrumb-item:first-child:before {
  content: '';
}

.breadcrumb.breadcrumb-soft .breadcrumb-item:last-child {
  background: transparent;
}

.breadcrumb.breadcrumb-soft .breadcrumb-item:before {
  color: #9396a5;
  content: '•';
  font-weight: 700;
}

.breadcrumb.breadcrumb-soft .breadcrumb-item a {
  color: #9396a5;
}

.breadcrumb.breadcrumb-soft .breadcrumb-item a:hover {
  text-decoration: none;
  color: #ef3e27;
  transition: all, 0.3s;
}

@media (prefers-reduced-motion: reduce) {
  .breadcrumb.breadcrumb-soft .breadcrumb-item a:hover {
    transition: none;
  }
}

.breadcrumb.breadcrumb-soft .breadcrumb-item + .breadcrumb-item {
  padding-left: 0.3125rem;
}

.breadcrumb.breadcrumb-soft .breadcrumb-item + .breadcrumb-item:before {
  padding-right: 0.3125rem;
}

.breadcrumb.bg-success,
.breadcrumb.bg-dark,
.breadcrumb.bg-danger,
.breadcrumb.bg-warning,
.breadcrumb.bg-primary,
.breadcrumb.bg-info {
  border: none;
}

.breadcrumb.bg-success .breadcrumb-item,
.breadcrumb.bg-dark .breadcrumb-item,
.breadcrumb.bg-danger .breadcrumb-item,
.breadcrumb.bg-warning .breadcrumb-item,
.breadcrumb.bg-primary .breadcrumb-item,
.breadcrumb.bg-info .breadcrumb-item {
  color: #ffffff;
}

.breadcrumb.bg-success .breadcrumb-item:before,
.breadcrumb.bg-dark .breadcrumb-item:before,
.breadcrumb.bg-danger .breadcrumb-item:before,
.breadcrumb.bg-warning .breadcrumb-item:before,
.breadcrumb.bg-primary .breadcrumb-item:before,
.breadcrumb.bg-info .breadcrumb-item:before {
  color: inherit;
}

.breadcrumb.bg-success .breadcrumb-item a,
.breadcrumb.bg-success .breadcrumb-item span,
.breadcrumb.bg-dark .breadcrumb-item a,
.breadcrumb.bg-dark .breadcrumb-item span,
.breadcrumb.bg-danger .breadcrumb-item a,
.breadcrumb.bg-danger .breadcrumb-item span,
.breadcrumb.bg-warning .breadcrumb-item a,
.breadcrumb.bg-warning .breadcrumb-item span,
.breadcrumb.bg-primary .breadcrumb-item a,
.breadcrumb.bg-primary .breadcrumb-item span,
.breadcrumb.bg-info .breadcrumb-item a,
.breadcrumb.bg-info .breadcrumb-item span {
  color: inherit;
}

.bg-soft-breadcrumb-primary {
  background-color: #ececec;
  border-color: #ef3e27;
}

.bg-soft-breadcrumb-primary .breadcrumb-item {
  color: #ef3e27;
}

.bg-soft-breadcrumb-primary .breadcrumb-item:before {
  color: inherit;
}

.bg-soft-breadcrumb-primary .breadcrumb-item a {
  color: inherit;
}

.bg-soft-breadcrumb-primary.breadcrumb-arrow {
  background: transparent;
}

.bg-soft-breadcrumb-primary.breadcrumb-arrow .breadcrumb-item {
  background: #ececec;
}

.bg-soft-breadcrumb-primary.breadcrumb-arrow .breadcrumb-item a {
  color: #ef3e27;
  border: none;
}

.bg-soft-breadcrumb-primary.breadcrumb-arrow .breadcrumb-item a:before {
  border-left-color: #ececec;
}

.bg-soft-breadcrumb-primary.breadcrumb-arrow .breadcrumb-item span {
  color: #ef3e27;
}

.bg-soft-breadcrumb-primary.breadcrumb-arrow .breadcrumb-item:last-child {
  background: transparent;
}

.bg-soft-breadcrumb-secondary {
  background-color: #f6f8fa;
  border-color: #d3dae6;
}

.bg-soft-breadcrumb-secondary .breadcrumb-item {
  color: #d3dae6;
}

.bg-soft-breadcrumb-secondary .breadcrumb-item:before {
  color: inherit;
}

.bg-soft-breadcrumb-secondary .breadcrumb-item a {
  color: inherit;
}

.bg-soft-breadcrumb-secondary.breadcrumb-arrow {
  background: transparent;
}

.bg-soft-breadcrumb-secondary.breadcrumb-arrow .breadcrumb-item {
  background: #f6f8fa;
}

.bg-soft-breadcrumb-secondary.breadcrumb-arrow .breadcrumb-item a {
  color: #d3dae6;
  border: none;
}

.bg-soft-breadcrumb-secondary.breadcrumb-arrow .breadcrumb-item a:before {
  border-left-color: #f6f8fa;
}

.bg-soft-breadcrumb-secondary.breadcrumb-arrow .breadcrumb-item span {
  color: #d3dae6;
}

.bg-soft-breadcrumb-secondary.breadcrumb-arrow .breadcrumb-item:last-child {
  background: transparent;
}

.bg-soft-breadcrumb-success {
  background-color: #ccf5e8;
  border-color: #00cb8e;
}

.bg-soft-breadcrumb-success .breadcrumb-item {
  color: #00cb8e;
}

.bg-soft-breadcrumb-success .breadcrumb-item:before {
  color: inherit;
}

.bg-soft-breadcrumb-success .breadcrumb-item a {
  color: inherit;
}

.bg-soft-breadcrumb-success.breadcrumb-arrow {
  background: transparent;
}

.bg-soft-breadcrumb-success.breadcrumb-arrow .breadcrumb-item {
  background: #ccf5e8;
}

.bg-soft-breadcrumb-success.breadcrumb-arrow .breadcrumb-item a {
  color: #00cb8e;
  border: none;
}

.bg-soft-breadcrumb-success.breadcrumb-arrow .breadcrumb-item a:before {
  border-left-color: #ccf5e8;
}

.bg-soft-breadcrumb-success.breadcrumb-arrow .breadcrumb-item span {
  color: #00cb8e;
}

.bg-soft-breadcrumb-success.breadcrumb-arrow .breadcrumb-item:last-child {
  background: transparent;
}

.bg-soft-breadcrumb-info {
  background-color: #cceeff;
  border-color: #00aaff;
}

.bg-soft-breadcrumb-info .breadcrumb-item {
  color: #00aaff;
}

.bg-soft-breadcrumb-info .breadcrumb-item:before {
  color: inherit;
}

.bg-soft-breadcrumb-info .breadcrumb-item a {
  color: inherit;
}

.bg-soft-breadcrumb-info.breadcrumb-arrow {
  background: transparent;
}

.bg-soft-breadcrumb-info.breadcrumb-arrow .breadcrumb-item {
  background: #cceeff;
}

.bg-soft-breadcrumb-info.breadcrumb-arrow .breadcrumb-item a {
  color: #00aaff;
  border: none;
}

.bg-soft-breadcrumb-info.breadcrumb-arrow .breadcrumb-item a:before {
  border-left-color: #cceeff;
}

.bg-soft-breadcrumb-info.breadcrumb-arrow .breadcrumb-item span {
  color: #00aaff;
}

.bg-soft-breadcrumb-info.breadcrumb-arrow .breadcrumb-item:last-child {
  background: transparent;
}

.bg-soft-breadcrumb-warning {
  background-color: #fff3dd;
  border-color: #ffc555;
}

.bg-soft-breadcrumb-warning .breadcrumb-item {
  color: #ffc555;
}

.bg-soft-breadcrumb-warning .breadcrumb-item:before {
  color: inherit;
}

.bg-soft-breadcrumb-warning .breadcrumb-item a {
  color: inherit;
}

.bg-soft-breadcrumb-warning.breadcrumb-arrow {
  background: transparent;
}

.bg-soft-breadcrumb-warning.breadcrumb-arrow .breadcrumb-item {
  background: #fff3dd;
}

.bg-soft-breadcrumb-warning.breadcrumb-arrow .breadcrumb-item a {
  color: #ffc555;
  border: none;
}

.bg-soft-breadcrumb-warning.breadcrumb-arrow .breadcrumb-item a:before {
  border-left-color: #fff3dd;
}

.bg-soft-breadcrumb-warning.breadcrumb-arrow .breadcrumb-item span {
  color: #ffc555;
}

.bg-soft-breadcrumb-warning.breadcrumb-arrow .breadcrumb-item:last-child {
  background: transparent;
}

.bg-soft-breadcrumb-danger {
  background-color: #ffdde0;
  border-color: #ef3e27;
}

.bg-soft-breadcrumb-danger .breadcrumb-item {
  color: #ef3e27;
}

.bg-soft-breadcrumb-danger .breadcrumb-item:before {
  color: inherit;
}

.bg-soft-breadcrumb-danger .breadcrumb-item a {
  color: inherit;
}

.bg-soft-breadcrumb-danger.breadcrumb-arrow {
  background: transparent;
}

.bg-soft-breadcrumb-danger.breadcrumb-arrow .breadcrumb-item {
  background: #ffdde0;
}

.bg-soft-breadcrumb-danger.breadcrumb-arrow .breadcrumb-item a {
  color: #ef3e27;
  border: none;
}

.bg-soft-breadcrumb-danger.breadcrumb-arrow .breadcrumb-item a:before {
  border-left-color: #ffdde0;
}

.bg-soft-breadcrumb-danger.breadcrumb-arrow .breadcrumb-item span {
  color: #ef3e27;
}

.bg-soft-breadcrumb-danger.breadcrumb-arrow .breadcrumb-item:last-child {
  background: transparent;
}

.bg-soft-breadcrumb-light {
  background-color: #fefefe;
  border-color: #f9fafb;
}

.bg-soft-breadcrumb-light .breadcrumb-item {
  color: #f9fafb;
}

.bg-soft-breadcrumb-light .breadcrumb-item:before {
  color: inherit;
}

.bg-soft-breadcrumb-light .breadcrumb-item a {
  color: inherit;
}

.bg-soft-breadcrumb-light.breadcrumb-arrow {
  background: transparent;
}

.bg-soft-breadcrumb-light.breadcrumb-arrow .breadcrumb-item {
  background: #fefefe;
}

.bg-soft-breadcrumb-light.breadcrumb-arrow .breadcrumb-item a {
  color: #f9fafb;
  border: none;
}

.bg-soft-breadcrumb-light.breadcrumb-arrow .breadcrumb-item a:before {
  border-left-color: #fefefe;
}

.bg-soft-breadcrumb-light.breadcrumb-arrow .breadcrumb-item span {
  color: #f9fafb;
}

.bg-soft-breadcrumb-light.breadcrumb-arrow .breadcrumb-item:last-child {
  background: transparent;
}

.bg-soft-breadcrumb-dark {
  background-color: #d3d4d6;
  border-color: #242a34;
}

.bg-soft-breadcrumb-dark .breadcrumb-item {
  color: #242a34;
}

.bg-soft-breadcrumb-dark .breadcrumb-item:before {
  color: inherit;
}

.bg-soft-breadcrumb-dark .breadcrumb-item a {
  color: inherit;
}

.bg-soft-breadcrumb-dark.breadcrumb-arrow {
  background: transparent;
}

.bg-soft-breadcrumb-dark.breadcrumb-arrow .breadcrumb-item {
  background: #d3d4d6;
}

.bg-soft-breadcrumb-dark.breadcrumb-arrow .breadcrumb-item a {
  color: #242a34;
  border: none;
}

.bg-soft-breadcrumb-dark.breadcrumb-arrow .breadcrumb-item a:before {
  border-left-color: #d3d4d6;
}

.bg-soft-breadcrumb-dark.breadcrumb-arrow .breadcrumb-item span {
  color: #242a34;
}

.bg-soft-breadcrumb-dark.breadcrumb-arrow .breadcrumb-item:last-child {
  background: transparent;
}

/* Buttons */
.btn,
.wizard > .actions a,
.fc button {
  font-size: 0.8125rem;
  line-height: 1.5;
  font-weight: 700;
  /* Buttons with only icons */
  /* Buttons with icon and text */
}

.btn i,
.wizard > .actions a i,
.fc button i {
  font-size: 1rem;
}

.btn svg,
.wizard > .actions a svg,
.fc button svg {
  width: 1rem;
  height: 1rem;
}

.btn.btn-rounded,
.wizard > .actions a.btn-rounded,
.fc button.btn-rounded {
  border-radius: 50px;
}

.btn.btn-sm,
.btn-group-sm > .btn,
.wizard > .actions .btn-group-sm > a,
.fc .btn-group-sm > button,
.wizard > .actions a.btn-sm,
.fc button {
  font-size: 0.71875rem;
}

.btn.btn-lg,
.btn-group-lg > .btn,
.wizard > .actions .btn-group-lg > a,
.fc .btn-group-lg > button,
.wizard > .actions a.btn-lg,
.fc button.btn-lg {
  font-size: 0.96875rem;
}

.btn.btn-icon,
.wizard > .actions a.btn-icon,
.fc button.btn-icon {
  width: 35px;
  height: 35px;
  padding: 0;
}

.btn.btn-icon-text .btn-icon-prepend,
.wizard > .actions a.btn-icon-text .btn-icon-prepend,
.fc button.btn-icon-text .btn-icon-prepend {
  margin-right: 0.5rem;
}

.btn.btn-icon-text .btn-icon-append,
.wizard > .actions a.btn-icon-text .btn-icon-append,
.fc button.btn-icon-text .btn-icon-append {
  margin-left: 0.5rem;
}

.btn.btn-social-icon,
.wizard > .actions a.btn-social-icon,
.fc button.btn-social-icon {
  width: 50px;
  height: 50px;
  padding: 0;
}

.btn-group .btn + .btn,
.fc .fc-button-group .btn + .btn,
.btn-group .wizard > .actions a + .btn,
.wizard > .actions .btn-group a + .btn,
.fc .fc-button-group .wizard > .actions a + .btn,
.wizard > .actions .fc .fc-button-group a + .btn,
.btn-group .fc button + .btn,
.fc .btn-group button + .btn,
.fc .fc-button-group button + .btn,
.btn-group .wizard > .actions .btn + a,
.wizard > .actions .btn-group .btn + a,
.fc .fc-button-group .wizard > .actions .btn + a,
.wizard > .actions .fc .fc-button-group .btn + a,
.btn-group .wizard > .actions a + a,
.wizard > .actions .btn-group a + a,
.fc .fc-button-group .wizard > .actions a + a,
.wizard > .actions .fc .fc-button-group a + a,
.btn-group .fc .wizard > .actions button + a,
.wizard > .actions .btn-group .fc button + a,
.fc .btn-group .wizard > .actions button + a,
.wizard > .actions .fc .btn-group button + a,
.fc .fc-button-group .wizard > .actions button + a,
.wizard > .actions .fc .fc-button-group button + a,
.btn-group .fc .btn + button,
.fc .btn-group .btn + button,
.fc .fc-button-group .btn + button,
.btn-group .wizard > .actions .fc a + button,
.fc .btn-group .wizard > .actions a + button,
.wizard > .actions .btn-group .fc a + button,
.fc .wizard > .actions .btn-group a + button,
.fc .fc-button-group .wizard > .actions a + button,
.wizard > .actions .fc .fc-button-group a + button,
.btn-group .fc button + button,
.fc .btn-group button + button,
.fc .fc-button-group button + button {
  border-left: 0;
}

/*social buttons*/
.btn-twitter {
  background: #2caae1;
  color: #ffffff;
}

.btn-twitter:hover,
.btn-twitter:focus {
  background: #1b8dbf;
  color: #ffffff;
}

.btn-twitter.btn-social-icon-text {
  padding: 0 1.5rem 0 0;
  background: #59bce7;
}

.btn-twitter.btn-social-icon-text i {
  background: #2caae1;
  padding: 0.75rem;
  display: inline-block;
  margin-right: 1.5rem;
}

.btn-outline-twitter {
  border: 1px solid #2caae1;
  background: transparent;
  color: #2caae1;
}

.btn-outline-twitter:hover {
  background: #2caae1;
  color: #ffffff;
}

.btn-facebook {
  background: #3b579d;
  color: #ffffff;
}

.btn-facebook:hover,
.btn-facebook:focus {
  background: #2d4278;
  color: #ffffff;
}

.btn-facebook.btn-social-icon-text {
  padding: 0 1.5rem 0 0;
  background: #4e6ebd;
}

.btn-facebook.btn-social-icon-text i {
  background: #3b579d;
  padding: 0.75rem;
  display: inline-block;
  margin-right: 1.5rem;
}

.btn-outline-facebook {
  border: 1px solid #3b579d;
  background: transparent;
  color: #3b579d;
}

.btn-outline-facebook:hover {
  background: #3b579d;
  color: #ffffff;
}

.btn-google {
  background: #dc4a38;
  color: #ffffff;
}

.btn-google:hover,
.btn-google:focus {
  background: #bf3322;
  color: #ffffff;
}

.btn-google.btn-social-icon-text {
  padding: 0 1.5rem 0 0;
  background: #e47163;
}

.btn-google.btn-social-icon-text i {
  background: #dc4a38;
  padding: 0.75rem;
  display: inline-block;
  margin-right: 1.5rem;
}

.btn-outline-google {
  border: 1px solid #dc4a38;
  background: transparent;
  color: #dc4a38;
}

.btn-outline-google:hover {
  background: #dc4a38;
  color: #ffffff;
}

.btn-linkedin {
  background: #0177b5;
  color: #ffffff;
}

.btn-linkedin:hover,
.btn-linkedin:focus {
  background: #015682;
  color: #ffffff;
}

.btn-linkedin.btn-social-icon-text {
  padding: 0 1.5rem 0 0;
  background: #0198e8;
}

.btn-linkedin.btn-social-icon-text i {
  background: #0177b5;
  padding: 0.75rem;
  display: inline-block;
  margin-right: 1.5rem;
}

.btn-outline-linkedin {
  border: 1px solid #0177b5;
  background: transparent;
  color: #0177b5;
}

.btn-outline-linkedin:hover {
  background: #0177b5;
  color: #ffffff;
}

.btn-instagram {
  background: #e4405f;
  color: #ffffff;
}

.btn-instagram:hover,
.btn-instagram:focus {
  background: #d31e40;
  color: #ffffff;
}

.btn-instagram.btn-social-icon-text {
  padding: 0 1.5rem 0 0;
  background: #ea6d84;
}

.btn-instagram.btn-social-icon-text i {
  background: #e4405f;
  padding: 0.75rem;
  display: inline-block;
  margin-right: 1.5rem;
}

.btn-outline-instagram {
  border: 1px solid #e4405f;
  background: transparent;
  color: #e4405f;
}

.btn-outline-instagram:hover {
  background: #e4405f;
  color: #ffffff;
}

.btn-pinterest {
  background: #cc2127;
  color: #ffffff;
}

.btn-pinterest:hover,
.btn-pinterest:focus {
  background: #a01a1f;
  color: #ffffff;
}

.btn-pinterest.btn-social-icon-text {
  padding: 0 1.5rem 0 0;
  background: #e04046;
}

.btn-pinterest.btn-social-icon-text i {
  background: #cc2127;
  padding: 0.75rem;
  display: inline-block;
  margin-right: 1.5rem;
}

.btn-outline-pinterest {
  border: 1px solid #cc2127;
  background: transparent;
  color: #cc2127;
}

.btn-outline-pinterest:hover {
  background: #cc2127;
  color: #ffffff;
}

.btn-youtube {
  background: #e52d27;
  color: #ffffff;
}

.btn-youtube:hover,
.btn-youtube:focus {
  background: #c21d17;
  color: #ffffff;
}

.btn-youtube.btn-social-icon-text {
  padding: 0 1.5rem 0 0;
  background: #ea5955;
}

.btn-youtube.btn-social-icon-text i {
  background: #e52d27;
  padding: 0.75rem;
  display: inline-block;
  margin-right: 1.5rem;
}

.btn-outline-youtube {
  border: 1px solid #e52d27;
  background: transparent;
  color: #e52d27;
}

.btn-outline-youtube:hover {
  background: #e52d27;
  color: #ffffff;
}

.btn-github {
  background: #333333;
  color: #ffffff;
}

.btn-github:hover,
.btn-github:focus {
  background: #1a1a1a;
  color: #ffffff;
}

.btn-github.btn-social-icon-text {
  padding: 0 1.5rem 0 0;
  background: #4d4d4d;
}

.btn-github.btn-social-icon-text i {
  background: #333333;
  padding: 0.75rem;
  display: inline-block;
  margin-right: 1.5rem;
}

.btn-outline-github {
  border: 1px solid #333333;
  background: transparent;
  color: #333333;
}

.btn-outline-github:hover {
  background: #333333;
  color: #ffffff;
}

.btn-behance {
  background: #1769ff;
  color: #ffffff;
}

.btn-behance:hover,
.btn-behance:focus {
  background: #0050e3;
  color: #ffffff;
}

.btn-behance.btn-social-icon-text {
  padding: 0 1.5rem 0 0;
  background: #4a8aff;
}

.btn-behance.btn-social-icon-text i {
  background: #1769ff;
  padding: 0.75rem;
  display: inline-block;
  margin-right: 1.5rem;
}

.btn-outline-behance {
  border: 1px solid #1769ff;
  background: transparent;
  color: #1769ff;
}

.btn-outline-behance:hover {
  background: #1769ff;
  color: #ffffff;
}

.btn-dribbble {
  background: #ea4c89;
  color: #ffffff;
}

.btn-dribbble:hover,
.btn-dribbble:focus {
  background: #e51e6b;
  color: #ffffff;
}

.btn-dribbble.btn-social-icon-text {
  padding: 0 1.5rem 0 0;
  background: #ef7aa7;
}

.btn-dribbble.btn-social-icon-text i {
  background: #ea4c89;
  padding: 0.75rem;
  display: inline-block;
  margin-right: 1.5rem;
}

.btn-outline-dribbble {
  border: 1px solid #ea4c89;
  background: transparent;
  color: #ea4c89;
}

.btn-outline-dribbble:hover {
  background: #ea4c89;
  color: #ffffff;
}

.btn-reddit {
  background: #ff4500;
  color: #ffffff;
}

.btn-reddit:hover,
.btn-reddit:focus {
  background: #cc3700;
  color: #ffffff;
}

.btn-reddit.btn-social-icon-text {
  padding: 0 1.5rem 0 0;
  background: #ff6a33;
}

.btn-reddit.btn-social-icon-text i {
  background: #ff4500;
  padding: 0.75rem;
  display: inline-block;
  margin-right: 1.5rem;
}

.btn-outline-reddit {
  border: 1px solid #ff4500;
  background: transparent;
  color: #ff4500;
}

.btn-outline-reddit:hover {
  background: #ff4500;
  color: #ffffff;
}

/* Soft buttons */
.btn-soft-primary {
  background-color: #ececec;
  background-image: none;
  border-color: rgba(71, 101, 255, 0);
}

.btn-soft-primary:not(.btn-soft-light) {
  color: #ef3e27;
}

.btn-soft-primary:hover {
  color: #ffffff;
  background-color: #ef3e27;
  border-color: #ef3e27;
}

.btn-soft-primary.focus,
.btn-soft-primary:focus {
  box-shadow: 0 0 0 3px rgba(71, 101, 255, 0.5);
}

.btn-soft-primary.disabled,
.btn-soft-primary:disabled {
  color: #ef3e27;
  background-color: transparent;
}

.btn-soft-primary.active,
.btn-soft-primary:active,
.show > .btn-soft-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #ef3e27;
  border-color: #ef3e27;
}

.btn-flash-primary {
  padding: 5px;
  background-image: none;
  border: none;
  color: #586dd9;
}

.btn-flash-primary:not(.btn-flash-light) {
  color: #8d8a89;
}

.btn-flash-primary[aria-expanded='true'] {
  background-color: #ececec;
  color: #ef3e27;
}

.btn-flash-primary:hover {
  background-color: #ececec;
  color: #070a1a;
  border: none;
}

.btn-flash-primary:hover:not(.btn-flash-light) {
  color: #ef3e27;
}

.btn-flash-primary.focus,
.btn-flash-primary:focus {
  box-shadow: none;
}

.btn-flash-primary.disabled,
.btn-flash-primary:disabled {
  color: #ef3e27;
  background-color: transparent;
}

.btn-flash-primary.active,
.btn-flash-primary:active,
.show > .btn-flash-primary.dropdown-toggle {
  color: #ef3e27;
  background-color: #ececec;
  border: none;
}

.btn-flash-border-primary {
  background-image: none;
  border: none;
  color: #586dd9;
  border: 1px solid #586dd9;
}

.btn-flash-border-primary:not(.btn-flash-border-light) {
  color: #8d8a89;
  border: 1px solid #8d8a89;
}

.btn-flash-border-primary[aria-expanded='true'] {
  background-color: #ececec;
  border: 1px solid #ef3e27;
  color: #ef3e27;
}

.btn-flash-border-primary:hover {
  background-color: #ececec;
  color: #070a1a;
  border: 1px solid transparent;
}

.btn-flash-border-primary:hover:not(.btn-flash-border-light) {
  color: #ef3e27;
}

.btn-flash-border-primary.focus,
.btn-flash-border-primary:focus {
  box-shadow: none;
  border: 1px solid transparent;
}

.btn-flash-border-primary.disabled,
.btn-flash-border-primary:disabled {
  color: #ef3e27;
  background-color: transparent;
  border: 1px solid transparent;
}

.btn-flash-border-primary.active,
.btn-flash-border-primary:active,
.show > .btn-flash-border-primary.dropdown-toggle {
  color: #ef3e27;
  background-color: #ececec;
  border: 1px solid transparent;
}

.btn-primary,
.wizard > .actions a {
  color: #ffffff;
  background-color: #ef3e27;
  border-color: #ef3e27;
}

.btn-primary:hover,
.wizard > .actions a:hover {
  color: #ffffff;
  background-color: #14181d;
  border-color: #14181d;
}

.btn-primary:focus,
.wizard > .actions a:focus,
.btn-primary.focus,
.wizard > .actions a.focus {
  box-shadow: 0 0 0 0.2rem rgba(99, 124, 255, 0.5);
}

.btn-primary.disabled,
.wizard > .actions a.disabled,
.btn-primary:disabled,
.wizard > .actions a:disabled {
  color: #ffffff;
  background-color: #ef3e27;
  border-color: #ef3e27;
}

.btn-primary:not(:disabled):not(.disabled):active,
.wizard > .actions a:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.wizard > .actions a:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle,
.wizard > .actions .show > a.dropdown-toggle {
  color: #ffffff;
  background-color: #14181d;
  border-color: #0730ff;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.wizard > .actions a:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.wizard > .actions a:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus,
.wizard > .actions .show > a.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(99, 124, 255, 0.5);
}

.btn-shadow-primary {
  background-image: none;
  border: none;
  color: #a09fa7;
  box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -webkit-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -moz-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -ms-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
}

.btn-shadow-primary:not(.btn-flash-border-light) {
  color: #a09fa7;
  border: 1px solid transparent;
}

.btn-shadow-primary[aria-expanded='true'] {
  background-color: #ffffff;
  border: 1px solid transparent;
  color: #a09fa7;
}

.btn-shadow-primary:hover {
  background-color: #ffffff;
  color: #ef3e27;
  border: 1px solid transparent;
  box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -webkit-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -moz-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -ms-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
}

.btn-shadow-primary:hover:not(.btn-flash-border-light) {
  color: #ef3e27;
}

.btn-shadow-primary.focus,
.btn-shadow-primary:focus {
  border: 1px solid transparent;
  box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -webkit-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -moz-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -ms-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
}

.btn-shadow-primary.disabled,
.btn-shadow-primary:disabled {
  color: #ef3e27;
  background-color: transparent;
  border: 1px solid transparent;
}

.btn-shadow-primary.active,
.btn-shadow-primary:active,
.show > .btn-shadow-primary.dropdown-toggle {
  color: #ef3e27;
  background-color: #ffffff;
  border: 1px solid transparent;
  box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -webkit-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -moz-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -ms-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
}

.btn-soft-secondary {
  background-color: #f6f8fa;
  background-image: none;
  border-color: rgba(211, 218, 230, 0);
}

.btn-soft-secondary:not(.btn-soft-light) {
  color: #d3dae6;
}

.btn-soft-secondary:hover {
  color: #ffffff;
  background-color: #d3dae6;
  border-color: #d3dae6;
}

.btn-soft-secondary.focus,
.btn-soft-secondary:focus {
  box-shadow: 0 0 0 3px rgba(211, 218, 230, 0.5);
}

.btn-soft-secondary.disabled,
.btn-soft-secondary:disabled {
  color: #d3dae6;
  background-color: transparent;
}

.btn-soft-secondary.active,
.btn-soft-secondary:active,
.show > .btn-soft-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: #d3dae6;
  border-color: #d3dae6;
}

.btn-flash-secondary {
  padding: 5px;
  background-image: none;
  border: none;
  color: #babfc7;
}

.btn-flash-secondary:not(.btn-flash-light) {
  color: #b9bac3;
}

.btn-flash-secondary[aria-expanded='true'] {
  background-color: #fbfbfd;
  color: #d3dae6;
}

.btn-flash-secondary:hover {
  background-color: #fbfbfd;
  color: #151617;
  border: none;
}

.btn-flash-secondary:hover:not(.btn-flash-light) {
  color: #d3dae6;
}

.btn-flash-secondary.focus,
.btn-flash-secondary:focus {
  box-shadow: none;
}

.btn-flash-secondary.disabled,
.btn-flash-secondary:disabled {
  color: #d3dae6;
  background-color: transparent;
}

.btn-flash-secondary.active,
.btn-flash-secondary:active,
.show > .btn-flash-secondary.dropdown-toggle {
  color: #d3dae6;
  background-color: #fbfbfd;
  border: none;
}

.btn-flash-border-secondary {
  background-image: none;
  border: none;
  color: #babfc7;
  border: 1px solid #babfc7;
}

.btn-flash-border-secondary:not(.btn-flash-border-light) {
  color: #b9bac3;
  border: 1px solid #b9bac3;
}

.btn-flash-border-secondary[aria-expanded='true'] {
  background-color: #fbfbfd;
  border: 1px solid #d3dae6;
  color: #d3dae6;
}

.btn-flash-border-secondary:hover {
  background-color: #fbfbfd;
  color: #151617;
  border: 1px solid transparent;
}

.btn-flash-border-secondary:hover:not(.btn-flash-border-light) {
  color: #d3dae6;
}

.btn-flash-border-secondary.focus,
.btn-flash-border-secondary:focus {
  box-shadow: none;
  border: 1px solid transparent;
}

.btn-flash-border-secondary.disabled,
.btn-flash-border-secondary:disabled {
  color: #d3dae6;
  background-color: transparent;
  border: 1px solid transparent;
}

.btn-flash-border-secondary.active,
.btn-flash-border-secondary:active,
.show > .btn-flash-border-secondary.dropdown-toggle {
  color: #d3dae6;
  background-color: #fbfbfd;
  border: 1px solid transparent;
}

.btn-secondary,
.daterangepicker .drp-buttons .btn.cancelBtn,
.daterangepicker .drp-buttons .wizard > .actions a.cancelBtn,
.wizard > .actions .daterangepicker .drp-buttons a.cancelBtn,
.daterangepicker .drp-buttons .fc button.cancelBtn,
.fc .daterangepicker .drp-buttons button.cancelBtn,
.wizard > .actions .disabled a {
  color: #212529;
  background-color: #d3dae6;
  border-color: #d3dae6;
}

.btn-secondary:hover,
.daterangepicker .drp-buttons .btn.cancelBtn:hover,
.daterangepicker .drp-buttons .wizard > .actions a.cancelBtn:hover,
.wizard > .actions .daterangepicker .drp-buttons a.cancelBtn:hover,
.daterangepicker .drp-buttons .fc button.cancelBtn:hover,
.fc .daterangepicker .drp-buttons button.cancelBtn:hover,
.wizard > .actions .disabled a:hover {
  color: #212529;
  background-color: #bbc5d8;
  border-color: #b2bfd4;
}

.btn-secondary:focus,
.daterangepicker .drp-buttons .btn.cancelBtn:focus,
.daterangepicker .drp-buttons .wizard > .actions a.cancelBtn:focus,
.wizard > .actions .daterangepicker .drp-buttons a.cancelBtn:focus,
.daterangepicker .drp-buttons .fc button.cancelBtn:focus,
.fc .daterangepicker .drp-buttons button.cancelBtn:focus,
.wizard > .actions .disabled a:focus,
.btn-secondary.focus,
.daterangepicker .drp-buttons .focus.btn.cancelBtn,
.daterangepicker .drp-buttons .wizard > .actions a.focus.cancelBtn,
.wizard > .actions .daterangepicker .drp-buttons a.focus.cancelBtn,
.daterangepicker .drp-buttons .fc button.focus.cancelBtn,
.fc .daterangepicker .drp-buttons button.focus.cancelBtn,
.wizard > .actions .disabled a.focus {
  box-shadow: 0 0 0 0.2rem rgba(184, 191, 202, 0.5);
}

.btn-secondary.disabled,
.daterangepicker .drp-buttons .disabled.btn.cancelBtn,
.daterangepicker .drp-buttons .wizard > .actions a.disabled.cancelBtn,
.wizard > .actions .daterangepicker .drp-buttons a.disabled.cancelBtn,
.daterangepicker .drp-buttons .fc button.disabled.cancelBtn,
.fc .daterangepicker .drp-buttons button.disabled.cancelBtn,
.wizard > .actions .disabled a.disabled,
.btn-secondary:disabled,
.daterangepicker .drp-buttons .btn.cancelBtn:disabled,
.daterangepicker .drp-buttons .wizard > .actions a.cancelBtn:disabled,
.wizard > .actions .daterangepicker .drp-buttons a.cancelBtn:disabled,
.daterangepicker .drp-buttons .fc button.cancelBtn:disabled,
.fc .daterangepicker .drp-buttons button.cancelBtn:disabled,
.wizard > .actions .disabled a:disabled {
  color: #212529;
  background-color: #d3dae6;
  border-color: #d3dae6;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.daterangepicker
  .drp-buttons
  .btn.cancelBtn:not(:disabled):not(.disabled):active,
.daterangepicker
  .drp-buttons
  .wizard
  > .actions
  a.cancelBtn:not(:disabled):not(.disabled):active,
.wizard
  > .actions
  .daterangepicker
  .drp-buttons
  a.cancelBtn:not(:disabled):not(.disabled):active,
.daterangepicker
  .drp-buttons
  .fc
  button.cancelBtn:not(:disabled):not(.disabled):active,
.fc
  .daterangepicker
  .drp-buttons
  button.cancelBtn:not(:disabled):not(.disabled):active,
.wizard > .actions .disabled a:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.daterangepicker
  .drp-buttons
  .btn.cancelBtn:not(:disabled):not(.disabled).active,
.daterangepicker
  .drp-buttons
  .wizard
  > .actions
  a.cancelBtn:not(:disabled):not(.disabled).active,
.wizard
  > .actions
  .daterangepicker
  .drp-buttons
  a.cancelBtn:not(:disabled):not(.disabled).active,
.daterangepicker
  .drp-buttons
  .fc
  button.cancelBtn:not(:disabled):not(.disabled).active,
.fc
  .daterangepicker
  .drp-buttons
  button.cancelBtn:not(:disabled):not(.disabled).active,
.wizard > .actions .disabled a:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle,
.daterangepicker .drp-buttons .show > .dropdown-toggle.btn.cancelBtn,
.daterangepicker
  .drp-buttons
  .wizard
  > .actions
  .show
  > a.dropdown-toggle.cancelBtn,
.wizard
  > .actions
  .daterangepicker
  .drp-buttons
  .show
  > a.dropdown-toggle.cancelBtn,
.daterangepicker .drp-buttons .fc .show > button.dropdown-toggle.cancelBtn,
.fc .daterangepicker .drp-buttons .show > button.dropdown-toggle.cancelBtn,
.wizard > .actions .disabled .show > a.dropdown-toggle {
  color: #212529;
  background-color: #b2bfd4;
  border-color: #aab8cf;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.daterangepicker
  .drp-buttons
  .btn.cancelBtn:not(:disabled):not(.disabled):active:focus,
.daterangepicker
  .drp-buttons
  .wizard
  > .actions
  a.cancelBtn:not(:disabled):not(.disabled):active:focus,
.wizard
  > .actions
  .daterangepicker
  .drp-buttons
  a.cancelBtn:not(:disabled):not(.disabled):active:focus,
.daterangepicker
  .drp-buttons
  .fc
  button.cancelBtn:not(:disabled):not(.disabled):active:focus,
.fc
  .daterangepicker
  .drp-buttons
  button.cancelBtn:not(:disabled):not(.disabled):active:focus,
.wizard > .actions .disabled a:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.daterangepicker
  .drp-buttons
  .btn.cancelBtn:not(:disabled):not(.disabled).active:focus,
.daterangepicker
  .drp-buttons
  .wizard
  > .actions
  a.cancelBtn:not(:disabled):not(.disabled).active:focus,
.wizard
  > .actions
  .daterangepicker
  .drp-buttons
  a.cancelBtn:not(:disabled):not(.disabled).active:focus,
.daterangepicker
  .drp-buttons
  .fc
  button.cancelBtn:not(:disabled):not(.disabled).active:focus,
.fc
  .daterangepicker
  .drp-buttons
  button.cancelBtn:not(:disabled):not(.disabled).active:focus,
.wizard > .actions .disabled a:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus,
.daterangepicker .drp-buttons .show > .dropdown-toggle.btn.cancelBtn:focus,
.daterangepicker
  .drp-buttons
  .wizard
  > .actions
  .show
  > a.dropdown-toggle.cancelBtn:focus,
.wizard
  > .actions
  .daterangepicker
  .drp-buttons
  .show
  > a.dropdown-toggle.cancelBtn:focus,
.daterangepicker
  .drp-buttons
  .fc
  .show
  > button.dropdown-toggle.cancelBtn:focus,
.fc
  .daterangepicker
  .drp-buttons
  .show
  > button.dropdown-toggle.cancelBtn:focus,
.wizard > .actions .disabled .show > a.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(184, 191, 202, 0.5);
}

.btn-shadow-secondary {
  background-image: none;
  border: none;
  color: #a09fa7;
  box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -webkit-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -moz-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -ms-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
}

.btn-shadow-secondary:not(.btn-flash-border-light) {
  color: #a09fa7;
  border: 1px solid transparent;
}

.btn-shadow-secondary[aria-expanded='true'] {
  background-color: #ffffff;
  border: 1px solid transparent;
  color: #a09fa7;
}

.btn-shadow-secondary:hover {
  background-color: #ffffff;
  color: #d3dae6;
  border: 1px solid transparent;
  box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -webkit-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -moz-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -ms-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
}

.btn-shadow-secondary:hover:not(.btn-flash-border-light) {
  color: #d3dae6;
}

.btn-shadow-secondary.focus,
.btn-shadow-secondary:focus {
  border: 1px solid transparent;
  box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -webkit-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -moz-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -ms-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
}

.btn-shadow-secondary.disabled,
.btn-shadow-secondary:disabled {
  color: #d3dae6;
  background-color: transparent;
  border: 1px solid transparent;
}

.btn-shadow-secondary.active,
.btn-shadow-secondary:active,
.show > .btn-shadow-secondary.dropdown-toggle {
  color: #d3dae6;
  background-color: #ffffff;
  border: 1px solid transparent;
  box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -webkit-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -moz-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -ms-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
}

.btn-soft-success {
  background-color: #ccf5e8;
  background-image: none;
  border-color: rgba(0, 203, 142, 0);
}

.btn-soft-success:not(.btn-soft-light) {
  color: #00cb8e;
}

.btn-soft-success:hover {
  color: #ffffff;
  background-color: #00cb8e;
  border-color: #00cb8e;
}

.btn-soft-success.focus,
.btn-soft-success:focus {
  box-shadow: 0 0 0 3px rgba(0, 203, 142, 0.5);
}

.btn-soft-success.disabled,
.btn-soft-success:disabled {
  color: #00cb8e;
  background-color: transparent;
}

.btn-soft-success.active,
.btn-soft-success:active,
.show > .btn-soft-success.dropdown-toggle {
  color: #ffffff;
  background-color: #00cb8e;
  border-color: #00cb8e;
}

.btn-flash-success {
  padding: 5px;
  background-image: none;
  border: none;
  color: #26b58a;
}

.btn-flash-success:not(.btn-flash-light) {
  color: #84b6ad;
}

.btn-flash-success[aria-expanded='true'] {
  background-color: #e6faf4;
  color: #00cb8e;
}

.btn-flash-success:hover {
  background-color: #e6faf4;
  color: #00140e;
  border: none;
}

.btn-flash-success:hover:not(.btn-flash-light) {
  color: #00cb8e;
}

.btn-flash-success.focus,
.btn-flash-success:focus {
  box-shadow: none;
}

.btn-flash-success.disabled,
.btn-flash-success:disabled {
  color: #00cb8e;
  background-color: transparent;
}

.btn-flash-success.active,
.btn-flash-success:active,
.show > .btn-flash-success.dropdown-toggle {
  color: #00cb8e;
  background-color: #e6faf4;
  border: none;
}

.btn-flash-border-success {
  background-image: none;
  border: none;
  color: #26b58a;
  border: 1px solid #26b58a;
}

.btn-flash-border-success:not(.btn-flash-border-light) {
  color: #84b6ad;
  border: 1px solid #84b6ad;
}

.btn-flash-border-success[aria-expanded='true'] {
  background-color: #e6faf4;
  border: 1px solid #00cb8e;
  color: #00cb8e;
}

.btn-flash-border-success:hover {
  background-color: #e6faf4;
  color: #00140e;
  border: 1px solid transparent;
}

.btn-flash-border-success:hover:not(.btn-flash-border-light) {
  color: #00cb8e;
}

.btn-flash-border-success.focus,
.btn-flash-border-success:focus {
  box-shadow: none;
  border: 1px solid transparent;
}

.btn-flash-border-success.disabled,
.btn-flash-border-success:disabled {
  color: #00cb8e;
  background-color: transparent;
  border: 1px solid transparent;
}

.btn-flash-border-success.active,
.btn-flash-border-success:active,
.show > .btn-flash-border-success.dropdown-toggle {
  color: #00cb8e;
  background-color: #e6faf4;
  border: 1px solid transparent;
}

.btn-success {
  color: #ffffff;
  background-color: #00cb8e;
  border-color: #00cb8e;
}

.btn-success:hover {
  color: #ffffff;
  background-color: #00a573;
  border-color: #00986a;
}

.btn-success:focus,
.btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 211, 159, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
  color: #ffffff;
  background-color: #00cb8e;
  border-color: #00cb8e;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #ffffff;
  background-color: #00986a;
  border-color: #008b61;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 211, 159, 0.5);
}

.btn-shadow-success {
  background-image: none;
  border: none;
  color: #a09fa7;
  box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -webkit-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -moz-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -ms-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
}

.btn-shadow-success:not(.btn-flash-border-light) {
  color: #a09fa7;
  border: 1px solid transparent;
}

.btn-shadow-success[aria-expanded='true'] {
  background-color: #ffffff;
  border: 1px solid transparent;
  color: #a09fa7;
}

.btn-shadow-success:hover {
  background-color: #ffffff;
  color: #00cb8e;
  border: 1px solid transparent;
  box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -webkit-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -moz-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -ms-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
}

.btn-shadow-success:hover:not(.btn-flash-border-light) {
  color: #00cb8e;
}

.btn-shadow-success.focus,
.btn-shadow-success:focus {
  border: 1px solid transparent;
  box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -webkit-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -moz-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -ms-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
}

.btn-shadow-success.disabled,
.btn-shadow-success:disabled {
  color: #00cb8e;
  background-color: transparent;
  border: 1px solid transparent;
}

.btn-shadow-success.active,
.btn-shadow-success:active,
.show > .btn-shadow-success.dropdown-toggle {
  color: #00cb8e;
  background-color: #ffffff;
  border: 1px solid transparent;
  box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -webkit-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -moz-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -ms-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
}

.btn-soft-info {
  background-color: #cceeff;
  background-image: none;
  border-color: rgba(0, 170, 255, 0);
}

.btn-soft-info:not(.btn-soft-light) {
  color: #00aaff;
}

.btn-soft-info:hover {
  color: #ffffff;
  background-color: #00aaff;
  border-color: #00aaff;
}

.btn-soft-info.focus,
.btn-soft-info:focus {
  box-shadow: 0 0 0 3px rgba(0, 170, 255, 0.5);
}

.btn-soft-info.disabled,
.btn-soft-info:disabled {
  color: #00aaff;
  background-color: transparent;
}

.btn-soft-info.active,
.btn-soft-info:active,
.show > .btn-soft-info.dropdown-toggle {
  color: #ffffff;
  background-color: #00aaff;
  border-color: #00aaff;
}

.btn-flash-info {
  padding: 5px;
  background-image: none;
  border: none;
  color: #269dd9;
}

.btn-flash-info:not(.btn-flash-light) {
  color: #84aec9;
}

.btn-flash-info[aria-expanded='true'] {
  background-color: #e6f7ff;
  color: #00aaff;
}

.btn-flash-info:hover {
  background-color: #e6f7ff;
  color: #00111a;
  border: none;
}

.btn-flash-info:hover:not(.btn-flash-light) {
  color: #00aaff;
}

.btn-flash-info.focus,
.btn-flash-info:focus {
  box-shadow: none;
}

.btn-flash-info.disabled,
.btn-flash-info:disabled {
  color: #00aaff;
  background-color: transparent;
}

.btn-flash-info.active,
.btn-flash-info:active,
.show > .btn-flash-info.dropdown-toggle {
  color: #00aaff;
  background-color: #e6f7ff;
  border: none;
}

.btn-flash-border-info {
  background-image: none;
  border: none;
  color: #269dd9;
  border: 1px solid #269dd9;
}

.btn-flash-border-info:not(.btn-flash-border-light) {
  color: #84aec9;
  border: 1px solid #84aec9;
}

.btn-flash-border-info[aria-expanded='true'] {
  background-color: #e6f7ff;
  border: 1px solid #00aaff;
  color: #00aaff;
}

.btn-flash-border-info:hover {
  background-color: #e6f7ff;
  color: #00111a;
  border: 1px solid transparent;
}

.btn-flash-border-info:hover:not(.btn-flash-border-light) {
  color: #00aaff;
}

.btn-flash-border-info.focus,
.btn-flash-border-info:focus {
  box-shadow: none;
  border: 1px solid transparent;
}

.btn-flash-border-info.disabled,
.btn-flash-border-info:disabled {
  color: #00aaff;
  background-color: transparent;
  border: 1px solid transparent;
}

.btn-flash-border-info.active,
.btn-flash-border-info:active,
.show > .btn-flash-border-info.dropdown-toggle {
  color: #00aaff;
  background-color: #e6f7ff;
  border: 1px solid transparent;
}

.btn-info {
  color: #ffffff;
  background-color: #00aaff;
  border-color: #00aaff;
}

.btn-info:hover {
  color: #ffffff;
  background-color: #0091d9;
  border-color: #0088cc;
}

.btn-info:focus,
.btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 183, 255, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
  color: #ffffff;
  background-color: #00aaff;
  border-color: #00aaff;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #ffffff;
  background-color: #0088cc;
  border-color: #0080bf;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 183, 255, 0.5);
}

.btn-shadow-info {
  background-image: none;
  border: none;
  color: #a09fa7;
  box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -webkit-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -moz-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -ms-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
}

.btn-shadow-info:not(.btn-flash-border-light) {
  color: #a09fa7;
  border: 1px solid transparent;
}

.btn-shadow-info[aria-expanded='true'] {
  background-color: #ffffff;
  border: 1px solid transparent;
  color: #a09fa7;
}

.btn-shadow-info:hover {
  background-color: #ffffff;
  color: #00aaff;
  border: 1px solid transparent;
  box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -webkit-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -moz-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -ms-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
}

.btn-shadow-info:hover:not(.btn-flash-border-light) {
  color: #00aaff;
}

.btn-shadow-info.focus,
.btn-shadow-info:focus {
  border: 1px solid transparent;
  box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -webkit-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -moz-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -ms-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
}

.btn-shadow-info.disabled,
.btn-shadow-info:disabled {
  color: #00aaff;
  background-color: transparent;
  border: 1px solid transparent;
}

.btn-shadow-info.active,
.btn-shadow-info:active,
.show > .btn-shadow-info.dropdown-toggle {
  color: #00aaff;
  background-color: #ffffff;
  border: 1px solid transparent;
  box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -webkit-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -moz-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -ms-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
}

.btn-soft-warning {
  background-color: #fff3dd;
  background-image: none;
  border-color: rgba(255, 197, 85, 0);
}

.btn-soft-warning:not(.btn-soft-light) {
  color: #ffc555;
}

.btn-soft-warning:hover {
  color: #ffffff;
  background-color: #ffc555;
  border-color: #ffc555;
}

.btn-soft-warning.focus,
.btn-soft-warning:focus {
  box-shadow: 0 0 0 3px rgba(255, 197, 85, 0.5);
}

.btn-soft-warning.disabled,
.btn-soft-warning:disabled {
  color: #ffc555;
  background-color: transparent;
}

.btn-soft-warning.active,
.btn-soft-warning:active,
.show > .btn-soft-warning.dropdown-toggle {
  color: #ffffff;
  background-color: #ffc555;
  border-color: #ffc555;
}

.btn-flash-warning {
  padding: 5px;
  background-image: none;
  border: none;
  color: #d9b062;
}

.btn-flash-warning:not(.btn-flash-light) {
  color: #c4b59f;
}

.btn-flash-warning[aria-expanded='true'] {
  background-color: #fff9ee;
  color: #ffc555;
}

.btn-flash-warning:hover {
  background-color: #fff9ee;
  color: #1a1409;
  border: none;
}

.btn-flash-warning:hover:not(.btn-flash-light) {
  color: #ffc555;
}

.btn-flash-warning.focus,
.btn-flash-warning:focus {
  box-shadow: none;
}

.btn-flash-warning.disabled,
.btn-flash-warning:disabled {
  color: #ffc555;
  background-color: transparent;
}

.btn-flash-warning.active,
.btn-flash-warning:active,
.show > .btn-flash-warning.dropdown-toggle {
  color: #ffc555;
  background-color: #fff9ee;
  border: none;
}

.btn-flash-border-warning {
  background-image: none;
  border: none;
  color: #d9b062;
  border: 1px solid #d9b062;
}

.btn-flash-border-warning:not(.btn-flash-border-light) {
  color: #c4b59f;
  border: 1px solid #c4b59f;
}

.btn-flash-border-warning[aria-expanded='true'] {
  background-color: #fff9ee;
  border: 1px solid #ffc555;
  color: #ffc555;
}

.btn-flash-border-warning:hover {
  background-color: #fff9ee;
  color: #1a1409;
  border: 1px solid transparent;
}

.btn-flash-border-warning:hover:not(.btn-flash-border-light) {
  color: #ffc555;
}

.btn-flash-border-warning.focus,
.btn-flash-border-warning:focus {
  box-shadow: none;
  border: 1px solid transparent;
}

.btn-flash-border-warning.disabled,
.btn-flash-border-warning:disabled {
  color: #ffc555;
  background-color: transparent;
  border: 1px solid transparent;
}

.btn-flash-border-warning.active,
.btn-flash-border-warning:active,
.show > .btn-flash-border-warning.dropdown-toggle {
  color: #ffc555;
  background-color: #fff9ee;
  border: 1px solid transparent;
}

.btn-warning {
  color: #212529;
  background-color: #ffc555;
  border-color: #ffc555;
}

.btn-warning:hover {
  color: #212529;
  background-color: #ffb82f;
  border-color: #ffb422;
}

.btn-warning:focus,
.btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 173, 78, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #ffc555;
  border-color: #ffc555;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffb422;
  border-color: #ffaf15;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 173, 78, 0.5);
}

.btn-shadow-warning {
  background-image: none;
  border: none;
  color: #a09fa7;
  box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -webkit-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -moz-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -ms-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
}

.btn-shadow-warning:not(.btn-flash-border-light) {
  color: #a09fa7;
  border: 1px solid transparent;
}

.btn-shadow-warning[aria-expanded='true'] {
  background-color: #ffffff;
  border: 1px solid transparent;
  color: #a09fa7;
}

.btn-shadow-warning:hover {
  background-color: #ffffff;
  color: #ffc555;
  border: 1px solid transparent;
  box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -webkit-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -moz-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -ms-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
}

.btn-shadow-warning:hover:not(.btn-flash-border-light) {
  color: #ffc555;
}

.btn-shadow-warning.focus,
.btn-shadow-warning:focus {
  border: 1px solid transparent;
  box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -webkit-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -moz-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -ms-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
}

.btn-shadow-warning.disabled,
.btn-shadow-warning:disabled {
  color: #ffc555;
  background-color: transparent;
  border: 1px solid transparent;
}

.btn-shadow-warning.active,
.btn-shadow-warning:active,
.show > .btn-shadow-warning.dropdown-toggle {
  color: #ffc555;
  background-color: #ffffff;
  border: 1px solid transparent;
  box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -webkit-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -moz-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -ms-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
}

.btn-soft-danger {
  background-color: #ffdde0;
  background-image: none;
  border-color: rgba(255, 86, 102, 0);
}

.btn-soft-danger:not(.btn-soft-light) {
  color: #ef3e27;
}

.btn-soft-danger:hover {
  color: #ffffff;
  background-color: #ef3e27;
  border-color: #ef3e27;
}

.btn-soft-danger.focus,
.btn-soft-danger:focus {
  box-shadow: 0 0 0 3px rgba(255, 86, 102, 0.5);
}

.btn-soft-danger.disabled,
.btn-soft-danger:disabled {
  color: #ef3e27;
  background-color: transparent;
}

.btn-soft-danger.active,
.btn-soft-danger:active,
.show > .btn-soft-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #ef3e27;
  border-color: #ef3e27;
}

.btn-flash-danger {
  padding: 5px;
  background-image: none;
  border: none;
  color: #d9636e;
}

.btn-flash-danger:not(.btn-flash-light) {
  color: #c499a3;
}

.btn-flash-danger[aria-expanded='true'] {
  background-color: #ffeef0;
  color: #ef3e27;
}

.btn-flash-danger:hover {
  background-color: #ffeef0;
  color: #1a090a;
  border: none;
}

.btn-flash-danger:hover:not(.btn-flash-light) {
  color: #ef3e27;
}

.btn-flash-danger.focus,
.btn-flash-danger:focus {
  box-shadow: none;
}

.btn-flash-danger.disabled,
.btn-flash-danger:disabled {
  color: #ef3e27;
  background-color: transparent;
}

.btn-flash-danger.active,
.btn-flash-danger:active,
.show > .btn-flash-danger.dropdown-toggle {
  color: #ef3e27;
  background-color: #ffeef0;
  border: none;
}

.btn-flash-border-danger {
  background-image: none;
  border: none;
  color: #d9636e;
  border: 1px solid #d9636e;
}

.btn-flash-border-danger:not(.btn-flash-border-light) {
  color: #c499a3;
  border: 1px solid #c499a3;
}

.btn-flash-border-danger[aria-expanded='true'] {
  background-color: #ffeef0;
  border: 1px solid #ef3e27;
  color: #ef3e27;
}

.btn-flash-border-danger:hover {
  background-color: #ffeef0;
  color: #1a090a;
  border: 1px solid transparent;
}

.btn-flash-border-danger:hover:not(.btn-flash-border-light) {
  color: #ef3e27;
}

.btn-flash-border-danger.focus,
.btn-flash-border-danger:focus {
  box-shadow: none;
  border: 1px solid transparent;
}

.btn-flash-border-danger.disabled,
.btn-flash-border-danger:disabled {
  color: #ef3e27;
  background-color: transparent;
  border: 1px solid transparent;
}

.btn-flash-border-danger.active,
.btn-flash-border-danger:active,
.show > .btn-flash-border-danger.dropdown-toggle {
  color: #ef3e27;
  background-color: #ffeef0;
  border: 1px solid transparent;
}

.btn-danger {
  color: #ffffff;
  background-color: #ef3e27;
  border-color: #ef3e27;
}

.btn-danger:hover {
  color: #ffffff;
  background-color: #231f20;
  border-color: #231f20;
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #ffffff;
  background-color: #ef3e27;
  border-color: #ef3e27;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #ff2338;
  border-color: #ff162c;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 111, 125, 0.5);
}

.btn-shadow-danger {
  background-image: none;
  border: none;
  color: #a09fa7;
  box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -webkit-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -moz-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -ms-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
}

.btn-shadow-danger:not(.btn-flash-border-light) {
  color: #a09fa7;
  border: 1px solid transparent;
}

.btn-shadow-danger[aria-expanded='true'] {
  background-color: #ffffff;
  border: 1px solid transparent;
  color: #a09fa7;
}

.btn-shadow-danger:hover {
  background-color: #ffffff;
  color: #ef3e27;
  border: 1px solid transparent;
  box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -webkit-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -moz-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -ms-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
}

.btn-shadow-danger:hover:not(.btn-flash-border-light) {
  color: #ef3e27;
}

.btn-shadow-danger.focus,
.btn-shadow-danger:focus {
  border: 1px solid transparent;
  box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -webkit-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -moz-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -ms-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
}

.btn-shadow-danger.disabled,
.btn-shadow-danger:disabled {
  color: #ef3e27;
  background-color: transparent;
  border: 1px solid transparent;
}

.btn-shadow-danger.active,
.btn-shadow-danger:active,
.show > .btn-shadow-danger.dropdown-toggle {
  color: #ef3e27;
  background-color: #ffffff;
  border: 1px solid transparent;
  box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -webkit-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -moz-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -ms-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
}

.btn-soft-light {
  background-color: #fefefe;
  background-image: none;
  border-color: rgba(249, 250, 251, 0);
}

.btn-soft-light:not(.btn-soft-light) {
  color: #f9fafb;
}

.btn-soft-light:hover {
  color: #ffffff;
  background-color: #f9fafb;
  border-color: #f9fafb;
}

.btn-soft-light.focus,
.btn-soft-light:focus {
  box-shadow: 0 0 0 3px rgba(249, 250, 251, 0.5);
}

.btn-soft-light.disabled,
.btn-soft-light:disabled {
  color: #f9fafb;
  background-color: transparent;
}

.btn-soft-light.active,
.btn-soft-light:active,
.show > .btn-soft-light.dropdown-toggle {
  color: #ffffff;
  background-color: #f9fafb;
  border-color: #f9fafb;
}

.btn-flash-light {
  padding: 5px;
  background-image: none;
  border: none;
  color: #d5d5d6;
}

.btn-flash-light:not(.btn-flash-light) {
  color: #c2c2c8;
}

.btn-flash-light[aria-expanded='true'] {
  background-color: #feffff;
  color: #f9fafb;
}

.btn-flash-light:hover {
  background-color: #feffff;
  color: #191919;
  border: none;
}

.btn-flash-light:hover:not(.btn-flash-light) {
  color: #f9fafb;
}

.btn-flash-light.focus,
.btn-flash-light:focus {
  box-shadow: none;
}

.btn-flash-light.disabled,
.btn-flash-light:disabled {
  color: #f9fafb;
  background-color: transparent;
}

.btn-flash-light.active,
.btn-flash-light:active,
.show > .btn-flash-light.dropdown-toggle {
  color: #f9fafb;
  background-color: #feffff;
  border: none;
}

.btn-flash-border-light {
  background-image: none;
  border: none;
  color: #d5d5d6;
  border: 1px solid #d5d5d6;
}

.btn-flash-border-light:not(.btn-flash-border-light) {
  color: #c2c2c8;
  border: 1px solid #c2c2c8;
}

.btn-flash-border-light[aria-expanded='true'] {
  background-color: #feffff;
  border: 1px solid #f9fafb;
  color: #f9fafb;
}

.btn-flash-border-light:hover {
  background-color: #feffff;
  color: #191919;
  border: 1px solid transparent;
}

.btn-flash-border-light:hover:not(.btn-flash-border-light) {
  color: #f9fafb;
}

.btn-flash-border-light.focus,
.btn-flash-border-light:focus {
  box-shadow: none;
  border: 1px solid transparent;
}

.btn-flash-border-light.disabled,
.btn-flash-border-light:disabled {
  color: #f9fafb;
  background-color: transparent;
  border: 1px solid transparent;
}

.btn-flash-border-light.active,
.btn-flash-border-light:active,
.show > .btn-flash-border-light.dropdown-toggle {
  color: #f9fafb;
  background-color: #feffff;
  border: 1px solid transparent;
}

.btn-light {
  color: #212529;
  background-color: #f9fafb;
  border-color: #f9fafb;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e7ec;
  border-color: #dae1e7;
}

.btn-light:focus,
.btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(217, 218, 220, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #f9fafb;
  border-color: #f9fafb;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae1e7;
  border-color: #d3dae2;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(217, 218, 220, 0.5);
}

.btn-shadow-light {
  background-image: none;
  border: none;
  color: #a09fa7;
  box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -webkit-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -moz-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -ms-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
}

.btn-shadow-light:not(.btn-flash-border-light) {
  color: #a09fa7;
  border: 1px solid transparent;
}

.btn-shadow-light[aria-expanded='true'] {
  background-color: #ffffff;
  border: 1px solid transparent;
  color: #a09fa7;
}

.btn-shadow-light:hover {
  background-color: #ffffff;
  color: #f9fafb;
  border: 1px solid transparent;
  box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -webkit-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -moz-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -ms-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
}

.btn-shadow-light:hover:not(.btn-flash-border-light) {
  color: #f9fafb;
}

.btn-shadow-light.focus,
.btn-shadow-light:focus {
  border: 1px solid transparent;
  box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -webkit-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -moz-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -ms-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
}

.btn-shadow-light.disabled,
.btn-shadow-light:disabled {
  color: #f9fafb;
  background-color: transparent;
  border: 1px solid transparent;
}

.btn-shadow-light.active,
.btn-shadow-light:active,
.show > .btn-shadow-light.dropdown-toggle {
  color: #f9fafb;
  background-color: #ffffff;
  border: 1px solid transparent;
  box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -webkit-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -moz-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -ms-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
}

.btn-soft-dark {
  background-color: #d3d4d6;
  background-image: none;
  border-color: rgba(36, 42, 52, 0);
}

.btn-soft-dark:not(.btn-soft-light) {
  color: #242a34;
}

.btn-soft-dark:hover {
  color: #ffffff;
  background-color: #242a34;
  border-color: #242a34;
}

.btn-soft-dark.focus,
.btn-soft-dark:focus {
  box-shadow: 0 0 0 3px rgba(36, 42, 52, 0.5);
}

.btn-soft-dark.disabled,
.btn-soft-dark:disabled {
  color: #242a34;
  background-color: transparent;
}

.btn-soft-dark.active,
.btn-soft-dark:active,
.show > .btn-soft-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #242a34;
  border-color: #242a34;
}

.btn-flash-dark {
  padding: 5px;
  background-image: none;
  border: none;
  color: #40444b;
}

.btn-flash-dark:not(.btn-flash-light) {
  color: #8d8e96;
}

.btn-flash-dark[aria-expanded='true'] {
  background-color: #e9eaeb;
  color: #242a34;
}

.btn-flash-dark:hover {
  background-color: #e9eaeb;
  color: #040405;
  border: none;
}

.btn-flash-dark:hover:not(.btn-flash-light) {
  color: #242a34;
}

.btn-flash-dark.focus,
.btn-flash-dark:focus {
  box-shadow: none;
}

.btn-flash-dark.disabled,
.btn-flash-dark:disabled {
  color: #242a34;
  background-color: transparent;
}

.btn-flash-dark.active,
.btn-flash-dark:active,
.show > .btn-flash-dark.dropdown-toggle {
  color: #242a34;
  background-color: #e9eaeb;
  border: none;
}

.btn-flash-border-dark {
  background-image: none;
  border: none;
  color: #40444b;
  border: 1px solid #40444b;
}

.btn-flash-border-dark:not(.btn-flash-border-light) {
  color: #8d8e96;
  border: 1px solid #8d8e96;
}

.btn-flash-border-dark[aria-expanded='true'] {
  background-color: #e9eaeb;
  border: 1px solid #242a34;
  color: #242a34;
}

.btn-flash-border-dark:hover {
  background-color: #e9eaeb;
  color: #040405;
  border: 1px solid transparent;
}

.btn-flash-border-dark:hover:not(.btn-flash-border-light) {
  color: #242a34;
}

.btn-flash-border-dark.focus,
.btn-flash-border-dark:focus {
  box-shadow: none;
  border: 1px solid transparent;
}

.btn-flash-border-dark.disabled,
.btn-flash-border-dark:disabled {
  color: #242a34;
  background-color: transparent;
  border: 1px solid transparent;
}

.btn-flash-border-dark.active,
.btn-flash-border-dark:active,
.show > .btn-flash-border-dark.dropdown-toggle {
  color: #242a34;
  background-color: #e9eaeb;
  border: 1px solid transparent;
}

.btn-dark {
  color: #ffffff;
  background-color: #242a34;
  border-color: #242a34;
}

.btn-dark:hover {
  color: #ffffff;
  background-color: #14181d;
  border-color: #0f1216;
}

.btn-dark:focus,
.btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(69, 74, 82, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: #ffffff;
  background-color: #242a34;
  border-color: #242a34;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #0f1216;
  border-color: #0a0c0e;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(69, 74, 82, 0.5);
}

.btn-shadow-dark {
  background-image: none;
  border: none;
  color: #a09fa7;
  box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -webkit-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -moz-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -ms-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
}

.btn-shadow-dark:not(.btn-flash-border-light) {
  color: #a09fa7;
  border: 1px solid transparent;
}

.btn-shadow-dark[aria-expanded='true'] {
  background-color: #ffffff;
  border: 1px solid transparent;
  color: #a09fa7;
}

.btn-shadow-dark:hover {
  background-color: #ffffff;
  color: #242a34;
  border: 1px solid transparent;
  box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -webkit-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -moz-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -ms-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
}

.btn-shadow-dark:hover:not(.btn-flash-border-light) {
  color: #242a34;
}

.btn-shadow-dark.focus,
.btn-shadow-dark:focus {
  border: 1px solid transparent;
  box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -webkit-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -moz-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -ms-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
}

.btn-shadow-dark.disabled,
.btn-shadow-dark:disabled {
  color: #242a34;
  background-color: transparent;
  border: 1px solid transparent;
}

.btn-shadow-dark.active,
.btn-shadow-dark:active,
.show > .btn-shadow-dark.dropdown-toggle {
  color: #242a34;
  background-color: #ffffff;
  border: 1px solid transparent;
  box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -webkit-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -moz-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
  -ms-box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
}

/* Cards */
.card {
  border: 0;
  box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
  -ms-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
}

.card .card-header {
  background: none;

  font-weight: 500;
  display: flex;
  align-items: center;
  min-height: 60px;
}

/* @media (max-width: 767px) {
  .card .card-header {
    padding: 0 12px;
  }
} */

.card .card-header .card-title {
  color: #3f3f3f;
  margin-bottom: 0;
  text-transform: uppercase;
}

.card .card-header .card-title .card-title-secondary {
  color: #8d8a89;
  padding-left: 0.5rem;
  font-weight: 300;
}

.card .card-header .card-toolbar {
  right: 3px;
  display: inline-flex;
  justify-content: end;
  align-items: center;
  position: absolute;
}
.card .card-header .card-toolbar .toolbar-icon {
  width: 20px;
  height: 20px;
}

.card .card-header.no-border {
  border: 0;
}

.card .card-body {
  padding: 0.9375rem 0.9375rem;
}

.card .card-body + .card-body {
  padding-top: 1rem;
}

.card .card-body .card-date-filter {
  text-align: right;
  margin-bottom: 1.25rem;
}

.card .card-body .card-date-filter .filter-icon {
  width: 0.9375rem;
  height: 0.9375rem;
}

.card .card-body .card-date-filter span {
  font-weight: 500;
  font-size: 13px;
}

.card .card-body .card-info {
  font-weight: 300;
  font-size: 13px;
  color: #3f3f3f;
  margin-bottom: 1rem;
}

.card .card-body .card-info p {
  font-weight: 300;
  font-size: 13px;
  color: #3f3f3f;
  margin-bottom: 1rem;
}

.card .card-footer {
  color: #3f3f3f;
}

.card .card-footer.no-border {
  border: 0;
}

.card .card-subtitle {
  color: #8d8a89;
  font-weight: 400;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.card .card-description {
  margin-bottom: 2.25rem;
  font-weight: 400;
  color: #76838f;
}

.card.card-outline-success {
  border: 1px solid #00cb8e;
}

.card.card-outline-primary {
  border: 1px solid #ef3e27;
}

.card.card-outline-warning {
  border: 1px solid #ffc555;
}

.card.card-outline-danger {
  border: 1px solid #ef3e27;
}

.card.card-rounded {
  border-radius: 5px;
}

.card.card-faded {
  background: #b5b0b2;
  border-color: #b5b0b2;
}

.card.card-circle-progress {
  color: #ffffff;
  text-align: center;
}

.card.card-img-holder {
  position: relative;
}

.card.card-img-holder .card-img-absolute {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}

.card.card-weather .weather-daily .weather-day {
  opacity: 0.5;
  font-weight: 900;
}

.card.card-weather .weather-daily i {
  font-size: 20px;
}

.card.card-weather .weather-daily .weather-temp {
  margin-top: 0.5rem;
  margin-bottom: 0;
  opacity: 0.5;
  font-size: 0.75rem;
}

.card-inverse-primary {
  background: rgba(71, 101, 255, 0.2);
  border: 1px solid #415deb;
  color: #364dc2;
}

.card-inverse-secondary {
  background: rgba(211, 218, 230, 0.2);
  border: 1px solid #c2c9d4;
  color: #a0a6af;
}

.card-inverse-success {
  background: rgba(0, 203, 142, 0.2);
  border: 1px solid #00bb83;
  color: #009a6c;
}

.card-inverse-info {
  background: rgba(0, 170, 255, 0.2);
  border: 1px solid #009ceb;
  color: #0081c2;
}

.card-inverse-warning {
  background: rgba(255, 197, 85, 0.2);
  border: 1px solid #ebb54e;
  color: #c29641;
}

.card-inverse-danger {
  background: rgba(255, 86, 102, 0.2);
  border: 1px solid #eb4f5e;
  color: #c2414e;
}

.card-inverse-light {
  background: rgba(249, 250, 251, 0.2);
  border: 1px solid #e5e6e7;
  color: #bdbebf;
}

.card-inverse-dark {
  background: rgba(36, 42, 52, 0.2);
  border: 1px solid #212730;
  color: #1b2028;
}

/* Configuration Panel */
.config-container .separator {
  margin: 1.5625rem 0;
  border-bottom: 1px solid #e6e4e9;
}

.config-container .config-list {
  margin: 0;
  list-style: none;
  padding: 0;
}

.config-container .config-list .config-item label {
  font-size: 0.8125rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.config-container .config-list .config-item .config-icon {
  width: 1.25rem;
  height: 1.25rem;
}

.config-container .config-list .config-item .config-icon:hover {
  color: #ef3e27;
}

.config-container .config-list .config-item .enable {
  color: #ef3e27;
}

.config-container .config-list .config-item .disable {
  color: #9396a5;
}

/* Checkboxes and Radios */
.string-check-inline .string-check {
  display: inline-block;
  margin-right: 0.5rem;
}

.string-check {
  position: relative;
  display: block;
  padding-left: 0;
}

.string-check .string-check-label {
  min-height: 18px;
  margin-left: 1.375rem;
  display: block;
  margin-bottom: 0;
  font-size: 0.8125rem;
  line-height: 1.5;
}

.rtl .string-check .string-check-label {
  margin-left: 0;
  margin-right: 1.75rem;
}

.string-check .string-check-label:hover {
  cursor: pointer;
}

.string-check input {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 0;
  margin-top: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}

.rtl .string-check input {
  left: auto;
  right: 0;
}

.string-check input[type='checkbox'] + .string-check-label:before,
.string-check input[type='checkbox'] + .string-check-label:after {
  position: absolute;
  top: 0;
  left: 0;
}

.rtl .string-check input[type='checkbox'] + .string-check-label:before,
.rtl .string-check input[type='checkbox'] + .string-check-label:after {
  left: auto;
  right: 0;
}

.string-check input[type='checkbox'] + .string-check-label:before {
  content: '';
  width: 18px;
  height: 18px;
  border-radius: 2px;
  transition: all;
  transition-duration: 0s;
  transition-duration: 250ms;
}

@media (prefers-reduced-motion: reduce) {
  .string-check input[type='checkbox'] + .string-check-label:before {
    transition: none;
  }
}

.string-check input[type='checkbox']:checked + .string-check-label:before {
  background: #ef3e27;
  border-width: 0;
}

.string-check input[type='checkbox']:checked + .string-check-label:after {
  /*content: '';
	position: absolute;
	left: 3px;
	top: 3px;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
	width: 13px;
	height: 13px;*/
  content: '';
  position: absolute;
  left: 6px;
  top: 3.1px;
  width: 6px;
  height: 10px;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.string-check input[type='checkbox']:disabled + .string-check-label {
  opacity: 0.6;
}

.string-check
  input[type='checkbox']:disabled:checked
  + .string-check-label:after {
  color: #ffffff;
}

.string-check input[type='radio'] + .string-check-label:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition: all;
  transition-duration: 0s;
  transition-duration: 250ms;
}

.rtl .string-check input[type='radio'] + .string-check-label:before {
  left: auto;
  right: 0;
}

@media (prefers-reduced-motion: reduce) {
  .string-check input[type='radio'] + .string-check-label:before {
    transition: none;
  }
}

.string-check input[type='radio'] + .string-check-label:after {
  content: '';
  width: 8px;
  height: 8px;
  background: #ffffff;
  border-radius: 100%;
  top: 6px;
  left: 6px;
  transition: all;
  transition-duration: 0s;
  transition-duration: 250ms;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transform: scale(0);
  transform: scale(0);
  position: absolute;
}

.rtl .string-check input[type='radio'] + .string-check-label:after {
  left: auto;
  right: 6px;
}

.string-check input[type='radio']:checked + .string-check-label:before {
  background: #ef3e27;
  border-width: 0;
}

.string-check input[type='radio']:checked + .string-check-label:after {
  opacity: 1;
  line-height: 1.5;
  filter: alpha(opacity=100);
  -webkit-transform: scale(1);
  transform: scale(1);
}

.string-check input[type='radio']:disabled + .string-check-label {
  opacity: 0.6;
}

.string-check-bordered-primary
  input[type='checkbox']
  + .string-check-label:before,
.string-check-bordered-primary
  input[type='radio']
  + .string-check-label:before {
  border: solid #ef3e27;
  border-width: 1px;
  border-color: #ef3e27;
}

.string-check-bordered-primary
  input[type='checkbox']:checked
  + .string-check-label:before,
.string-check-bordered-primary
  input[type='radio']:checked
  + .string-check-label:before {
  border: solid #ef3e27;
  border-width: 1px;
  border-color: #ef3e27;
  background: transparent;
  color: #ef3e27;
}

.string-check-bordered-primary
  input[type='checkbox']:checked
  + .string-check-label:after,
.string-check-bordered-primary
  input[type='radio']:checked
  + .string-check-label:after {
  border-style: solid;
  border-color: #ef3e27;
}

.string-check-bold-bordered-primary
  input[type='checkbox']
  + .string-check-label:before,
.string-check-bold-bordered-primary
  input[type='radio']
  + .string-check-label:before {
  border: solid #ef3e27;
  border-width: 2px;
  border-color: #ef3e27;
}

.string-check-bold-bordered-primary
  input[type='checkbox']:checked
  + .string-check-label:before,
.string-check-bold-bordered-primary
  input[type='radio']:checked
  + .string-check-label:before {
  border: solid #ef3e27;
  border-width: 2px;
  border-color: #ef3e27;
  background: transparent;
  color: #ef3e27;
}

.string-check-bold-bordered-primary
  input[type='checkbox']:checked
  + .string-check-label:after,
.string-check-bold-bordered-primary
  input[type='radio']:checked
  + .string-check-label:after {
  border-style: solid;
  border-color: #ef3e27;
}

.string-check-soft-primary.string-check
  input[type='checkbox']
  + .string-check-label:before,
.string-check-soft-primary.string-check
  input[type='radio']
  + .string-check-label:before {
  background-color: #ececec;
}

.string-check-soft-primary.string-check
  input[type='checkbox']:checked
  + .string-check-label:before,
.string-check-soft-primary.string-check
  input[type='radio']:checked
  + .string-check-label:before {
  background: #ef3e27;
}

.string-check-soft-primary.string-check
  input[type='checkbox']:checked
  + .string-check-label:after,
.string-check-soft-primary.string-check
  input[type='radio']:checked
  + .string-check-label:after {
  border-style: solid;
  border-color: #ffffff;
}

.string-check-bordered-secondary
  input[type='checkbox']
  + .string-check-label:before,
.string-check-bordered-secondary
  input[type='radio']
  + .string-check-label:before {
  border: solid #ef3e27;
  border-width: 1px;
  border-color: #d3dae6;
}

.string-check-bordered-secondary
  input[type='checkbox']:checked
  + .string-check-label:before,
.string-check-bordered-secondary
  input[type='radio']:checked
  + .string-check-label:before {
  border: solid #ef3e27;
  border-width: 1px;
  border-color: #d3dae6;
  background: transparent;
  color: #d3dae6;
}

.string-check-bordered-secondary
  input[type='checkbox']:checked
  + .string-check-label:after,
.string-check-bordered-secondary
  input[type='radio']:checked
  + .string-check-label:after {
  border-style: solid;
  border-color: #d3dae6;
}

.string-check-bold-bordered-secondary
  input[type='checkbox']
  + .string-check-label:before,
.string-check-bold-bordered-secondary
  input[type='radio']
  + .string-check-label:before {
  border: solid #ef3e27;
  border-width: 2px;
  border-color: #d3dae6;
}

.string-check-bold-bordered-secondary
  input[type='checkbox']:checked
  + .string-check-label:before,
.string-check-bold-bordered-secondary
  input[type='radio']:checked
  + .string-check-label:before {
  border: solid #ef3e27;
  border-width: 2px;
  border-color: #d3dae6;
  background: transparent;
  color: #d3dae6;
}

.string-check-bold-bordered-secondary
  input[type='checkbox']:checked
  + .string-check-label:after,
.string-check-bold-bordered-secondary
  input[type='radio']:checked
  + .string-check-label:after {
  border-style: solid;
  border-color: #d3dae6;
}

.string-check-soft-secondary.string-check
  input[type='checkbox']
  + .string-check-label:before,
.string-check-soft-secondary.string-check
  input[type='radio']
  + .string-check-label:before {
  background-color: #f6f8fa;
}

.string-check-soft-secondary.string-check
  input[type='checkbox']:checked
  + .string-check-label:before,
.string-check-soft-secondary.string-check
  input[type='radio']:checked
  + .string-check-label:before {
  background: #d3dae6;
}

.string-check-soft-secondary.string-check
  input[type='checkbox']:checked
  + .string-check-label:after,
.string-check-soft-secondary.string-check
  input[type='radio']:checked
  + .string-check-label:after {
  border-style: solid;
  border-color: #ffffff;
}

.string-check-bordered-success
  input[type='checkbox']
  + .string-check-label:before,
.string-check-bordered-success
  input[type='radio']
  + .string-check-label:before {
  border: solid #ef3e27;
  border-width: 1px;
  border-color: #00cb8e;
}

.string-check-bordered-success
  input[type='checkbox']:checked
  + .string-check-label:before,
.string-check-bordered-success
  input[type='radio']:checked
  + .string-check-label:before {
  border: solid #ef3e27;
  border-width: 1px;
  border-color: #00cb8e;
  background: transparent;
  color: #00cb8e;
}

.string-check-bordered-success
  input[type='checkbox']:checked
  + .string-check-label:after,
.string-check-bordered-success
  input[type='radio']:checked
  + .string-check-label:after {
  border-style: solid;
  border-color: #00cb8e;
}

.string-check-bold-bordered-success
  input[type='checkbox']
  + .string-check-label:before,
.string-check-bold-bordered-success
  input[type='radio']
  + .string-check-label:before {
  border: solid #ef3e27;
  border-width: 2px;
  border-color: #00cb8e;
}

.string-check-bold-bordered-success
  input[type='checkbox']:checked
  + .string-check-label:before,
.string-check-bold-bordered-success
  input[type='radio']:checked
  + .string-check-label:before {
  border: solid #ef3e27;
  border-width: 2px;
  border-color: #00cb8e;
  background: transparent;
  color: #00cb8e;
}

.string-check-bold-bordered-success
  input[type='checkbox']:checked
  + .string-check-label:after,
.string-check-bold-bordered-success
  input[type='radio']:checked
  + .string-check-label:after {
  border-style: solid;
  border-color: #00cb8e;
}

.string-check-soft-success.string-check
  input[type='checkbox']
  + .string-check-label:before,
.string-check-soft-success.string-check
  input[type='radio']
  + .string-check-label:before {
  background-color: #ccf5e8;
}

.string-check-soft-success.string-check
  input[type='checkbox']:checked
  + .string-check-label:before,
.string-check-soft-success.string-check
  input[type='radio']:checked
  + .string-check-label:before {
  background: #00cb8e;
}

.string-check-soft-success.string-check
  input[type='checkbox']:checked
  + .string-check-label:after,
.string-check-soft-success.string-check
  input[type='radio']:checked
  + .string-check-label:after {
  border-style: solid;
  border-color: #ffffff;
}

.string-check-bordered-info input[type='checkbox'] + .string-check-label:before,
.string-check-bordered-info input[type='radio'] + .string-check-label:before {
  border: solid #ef3e27;
  border-width: 1px;
  border-color: #00aaff;
}

.string-check-bordered-info
  input[type='checkbox']:checked
  + .string-check-label:before,
.string-check-bordered-info
  input[type='radio']:checked
  + .string-check-label:before {
  border: solid #ef3e27;
  border-width: 1px;
  border-color: #00aaff;
  background: transparent;
  color: #00aaff;
}

.string-check-bordered-info
  input[type='checkbox']:checked
  + .string-check-label:after,
.string-check-bordered-info
  input[type='radio']:checked
  + .string-check-label:after {
  border-style: solid;
  border-color: #00aaff;
}

.string-check-bold-bordered-info
  input[type='checkbox']
  + .string-check-label:before,
.string-check-bold-bordered-info
  input[type='radio']
  + .string-check-label:before {
  border: solid #ef3e27;
  border-width: 2px;
  border-color: #00aaff;
}

.string-check-bold-bordered-info
  input[type='checkbox']:checked
  + .string-check-label:before,
.string-check-bold-bordered-info
  input[type='radio']:checked
  + .string-check-label:before {
  border: solid #ef3e27;
  border-width: 2px;
  border-color: #00aaff;
  background: transparent;
  color: #00aaff;
}

.string-check-bold-bordered-info
  input[type='checkbox']:checked
  + .string-check-label:after,
.string-check-bold-bordered-info
  input[type='radio']:checked
  + .string-check-label:after {
  border-style: solid;
  border-color: #00aaff;
}

.string-check-soft-info.string-check
  input[type='checkbox']
  + .string-check-label:before,
.string-check-soft-info.string-check
  input[type='radio']
  + .string-check-label:before {
  background-color: #cceeff;
}

.string-check-soft-info.string-check
  input[type='checkbox']:checked
  + .string-check-label:before,
.string-check-soft-info.string-check
  input[type='radio']:checked
  + .string-check-label:before {
  background: #00aaff;
}

.string-check-soft-info.string-check
  input[type='checkbox']:checked
  + .string-check-label:after,
.string-check-soft-info.string-check
  input[type='radio']:checked
  + .string-check-label:after {
  border-style: solid;
  border-color: #ffffff;
}

.string-check-bordered-warning
  input[type='checkbox']
  + .string-check-label:before,
.string-check-bordered-warning
  input[type='radio']
  + .string-check-label:before {
  border: solid #ef3e27;
  border-width: 1px;
  border-color: #ffc555;
}

.string-check-bordered-warning
  input[type='checkbox']:checked
  + .string-check-label:before,
.string-check-bordered-warning
  input[type='radio']:checked
  + .string-check-label:before {
  border: solid #ef3e27;
  border-width: 1px;
  border-color: #ffc555;
  background: transparent;
  color: #ffc555;
}

.string-check-bordered-warning
  input[type='checkbox']:checked
  + .string-check-label:after,
.string-check-bordered-warning
  input[type='radio']:checked
  + .string-check-label:after {
  border-style: solid;
  border-color: #ffc555;
}

.string-check-bold-bordered-warning
  input[type='checkbox']
  + .string-check-label:before,
.string-check-bold-bordered-warning
  input[type='radio']
  + .string-check-label:before {
  border: solid #ef3e27;
  border-width: 2px;
  border-color: #ffc555;
}

.string-check-bold-bordered-warning
  input[type='checkbox']:checked
  + .string-check-label:before,
.string-check-bold-bordered-warning
  input[type='radio']:checked
  + .string-check-label:before {
  border: solid #ef3e27;
  border-width: 2px;
  border-color: #ffc555;
  background: transparent;
  color: #ffc555;
}

.string-check-bold-bordered-warning
  input[type='checkbox']:checked
  + .string-check-label:after,
.string-check-bold-bordered-warning
  input[type='radio']:checked
  + .string-check-label:after {
  border-style: solid;
  border-color: #ffc555;
}

.string-check-soft-warning.string-check
  input[type='checkbox']
  + .string-check-label:before,
.string-check-soft-warning.string-check
  input[type='radio']
  + .string-check-label:before {
  background-color: #fff3dd;
}

.string-check-soft-warning.string-check
  input[type='checkbox']:checked
  + .string-check-label:before,
.string-check-soft-warning.string-check
  input[type='radio']:checked
  + .string-check-label:before {
  background: #ffc555;
}

.string-check-soft-warning.string-check
  input[type='checkbox']:checked
  + .string-check-label:after,
.string-check-soft-warning.string-check
  input[type='radio']:checked
  + .string-check-label:after {
  border-style: solid;
  border-color: #ffffff;
}

.string-check-bordered-danger
  input[type='checkbox']
  + .string-check-label:before,
.string-check-bordered-danger input[type='radio'] + .string-check-label:before {
  border: solid #ef3e27;
  border-width: 1px;
  border-color: #ef3e27;
}

.string-check-bordered-danger
  input[type='checkbox']:checked
  + .string-check-label:before,
.string-check-bordered-danger
  input[type='radio']:checked
  + .string-check-label:before {
  border: solid #ef3e27;
  border-width: 1px;
  border-color: #ef3e27;
  background: transparent;
  color: #ef3e27;
}

.string-check-bordered-danger
  input[type='checkbox']:checked
  + .string-check-label:after,
.string-check-bordered-danger
  input[type='radio']:checked
  + .string-check-label:after {
  border-style: solid;
  border-color: #ef3e27;
}

.string-check-bold-bordered-danger
  input[type='checkbox']
  + .string-check-label:before,
.string-check-bold-bordered-danger
  input[type='radio']
  + .string-check-label:before {
  border: solid #ef3e27;
  border-width: 2px;
  border-color: #ef3e27;
}

.string-check-bold-bordered-danger
  input[type='checkbox']:checked
  + .string-check-label:before,
.string-check-bold-bordered-danger
  input[type='radio']:checked
  + .string-check-label:before {
  border: solid #ef3e27;
  border-width: 2px;
  border-color: #ef3e27;
  background: transparent;
  color: #ef3e27;
}

.string-check-bold-bordered-danger
  input[type='checkbox']:checked
  + .string-check-label:after,
.string-check-bold-bordered-danger
  input[type='radio']:checked
  + .string-check-label:after {
  border-style: solid;
  border-color: #ef3e27;
}

.string-check-soft-danger.string-check
  input[type='checkbox']
  + .string-check-label:before,
.string-check-soft-danger.string-check
  input[type='radio']
  + .string-check-label:before {
  background-color: #ffdde0;
}

.string-check-soft-danger.string-check
  input[type='checkbox']:checked
  + .string-check-label:before,
.string-check-soft-danger.string-check
  input[type='radio']:checked
  + .string-check-label:before {
  background: #ef3e27;
}

.string-check-soft-danger.string-check
  input[type='checkbox']:checked
  + .string-check-label:after,
.string-check-soft-danger.string-check
  input[type='radio']:checked
  + .string-check-label:after {
  border-style: solid;
  border-color: #ffffff;
}

.string-check-bordered-light
  input[type='checkbox']
  + .string-check-label:before,
.string-check-bordered-light input[type='radio'] + .string-check-label:before {
  border: solid #ef3e27;
  border-width: 1px;
  border-color: #f9fafb;
}

.string-check-bordered-light
  input[type='checkbox']:checked
  + .string-check-label:before,
.string-check-bordered-light
  input[type='radio']:checked
  + .string-check-label:before {
  border: solid #ef3e27;
  border-width: 1px;
  border-color: #f9fafb;
  background: transparent;
  color: #f9fafb;
}

.string-check-bordered-light
  input[type='checkbox']:checked
  + .string-check-label:after,
.string-check-bordered-light
  input[type='radio']:checked
  + .string-check-label:after {
  border-style: solid;
  border-color: #f9fafb;
}

.string-check-bold-bordered-light
  input[type='checkbox']
  + .string-check-label:before,
.string-check-bold-bordered-light
  input[type='radio']
  + .string-check-label:before {
  border: solid #ef3e27;
  border-width: 2px;
  border-color: #f9fafb;
}

.string-check-bold-bordered-light
  input[type='checkbox']:checked
  + .string-check-label:before,
.string-check-bold-bordered-light
  input[type='radio']:checked
  + .string-check-label:before {
  border: solid #ef3e27;
  border-width: 2px;
  border-color: #f9fafb;
  background: transparent;
  color: #f9fafb;
}

.string-check-bold-bordered-light
  input[type='checkbox']:checked
  + .string-check-label:after,
.string-check-bold-bordered-light
  input[type='radio']:checked
  + .string-check-label:after {
  border-style: solid;
  border-color: #f9fafb;
}

.string-check-soft-light.string-check
  input[type='checkbox']
  + .string-check-label:before,
.string-check-soft-light.string-check
  input[type='radio']
  + .string-check-label:before {
  background-color: #fefefe;
}

.string-check-soft-light.string-check
  input[type='checkbox']:checked
  + .string-check-label:before,
.string-check-soft-light.string-check
  input[type='radio']:checked
  + .string-check-label:before {
  background: #f9fafb;
}

.string-check-soft-light.string-check
  input[type='checkbox']:checked
  + .string-check-label:after,
.string-check-soft-light.string-check
  input[type='radio']:checked
  + .string-check-label:after {
  border-style: solid;
  border-color: #ffffff;
}

.string-check-bordered-dark input[type='checkbox'] + .string-check-label:before,
.string-check-bordered-dark input[type='radio'] + .string-check-label:before {
  border: solid #ef3e27;
  border-width: 1px;
  border-color: #242a34;
}

.string-check-bordered-dark
  input[type='checkbox']:checked
  + .string-check-label:before,
.string-check-bordered-dark
  input[type='radio']:checked
  + .string-check-label:before {
  border: solid #ef3e27;
  border-width: 1px;
  border-color: #242a34;
  background: transparent;
  color: #242a34;
}

.string-check-bordered-dark
  input[type='checkbox']:checked
  + .string-check-label:after,
.string-check-bordered-dark
  input[type='radio']:checked
  + .string-check-label:after {
  border-style: solid;
  border-color: #242a34;
}

.string-check-bold-bordered-dark
  input[type='checkbox']
  + .string-check-label:before,
.string-check-bold-bordered-dark
  input[type='radio']
  + .string-check-label:before {
  border: solid #ef3e27;
  border-width: 2px;
  border-color: #242a34;
}

.string-check-bold-bordered-dark
  input[type='checkbox']:checked
  + .string-check-label:before,
.string-check-bold-bordered-dark
  input[type='radio']:checked
  + .string-check-label:before {
  border: solid #ef3e27;
  border-width: 2px;
  border-color: #242a34;
  background: transparent;
  color: #242a34;
}

.string-check-bold-bordered-dark
  input[type='checkbox']:checked
  + .string-check-label:after,
.string-check-bold-bordered-dark
  input[type='radio']:checked
  + .string-check-label:after {
  border-style: solid;
  border-color: #242a34;
}

.string-check-soft-dark.string-check
  input[type='checkbox']
  + .string-check-label:before,
.string-check-soft-dark.string-check
  input[type='radio']
  + .string-check-label:before {
  background-color: #d3d4d6;
}

.string-check-soft-dark.string-check
  input[type='checkbox']:checked
  + .string-check-label:before,
.string-check-soft-dark.string-check
  input[type='radio']:checked
  + .string-check-label:before {
  background: #242a34;
}

.string-check-soft-dark.string-check
  input[type='checkbox']:checked
  + .string-check-label:after,
.string-check-soft-dark.string-check
  input[type='radio']:checked
  + .string-check-label:after {
  border-style: solid;
  border-color: #ffffff;
}

/* Dropdowns */
.dropdown-menu {
  margin-top: 0;
  font-size: 0.875rem;
  box-shadow: 0px 0px 40px 0px rgba(79, 61, 101, 0.15);
  border-radius: 0.25rem;
  background-color: #ffffff;
}

.dropdown-menu .dropdown-item {
  font-size: 0.8125rem;
  font-weight: 400;
  padding: 0.25rem 1.5rem;
}

.dropdown-menu .dropdown-item:hover {
  background-color: #ececec;
}

.dropdown-menu .dropdown-item .dropdown-title {
  color: #ef3e27;
  font-weight: 500;
}

.dropdown-menu .dropdown-item .dropdown-link {
  color: #8d8a89;
  display: block;
}

.dropdown-menu .dropdown-item .dropdown-link svg {
  color: #8d8a89;
  width: 13px;
  height: 13px;
  margin-right: 10px;
}

.dropdown-menu .dropdown-item .dropdown-link:hover {
  text-decoration: none;
  color: #ef3e27;
}

.dropdown-menu .dropdown-item .dropdown-link:hover svg {
  color: #ef3e27;
}

.dropdown-menu .dropdown-item:active {
  background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
  background: initial;
}

.event-list .event-item {
  padding: 1rem 1.9rem;
  margin: 0.9375rem 0.9375rem 1.875rem 0.9375rem;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
  -ms-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
}

.event-list .event-item .event-schedule {
  color: #3f3f3f;
  margin-bottom: 0.625rem;
}

.event-list .event-item .event-schedule .event-icon {
  stroke-width: 1;
  width: 18px;
  height: 18px;
  margin: 0.6875rem 1rem 0 0;
}

.event-list .event-item .event-schedule .event-day {
  font-size: 70px;
  font-weight: 200;
  margin-right: 1rem;
  line-height: 100%;
}

.event-list .event-item .event-schedule .event-month-time {
  font-weight: 200;
  font-size: 22px;
  display: flex;
  line-height: 118%;
  flex-direction: column;
  justify-content: center;
}

.event-list .event-item .event-schedule .event-month-time span {
  display: block;
  text-transform: uppercase;
}

.event-list .event-item .event-content {
  color: #ababbb;
  margin-bottom: 0.625rem;
  font-weight: 300;
}
p.event-content.dashboar {
  margin: 0px;
}
i.fa.fa-calendar.fa-calendar-alt.event-icon {
  font-size: 16px;
}
i.fa.fa-ticket.fa-ticket-alt.filled-warning.text-warning {
  font-size: 19px;
}
.event-list .event-item .event-participants {
  padding: 0;
  margin: 0;
}

.event-list .event-item .event-participants .event-user {
  border: 2px solid #ffffff;
  width: 48px;
  height: 42px;
  cursor: pointer;
  border-radius: 50%;
}

.event-list .event-item .event-participants .event-user .event-user-inital {
  font-size: 13px;
  line-height: 100%;
}

.event-list .event-item .event-participants li + li {
  margin-left: -10px;
}

/* Forms */
.form-group {
  margin-bottom: 1.5rem;
}

.input-group-append,
.input-group-prepend {
  color: #8d8a89;
  width: auto;
  border: none;
}

.input-group-append .input-group-text,
.input-group-prepend .input-group-text {
  border-color: #e6e4e9;
  padding: 0.3rem 0.75rem;
  color: #8d8a89;
}

.form-text {
  font-weight: 300;
  font-size: 12px;
}

@media (min-width: 1025px) {
  .form-label-right label {
    text-align: right;
  }
}

select.form-control,
.jsgrid .jsgrid-table .jsgrid-filter-row select,
.jsgrid .jsgrid-table .jsgrid-edit-row select {
  border: 1px solid #e6e4e9;
  color: #3f3f3f;
}

select.form-control:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row select:focus,
.jsgrid .jsgrid-table .jsgrid-edit-row select:focus {
  border: 1px solid #ef3e27;
}

select.form-control.border-primary,
.jsgrid .jsgrid-table .jsgrid-filter-row select.border-primary,
.jsgrid .jsgrid-table .jsgrid-edit-row select.border-primary {
  outline: 1px solid #ef3e27;
}

select.form-control.border-primary:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row select.border-primary:focus,
.jsgrid .jsgrid-table .jsgrid-edit-row select.border-primary:focus {
  outline: 1px solid #ef3e27;
}

select.form-control.border-secondary,
.jsgrid .jsgrid-table .jsgrid-filter-row select.border-secondary,
.jsgrid .jsgrid-table .jsgrid-edit-row select.border-secondary {
  outline: 1px solid #d3dae6;
}

select.form-control.border-secondary:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row select.border-secondary:focus,
.jsgrid .jsgrid-table .jsgrid-edit-row select.border-secondary:focus {
  outline: 1px solid #d3dae6;
}

select.form-control.border-success,
.jsgrid .jsgrid-table .jsgrid-filter-row select.border-success,
.jsgrid .jsgrid-table .jsgrid-edit-row select.border-success {
  outline: 1px solid #00cb8e;
}

select.form-control.border-success:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row select.border-success:focus,
.jsgrid .jsgrid-table .jsgrid-edit-row select.border-success:focus {
  outline: 1px solid #00cb8e;
}

select.form-control.border-info,
.jsgrid .jsgrid-table .jsgrid-filter-row select.border-info,
.jsgrid .jsgrid-table .jsgrid-edit-row select.border-info {
  outline: 1px solid #00aaff;
}

select.form-control.border-info:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row select.border-info:focus,
.jsgrid .jsgrid-table .jsgrid-edit-row select.border-info:focus {
  outline: 1px solid #00aaff;
}

select.form-control.border-warning,
.jsgrid .jsgrid-table .jsgrid-filter-row select.border-warning,
.jsgrid .jsgrid-table .jsgrid-edit-row select.border-warning {
  outline: 1px solid #ffc555;
}

select.form-control.border-warning:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row select.border-warning:focus,
.jsgrid .jsgrid-table .jsgrid-edit-row select.border-warning:focus {
  outline: 1px solid #ffc555;
}

select.form-control.border-danger,
.jsgrid .jsgrid-table .jsgrid-filter-row select.border-danger,
.jsgrid .jsgrid-table .jsgrid-edit-row select.border-danger {
  outline: 1px solid #ef3e27;
}

select.form-control.border-danger:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row select.border-danger:focus,
.jsgrid .jsgrid-table .jsgrid-edit-row select.border-danger:focus {
  outline: 1px solid #ef3e27;
}

select.form-control.border-light,
.jsgrid .jsgrid-table .jsgrid-filter-row select.border-light,
.jsgrid .jsgrid-table .jsgrid-edit-row select.border-light {
  outline: 1px solid #f9fafb;
}

select.form-control.border-light:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row select.border-light:focus,
.jsgrid .jsgrid-table .jsgrid-edit-row select.border-light:focus {
  outline: 1px solid #f9fafb;
}

select.form-control.border-dark,
.jsgrid .jsgrid-table .jsgrid-filter-row select.border-dark,
.jsgrid .jsgrid-table .jsgrid-edit-row select.border-dark {
  outline: 1px solid #242a34;
}

select.form-control.border-dark:focus,
.jsgrid .jsgrid-table .jsgrid-filter-row select.border-dark:focus,
.jsgrid .jsgrid-table .jsgrid-edit-row select.border-dark:focus {
  outline: 1px solid #242a34;
}

.form-group label {
  font-size: 0.8125rem;
  line-height: 1;
  vertical-align: top;
  margin-bottom: 0.5rem;
  color: #3c4b5c;
}

.form-group.has-danger .form-control,
.form-group.has-danger
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type='text'],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .form-group.has-danger
  input[type='text'],
.form-group.has-danger .jsgrid .jsgrid-table .jsgrid-filter-row select,
.jsgrid .jsgrid-table .jsgrid-filter-row .form-group.has-danger select,
.form-group.has-danger
  .jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  input[type='text'],
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .form-group.has-danger
  input[type='text'],
.form-group.has-danger .jsgrid .jsgrid-table .jsgrid-edit-row select,
.jsgrid .jsgrid-table .jsgrid-edit-row .form-group.has-danger select,
.form-group.has-danger
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type='number'],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .form-group.has-danger
  input[type='number'],
.form-group.has-danger
  .jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  input[type='number'],
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .form-group.has-danger
  input[type='number'] {
  border-color: #ef3e27;
}

.form-group .file-upload-default {
  visibility: hidden;
  position: absolute;
}

.form-group .file-upload-info {
  background: transparent;
}

.map-container {
  position: relative;
  min-width: 100%;
  max-width: 100%;
  display: block;
  padding: 35% 0;
  overflow: hidden;
  border-radius: 5px;
}

.map-container .google-map {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.map-container .google-map.no-controls .gmnoprint,
.map-container .google-map.no-controls .gm-style-cc {
  display: none;
}

/* Icons */
.icons-list {
  border-left: 1px solid #e6e4e9;
  border-top: 1px solid #e6e4e9;
}

.icons-list > div {
  background: #ffffff;
  border-bottom: 1px solid #e6e4e9;
  border-right: 1px solid #e6e4e9;
  /*@include display-flex;
	@include align-items(center);*/
  padding: 15px 15px;
  font-weight: 400;
  font-size: 0.875rem;
}

.icons-list > div i {
  display: inline-block;
  font-size: 20px;
  width: 40px;
  text-align: left;
  color: #ef3e27;
}

.icon-in-bg {
  width: 55px;
  height: 55px;
  text-align: center;
  /*@include display-flex();
	@include align-items(center);
	@include justify-content(center);*/
}

.icon-lg {
  font-size: 2.5rem;
}

.icon-md {
  font-size: 1.875rem;
}

.icon-sm {
  font-size: 1rem;
}

/* Lists */
ul,
ol,
dl {
  padding-left: 2rem;
  font-size: 0.875rem;
}

/* ul li,
ol li,
dl li {
	line-height: 1.8;
} */

/* Modals */
.modal .modal-dialog .modal-content {
  background-color: #ffffff;
}
i.fa.fa-angle-left {
  color: red;
  font-size: 22px;
}
i.fa.fa-angle-right {
  color: red;
  font-size: 22px;
}
.modal .modal-dialog .modal-content .modal-header {
  padding: 1rem 1rem;
}

.modal .modal-dialog .modal-content .modal-header .close span {
  font-size: 20px;
  font-weight: 400;
  color: #6a6a6a;
}

.modal .modal-dialog .modal-content .modal-body {
  padding: 1rem 1rem;
  font-weight: 300;
  color: #3f3f3f;
}

.modal .modal-dialog .modal-content .modal-footer {
  padding: 1rem 1rem;
}

.pagination,
.jsgrid .jsgrid-pager {
  font-weight: 300;
}

.pagination .page-item:first-child .page-link,
.jsgrid .jsgrid-pager .page-item:first-child .page-link,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button:first-child .page-link,
.jsgrid .jsgrid-pager .jsgrid-pager-page:first-child .page-link,
.pagination
  .page-item:first-child
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid .jsgrid-pager .page-item:first-child .jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button .page-item:first-child a,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button:first-child a,
.pagination .page-item:first-child .jsgrid .jsgrid-pager .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .page-item:first-child .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .jsgrid-pager-page .page-item:first-child a,
.jsgrid .jsgrid-pager .jsgrid-pager-page:first-child a,
.pagination .page-item:last-child .page-link,
.jsgrid .jsgrid-pager .page-item:last-child .page-link,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button:last-child .page-link,
.jsgrid .jsgrid-pager .jsgrid-pager-page:last-child .page-link,
.pagination
  .page-item:last-child
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid .jsgrid-pager .page-item:last-child .jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button .page-item:last-child a,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button:last-child a,
.pagination .page-item:last-child .jsgrid .jsgrid-pager .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .page-item:last-child .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .jsgrid-pager-page .page-item:last-child a,
.jsgrid .jsgrid-pager .jsgrid-pager-page:last-child a {
  background-color: #ececec;
}

.pagination .page-item .page-link,
.jsgrid .jsgrid-pager .page-item .page-link,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button .page-link,
.jsgrid .jsgrid-pager .jsgrid-pager-page .page-link,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .jsgrid-pager-page a {
  background-color: #ffffff;
}

.pagination .page-item:hover a.page-link,
.jsgrid .jsgrid-pager .page-item:hover a.page-link,
.pagination .page-item:hover .jsgrid .jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .page-item:hover .jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button .page-item:hover a,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button:hover a,
.pagination .page-item:hover .jsgrid .jsgrid-pager .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .page-item:hover .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .jsgrid-pager-page .page-item:hover a,
.jsgrid .jsgrid-pager .jsgrid-pager-page:hover a,
.pagination .page-item.active a.page-link,
.jsgrid .jsgrid-pager .page-item.active a.page-link,
.pagination .page-item.active .jsgrid .jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .page-item.active .jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button .page-item.active a,
.jsgrid .jsgrid-pager .active.jsgrid-pager-nav-button a,
.pagination .page-item.active .jsgrid .jsgrid-pager .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .page-item.active .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .jsgrid-pager-page .page-item.active a,
.jsgrid .jsgrid-pager .active.jsgrid-pager-page a,
.pagination .page-item:focus a.page-link,
.jsgrid .jsgrid-pager .page-item:focus a.page-link,
.pagination .page-item:focus .jsgrid .jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .page-item:focus .jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button .page-item:focus a,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button:focus a,
.pagination .page-item:focus .jsgrid .jsgrid-pager .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .page-item:focus .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .jsgrid-pager-page .page-item:focus a,
.jsgrid .jsgrid-pager .jsgrid-pager-page:focus a {
  background-color: #ef3e27;
  border-color: #ef3e27;
  color: #fff;
}

.pagination .page-item .page-link,
.jsgrid .jsgrid-pager .page-item .page-link,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button .page-link,
.jsgrid .jsgrid-pager .jsgrid-pager-page .page-link,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .jsgrid-pager-page a {
  border-color: transparent;
  color: #323232;
}

.pagination .page-item .page-link:hover,
.jsgrid .jsgrid-pager .page-item .page-link:hover,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button .page-link:hover,
.jsgrid .jsgrid-pager .jsgrid-pager-page .page-link:hover,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button a:hover,
.jsgrid .jsgrid-pager .jsgrid-pager-page a:hover,
.pagination .page-item .page-link:active,
.jsgrid .jsgrid-pager .page-item .page-link:active,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button .page-link:active,
.jsgrid .jsgrid-pager .jsgrid-pager-page .page-link:active,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button a:active,
.jsgrid .jsgrid-pager .jsgrid-pager-page a:active,
.pagination .page-item .page-link:focus,
.jsgrid .jsgrid-pager .page-item .page-link:focus,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button .page-link:focus,
.jsgrid .jsgrid-pager .jsgrid-pager-page .page-link:focus,
.jsgrid .jsgrid-pager .jsgrid-pager-nav-button a:focus,
.jsgrid .jsgrid-pager .jsgrid-pager-page a:focus {
  color: #fff;
  background-color: #ef3e27;
  box-shadow: none;
}

.pagination.pagination-primary :first-child a,
.jsgrid .pagination-primary.jsgrid-pager :first-child a,
.pagination.pagination-primary :last-child a,
.jsgrid .pagination-primary.jsgrid-pager :last-child a {
  background-color: #ececec;
}

.pagination.pagination-primary .page-item .page-link,
.jsgrid .pagination-primary.jsgrid-pager .page-item .page-link,
.pagination.pagination-primary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-primary
  .jsgrid-pager-nav-button
  .page-link,
.jsgrid .pagination-primary.jsgrid-pager .jsgrid-pager-nav-button .page-link,
.pagination.pagination-primary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-primary
  .jsgrid-pager-page
  .page-link,
.jsgrid .pagination-primary.jsgrid-pager .jsgrid-pager-page .page-link,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-primary
  .page-item
  a,
.pagination.pagination-primary .jsgrid .jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .pagination.pagination-primary .jsgrid-pager-nav-button a,
.jsgrid .pagination-primary.jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-primary
  .page-item
  a,
.pagination.pagination-primary .jsgrid .jsgrid-pager .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .pagination.pagination-primary .jsgrid-pager-page a,
.jsgrid .pagination-primary.jsgrid-pager .jsgrid-pager-page a {
  color: #323232;
  background-color: #ffffff;
}

.pagination.pagination-primary .page-item .page-link:hover,
.jsgrid .pagination-primary.jsgrid-pager .page-item .page-link:hover,
.pagination.pagination-primary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:hover,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-primary
  .jsgrid-pager-nav-button
  .page-link:hover,
.jsgrid
  .pagination-primary.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:hover,
.pagination.pagination-primary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .page-link:hover,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-primary
  .jsgrid-pager-page
  .page-link:hover,
.jsgrid .pagination-primary.jsgrid-pager .jsgrid-pager-page .page-link:hover,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-primary
  .page-item
  a:hover,
.pagination.pagination-primary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a:hover,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-primary
  .jsgrid-pager-nav-button
  a:hover,
.jsgrid .pagination-primary.jsgrid-pager .jsgrid-pager-nav-button a:hover,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-primary
  .page-item
  a:hover,
.pagination.pagination-primary .jsgrid .jsgrid-pager .jsgrid-pager-page a:hover,
.jsgrid .jsgrid-pager .pagination.pagination-primary .jsgrid-pager-page a:hover,
.jsgrid .pagination-primary.jsgrid-pager .jsgrid-pager-page a:hover,
.pagination.pagination-primary .page-item .page-link:active,
.jsgrid .pagination-primary.jsgrid-pager .page-item .page-link:active,
.pagination.pagination-primary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:active,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-primary
  .jsgrid-pager-nav-button
  .page-link:active,
.jsgrid
  .pagination-primary.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:active,
.pagination.pagination-primary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .page-link:active,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-primary
  .jsgrid-pager-page
  .page-link:active,
.jsgrid .pagination-primary.jsgrid-pager .jsgrid-pager-page .page-link:active,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-primary
  .page-item
  a:active,
.pagination.pagination-primary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a:active,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-primary
  .jsgrid-pager-nav-button
  a:active,
.jsgrid .pagination-primary.jsgrid-pager .jsgrid-pager-nav-button a:active,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-primary
  .page-item
  a:active,
.pagination.pagination-primary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a:active,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-primary
  .jsgrid-pager-page
  a:active,
.jsgrid .pagination-primary.jsgrid-pager .jsgrid-pager-page a:active,
.pagination.pagination-primary .page-item .page-link:focus,
.jsgrid .pagination-primary.jsgrid-pager .page-item .page-link:focus,
.pagination.pagination-primary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:focus,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-primary
  .jsgrid-pager-nav-button
  .page-link:focus,
.jsgrid
  .pagination-primary.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:focus,
.pagination.pagination-primary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .page-link:focus,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-primary
  .jsgrid-pager-page
  .page-link:focus,
.jsgrid .pagination-primary.jsgrid-pager .jsgrid-pager-page .page-link:focus,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-primary
  .page-item
  a:focus,
.pagination.pagination-primary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a:focus,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-primary
  .jsgrid-pager-nav-button
  a:focus,
.jsgrid .pagination-primary.jsgrid-pager .jsgrid-pager-nav-button a:focus,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-primary
  .page-item
  a:focus,
.pagination.pagination-primary .jsgrid .jsgrid-pager .jsgrid-pager-page a:focus,
.jsgrid .jsgrid-pager .pagination.pagination-primary .jsgrid-pager-page a:focus,
.jsgrid .pagination-primary.jsgrid-pager .jsgrid-pager-page a:focus {
  color: #fff;
  background-color: #ef3e27;
}

.pagination.pagination-primary .page-item:hover .page-link,
.jsgrid .pagination-primary.jsgrid-pager .page-item:hover .page-link,
.pagination.pagination-primary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:hover
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-primary
  .jsgrid-pager-nav-button:hover
  .page-link,
.jsgrid
  .pagination-primary.jsgrid-pager
  .jsgrid-pager-nav-button:hover
  .page-link,
.pagination.pagination-primary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page:hover
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-primary
  .jsgrid-pager-page:hover
  .page-link,
.jsgrid .pagination-primary.jsgrid-pager .jsgrid-pager-page:hover .page-link,
.pagination.pagination-primary
  .page-item:hover
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-primary
  .page-item:hover
  a,
.jsgrid
  .pagination-primary.jsgrid-pager
  .page-item:hover
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .pagination-primary.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-item:hover
  a,
.pagination.pagination-primary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:hover
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-primary
  .jsgrid-pager-nav-button:hover
  a,
.jsgrid .pagination-primary.jsgrid-pager .jsgrid-pager-nav-button:hover a,
.pagination.pagination-primary
  .page-item:hover
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-primary
  .page-item:hover
  a,
.jsgrid .pagination-primary.jsgrid-pager .page-item:hover .jsgrid-pager-page a,
.jsgrid .pagination-primary.jsgrid-pager .jsgrid-pager-page .page-item:hover a,
.pagination.pagination-primary .jsgrid .jsgrid-pager .jsgrid-pager-page:hover a,
.jsgrid .jsgrid-pager .pagination.pagination-primary .jsgrid-pager-page:hover a,
.jsgrid .pagination-primary.jsgrid-pager .jsgrid-pager-page:hover a,
.pagination.pagination-primary .page-item.active .page-link,
.jsgrid .pagination-primary.jsgrid-pager .page-item.active .page-link,
.pagination.pagination-primary
  .jsgrid
  .jsgrid-pager
  .active.jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-primary
  .active.jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .pagination-primary.jsgrid-pager
  .active.jsgrid-pager-nav-button
  .page-link,
.pagination.pagination-primary
  .jsgrid
  .jsgrid-pager
  .active.jsgrid-pager-page
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-primary
  .active.jsgrid-pager-page
  .page-link,
.jsgrid .pagination-primary.jsgrid-pager .active.jsgrid-pager-page .page-link,
.pagination.pagination-primary
  .page-item.active
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-primary
  .page-item.active
  a,
.jsgrid
  .pagination-primary.jsgrid-pager
  .page-item.active
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .pagination-primary.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-item.active
  a,
.pagination.pagination-primary
  .jsgrid
  .jsgrid-pager
  .active.jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-primary
  .active.jsgrid-pager-nav-button
  a,
.jsgrid .pagination-primary.jsgrid-pager .active.jsgrid-pager-nav-button a,
.pagination.pagination-primary
  .page-item.active
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-primary
  .page-item.active
  a,
.jsgrid .pagination-primary.jsgrid-pager .page-item.active .jsgrid-pager-page a,
.jsgrid .pagination-primary.jsgrid-pager .jsgrid-pager-page .page-item.active a,
.pagination.pagination-primary
  .jsgrid
  .jsgrid-pager
  .active.jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-primary
  .active.jsgrid-pager-page
  a,
.jsgrid .pagination-primary.jsgrid-pager .active.jsgrid-pager-page a,
.pagination.pagination-primary .page-item:focus .page-link,
.jsgrid .pagination-primary.jsgrid-pager .page-item:focus .page-link,
.pagination.pagination-primary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:focus
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-primary
  .jsgrid-pager-nav-button:focus
  .page-link,
.jsgrid
  .pagination-primary.jsgrid-pager
  .jsgrid-pager-nav-button:focus
  .page-link,
.pagination.pagination-primary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page:focus
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-primary
  .jsgrid-pager-page:focus
  .page-link,
.jsgrid .pagination-primary.jsgrid-pager .jsgrid-pager-page:focus .page-link,
.pagination.pagination-primary
  .page-item:focus
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-primary
  .page-item:focus
  a,
.jsgrid
  .pagination-primary.jsgrid-pager
  .page-item:focus
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .pagination-primary.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-item:focus
  a,
.pagination.pagination-primary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:focus
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-primary
  .jsgrid-pager-nav-button:focus
  a,
.jsgrid .pagination-primary.jsgrid-pager .jsgrid-pager-nav-button:focus a,
.pagination.pagination-primary
  .page-item:focus
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-primary
  .page-item:focus
  a,
.jsgrid .pagination-primary.jsgrid-pager .page-item:focus .jsgrid-pager-page a,
.jsgrid .pagination-primary.jsgrid-pager .jsgrid-pager-page .page-item:focus a,
.pagination.pagination-primary .jsgrid .jsgrid-pager .jsgrid-pager-page:focus a,
.jsgrid .jsgrid-pager .pagination.pagination-primary .jsgrid-pager-page:focus a,
.jsgrid .pagination-primary.jsgrid-pager .jsgrid-pager-page:focus a {
  background-color: #ef3e27;
  border-color: #ef3e27;
  color: #fff;
}

.pagination.pagination-border-primary .page-item .page-link,
.jsgrid .pagination-border-primary.jsgrid-pager .page-item .page-link,
.pagination.pagination-border-primary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-border-primary
  .jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .pagination-border-primary.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link,
.pagination.pagination-border-primary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-border-primary
  .jsgrid-pager-page
  .page-link,
.jsgrid .pagination-border-primary.jsgrid-pager .jsgrid-pager-page .page-link,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-border-primary
  .page-item
  a,
.pagination.pagination-border-primary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-border-primary
  .jsgrid-pager-nav-button
  a,
.jsgrid .pagination-border-primary.jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-border-primary
  .page-item
  a,
.pagination.pagination-border-primary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-border-primary
  .jsgrid-pager-page
  a,
.jsgrid .pagination-border-primary.jsgrid-pager .jsgrid-pager-page a {
  border-color: #ef3e27;
}

.pagination.pagination-secondary :first-child a,
.jsgrid .pagination-secondary.jsgrid-pager :first-child a,
.pagination.pagination-secondary :last-child a,
.jsgrid .pagination-secondary.jsgrid-pager :last-child a {
  background-color: #f6f8fa;
}

.pagination.pagination-secondary .page-item .page-link,
.jsgrid .pagination-secondary.jsgrid-pager .page-item .page-link,
.pagination.pagination-secondary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-secondary
  .jsgrid-pager-nav-button
  .page-link,
.jsgrid .pagination-secondary.jsgrid-pager .jsgrid-pager-nav-button .page-link,
.pagination.pagination-secondary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-secondary
  .jsgrid-pager-page
  .page-link,
.jsgrid .pagination-secondary.jsgrid-pager .jsgrid-pager-page .page-link,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-secondary
  .page-item
  a,
.pagination.pagination-secondary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-secondary
  .jsgrid-pager-nav-button
  a,
.jsgrid .pagination-secondary.jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-secondary
  .page-item
  a,
.pagination.pagination-secondary .jsgrid .jsgrid-pager .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .pagination.pagination-secondary .jsgrid-pager-page a,
.jsgrid .pagination-secondary.jsgrid-pager .jsgrid-pager-page a {
  color: #bcbec7;
  background-color: #ffffff;
}

.pagination.pagination-secondary .page-item .page-link:hover,
.jsgrid .pagination-secondary.jsgrid-pager .page-item .page-link:hover,
.pagination.pagination-secondary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:hover,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-secondary
  .jsgrid-pager-nav-button
  .page-link:hover,
.jsgrid
  .pagination-secondary.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:hover,
.pagination.pagination-secondary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .page-link:hover,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-secondary
  .jsgrid-pager-page
  .page-link:hover,
.jsgrid .pagination-secondary.jsgrid-pager .jsgrid-pager-page .page-link:hover,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-secondary
  .page-item
  a:hover,
.pagination.pagination-secondary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a:hover,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-secondary
  .jsgrid-pager-nav-button
  a:hover,
.jsgrid .pagination-secondary.jsgrid-pager .jsgrid-pager-nav-button a:hover,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-secondary
  .page-item
  a:hover,
.pagination.pagination-secondary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a:hover,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-secondary
  .jsgrid-pager-page
  a:hover,
.jsgrid .pagination-secondary.jsgrid-pager .jsgrid-pager-page a:hover,
.pagination.pagination-secondary .page-item .page-link:active,
.jsgrid .pagination-secondary.jsgrid-pager .page-item .page-link:active,
.pagination.pagination-secondary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:active,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-secondary
  .jsgrid-pager-nav-button
  .page-link:active,
.jsgrid
  .pagination-secondary.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:active,
.pagination.pagination-secondary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .page-link:active,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-secondary
  .jsgrid-pager-page
  .page-link:active,
.jsgrid .pagination-secondary.jsgrid-pager .jsgrid-pager-page .page-link:active,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-secondary
  .page-item
  a:active,
.pagination.pagination-secondary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a:active,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-secondary
  .jsgrid-pager-nav-button
  a:active,
.jsgrid .pagination-secondary.jsgrid-pager .jsgrid-pager-nav-button a:active,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-secondary
  .page-item
  a:active,
.pagination.pagination-secondary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a:active,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-secondary
  .jsgrid-pager-page
  a:active,
.jsgrid .pagination-secondary.jsgrid-pager .jsgrid-pager-page a:active,
.pagination.pagination-secondary .page-item .page-link:focus,
.jsgrid .pagination-secondary.jsgrid-pager .page-item .page-link:focus,
.pagination.pagination-secondary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:focus,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-secondary
  .jsgrid-pager-nav-button
  .page-link:focus,
.jsgrid
  .pagination-secondary.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:focus,
.pagination.pagination-secondary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .page-link:focus,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-secondary
  .jsgrid-pager-page
  .page-link:focus,
.jsgrid .pagination-secondary.jsgrid-pager .jsgrid-pager-page .page-link:focus,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-secondary
  .page-item
  a:focus,
.pagination.pagination-secondary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a:focus,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-secondary
  .jsgrid-pager-nav-button
  a:focus,
.jsgrid .pagination-secondary.jsgrid-pager .jsgrid-pager-nav-button a:focus,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-secondary
  .page-item
  a:focus,
.pagination.pagination-secondary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a:focus,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-secondary
  .jsgrid-pager-page
  a:focus,
.jsgrid .pagination-secondary.jsgrid-pager .jsgrid-pager-page a:focus {
  color: #fff;
  background-color: #d3dae6;
}

.pagination.pagination-secondary .page-item:hover .page-link,
.jsgrid .pagination-secondary.jsgrid-pager .page-item:hover .page-link,
.pagination.pagination-secondary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:hover
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-secondary
  .jsgrid-pager-nav-button:hover
  .page-link,
.jsgrid
  .pagination-secondary.jsgrid-pager
  .jsgrid-pager-nav-button:hover
  .page-link,
.pagination.pagination-secondary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page:hover
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-secondary
  .jsgrid-pager-page:hover
  .page-link,
.jsgrid .pagination-secondary.jsgrid-pager .jsgrid-pager-page:hover .page-link,
.pagination.pagination-secondary
  .page-item:hover
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-secondary
  .page-item:hover
  a,
.jsgrid
  .pagination-secondary.jsgrid-pager
  .page-item:hover
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .pagination-secondary.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-item:hover
  a,
.pagination.pagination-secondary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:hover
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-secondary
  .jsgrid-pager-nav-button:hover
  a,
.jsgrid .pagination-secondary.jsgrid-pager .jsgrid-pager-nav-button:hover a,
.pagination.pagination-secondary
  .page-item:hover
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-secondary
  .page-item:hover
  a,
.jsgrid
  .pagination-secondary.jsgrid-pager
  .page-item:hover
  .jsgrid-pager-page
  a,
.jsgrid
  .pagination-secondary.jsgrid-pager
  .jsgrid-pager-page
  .page-item:hover
  a,
.pagination.pagination-secondary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page:hover
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-secondary
  .jsgrid-pager-page:hover
  a,
.jsgrid .pagination-secondary.jsgrid-pager .jsgrid-pager-page:hover a,
.pagination.pagination-secondary .page-item.active .page-link,
.jsgrid .pagination-secondary.jsgrid-pager .page-item.active .page-link,
.pagination.pagination-secondary
  .jsgrid
  .jsgrid-pager
  .active.jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-secondary
  .active.jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .pagination-secondary.jsgrid-pager
  .active.jsgrid-pager-nav-button
  .page-link,
.pagination.pagination-secondary
  .jsgrid
  .jsgrid-pager
  .active.jsgrid-pager-page
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-secondary
  .active.jsgrid-pager-page
  .page-link,
.jsgrid .pagination-secondary.jsgrid-pager .active.jsgrid-pager-page .page-link,
.pagination.pagination-secondary
  .page-item.active
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-secondary
  .page-item.active
  a,
.jsgrid
  .pagination-secondary.jsgrid-pager
  .page-item.active
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .pagination-secondary.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-item.active
  a,
.pagination.pagination-secondary
  .jsgrid
  .jsgrid-pager
  .active.jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-secondary
  .active.jsgrid-pager-nav-button
  a,
.jsgrid .pagination-secondary.jsgrid-pager .active.jsgrid-pager-nav-button a,
.pagination.pagination-secondary
  .page-item.active
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-secondary
  .page-item.active
  a,
.jsgrid
  .pagination-secondary.jsgrid-pager
  .page-item.active
  .jsgrid-pager-page
  a,
.jsgrid
  .pagination-secondary.jsgrid-pager
  .jsgrid-pager-page
  .page-item.active
  a,
.pagination.pagination-secondary
  .jsgrid
  .jsgrid-pager
  .active.jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-secondary
  .active.jsgrid-pager-page
  a,
.jsgrid .pagination-secondary.jsgrid-pager .active.jsgrid-pager-page a,
.pagination.pagination-secondary .page-item:focus .page-link,
.jsgrid .pagination-secondary.jsgrid-pager .page-item:focus .page-link,
.pagination.pagination-secondary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:focus
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-secondary
  .jsgrid-pager-nav-button:focus
  .page-link,
.jsgrid
  .pagination-secondary.jsgrid-pager
  .jsgrid-pager-nav-button:focus
  .page-link,
.pagination.pagination-secondary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page:focus
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-secondary
  .jsgrid-pager-page:focus
  .page-link,
.jsgrid .pagination-secondary.jsgrid-pager .jsgrid-pager-page:focus .page-link,
.pagination.pagination-secondary
  .page-item:focus
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-secondary
  .page-item:focus
  a,
.jsgrid
  .pagination-secondary.jsgrid-pager
  .page-item:focus
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .pagination-secondary.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-item:focus
  a,
.pagination.pagination-secondary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:focus
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-secondary
  .jsgrid-pager-nav-button:focus
  a,
.jsgrid .pagination-secondary.jsgrid-pager .jsgrid-pager-nav-button:focus a,
.pagination.pagination-secondary
  .page-item:focus
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-secondary
  .page-item:focus
  a,
.jsgrid
  .pagination-secondary.jsgrid-pager
  .page-item:focus
  .jsgrid-pager-page
  a,
.jsgrid
  .pagination-secondary.jsgrid-pager
  .jsgrid-pager-page
  .page-item:focus
  a,
.pagination.pagination-secondary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page:focus
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-secondary
  .jsgrid-pager-page:focus
  a,
.jsgrid .pagination-secondary.jsgrid-pager .jsgrid-pager-page:focus a {
  background-color: #d3dae6;
  border-color: #d3dae6;
  color: #fff;
}

.pagination.pagination-border-secondary .page-item .page-link,
.jsgrid .pagination-border-secondary.jsgrid-pager .page-item .page-link,
.pagination.pagination-border-secondary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-border-secondary
  .jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .pagination-border-secondary.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link,
.pagination.pagination-border-secondary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-border-secondary
  .jsgrid-pager-page
  .page-link,
.jsgrid .pagination-border-secondary.jsgrid-pager .jsgrid-pager-page .page-link,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-border-secondary
  .page-item
  a,
.pagination.pagination-border-secondary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-border-secondary
  .jsgrid-pager-nav-button
  a,
.jsgrid .pagination-border-secondary.jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-border-secondary
  .page-item
  a,
.pagination.pagination-border-secondary
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-border-secondary
  .jsgrid-pager-page
  a,
.jsgrid .pagination-border-secondary.jsgrid-pager .jsgrid-pager-page a {
  border-color: #d3dae6;
}

.pagination.pagination-success :first-child a,
.jsgrid .pagination-success.jsgrid-pager :first-child a,
.pagination.pagination-success :last-child a,
.jsgrid .pagination-success.jsgrid-pager :last-child a {
  background-color: #ccf5e8;
}

.pagination.pagination-success .page-item .page-link,
.jsgrid .pagination-success.jsgrid-pager .page-item .page-link,
.pagination.pagination-success
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-success
  .jsgrid-pager-nav-button
  .page-link,
.jsgrid .pagination-success.jsgrid-pager .jsgrid-pager-nav-button .page-link,
.pagination.pagination-success
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-success
  .jsgrid-pager-page
  .page-link,
.jsgrid .pagination-success.jsgrid-pager .jsgrid-pager-page .page-link,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-success
  .page-item
  a,
.pagination.pagination-success .jsgrid .jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .pagination.pagination-success .jsgrid-pager-nav-button a,
.jsgrid .pagination-success.jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-success
  .page-item
  a,
.pagination.pagination-success .jsgrid .jsgrid-pager .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .pagination.pagination-success .jsgrid-pager-page a,
.jsgrid .pagination-success.jsgrid-pager .jsgrid-pager-page a {
  color: #72b9a9;
  background-color: #ffffff;
}

.pagination.pagination-success .page-item .page-link:hover,
.jsgrid .pagination-success.jsgrid-pager .page-item .page-link:hover,
.pagination.pagination-success
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:hover,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-success
  .jsgrid-pager-nav-button
  .page-link:hover,
.jsgrid
  .pagination-success.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:hover,
.pagination.pagination-success
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .page-link:hover,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-success
  .jsgrid-pager-page
  .page-link:hover,
.jsgrid .pagination-success.jsgrid-pager .jsgrid-pager-page .page-link:hover,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-success
  .page-item
  a:hover,
.pagination.pagination-success
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a:hover,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-success
  .jsgrid-pager-nav-button
  a:hover,
.jsgrid .pagination-success.jsgrid-pager .jsgrid-pager-nav-button a:hover,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-success
  .page-item
  a:hover,
.pagination.pagination-success .jsgrid .jsgrid-pager .jsgrid-pager-page a:hover,
.jsgrid .jsgrid-pager .pagination.pagination-success .jsgrid-pager-page a:hover,
.jsgrid .pagination-success.jsgrid-pager .jsgrid-pager-page a:hover,
.pagination.pagination-success .page-item .page-link:active,
.jsgrid .pagination-success.jsgrid-pager .page-item .page-link:active,
.pagination.pagination-success
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:active,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-success
  .jsgrid-pager-nav-button
  .page-link:active,
.jsgrid
  .pagination-success.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:active,
.pagination.pagination-success
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .page-link:active,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-success
  .jsgrid-pager-page
  .page-link:active,
.jsgrid .pagination-success.jsgrid-pager .jsgrid-pager-page .page-link:active,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-success
  .page-item
  a:active,
.pagination.pagination-success
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a:active,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-success
  .jsgrid-pager-nav-button
  a:active,
.jsgrid .pagination-success.jsgrid-pager .jsgrid-pager-nav-button a:active,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-success
  .page-item
  a:active,
.pagination.pagination-success
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a:active,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-success
  .jsgrid-pager-page
  a:active,
.jsgrid .pagination-success.jsgrid-pager .jsgrid-pager-page a:active,
.pagination.pagination-success .page-item .page-link:focus,
.jsgrid .pagination-success.jsgrid-pager .page-item .page-link:focus,
.pagination.pagination-success
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:focus,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-success
  .jsgrid-pager-nav-button
  .page-link:focus,
.jsgrid
  .pagination-success.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:focus,
.pagination.pagination-success
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .page-link:focus,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-success
  .jsgrid-pager-page
  .page-link:focus,
.jsgrid .pagination-success.jsgrid-pager .jsgrid-pager-page .page-link:focus,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-success
  .page-item
  a:focus,
.pagination.pagination-success
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a:focus,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-success
  .jsgrid-pager-nav-button
  a:focus,
.jsgrid .pagination-success.jsgrid-pager .jsgrid-pager-nav-button a:focus,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-success
  .page-item
  a:focus,
.pagination.pagination-success .jsgrid .jsgrid-pager .jsgrid-pager-page a:focus,
.jsgrid .jsgrid-pager .pagination.pagination-success .jsgrid-pager-page a:focus,
.jsgrid .pagination-success.jsgrid-pager .jsgrid-pager-page a:focus {
  color: #fff;
  background-color: #00cb8e;
}

.pagination.pagination-success .page-item:hover .page-link,
.jsgrid .pagination-success.jsgrid-pager .page-item:hover .page-link,
.pagination.pagination-success
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:hover
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-success
  .jsgrid-pager-nav-button:hover
  .page-link,
.jsgrid
  .pagination-success.jsgrid-pager
  .jsgrid-pager-nav-button:hover
  .page-link,
.pagination.pagination-success
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page:hover
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-success
  .jsgrid-pager-page:hover
  .page-link,
.jsgrid .pagination-success.jsgrid-pager .jsgrid-pager-page:hover .page-link,
.pagination.pagination-success
  .page-item:hover
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-success
  .page-item:hover
  a,
.jsgrid
  .pagination-success.jsgrid-pager
  .page-item:hover
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .pagination-success.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-item:hover
  a,
.pagination.pagination-success
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:hover
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-success
  .jsgrid-pager-nav-button:hover
  a,
.jsgrid .pagination-success.jsgrid-pager .jsgrid-pager-nav-button:hover a,
.pagination.pagination-success
  .page-item:hover
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-success
  .page-item:hover
  a,
.jsgrid .pagination-success.jsgrid-pager .page-item:hover .jsgrid-pager-page a,
.jsgrid .pagination-success.jsgrid-pager .jsgrid-pager-page .page-item:hover a,
.pagination.pagination-success .jsgrid .jsgrid-pager .jsgrid-pager-page:hover a,
.jsgrid .jsgrid-pager .pagination.pagination-success .jsgrid-pager-page:hover a,
.jsgrid .pagination-success.jsgrid-pager .jsgrid-pager-page:hover a,
.pagination.pagination-success .page-item.active .page-link,
.jsgrid .pagination-success.jsgrid-pager .page-item.active .page-link,
.pagination.pagination-success
  .jsgrid
  .jsgrid-pager
  .active.jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-success
  .active.jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .pagination-success.jsgrid-pager
  .active.jsgrid-pager-nav-button
  .page-link,
.pagination.pagination-success
  .jsgrid
  .jsgrid-pager
  .active.jsgrid-pager-page
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-success
  .active.jsgrid-pager-page
  .page-link,
.jsgrid .pagination-success.jsgrid-pager .active.jsgrid-pager-page .page-link,
.pagination.pagination-success
  .page-item.active
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-success
  .page-item.active
  a,
.jsgrid
  .pagination-success.jsgrid-pager
  .page-item.active
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .pagination-success.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-item.active
  a,
.pagination.pagination-success
  .jsgrid
  .jsgrid-pager
  .active.jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-success
  .active.jsgrid-pager-nav-button
  a,
.jsgrid .pagination-success.jsgrid-pager .active.jsgrid-pager-nav-button a,
.pagination.pagination-success
  .page-item.active
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-success
  .page-item.active
  a,
.jsgrid .pagination-success.jsgrid-pager .page-item.active .jsgrid-pager-page a,
.jsgrid .pagination-success.jsgrid-pager .jsgrid-pager-page .page-item.active a,
.pagination.pagination-success
  .jsgrid
  .jsgrid-pager
  .active.jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-success
  .active.jsgrid-pager-page
  a,
.jsgrid .pagination-success.jsgrid-pager .active.jsgrid-pager-page a,
.pagination.pagination-success .page-item:focus .page-link,
.jsgrid .pagination-success.jsgrid-pager .page-item:focus .page-link,
.pagination.pagination-success
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:focus
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-success
  .jsgrid-pager-nav-button:focus
  .page-link,
.jsgrid
  .pagination-success.jsgrid-pager
  .jsgrid-pager-nav-button:focus
  .page-link,
.pagination.pagination-success
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page:focus
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-success
  .jsgrid-pager-page:focus
  .page-link,
.jsgrid .pagination-success.jsgrid-pager .jsgrid-pager-page:focus .page-link,
.pagination.pagination-success
  .page-item:focus
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-success
  .page-item:focus
  a,
.jsgrid
  .pagination-success.jsgrid-pager
  .page-item:focus
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .pagination-success.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-item:focus
  a,
.pagination.pagination-success
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:focus
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-success
  .jsgrid-pager-nav-button:focus
  a,
.jsgrid .pagination-success.jsgrid-pager .jsgrid-pager-nav-button:focus a,
.pagination.pagination-success
  .page-item:focus
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-success
  .page-item:focus
  a,
.jsgrid .pagination-success.jsgrid-pager .page-item:focus .jsgrid-pager-page a,
.jsgrid .pagination-success.jsgrid-pager .jsgrid-pager-page .page-item:focus a,
.pagination.pagination-success .jsgrid .jsgrid-pager .jsgrid-pager-page:focus a,
.jsgrid .jsgrid-pager .pagination.pagination-success .jsgrid-pager-page:focus a,
.jsgrid .pagination-success.jsgrid-pager .jsgrid-pager-page:focus a {
  background-color: #00cb8e;
  border-color: #00cb8e;
  color: #fff;
}

.pagination.pagination-border-success .page-item .page-link,
.jsgrid .pagination-border-success.jsgrid-pager .page-item .page-link,
.pagination.pagination-border-success
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-border-success
  .jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .pagination-border-success.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link,
.pagination.pagination-border-success
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-border-success
  .jsgrid-pager-page
  .page-link,
.jsgrid .pagination-border-success.jsgrid-pager .jsgrid-pager-page .page-link,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-border-success
  .page-item
  a,
.pagination.pagination-border-success
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-border-success
  .jsgrid-pager-nav-button
  a,
.jsgrid .pagination-border-success.jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-border-success
  .page-item
  a,
.pagination.pagination-border-success
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-border-success
  .jsgrid-pager-page
  a,
.jsgrid .pagination-border-success.jsgrid-pager .jsgrid-pager-page a {
  border-color: #00cb8e;
}

.pagination.pagination-info :first-child a,
.jsgrid .pagination-info.jsgrid-pager :first-child a,
.pagination.pagination-info :last-child a,
.jsgrid .pagination-info.jsgrid-pager :last-child a {
  background-color: #cceeff;
}

.pagination.pagination-info .page-item .page-link,
.jsgrid .pagination-info.jsgrid-pager .page-item .page-link,
.pagination.pagination-info
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-info
  .jsgrid-pager-nav-button
  .page-link,
.jsgrid .pagination-info.jsgrid-pager .jsgrid-pager-nav-button .page-link,
.pagination.pagination-info .jsgrid .jsgrid-pager .jsgrid-pager-page .page-link,
.jsgrid .jsgrid-pager .pagination.pagination-info .jsgrid-pager-page .page-link,
.jsgrid .pagination-info.jsgrid-pager .jsgrid-pager-page .page-link,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-info
  .page-item
  a,
.pagination.pagination-info .jsgrid .jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .pagination.pagination-info .jsgrid-pager-nav-button a,
.jsgrid .pagination-info.jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-info
  .page-item
  a,
.pagination.pagination-info .jsgrid .jsgrid-pager .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .pagination.pagination-info .jsgrid-pager-page a,
.jsgrid .pagination-info.jsgrid-pager .jsgrid-pager-page a {
  color: #72add0;
  background-color: #ffffff;
}

.pagination.pagination-info .page-item .page-link:hover,
.jsgrid .pagination-info.jsgrid-pager .page-item .page-link:hover,
.pagination.pagination-info
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:hover,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-info
  .jsgrid-pager-nav-button
  .page-link:hover,
.jsgrid .pagination-info.jsgrid-pager .jsgrid-pager-nav-button .page-link:hover,
.pagination.pagination-info
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .page-link:hover,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-info
  .jsgrid-pager-page
  .page-link:hover,
.jsgrid .pagination-info.jsgrid-pager .jsgrid-pager-page .page-link:hover,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-info
  .page-item
  a:hover,
.pagination.pagination-info
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a:hover,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-info
  .jsgrid-pager-nav-button
  a:hover,
.jsgrid .pagination-info.jsgrid-pager .jsgrid-pager-nav-button a:hover,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-info
  .page-item
  a:hover,
.pagination.pagination-info .jsgrid .jsgrid-pager .jsgrid-pager-page a:hover,
.jsgrid .jsgrid-pager .pagination.pagination-info .jsgrid-pager-page a:hover,
.jsgrid .pagination-info.jsgrid-pager .jsgrid-pager-page a:hover,
.pagination.pagination-info .page-item .page-link:active,
.jsgrid .pagination-info.jsgrid-pager .page-item .page-link:active,
.pagination.pagination-info
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:active,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-info
  .jsgrid-pager-nav-button
  .page-link:active,
.jsgrid
  .pagination-info.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:active,
.pagination.pagination-info
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .page-link:active,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-info
  .jsgrid-pager-page
  .page-link:active,
.jsgrid .pagination-info.jsgrid-pager .jsgrid-pager-page .page-link:active,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-info
  .page-item
  a:active,
.pagination.pagination-info
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a:active,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-info
  .jsgrid-pager-nav-button
  a:active,
.jsgrid .pagination-info.jsgrid-pager .jsgrid-pager-nav-button a:active,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-info
  .page-item
  a:active,
.pagination.pagination-info .jsgrid .jsgrid-pager .jsgrid-pager-page a:active,
.jsgrid .jsgrid-pager .pagination.pagination-info .jsgrid-pager-page a:active,
.jsgrid .pagination-info.jsgrid-pager .jsgrid-pager-page a:active,
.pagination.pagination-info .page-item .page-link:focus,
.jsgrid .pagination-info.jsgrid-pager .page-item .page-link:focus,
.pagination.pagination-info
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:focus,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-info
  .jsgrid-pager-nav-button
  .page-link:focus,
.jsgrid .pagination-info.jsgrid-pager .jsgrid-pager-nav-button .page-link:focus,
.pagination.pagination-info
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .page-link:focus,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-info
  .jsgrid-pager-page
  .page-link:focus,
.jsgrid .pagination-info.jsgrid-pager .jsgrid-pager-page .page-link:focus,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-info
  .page-item
  a:focus,
.pagination.pagination-info
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a:focus,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-info
  .jsgrid-pager-nav-button
  a:focus,
.jsgrid .pagination-info.jsgrid-pager .jsgrid-pager-nav-button a:focus,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-info
  .page-item
  a:focus,
.pagination.pagination-info .jsgrid .jsgrid-pager .jsgrid-pager-page a:focus,
.jsgrid .jsgrid-pager .pagination.pagination-info .jsgrid-pager-page a:focus,
.jsgrid .pagination-info.jsgrid-pager .jsgrid-pager-page a:focus {
  color: #fff;
  background-color: #00aaff;
}

.pagination.pagination-info .page-item:hover .page-link,
.jsgrid .pagination-info.jsgrid-pager .page-item:hover .page-link,
.pagination.pagination-info
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:hover
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-info
  .jsgrid-pager-nav-button:hover
  .page-link,
.jsgrid .pagination-info.jsgrid-pager .jsgrid-pager-nav-button:hover .page-link,
.pagination.pagination-info
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page:hover
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-info
  .jsgrid-pager-page:hover
  .page-link,
.jsgrid .pagination-info.jsgrid-pager .jsgrid-pager-page:hover .page-link,
.pagination.pagination-info
  .page-item:hover
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-info
  .page-item:hover
  a,
.jsgrid
  .pagination-info.jsgrid-pager
  .page-item:hover
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .pagination-info.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-item:hover
  a,
.pagination.pagination-info
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:hover
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-info
  .jsgrid-pager-nav-button:hover
  a,
.jsgrid .pagination-info.jsgrid-pager .jsgrid-pager-nav-button:hover a,
.pagination.pagination-info
  .page-item:hover
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-info
  .page-item:hover
  a,
.jsgrid .pagination-info.jsgrid-pager .page-item:hover .jsgrid-pager-page a,
.jsgrid .pagination-info.jsgrid-pager .jsgrid-pager-page .page-item:hover a,
.pagination.pagination-info .jsgrid .jsgrid-pager .jsgrid-pager-page:hover a,
.jsgrid .jsgrid-pager .pagination.pagination-info .jsgrid-pager-page:hover a,
.jsgrid .pagination-info.jsgrid-pager .jsgrid-pager-page:hover a,
.pagination.pagination-info .page-item.active .page-link,
.jsgrid .pagination-info.jsgrid-pager .page-item.active .page-link,
.pagination.pagination-info
  .jsgrid
  .jsgrid-pager
  .active.jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-info
  .active.jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .pagination-info.jsgrid-pager
  .active.jsgrid-pager-nav-button
  .page-link,
.pagination.pagination-info
  .jsgrid
  .jsgrid-pager
  .active.jsgrid-pager-page
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-info
  .active.jsgrid-pager-page
  .page-link,
.jsgrid .pagination-info.jsgrid-pager .active.jsgrid-pager-page .page-link,
.pagination.pagination-info
  .page-item.active
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-info
  .page-item.active
  a,
.jsgrid
  .pagination-info.jsgrid-pager
  .page-item.active
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .pagination-info.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-item.active
  a,
.pagination.pagination-info
  .jsgrid
  .jsgrid-pager
  .active.jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-info
  .active.jsgrid-pager-nav-button
  a,
.jsgrid .pagination-info.jsgrid-pager .active.jsgrid-pager-nav-button a,
.pagination.pagination-info
  .page-item.active
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-info
  .page-item.active
  a,
.jsgrid .pagination-info.jsgrid-pager .page-item.active .jsgrid-pager-page a,
.jsgrid .pagination-info.jsgrid-pager .jsgrid-pager-page .page-item.active a,
.pagination.pagination-info .jsgrid .jsgrid-pager .active.jsgrid-pager-page a,
.jsgrid .jsgrid-pager .pagination.pagination-info .active.jsgrid-pager-page a,
.jsgrid .pagination-info.jsgrid-pager .active.jsgrid-pager-page a,
.pagination.pagination-info .page-item:focus .page-link,
.jsgrid .pagination-info.jsgrid-pager .page-item:focus .page-link,
.pagination.pagination-info
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:focus
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-info
  .jsgrid-pager-nav-button:focus
  .page-link,
.jsgrid .pagination-info.jsgrid-pager .jsgrid-pager-nav-button:focus .page-link,
.pagination.pagination-info
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page:focus
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-info
  .jsgrid-pager-page:focus
  .page-link,
.jsgrid .pagination-info.jsgrid-pager .jsgrid-pager-page:focus .page-link,
.pagination.pagination-info
  .page-item:focus
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-info
  .page-item:focus
  a,
.jsgrid
  .pagination-info.jsgrid-pager
  .page-item:focus
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .pagination-info.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-item:focus
  a,
.pagination.pagination-info
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:focus
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-info
  .jsgrid-pager-nav-button:focus
  a,
.jsgrid .pagination-info.jsgrid-pager .jsgrid-pager-nav-button:focus a,
.pagination.pagination-info
  .page-item:focus
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-info
  .page-item:focus
  a,
.jsgrid .pagination-info.jsgrid-pager .page-item:focus .jsgrid-pager-page a,
.jsgrid .pagination-info.jsgrid-pager .jsgrid-pager-page .page-item:focus a,
.pagination.pagination-info .jsgrid .jsgrid-pager .jsgrid-pager-page:focus a,
.jsgrid .jsgrid-pager .pagination.pagination-info .jsgrid-pager-page:focus a,
.jsgrid .pagination-info.jsgrid-pager .jsgrid-pager-page:focus a {
  background-color: #00aaff;
  border-color: #00aaff;
  color: #fff;
}

.pagination.pagination-border-info .page-item .page-link,
.jsgrid .pagination-border-info.jsgrid-pager .page-item .page-link,
.pagination.pagination-border-info
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-border-info
  .jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .pagination-border-info.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link,
.pagination.pagination-border-info
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-border-info
  .jsgrid-pager-page
  .page-link,
.jsgrid .pagination-border-info.jsgrid-pager .jsgrid-pager-page .page-link,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-border-info
  .page-item
  a,
.pagination.pagination-border-info
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-border-info
  .jsgrid-pager-nav-button
  a,
.jsgrid .pagination-border-info.jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-border-info
  .page-item
  a,
.pagination.pagination-border-info .jsgrid .jsgrid-pager .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .pagination.pagination-border-info .jsgrid-pager-page a,
.jsgrid .pagination-border-info.jsgrid-pager .jsgrid-pager-page a {
  border-color: #00aaff;
}

.pagination.pagination-warning :first-child a,
.jsgrid .pagination-warning.jsgrid-pager :first-child a,
.pagination.pagination-warning :last-child a,
.jsgrid .pagination-warning.jsgrid-pager :last-child a {
  background-color: #fff3dd;
}

.pagination.pagination-warning .page-item .page-link,
.jsgrid .pagination-warning.jsgrid-pager .page-item .page-link,
.pagination.pagination-warning
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-warning
  .jsgrid-pager-nav-button
  .page-link,
.jsgrid .pagination-warning.jsgrid-pager .jsgrid-pager-nav-button .page-link,
.pagination.pagination-warning
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-warning
  .jsgrid-pager-page
  .page-link,
.jsgrid .pagination-warning.jsgrid-pager .jsgrid-pager-page .page-link,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-warning
  .page-item
  a,
.pagination.pagination-warning .jsgrid .jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .pagination.pagination-warning .jsgrid-pager-nav-button a,
.jsgrid .pagination-warning.jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-warning
  .page-item
  a,
.pagination.pagination-warning .jsgrid .jsgrid-pager .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .pagination.pagination-warning .jsgrid-pager-page a,
.jsgrid .pagination-warning.jsgrid-pager .jsgrid-pager-page a {
  color: #ccb795;
  background-color: #ffffff;
}

.pagination.pagination-warning .page-item .page-link:hover,
.jsgrid .pagination-warning.jsgrid-pager .page-item .page-link:hover,
.pagination.pagination-warning
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:hover,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-warning
  .jsgrid-pager-nav-button
  .page-link:hover,
.jsgrid
  .pagination-warning.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:hover,
.pagination.pagination-warning
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .page-link:hover,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-warning
  .jsgrid-pager-page
  .page-link:hover,
.jsgrid .pagination-warning.jsgrid-pager .jsgrid-pager-page .page-link:hover,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-warning
  .page-item
  a:hover,
.pagination.pagination-warning
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a:hover,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-warning
  .jsgrid-pager-nav-button
  a:hover,
.jsgrid .pagination-warning.jsgrid-pager .jsgrid-pager-nav-button a:hover,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-warning
  .page-item
  a:hover,
.pagination.pagination-warning .jsgrid .jsgrid-pager .jsgrid-pager-page a:hover,
.jsgrid .jsgrid-pager .pagination.pagination-warning .jsgrid-pager-page a:hover,
.jsgrid .pagination-warning.jsgrid-pager .jsgrid-pager-page a:hover,
.pagination.pagination-warning .page-item .page-link:active,
.jsgrid .pagination-warning.jsgrid-pager .page-item .page-link:active,
.pagination.pagination-warning
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:active,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-warning
  .jsgrid-pager-nav-button
  .page-link:active,
.jsgrid
  .pagination-warning.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:active,
.pagination.pagination-warning
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .page-link:active,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-warning
  .jsgrid-pager-page
  .page-link:active,
.jsgrid .pagination-warning.jsgrid-pager .jsgrid-pager-page .page-link:active,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-warning
  .page-item
  a:active,
.pagination.pagination-warning
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a:active,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-warning
  .jsgrid-pager-nav-button
  a:active,
.jsgrid .pagination-warning.jsgrid-pager .jsgrid-pager-nav-button a:active,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-warning
  .page-item
  a:active,
.pagination.pagination-warning
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a:active,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-warning
  .jsgrid-pager-page
  a:active,
.jsgrid .pagination-warning.jsgrid-pager .jsgrid-pager-page a:active,
.pagination.pagination-warning .page-item .page-link:focus,
.jsgrid .pagination-warning.jsgrid-pager .page-item .page-link:focus,
.pagination.pagination-warning
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:focus,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-warning
  .jsgrid-pager-nav-button
  .page-link:focus,
.jsgrid
  .pagination-warning.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:focus,
.pagination.pagination-warning
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .page-link:focus,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-warning
  .jsgrid-pager-page
  .page-link:focus,
.jsgrid .pagination-warning.jsgrid-pager .jsgrid-pager-page .page-link:focus,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-warning
  .page-item
  a:focus,
.pagination.pagination-warning
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a:focus,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-warning
  .jsgrid-pager-nav-button
  a:focus,
.jsgrid .pagination-warning.jsgrid-pager .jsgrid-pager-nav-button a:focus,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-warning
  .page-item
  a:focus,
.pagination.pagination-warning .jsgrid .jsgrid-pager .jsgrid-pager-page a:focus,
.jsgrid .jsgrid-pager .pagination.pagination-warning .jsgrid-pager-page a:focus,
.jsgrid .pagination-warning.jsgrid-pager .jsgrid-pager-page a:focus {
  color: #fff;
  background-color: #ffc555;
}

.pagination.pagination-warning .page-item:hover .page-link,
.jsgrid .pagination-warning.jsgrid-pager .page-item:hover .page-link,
.pagination.pagination-warning
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:hover
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-warning
  .jsgrid-pager-nav-button:hover
  .page-link,
.jsgrid
  .pagination-warning.jsgrid-pager
  .jsgrid-pager-nav-button:hover
  .page-link,
.pagination.pagination-warning
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page:hover
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-warning
  .jsgrid-pager-page:hover
  .page-link,
.jsgrid .pagination-warning.jsgrid-pager .jsgrid-pager-page:hover .page-link,
.pagination.pagination-warning
  .page-item:hover
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-warning
  .page-item:hover
  a,
.jsgrid
  .pagination-warning.jsgrid-pager
  .page-item:hover
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .pagination-warning.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-item:hover
  a,
.pagination.pagination-warning
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:hover
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-warning
  .jsgrid-pager-nav-button:hover
  a,
.jsgrid .pagination-warning.jsgrid-pager .jsgrid-pager-nav-button:hover a,
.pagination.pagination-warning
  .page-item:hover
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-warning
  .page-item:hover
  a,
.jsgrid .pagination-warning.jsgrid-pager .page-item:hover .jsgrid-pager-page a,
.jsgrid .pagination-warning.jsgrid-pager .jsgrid-pager-page .page-item:hover a,
.pagination.pagination-warning .jsgrid .jsgrid-pager .jsgrid-pager-page:hover a,
.jsgrid .jsgrid-pager .pagination.pagination-warning .jsgrid-pager-page:hover a,
.jsgrid .pagination-warning.jsgrid-pager .jsgrid-pager-page:hover a,
.pagination.pagination-warning .page-item.active .page-link,
.jsgrid .pagination-warning.jsgrid-pager .page-item.active .page-link,
.pagination.pagination-warning
  .jsgrid
  .jsgrid-pager
  .active.jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-warning
  .active.jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .pagination-warning.jsgrid-pager
  .active.jsgrid-pager-nav-button
  .page-link,
.pagination.pagination-warning
  .jsgrid
  .jsgrid-pager
  .active.jsgrid-pager-page
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-warning
  .active.jsgrid-pager-page
  .page-link,
.jsgrid .pagination-warning.jsgrid-pager .active.jsgrid-pager-page .page-link,
.pagination.pagination-warning
  .page-item.active
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-warning
  .page-item.active
  a,
.jsgrid
  .pagination-warning.jsgrid-pager
  .page-item.active
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .pagination-warning.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-item.active
  a,
.pagination.pagination-warning
  .jsgrid
  .jsgrid-pager
  .active.jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-warning
  .active.jsgrid-pager-nav-button
  a,
.jsgrid .pagination-warning.jsgrid-pager .active.jsgrid-pager-nav-button a,
.pagination.pagination-warning
  .page-item.active
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-warning
  .page-item.active
  a,
.jsgrid .pagination-warning.jsgrid-pager .page-item.active .jsgrid-pager-page a,
.jsgrid .pagination-warning.jsgrid-pager .jsgrid-pager-page .page-item.active a,
.pagination.pagination-warning
  .jsgrid
  .jsgrid-pager
  .active.jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-warning
  .active.jsgrid-pager-page
  a,
.jsgrid .pagination-warning.jsgrid-pager .active.jsgrid-pager-page a,
.pagination.pagination-warning .page-item:focus .page-link,
.jsgrid .pagination-warning.jsgrid-pager .page-item:focus .page-link,
.pagination.pagination-warning
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:focus
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-warning
  .jsgrid-pager-nav-button:focus
  .page-link,
.jsgrid
  .pagination-warning.jsgrid-pager
  .jsgrid-pager-nav-button:focus
  .page-link,
.pagination.pagination-warning
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page:focus
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-warning
  .jsgrid-pager-page:focus
  .page-link,
.jsgrid .pagination-warning.jsgrid-pager .jsgrid-pager-page:focus .page-link,
.pagination.pagination-warning
  .page-item:focus
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-warning
  .page-item:focus
  a,
.jsgrid
  .pagination-warning.jsgrid-pager
  .page-item:focus
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .pagination-warning.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-item:focus
  a,
.pagination.pagination-warning
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:focus
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-warning
  .jsgrid-pager-nav-button:focus
  a,
.jsgrid .pagination-warning.jsgrid-pager .jsgrid-pager-nav-button:focus a,
.pagination.pagination-warning
  .page-item:focus
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-warning
  .page-item:focus
  a,
.jsgrid .pagination-warning.jsgrid-pager .page-item:focus .jsgrid-pager-page a,
.jsgrid .pagination-warning.jsgrid-pager .jsgrid-pager-page .page-item:focus a,
.pagination.pagination-warning .jsgrid .jsgrid-pager .jsgrid-pager-page:focus a,
.jsgrid .jsgrid-pager .pagination.pagination-warning .jsgrid-pager-page:focus a,
.jsgrid .pagination-warning.jsgrid-pager .jsgrid-pager-page:focus a {
  background-color: #ffc555;
  border-color: #ffc555;
  color: #fff;
}

.pagination.pagination-border-warning .page-item .page-link,
.jsgrid .pagination-border-warning.jsgrid-pager .page-item .page-link,
.pagination.pagination-border-warning
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-border-warning
  .jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .pagination-border-warning.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link,
.pagination.pagination-border-warning
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-border-warning
  .jsgrid-pager-page
  .page-link,
.jsgrid .pagination-border-warning.jsgrid-pager .jsgrid-pager-page .page-link,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-border-warning
  .page-item
  a,
.pagination.pagination-border-warning
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-border-warning
  .jsgrid-pager-nav-button
  a,
.jsgrid .pagination-border-warning.jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-border-warning
  .page-item
  a,
.pagination.pagination-border-warning
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-border-warning
  .jsgrid-pager-page
  a,
.jsgrid .pagination-border-warning.jsgrid-pager .jsgrid-pager-page a {
  border-color: #ffc555;
}

.pagination.pagination-danger :first-child a,
.jsgrid .pagination-danger.jsgrid-pager :first-child a,
.pagination.pagination-danger :last-child a,
.jsgrid .pagination-danger.jsgrid-pager :last-child a {
  background-color: #ffdde0;
}

.pagination.pagination-danger .page-item .page-link,
.jsgrid .pagination-danger.jsgrid-pager .page-item .page-link,
.pagination.pagination-danger
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-danger
  .jsgrid-pager-nav-button
  .page-link,
.jsgrid .pagination-danger.jsgrid-pager .jsgrid-pager-nav-button .page-link,
.pagination.pagination-danger
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-danger
  .jsgrid-pager-page
  .page-link,
.jsgrid .pagination-danger.jsgrid-pager .jsgrid-pager-page .page-link,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-danger
  .page-item
  a,
.pagination.pagination-danger .jsgrid .jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .pagination.pagination-danger .jsgrid-pager-nav-button a,
.jsgrid .pagination-danger.jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-danger
  .page-item
  a,
.pagination.pagination-danger .jsgrid .jsgrid-pager .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .pagination.pagination-danger .jsgrid-pager-page a,
.jsgrid .pagination-danger.jsgrid-pager .jsgrid-pager-page a {
  color: #cc909b;
  background-color: #ffffff;
}

.pagination.pagination-danger .page-item .page-link:hover,
.jsgrid .pagination-danger.jsgrid-pager .page-item .page-link:hover,
.pagination.pagination-danger
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:hover,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-danger
  .jsgrid-pager-nav-button
  .page-link:hover,
.jsgrid
  .pagination-danger.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:hover,
.pagination.pagination-danger
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .page-link:hover,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-danger
  .jsgrid-pager-page
  .page-link:hover,
.jsgrid .pagination-danger.jsgrid-pager .jsgrid-pager-page .page-link:hover,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-danger
  .page-item
  a:hover,
.pagination.pagination-danger
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a:hover,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-danger
  .jsgrid-pager-nav-button
  a:hover,
.jsgrid .pagination-danger.jsgrid-pager .jsgrid-pager-nav-button a:hover,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-danger
  .page-item
  a:hover,
.pagination.pagination-danger .jsgrid .jsgrid-pager .jsgrid-pager-page a:hover,
.jsgrid .jsgrid-pager .pagination.pagination-danger .jsgrid-pager-page a:hover,
.jsgrid .pagination-danger.jsgrid-pager .jsgrid-pager-page a:hover,
.pagination.pagination-danger .page-item .page-link:active,
.jsgrid .pagination-danger.jsgrid-pager .page-item .page-link:active,
.pagination.pagination-danger
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:active,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-danger
  .jsgrid-pager-nav-button
  .page-link:active,
.jsgrid
  .pagination-danger.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:active,
.pagination.pagination-danger
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .page-link:active,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-danger
  .jsgrid-pager-page
  .page-link:active,
.jsgrid .pagination-danger.jsgrid-pager .jsgrid-pager-page .page-link:active,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-danger
  .page-item
  a:active,
.pagination.pagination-danger
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a:active,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-danger
  .jsgrid-pager-nav-button
  a:active,
.jsgrid .pagination-danger.jsgrid-pager .jsgrid-pager-nav-button a:active,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-danger
  .page-item
  a:active,
.pagination.pagination-danger .jsgrid .jsgrid-pager .jsgrid-pager-page a:active,
.jsgrid .jsgrid-pager .pagination.pagination-danger .jsgrid-pager-page a:active,
.jsgrid .pagination-danger.jsgrid-pager .jsgrid-pager-page a:active,
.pagination.pagination-danger .page-item .page-link:focus,
.jsgrid .pagination-danger.jsgrid-pager .page-item .page-link:focus,
.pagination.pagination-danger
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:focus,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-danger
  .jsgrid-pager-nav-button
  .page-link:focus,
.jsgrid
  .pagination-danger.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:focus,
.pagination.pagination-danger
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .page-link:focus,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-danger
  .jsgrid-pager-page
  .page-link:focus,
.jsgrid .pagination-danger.jsgrid-pager .jsgrid-pager-page .page-link:focus,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-danger
  .page-item
  a:focus,
.pagination.pagination-danger
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a:focus,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-danger
  .jsgrid-pager-nav-button
  a:focus,
.jsgrid .pagination-danger.jsgrid-pager .jsgrid-pager-nav-button a:focus,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-danger
  .page-item
  a:focus,
.pagination.pagination-danger .jsgrid .jsgrid-pager .jsgrid-pager-page a:focus,
.jsgrid .jsgrid-pager .pagination.pagination-danger .jsgrid-pager-page a:focus,
.jsgrid .pagination-danger.jsgrid-pager .jsgrid-pager-page a:focus {
  color: #fff;
  background-color: #ef3e27;
}

.pagination.pagination-danger .page-item:hover .page-link,
.jsgrid .pagination-danger.jsgrid-pager .page-item:hover .page-link,
.pagination.pagination-danger
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:hover
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-danger
  .jsgrid-pager-nav-button:hover
  .page-link,
.jsgrid
  .pagination-danger.jsgrid-pager
  .jsgrid-pager-nav-button:hover
  .page-link,
.pagination.pagination-danger
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page:hover
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-danger
  .jsgrid-pager-page:hover
  .page-link,
.jsgrid .pagination-danger.jsgrid-pager .jsgrid-pager-page:hover .page-link,
.pagination.pagination-danger
  .page-item:hover
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-danger
  .page-item:hover
  a,
.jsgrid
  .pagination-danger.jsgrid-pager
  .page-item:hover
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .pagination-danger.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-item:hover
  a,
.pagination.pagination-danger
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:hover
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-danger
  .jsgrid-pager-nav-button:hover
  a,
.jsgrid .pagination-danger.jsgrid-pager .jsgrid-pager-nav-button:hover a,
.pagination.pagination-danger
  .page-item:hover
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-danger
  .page-item:hover
  a,
.jsgrid .pagination-danger.jsgrid-pager .page-item:hover .jsgrid-pager-page a,
.jsgrid .pagination-danger.jsgrid-pager .jsgrid-pager-page .page-item:hover a,
.pagination.pagination-danger .jsgrid .jsgrid-pager .jsgrid-pager-page:hover a,
.jsgrid .jsgrid-pager .pagination.pagination-danger .jsgrid-pager-page:hover a,
.jsgrid .pagination-danger.jsgrid-pager .jsgrid-pager-page:hover a,
.pagination.pagination-danger .page-item.active .page-link,
.jsgrid .pagination-danger.jsgrid-pager .page-item.active .page-link,
.pagination.pagination-danger
  .jsgrid
  .jsgrid-pager
  .active.jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-danger
  .active.jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .pagination-danger.jsgrid-pager
  .active.jsgrid-pager-nav-button
  .page-link,
.pagination.pagination-danger
  .jsgrid
  .jsgrid-pager
  .active.jsgrid-pager-page
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-danger
  .active.jsgrid-pager-page
  .page-link,
.jsgrid .pagination-danger.jsgrid-pager .active.jsgrid-pager-page .page-link,
.pagination.pagination-danger
  .page-item.active
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-danger
  .page-item.active
  a,
.jsgrid
  .pagination-danger.jsgrid-pager
  .page-item.active
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .pagination-danger.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-item.active
  a,
.pagination.pagination-danger
  .jsgrid
  .jsgrid-pager
  .active.jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-danger
  .active.jsgrid-pager-nav-button
  a,
.jsgrid .pagination-danger.jsgrid-pager .active.jsgrid-pager-nav-button a,
.pagination.pagination-danger
  .page-item.active
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-danger
  .page-item.active
  a,
.jsgrid .pagination-danger.jsgrid-pager .page-item.active .jsgrid-pager-page a,
.jsgrid .pagination-danger.jsgrid-pager .jsgrid-pager-page .page-item.active a,
.pagination.pagination-danger .jsgrid .jsgrid-pager .active.jsgrid-pager-page a,
.jsgrid .jsgrid-pager .pagination.pagination-danger .active.jsgrid-pager-page a,
.jsgrid .pagination-danger.jsgrid-pager .active.jsgrid-pager-page a,
.pagination.pagination-danger .page-item:focus .page-link,
.jsgrid .pagination-danger.jsgrid-pager .page-item:focus .page-link,
.pagination.pagination-danger
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:focus
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-danger
  .jsgrid-pager-nav-button:focus
  .page-link,
.jsgrid
  .pagination-danger.jsgrid-pager
  .jsgrid-pager-nav-button:focus
  .page-link,
.pagination.pagination-danger
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page:focus
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-danger
  .jsgrid-pager-page:focus
  .page-link,
.jsgrid .pagination-danger.jsgrid-pager .jsgrid-pager-page:focus .page-link,
.pagination.pagination-danger
  .page-item:focus
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-danger
  .page-item:focus
  a,
.jsgrid
  .pagination-danger.jsgrid-pager
  .page-item:focus
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .pagination-danger.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-item:focus
  a,
.pagination.pagination-danger
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:focus
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-danger
  .jsgrid-pager-nav-button:focus
  a,
.jsgrid .pagination-danger.jsgrid-pager .jsgrid-pager-nav-button:focus a,
.pagination.pagination-danger
  .page-item:focus
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-danger
  .page-item:focus
  a,
.jsgrid .pagination-danger.jsgrid-pager .page-item:focus .jsgrid-pager-page a,
.jsgrid .pagination-danger.jsgrid-pager .jsgrid-pager-page .page-item:focus a,
.pagination.pagination-danger .jsgrid .jsgrid-pager .jsgrid-pager-page:focus a,
.jsgrid .jsgrid-pager .pagination.pagination-danger .jsgrid-pager-page:focus a,
.jsgrid .pagination-danger.jsgrid-pager .jsgrid-pager-page:focus a {
  background-color: #ef3e27;
  border-color: #ef3e27;
  color: #fff;
}

.pagination.pagination-border-danger .page-item .page-link,
.jsgrid .pagination-border-danger.jsgrid-pager .page-item .page-link,
.pagination.pagination-border-danger
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-border-danger
  .jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .pagination-border-danger.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link,
.pagination.pagination-border-danger
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-border-danger
  .jsgrid-pager-page
  .page-link,
.jsgrid .pagination-border-danger.jsgrid-pager .jsgrid-pager-page .page-link,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-border-danger
  .page-item
  a,
.pagination.pagination-border-danger
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-border-danger
  .jsgrid-pager-nav-button
  a,
.jsgrid .pagination-border-danger.jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-border-danger
  .page-item
  a,
.pagination.pagination-border-danger .jsgrid .jsgrid-pager .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .pagination.pagination-border-danger .jsgrid-pager-page a,
.jsgrid .pagination-border-danger.jsgrid-pager .jsgrid-pager-page a {
  border-color: #ef3e27;
}

.pagination.pagination-light :first-child a,
.jsgrid .pagination-light.jsgrid-pager :first-child a,
.pagination.pagination-light :last-child a,
.jsgrid .pagination-light.jsgrid-pager :last-child a {
  background-color: #fefefe;
}

.pagination.pagination-light .page-item .page-link,
.jsgrid .pagination-light.jsgrid-pager .page-item .page-link,
.pagination.pagination-light
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-light
  .jsgrid-pager-nav-button
  .page-link,
.jsgrid .pagination-light.jsgrid-pager .jsgrid-pager-nav-button .page-link,
.pagination.pagination-light
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-light
  .jsgrid-pager-page
  .page-link,
.jsgrid .pagination-light.jsgrid-pager .jsgrid-pager-page .page-link,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-light
  .page-item
  a,
.pagination.pagination-light .jsgrid .jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .pagination.pagination-light .jsgrid-pager-nav-button a,
.jsgrid .pagination-light.jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-light
  .page-item
  a,
.pagination.pagination-light .jsgrid .jsgrid-pager .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .pagination.pagination-light .jsgrid-pager-page a,
.jsgrid .pagination-light.jsgrid-pager .jsgrid-pager-page a {
  color: #cac9cf;
  background-color: #ffffff;
}

.pagination.pagination-light .page-item .page-link:hover,
.jsgrid .pagination-light.jsgrid-pager .page-item .page-link:hover,
.pagination.pagination-light
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:hover,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-light
  .jsgrid-pager-nav-button
  .page-link:hover,
.jsgrid
  .pagination-light.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:hover,
.pagination.pagination-light
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .page-link:hover,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-light
  .jsgrid-pager-page
  .page-link:hover,
.jsgrid .pagination-light.jsgrid-pager .jsgrid-pager-page .page-link:hover,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-light
  .page-item
  a:hover,
.pagination.pagination-light
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a:hover,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-light
  .jsgrid-pager-nav-button
  a:hover,
.jsgrid .pagination-light.jsgrid-pager .jsgrid-pager-nav-button a:hover,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-light
  .page-item
  a:hover,
.pagination.pagination-light .jsgrid .jsgrid-pager .jsgrid-pager-page a:hover,
.jsgrid .jsgrid-pager .pagination.pagination-light .jsgrid-pager-page a:hover,
.jsgrid .pagination-light.jsgrid-pager .jsgrid-pager-page a:hover,
.pagination.pagination-light .page-item .page-link:active,
.jsgrid .pagination-light.jsgrid-pager .page-item .page-link:active,
.pagination.pagination-light
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:active,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-light
  .jsgrid-pager-nav-button
  .page-link:active,
.jsgrid
  .pagination-light.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:active,
.pagination.pagination-light
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .page-link:active,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-light
  .jsgrid-pager-page
  .page-link:active,
.jsgrid .pagination-light.jsgrid-pager .jsgrid-pager-page .page-link:active,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-light
  .page-item
  a:active,
.pagination.pagination-light
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a:active,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-light
  .jsgrid-pager-nav-button
  a:active,
.jsgrid .pagination-light.jsgrid-pager .jsgrid-pager-nav-button a:active,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-light
  .page-item
  a:active,
.pagination.pagination-light .jsgrid .jsgrid-pager .jsgrid-pager-page a:active,
.jsgrid .jsgrid-pager .pagination.pagination-light .jsgrid-pager-page a:active,
.jsgrid .pagination-light.jsgrid-pager .jsgrid-pager-page a:active,
.pagination.pagination-light .page-item .page-link:focus,
.jsgrid .pagination-light.jsgrid-pager .page-item .page-link:focus,
.pagination.pagination-light
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:focus,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-light
  .jsgrid-pager-nav-button
  .page-link:focus,
.jsgrid
  .pagination-light.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:focus,
.pagination.pagination-light
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .page-link:focus,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-light
  .jsgrid-pager-page
  .page-link:focus,
.jsgrid .pagination-light.jsgrid-pager .jsgrid-pager-page .page-link:focus,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-light
  .page-item
  a:focus,
.pagination.pagination-light
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a:focus,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-light
  .jsgrid-pager-nav-button
  a:focus,
.jsgrid .pagination-light.jsgrid-pager .jsgrid-pager-nav-button a:focus,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-light
  .page-item
  a:focus,
.pagination.pagination-light .jsgrid .jsgrid-pager .jsgrid-pager-page a:focus,
.jsgrid .jsgrid-pager .pagination.pagination-light .jsgrid-pager-page a:focus,
.jsgrid .pagination-light.jsgrid-pager .jsgrid-pager-page a:focus {
  color: #000;
  background-color: #f9fafb;
}

.pagination.pagination-light .page-item:hover .page-link,
.jsgrid .pagination-light.jsgrid-pager .page-item:hover .page-link,
.pagination.pagination-light
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:hover
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-light
  .jsgrid-pager-nav-button:hover
  .page-link,
.jsgrid
  .pagination-light.jsgrid-pager
  .jsgrid-pager-nav-button:hover
  .page-link,
.pagination.pagination-light
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page:hover
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-light
  .jsgrid-pager-page:hover
  .page-link,
.jsgrid .pagination-light.jsgrid-pager .jsgrid-pager-page:hover .page-link,
.pagination.pagination-light
  .page-item:hover
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-light
  .page-item:hover
  a,
.jsgrid
  .pagination-light.jsgrid-pager
  .page-item:hover
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .pagination-light.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-item:hover
  a,
.pagination.pagination-light
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:hover
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-light
  .jsgrid-pager-nav-button:hover
  a,
.jsgrid .pagination-light.jsgrid-pager .jsgrid-pager-nav-button:hover a,
.pagination.pagination-light
  .page-item:hover
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-light
  .page-item:hover
  a,
.jsgrid .pagination-light.jsgrid-pager .page-item:hover .jsgrid-pager-page a,
.jsgrid .pagination-light.jsgrid-pager .jsgrid-pager-page .page-item:hover a,
.pagination.pagination-light .jsgrid .jsgrid-pager .jsgrid-pager-page:hover a,
.jsgrid .jsgrid-pager .pagination.pagination-light .jsgrid-pager-page:hover a,
.jsgrid .pagination-light.jsgrid-pager .jsgrid-pager-page:hover a,
.pagination.pagination-light .page-item.active .page-link,
.jsgrid .pagination-light.jsgrid-pager .page-item.active .page-link,
.pagination.pagination-light
  .jsgrid
  .jsgrid-pager
  .active.jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-light
  .active.jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .pagination-light.jsgrid-pager
  .active.jsgrid-pager-nav-button
  .page-link,
.pagination.pagination-light
  .jsgrid
  .jsgrid-pager
  .active.jsgrid-pager-page
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-light
  .active.jsgrid-pager-page
  .page-link,
.jsgrid .pagination-light.jsgrid-pager .active.jsgrid-pager-page .page-link,
.pagination.pagination-light
  .page-item.active
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-light
  .page-item.active
  a,
.jsgrid
  .pagination-light.jsgrid-pager
  .page-item.active
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .pagination-light.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-item.active
  a,
.pagination.pagination-light
  .jsgrid
  .jsgrid-pager
  .active.jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-light
  .active.jsgrid-pager-nav-button
  a,
.jsgrid .pagination-light.jsgrid-pager .active.jsgrid-pager-nav-button a,
.pagination.pagination-light
  .page-item.active
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-light
  .page-item.active
  a,
.jsgrid .pagination-light.jsgrid-pager .page-item.active .jsgrid-pager-page a,
.jsgrid .pagination-light.jsgrid-pager .jsgrid-pager-page .page-item.active a,
.pagination.pagination-light .jsgrid .jsgrid-pager .active.jsgrid-pager-page a,
.jsgrid .jsgrid-pager .pagination.pagination-light .active.jsgrid-pager-page a,
.jsgrid .pagination-light.jsgrid-pager .active.jsgrid-pager-page a,
.pagination.pagination-light .page-item:focus .page-link,
.jsgrid .pagination-light.jsgrid-pager .page-item:focus .page-link,
.pagination.pagination-light
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:focus
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-light
  .jsgrid-pager-nav-button:focus
  .page-link,
.jsgrid
  .pagination-light.jsgrid-pager
  .jsgrid-pager-nav-button:focus
  .page-link,
.pagination.pagination-light
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page:focus
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-light
  .jsgrid-pager-page:focus
  .page-link,
.jsgrid .pagination-light.jsgrid-pager .jsgrid-pager-page:focus .page-link,
.pagination.pagination-light
  .page-item:focus
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-light
  .page-item:focus
  a,
.jsgrid
  .pagination-light.jsgrid-pager
  .page-item:focus
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .pagination-light.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-item:focus
  a,
.pagination.pagination-light
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:focus
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-light
  .jsgrid-pager-nav-button:focus
  a,
.jsgrid .pagination-light.jsgrid-pager .jsgrid-pager-nav-button:focus a,
.pagination.pagination-light
  .page-item:focus
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-light
  .page-item:focus
  a,
.jsgrid .pagination-light.jsgrid-pager .page-item:focus .jsgrid-pager-page a,
.jsgrid .pagination-light.jsgrid-pager .jsgrid-pager-page .page-item:focus a,
.pagination.pagination-light .jsgrid .jsgrid-pager .jsgrid-pager-page:focus a,
.jsgrid .jsgrid-pager .pagination.pagination-light .jsgrid-pager-page:focus a,
.jsgrid .pagination-light.jsgrid-pager .jsgrid-pager-page:focus a {
  background-color: #f9fafb;
  border-color: #f9fafb;
  color: #000;
}

.pagination.pagination-border-light .page-item .page-link,
.jsgrid .pagination-border-light.jsgrid-pager .page-item .page-link,
.pagination.pagination-border-light
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-border-light
  .jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .pagination-border-light.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link,
.pagination.pagination-border-light
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-border-light
  .jsgrid-pager-page
  .page-link,
.jsgrid .pagination-border-light.jsgrid-pager .jsgrid-pager-page .page-link,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-border-light
  .page-item
  a,
.pagination.pagination-border-light
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-border-light
  .jsgrid-pager-nav-button
  a,
.jsgrid .pagination-border-light.jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-border-light
  .page-item
  a,
.pagination.pagination-border-light .jsgrid .jsgrid-pager .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .pagination.pagination-border-light .jsgrid-pager-page a,
.jsgrid .pagination-border-light.jsgrid-pager .jsgrid-pager-page a {
  border-color: #f9fafb;
}

.pagination.pagination-dark :first-child a,
.jsgrid .pagination-dark.jsgrid-pager :first-child a,
.pagination.pagination-dark :last-child a,
.jsgrid .pagination-dark.jsgrid-pager :last-child a {
  background-color: #d3d4d6;
}

.pagination.pagination-dark .page-item .page-link,
.jsgrid .pagination-dark.jsgrid-pager .page-item .page-link,
.pagination.pagination-dark
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-dark
  .jsgrid-pager-nav-button
  .page-link,
.jsgrid .pagination-dark.jsgrid-pager .jsgrid-pager-nav-button .page-link,
.pagination.pagination-dark .jsgrid .jsgrid-pager .jsgrid-pager-page .page-link,
.jsgrid .jsgrid-pager .pagination.pagination-dark .jsgrid-pager-page .page-link,
.jsgrid .pagination-dark.jsgrid-pager .jsgrid-pager-page .page-link,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-dark
  .page-item
  a,
.pagination.pagination-dark .jsgrid .jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .pagination.pagination-dark .jsgrid-pager-nav-button a,
.jsgrid .pagination-dark.jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-dark
  .page-item
  a,
.pagination.pagination-dark .jsgrid .jsgrid-pager .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .pagination.pagination-dark .jsgrid-pager-page a,
.jsgrid .pagination-dark.jsgrid-pager .jsgrid-pager-page a {
  color: #7f8089;
  background-color: #ffffff;
}

.pagination.pagination-dark .page-item .page-link:hover,
.jsgrid .pagination-dark.jsgrid-pager .page-item .page-link:hover,
.pagination.pagination-dark
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:hover,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-dark
  .jsgrid-pager-nav-button
  .page-link:hover,
.jsgrid .pagination-dark.jsgrid-pager .jsgrid-pager-nav-button .page-link:hover,
.pagination.pagination-dark
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .page-link:hover,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-dark
  .jsgrid-pager-page
  .page-link:hover,
.jsgrid .pagination-dark.jsgrid-pager .jsgrid-pager-page .page-link:hover,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-dark
  .page-item
  a:hover,
.pagination.pagination-dark
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a:hover,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-dark
  .jsgrid-pager-nav-button
  a:hover,
.jsgrid .pagination-dark.jsgrid-pager .jsgrid-pager-nav-button a:hover,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-dark
  .page-item
  a:hover,
.pagination.pagination-dark .jsgrid .jsgrid-pager .jsgrid-pager-page a:hover,
.jsgrid .jsgrid-pager .pagination.pagination-dark .jsgrid-pager-page a:hover,
.jsgrid .pagination-dark.jsgrid-pager .jsgrid-pager-page a:hover,
.pagination.pagination-dark .page-item .page-link:active,
.jsgrid .pagination-dark.jsgrid-pager .page-item .page-link:active,
.pagination.pagination-dark
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:active,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-dark
  .jsgrid-pager-nav-button
  .page-link:active,
.jsgrid
  .pagination-dark.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:active,
.pagination.pagination-dark
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .page-link:active,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-dark
  .jsgrid-pager-page
  .page-link:active,
.jsgrid .pagination-dark.jsgrid-pager .jsgrid-pager-page .page-link:active,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-dark
  .page-item
  a:active,
.pagination.pagination-dark
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a:active,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-dark
  .jsgrid-pager-nav-button
  a:active,
.jsgrid .pagination-dark.jsgrid-pager .jsgrid-pager-nav-button a:active,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-dark
  .page-item
  a:active,
.pagination.pagination-dark .jsgrid .jsgrid-pager .jsgrid-pager-page a:active,
.jsgrid .jsgrid-pager .pagination.pagination-dark .jsgrid-pager-page a:active,
.jsgrid .pagination-dark.jsgrid-pager .jsgrid-pager-page a:active,
.pagination.pagination-dark .page-item .page-link:focus,
.jsgrid .pagination-dark.jsgrid-pager .page-item .page-link:focus,
.pagination.pagination-dark
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:focus,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-dark
  .jsgrid-pager-nav-button
  .page-link:focus,
.jsgrid .pagination-dark.jsgrid-pager .jsgrid-pager-nav-button .page-link:focus,
.pagination.pagination-dark
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .page-link:focus,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-dark
  .jsgrid-pager-page
  .page-link:focus,
.jsgrid .pagination-dark.jsgrid-pager .jsgrid-pager-page .page-link:focus,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-dark
  .page-item
  a:focus,
.pagination.pagination-dark
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a:focus,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-dark
  .jsgrid-pager-nav-button
  a:focus,
.jsgrid .pagination-dark.jsgrid-pager .jsgrid-pager-nav-button a:focus,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-dark
  .page-item
  a:focus,
.pagination.pagination-dark .jsgrid .jsgrid-pager .jsgrid-pager-page a:focus,
.jsgrid .jsgrid-pager .pagination.pagination-dark .jsgrid-pager-page a:focus,
.jsgrid .pagination-dark.jsgrid-pager .jsgrid-pager-page a:focus {
  color: #fff;
  background-color: #242a34;
}

.pagination.pagination-dark .page-item:hover .page-link,
.jsgrid .pagination-dark.jsgrid-pager .page-item:hover .page-link,
.pagination.pagination-dark
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:hover
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-dark
  .jsgrid-pager-nav-button:hover
  .page-link,
.jsgrid .pagination-dark.jsgrid-pager .jsgrid-pager-nav-button:hover .page-link,
.pagination.pagination-dark
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page:hover
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-dark
  .jsgrid-pager-page:hover
  .page-link,
.jsgrid .pagination-dark.jsgrid-pager .jsgrid-pager-page:hover .page-link,
.pagination.pagination-dark
  .page-item:hover
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-dark
  .page-item:hover
  a,
.jsgrid
  .pagination-dark.jsgrid-pager
  .page-item:hover
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .pagination-dark.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-item:hover
  a,
.pagination.pagination-dark
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:hover
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-dark
  .jsgrid-pager-nav-button:hover
  a,
.jsgrid .pagination-dark.jsgrid-pager .jsgrid-pager-nav-button:hover a,
.pagination.pagination-dark
  .page-item:hover
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-dark
  .page-item:hover
  a,
.jsgrid .pagination-dark.jsgrid-pager .page-item:hover .jsgrid-pager-page a,
.jsgrid .pagination-dark.jsgrid-pager .jsgrid-pager-page .page-item:hover a,
.pagination.pagination-dark .jsgrid .jsgrid-pager .jsgrid-pager-page:hover a,
.jsgrid .jsgrid-pager .pagination.pagination-dark .jsgrid-pager-page:hover a,
.jsgrid .pagination-dark.jsgrid-pager .jsgrid-pager-page:hover a,
.pagination.pagination-dark .page-item.active .page-link,
.jsgrid .pagination-dark.jsgrid-pager .page-item.active .page-link,
.pagination.pagination-dark
  .jsgrid
  .jsgrid-pager
  .active.jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-dark
  .active.jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .pagination-dark.jsgrid-pager
  .active.jsgrid-pager-nav-button
  .page-link,
.pagination.pagination-dark
  .jsgrid
  .jsgrid-pager
  .active.jsgrid-pager-page
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-dark
  .active.jsgrid-pager-page
  .page-link,
.jsgrid .pagination-dark.jsgrid-pager .active.jsgrid-pager-page .page-link,
.pagination.pagination-dark
  .page-item.active
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-dark
  .page-item.active
  a,
.jsgrid
  .pagination-dark.jsgrid-pager
  .page-item.active
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .pagination-dark.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-item.active
  a,
.pagination.pagination-dark
  .jsgrid
  .jsgrid-pager
  .active.jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-dark
  .active.jsgrid-pager-nav-button
  a,
.jsgrid .pagination-dark.jsgrid-pager .active.jsgrid-pager-nav-button a,
.pagination.pagination-dark
  .page-item.active
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-dark
  .page-item.active
  a,
.jsgrid .pagination-dark.jsgrid-pager .page-item.active .jsgrid-pager-page a,
.jsgrid .pagination-dark.jsgrid-pager .jsgrid-pager-page .page-item.active a,
.pagination.pagination-dark .jsgrid .jsgrid-pager .active.jsgrid-pager-page a,
.jsgrid .jsgrid-pager .pagination.pagination-dark .active.jsgrid-pager-page a,
.jsgrid .pagination-dark.jsgrid-pager .active.jsgrid-pager-page a,
.pagination.pagination-dark .page-item:focus .page-link,
.jsgrid .pagination-dark.jsgrid-pager .page-item:focus .page-link,
.pagination.pagination-dark
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:focus
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-dark
  .jsgrid-pager-nav-button:focus
  .page-link,
.jsgrid .pagination-dark.jsgrid-pager .jsgrid-pager-nav-button:focus .page-link,
.pagination.pagination-dark
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page:focus
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-dark
  .jsgrid-pager-page:focus
  .page-link,
.jsgrid .pagination-dark.jsgrid-pager .jsgrid-pager-page:focus .page-link,
.pagination.pagination-dark
  .page-item:focus
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-dark
  .page-item:focus
  a,
.jsgrid
  .pagination-dark.jsgrid-pager
  .page-item:focus
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .pagination-dark.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-item:focus
  a,
.pagination.pagination-dark
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:focus
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-dark
  .jsgrid-pager-nav-button:focus
  a,
.jsgrid .pagination-dark.jsgrid-pager .jsgrid-pager-nav-button:focus a,
.pagination.pagination-dark
  .page-item:focus
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-dark
  .page-item:focus
  a,
.jsgrid .pagination-dark.jsgrid-pager .page-item:focus .jsgrid-pager-page a,
.jsgrid .pagination-dark.jsgrid-pager .jsgrid-pager-page .page-item:focus a,
.pagination.pagination-dark .jsgrid .jsgrid-pager .jsgrid-pager-page:focus a,
.jsgrid .jsgrid-pager .pagination.pagination-dark .jsgrid-pager-page:focus a,
.jsgrid .pagination-dark.jsgrid-pager .jsgrid-pager-page:focus a {
  background-color: #242a34;
  border-color: #242a34;
  color: #fff;
}

.pagination.pagination-border-dark .page-item .page-link,
.jsgrid .pagination-border-dark.jsgrid-pager .page-item .page-link,
.pagination.pagination-border-dark
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-border-dark
  .jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .pagination-border-dark.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link,
.pagination.pagination-border-dark
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-border-dark
  .jsgrid-pager-page
  .page-link,
.jsgrid .pagination-border-dark.jsgrid-pager .jsgrid-pager-page .page-link,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-border-dark
  .page-item
  a,
.pagination.pagination-border-dark
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-border-dark
  .jsgrid-pager-nav-button
  a,
.jsgrid .pagination-border-dark.jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-border-dark
  .page-item
  a,
.pagination.pagination-border-dark .jsgrid .jsgrid-pager .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .pagination.pagination-border-dark .jsgrid-pager-page a,
.jsgrid .pagination-border-dark.jsgrid-pager .jsgrid-pager-page a {
  border-color: #242a34;
}

.pagination.pagination-square .page-item:first-child .page-link,
.jsgrid .pagination-square.jsgrid-pager .page-item:first-child .page-link,
.pagination.pagination-square
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:first-child
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-square
  .jsgrid-pager-nav-button:first-child
  .page-link,
.jsgrid
  .pagination-square.jsgrid-pager
  .jsgrid-pager-nav-button:first-child
  .page-link,
.pagination.pagination-square
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page:first-child
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-square
  .jsgrid-pager-page:first-child
  .page-link,
.jsgrid
  .pagination-square.jsgrid-pager
  .jsgrid-pager-page:first-child
  .page-link,
.pagination.pagination-square
  .page-item:first-child
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-square
  .page-item:first-child
  a,
.jsgrid
  .pagination-square.jsgrid-pager
  .page-item:first-child
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .pagination-square.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-item:first-child
  a,
.pagination.pagination-square
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:first-child
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-square
  .jsgrid-pager-nav-button:first-child
  a,
.jsgrid .pagination-square.jsgrid-pager .jsgrid-pager-nav-button:first-child a,
.pagination.pagination-square
  .page-item:first-child
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-square
  .page-item:first-child
  a,
.jsgrid
  .pagination-square.jsgrid-pager
  .page-item:first-child
  .jsgrid-pager-page
  a,
.jsgrid
  .pagination-square.jsgrid-pager
  .jsgrid-pager-page
  .page-item:first-child
  a,
.pagination.pagination-square
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page:first-child
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-square
  .jsgrid-pager-page:first-child
  a,
.jsgrid .pagination-square.jsgrid-pager .jsgrid-pager-page:first-child a {
  border-radius: 0;
}

.pagination.pagination-square .page-item:last-child .page-link,
.jsgrid .pagination-square.jsgrid-pager .page-item:last-child .page-link,
.pagination.pagination-square
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:last-child
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-square
  .jsgrid-pager-nav-button:last-child
  .page-link,
.jsgrid
  .pagination-square.jsgrid-pager
  .jsgrid-pager-nav-button:last-child
  .page-link,
.pagination.pagination-square
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page:last-child
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-square
  .jsgrid-pager-page:last-child
  .page-link,
.jsgrid
  .pagination-square.jsgrid-pager
  .jsgrid-pager-page:last-child
  .page-link,
.pagination.pagination-square
  .page-item:last-child
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-square
  .page-item:last-child
  a,
.jsgrid
  .pagination-square.jsgrid-pager
  .page-item:last-child
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .pagination-square.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-item:last-child
  a,
.pagination.pagination-square
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:last-child
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-square
  .jsgrid-pager-nav-button:last-child
  a,
.jsgrid .pagination-square.jsgrid-pager .jsgrid-pager-nav-button:last-child a,
.pagination.pagination-square
  .page-item:last-child
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-square
  .page-item:last-child
  a,
.jsgrid
  .pagination-square.jsgrid-pager
  .page-item:last-child
  .jsgrid-pager-page
  a,
.jsgrid
  .pagination-square.jsgrid-pager
  .jsgrid-pager-page
  .page-item:last-child
  a,
.pagination.pagination-square
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page:last-child
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-square
  .jsgrid-pager-page:last-child
  a,
.jsgrid .pagination-square.jsgrid-pager .jsgrid-pager-page:last-child a {
  border-radius: 0;
}

.pagination.pagination-boxed :first-child,
.jsgrid .pagination-boxed.jsgrid-pager :first-child,
.pagination.pagination-boxed :last-child,
.jsgrid .pagination-boxed.jsgrid-pager :last-child {
  border-radius: 2px !important;
}

.pagination.pagination-boxed .page-item,
.jsgrid .pagination-boxed.jsgrid-pager .page-item,
.pagination.pagination-boxed .jsgrid .jsgrid-pager .jsgrid-pager-nav-button,
.jsgrid .jsgrid-pager .pagination.pagination-boxed .jsgrid-pager-nav-button,
.jsgrid .pagination-boxed.jsgrid-pager .jsgrid-pager-nav-button,
.pagination.pagination-boxed .jsgrid .jsgrid-pager .jsgrid-pager-page,
.jsgrid .jsgrid-pager .pagination.pagination-boxed .jsgrid-pager-page,
.jsgrid .pagination-boxed.jsgrid-pager .jsgrid-pager-page {
  margin-right: 5px;
}

.pagination.pagination-boxed .page-item .page-link,
.jsgrid .pagination-boxed.jsgrid-pager .page-item .page-link,
.pagination.pagination-boxed
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-boxed
  .jsgrid-pager-nav-button
  .page-link,
.jsgrid .pagination-boxed.jsgrid-pager .jsgrid-pager-nav-button .page-link,
.pagination.pagination-boxed
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-boxed
  .jsgrid-pager-page
  .page-link,
.jsgrid .pagination-boxed.jsgrid-pager .jsgrid-pager-page .page-link,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-boxed
  .page-item
  a,
.pagination.pagination-boxed .jsgrid .jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .pagination.pagination-boxed .jsgrid-pager-nav-button a,
.jsgrid .pagination-boxed.jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-boxed
  .page-item
  a,
.pagination.pagination-boxed .jsgrid .jsgrid-pager .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .pagination.pagination-boxed .jsgrid-pager-page a,
.jsgrid .pagination-boxed.jsgrid-pager .jsgrid-pager-page a {
  border-radius: 2px;
}

.pagination.pagination-boxed .page-item .page-link.no-border,
.jsgrid .pagination-boxed.jsgrid-pager .page-item .page-link.no-border,
.pagination.pagination-boxed
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link.no-border,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-boxed
  .jsgrid-pager-nav-button
  .page-link.no-border,
.jsgrid
  .pagination-boxed.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link.no-border,
.pagination.pagination-boxed
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .page-link.no-border,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-boxed
  .jsgrid-pager-page
  .page-link.no-border,
.jsgrid .pagination-boxed.jsgrid-pager .jsgrid-pager-page .page-link.no-border,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-boxed
  .page-item
  a.no-border,
.pagination.pagination-boxed
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a.no-border,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-boxed
  .jsgrid-pager-nav-button
  a.no-border,
.jsgrid .pagination-boxed.jsgrid-pager .jsgrid-pager-nav-button a.no-border,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-boxed
  .page-item
  a.no-border,
.pagination.pagination-boxed
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a.no-border,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-boxed
  .jsgrid-pager-page
  a.no-border,
.jsgrid .pagination-boxed.jsgrid-pager .jsgrid-pager-page a.no-border {
  border: 0;
}

.pagination.pagination-circle .page-item .page-link,
.jsgrid .pagination-circle.jsgrid-pager .page-item .page-link,
.pagination.pagination-circle
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-circle
  .jsgrid-pager-nav-button
  .page-link,
.jsgrid .pagination-circle.jsgrid-pager .jsgrid-pager-nav-button .page-link,
.pagination.pagination-circle
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-circle
  .jsgrid-pager-page
  .page-link,
.jsgrid .pagination-circle.jsgrid-pager .jsgrid-pager-page .page-link,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-circle
  .page-item
  a,
.pagination.pagination-circle .jsgrid .jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid .jsgrid-pager .pagination.pagination-circle .jsgrid-pager-nav-button a,
.jsgrid .pagination-circle.jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-circle
  .page-item
  a,
.pagination.pagination-circle .jsgrid .jsgrid-pager .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .pagination.pagination-circle .jsgrid-pager-page a,
.jsgrid .pagination-circle.jsgrid-pager .jsgrid-pager-page a {
  margin-right: 5px;
  border: none;
  border-radius: 50%;
}

.pagination.pagination-circle .page-item.active .page-link,
.jsgrid .pagination-circle.jsgrid-pager .page-item.active .page-link,
.pagination.pagination-circle
  .jsgrid
  .jsgrid-pager
  .active.jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-circle
  .active.jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .pagination-circle.jsgrid-pager
  .active.jsgrid-pager-nav-button
  .page-link,
.pagination.pagination-circle
  .jsgrid
  .jsgrid-pager
  .active.jsgrid-pager-page
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-circle
  .active.jsgrid-pager-page
  .page-link,
.jsgrid .pagination-circle.jsgrid-pager .active.jsgrid-pager-page .page-link,
.pagination.pagination-circle
  .page-item.active
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-circle
  .page-item.active
  a,
.jsgrid
  .pagination-circle.jsgrid-pager
  .page-item.active
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .pagination-circle.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-item.active
  a,
.pagination.pagination-circle
  .jsgrid
  .jsgrid-pager
  .active.jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-circle
  .active.jsgrid-pager-nav-button
  a,
.jsgrid .pagination-circle.jsgrid-pager .active.jsgrid-pager-nav-button a,
.pagination.pagination-circle
  .page-item.active
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-circle
  .page-item.active
  a,
.jsgrid .pagination-circle.jsgrid-pager .page-item.active .jsgrid-pager-page a,
.jsgrid .pagination-circle.jsgrid-pager .jsgrid-pager-page .page-item.active a,
.pagination.pagination-circle .jsgrid .jsgrid-pager .active.jsgrid-pager-page a,
.jsgrid .jsgrid-pager .pagination.pagination-circle .active.jsgrid-pager-page a,
.jsgrid .pagination-circle.jsgrid-pager .active.jsgrid-pager-page a,
.pagination.pagination-circle .page-item:hover .page-link,
.jsgrid .pagination-circle.jsgrid-pager .page-item:hover .page-link,
.pagination.pagination-circle
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:hover
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-circle
  .jsgrid-pager-nav-button:hover
  .page-link,
.jsgrid
  .pagination-circle.jsgrid-pager
  .jsgrid-pager-nav-button:hover
  .page-link,
.pagination.pagination-circle
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page:hover
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-circle
  .jsgrid-pager-page:hover
  .page-link,
.jsgrid .pagination-circle.jsgrid-pager .jsgrid-pager-page:hover .page-link,
.pagination.pagination-circle
  .page-item:hover
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-circle
  .page-item:hover
  a,
.jsgrid
  .pagination-circle.jsgrid-pager
  .page-item:hover
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .pagination-circle.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-item:hover
  a,
.pagination.pagination-circle
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:hover
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-circle
  .jsgrid-pager-nav-button:hover
  a,
.jsgrid .pagination-circle.jsgrid-pager .jsgrid-pager-nav-button:hover a,
.pagination.pagination-circle
  .page-item:hover
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-circle
  .page-item:hover
  a,
.jsgrid .pagination-circle.jsgrid-pager .page-item:hover .jsgrid-pager-page a,
.jsgrid .pagination-circle.jsgrid-pager .jsgrid-pager-page .page-item:hover a,
.pagination.pagination-circle .jsgrid .jsgrid-pager .jsgrid-pager-page:hover a,
.jsgrid .jsgrid-pager .pagination.pagination-circle .jsgrid-pager-page:hover a,
.jsgrid .pagination-circle.jsgrid-pager .jsgrid-pager-page:hover a,
.pagination.pagination-circle .page-item:focus .page-link,
.jsgrid .pagination-circle.jsgrid-pager .page-item:focus .page-link,
.pagination.pagination-circle
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:focus
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-circle
  .jsgrid-pager-nav-button:focus
  .page-link,
.jsgrid
  .pagination-circle.jsgrid-pager
  .jsgrid-pager-nav-button:focus
  .page-link,
.pagination.pagination-circle
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page:focus
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-circle
  .jsgrid-pager-page:focus
  .page-link,
.jsgrid .pagination-circle.jsgrid-pager .jsgrid-pager-page:focus .page-link,
.pagination.pagination-circle
  .page-item:focus
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-circle
  .page-item:focus
  a,
.jsgrid
  .pagination-circle.jsgrid-pager
  .page-item:focus
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .pagination-circle.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-item:focus
  a,
.pagination.pagination-circle
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:focus
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-circle
  .jsgrid-pager-nav-button:focus
  a,
.jsgrid .pagination-circle.jsgrid-pager .jsgrid-pager-nav-button:focus a,
.pagination.pagination-circle
  .page-item:focus
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-circle
  .page-item:focus
  a,
.jsgrid .pagination-circle.jsgrid-pager .page-item:focus .jsgrid-pager-page a,
.jsgrid .pagination-circle.jsgrid-pager .jsgrid-pager-page .page-item:focus a,
.pagination.pagination-circle .jsgrid .jsgrid-pager .jsgrid-pager-page:focus a,
.jsgrid .jsgrid-pager .pagination.pagination-circle .jsgrid-pager-page:focus a,
.jsgrid .pagination-circle.jsgrid-pager .jsgrid-pager-page:focus a {
  border: none;
  border-radius: 50%;
}

.pagination.pagination-boxed-transparent .page-item .page-link,
.jsgrid .pagination-boxed-transparent.jsgrid-pager .page-item .page-link,
.pagination.pagination-boxed-transparent
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-boxed-transparent
  .jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .pagination-boxed-transparent.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link,
.pagination.pagination-boxed-transparent
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-boxed-transparent
  .jsgrid-pager-page
  .page-link,
.jsgrid
  .pagination-boxed-transparent.jsgrid-pager
  .jsgrid-pager-page
  .page-link,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-boxed-transparent
  .page-item
  a,
.pagination.pagination-boxed-transparent
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-boxed-transparent
  .jsgrid-pager-nav-button
  a,
.jsgrid .pagination-boxed-transparent.jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-boxed-transparent
  .page-item
  a,
.pagination.pagination-boxed-transparent
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-boxed-transparent
  .jsgrid-pager-page
  a,
.jsgrid .pagination-boxed-transparent.jsgrid-pager .jsgrid-pager-page a {
  margin-right: 5px;
  border: none;
}

.pagination.pagination-boxed-transparent .page-item.active .page-link,
.jsgrid .pagination-boxed-transparent.jsgrid-pager .page-item.active .page-link,
.pagination.pagination-boxed-transparent
  .jsgrid
  .jsgrid-pager
  .active.jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-boxed-transparent
  .active.jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .pagination-boxed-transparent.jsgrid-pager
  .active.jsgrid-pager-nav-button
  .page-link,
.pagination.pagination-boxed-transparent
  .jsgrid
  .jsgrid-pager
  .active.jsgrid-pager-page
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-boxed-transparent
  .active.jsgrid-pager-page
  .page-link,
.jsgrid
  .pagination-boxed-transparent.jsgrid-pager
  .active.jsgrid-pager-page
  .page-link,
.pagination.pagination-boxed-transparent
  .page-item.active
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-boxed-transparent
  .page-item.active
  a,
.jsgrid
  .pagination-boxed-transparent.jsgrid-pager
  .page-item.active
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .pagination-boxed-transparent.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-item.active
  a,
.pagination.pagination-boxed-transparent
  .jsgrid
  .jsgrid-pager
  .active.jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-boxed-transparent
  .active.jsgrid-pager-nav-button
  a,
.jsgrid
  .pagination-boxed-transparent.jsgrid-pager
  .active.jsgrid-pager-nav-button
  a,
.pagination.pagination-boxed-transparent
  .page-item.active
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-boxed-transparent
  .page-item.active
  a,
.jsgrid
  .pagination-boxed-transparent.jsgrid-pager
  .page-item.active
  .jsgrid-pager-page
  a,
.jsgrid
  .pagination-boxed-transparent.jsgrid-pager
  .jsgrid-pager-page
  .page-item.active
  a,
.pagination.pagination-boxed-transparent
  .jsgrid
  .jsgrid-pager
  .active.jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-boxed-transparent
  .active.jsgrid-pager-page
  a,
.jsgrid .pagination-boxed-transparent.jsgrid-pager .active.jsgrid-pager-page a,
.pagination.pagination-boxed-transparent .page-item:hover .page-link,
.jsgrid .pagination-boxed-transparent.jsgrid-pager .page-item:hover .page-link,
.pagination.pagination-boxed-transparent
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:hover
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-boxed-transparent
  .jsgrid-pager-nav-button:hover
  .page-link,
.jsgrid
  .pagination-boxed-transparent.jsgrid-pager
  .jsgrid-pager-nav-button:hover
  .page-link,
.pagination.pagination-boxed-transparent
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page:hover
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-boxed-transparent
  .jsgrid-pager-page:hover
  .page-link,
.jsgrid
  .pagination-boxed-transparent.jsgrid-pager
  .jsgrid-pager-page:hover
  .page-link,
.pagination.pagination-boxed-transparent
  .page-item:hover
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-boxed-transparent
  .page-item:hover
  a,
.jsgrid
  .pagination-boxed-transparent.jsgrid-pager
  .page-item:hover
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .pagination-boxed-transparent.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-item:hover
  a,
.pagination.pagination-boxed-transparent
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:hover
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-boxed-transparent
  .jsgrid-pager-nav-button:hover
  a,
.jsgrid
  .pagination-boxed-transparent.jsgrid-pager
  .jsgrid-pager-nav-button:hover
  a,
.pagination.pagination-boxed-transparent
  .page-item:hover
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-boxed-transparent
  .page-item:hover
  a,
.jsgrid
  .pagination-boxed-transparent.jsgrid-pager
  .page-item:hover
  .jsgrid-pager-page
  a,
.jsgrid
  .pagination-boxed-transparent.jsgrid-pager
  .jsgrid-pager-page
  .page-item:hover
  a,
.pagination.pagination-boxed-transparent
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page:hover
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-boxed-transparent
  .jsgrid-pager-page:hover
  a,
.jsgrid .pagination-boxed-transparent.jsgrid-pager .jsgrid-pager-page:hover a,
.pagination.pagination-boxed-transparent .page-item:focus .page-link,
.jsgrid .pagination-boxed-transparent.jsgrid-pager .page-item:focus .page-link,
.pagination.pagination-boxed-transparent
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:focus
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-boxed-transparent
  .jsgrid-pager-nav-button:focus
  .page-link,
.jsgrid
  .pagination-boxed-transparent.jsgrid-pager
  .jsgrid-pager-nav-button:focus
  .page-link,
.pagination.pagination-boxed-transparent
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page:focus
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-boxed-transparent
  .jsgrid-pager-page:focus
  .page-link,
.jsgrid
  .pagination-boxed-transparent.jsgrid-pager
  .jsgrid-pager-page:focus
  .page-link,
.pagination.pagination-boxed-transparent
  .page-item:focus
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-boxed-transparent
  .page-item:focus
  a,
.jsgrid
  .pagination-boxed-transparent.jsgrid-pager
  .page-item:focus
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .pagination-boxed-transparent.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-item:focus
  a,
.pagination.pagination-boxed-transparent
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button:focus
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-boxed-transparent
  .jsgrid-pager-nav-button:focus
  a,
.jsgrid
  .pagination-boxed-transparent.jsgrid-pager
  .jsgrid-pager-nav-button:focus
  a,
.pagination.pagination-boxed-transparent
  .page-item:focus
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-boxed-transparent
  .page-item:focus
  a,
.jsgrid
  .pagination-boxed-transparent.jsgrid-pager
  .page-item:focus
  .jsgrid-pager-page
  a,
.jsgrid
  .pagination-boxed-transparent.jsgrid-pager
  .jsgrid-pager-page
  .page-item:focus
  a,
.pagination.pagination-boxed-transparent
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page:focus
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-boxed-transparent
  .jsgrid-pager-page:focus
  a,
.jsgrid .pagination-boxed-transparent.jsgrid-pager .jsgrid-pager-page:focus a {
  border: none;
}

.pagination.pagination-circle-border .page-item .page-link,
.jsgrid .pagination-circle-border.jsgrid-pager .page-item .page-link,
.pagination.pagination-circle-border
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-circle-border
  .jsgrid-pager-nav-button
  .page-link,
.jsgrid
  .pagination-circle-border.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link,
.pagination.pagination-circle-border
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .page-link,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-circle-border
  .jsgrid-pager-page
  .page-link,
.jsgrid .pagination-circle-border.jsgrid-pager .jsgrid-pager-page .page-link,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-circle-border
  .page-item
  a,
.pagination.pagination-circle-border
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-circle-border
  .jsgrid-pager-nav-button
  a,
.jsgrid .pagination-circle-border.jsgrid-pager .jsgrid-pager-nav-button a,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-circle-border
  .page-item
  a,
.pagination.pagination-circle-border .jsgrid .jsgrid-pager .jsgrid-pager-page a,
.jsgrid .jsgrid-pager .pagination.pagination-circle-border .jsgrid-pager-page a,
.jsgrid .pagination-circle-border.jsgrid-pager .jsgrid-pager-page a {
  border-radius: 50%;
  margin-right: 5px;
}

.pagination.pagination-circle-border .page-item .page-link:hover,
.jsgrid .pagination-circle-border.jsgrid-pager .page-item .page-link:hover,
.pagination.pagination-circle-border
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:hover,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-circle-border
  .jsgrid-pager-nav-button
  .page-link:hover,
.jsgrid
  .pagination-circle-border.jsgrid-pager
  .jsgrid-pager-nav-button
  .page-link:hover,
.pagination.pagination-circle-border
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .page-link:hover,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-circle-border
  .jsgrid-pager-page
  .page-link:hover,
.jsgrid
  .pagination-circle-border.jsgrid-pager
  .jsgrid-pager-page
  .page-link:hover,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  .pagination.pagination-circle-border
  .page-item
  a:hover,
.pagination.pagination-circle-border
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-nav-button
  a:hover,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-circle-border
  .jsgrid-pager-nav-button
  a:hover,
.jsgrid .pagination-circle-border.jsgrid-pager .jsgrid-pager-nav-button a:hover,
.jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  .pagination.pagination-circle-border
  .page-item
  a:hover,
.pagination.pagination-circle-border
  .jsgrid
  .jsgrid-pager
  .jsgrid-pager-page
  a:hover,
.jsgrid
  .jsgrid-pager
  .pagination.pagination-circle-border
  .jsgrid-pager-page
  a:hover,
.jsgrid .pagination-circle-border.jsgrid-pager .jsgrid-pager-page a:hover {
  border-radius: 50%;
}

/* Popovers */
.popover {
  /*z-index: 1029;*/
  font-family: 'Poppins';
  box-shadow: 0px 0px 40px 0px rgba(79, 61, 101, 0.15);
  background-color: #ffffff;
  border: 0;
}

.popover.bs-popover-top .arrow:before,
.popover.bs-popover-auto[x-placement^='top'] .arrow:before {
  border-top-color: #ffffff;
}

.popover.bs-popover-top .arrow:after,
.popover.bs-popover-auto[x-placement^='top'] .arrow:after {
  border-top-color: #ffffff;
}

.popover.bs-popover-right .arrow:before,
.popover.bs-popover-auto[x-placement^='right'] .arrow:before {
  border-right-color: #ffffff;
}

.popover.bs-popover-right .arrow:after,
.popover.bs-popover-auto[x-placement^='right'] .arrow:after {
  border-right-color: #ffffff;
}

.popover.bs-popover-bottom .arrow:before,
.popover.bs-popover-auto[x-placement^='bottom'] .arrow:before {
  border-bottom-color: #ffffff;
}

.popover.bs-popover-bottom .arrow:after,
.popover.bs-popover-auto[x-placement^='bottom'] .arrow:after {
  border-bottom-color: #ffffff;
}

.popover.bs-popover-bottom .popover-header:before,
.popover.bs-popover-auto[x-placement^='bottom'] .popover-header:before {
  border-bottom: 0;
}

.popover.bs-popover-left .arrow:before,
.popover.bs-popover-auto[x-placement^='left'] .arrow:before {
  border-left-color: #ffffff;
}

.popover.bs-popover-left .arrow:after,
.popover.bs-popover-auto[x-placement^='left'] .arrow:after {
  border-left-color: #ffffff;
}

.popover .popover-header {
  font-weight: 500;
  font-size: 0.8125rem;
  border-bottom: 0;
  background-color: #ffffff;
  color: #3f3f3f;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.popover .popover-body {
  background-color: #ffffff;
  color: #8d8a89;
  font-weight: 300;
  font-size: 0.8125rem;
}

.popover.left .arrow:before {
  border-left-color: #ffffff;
}

.popover.left .arrow:after {
  border-left-color: #ffffff;
}

.popover.right .arrow:before {
  border-right-color: #ffffff;
}

.popover.right .arrow:after {
  border-right-color: #ffffff;
}

.popover.top .arrow:before {
  border-top-color: #ffffff;
}

.popover.top .arrow:after {
  border-top-color: #ffffff;
}

.popover.bottom .arrow:before {
  border-bottom-color: #ffffff;
}

.popover.bottom .arrow:after {
  border-bottom-color: #ffffff;
}

/* Portfolio */
.portfolio-grid figure {
  position: relative;
  float: left;
  overflow: hidden;
  width: 100%;
  background: #ef3e27;
  text-align: center;
  cursor: pointer;
}

@media (max-width: 767px) {
  .portfolio-grid figure {
    width: 270px;
  }
}

.portfolio-grid figure img {
  position: relative;
  display: block;
  min-height: 100%;
  max-width: 100%;
  width: 100%;
  opacity: 0.8;
}

.portfolio-grid figure figcaption {
  padding: 2em;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 1.25em;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.portfolio-grid figure figcaption:after,
.portfolio-grid figure figcaption:before {
  pointer-events: none;
}

.portfolio-grid figure.effect-text-in {
  border-radius: 0.25rem;
}

.portfolio-grid figure.effect-text-in h4,
.portfolio-grid figure.effect-text-in img {
  transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  transition: transform 0.35s, -webkit-transform 0.35s;
}

.portfolio-grid figure.effect-text-in img {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.portfolio-grid figure.effect-text-in h4,
.portfolio-grid figure.effect-text-in p {
  position: absolute;
  bottom: 10px;
  left: 0;
  padding: 10px;
  margin-bottom: 0;
  width: 100%;
}

.portfolio-grid figure.effect-text-in p {
  text-transform: none;
  opacity: 0;
  transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: translate3d(0, 50px, 0);
  transform: translate3d(0, 50px, 0);
}

.portfolio-grid figure.effect-text-in:hover img {
  -webkit-transform: translate3d(0, -80px, 0);
  transform: translate3d(0, -80px, 0);
}

.portfolio-grid figure.effect-text-in:hover h4 {
  -webkit-transform: translate3d(0, -100px, 0);
  transform: translate3d(0, -100px, 0);
}

.portfolio-grid figure.effect-text-in:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/* Highlighter */
.highlight-list {
  padding: 0;
}

.highlight-list .highlight-item {
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #e6e4e9;
}

.highlight-list .highlight-item .highlight-thumbnail {
  position: relative;
  margin: auto 0;
}

.highlight-list .highlight-item .highlight-thumbnail .highlight-icon {
  width: 2.25rem;
  height: 2.25rem;
  padding: 0.375rem;
  text-align: center;
  border-radius: 100%;
}

.highlight-list .highlight-item .highlight-thumbnail .highlight-icon svg {
  stroke-width: 1;
  width: 1.25rem;
  height: 1.25rem;
}

.highlight-list .highlight-item .highlight-item-content {
  line-height: 1.25rem;
  padding-left: 0.9375rem;
  color: #3f3f3f;
}

.highlight-list .highlight-item .highlight-item-content .highlight-detail,
.highlight-list .highlight-item .highlight-item-content .highlight-message {
  color: #797e99;
  font-size: 0.8125rem;
  font-weight: 300;
}

.highlight-list .highlight-item.starred {
  background: #ffa749;
}

.highlight-list .highlight-item.starred svg {
  fill: #ffffff;
}

.highlight-list .highlight-item.starred .highlight-message {
  color: #797e99;
}

.highlight-list .highlight-item:hover {
  background: white;
  cursor: pointer;
}

.highlight-list .highlight-item:hover.starred {
  background: #ffa749;
}

/* Pricing table */
.pricing-table {
  border-right: 1px solid #e6e4e9;
  padding: 0 1.5625rem;
  margin: 1rem 0;
}

.pricing-table .header {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.pricing-table .header .logo {
  background: #f0f9ff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2rem solid #fbfdff;
  width: 180px;
  height: 180px;
}

.pricing-table .header .logo svg {
  width: 120px;
  height: 120px;
  stroke-width: 1;
  color: #2196f3;
}

.pricing-table .header .title {
  font-weight: 500;
  font-size: 1rem;
  color: #88939d;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 1rem;
}

.pricing-table .body {
  padding: 0 4rem;
  margin-bottom: 1rem;
}

.pricing-table .body p {
  text-align: center;
  font-size: 13px;
  color: #2196f3;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-top: 1px solid #e6e4e9;
  line-height: 1.5;
  padding: 1rem 0;
  margin-bottom: 0;
}

.pricing-table .footer {
  background: transparent;
  text-align: center;
}

.pricing-table .footer .price {
  font-weight: 400;
  font-size: 4rem;
  color: #2196f3;
}

.pricing-table .footer .price small {
  font-size: 30%;
}

/* Tables */
.table,
.jsgrid .jsgrid-table {
  margin-bottom: 0;
}

.table thead th,
.jsgrid .jsgrid-table thead th {
  font-weight: bold;
  font-size: 12px;
}

.table thead th i,
.jsgrid .jsgrid-table thead th i {
  margin-left: 0.325rem;
}

.table th,
.jsgrid .jsgrid-table th,
.table td,
.jsgrid .jsgrid-table td {
  vertical-align: middle;
  line-height: 1;
}

.table td,
.jsgrid .jsgrid-table td {
  font-size: 13px;
  font-weight: 400;
}

.table td img,
.jsgrid .jsgrid-table td img {
  max-height: 80px;
  vertical-align: middle;
  margin-top: 4px;
}

.table td .badge,
.jsgrid .jsgrid-table td .badge {
  margin-bottom: 0;
}

.table.table-borderless,
.jsgrid .table-borderless.jsgrid-table {
  border: none;
}

.table.table-borderless tr,
.jsgrid .table-borderless.jsgrid-table tr,
.table.table-borderless td,
.jsgrid .table-borderless.jsgrid-table td,
.table.table-borderless th,
.jsgrid .table-borderless.jsgrid-table th {
  border: none;
}

.nav-tabs .nav-link {
  color: #ef3e27;
  padding: 0.75rem 1.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all, 0.3s;
  border-radius: 2.5px;
}

@media (prefers-reduced-motion: reduce) {
  .nav-tabs .nav-link {
    transition: none;
  }
}

@media (max-width: 767px) {
  .nav-tabs .nav-link {
    padding: 0.75rem 0.5rem;
  }
}

.nav-tabs .nav-link.active {
  color: #ef3e27;
  background-color: transparent;
  border-bottom-color: #ffffff;
}

.nav-tabs .nav-link svg {
  height: 15px;
  width: 15px;
  margin-right: 0.5rem;
}

.nav-tabs.nav-tabs-vertical,
.nav-tabs.nav-tabs-vertical-custom {
  border-bottom: 0;
}

.nav-tabs.nav-tabs-vertical .nav-link.active,
.nav-tabs.nav-tabs-vertical-custom .nav-link.active {
  border-bottom-color: #e6e4e9;
}

.nav-tabs.nav-tabs-vertical .nav-item:first-child .nav-link,
.nav-tabs.nav-tabs-vertical-custom .nav-item:first-child .nav-link {
  border-radius: 4px 4px 0 0;
}

.nav-tabs.nav-tabs-vertical .nav-item:last-child .nav-link,
.nav-tabs.nav-tabs-vertical-custom .nav-item:last-child .nav-link {
  border-radius: 0 0 4px 4px;
}

.nav-tabs.nav-tabs-vertical-custom {
  background: #ef3e27;
  border-radius: 4px;
}

.nav-tabs.nav-tabs-vertical-custom .nav-link {
  background: transparent;
  border-color: rgba(255, 255, 255, 0.2);
  color: #001cad;
  font-weight: 600;
  padding: 1.75rem;
}

.nav-tabs.nav-tabs-vertical-custom .nav-link.active {
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.2);
}

.nav-tabs-line {
  border-bottom: 1px solid #e6e4e9;
}

.nav-tabs-line.no-borders {
  border: 0;
}

.nav-tabs-line.no-borders .nav-link {
  border-bottom: 1px solid transparent;
}

.nav-tabs-line .nav-link {
  padding: 0.75rem 1.5rem;
  margin: 0 0 -1px 0;
  border-bottom: 1px solid transparent;
  transition: all, 0s;
  background: none;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  color: #3f3f3f;
  border-radius: 0;
}

@media (prefers-reduced-motion: reduce) {
  .nav-tabs-line .nav-link {
    transition: none;
  }
}

@media (max-width: 767px) {
  .nav-tabs-line .nav-link {
    padding: 0.75rem 0.5rem;
  }
}

.nav-tabs-line .nav-link:hover,
.nav-tabs-line .nav-link.active {
  border-bottom: 1px solid #ef3e27;
  color: #ef3e27;
  background: none;
}

.nav-tabs-icon {
  border-bottom: none;
  justify-content: center;
}

.nav-tabs-icon .nav-link {
  width: 25%;
  padding: 0.75rem;
  margin: 0 0.5rem -0.0625rem 0;
  background: none;
  border: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.25rem;
  box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
  -ms-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
  transition: all, 0.3s;
}

@media (prefers-reduced-motion: reduce) {
  .nav-tabs-icon .nav-link {
    transition: none;
  }
}

.nav-tabs-icon .nav-link:hover,
.nav-tabs-icon .nav-link.active {
  background-color: #ef3e27;
  color: #ffffff;
  cursor: pointer;
}

.nav-tabs-icon .nav-link:hover svg,
.nav-tabs-icon .nav-link:hover .nav-title-text,
.nav-tabs-icon .nav-link.active svg,
.nav-tabs-icon .nav-link.active .nav-title-text {
  color: inherit;
}

.nav-tabs-icon .nav-link svg {
  color: #3f3f3f;
  width: 20px;
  height: 20px;
  margin: 0;
}

.nav-tabs-icon .nav-link .nav-title-text {
  display: block;
  color: #3f3f3f;
  font-weight: 500;
  font-size: 13px;
  text-transform: capitalize;
}

.nav-pills {
  border-bottom: 1px solid #e6e4e9;
  padding-bottom: 1rem;
}

.nav-pills .nav-link {
  border: 0;
  padding: 0.5rem 1.75rem;
  background: #ececec;
  color: #3f3f3f;
}

@media (max-width: 767px) {
  .nav-pills .nav-link {
    padding: 0.5rem 0.5rem;
  }
}

.nav-pills .nav-link.active {
  background: #ef3e27;
  color: #ffffff;
}

.nav-pills .nav-item {
  margin-right: 1rem;
}

@media (max-width: 767px) {
  .nav-pills .nav-item {
    margin-right: 0.5rem;
  }
}

.nav-pills.nav-pills-vertical {
  border-bottom: 0;
}

.nav-pills.nav-pills-vertical .nav-item {
  margin-right: 0;
  margin-bottom: 1rem;
}

.nav-pills.nav-pills-custom {
  border-bottom: 0;
}

.nav-pills.nav-pills-custom .nav-link {
  border-radius: 20px;
  padding: 0.5rem 2.5rem;
  background: #fcfcfd;
  color: #000000;
}

.nav-pills.nav-pills-custom .nav-link.active {
  background: #ef3e27;
  color: #ffffff;
}

/* Pills color variations */
.nav-pills-primary .nav-link {
  background: #ececec;
  color: #3f3f3f;
}

.nav-pills-primary .nav-link.active {
  background: #ef3e27;
  color: #ffffff;
}

.nav-tabs-line-primary {
  border-bottom: 0;
}

.nav-tabs-line-primary .nav-link {
  color: #ef3e27;
}

.nav-tabs-line-primary .nav-link:hover,
.nav-tabs-line-primary .nav-link.active {
  border-bottom: 1px solid #ef3e27;
  color: #ef3e27;
}

.nav-tabs-icon-primary .nav-link {
  color: #ef3e27;
}

.nav-tabs-icon-primary .nav-link:hover,
.nav-tabs-icon-primary .nav-link.active {
  background-color: #ef3e27;
  color: #ffffff;
}

.nav-tabs-icon-primary .nav-link:hover svg,
.nav-tabs-icon-primary .nav-link:hover .nav-title-text,
.nav-tabs-icon-primary .nav-link.active svg,
.nav-tabs-icon-primary .nav-link.active .nav-title-text {
  color: inherit;
}

.nav-tabs-icon-primary .nav-link svg {
  color: #3f3f3f;
}

.nav-tabs-icon-primary .nav-link .nav-title-text {
  display: block;
  color: #3f3f3f;
}

.nav-pills-secondary .nav-link {
  background: #fbfbfd;
  color: #74757a;
}

.nav-pills-secondary .nav-link.active {
  background: #d3dae6;
  color: #ffffff;
}

.nav-tabs-line-secondary {
  border-bottom: 0;
}

.nav-tabs-line-secondary .nav-link {
  color: #d3dae6;
}

.nav-tabs-line-secondary .nav-link:hover,
.nav-tabs-line-secondary .nav-link.active {
  border-bottom: 1px solid #d3dae6;
  color: #d3dae6;
}

.nav-tabs-icon-secondary .nav-link {
  color: #d3dae6;
}

.nav-tabs-icon-secondary .nav-link:hover,
.nav-tabs-icon-secondary .nav-link.active {
  background-color: #d3dae6;
  color: #ffffff;
}

.nav-tabs-icon-secondary .nav-link:hover svg,
.nav-tabs-icon-secondary .nav-link:hover .nav-title-text,
.nav-tabs-icon-secondary .nav-link.active svg,
.nav-tabs-icon-secondary .nav-link.active .nav-title-text {
  color: inherit;
}

.nav-tabs-icon-secondary .nav-link svg {
  color: #74757a;
}

.nav-tabs-icon-secondary .nav-link .nav-title-text {
  display: block;
  color: #74757a;
}

.nav-pills-success .nav-link {
  background: #e6faf4;
  color: #4a7268;
}

.nav-pills-success .nav-link.active {
  background: #00cb8e;
  color: #ffffff;
}

.nav-tabs-line-success {
  border-bottom: 0;
}

.nav-tabs-line-success .nav-link {
  color: #00cb8e;
}

.nav-tabs-line-success .nav-link:hover,
.nav-tabs-line-success .nav-link.active {
  border-bottom: 1px solid #00cb8e;
  color: #00cb8e;
}

.nav-tabs-icon-success .nav-link {
  color: #00cb8e;
}

.nav-tabs-icon-success .nav-link:hover,
.nav-tabs-icon-success .nav-link.active {
  background-color: #00cb8e;
  color: #ffffff;
}

.nav-tabs-icon-success .nav-link:hover svg,
.nav-tabs-icon-success .nav-link:hover .nav-title-text,
.nav-tabs-icon-success .nav-link.active svg,
.nav-tabs-icon-success .nav-link.active .nav-title-text {
  color: inherit;
}

.nav-tabs-icon-success .nav-link svg {
  color: #4a7268;
}

.nav-tabs-icon-success .nav-link .nav-title-text {
  display: block;
  color: #4a7268;
}

.nav-pills-info .nav-link {
  background: #e6f7ff;
  color: #4a6c7f;
}

.nav-pills-info .nav-link.active {
  background: #00aaff;
  color: #ffffff;
}

.nav-tabs-line-info {
  border-bottom: 0;
}

.nav-tabs-line-info .nav-link {
  color: #00aaff;
}

.nav-tabs-line-info .nav-link:hover,
.nav-tabs-line-info .nav-link.active {
  border-bottom: 1px solid #00aaff;
  color: #00aaff;
}

.nav-tabs-icon-info .nav-link {
  color: #00aaff;
}

.nav-tabs-icon-info .nav-link:hover,
.nav-tabs-icon-info .nav-link.active {
  background-color: #00aaff;
  color: #ffffff;
}

.nav-tabs-icon-info .nav-link:hover svg,
.nav-tabs-icon-info .nav-link:hover .nav-title-text,
.nav-tabs-icon-info .nav-link.active svg,
.nav-tabs-icon-info .nav-link.active .nav-title-text {
  color: inherit;
}

.nav-tabs-icon-info .nav-link svg {
  color: #4a6c7f;
}

.nav-tabs-icon-info .nav-link .nav-title-text {
  display: block;
  color: #4a6c7f;
}

.nav-pills-warning .nav-link {
  background: #fff9ee;
  color: #7d715d;
}

.nav-pills-warning .nav-link.active {
  background: #ffc555;
  color: #ffffff;
}

.nav-tabs-line-warning {
  border-bottom: 0;
}

.nav-tabs-line-warning .nav-link {
  color: #ffc555;
}

.nav-tabs-line-warning .nav-link:hover,
.nav-tabs-line-warning .nav-link.active {
  border-bottom: 1px solid #ffc555;
  color: #ffc555;
}

.nav-tabs-icon-warning .nav-link {
  color: #ffc555;
}

.nav-tabs-icon-warning .nav-link:hover,
.nav-tabs-icon-warning .nav-link.active {
  background-color: #ffc555;
  color: #ffffff;
}

.nav-tabs-icon-warning .nav-link:hover svg,
.nav-tabs-icon-warning .nav-link:hover .nav-title-text,
.nav-tabs-icon-warning .nav-link.active svg,
.nav-tabs-icon-warning .nav-link.active .nav-title-text {
  color: inherit;
}

.nav-tabs-icon-warning .nav-link svg {
  color: #7d715d;
}

.nav-tabs-icon-warning .nav-link .nav-title-text {
  display: block;
  color: #7d715d;
}

.nav-pills-danger .nav-link {
  background: #ffeef0;
  color: #7d5b60;
}

.nav-pills-danger .nav-link.active {
  background: #ef3e27;
  color: #ffffff;
}

.nav-tabs-line-danger {
  border-bottom: 0;
}

.nav-tabs-line-danger .nav-link {
  color: #ef3e27;
}

.nav-tabs-line-danger .nav-link:hover,
.nav-tabs-line-danger .nav-link.active {
  border-bottom: 1px solid #ef3e27;
  color: #ef3e27;
}

.nav-tabs-icon-danger .nav-link {
  color: #ef3e27;
}

.nav-tabs-icon-danger .nav-link:hover,
.nav-tabs-icon-danger .nav-link.active {
  background-color: #ef3e27;
  color: #ffffff;
}

.nav-tabs-icon-danger .nav-link:hover svg,
.nav-tabs-icon-danger .nav-link:hover .nav-title-text,
.nav-tabs-icon-danger .nav-link.active svg,
.nav-tabs-icon-danger .nav-link.active .nav-title-text {
  color: inherit;
}

.nav-tabs-icon-danger .nav-link svg {
  color: #7d5b60;
}

.nav-tabs-icon-danger .nav-link .nav-title-text {
  display: block;
  color: #7d5b60;
}

.nav-pills-light .nav-link {
  background: #feffff;
  color: #7b7c7e;
}

.nav-pills-light .nav-link.active {
  background: #f9fafb;
  color: #ffffff;
}

.nav-tabs-line-light {
  border-bottom: 0;
}

.nav-tabs-line-light .nav-link {
  color: #f9fafb;
}

.nav-tabs-line-light .nav-link:hover,
.nav-tabs-line-light .nav-link.active {
  border-bottom: 1px solid #f9fafb;
  color: #f9fafb;
}

.nav-tabs-icon-light .nav-link {
  color: #f9fafb;
}

.nav-tabs-icon-light .nav-link:hover,
.nav-tabs-icon-light .nav-link.active {
  background-color: #f9fafb;
  color: #ffffff;
}

.nav-tabs-icon-light .nav-link:hover svg,
.nav-tabs-icon-light .nav-link:hover .nav-title-text,
.nav-tabs-icon-light .nav-link.active svg,
.nav-tabs-icon-light .nav-link.active .nav-title-text {
  color: inherit;
}

.nav-tabs-icon-light .nav-link svg {
  color: #7b7c7e;
}

.nav-tabs-icon-light .nav-link .nav-title-text {
  display: block;
  color: #7b7c7e;
}

.nav-pills-dark .nav-link {
  background: #e9eaeb;
  color: #515256;
}

.nav-pills-dark .nav-link.active {
  background: #242a34;
  color: #ffffff;
}

.nav-tabs-line-dark {
  border-bottom: 0;
}

.nav-tabs-line-dark .nav-link {
  color: #242a34;
}

.nav-tabs-line-dark .nav-link:hover,
.nav-tabs-line-dark .nav-link.active {
  border-bottom: 1px solid #242a34;
  color: #242a34;
}

.nav-tabs-icon-dark .nav-link {
  color: #242a34;
}

.nav-tabs-icon-dark .nav-link:hover,
.nav-tabs-icon-dark .nav-link.active {
  background-color: #242a34;
  color: #ffffff;
}

.nav-tabs-icon-dark .nav-link:hover svg,
.nav-tabs-icon-dark .nav-link:hover .nav-title-text,
.nav-tabs-icon-dark .nav-link.active svg,
.nav-tabs-icon-dark .nav-link.active .nav-title-text {
  color: inherit;
}

.nav-tabs-icon-dark .nav-link svg {
  color: #515256;
}

.nav-tabs-icon-dark .nav-link .nav-title-text {
  display: block;
  color: #515256;
}

.tab-content {
  border: 0;
  padding: 1rem;
  text-align: justify;
  font-weight: 400;
  color: #3f3f3f;
}

.tab-content.tab-content-vertical {
  border-top: 1px solid #e6e4e9;
}

.tab-content.tab-content-vertical-custom {
  border: 0;
  padding-top: 0;
}

.tab-content.tab-content-custom-pill {
  border: 0;
  padding-left: 0;
}

.tab-content.tabs-line-content,
.tab-content.tabs-icon-content {
  border: 0;
}

/* Timeline */
.timeline-container {
  position: relative;
  height: 400px;
}

.timeline {
  list-style-type: none;
  position: relative;
  padding-left: 3rem;
}

.timeline:before {
  content: ' ';
  background: #f4f4f4;
  display: inline-block;
  position: absolute;
  left: 20px;
  width: 4px;
  height: 100%;
}

.timeline > .timeline-item .timeline-header-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  font-weight: 500;
  margin: 20px 0;
  padding-left: 0.5rem;
}

.timeline > .timeline-item .timeline-header-container .timeline-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  left: 0px;
  border: 5px solid #ffffff;
  position: absolute;
  border-radius: 50%;
}

.timeline > .timeline-item .timeline-header-container .timeline-icon svg {
  width: 20px;
  height: 20px;
}

.timeline > .timeline-item .timeline-header-container .timeline-header {
  font-size: 13px;
  color: #3f3f3f;
  display: flex;
  width: 100%;
  text-transform: capitalize;
}

.timeline > .timeline-item .timeline-header-container .timeline-header a {
  color: inherit;
  text-decoration: none;
  transition: all, 0.3s;
}

@media (prefers-reduced-motion: reduce) {
  .timeline > .timeline-item .timeline-header-container .timeline-header a {
    transition: none;
  }
}

.timeline > .timeline-item .timeline-header-container .timeline-header a:hover {
  color: #ef3e27;
}

.timeline > .timeline-item .timeline-header-container .timeline-header span {
  display: block;
  margin-left: auto;
}

.timeline > .timeline-item .timeline-content {
  color: #ababbb;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 1rem;
}

.timeline > .timeline-item .timeline-sub-content {
  color: #8d8a89;
  font-weight: 400;
  font-size: 12px;
}

.font-icons i {
  font-size: 18px;
  padding-right: 10px;
  color: #3f3f3f;
}

.font-icons .flag-icon {
  margin-right: 10px;
}

.font-icons svg {
  width: 22px;
  height: 22px;
  margin-right: 10px;
  color: #3f3f3f;
}

.font-icons span {
  font-size: 14px;
  color: #3f3f3f;
  font-weight: 300;
}

.task-list-container {
  height: calc(100vh - 150px);
  max-height: 100%;
  position: relative;
}

.task-list-container ul {
  padding: 0;
  text-align: left;
  list-style: none;
  margin-bottom: 0;
}

.task-list-container ul li {
  font-size: 0.9375rem;
  padding: 0.4rem 0;
}

.task-list-container ul li .card .card-header {
  border-bottom: none;
}

.task-list-container ul li .card .card-header .task-header-left {
  line-height: normal;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  text-align: center;
  padding: 0.25rem 0.125rem;
}

.task-list-container ul li .card .card-header .task-header-right {
  text-align: right;
}

.task-list-container ul li .card .card-body {
  padding: 0.75rem 1.25rem;
}

.task-list-container ul li .card .card-body .title {
  font-size: 0.875rem;
  color: #ffffff;
}

.task-list-container ul li .card .card-body .content {
  font-size: 0.8125rem;
}

.task-list-container ul li .card .card-footer {
  border: none;
}

.task-list-container ul li .card .card-footer .actions {
  text-align: right;
}

.task-list-wrapper {
  height: 100%;
  max-height: 100%;
}

.task-list-wrapper ul {
  display: flex;
  flex-direction: column;
  padding: 0;
  text-align: left;
  list-style: none;
  margin-bottom: 0;
}

.task-list-wrapper ul li {
  font-size: 0.9375rem;
  padding: 0.4rem 0;
}

.task-list-wrapper ul li:first-child {
  border-bottom: none;
}

.task-list-wrapper ul li .string-check {
  max-width: 90%;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.task-list-wrapper ul li .string-check .string-check-label {
  color: #3f3f3f;
  font-weight: 500;
}

.task-list-wrapper input[type='checkbox'] {
  margin-right: 15px;
}

.task-list-wrapper .remove {
  cursor: pointer;
  font-size: 1.438rem;
  font-weight: 600;
  width: 1.25rem;
  height: 1.25rem;
  line-height: 20px;
  text-align: center;
}

.task-list-wrapper .completed .string-check-label {
  opacity: 0.4;
}

/* Tooltips */
.tooltip {
  font-size: 0.75rem;
  min-width: 5.625rem;
  z-index: 1029;
}

.tooltip .tooltip-inner {
  font-weight: 400;
}

.tooltip.show {
  opacity: 1;
}

.tooltip-base .tooltip-inner {
  box-shadow: 0px 0px 15px 0px rgba(79, 61, 101, 0.15);
  background: #ffffff;
  color: #8d8a89;
}

.tooltip-base.bs-tooltip-top .arrow::before,
.tooltip-base.bs-tooltip-auto[x-placement^='top'] .arrow::before {
  border-top-color: #ffffff;
}

.tooltip-base.bs-tooltip-right .arrow::before,
.tooltip-base.bs-tooltip-auto[x-placement^='right'] .arrow::before {
  border-right-color: #ffffff;
}

.tooltip-base.bs-tooltip-bottom .arrow::before,
.tooltip-base.bs-tooltip-auto[x-placement^='bottom'] .arrow::before {
  border-bottom-color: #ffffff;
}

.tooltip-base.bs-tooltip-left .arrow::before,
.tooltip-base.bs-tooltip-auto[x-placement^='left'] .arrow::before {
  border-left-color: #ffffff;
}

.tooltip-primary .tooltip-inner {
  background: #ef3e27;
  color: #ffffff;
}

.tooltip-primary.bs-tooltip-top .arrow::before,
.tooltip-primary.bs-tooltip-auto[x-placement^='top'] .arrow::before {
  border-top-color: #ef3e27;
}

.tooltip-primary.bs-tooltip-right .arrow::before,
.tooltip-primary.bs-tooltip-auto[x-placement^='right'] .arrow::before {
  border-right-color: #ef3e27;
}

.tooltip-primary.bs-tooltip-bottom .arrow::before,
.tooltip-primary.bs-tooltip-auto[x-placement^='bottom'] .arrow::before {
  border-bottom-color: #ef3e27;
}

.tooltip-primary.bs-tooltip-left .arrow::before,
.tooltip-primary.bs-tooltip-auto[x-placement^='left'] .arrow::before {
  border-left-color: #ef3e27;
}

.tooltip-secondary .tooltip-inner {
  background: #d3dae6;
  color: #ffffff;
}

.tooltip-secondary.bs-tooltip-top .arrow::before,
.tooltip-secondary.bs-tooltip-auto[x-placement^='top'] .arrow::before {
  border-top-color: #d3dae6;
}

.tooltip-secondary.bs-tooltip-right .arrow::before,
.tooltip-secondary.bs-tooltip-auto[x-placement^='right'] .arrow::before {
  border-right-color: #d3dae6;
}

.tooltip-secondary.bs-tooltip-bottom .arrow::before,
.tooltip-secondary.bs-tooltip-auto[x-placement^='bottom'] .arrow::before {
  border-bottom-color: #d3dae6;
}

.tooltip-secondary.bs-tooltip-left .arrow::before,
.tooltip-secondary.bs-tooltip-auto[x-placement^='left'] .arrow::before {
  border-left-color: #d3dae6;
}

.tooltip-success .tooltip-inner {
  background: #00cb8e;
  color: #ffffff;
}

.tooltip-success.bs-tooltip-top .arrow::before,
.tooltip-success.bs-tooltip-auto[x-placement^='top'] .arrow::before {
  border-top-color: #00cb8e;
}

.tooltip-success.bs-tooltip-right .arrow::before,
.tooltip-success.bs-tooltip-auto[x-placement^='right'] .arrow::before {
  border-right-color: #00cb8e;
}

.tooltip-success.bs-tooltip-bottom .arrow::before,
.tooltip-success.bs-tooltip-auto[x-placement^='bottom'] .arrow::before {
  border-bottom-color: #00cb8e;
}

.tooltip-success.bs-tooltip-left .arrow::before,
.tooltip-success.bs-tooltip-auto[x-placement^='left'] .arrow::before {
  border-left-color: #00cb8e;
}

.tooltip-info .tooltip-inner {
  background: #00aaff;
  color: #ffffff;
}

.tooltip-info.bs-tooltip-top .arrow::before,
.tooltip-info.bs-tooltip-auto[x-placement^='top'] .arrow::before {
  border-top-color: #00aaff;
}

.tooltip-info.bs-tooltip-right .arrow::before,
.tooltip-info.bs-tooltip-auto[x-placement^='right'] .arrow::before {
  border-right-color: #00aaff;
}

.tooltip-info.bs-tooltip-bottom .arrow::before,
.tooltip-info.bs-tooltip-auto[x-placement^='bottom'] .arrow::before {
  border-bottom-color: #00aaff;
}

.tooltip-info.bs-tooltip-left .arrow::before,
.tooltip-info.bs-tooltip-auto[x-placement^='left'] .arrow::before {
  border-left-color: #00aaff;
}

.tooltip-warning .tooltip-inner {
  background: #ffc555;
  color: #ffffff;
}

.tooltip-warning.bs-tooltip-top .arrow::before,
.tooltip-warning.bs-tooltip-auto[x-placement^='top'] .arrow::before {
  border-top-color: #ffc555;
}

.tooltip-warning.bs-tooltip-right .arrow::before,
.tooltip-warning.bs-tooltip-auto[x-placement^='right'] .arrow::before {
  border-right-color: #ffc555;
}

.tooltip-warning.bs-tooltip-bottom .arrow::before,
.tooltip-warning.bs-tooltip-auto[x-placement^='bottom'] .arrow::before {
  border-bottom-color: #ffc555;
}

.tooltip-warning.bs-tooltip-left .arrow::before,
.tooltip-warning.bs-tooltip-auto[x-placement^='left'] .arrow::before {
  border-left-color: #ffc555;
}

.tooltip-danger .tooltip-inner {
  background: #ef3e27;
  color: #ffffff;
}

.tooltip-danger.bs-tooltip-top .arrow::before,
.tooltip-danger.bs-tooltip-auto[x-placement^='top'] .arrow::before {
  border-top-color: #ef3e27;
}

.tooltip-danger.bs-tooltip-right .arrow::before,
.tooltip-danger.bs-tooltip-auto[x-placement^='right'] .arrow::before {
  border-right-color: #ef3e27;
}

.tooltip-danger.bs-tooltip-bottom .arrow::before,
.tooltip-danger.bs-tooltip-auto[x-placement^='bottom'] .arrow::before {
  border-bottom-color: #ef3e27;
}

.tooltip-danger.bs-tooltip-left .arrow::before,
.tooltip-danger.bs-tooltip-auto[x-placement^='left'] .arrow::before {
  border-left-color: #ef3e27;
}

.tooltip-light .tooltip-inner {
  background: #f9fafb;
  color: #ffffff;
}

.tooltip-light.bs-tooltip-top .arrow::before,
.tooltip-light.bs-tooltip-auto[x-placement^='top'] .arrow::before {
  border-top-color: #f9fafb;
}

.tooltip-light.bs-tooltip-right .arrow::before,
.tooltip-light.bs-tooltip-auto[x-placement^='right'] .arrow::before {
  border-right-color: #f9fafb;
}

.tooltip-light.bs-tooltip-bottom .arrow::before,
.tooltip-light.bs-tooltip-auto[x-placement^='bottom'] .arrow::before {
  border-bottom-color: #f9fafb;
}

.tooltip-light.bs-tooltip-left .arrow::before,
.tooltip-light.bs-tooltip-auto[x-placement^='left'] .arrow::before {
  border-left-color: #f9fafb;
}

.tooltip-dark .tooltip-inner {
  background: #242a34;
  color: #ffffff;
}

.tooltip-dark.bs-tooltip-top .arrow::before,
.tooltip-dark.bs-tooltip-auto[x-placement^='top'] .arrow::before {
  border-top-color: #242a34;
}

.tooltip-dark.bs-tooltip-right .arrow::before,
.tooltip-dark.bs-tooltip-auto[x-placement^='right'] .arrow::before {
  border-right-color: #242a34;
}

.tooltip-dark.bs-tooltip-bottom .arrow::before,
.tooltip-dark.bs-tooltip-auto[x-placement^='bottom'] .arrow::before {
  border-bottom-color: #242a34;
}

.tooltip-dark.bs-tooltip-left .arrow::before,
.tooltip-dark.bs-tooltip-auto[x-placement^='left'] .arrow::before {
  border-left-color: #242a34;
}

.errors {
  display: flex;
  width: 100%;
}

.errors.error-v1 {
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.errors.error-v1 .error-image {
  width: 100%;
}

.errors.error-v1 .error-title {
  font-weight: 600;
  font-size: 50px;
  color: #3f3f3f;
}

.errors.error-v1 .error-description {
  font-weight: 400;
  color: #8d8a89;
}

.errors.error-v2 {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: transperant;
}

.errors.error-v2 .error-right {
  text-align: center;
}

.errors.error-v2 .error-right .error-image {
  width: 100%;
  height: auto;
}

.errors.error-v2 .error-left {
  display: flex;
  align-items: center;
  justify-content: center;
}

.errors.error-v2 .error-left .error-title {
  font-weight: 600;
  font-size: 50px;
  color: #3f3f3f;
}

.errors.error-v2 .error-left .error-description {
  font-weight: 400;
  color: #8d8a89;
}

.user-login.login-v1 {
  background: url(/static/media/user-login-bg-1.7a5bb3c5.jpg);
  background-color: #ffffff;
}

.user-login.login-v1 .login-header {
  display: flex;
  padding: 3rem;
  align-items: center;
}

.user-login.login-v1 .login-header .logo-container {
  text-align: left;
  width: 50%;
}

.user-login.login-v1 .login-header .logo-container img {
  width: 50px;
}

.user-login.login-v1 .login-header .link-container {
  text-align: right;
  width: 50%;
}

.user-login.login-v1 .login-header .link-container a {
  font-weight: 500;
  color: #ffffff;
  font-size: 14px;
  padding-left: 0.5rem;
}

.user-login.login-v1 .user-login-content {
  padding: 3rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-left: 1px solid rgba(255, 255, 255, 0.15);
}

.user-login.login-v1 .user-login-content .login-title {
  color: #ffffff;
  text-align: center;
  margin-bottom: 1.5rem;
}

.user-login.login-v1 .user-login-content .form-control,
.user-login.login-v1
  .user-login-content
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type='text'],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .user-login.login-v1
  .user-login-content
  input[type='text'],
.user-login.login-v1
  .user-login-content
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  select,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .user-login.login-v1
  .user-login-content
  select,
.user-login.login-v1
  .user-login-content
  .jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  input[type='text'],
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .user-login.login-v1
  .user-login-content
  input[type='text'],
.user-login.login-v1
  .user-login-content
  .jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  select,
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .user-login.login-v1
  .user-login-content
  select,
.user-login.login-v1
  .user-login-content
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type='number'],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .user-login.login-v1
  .user-login-content
  input[type='number'],
.user-login.login-v1
  .user-login-content
  .jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  input[type='number'],
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .user-login.login-v1
  .user-login-content
  input[type='number'] {
  height: 2.8rem;
  background: transparent;
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.1);
  border-radius: 2.8rem;
}

.user-login.login-v1 .user-login-content .separator {
  margin: 1.5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-login.login-v1 .user-login-content .separator span:not(:first-child),
.user-login.login-v1 .user-login-content .separator span:not(:last-child) {
  color: #ffffff;
  padding: 0 1.5rem;
}

.user-login.login-v1 .user-login-content .separator span:last-child,
.user-login.login-v1 .user-login-content .separator span:first-child {
  flex: 1 1;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.user-login.login-v1 .user-login-content .user-login-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-login.login-v1 .user-login-content .user-login-footer .btn i,
.user-login.login-v1
  .user-login-content
  .user-login-footer
  .wizard
  > .actions
  a
  i,
.wizard
  > .actions
  .user-login.login-v1
  .user-login-content
  .user-login-footer
  a
  i,
.user-login.login-v1 .user-login-content .user-login-footer .fc button i,
.fc .user-login.login-v1 .user-login-content .user-login-footer button i {
  padding-right: 5px;
}

.user-login.login-v1
  .user-login-content
  .user-login-footer
  .btn:not(:first-child):not(:last-child),
.user-login.login-v1
  .user-login-content
  .user-login-footer
  .wizard
  > .actions
  a:not(:first-child):not(:last-child),
.wizard
  > .actions
  .user-login.login-v1
  .user-login-content
  .user-login-footer
  a:not(:first-child):not(:last-child),
.user-login.login-v1
  .user-login-content
  .user-login-footer
  .fc
  button:not(:first-child):not(:last-child),
.fc
  .user-login.login-v1
  .user-login-content
  .user-login-footer
  button:not(:first-child):not(:last-child) {
  margin: 0 1rem;
}

.user-login.login-v1 .user-login-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem;
  height: 100%;
}

.user-login.login-v1 .user-login-info .info-title {
  color: #ffffff;
  font-size: 1.7rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.user-login.login-v1 .user-login-info .info-description {
  color: rgba(255, 255, 255, 0.5);
  font-size: 1rem;
}

@media (min-width: 700px) and (max-width: 1024px) {
  .user-login.login-v1 .login-header {
    padding: 2rem;
    flex-flow: column;
  }
  .user-login.login-v1 .login-header .logo-container {
    width: 100%;
  }
  .user-login.login-v1 .login-header .link-container {
    width: 100%;
    text-align: center;
    margin-top: 1rem;
  }
  .user-login.login-v1 .user-login-content {
    margin: 0 auto;
    max-width: 500px;
    border-left: 0;
    background: none;
  }
  .user-login.login-v1 .user-login-info {
    max-width: 500px;
    text-align: center;
    margin: 0 auto;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }
}

@media (max-width: 575px) {
  .user-login.login-v1 .login-header {
    padding: 2rem;
    flex-flow: column;
  }
  .user-login.login-v1 .login-header .logo-container {
    width: 100%;
  }
  .user-login.login-v1 .login-header .link-container {
    width: 100%;
    text-align: center;
    margin-top: 1rem;
  }
  .user-login.login-v1 .user-login-content {
    padding: 1rem;
  }
  .user-login.login-v1 .user-login-content .user-login-footer button {
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.53125rem 0.625rem;
  }
  .user-login.login-v1 .user-login-content .user-login-footer button i {
    font-size: 0.75rem;
  }
  .user-login.login-v1 .user-login-info {
    padding: 1rem;
    text-align: center;
  }
}

.user-login.login-v2 {
  width: 100%;
}

.user-login.login-v2 .login-header {
  display: flex;
  padding: 3rem;
  align-items: center;
}

.user-login.login-v2 .login-header .logo-container {
  text-align: left;
  width: 50%;
}

.user-login.login-v2 .login-header .logo-container img {
  width: 150px;
}

.user-login.login-v2 .login-header .link-container {
  text-align: right;
  width: 50%;
}

.user-login.login-v2 .login-header .link-container a {
  font-weight: 500;
  color: #3f3f3f;
  font-size: 14px;
  padding-left: 0.5rem;
}

.user-login.login-v2 .card {
  box-shadow: 0 7px 34px 1px #c1bed1;
  -webkit-box-shadow: 0 7px 34px 1px #c1bed1;
  -moz-box-shadow: 0 7px 34px 1px #c1bed1;
  -ms-box-shadow: 0 7px 34px 1px #c1bed1;
}

.user-login.login-v2 .login-left-section {
  background: url(/static/media/login-bg.fc89cc5e.jpg) no-repeat;

  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  justify-content: center;
}

.user-login.login-v2 .login-left-section .login-title {
  color: #ffffff;
  font-size: 1.7rem;
  font-weight: 500;
  margin-bottom: 1rem;
  line-height: 1.2;
}

.user-login.login-v2 .login-left-section .login-descritpion {
  color: rgba(255, 255, 255, 0.5);
  font-size: 1rem;
}

.user-login.login-v2 .login-left-section .login-copyright {
  color: #ffffff;
  font-size: 0.8125rem;
  text-align: center;
}

.user-login.login-v2 .user-login-content {
  padding: 1.5rem;
}

.user-login.login-v2 .user-login-content .login-title {
  color: #3f3f3f;
  text-align: center;
  margin-bottom: 1.5rem;
}

.user-login.login-v2 .user-login-content .form-control,
.user-login.login-v2
  .user-login-content
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type='text'],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .user-login.login-v2
  .user-login-content
  input[type='text'],
.user-login.login-v2
  .user-login-content
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  select,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .user-login.login-v2
  .user-login-content
  select,
.user-login.login-v2
  .user-login-content
  .jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  input[type='text'],
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .user-login.login-v2
  .user-login-content
  input[type='text'],
.user-login.login-v2
  .user-login-content
  .jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  select,
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .user-login.login-v2
  .user-login-content
  select,
.user-login.login-v2
  .user-login-content
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type='number'],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .user-login.login-v2
  .user-login-content
  input[type='number'],
.user-login.login-v2
  .user-login-content
  .jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  input[type='number'],
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .user-login.login-v2
  .user-login-content
  input[type='number'] {
  height: 2.8rem;
  background: transparent;
  color: #3f3f3f;
  border-radius: 2.8rem;
}

.user-login.login-v2 .user-login-content .user-login-footer {
  display: flex;
  justify-content: center;
}

.user-login.login-v2 .user-login-content .user-login-footer .btn,
.user-login.login-v2
  .user-login-content
  .user-login-footer
  .wizard
  > .actions
  a,
.wizard
  > .actions
  .user-login.login-v2
  .user-login-content
  .user-login-footer
  a,
.user-login.login-v2 .user-login-content .user-login-footer .fc button,
.fc .user-login.login-v2 .user-login-content .user-login-footer button {
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.53125rem 0.625rem;
}

.user-login.login-v2 .user-login-content .user-login-footer .btn i,
.user-login.login-v2
  .user-login-content
  .user-login-footer
  .wizard
  > .actions
  a
  i,
.wizard
  > .actions
  .user-login.login-v2
  .user-login-content
  .user-login-footer
  a
  i,
.user-login.login-v2 .user-login-content .user-login-footer .fc button i,
.fc .user-login.login-v2 .user-login-content .user-login-footer button i {
  font-size: 0.75rem;
}

.user-login.login-v2
  .user-login-content
  .user-login-footer
  .btn:not(:first-child):not(:last-child),
.user-login.login-v2
  .user-login-content
  .user-login-footer
  .wizard
  > .actions
  a:not(:first-child):not(:last-child),
.wizard
  > .actions
  .user-login.login-v2
  .user-login-content
  .user-login-footer
  a:not(:first-child):not(:last-child),
.user-login.login-v2
  .user-login-content
  .user-login-footer
  .fc
  button:not(:first-child):not(:last-child),
.fc
  .user-login.login-v2
  .user-login-content
  .user-login-footer
  button:not(:first-child):not(:last-child) {
  margin: 0 1rem;
}

.user-login.login-v2 .user-login-content .divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin: 1.5rem 0;
}

.user-login.login-v2 .user-login-content .user-login-footer-links {
  text-align: center;
}

.user-login.login-v2 .user-login-content .separator {
  margin: 1.5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-login.login-v2 .user-login-content .separator span:not(:first-child),
.user-login.login-v2 .user-login-content .separator span:not(:last-child) {
  padding: 0 1.5rem;
}

.user-login.login-v2 .user-login-content .separator span:last-child,
.user-login.login-v2 .user-login-content .separator span:first-child {
  flex: 1 1;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

@media (max-width: 575px) {
  .user-login.login-v2 .login-header {
    padding: 2rem;
    flex-flow: column;
  }
  .user-login.login-v2 .login-header .logo-container {
    width: 100%;
    text-align: center;
  }
  .user-login.login-v2 .login-header .link-container {
    width: 100%;
    margin-top: 1rem;
    text-align: center;
  }
  .user-login.login-v2 .login-left-section {
    padding: 1rem;
    min-height: 300px;
  }
  .user-login.login-v2 .user-login-content {
    padding: 1rem;
  }
  .user-login.login-v2 .user-login-content .user-login-footer .btn,
  .user-login.login-v2
    .user-login-content
    .user-login-footer
    .wizard
    > .actions
    a,
  .wizard
    > .actions
    .user-login.login-v2
    .user-login-content
    .user-login-footer
    a,
  .user-login.login-v2 .user-login-content .user-login-footer .fc button,
  .fc .user-login.login-v2 .user-login-content .user-login-footer button {
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.53125rem 0.5rem;
  }
  .user-login.login-v2 .user-login-content .user-login-footer .btn i,
  .user-login.login-v2
    .user-login-content
    .user-login-footer
    .wizard
    > .actions
    a
    i,
  .wizard
    > .actions
    .user-login.login-v2
    .user-login-content
    .user-login-footer
    a
    i,
  .user-login.login-v2 .user-login-content .user-login-footer .fc button i,
  .fc .user-login.login-v2 .user-login-content .user-login-footer button i {
    font-size: 0.75rem;
  }
  .user-login.login-v2
    .user-login-content
    .user-login-footer
    .btn:not(:first-child):not(:last-child),
  .user-login.login-v2
    .user-login-content
    .user-login-footer
    .wizard
    > .actions
    a:not(:first-child):not(:last-child),
  .wizard
    > .actions
    .user-login.login-v2
    .user-login-content
    .user-login-footer
    a:not(:first-child):not(:last-child),
  .user-login.login-v2
    .user-login-content
    .user-login-footer
    .fc
    button:not(:first-child):not(:last-child),
  .fc
    .user-login.login-v2
    .user-login-content
    .user-login-footer
    button:not(:first-child):not(:last-child) {
    margin: 0 0.625rem;
  }
}

.user-login.login-v3 {
  width: 100%;
}

.user-login.login-v3 .logo-container {
  text-align: center;
  margin-bottom: 1.5rem;
}

.user-login.login-v3 .logo-container img {
  width: 150px;
}

.user-login.login-v3 .user-login-content {
  padding: 1.5rem;
}

.user-login.login-v3 .user-login-content .login-title {
  color: #3f3f3f;
  text-align: center;
  margin-bottom: 1.5rem;
}

.user-login.login-v3 .user-login-content .form-control,
.user-login.login-v3
  .user-login-content
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type='text'],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .user-login.login-v3
  .user-login-content
  input[type='text'],
.user-login.login-v3
  .user-login-content
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  select,
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .user-login.login-v3
  .user-login-content
  select,
.user-login.login-v3
  .user-login-content
  .jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  input[type='text'],
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .user-login.login-v3
  .user-login-content
  input[type='text'],
.user-login.login-v3
  .user-login-content
  .jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  select,
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .user-login.login-v3
  .user-login-content
  select,
.user-login.login-v3
  .user-login-content
  .jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  input[type='number'],
.jsgrid
  .jsgrid-table
  .jsgrid-filter-row
  .user-login.login-v3
  .user-login-content
  input[type='number'],
.user-login.login-v3
  .user-login-content
  .jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  input[type='number'],
.jsgrid
  .jsgrid-table
  .jsgrid-edit-row
  .user-login.login-v3
  .user-login-content
  input[type='number'] {
  height: 2.8rem;
  background: transparent;
  color: #3f3f3f;
}

.user-login.login-v3 .user-login-content .user-login-footer {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.user-login.login-v3 .user-login-content .user-login-footer .btn,
.user-login.login-v3
  .user-login-content
  .user-login-footer
  .wizard
  > .actions
  a,
.wizard
  > .actions
  .user-login.login-v3
  .user-login-content
  .user-login-footer
  a,
.user-login.login-v3 .user-login-content .user-login-footer .fc button,
.fc .user-login.login-v3 .user-login-content .user-login-footer button {
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.53125rem 0.625rem;
  text-transform: uppercase;
  letter-spacing: 0.4px;
}

.user-login.login-v3 .user-login-content .user-login-footer .btn i,
.user-login.login-v3
  .user-login-content
  .user-login-footer
  .wizard
  > .actions
  a
  i,
.wizard
  > .actions
  .user-login.login-v3
  .user-login-content
  .user-login-footer
  a
  i,
.user-login.login-v3 .user-login-content .user-login-footer .fc button i,
.fc .user-login.login-v3 .user-login-content .user-login-footer button i {
  font-size: 0.75rem;
}

.user-login.login-v3 .user-login-content .divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin: 1.5rem 0;
}

.user-login.login-v3 .user-login-content .user-login-footer-links {
  text-align: center;
}

.user-login.login-v3 .user-login-content .separator {
  margin: 1.5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-login.login-v3 .user-login-content .separator span:not(:first-child),
.user-login.login-v3 .user-login-content .separator span:not(:last-child) {
  padding: 0 1.5rem;
}

.user-login.login-v3 .user-login-content .separator span:last-child,
.user-login.login-v3 .user-login-content .separator span:first-child {
  flex: 1 1;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

@media (max-width: 575px) {
  .user-login.login-v3 .user-login-content {
    padding: 1rem;
  }
  .user-login.login-v3 .user-login-content .user-login-footer .btn,
  .user-login.login-v3
    .user-login-content
    .user-login-footer
    .wizard
    > .actions
    a,
  .wizard
    > .actions
    .user-login.login-v3
    .user-login-content
    .user-login-footer
    a,
  .user-login.login-v3 .user-login-content .user-login-footer .fc button,
  .fc .user-login.login-v3 .user-login-content .user-login-footer button {
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.53125rem 0.5rem;
  }
  .user-login.login-v3 .user-login-content .user-login-footer .btn i,
  .user-login.login-v3
    .user-login-content
    .user-login-footer
    .wizard
    > .actions
    a
    i,
  .wizard
    > .actions
    .user-login.login-v3
    .user-login-content
    .user-login-footer
    a
    i,
  .user-login.login-v3 .user-login-content .user-login-footer .fc button i,
  .fc .user-login.login-v3 .user-login-content .user-login-footer button i {
    font-size: 0.75rem;
  }
}

.invoice .invoice-header {
  display: flex;
  padding: 4rem;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #2196f3;
}

.invoice .invoice-header .invoice-address {
  width: 50%;
  color: rgba(255, 255, 255, 0.7);
}

.invoice .invoice-header .invoice-address .title {
  color: #ffffff;
  font-weight: 700;
  font-size: 1rem;
}

.invoice .invoice-header .invoice-address .invoice-from-address {
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 2rem;
}

.invoice .invoice-header .invoice-address .invoice-to-address {
  font-weight: 500;
  font-size: 1rem;
}

.invoice .invoice-header .invoice-info {
  margin-top: auto;
}

.invoice .invoice-header .invoice-info .title {
  color: #ffffff;
  font-weight: 700;
  font-size: 1.7rem;
  text-transform: uppercase;
}

.invoice .invoice-header .invoice-info .invoice-basic-details span {
  font-weight: 500;
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.7);
}

.invoice
  .invoice-header
  .invoice-info
  .invoice-basic-details
  span:first-child:after {
  padding: 0 1rem;
  content: '|';
}

@media (max-width: 575px) {
  .invoice .invoice-header {
    padding: 2rem;
    display: flex;
    flex-flow: column;
  }
  .invoice .invoice-header .invoice-address {
    width: 100%;
    margin-bottom: 1rem;
  }
  .invoice .invoice-header .invoice-info {
    width: 100%;
  }
}

.invoice .invoice-body {
  padding: 4rem;
}

@media (max-width: 575px) {
  .invoice .invoice-body {
    padding: 2rem;
  }
}

.invoice .invoice-body table thead th {
  border-top: 0;
  font-weight: 500;
  font-size: 1rem;
  color: #2196f3;
  border-color: #2196f3;
  text-transform: uppercase;
}

.invoice .invoice-body table tbody td {
  font-weight: 500;
  color: #88939d;
  font-size: 14px;
  line-height: 1.5;
}

.invoice .invoice-body table tbody td.hg-amount {
  font-size: 1rem;
  color: #2196f3;
  font-weight: 600;
}

.invoice .invoice-body table tbody td.final-amount {
  font-size: 1.5rem;
  color: #2196f3;
  font-weight: 700;
}

.invoice .invoice-body .invoice-footer {
  padding-top: 2rem;
  text-align: center;
}

.invoice .invoice-body .invoice-footer .btn-invoice {
  background-color: #2196f3;
  border-color: #2196f3;
  box-shadow: none;
}

/* App - Email */
.email {
  display: flex;
}

.email .email-toolbars-wrapper {
  width: 20%;
}

.email .email-toolbars-wrapper .toolbar-header {
  padding: 1rem;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}

.email .email-toolbars-wrapper .toolbar-body .toolbar-menu {
  padding: 0;
  margin-bottom: 1rem;
  height: auto;
  list-style-type: none;
}

.email .email-toolbars-wrapper .toolbar-body .toolbar-menu li {
  padding: 0.5rem 1rem;
  transition: 0.4s;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.email .email-toolbars-wrapper .toolbar-body .toolbar-menu li:hover {
  background: #ececec;
}

.email .email-toolbars-wrapper .toolbar-body .toolbar-menu li a {
  color: #3f3f3f;
  font-size: 13px;
  text-decoration: none;
}

.email .email-toolbars-wrapper .toolbar-body .toolbar-menu li a svg {
  margin-right: 8px;
  width: 1rem;
  height: 1rem;
  line-height: 1.5;
}

.email .email-toolbars-wrapper .toolbar-body .toolbar-menu li.active {
  border-left: 2px solid #ef3e27;
  background: #ececec;
}

.email .email-toolbars-wrapper .toolbar-body .toolbar-menu li.active a {
  color: #ef3e27;
}

.email .email-toolbars-wrapper .toolbar-body .contact-header {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.email .email-toolbars-wrapper .toolbar-body .contact-header .contact-left {
  display: flex;
}

.email
  .email-toolbars-wrapper
  .toolbar-body
  .contact-header
  .contact-left
  .title {
  margin: 0 1rem 0 0;
}

.email .email-toolbars-wrapper .toolbar-body .contact-header .dropdown {
  float: right;
}

.email .email-toolbars-wrapper .toolbar-body .contact-list {
  padding: 0 1rem;
  list-style-type: none;
}

.email .email-toolbars-wrapper .toolbar-body .contact-list .contact-list-item {
  padding: 0.625rem 0;
  display: block;
}

.email
  .email-toolbars-wrapper
  .toolbar-body
  .contact-list
  .contact-list-item:last-child {
  border-bottom: 0;
}

.email
  .email-toolbars-wrapper
  .toolbar-body
  .contact-list
  .contact-list-item
  a {
  text-decoration: none;
}

.email
  .email-toolbars-wrapper
  .toolbar-body
  .contact-list
  .contact-list-item
  a
  .pro-pic {
  display: flex;
  align-items: center;
  padding: 0;
  width: 20%;
  max-width: 40px;
  position: relative;
}

.email
  .email-toolbars-wrapper
  .toolbar-body
  .contact-list
  .contact-list-item
  a
  .pro-pic
  img {
  max-width: 100%;
  width: 100%;
  border-radius: 100%;
}

.email
  .email-toolbars-wrapper
  .toolbar-body
  .contact-list
  .contact-list-item
  a
  .pro-pic
  .active {
  width: 12px;
  height: 12px;
  background: #00cb8e;
  border-radius: 100%;
  position: absolute;
  top: 6px;
  right: -4px;
  border: 2px solid #ffffff;
}

.email
  .email-toolbars-wrapper
  .toolbar-body
  .contact-list
  .contact-list-item
  a
  .pro-pic
  .inactive {
  width: 12px;
  height: 12px;
  background: #d3dae6;
  border-radius: 100%;
  position: absolute;
  top: 6px;
  right: -4px;
  border: 2px solid #ffffff;
}

.email
  .email-toolbars-wrapper
  .toolbar-body
  .contact-list
  .contact-list-item
  a
  .pro-pic
  .busy {
  width: 12px;
  height: 12px;
  background: #ef3e27;
  border-radius: 100%;
  position: absolute;
  top: 6px;
  right: -4px;
  border: 2px solid #ffffff;
}

.email
  .email-toolbars-wrapper
  .toolbar-body
  .contact-list
  .contact-list-item
  a
  .user {
  width: 100%;
  padding: 5px 10px 0 15px;
}

.email
  .email-toolbars-wrapper
  .toolbar-body
  .contact-list
  .contact-list-item
  a
  .user
  .user-name {
  margin: 0;
  font-weight: 400;
  font-size: 13px;
  line-height: 1;
  color: #3f3f3f;
}

.email
  .email-toolbars-wrapper
  .toolbar-body
  .contact-list
  .contact-list-item
  a
  .user
  .user-designation {
  font-size: 12px;
  color: #ababbb;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
  margin-bottom: 0;
}

.email .email-list-wrapper {
  border-left: 1px solid #e6e4e9;
  width: 30%;
}

.email .email-list-wrapper .email-list-scroll-container {
  position: relative;
  height: calc(100% - 80px);
}

.email .email-list-wrapper .email-list-header {
  padding: 1rem;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e6e4e9;
}

.email .email-list-wrapper .email-list {
  list-style-type: none;
  padding: 0;
  height: calc(100vh - 70px);
}

.email .email-list-wrapper .email-list .email-list-item {
  padding: 0.625rem 1rem;
  display: flex;
  border-bottom: 1px solid #e6e4e9;
  text-decoration: none;
}

.email .email-list-wrapper .email-list .email-list-item.active {
  border-left: 2px solid #ef3e27;
  background: #ececec;
}

.email .email-list-wrapper .email-list .email-list-item.active a {
  color: #ef3e27 !important;
}

.email .email-list-wrapper .email-list .email-list-item .pro-pic {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 0;
  width: 20%;
  max-width: 40px;
}

.email .email-list-wrapper .email-list .email-list-item .pro-pic img {
  max-width: 100%;
  width: 100%;
  border-radius: 100%;
}

.email .email-list-wrapper .email-list .email-list-item .pro-pic .attachment {
  margin-top: 1rem;
  transition: 0.3s;
  color: #ababbb;
}

.email
  .email-list-wrapper
  .email-list
  .email-list-item
  .pro-pic
  .attachment
  svg {
  width: 18px;
  height: 18px;
}

.email
  .email-list-wrapper
  .email-list
  .email-list-item
  .pro-pic
  .attachment:hover {
  color: #ef3e27;
}

.email .email-list-wrapper .email-list .email-list-item .email-content {
  padding-left: 1rem;
}

.email
  .email-list-wrapper
  .email-list
  .email-list-item
  .email-content
  .recipient {
  width: 100%;
}

.email
  .email-list-wrapper
  .email-list
  .email-list-item
  .email-content
  .recipient
  .recipient-name {
  margin: 0;
  font-size: 12px;
  line-height: 1;
  color: #ababbb;
}

.email
  .email-list-wrapper
  .email-list
  .email-list-item
  .email-content
  .recipient
  .recipient-time {
  color: #ababbb;
  line-height: 1;
  font-size: 12px;
}

.email
  .email-list-wrapper
  .email-list
  .email-list-item
  .email-content
  .email-subject {
  display: flex;
  align-items: center;
  color: #3f3f3f;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0.625rem;
  text-decoration: none;
  transition: 0.3s;
}

.email
  .email-list-wrapper
  .email-list
  .email-list-item
  .email-content
  .email-subject:hover {
  color: #ef3e27;
}

.email
  .email-list-wrapper
  .email-list
  .email-list-item
  .email-content
  .email-subject
  .starred {
  margin-left: 1rem;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 100%;
  display: block;
  background: #ef3e27;
}

.email
  .email-list-wrapper
  .email-list
  .email-list-item
  .email-content
  .email-text {
  font-size: 13px;
  line-height: 1.3;
  color: #ababbb;
}

.email .email-desc-wrapper {
  border-left: 1px solid #e6e4e9;
  width: 50%;
}

.email .email-desc-wrapper .email-desc-header {
  padding: 1rem;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 1px solid #e6e4e9;
}

.email .email-desc-wrapper .email-desc-header .btn,
.email .email-desc-wrapper .email-desc-header .wizard > .actions a,
.wizard > .actions .email .email-desc-wrapper .email-desc-header a,
.email .email-desc-wrapper .email-desc-header .fc button,
.fc .email .email-desc-wrapper .email-desc-header button {
  display: flex;
  align-items: center;
}

.email .email-desc-wrapper .email-desc-header .btn i,
.email .email-desc-wrapper .email-desc-header .wizard > .actions a i,
.wizard > .actions .email .email-desc-wrapper .email-desc-header a i,
.email .email-desc-wrapper .email-desc-header .fc button i,
.fc .email .email-desc-wrapper .email-desc-header button i {
  margin-right: 0.5rem;
  font-size: 0.875rem;
}

.email .email-desc-wrapper .email-desc-body-wrapper .email-header {
  padding: 1rem;
}

.email
  .email-desc-wrapper
  .email-desc-body-wrapper
  .email-header
  .email-subject {
  color: #3f3f3f;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0.625rem;
}

.email
  .email-desc-wrapper
  .email-desc-body-wrapper
  .email-header
  .email-recipient {
  text-decoration: none;
}

.email
  .email-desc-wrapper
  .email-desc-body-wrapper
  .email-header
  .email-recipient
  .pro-pic {
  display: flex;
  align-items: center;
  padding: 0;
  width: 20%;
  max-width: 40px;
  position: relative;
}

.email
  .email-desc-wrapper
  .email-desc-body-wrapper
  .email-header
  .email-recipient
  .pro-pic
  img {
  max-width: 100%;
  width: 100%;
  border-radius: 100%;
}

.email
  .email-desc-wrapper
  .email-desc-body-wrapper
  .email-header
  .email-recipient
  .email {
  width: 100%;
  padding: 5px 10px 0 15px;
}

.email
  .email-desc-wrapper
  .email-desc-body-wrapper
  .email-header
  .email-recipient
  .email
  .recipient {
  margin: 0;
  font-weight: 400;
  font-size: 13px;
  line-height: 1;
  color: #3f3f3f;
}

.email
  .email-desc-wrapper
  .email-desc-body-wrapper
  .email-header
  .email-recipient
  .email
  .time {
  font-size: 12px;
  color: #ababbb;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
  margin-bottom: 0;
}

.email .email-desc-wrapper .email-desc-body-wrapper .email-body-wrapper {
  position: relative;
  height: 442px;
}

.email
  .email-desc-wrapper
  .email-desc-body-wrapper
  .email-body-wrapper
  .email-body {
  height: 350px;
  padding: 1rem 3rem;
  color: #a6a8be;
  font-size: 13px;
}

.email
  .email-desc-wrapper
  .email-desc-body-wrapper
  .email-body-wrapper
  .email-body
  p {
  font-size: 13px;
}

.email
  .email-desc-wrapper
  .email-desc-body-wrapper
  .email-body-wrapper
  .email-body
  .attachments-sections
  ul {
  list-style: none;
  border-top: 1px solid #e6e4e9;
  padding: 1rem 0;
}

.email
  .email-desc-wrapper
  .email-desc-body-wrapper
  .email-body-wrapper
  .email-body
  .attachments-sections
  ul
  li {
  padding: 10px;
  margin: 0 0.875rem 0.875rem 0;
  border: 1px solid #e6e4e9;
  border-radius: 5px;
}

.email
  .email-desc-wrapper
  .email-desc-body-wrapper
  .email-body-wrapper
  .email-body
  .attachments-sections
  ul
  li
  .thumb {
  display: inline-block;
  margin-right: 10px;
}

.email
  .email-desc-wrapper
  .email-desc-body-wrapper
  .email-body-wrapper
  .email-body
  .attachments-sections
  ul
  li
  .thumb
  i {
  font-size: 20px;
  margin: 0;
  color: #3f3f3f;
}

.email
  .email-desc-wrapper
  .email-desc-body-wrapper
  .email-body-wrapper
  .email-body
  .attachments-sections
  ul
  li
  .details
  p.file-name {
  display: block;
  margin-bottom: 0;
  color: #3f3f3f;
  font-weight: 500;
}

.email
  .email-desc-wrapper
  .email-desc-body-wrapper
  .email-body-wrapper
  .email-body
  .attachments-sections
  ul
  li
  .details
  .buttons
  .file-size {
  margin-right: 10px;
  margin-bottom: 0;
  font-size: 12px;
}

.email
  .email-desc-wrapper
  .email-desc-body-wrapper
  .email-body-wrapper
  .email-body
  .attachments-sections
  ul
  li
  .details
  .buttons
  a {
  font-size: 12px;
  margin-right: 10px;
  transition: 0.3s;
  text-decoration: none;
  color: #3f3f3f;
}

.email
  .email-desc-wrapper
  .email-desc-body-wrapper
  .email-body-wrapper
  .email-body
  .attachments-sections
  ul
  li
  .details
  .buttons
  a:last-child {
  margin-right: 0;
}

.email
  .email-desc-wrapper
  .email-desc-body-wrapper
  .email-body-wrapper
  .email-body
  .attachments-sections
  ul
  li
  .details
  .buttons
  a:hover {
  color: #ef3e27;
}

.email .email-desc-wrapper .email-desc-body-wrapper .email-footer-wrapper {
  border-top: 1px solid #e6e4e9;
  border-bottom: 1px solid #e6e4e9;
}

.email
  .email-desc-wrapper
  .email-desc-body-wrapper
  .email-footer-wrapper
  .email-footer-message {
  display: flex;
  justify-content: flex-end;
}

.email
  .email-desc-wrapper
  .email-desc-body-wrapper
  .email-footer-wrapper
  .email-footer-message
  textarea {
  flex-grow: 1;
  border: 0;
  line-height: 100%;
  background: #ffffff;
  padding: 0.625rem;
  color: #3f3f3f;
}

.email
  .email-desc-wrapper
  .email-desc-body-wrapper
  .email-footer-wrapper
  .email-footer-message
  a {
  border-left: 1px solid #e6e4e9;
  background: #ffffff;
  color: #ef3e27;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 575px) {
  .email .email-toolbars-wrapper,
  .email .email-desc-wrapper {
    display: none;
  }
  .email .email-list-wrapper {
    width: 100%;
  }
}

@media (min-width: 600px) and (max-width: 1024px) {
  .email .email-toolbars-wrapper {
    display: none;
  }
  .email .email-desc-wrapper {
    width: 60%;
  }
  .email .email-list-wrapper {
    width: 40%;
  }
}

.profile-navbar .nav-item .nav-link {
  color: #222222;
}

.profile-navbar .nav-item .nav-link.active {
  color: #ef3e27;
}

.profile-navbar .nav-item .nav-link i {
  font-size: 1.25rem;
}

.profile-feed-item {
  padding: 1.5rem 0;
  border-bottom: 1px solid #e6e4e9;
}

.widget-1 {
  display: flex;
  align-items: center;
}

.widget-1 .widget-1-statistics {
  width: 50%;
}

.widget-1 .widget-1-statistics .widget-1-title {
  margin-bottom: 10px;
  color: #3f3f3f;
}

.widget-1 .widget-1-statistics .widget-1-content {
  font-weight: 500;
}

.widget-1 .widget-1-statistics .widget-1-content .widget-1-legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

.widget-1 .widget-1-statistics .widget-1-content .widget-1-legend-item i {
  border-radius: 100%;
  width: 5px;
  height: 5px;
  margin-right: 1rem;
}

.widget-1
  .widget-1-statistics
  .widget-1-content
  .widget-1-legend-item
  .widget-1-option-1-indicator-a {
  background: #ef3e27;
}

.widget-1
  .widget-1-statistics
  .widget-1-content
  .widget-1-legend-item
  .widget-1-option-1-indicator-b {
  background: #aca9bb;
}

.widget-1
  .widget-1-statistics
  .widget-1-content
  .widget-1-legend-item
  .widget-1-option-1-indicator-c {
  background: #474554;
}

.widget-1
  .widget-1-statistics
  .widget-1-content
  .widget-1-legend-item
  .widget-1-option-2-indicator-a {
  background: #ef3e27;
}

.widget-1
  .widget-1-statistics
  .widget-1-content
  .widget-1-legend-item
  .widget-1-option-2-indicator-b {
  background: #1ace1a;
}

.widget-1
  .widget-1-statistics
  .widget-1-content
  .widget-1-legend-item
  .widget-1-option-2-indicator-c {
  background: #e0631a;
}

.widget-1
  .widget-1-statistics
  .widget-1-content
  .widget-1-legend-item
  .widget-1-option-3-indicator-a {
  background: #ef3e27;
}

.widget-1
  .widget-1-statistics
  .widget-1-content
  .widget-1-legend-item
  .widget-1-option-3-indicator-b {
  background: #ff559f;
}

.widget-1
  .widget-1-statistics
  .widget-1-content
  .widget-1-legend-item
  .widget-1-option-3-indicator-c {
  background: #ffc555;
}

.widget-1
  .widget-1-statistics
  .widget-1-content
  .widget-1-legend-item
  .widget-1-device {
  color: #3f3f3f;
  margin-right: 1rem;
  width: 60%;
  font-weight: 600;
}

.widget-1
  .widget-1-statistics
  .widget-1-content
  .widget-1-legend-item
  .widget-1-figure {
  color: #8d8a89;
  width: 30%;
}

.widget-1 .widget-1-statistics-graph {
  margin-left: auto;
}

.widget-2 {
  display: flex;
  align-items: center;
}

.widget-2 .widget-2-statistics .widget-2-figure {
  text-align: center;
}

.widget-2 .widget-2-statistics .widget-2-figure span {
  border-radius: 50%;
  font-size: 2rem;
  font-weight: 500;
  color: #3f3f3f;
}

.widget-2 .widget-2-statistics .widget-2-title {
  color: #3f3f3f;
  text-align: center;
}

.widget-2 .widget-2-statistics .widget-2-content {
  font-weight: 500;
}

.widget-2 .widget-2-statistics-graph {
  margin-left: auto;
}

.widget-3 {
  display: flex;
  align-items: center;
}

.widget-3 .widget-3-statistics {
  text-align: center;
}

.widget-3 .widget-3-statistics .widget-3-figure span {
  border-radius: 50%;
  font-size: 2rem;
  font-weight: 500;
  color: #3f3f3f;
}

.widget-3 .widget-3-statistics .widget-3-title {
  color: #3f3f3f;
}

.widget-3 .widget-3-statistics .widget-3-content {
  font-weight: 500;
}

.widget-3 .widget-3-statistics-graph {
  margin-left: auto;
}

.widget-4 .widget-4-title-wrapper {
  display: flex;
  align-items: center;
}

.widget-4 .widget-4-title-wrapper .widget-4-date-primary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ececec;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}

.widget-4 .widget-4-title-wrapper .widget-4-date-primary .widget-4-date-day {
  color: #ef3e27;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}

.widget-4 .widget-4-title-wrapper .widget-4-date-primary .widget-4-date-month {
  color: #ef3e27;
  line-height: 1;
  font-size: 1rem;
  text-transform: uppercase;
}

.widget-4 .widget-4-title-wrapper .widget-4-date-secondary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fbfbfd;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}

.widget-4 .widget-4-title-wrapper .widget-4-date-secondary .widget-4-date-day {
  color: #d3dae6;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}

.widget-4
  .widget-4-title-wrapper
  .widget-4-date-secondary
  .widget-4-date-month {
  color: #d3dae6;
  line-height: 1;
  font-size: 1rem;
  text-transform: uppercase;
}

.widget-4 .widget-4-title-wrapper .widget-4-date-success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #e6faf4;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}

.widget-4 .widget-4-title-wrapper .widget-4-date-success .widget-4-date-day {
  color: #00cb8e;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}

.widget-4 .widget-4-title-wrapper .widget-4-date-success .widget-4-date-month {
  color: #00cb8e;
  line-height: 1;
  font-size: 1rem;
  text-transform: uppercase;
}

.widget-4 .widget-4-title-wrapper .widget-4-date-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #e6f7ff;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}

.widget-4 .widget-4-title-wrapper .widget-4-date-info .widget-4-date-day {
  color: #00aaff;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}

.widget-4 .widget-4-title-wrapper .widget-4-date-info .widget-4-date-month {
  color: #00aaff;
  line-height: 1;
  font-size: 1rem;
  text-transform: uppercase;
}

.widget-4 .widget-4-title-wrapper .widget-4-date-warning {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff9ee;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}

.widget-4 .widget-4-title-wrapper .widget-4-date-warning .widget-4-date-day {
  color: #ffc555;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}

.widget-4 .widget-4-title-wrapper .widget-4-date-warning .widget-4-date-month {
  color: #ffc555;
  line-height: 1;
  font-size: 1rem;
  text-transform: uppercase;
}

.widget-4 .widget-4-title-wrapper .widget-4-date-danger {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffeef0;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}

.widget-4 .widget-4-title-wrapper .widget-4-date-danger .widget-4-date-day {
  color: #ef3e27;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}

.widget-4 .widget-4-title-wrapper .widget-4-date-danger .widget-4-date-month {
  color: #ef3e27;
  line-height: 1;
  font-size: 1rem;
  text-transform: uppercase;
}

.widget-4 .widget-4-title-wrapper .widget-4-date-light {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #feffff;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}

.widget-4 .widget-4-title-wrapper .widget-4-date-light .widget-4-date-day {
  color: #f9fafb;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}

.widget-4 .widget-4-title-wrapper .widget-4-date-light .widget-4-date-month {
  color: #f9fafb;
  line-height: 1;
  font-size: 1rem;
  text-transform: uppercase;
}

.widget-4 .widget-4-title-wrapper .widget-4-date-dark {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #e9eaeb;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}

.widget-4 .widget-4-title-wrapper .widget-4-date-dark .widget-4-date-day {
  color: #242a34;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}

.widget-4 .widget-4-title-wrapper .widget-4-date-dark .widget-4-date-month {
  color: #242a34;
  line-height: 1;
  font-size: 1rem;
  text-transform: uppercase;
}

.widget-4 .widget-4-title-wrapper .widget-4-meeting-info {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  font-weight: 500;
}

.widget-4 .widget-4-title-wrapper .widget-4-meeting-info .widget-4-pro-title {
  color: #3f3f3f;
  font-size: 14px;
}

.widget-4
  .widget-4-title-wrapper
  .widget-4-meeting-info
  .widget-4-meeting-time {
  color: #8d8a89;
  font-size: 13px;
}

.widget-4 .widget-4-meeting-points {
  font-weight: 400;
  font-size: 13px;
  margin-top: 0.5rem;
}

.widget-4 .widget-4-meeting-points .widget-4-meeting-item {
  display: list-item;
  color: #ababbb;
}

.widget-4 .widget-4-meeting-points .widget-4-meeting-item span {
  margin-left: 0.5rem;
}

.widget-4 .widget-4-meeting-action {
  text-align: right;
}

.widget-4 .widget-4-meeting-action a {
  text-transform: uppercase;
}

.widget-5 .widget-5-list .widget-5-items {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  font-weight: 500;
}

.widget-5 .widget-5-list .widget-5-items i {
  border-radius: 25%;
  width: 15px;
  height: 15px;
  margin-right: 1rem;
}

.widget-5 .widget-5-list .widget-5-items .widget-5-option-1 {
  background: #ef3e27;
}

.widget-5 .widget-5-list .widget-5-items .widget-5-option-2 {
  background: #00cb8e;
}

.widget-5 .widget-5-list .widget-5-items .widget-5-option-3 {
  background: #ffc555;
}

.widget-5 .widget-5-list .widget-5-items .widget-5-option-4 {
  background: #ef3e27;
}

.widget-5 .widget-5-list .widget-5-items .widget-5-option-5 {
  background: #00d8fa;
}

.widget-5 .widget-5-list .widget-5-items .widget-5-option-6 {
  background: #242a34;
}

.widget-5 .widget-5-list .widget-5-items .widget-5-desc {
  display: block;
}

.widget-5 .widget-5-list .widget-5-items .widget-5-desc a {
  color: #3f3f3f;
  font-size: 14px;
  text-transform: capitalize;
}

.widget-5 .widget-5-list .widget-5-items .widget-5-desc a:hover {
  transition: all 0.3s;
  color: #ef3e27;
  text-decoration: none;
}

@media (prefers-reduced-motion: reduce) {
  .widget-5 .widget-5-list .widget-5-items .widget-5-desc a:hover {
    transition: none;
  }
}

.widget-5 .widget-5-list .widget-5-items .widget-5-desc span {
  color: #8d8a89;
  font-size: 12px;
}

.widget-5 .widget-5-list .widget-5-items .widget-5-figures {
  display: block;
  margin-left: auto;
  color: #3f3f3f;
  font-size: 13px;
  text-align: center;
}

.widget-5 .widget-5-list .widget-5-items .widget-5-figures span {
  font-size: 12px;
}

.widget-6 .widget-6-chart {
  flex-grow: 1;
}

@media (max-width: 1024px) {
  .widget-6 {
    display: block;
  }
}

.widget-7 {
  font-weight: 500;
}

.widget-7 .widget-7-icon {
  color: #3f3f3f;
}

.widget-7 .widget-7-title {
  color: #3f3f3f;
  font-size: 14px;
  padding-bottom: 5px;
}

.widget-7 .widget-7-number {
  color: #8d8a89;
  font-size: 13px;
  line-height: 1.5;
}

.widget-8 tr th {
  color: #3f3f3f;
}

.widget-8 .widget-8-title-wrapper {
  display: flex;
  align-items: center;
  width: 300px;
}

.widget-8 .widget-8-title-wrapper .widget-8-img {
  border-radius: 0.25rem;
}

.widget-8 .widget-8-title-wrapper .widget-8-product-info {
  font-weight: 500;
  margin-left: 0.5rem;
}

.widget-8 .widget-8-title-wrapper .widget-8-product-info .title {
  color: #3f3f3f;
  line-height: 2;
  font-size: 14px;
}

.widget-8 .widget-8-title-wrapper .widget-8-product-info .attribute {
  font-weight: 400;
  color: #8d8a89;
  font-size: 12px;
}

.widget-8 .widget-8-status-wrapper small {
  display: block;
  line-height: 2;
  color: #8d8a89;
}

.widget-8 .widget-8-desc {
  width: 400px;
  display: flex;
  align-items: center;
}

.widget-8 .widget-8-desc .widget-8-review-wrapper {
  margin-right: 1rem;
}

.widget-8 .widget-8-desc .widget-8-review-wrapper .rating {
  line-height: 2;
}

.widget-8 .widget-8-desc .widget-8-review-wrapper .rating svg {
  width: 13px;
  height: 13px;
}

.widget-8 .widget-8-desc .widget-8-review-wrapper .rating svg.fill {
  color: #3f3f3f;
  fill: #3f3f3f;
}

.widget-8 .widget-8-desc .widget-8-review-wrapper .rating svg:not(.fill) {
  color: #cacff6;
  fill: #cacff6;
}

.widget-8 .widget-8-desc .widget-8-review-wrapper .review {
  color: #8d8a89;
}

.widget-8 .widget-8-desc .widget-8-review-wrapper .review a {
  color: #8d8a89;
}

.widget-8 .widget-8-desc .widget-8-order-wrapper {
  display: block;
  margin-right: 1.5rem;
}

.widget-8 .widget-8-desc .widget-8-order-wrapper .figure {
  color: #3f3f3f;
  line-height: 1.5;
  font-size: 18px;
  font-weight: 500;
}

.widget-8 .widget-8-desc .widget-8-order-wrapper .desc {
  font-size: 85%;
  color: #8d8a89;
  text-transform: capitalize;
}

.widget-8 .widget-8-desc .widget-8-earning-wrapper,
.widget-8 .widget-8-desc .widget-8-return-wrapper {
  display: block;
  margin-right: 1.5rem;
}

.widget-8 .widget-8-desc .widget-8-earning-wrapper .figure,
.widget-8 .widget-8-desc .widget-8-return-wrapper .figure {
  display: flex;
  color: #3f3f3f;
  line-height: 1.5;
  font-size: 18px;
  font-weight: 500;
}

.widget-8 .widget-8-desc .widget-8-earning-wrapper .figure small,
.widget-8 .widget-8-desc .widget-8-return-wrapper .figure small {
  padding-left: 0.5rem;
  font-size: 70%;
  display: flex;
  align-items: center;
}

.widget-8 .widget-8-desc .widget-8-earning-wrapper .figure small svg,
.widget-8 .widget-8-desc .widget-8-return-wrapper .figure small svg {
  width: 11px;
  height: 11px;
}

.widget-8 .widget-8-desc .widget-8-earning-wrapper .desc,
.widget-8 .widget-8-desc .widget-8-return-wrapper .desc {
  font-size: 85%;
  color: #8d8a89;
  text-transform: capitalize;
}

.widget-8 .widget-8-action-wrapper {
  display: flex;
}

.widget-9 .widget-9-wrapper {
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
}

.widget-9 .widget-9-wrapper .widget-9-info {
  font-weight: 500;
}

.widget-9 .widget-9-wrapper .widget-9-info .widget-9-title {
  color: #3f3f3f;
  display: block;
  font-size: 14px;
  transition: all, 0.3s;
}

@media (prefers-reduced-motion: reduce) {
  .widget-9 .widget-9-wrapper .widget-9-info .widget-9-title {
    transition: none;
  }
}

.widget-9 .widget-9-wrapper .widget-9-info .widget-9-title:hover {
  text-decoration: none;
  color: #ef3e27;
}

.widget-9 .widget-9-wrapper .widget-9-info .widget-9-desc {
  color: #8d8a89;
  font-size: 12px;
}

.widget-9 .widget-9-wrapper .widget-9-stat {
  margin-left: auto;
}

.widget-10 .widget-10-wrapper {
  display: flex;
  align-items: center;
  padding: 1rem 0;
}

@media (max-width: 575px) {
  .widget-10 .widget-10-wrapper {
    display: block;
    border-bottom: 1px dashed #e6e4e9;
  }
}

.widget-10 .widget-10-wrapper .widget-10-left-container {
  display: flex;
}

.widget-10 .widget-10-wrapper .widget-10-left-container .widget-10-user-info {
  min-width: 70px;
  padding-right: 0.5rem;
  margin-right: 0.5rem;
  font-size: 12px;
  border-right: 1px solid #e6e4e9;
}

.widget-10
  .widget-10-wrapper
  .widget-10-left-container
  .widget-10-user-info
  .widget-10-profile-pic {
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  border-radius: 100%;
  margin-bottom: 0.5rem;
}

.widget-10
  .widget-10-wrapper
  .widget-10-left-container
  .widget-10-user-info
  .widget-10-username {
  text-decoration: none;
  color: #3f3f3f;
  display: block;
  font-weight: 500;
  white-space: nowrap;
}

.widget-10
  .widget-10-wrapper
  .widget-10-left-container
  .widget-10-user-info
  .widget-10-username:hover {
  color: #ef3e27;
}

.widget-10
  .widget-10-wrapper
  .widget-10-left-container
  .widget-10-user-info
  .widget-10-cmp {
  line-height: 1;
  text-decoration: none;
  display: block;
  color: #ababbb;
  white-space: nowrap;
}

.widget-10
  .widget-10-wrapper
  .widget-10-left-container
  .widget-10-user-info
  .widget-10-cmp-add {
  text-decoration: none;
  display: block;
  color: #3f3f3f;
}

.widget-10 .widget-10-wrapper .widget-10-left-container .widget-10-ticket {
  display: flex;
  flex-direction: column;
}

.widget-10
  .widget-10-wrapper
  .widget-10-left-container
  .widget-10-ticket
  .widget-10-ticket-tags {
  margin-bottom: 0.5rem;
}

.widget-10
  .widget-10-wrapper
  .widget-10-left-container
  .widget-10-ticket
  .widget-10-ticket-title {
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: #3f3f3f;
  display: block;
  font-size: 13px;
  transition: all, 0.3s;
}

@media (prefers-reduced-motion: reduce) {
  .widget-10
    .widget-10-wrapper
    .widget-10-left-container
    .widget-10-ticket
    .widget-10-ticket-title {
    transition: none;
  }
}

.widget-10
  .widget-10-wrapper
  .widget-10-left-container
  .widget-10-ticket
  .widget-10-ticket-title:hover {
  text-decoration: none;
  color: #ef3e27;
}

.widget-10
  .widget-10-wrapper
  .widget-10-left-container
  .widget-10-ticket
  .widget-10-ticket-desc {
  color: #ababbb;
  font-size: 12px;
}

.widget-10
  .widget-10-wrapper
  .widget-10-left-container
  .widget-10-ticket
  .widget-10-ticket-desc
  .widget-10-ticket-id {
  font-weight: 500;
  font-size: 12px;
}

.widget-10 .widget-10-wrapper .widget-10-interaction {
  margin-left: auto;
}

.widget-10 .widget-10-wrapper .widget-10-interaction .widget-10-comments,
.widget-10 .widget-10-wrapper .widget-10-interaction .widget-10-viewers,
.widget-10 .widget-10-wrapper .widget-10-interaction .widget-10-user {
  display: flex;
  align-items: center;
}

.widget-10 .widget-10-wrapper .widget-10-interaction .widget-10-comments a,
.widget-10 .widget-10-wrapper .widget-10-interaction .widget-10-viewers a,
.widget-10 .widget-10-wrapper .widget-10-interaction .widget-10-user a {
  color: #3f3f3f;
  font-size: 13px;
  line-height: 1.8;
  text-transform: capitalize;
  transition: all, 0.3s;
}

@media (prefers-reduced-motion: reduce) {
  .widget-10 .widget-10-wrapper .widget-10-interaction .widget-10-comments a,
  .widget-10 .widget-10-wrapper .widget-10-interaction .widget-10-viewers a,
  .widget-10 .widget-10-wrapper .widget-10-interaction .widget-10-user a {
    transition: none;
  }
}

.widget-10
  .widget-10-wrapper
  .widget-10-interaction
  .widget-10-comments
  a:hover,
.widget-10 .widget-10-wrapper .widget-10-interaction .widget-10-viewers a:hover,
.widget-10 .widget-10-wrapper .widget-10-interaction .widget-10-user a:hover {
  text-decoration: none;
  color: #ef3e27;
}

.widget-10 .widget-10-wrapper .widget-10-interaction .widget-10-comments a svg,
.widget-10 .widget-10-wrapper .widget-10-interaction .widget-10-viewers a svg,
.widget-10 .widget-10-wrapper .widget-10-interaction .widget-10-user a svg {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}

@media (max-width: 575px) {
  .widget-10 .widget-10-wrapper .widget-10-interaction {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
  }
  .widget-10
    .widget-10-wrapper
    .widget-10-interaction
    .widget-10-comments
    a
    svg,
  .widget-10 .widget-10-wrapper .widget-10-interaction .widget-10-viewers a svg,
  .widget-10 .widget-10-wrapper .widget-10-interaction .widget-10-user a svg {
    display: none;
  }
}

.widget-11 {
  font-weight: 500;
}

.widget-11 .widget-11-task-time {
  color: #ef3e27;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.widget-11 .widget-11-task-time svg {
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
}

.widget-11 .widget-11-task-title {
  color: #3f3f3f;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.widget-11 .widget-11-task-desc {
  font-weight: 400;
  color: #ababbb;
  font-size: 13px;
  margin-bottom: 0.5rem;
}

.widget-11 .widget-11-task-action {
  text-align: right;
}

.widget-12 {
  font-weight: 500;
}

.widget-12 .widget-12-pro-title {
  margin-bottom: 1rem;
}

.widget-12 .widget-12-pro-title .widget-12-pro-title-link {
  color: #3f3f3f;
  font-size: 1rem;
  margin-bottom: 0.625rem;
  text-decoration: none;
  transition: all, 0.3s;
}

@media (prefers-reduced-motion: reduce) {
  .widget-12 .widget-12-pro-title .widget-12-pro-title-link {
    transition: none;
  }
}

.widget-12 .widget-12-pro-title .widget-12-pro-title-link:hover {
  color: #ef3e27;
}

.widget-12 .widget-12-pro-title .widget-12-pro-ext-link {
  font-size: 13px;
  font-weight: 300;
  color: #8d8a89;
  margin-left: 1rem;
  text-decoration: none;
  transition: all, 0.3s;
}

@media (prefers-reduced-motion: reduce) {
  .widget-12 .widget-12-pro-title .widget-12-pro-ext-link {
    transition: none;
  }
}

.widget-12 .widget-12-pro-title .widget-12-pro-ext-link:hover {
  color: #ef3e27;
}

.widget-12 .widget-12-task-desc {
  font-weight: 400;
  color: #ababbb;
  font-size: 13px;
  margin-bottom: 1rem;
}

.widget-12 .widget-12-pro-att-list {
  margin-bottom: 0.625rem;
}

.widget-12 .widget-12-pro-att-list .widget-12-pro-att-item {
  padding-bottom: 5px;
  display: flex;
  align-items: center;
}

.widget-12 .widget-12-pro-att-list .widget-12-pro-att-item a {
  font-weight: 400;
  font-size: 13px;
  color: #3f3f3f;
  transition: all, 0.3s;
}

@media (prefers-reduced-motion: reduce) {
  .widget-12 .widget-12-pro-att-list .widget-12-pro-att-item a {
    transition: none;
  }
}

.widget-12 .widget-12-pro-att-list .widget-12-pro-att-item a svg {
  width: 13px;
  height: 13px;
  margin-right: 0.5rem;
  color: #ef3e27;
}

.widget-12 .widget-12-pro-att-list .widget-12-pro-att-item a:hover {
  color: #ef3e27;
  text-decoration: none;
}

.widget-12 .widget-12-pro-att-list .widget-12-pro-att-item span {
  font-weight: 400;
  margin-left: auto;
  color: #8d8a89;
}

.widget-12 .widget-12-progress {
  font-weight: 400;
  display: flex;
  align-items: center;
  margin: 1rem 0 0.3125rem 0;
}

.widget-12 .widget-12-progress .widget-12-progress-title {
  color: #8d8a89;
}

.widget-12 .widget-12-progress .widget-12-progress-figure {
  margin-left: auto;
  color: #3f3f3f;
}

.widget-12 .progress {
  height: 5px;
}

.widget-13 .widget-13-body {
  display: flex;
  align-items: center;
}

.widget-13 .widget-13-body .widget-13-left-section img {
  width: 130px;
}

.widget-13 .widget-13-body .widget-13-right-section {
  display: flex;
  flex-direction: column;
  padding-left: 1.5rem;
}

.widget-13 .widget-13-body .widget-13-right-section .widget-13-bio {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
}

.widget-13 .widget-13-body .widget-13-right-section .widget-13-bio .name {
  color: #3f3f3f;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1;
}

.widget-13
  .widget-13-body
  .widget-13-right-section
  .widget-13-bio
  .designation {
  color: #ababbb;
}

.widget-13 .widget-13-body .widget-13-right-section .widget-13-other button {
  box-shadow: none;
}

.widget-13
  .widget-13-body
  .widget-13-right-section
  .widget-13-other
  button
  .filled {
  fill: #ef3e27;
}

.widget-13 .widget-13-contact {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
}

.widget-13 .widget-13-contact a {
  line-height: 1.5;
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: #3f3f3f;
  transition: all, 0.3s;
}

.widget-13 .widget-13-contact a:first-child {
  margin-top: 1rem;
}

.widget-13 .widget-13-contact a:hover {
  color: #ef3e27;
  text-decoration: none;
}

@media (prefers-reduced-motion: reduce) {
  .widget-13 .widget-13-contact a {
    transition: none;
  }
}

.widget-13 .widget-13-contact a .label {
  color: #ababbb;
  width: 100px;
  display: inline-block;
  padding-right: 1.5rem;
}

.widget-13 .widget-13-divider {
  margin: 1rem 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.11);
}

.widget-13 .widget-13-user-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.widget-13 .widget-13-user-info .widget-13-frds-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.widget-13 .widget-13-user-info .widget-13-frds-info .widget-13-category {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
  border-radius: 50%;
}

.widget-13 .widget-13-user-info .widget-13-frds-info .widget-13-category svg {
  width: 20px;
  height: 20px;
}

.widget-13 .widget-13-user-info .widget-13-frds-info .numbers {
  color: #3f3f3f;
  font-weight: 500;
  font-size: 1rem;
}

.widget-13 .widget-13-user-info .widget-13-frds-info .title {
  color: #ababbb;
  font-size: 12px;
  font-weight: 500;
}

.widget-14 {
  margin-bottom: 2rem;
  border-bottom: 1px dashed #e6e4e9;
}

.widget-14 .widget-14-header {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.widget-14 .widget-14-header .widget-14-user-info {
  display: flex;
  align-items: center;
}

.widget-14 .widget-14-header .widget-14-user-info .name {
  color: #3f3f3f;
  font-weight: 500;
  font-size: 14px;
}

.widget-14 .widget-14-header .widget-14-user-info .name .additional-info {
  color: #ababbb;
  text-transform: lowercase;
  font-size: 13px;
  font-weight: 400;
}

.widget-14 .widget-14-header .widget-14-user-info .time {
  color: #ababbb;
  font-size: 13px;
  font-weight: 400;
}

.widget-14 .widget-14-header .widget-14-user-info img {
  width: 50px;
  height: 50px;
  margin-right: 1rem;
}

.widget-14 .widget-14-body {
  margin-bottom: 1rem;
}

.widget-14 .widget-14-body .widget-14-post-description {
  color: #3f3f3f;
  font-weight: 400;
  font-size: 13px;
}

.widget-14 .widget-14-body .widget-14-post-gallery {
  display: flex;
  flex-wrap: wrap;
}

.widget-14 .widget-14-body .widget-14-post-gallery img {
  max-width: 200px;
  margin: 1rem 1rem 0.5rem 0;
}

@media (max-width: 575px) {
  .widget-14 .widget-14-body .widget-14-post-gallery img {
    max-width: 70px;
  }
}

@media (min-width: 600px) and (max-width: 1024px) {
  .widget-14 .widget-14-body .widget-14-post-gallery img {
    max-width: 120px;
  }
}

.widget-14 .widget-14-emoji-panel {
  list-style-type: none;
  padding-left: 0;
}

.widget-14 .widget-14-emoji-panel li {
  display: inline-block;
  padding-right: 0.5rem;
}

.widget-14 .widget-14-emoji-panel li .emoji-icon {
  width: 1rem;
  height: 1rem;
}

.widget-14 .widget-14-emoji-panel li a {
  font-size: 13px;
  color: #ababbb;
  font-weight: 400;
  color: #ababbb;
}

.widget-14 .widget-14-emoji-panel li a:hover {
  color: #ef3e27;
  text-decoration: none;
}

.widget-15 .widget-15-header {
  padding: 1rem 0.5rem;
  display: flex;
  align-items: center;
}

.widget-15 .widget-15-header img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.widget-15 .widget-15-header .widget-15-post-info {
  display: flex;
  flex-direction: column;
}

.widget-15 .widget-15-header .widget-15-post-info .author-name {
  color: #3f3f3f;
  font-size: 13px;
  font-weight: 500;
}

.widget-15 .widget-15-header .widget-15-post-info .time {
  color: #ababbb;
  font-size: 11px;
}

.widget-15 .widget-15-header .widget-15-post-action {
  flex: 1 1;
  text-align: right;
}

.widget-15 .widget-15-body img {
  width: 100%;
  margin-bottom: 0.5rem;
}

.widget-15 .widget-15-body .widget-15-post-container {
  padding: 0.5rem 1rem;
}

.widget-15 .widget-15-body .widget-15-post-container .title {
  font-size: 1rem;
  color: #3f3f3f;
  margin-bottom: 0.5rem;
  display: block;
  color: #3f3f3f;
  transition: all, 0.3s;
}

.widget-15 .widget-15-body .widget-15-post-container .title:hover {
  color: #ef3e27;
  text-decoration: none;
}

@media (prefers-reduced-motion: reduce) {
  .widget-15 .widget-15-body .widget-15-post-container .title {
    transition: none;
  }
}

.widget-15 .widget-15-body .widget-15-post-container .desc {
  color: #ababbb;
}

.widget-16 {
  display: flex;
  flex-flow: row;
}

.widget-16 .widget-16-left-section {
  min-width: 140px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 4px 0 0 4px;
}

.widget-16 .widget-16-left-section a.badge {
  font-weight: 300;
  font-size: 0.8125rem;
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  box-shadow: none;
}

.widget-16 .widget-16-right-section {
  padding: 1rem;
}

.widget-16 .widget-16-right-section .widget-16-post-container {
  margin-bottom: 1rem;
}

.widget-16 .widget-16-right-section .widget-16-post-container .title {
  font-size: 1rem;
  color: #3f3f3f;
  margin-bottom: 0.5rem;
  display: block;
  color: #3f3f3f;
  transition: all, 0.3s;
}

.widget-16 .widget-16-right-section .widget-16-post-container .title:hover {
  color: #ef3e27;
  text-decoration: none;
}

@media (prefers-reduced-motion: reduce) {
  .widget-16 .widget-16-right-section .widget-16-post-container .title {
    transition: none;
  }
}

.widget-16 .widget-16-right-section .widget-16-post-container .desc {
  color: #ababbb;
}

.widget-16 .widget-16-right-section .widget-16-author-container {
  display: flex;
  align-items: center;
}

.widget-16 .widget-16-right-section .widget-16-author-container img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.widget-16
  .widget-16-right-section
  .widget-16-author-container
  .widget-16-author-info {
  display: flex;
  flex-direction: column;
}

.widget-16
  .widget-16-right-section
  .widget-16-author-container
  .widget-16-author-info
  .author-name {
  color: #3f3f3f;
  font-size: 13px;
  font-weight: 500;
}

.widget-16
  .widget-16-right-section
  .widget-16-author-container
  .widget-16-author-info
  .time {
  color: #ababbb;
  font-size: 11px;
}

.widget-16
  .widget-16-right-section
  .widget-16-author-container
  .widget-16-post-action {
  flex: 1 1;
  text-align: right;
}

.widget-17 .widget-17-header img {
  width: 100%;
  border-radius: 4px 4px 0 0;
}

.widget-17 .widget-17-body {
  border-bottom: 1px solid #e6e4e9;
}

.widget-17 .widget-17-body img {
  width: 100%;
  margin-bottom: 0.5rem;
}

.widget-17 .widget-17-body .widget-17-post-container {
  padding: 0.5rem 1rem;
}

.widget-17 .widget-17-body .widget-17-post-container .title {
  font-size: 1rem;
  color: #3f3f3f;
  margin-bottom: 0.5rem;
  display: block;
  color: #3f3f3f;
  transition: all, 0.3s;
}

.widget-17 .widget-17-body .widget-17-post-container .title:hover {
  color: #ef3e27;
  text-decoration: none;
}

@media (prefers-reduced-motion: reduce) {
  .widget-17 .widget-17-body .widget-17-post-container .title {
    transition: none;
  }
}

.widget-17 .widget-17-body .widget-17-post-container .desc {
  color: #ababbb;
}

.widget-17 .widget-17-footer {
  padding: 1rem 0.5rem;
  display: flex;
  align-items: center;
}

.widget-17 .widget-17-footer img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.widget-17 .widget-17-footer .widget-17-post-info {
  display: flex;
  flex-direction: column;
}

.widget-17 .widget-17-footer .widget-17-post-info .author-name {
  color: #3f3f3f;
  font-size: 13px;
  font-weight: 500;
}

.widget-17 .widget-17-footer .widget-17-post-info .time {
  color: #ababbb;
  font-size: 11px;
}

.widget-17 .widget-17-footer .widget-17-post-action {
  flex: 1 1;
  text-align: right;
}

.widget-18 {
  display: flex;
}

@media (max-width: 575px) {
  .widget-18 {
    display: block;
  }
}

.widget-18 .widget-18-product-container {
  display: flex;
}

.widget-18 .widget-18-product-container .widget-18-product-image {
  margin-right: 1rem;
}

.widget-18 .widget-18-product-container .widget-18-product-image img {
  width: 100px;
}

@media (max-width: 575px) {
  .widget-18 .widget-18-product-container .widget-18-product-image img {
    width: 70px;
  }
}

.widget-18 .widget-18-product-container .widget-18-product-content a.title {
  font-weight: 500;
  font-size: 14px;
  display: block;
  margin-bottom: 0.5rem;
  color: #3f3f3f;
  transition: all, 0.3s;
}

.widget-18
  .widget-18-product-container
  .widget-18-product-content
  a.title:hover {
  color: #ef3e27;
  text-decoration: none;
}

@media (prefers-reduced-motion: reduce) {
  .widget-18 .widget-18-product-container .widget-18-product-content a.title {
    transition: none;
  }
}

.widget-18
  .widget-18-product-container
  .widget-18-product-content
  .description {
  font-size: 12px;
  color: #8d8a89;
  margin-bottom: 0.5rem;
}

.widget-18 .widget-18-product-container .widget-18-product-content .tags {
  font-weight: 500;
  font-size: 12px;
  color: #3f3f3f;
}

.widget-18 .widget-18-product-action {
  margin-left: 0.5rem;
}

.widget-18 .widget-18-product-action .widget-18-action-pane {
  display: flex;
  flex-flow: row;
  margin-bottom: 1rem;
}

.widget-18 .widget-18-product-action .widget-18-action-pane button {
  margin-left: 0.625rem;
}

.widget-18 .widget-18-product-action .widget-18-action-pane button:first-child {
  margin-left: 0;
}

.widget-18 .widget-18-product-action .widget-18-action-pane button.btn-rspv {
  display: none;
}

.widget-18 .widget-18-product-action .dropdown {
  text-align: right;
  margin-bottom: 1rem;
}

.widget-18 .widget-18-product-action .widget-18-product-publish-info {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  color: #3f3f3f;
  font-weight: 300;
}

@media (max-width: 575px) {
  .widget-18 .widget-18-product-action .widget-18-action-pane {
    margin-top: 0.5rem;
    justify-content: center;
  }
  .widget-18 .widget-18-product-action .widget-18-action-pane button {
    margin-left: 0.625rem;
  }
  .widget-18
    .widget-18-product-action
    .widget-18-action-pane
    button:first-child {
    margin-left: 0;
  }
  .widget-18 .widget-18-product-action .widget-18-action-pane button.btn-rspv {
    display: block;
  }
  .widget-18 .widget-18-product-action .dropdown {
    display: none;
  }
  .widget-18 .widget-18-product-action .widget-18-product-publish-info {
    justify-content: center;
  }
}

.widget-19 .widget-19-header {
  padding: 1rem;
}

.widget-19 .widget-19-header .widget-19-title {
  color: #ababbb;
  text-transform: uppercase;
  letter-spacing: 0.0625rem;
  font-size: 11px;
  font-weight: 600;
}

.widget-19 .widget-19-header .widget-19-stats {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.widget-19 .widget-19-header .widget-19-stats .widget-19-figure {
  color: #3f3f3f;
}

.widget-19 .widget-19-header .widget-19-stats .widget-19-ratio {
  font-size: 13px;
  font-weight: 600;
}

.widget-19 .widget-19-header .widget-19-stats .widget-19-arrow {
  width: 15px;
  height: 15px;
  stroke-width: 3;
}

.widget-19 .widget-19-chart {
  display: block;
}

.widget-19 .widget-19-chart canvas {
  border-radius: 4px;
}

.widget-20 {
  min-height: 12rem;
}
.widget-20 .widget-20-header, .widget-20 .widget-20-detail {
  padding: 1rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
}


.widget-20 .widget-20-content .widget-20-title {
  font-weight: 700;
  color: #3f3f3f;
  font-size: 1rem;
  margin-bottom: 5px;
}

.widget-20 .widget-20-content .widget-20-info {
  font-weight: 500;
  color: #8d8a89;
}

.widget-20 .widget-20-figure {
  color: #3f3f3f;
}

.widget-20 .widget-20-chart {
  display: block;
}

.widget-20 .widget-20-chart canvas {
  border-radius: 4px;
}

.widget-20 .widget-20-detail .widget-20-subtitle {
  font-size: 0.9rem;
}
.widget-20 .widget-20-detail {
  padding: 0 1rem;
  margin-bottom: 0;
}

.separateTicketDeliveryContainer {
  display: flex;
  flex-wrap: nowrap;
  grid-gap: 8px;
  gap: 8px;
  align-items: center;
  margin-bottom: 8px;
}
.separateTicketDeliveryContainer input {
  width: 100%;
  flex-shrink: 1;
  flex-grow: 1;
  height: 40px;
}
@media screen and (max-width: 900px) {
  .separateTicketDeliveryContainer {
    flex-wrap: wrap !important;
    grid-gap: 4px;
    gap: 4px;
  }
}

.createAccountGroup label {
  margin: 6px 0;
}
@font-face {
  font-family: chivoBold;
  src: url(/static/media/Chivo-Bold.44169c43.ttf);
}
@font-face {
  font-family: chivo;
  src: url(/static/media/Chivo-Regular.dd17e112.ttf);
}

/* ::selection {
  color: #fff;
  background: rgb(239 62 39);
} */
a.link-text-white {
  text-decoration: none;
}

.menu_overlay {
  position: fixed;
  width: calc(100% - 280px);
  height: 100%;
  right: 0;
  top: 0;
  z-index: 999;
  opacity: 0;
  cursor: e-resize;
  transition: all 0.3s;
  -webkit-transform: translateX(calc(-100% - 280px));
          transform: translateX(calc(-100% - 280px));
}

.menu_open .menu_overlay {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

input[autocomplete='off']::-webkit-contacts-auto-fill-button,
input[autocomplete='off']::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none;
  height: 0;
  width: 0;
  margin: 0;
  pointer-events: none;
}
h6.sub.bold {
  line-height: 1;
  vertical-align: top;
  margin-bottom: 0.5rem;
  color: #3c4b5c;
  font-weight: bold;
}
.checkout-button-bottom {
  position: absolute;
  bottom: 0;
}
.ql-snow .ql-tooltip {
  z-index: 9999 !important;
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border: 1px solid #e6e4e9 !important;
  border-right-width: 1px !important;
  z-index: 1;
}
.mini-btn {
  padding: 6px 9px;
}
.sub.bold.italic {
  font-weight: bold;
  font-style: italic;
  margin-left: 17%;
}
/* ...................................................FA icon.......................................... */
.delete-svg-icon {
  font-size: 16px !important;
}

svg.svg-inline--fa.fa-image.fa-w-16.fa-3x {
  width: 100% !important;
  height: 100%;
  color: #9e9e9e;
}
.popup-share-icon {
  color: #fff !important;
  font-size: 24px !important;
}

svg.svg-inline--fa.fa-cogs.fa-w-20.menu-icon.setting {
  width: 1rem !important;
}
svg.feather.feather-log-out {
  margin-left: 4px;
}
.svg-inline--fa.fa-w-16.far.fa-user-circle.fa-2x {
  width: 30px !important;
}
.svg-inline--fa.fa-w-16.far.fa-user-circle.login-home {
  width: 45px !important;
}

i.fas.fa-film {
  font-size: 13px !important;
}
i.fas.fa-envelope {
  font-size: 20px;
  cursor: pointer;
}
i.fas.fa-copy {
  font-size: 20px;
  cursor: pointer;
}
i.fas.fa-copy:hover {
  font-size: 24px;
  color: #f4f4f4;
  cursor: pointer;
}
i.fas.fa-envelope:hover {
  font-size: 24px;
  color: #f4f4f4;
  cursor: pointer;
}
svg.svg-inline--fa.fa-times-circle {
  cursor: pointer !important;
  color: red !important;
}
i.fa.fa-plus {
  font-size: 9px !important;
}
.fa-home {
  font-size: 18px;
}
svg.svg-inline--fa.fa-w-16.far.fa-user-circle.fa-2x {
  height: 32px;
}

i.fa.fa-facebook-official.mr-1 {
  font-size: 17px !important;
}
i.fas.fa-plus {
  line-height: 1rem !important;
  font-size: 1rem !important;
  margin-right: 3px;
}
i.fa.fa-minus {
  font-size: 10px !important;
}
i.fa.fa-plus.add-input {
  font-size: 13px !important;
  margin-right: 9px;
}
i.fas.fa-minus.remove-input {
  font-size: 12px !important;
  margin-right: 4px;
}

svg.svg-inline--fa.fa-w-16.far.fa-user-circle.fa-2x.login-home {
  color: #fff;
  font-size: 46px;
}
i.fa.fa-sort {
  cursor: pointer;
}
.fa.fa-heart {
  color: red;
}
.fa.fa-comment.comment-icon {
  color: #00cb8e;
}
i.fa.fa-user.follower {
  color: #ffc555;
}

i.fa.fa-trash-alt {
  cursor: pointer;
}
i.fa.fa-circle {
  position: absolute;
  top: 20px;
  left: -5px;
  font-size: 4px;
}
.fas.fa-cloud-upload-alt {
  font-size: 43px;
  position: absolute;
  left: 50%;
  top: 0;
  -webkit-transform: translate(-27%, -96%);
          transform: translate(-27%, -96%);
  margin: 0;
}
i.fa.fa-angle-double-down {
  margin-left: 9px;
  font-size: 14px !important;
}
i.fa.fa-home.menu-icon {
  position: absolute;
  left: 3px;
}
.menu_icon {
  font-size: 15px;
}
i.fa.fa-receipt {
  font-size: 17px !important;
}
i.far.fa-user {
  font-size: 16px;
}

.fa-user-headset:hover {
  width: 15px;
  fill: red;
  height: 15px;
}
.svg-inline--fa.fa-w-20 {
  width: 1rem;
}
svg.svg-inline--fa.fa-trash-alt.fa-w-14 {
  cursor: pointer;
}
i.far.fa-image.x {
  font-size: 13px !important;
}
i.fa.fa-chevron-left {
  position: absolute;
  margin-top: 7px;
  text-align: center;
  color: #ef3e27 !important;
}
i.fa.fa-chevron-right {
  position: absolute;
  margin-top: 3px;
  text-align: center;
  color: #ef3e27 !important;
}
.fa-heart.mr-1 {
  cursor: pointer;
}
i.fas.fa-paper-plane {
  color: #9e9e9e;
  font-size: 20px;
  position: absolute;
  bottom: 63px;
  right: 11px;
}
i.fas.fa-upload {
  font-size: 14px !important;
  margin-right: 10px;
}
i.fab.fa-linkedin-in {
  font-size: 18px;
}
.popup-youtube i {
  position: absolute;
  font-size: 80px;
  color: #fff;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
i.far.fa-check-square {
  margin-left: 10px;
  margin-right: 15px;
}
svg.svg-inline--fa.fa-warn.fa-w-18 {
  height: 50px;

  color: #faad14 !important;
}

i.fas.fa-play-circle.newsfeed-video {
  font-size: 50px;
}

.fa-w-14 {
  width: 14px !important;
}
.fa-w-18 {
  width: 18px !important;
}
.fa-w-20 {
  width: 20px !important;
}
.fa-w-12 {
  width: 12px !important;
}
.fa-w-16 {
  width: 16px !important;
}
i.fas.fa-square.mr-2 {
  color: #242a34 !important;
  font-size: 5px !important;
  top: 1;
}
.cross-dropzone-icon.cm_red svg {
  width: 22px !important;
}
/* ................................................Nav side bar............................................ */
.sidebar ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.sidebar ul li {
  position: relative;
  padding: 12px;
  padding-left: 50px;
}

.sidebar {
  position: fixed;
  background: #231f20;
  height: 100vh;
  width: 270px;
  left: 0;
  top: 0;
  max-height: 100vh;
  overflow-y: auto;
  transition: all 0.3s;
  padding: 0;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
}
.ant-collapse-icon-position-right
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  right: 12% !important;
  left: auto;
  font-size: 10px;
  color: #9e9e9e;
}
.cm_close {
  width: 21px;
  right: 20px;
  cursor: pointer;
  position: absolute;
  color: #fff;
  font-size: 24px;
  top: 16px;
}

img.logo-white {
  height: calc(6rem - 1.5rem);
  margin-left: 27%;
  margin-top: 14px;
  margin-bottom: 24px;
}

@media (max-width: 1024px) {
  .navbar .navbar-menu-container .navbar-nav .nav-item .nav-link {
    color: inherit;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.25rem -0.875rem;
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 35px;

    text-align: center;
    border-radius: 100%;
    transition: all 0.2s;
  }
  .page-title {
    display: none !important;
  }
  .menu_open .sidebar {
    left: 0px !important;
  }
  .sidebar {
    left: -330px;
    z-index: 999;
    top: 0px;
    max-height: (100vh - 75px);
    transition: all 0.3s;
  }
  .second-nav {
    display: block;
    padding: 0 0 0 50px;
    height: 80px;
    z-index: 12;
    position: relative;
    width: 100%;
    background-color: #fff;
  }
  .active {
    right: 0;
  }
  .show.search {
    display: block;
    padding: 0 0 0 50px;
    height: 80px;
    z-index: 12;
    position: relative;
    width: 100%;
    background-color: #fff;
  }

  .mobile_trigger {
    position: absolute;
    right: 0;
    display: block;
    z-index: 99;
    cursor: pointer;
    color: inherit;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.25rem 0.125rem;
    width: 2.5rem;
    height: 2.5rem;
    text-align: center;
    border-radius: 50%;
    transition: all 0.2s;
  }

  .mobile_trigger:hover {
    color: #ef3e27;
  }

  .mobile_trigger span {
    color: inherit;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.25rem 0.125rem;
    width: 2.5rem;
    height: 2.5rem;
    text-align: center;
    border-radius: 50%;
    transition: all 0.2s;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 99;
    cursor: pointer;
  }
}

.nav-item.nav-profile {
  align-self: stretch !important;
  margin-right: 25px;
}
@media (min-width: 1331px) {
  .cm_close,
  .mobile_trigger {
    display: none !important;
  }
}
.navbar .navbar-menu-container .navbar-nav .nav-item .nav-link {
  color: inherit;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.25rem -0.875rem;
  width: 2.5rem;
  height: 2.5rem;
  text-align: center;
  border-radius: 100%;
  transition: all 0.2s;
}

.sidebar-menu a {
  color: #fff;
  display: inline-block;
  font-size: 0.7997rem;
  vertical-align: middle;
  text-transform: uppercase;
  font-weight: 700;
}
.sidebar-menu li:hover {
  color: #ef3e27;
}

.sidebar-menu a:hover,
.sidebar-menu a:active {
  color: #ef3e27;
  text-decoration: none;
}

@media only screen and (max-width: 1025px) {
  .second-nav1 {
    right: 0;
  }
}

/*--------------------------------------------home page OwlCarousel-------------------------------------------------------------- */
.event-owl .owl-prev,
.event-owl .owl-next {
  padding: 0.5rem 1rem !important;
  width: 40px;
  height: 40px;
  border: 1px solid #404040 !important;
  border-radius: 0;
  margin: 0;
  outline: none !important;
}
.event-owl.owl-theme .owl-nav [class*='owl-'] {
  color: #fff;
  font-size: 14px;
  margin: 0px !important;
  padding: 4px 7px;
  background: #d6d6d6;
  display: inline-block;
  cursor: pointer;
  border-radius: 0 !important ;
}

.event-owl.owl-theme .owl-nav [class*='owl-']:hover {
  background: red !important;
  color: #fff;
  text-decoration: none;
}

.autocomplete-dropdown-container {
  z-index: 1000;
  padding: 0px 2px 1px;
}

.bs-stepper-header {
  display: flex;
  margin-right: -5px;
  margin-left: -4px;
  align-items: center;
}

.step.col-lg-3.col-sm-6.mb-3.d-flex.justify-content-center.active
  .step-trigger {
  width: 100%;
  background: #000 !important ;
  color: #fff !important;
}

.bs-stepper .step-trigger:hover {
  width: 100%;
  color: #fff !important;
  background: red !important;
}
.bs-stepper .step-trigger:focus {
  color: #000 !important;
}

.bs-stepper .step-trigger {
  width: 100%;
  letter-spacing: 0.3px;
  font-size: 13px !important;
  border-radius: 0 !important;
  padding: 14px 0 !important;
  color: #000 !important;
  background: #e9edf3 !important;
}

button.btn.btn-primary.mr-2.previous {
  color: #212529;
  background-color: #d3dae6;
  border-color: #d3dae6;
}
button.btn.btn-primary.mr-2.previous:hover {
  color: #212529;
  background-color: #bbc5d8;
  border-color: #b2bfd4;
}

.ant-btn-primary {
  color: #fff;
  background: #ef3e27;
  border-color: #ef3e27;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-primary:hover {
  color: #fff;
  background: #000;
  border-color: #000;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.rdtPicker {
  padding: 12px 16px !important;
  color: rgba(0, 0, 0, 0.85) !important;
  margin-top: 0 !important;
  position: absolute;
  font-size: 0.875rem !important;
  box-shadow: 0px 0px 40px 0px rgb(79 61 101 / 15%) !important;
  border-radius: 0.25rem !important;
  background-color: #ffffff !important;
  min-width: 160px !important;
}
.rdtCounter .rdtCount {
  color: #3f3f3f;
  font-weight: 100;
  font-size: 14px !important;
}
.rdtCounterSeparator {
  line-height: 96px !important;
}
.rdtCounter .rdtBtn:hover {
  /* background: #eee; */

  border: none;
  border-radius: 3px !important;
}
.bs-stepper-content .string-check .string-check-label {
  min-height: 18px;
  margin-left: 1.375rem;
  display: inline-flex !important;
  margin-bottom: 0;
  font-size: 0.8125rem;
  line-height: 1.5;
}
.bs-stepper-content {
  color: #3f3f3f;
  background: #ffffff;
  display: block;
  margin: 0.5em;
  min-height: 24em;
  /* overflow: hidden; */
  position: relative;
  width: auto;
  padding: 30px !important;
  border-radius: 0px !important;
  border: 1px solid #e6e4e9;
}

.item {
  width: 448px;
  margin-right: 20px;
}
.navbar-container {
  transition: all 0.3s;
  min-height: calc(100vh - 3.75rem);
  padding: 0;
  position: fixed;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: -1 !important;
  display: flex;
  box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
  -ms-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
}
.ant-picker.form-control.home-datepicker.ant-picker-focused {
  border-right: 0 !important;
}
.cm_check {
  width: 50px;
  height: 50px;
  background: #ef3e27;
  border-radius: 100%;
  margin: 0 auto;
  margin-bottom: 30px;
  position: relative;
}
.cm_check:hover {
  background: #000;
}

.btn-primary:focus,
.wizard > .actions a:focus,
.btn-primary.focus,
.wizard > .actions a.focus {
  box-shadow: 0 !important;
}
.cm_check:before {
  content: '';
  width: 16px;
  height: 25px;
  border-right: 3px solid #000;
  border-bottom: 3px solid #000;
  position: absolute;
  top: 9px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  left: 16px;
}

.btn-primary.focus,
.btn-primary:focus {
  color: #fff;
  background-color: #000 !important;
  border-color: #000 !important;
  box-shadow: 0 !important;
}

.cm_check:hover::before {
  content: '';
  width: 16px;
  height: 25px;
  border-right: 3px solid #ef3e27;
  border-bottom: 3px solid #ef3e27;
  position: absolute;
  top: 9px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  left: 16px;
}

.eye_icon {
  position: absolute;
  width: 22px;
  top: 12px;
  right: 15px;
  cursor: pointer;
  z-index: 99;
}
.eye_icon svg {
  fill: #ced4da;
}

.form-group.inner-addon {
  position: relative;
}

.eye_icon.slesh:after {
  content: '';
  position: absolute;
  width: 26px;
  height: 3px;
  background: #ced4da;
  left: -2px;
  top: 8px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  border-radius: 3px;
}

.center {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}

/* Simple CSS3 Fade-in Animation */
.underlineHover:after {
  display: block;
  left: 50%;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: #000;
  content: '';
  transition: width 0.2s;
}

.underlineHover:hover:after {
  width: 100%;
}

button.nav-link.dropdown-toggle.dropdown-toggle.btn.btn-primary,
button.nav-link.dropdown-toggle.dropdown-toggle.btn.btn-primary:focus {
  background: none;
  border: none;
}

.delete-content .cm_modal {
  padding: 10px 15px;
}
.delete-content {
  width: 30% !important;
  border-radius: 2px;
}

@media (max-width: 767px) {
  .delete-content {
    width: 90% !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .delete-content {
    width: 50% !important;
  }
  /* .event-card-img {
    height: 180px !important;
  } */
}

.main-panel {
  transition: all 0.25s ease, margin 0.25s ease;
  width: 100%;
  min-height: calc(100vh - 3.75rem);
  top: 3.75rem;
  right: 0;
  display: flex;
  flex-direction: column;
  padding-left: 270px;
}

.white-footer {
  background: #ffffff;
  color: #ababbb;
  padding: 1.5rem;
  font-size: calc(0.875rem - 0.05rem);
  font-weight: 400;
}

.error {
  -webkit-filter: Shadow(Color=#666666, Direction=135, Strength=5) !important;
          filter: Shadow(Color=#666666, Direction=135, Strength=5) !important;
  color: red;
  text-align: left;
  margin-top: 10px !important;
  margin-left: 12px;
}

.event-form-home.card {
  bottom: -50px !important;
}

.page-title {
  text-transform: uppercase;
  font-weight: 500 !important;
  font-family: chivoBold;
  color: #3f3f3f !important;
  width: 270px !important;
  padding-left: 1.875rem !important;
}
.card .card-header .card-title {
  color: #3f3f3f;
  margin-bottom: 0;
  font-weight: 500 !important;
  font-family: chivoBold !important;
  text-transform: uppercase;
}
.ant-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50);
}

.modal-header {
  border: 0 !important;
}

form.home-form {
  padding: 0.7rem 0.5rem;
}

.modal .modal-dialog .modal-content .modal-header .close span {
  font-size: 28px;
  font-weight: 400;
  color: #333 !important;
}

button.btn.btn-brand.form {
  padding: 19px 25px;
  position: absolute;
  right: 0;
  background-color: #ef3e27;
  color: #fff;
  border-radius: 0px;
  bottom: 0;
}

.btn i,
.wizard > .actions a i,
.fc button i {
  font-size: 1.25rem !important;
}

label.m-0.text-capitalize.font-size-12 {
  color: #737373 !important;
  font-size: 12px !important;
}

p.text-white.font-size-16 {
  line-height: 1.5 !important;
  margin-bottom: 1rem;
}

h1.text-uppercase.text-white.font-weight-bold {
  font-size: 2.5rem;
  max-width: 45% !important;
  font-weight: bold !important;
  letter-spacing: 1px !important;
}

/*.......................................... loader ............................................*/

.loader_wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  z-index: 999999;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.5);
}

.lds-roller {
  display: inline-block;
  width: 64px;
  position: absolute;
  height: 64px;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.lds-roller div {
  -webkit-animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -webkit-transform-origin: 32px 32px;
          transform-origin: 32px 32px;
}

.lds-roller div:after {
  content: ' ';
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #fff;
  margin: -3px 0 0 -3px;
}

.lds-roller div:nth-child(1) {
  -webkit-animation-delay: -0.036s;
          animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 50px;
  left: 50px;
}

.lds-roller div:nth-child(2) {
  -webkit-animation-delay: -0.072s;
          animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 54px;
  left: 45px;
}

.lds-roller div:nth-child(3) {
  -webkit-animation-delay: -0.108s;
          animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 57px;
  left: 39px;
}

.lds-roller div:nth-child(4) {
  -webkit-animation-delay: -0.144s;
          animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 58px;
  left: 32px;
}

.lds-roller div:nth-child(5) {
  -webkit-animation-delay: -0.18s;
          animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 57px;
  left: 25px;
}

.lds-roller div:nth-child(6) {
  -webkit-animation-delay: -0.216s;
          animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 54px;
  left: 19px;
}

.lds-roller div:nth-child(7) {
  -webkit-animation-delay: -0.252s;
          animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 50px;
  left: 14px;
}

.lds-roller div:nth-child(8) {
  -webkit-animation-delay: -0.288s;
          animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 45px;
  left: 10px;
}

@-webkit-keyframes lds-roller {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes lds-roller {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.loader_wrapper.with_message {
 background:#fff;
}
.loader_wrapper.with_message h3 {
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: center;
  height: 100%;
  align-items: center;
  margin-top: -100px;
  padding:0px 20px;
 }
.loader_wrapper.with_message .lds-roller div:after {
  background: rgba(0,0,0,0.6);
}
/*,......................................event pages...........................................  */

.event .event-banner {
  position: relative;
  margin-top: 5rem;
  border-radius: 1px;
  height: 530px;
  z-index: 22;
  overflow: hidden;
  background: no-repeat center center;
  background-size: cover;
}

.event .event-banner a:hover {
  color: #fff;
}

.event-banner-mb .shadow,
.event .event-banner .shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 -90px 100px #000000 !important  ;
  /* border-radius: 20px; */
  top: 0;
  left: 0;
}

.event .event-banner .event-title {
  padding: 2rem;
  position: relative;
  z-index: 111;
}

.event .event-banner .event-price-container {
  height: 70px;
  position: relative;
  z-index: 111;
}

.event .event-banner .event-price-container a:hover {
  background-color: #e1250d;
  transition: all 0.3s;
}

.event .event-banner .event-price-container .event-price {
  background-color: #ebebeb;
  padding: 1.2rem;
}

.event-banner-mb {
  height: 450px;
  border-radius: 20px;
  z-index: 1;
  position: relative;
  box-shadow: 0px 0px 43px -10px rgba(0, 0, 0, 0.75);
}

.event-banner-mb .event-title {
  margin: 2rem;
  position: relative;
  z-index: 111;
}

.event-banner-mb .event-price-container {
  height: 70px;
  position: relative;
  z-index: 111;
}

.event-banner-mb .event-price-container .event-price {
  background-color: #ebebeb;
  padding: 1.2rem;
}

.event .event-info {
  margin-left: 2rem;
}

.buy-tickets .btn:hover {
  background-color: #e1250d !important;
  transition: all 0.3s;
}

.feed .feed-body .user-img {
  height: 70px;
  width: 70px;
}

.feed .feed-footer .action-link.active {
  text-decoration: underline !important;
}

.feed .feed-footer .action-link {
  text-decoration: none;
  color: #404040;
}

.feed .feed-footer .action-link:hover {
  color: #ef3e27;
  transition: all 0.3s;
}

.feed-comment .user-img {
  height: 50px;
  width: 50px;
}

.feed-comment .comment-panel {
  background-color: #f4f4f4;
}
.event-location img {
  width: 100%;
}

.event-address {
  position: relative;
  top: -45px;
  box-shadow: 1px -1px 4px 1px rgb(230 219 219 / 59%);
}
.event-addresses {
  position: relative;
}
.box-shadow {
  box-shadow: 1px -1px 4px 1px rgb(230 219 219 / 59%);
}
.text-black {
  color: #000 !important;
}
.custom-item {
  padding: 0px 30px;
}
.custom-item > a {
  color: #404040 !important;
  position: relative;
  padding: 32px 0px !important;
  line-height: normal;
  line-height: initial;
}
.custom-item > a:hover {
  background: #fff !important;
  text-decoration: none !important;
}
.custom-item > a:after {
  content: '';
  position: absolute;
  height: 4px;
  width: 0px;
  opacity: 0;
  transition: 0.3s ease;
  background: #ef3e27;
  left: 0;
  bottom: 0;
}
.custom-item > a:hover:after,
.custom-item > .active:after {
  width: 100%;
  opacity: 1;
}
.custom-item > .active {
  color: #404040 !important;
}
.event-address-container {
  padding: 0 230px;
  background: linear-gradient(180deg, #f4f4f4 67%, #ffffff 67%);
}

.manage-bg {
  background: linear-gradient(180deg, #f4f4f4 50%, #fff 50%) !important;
}

.promoter-img {
  width: 100px;
  height: 100px;
  flex-shrink: 0;
}

.related-events {
  margin-bottom: 4rem;
}

.related-events .item {
  height: 400px;
}
.related-events .item .event-body {
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  box-shadow: inset 0 -250px 700px #000000;
}

.related-events .item .event-link {
  text-decoration: none;
  color: #fff;
}

.related-events .item .event-link:hover,
.related-events .item .event-body a:hover {
  color: #ef3e27;
  transition: all 0.3s;
}

.related-events .item .event-body p {
  word-wrap: break-word;
  width: 100%;
}

.related-events .item .event-body .action-link {
  color: white;
  text-decoration: none;
}

.event-img-gallery {
  display: flex;
  flex-wrap: wrap;
}

.event-img-gallery a {
  width: 33.33%;
  padding: 2px;
}

.event-img-gallery img {
  width: 100%;
}

.event-img-gallery img:hover {
  opacity: 1;
}
body.event-bdy {
  background-size: 100% 630px;
}

body.event-list-bdy {
  background-size: 100% 630px;
  background-color: #1c1c1c;
}

.overlay-bg {
  position: absolute;
  width: 100%;
  height: 630px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.base-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.content-wrapper {
  overflow: hidden;
  min-height: 100vh;
}

.navbar-mb-menu-container {
  z-index: 12;
  position: relative;
  width: 100%;
  height: 80px;
}

.navbar-mb-more {
  position: absolute;
  top: -1000px;
  width: 280px;
  z-index: 1;
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}

.navbar-mb-more.show {
  top: 80px;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  right: 0;
}

.banner-front {
  background-color: #000;
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.banner-front form {
  width: 500px;
}

.banner-front button {
  position: absolute;
  left: 0;
  padding: 0;
  bottom: 1rem;
}
.banner-front .form-control:focus {
  color: #808080;
}

.banner-front input {
  padding: 1rem 2rem;
  color: #808080;
}

.accordion .card-header {
  padding: 1.1rem 0;
}

.accordion .card-header .card-title {
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  color: #404040;
}

.accordion .card-header:after {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: '\f068';
  float: right;
  color: #ef3e27;
}
.accordion .card-header.collapsed:after {
  content: '\f067';
}

/* */
.success-content {
  margin: auto !important;
  min-height: calc(100vh - 400px);
  max-width: 1045px;
  width: 90%;
}
.success-content .content-title {
  font-weight: 700;
  font-size: 30px;
  text-transform: uppercase;
  padding: 40px 0;
  margin: 0;
  text-align: center;
  color: #404040;
}
.content-event {
  margin: 0 230px;
  min-height: calc(100vh - 0px - 320px);
}

.content .content-title {
  font-weight: 700;
  font-size: 30px;
  text-transform: uppercase;
  padding: 40px 0;
  margin: 0;
  text-align: center;
  color: #404040;
}

.card {
  border-radius: 0px;
}
.mb-cart-info {
  display: flex;
  align-items: center;
  position: relative;
  height: 80px;
  box-shadow: 0px -6px 34px -14px rgba(0, 0, 0, 0.75);
}
.mb-cart-info .cart,
.mb-cart-info .checkout {
  padding: 1.25rem;
}
.mb-cart-info .cart .cart-toggle::after {
  content: none;
}
.mb-cart-info .cart .count {
  position: absolute;
  top: 12px;
  left: 35px;
}
.mb-cart-info .cart .cart-container {
  top: -375px !important;
  border-radius: 0;
  width: 300px;
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.tagline {
  color: #fff !important;
}

.sidebar-overlay {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 99;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.sidebar-overlay.active {
  display: block;
  opacity: 0.1;
}

.footer {
  background-color: #000;
  padding: 0 230px;
  bottom: 0;
  position: relative;
  width: 100%;
}

.footer .footer-body {
  padding: 40px 0;
  border-bottom: 2px solid #1c1c1c;
}
.footer .footer-body ul li a:hover,
.footer .footer-privacy a:hover {
  color: #ef3e27 !important;
  transition: all 0.3s;
}
.footer .footer-body ul li a {
  color: #aeaeae;
  text-decoration: none;
  line-height: 1.7;
}

.footer .footer-privacy span {
  color: #aeaeae;
}
.footer .footer-privacy i {
  font-size: 14px;
}
.footer-support {
  background-color: #f4f4f4;
  padding: 3rem;
}
/* @media screen and (max-width:1800px){
  .custom-item {
    padding: 0px 17px;
}
.navbar-home .navbar-nav > .nav-item a {
    padding: 32px 17px !important;
}
} */
.mobile-show {
  display: none !important;
}
@media screen and (max-width: 1800px) {
  .custom-item {
    padding: 0px 13px;
  }
  .navbar-home .navbar-nav > .nav-item a {
    padding: 32px 13px !important;
  }
}
@media screen and (max-width: 1600px) {
  .custom-item {
    padding: 0px 11px;
  }
  .navbar-home .navbar-nav > .nav-item a {
    padding: 32px 11px !important;
  }
}
@media screen and (max-width: 1440px) {
  .custom-item {
    padding: 0px 10px;
  }
  .navbar-home .navbar-nav > .nav-item a {
    padding: 32px 10px !important;
  }
  .navbar-home .navbar-nav > .nav-item input {
    width: 140px;
  }
}
@media screen and (max-width: 1380px) {
  .custom-item {
    padding: 0px 8px;
  }
}
@media screen and (max-width: 1280px) {
  .mobile-show {
    display: block !important;
  }
  .navbar-mb-container.active {
    right: 0;
  }
  .mobile-hide {
    display: none !important;
  }
}

@media only screen and (max-width: 1025px) {
  .navbar .navbar-nav .nav-item {
    margin-right: 20px;
  }
  .success-content,
  .content {
    margin: 0 100px;
  }
  .mb-cart-info {
    padding: 0 100px;
  }
  .mb-cart-info .cart {
    padding-left: 0;
  }
  .mb-cart-info .cart .count {
    position: absolute;
    top: 12px;
    left: 114px;
  }

  .footer {
    padding: 0 100px;
  }
}

label {
  color: #737373;
  font-size: 15px;
}

.event .event-banner {
  position: relative;
  margin-top: 5rem;
  height: 530px;
  z-index: 22;
  overflow: hidden;
  background-size: 100% !important;
}
a.link:hover {
  color: black;
}
h2.text-uppercase.text-white.font-weight-bold.mb-0 {
  font-size: 2rem;
}
h3.text-uppercase.border-bottom {
  color: #404040;
  font-family: chivofont;
  font-weight: 100 !important;
  font-size: 1.75rem;
}
p {
  line-height: 1.5 !important;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

a.btn.btn-outline-brand.border-brand {
  border-radius: 0px;
  color: #ef3e27;
  border-color: #dddddd;
  background-color: #fff;
}
text-brand {
  color: #ef3e27 !important;
}
.border-brand {
  border-color: #ef3e27 !important;
}
.btn-outline-brand:hover {
  color: #fff !important;
  background-color: #ef3e27 !important;
}

h4.font-weight-bold.text-capitalize {
  font-size: 1.5rem;
  color: #404040;
}
button.btn-lg.btn-brand {
  font-size: 1.25rem;
}

.border-brand {
  color: #ef3e27 !important;
  border-color: #ef3e27 !important;
}

.border-3 {
  border-width: 3px !important;
}

button.btn.btn-outline-brand.bg-transparent.border-brand.border-3.pl-4.pr-4 {
  font-size: 15px;
  font-weight: 100;
}
button.btn.btn-brand.pl-3.pr-3.mr-1 {
  font-size: 15px;
  font-weight: 100;
}
.item.evnt-details-owl {
  background: no-repeat;
  background-size: 100% 100% !important;
}
.item.evnt-details-owl {
  width: 100%;
}
h4.text-capitalize.font-weight-bold.event-link-text {
  color: #fff !important;
}
h4.text-capitalize.font-weight-bold.event-link-text:hover {
  color: #ef3e27 !important;
}
h3.text-uppercase.font-weight-bold.pt-4.pb-2.mb-3.text-center {
  color: #404040;
  font-family: chivofont;
  font-weight: 100 !important;
  font-size: 1.75rem;
}

.event-detail {
  background: linear-gradient(180deg, #f4f4f4 41.4%, #ffffff 41.4%);
  font-family: 'Chivo', sans-serif;
  letter-spacing: 0.3px;
  color: #404040;
  font-size: 0.875rem;
}

button.nav-link-home.navbar-toggler.navbar-toggler-right.align-self-center {
  color: #fff !important;
}

img.widget-8-img {
  width: 82px !important;
  height: 42px !important;
}

.content-wrapper.manage-event {
  min-height: 80vh !important;
}

ul.ant-dropdown-menu.ant-dropdown-menu-light.ant-dropdown-menu-root.ant-dropdown-menu-vertical {
  position: absolute;
  top: 0px;
  left: 0px;
  margin: 0px;
  right: auto;
  bottom: auto;
  -webkit-transform: translate(-97px, -5px) !important;
          transform: translate(-97px, -5px) !important;
  width: 200px !important;
}

.link-home {
  text-decoration: none !important;
}
.link-home:hover {
  color: #ef3e27 !important;
  text-decoration: none !important;
}

.menu-item:hover {
  color: #ef3e27 !important;
  text-decoration: none !important;
}
.ant-picker.form-control.home-datepicker {
  border-right: 0 !important;
}

.navbar-nav.navbar-nav-right {
  outline: none !important;
}
button.nav-link.dropdown-toggle.dropdown-toggle.btn.btn-primary {
  outline: none !important;
}
button.nav-link.dropdown-toggle.dropdown-toggle.btn.btn-primary,
button.nav-link.dropdown-toggle.dropdown-toggle.btn.btn-primary:focus {
  background: none !important;
  outline: none;
  box-shadow: none !important;
  border: none !important;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background: #ef3e27 !important;
  border: #ef3e27 !important;
  outline: #ef3e27 !important;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  border: 1px solid #ef3e27 !important;
  border-radius: 2px;
  content: '';
}

.ant-picker-cell-inner:hover {
  background: #ffdcda !important;
}

.ant-picker-header-view button:hover {
  color: #ef3e27 !important;
}
.ant-picker:hover,
.ant-picker-focused {
  border-color: #ef3e27;
  box-shadow: none !important;
  border-right-width: 1px !important;
}

.event-list .event-item .event-schedule .event-day {
  font-size: 70px;
  font-weight: 100;

  font-family: chivoLight !important ;
}
.event-list .event-item .event-schedule .event-month-time span {
  font-weight: 100;

  font-family: chivoLight !important ;
}
a.active {
  color: #ef3e27 !important;
}
.pagination li a {
  text-decoration: none;
  position: relative;
  display: block;
  border-radius: 2px !important;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  margin: 3px;
  line-height: 1.25;
  color: #404040;

  border: 0px solid #ef3e27;
}
.pagination {
  list-style: none;
}

.pagination li.disabled a {
  cursor: not-allowed;
  z-index: 1;
  color: rgba(0, 0, 0, 0.25);

  border-radius: 0;
}
.pagination li.active a {
  z-index: 1;
  color: #fff;
  background-color: #ef3e27;
  border-color: #ef3e27;
}
.pagination li:hover a {
  z-index: 1;
  color: #fff;
  background-color: #ef3e27;
  border-color: #ef3e27;
}

/* p.card-p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
} */

.select-limit span.ant-select-arrow {
  color: #000;
  font-weight: bold;
  font-family: chivoBold;
}
.ant-select-disabled.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  color: rgba(0, 0, 0, 0.25);
  background: none !important;
  cursor: default;
  color: #404040 !important;
}
.ant-select-disabled span.ant-select-arrow {
  display: none;
}
/* .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border: 1px solid #d9d9d9 !important;
  box-shadow: 0 !important;
} */
.ant-select {
  box-shadow: none !important;
}

li.page-change a {
  background-color: #ececec;
  border-radius: 2px !important;
}

li.page-change.active a {
  background-color: #ececec;
  border-radius: 2px !important;
}

li.page-change:hover a {
  background-color: #ef3e27;
}

.event-lable-ckeck {
  color: #3f3f3f;
}

span.tab-head {
  font-family: ChivoLight !important;
  font-size: 12px;
}
button.btn.btn-primary.filter {
  padding: 7px 12px !important;
  font-size: 12px !important;
  letter-spacing: 0.1px;
}
.card .card-header .card-title .card-title-secondary {
  color: #8d8a89;
  font-family: chivoLight !important;
  padding-left: 0.5rem;
  font-weight: 300;
}

.ant-tabs-nav {
  left: 20px;
  top: 10px;
  color: #000 !important;
}
.ant-tabs-tab.ant-tabs-tab-active {
  color: #ef3e27 !important;
  font-weight: 500;
}
.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  background: #ef3e27 !important;
}
.ant-tabs-ink-bar.ant-tabs-ink-bar-animated:hover {
  background: #ef3e27 !important;
}
.ant-tabs-tab:hover {
  color: #ef3e27;
}
.mini_loader {
  width: 40px;
  height: 40px;
  border: 2px #347bde solid;
  border-radius: 50%;
  -webkit-animation: rotation 1s ease-in-out infinite;
          animation: rotation 1s ease-in-out infinite;
  margin: 30px auto;
  position: absolute;
  left: 75px;
  bottom: -8px;
}
.mini_loader:after {
  width: 8px;
  height: 8px;
  background-color: rgb(0, 0, 0);
  border-radius: 100%;
  position: absolute;
  content: '';
}
.modal-body-popup div:first-child {
  color: #000 !important;
  background: #fff !important;
}
.modal-body-popup svg {
  fill: #000 !important;
}

.autocomplete-dropdown-container {
  z-index: 1000;
  padding: 0px 2px 1px;
}

.upload_doc {
  display: block;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  height: 314px;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 22px;
  color: #777;
  background-color: #fff;
  background-image: none;
  text-align: center;
  border: 2px solid #e5e5e5;
  transition: border-color 0.15s linear;
}
img.ReactCrop__image {
  background-repeat: no-repeat !important;
  background-position: 50% 50% !important;
  background-size: contain !important;
  height: 314px;
}

.upload_doc p {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  margin: 0;
}

.ant-select.form-control-select.ant-select-single.ant-select-show-arrow {
  width: 100%;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}
.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .form-control-select {
  height: 39px !important;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  height: 39px !important;
  padding: 0 11px;
}
.ant-select-selector {
  outline: none !important;
  box-shadow: none !important;
}
/* .ant-select-focused.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: #ef3e27 !important;
} */

/* .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #ef3e27 !important;
} */

/* .ant-select-focused.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: #ef3e27 !important;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 !important;
  box-shadow: 0 !important;
} */

.status_button.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  position: relative;
  background-color: #ef3e27 !important;
  border: 1px solid #ef3e27;
  border-radius: 0px;
  color: #fff !important;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  font-weight: bold !important;
  font-size: 12px !important;
  height: 33px !important;
  padding: 0 11px;
}
.status_button.ant-select-single.ant-select-show-arrow
  .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 18px;
  top: 0px !important;
}
.status_button span.ant-select-arrow {
  display: none;
}
.status_button.ant-select-single.ant-select-show-arrow
  .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 0px !important;
}
.ant-select-item:hover {
  color: #ef3e27 !important;
}
.ant-select-item.ant-select-item-option.ant-select-item-option-active.ant-select-item-option-selected {
  color: #ef3e27 !important;
  font-weight: 100 !important;
  background-color: none !important;
  background: none !important;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #ef3e27 !important;
  font-weight: 100 !important;
  background-color: #ececec !important;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 18px;
  top: 3px !important;
}
.pagination li.disabled a {
  cursor: not-allowed;
  z-index: 1;
  color: #404040;
  border-radius: 0;
}
.dropdown-arrow {
  font-size: 11px !important;
}

li.sub-menu {
  font-weight: 100;
  text-transform: none;
  padding: 0.9em 0.5em 0.5em 1em !important;
  position: relative;
  font-size: 0.8125rem;
}
.ant-picker-range .ant-picker-active-bar {
  bottom: -1px;
  height: 2px;
  margin-left: 11px;
  background: #8f8c8b;
  opacity: 0;
  transition: all 0.3s ease-out;
  pointer-events: none;
}
.ant-picker.ant-picker-range {
  width: 100%;
  height: 39px;
}
.rang-picker .ant-picker-suffix {
  position: absolute;
  background: #f5f5f7;
  padding: 11px 10px;
  right: 0;
  border-left: 1px solid #e6e4e9 !important;
  padding-left: 12px;
}
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
  position: absolute;
  top: 50%;
  z-index: 0;
  height: 24px;
  border-top: 1px dashed #ef3e27;
  border-bottom: 1px dashed #ef3e27;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  content: '';
}

.invoice .invoice-header {
  display: flex;
  padding: 4rem;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #ef3e27;
}
.invoice .invoice-body table thead th {
  border-top: 0;
  font-weight: 500;
  font-size: 1rem;
  color: #ef3e27;
  border-color: #ef3e27;
  text-transform: uppercase;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: #ffdcda !important;
}

.menu_icon {
  position: absolute;
  width: 40px;
  height: 40px;
  padding: 7px;
  border-radius: 7px;
  line-height: 25px;
  left: 29px;
  top: 0;
}
.sidebar ul li {
  position: relative;
  padding: 12px;
  padding-left: 63px;
}

li.sub-menu {
  padding-left: 10px !important;
}

button.btn.btn-light {
  margin-right: 4px;
}

.login_wrapper {
  height: 100vh;
  min-height: 630px;
}
.bg_white {
  background: #fff;
  border-radius: 20px;
  padding: 50px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  min-height: 500px;
}

@media (max-width: 1023px) {
  .bg_white {
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
    padding: 15px 15px;
    min-height: 400px;
  }
}

.page_content {
  margin: 0 auto;
  max-width: 511px;
}

h4.cm_heading {
  font-size: 5rem;
}

.sidebar ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.cm_col {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: 250px;
}

.ant-select.cm-select-box.ant-select-single.ant-select-show-arrow.ant-select-open {
  width: 100%;
}
.ant-select.cm-select-box.ant-select-single.ant-select-show-arrow {
  width: 100%;
}
.ant-select.cm-select-box-2.ant-select-single.ant-select-show-arrow.ant-select-open {
  width: 100%;
}
.ant-select.cm-select-box-2.ant-select-single.ant-select-show-arrow {
  width: 100%;
}

p.event-content.dashboar {
  word-wrap: break-word;
}

h5.text-white.font-weight-bold.mb-3 {
  word-wrap: break-word;
}

span.search {
  position: absolute;
  right: 215px;
  top: 29px;
}
input.form-control.search-box {
  height: 35px;
  max-width: 202px;
  float: right;
  margin-bottom: 15px;
}

.ant-time-picker-input::-webkit-input-placeholder, .ant-calendar-range-picker-input::-webkit-input-placeholder, .ant-time-picker-input::-webkit-input-placeholder {
  color: #000000ad;
  opacity: 1; /* Firefox */
}

.ant-time-picker-input:-ms-input-placeholder, .ant-calendar-range-picker-input:-ms-input-placeholder, .ant-time-picker-input:-ms-input-placeholder {
  color: #000000ad;
  opacity: 1; /* Firefox */
}

.ant-time-picker-input::placeholder,
.ant-calendar-range-picker-input::placeholder,
.ant-time-picker-input::placeholder {
  color: #000000ad;
  opacity: 1; /* Firefox */
}
.ant-time-picker-input:-ms-input-placeholder,
.ant-time-picker-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000000ad;
}
.selectHeight {
  height: auto !important;
}

.ant-calendar-input::-webkit-input-placeholder, .ant-calendar-picker-input::-webkit-input-placeholder {
  color: #000000ad;
  opacity: 1; /* Firefox */
}

.ant-calendar-input:-ms-input-placeholder, .ant-calendar-picker-input:-ms-input-placeholder {
  color: #000000ad;
  opacity: 1; /* Firefox */
}

.ant-calendar-input::placeholder,
.ant-calendar-picker-input::placeholder {
  color: #000000ad;
  opacity: 1; /* Firefox */
}
.ant-calendar-picker-input:-ms-input-placeholder,
.ant-calendar-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000000ad;
}

.ant-calendar-picker-input::-ms-input-placeholder,
.ant-calendar-input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #000000ad;
}

.ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
  font-size: 17px;

  color: rgba(0, 0, 0, 0.59) !important;
}
.ant-select-selection__placeholder {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.68) !important;
}

.ant-picker-input::-webkit-input-placeholder, .ant-picker-input > input::-webkit-input-placeholder {
  color: #8f8c8b !important;
  font-size: 13px;
  opacity: 1; /* Firefox */
}

.ant-picker-input:-ms-input-placeholder, .ant-picker-input > input:-ms-input-placeholder {
  color: #8f8c8b !important;
  font-size: 13px;
  opacity: 1; /* Firefox */
}

.ant-picker-input::placeholder,
.ant-picker-input > input::placeholder {
  color: #8f8c8b !important;
  font-size: 13px;
  opacity: 1; /* Firefox */
}
.home-datepicker .ant-picker-input::-webkit-input-placeholder, .home-datepicker .ant-picker-input > input::-webkit-input-placeholder {
  color: #8f8c8b !important;
  font-size: 15px !important;
  opacity: 1; /* Firefox */
}
.home-datepicker .ant-picker-input:-ms-input-placeholder, .home-datepicker .ant-picker-input > input:-ms-input-placeholder {
  color: #8f8c8b !important;
  font-size: 15px !important;
  opacity: 1; /* Firefox */
}
.home-datepicker .ant-picker-input::placeholder,
.home-datepicker .ant-picker-input > input::placeholder {
  color: #8f8c8b !important;
  font-size: 15px !important;
  opacity: 1; /* Firefox */
}
.select-place::-webkit-input-placeholder {
  color: #8f8c8b !important;
  font-size: 15px !important;
}
.select-place:-ms-input-placeholder {
  color: #8f8c8b !important;
  font-size: 15px !important;
}
.select-place::placeholder {
  color: #8f8c8b !important;
  font-size: 15px !important;
}
.widget-status-desc {
  width: 814px;
  display: flex;
  align-items: center;
}

span.cancel-btn {
  padding: 0.53125rem 0.8125rem;
}

span.btn.btn-back.btn-outline-brand.border-brand.remove-btn {
  position: absolute;
  top: 0;
  right: 0;
  color: #737373 !important;
}
span.btn.btn-back.btn-outline-brand.border-brand.remove-btn:hover {
  color: #212529 !important;
  background-color: #e2e7ec !important;
  border-color: #737373 !important;
}
.ReactCrop {
  position: relative;
  display: inline-block;
  cursor: crosshair;
  overflow: hidden;
  top: -5px !important;
  max-width: 100%;
}

.mange-evnt {
  font-weight: 200 !important;
  font-family: chivoBold !important;
}

h3.text-white.text-uppercase.font-weight-bold.pb-3.event-list-title {
  font-size: 1.75rem !important;
}

.event-body .event-list .event-owl.owl-carousel .event-card .event-date {
  position: absolute;
  font-size: 1rem !important;
  font-family: chivoBold !important;
  background-color: white;
  font-weight: 500 !important;
  width: 70px;
  height: 70px;
  color: #000;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  top: 0;
  left: 0;
}

.related-events .item .event-date {
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ef3e27;
  color: white;
  width: 80px;
  position: absolute;
  padding: 0.4rem 1rem;
  top: 0;
  left: 0;
  font-size: 20px;
  text-transform: uppercase;
}

.table-bordered {
  border-bottom: 2px solid #e6e4e9 !important;
}
.card-news {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.table tr th {
  color: #3f3f3f;
  border-color: #e6e4e9;
}

.menu-link {
  font-family: chivoBold !important;
  color: #fff !important;
  text-decoration: none !important;
}

h5.font-weight-bold {
  color: #404040;
  font-size: 1.22rem;
}

.event-step {
  padding-left: 51px !important;
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.select-limit.ant-select-single.ant-select-show-arrow
  .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 18px;
  top: -2px !important;
}

span.tab-head {
  font-family: ChivoLight !important;
  font-weight: 600 !important;
  font-size: 12px;
}

.home-datepicker .ant-picker-suffix {
  display: none !important;
}

h3.widget-20-figure {
  font-family: chivoBold !important;
}

.ant-select-item-option-content {
  color: #8d8a89 !important;
  font-size: 0.8125rem;
  font-weight: 400;
}

.filter-icon {
  margin-right: 5px !important;
}

input.dropify.p-0 {
  display: block;
  border: 1px solid #e6e4e9;
}

.imagePreview:hover .overlay {
  opacity: 1;
}
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  background: rgba(0, 0, 0, 0.705);
}

.text {
  color: white;
  font-size: 13px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
.file-name {
  font-family: chivoBold !important;
  font-size: 15px !important;
}
.line::before {
  content: '';
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  background: #fff;
  width: 30px;
  height: 2px;
}
.upload_doc.imagePreview {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  /* max-width: 800px; */
}

.upload_doc {
  outline: none !important;
  box-shadow: none !important;
}

button.dropify-clear {
  z-index: 9999 !important;
}

.dropify-wrapper:hover {
  background-size: 30px 30px;
  background-image: linear-gradient(
    -45deg,
    #f6f6f6 25%,
    transparent 25%,
    transparent 50%,
    #f6f6f6 50%,
    #f6f6f6 75%,
    transparent 75%,
    transparent
  ) !important;
  -webkit-animation: stripes 2s linear infinite;
  animation: stripes 2s linear infinite;
}
@-webkit-keyframes stripes {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 60px 30px;
  }
}
@keyframes stripes {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 60px 30px;
  }
}

.bootstrap-timepicker-widget table td span {
  border: solid #3f3f3f;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 4px;
  width: 7px;
  cursor: pointer;
}
.bootstrap-timepicker-widget table td span.menu-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.bootstrap-timepicker-widget table td span.menu-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.bootstrap-timepicker-widget table td input {
  width: 25px;
  margin: 0;
  text-align: center;
}

.bootstrap-timepicker-widget table td input {
  border: none;
  color: #3f3f3f;
  font-weight: 400;
  background-color: #ffffff;
}

.btn-up-down:hover {
  background-color: #ececec;
  border: none;
  border-radius: 4px !important;
}

.ant-popover-inner {
  margin-top: 0;
  font-size: 0.875rem;
  box-shadow: 0px 0px 40px 0px rgba(79, 61, 101, 0.15);
  border-radius: 0.25rem;
  background-color: #ffffff;
}

.bootstrap-timepicker-widget table td:not(.separator) {
  min-width: 30px;
}
.bootstrap-timepicker-widget table td input {
  width: 38px;
  margin: 0;
  height: 40px;
  text-align: center;
}

.bootstrap-timepicker-widget table td {
  text-align: center;
  height: 30px;
  margin: 0;
  padding: 2px;
}
.event-date {
  text-transform: uppercase;
}
.placholder {
  color: #8f8c8b !important;
}
.ant-select.select-limit.mr-1.ml-1.ant-select-single.ant-select-show-arrow {
  position: relative;
}
.select-limit.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  top: 4px;
  font-family: chivoBold !important;
  -webkit-columns: #000;
          columns: #000;
  height: 25px !important;
  padding: 0 11px;
}
.select-limit span.ant-select-arrow {
  margin-top: -2px !important;
  color: #000;
  font-weight: bold;
  font-family: chivoBold;
}
.white {
  color: #fff !important;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
  object-fit: cover;
  height: 160px;
}
.owl-carousel .owl-stage,
.owl-carousel.owl-drag .owl-item {
  touch-action: auto !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ef3e27 !important;
  font-weight: 500;
}
.autocomplete-dropdown-container {
  line-height: 1.5rem !important;
}
input.form-control {
  position: relative !important;
}

button.btn.btn-sm.btn-dark.dark-black {
  position: absolute;
  top: 33px;
}

input.form-control.price-input {
  position: relative !important;
  padding: 0.53125rem 1.5rem !important;
}
/* ...................svg icons..................................... */

.sidebar ul li a svg path {
  stroke: #fff;
}

.sidebar ul li a:hover svg circle,
.sidebar ul li a:hover svg path {
  stroke: #ef3e27;
}

.sidebar ul li a.active svg circle,
.sidebar ul li a.active svg path {
  stroke: #ef3e27;
}

svg.menu-icon {
  display: inline-block;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 0.75rem;
  width: 1rem !important;
  height: 1rem;
}

.svg-filter {
  margin-left: 10px;
  margin-right: 15px;
  width: 10px;
}
.svg-active {
  margin-left: 10px;
  margin-right: 15px;
  width: 13px;
}
td.btn-up-down {
  cursor: pointer;
}

.cropper-drag-box.cropper-crop.cropper-modal {
  top: -6px;
  height: 314px !important;
}

.modal-backdrop.show {
  opacity: 0.75 !important;
}
.string-check input {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 5px;
  margin-top: 5px;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}

.cropper-bg {
  background-image: none !important;
}

button.cm_hide_upload {
  position: relative;
}
button.cm_hide_upload input {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.newsfeed img.ReactCrop__image {
  background-repeat: no-repeat !important;
  background-position: 50% 50% !important;
  background-size: contain !important;
  height: 200px;
}

h5.card-title {
  text-transform: uppercase;
  font-weight: bold;
}

.autocomplete-dropdown-container {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  -webkit-font-feature-settings: "tnum", ;
          font-feature-settings: "tnum", ;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  width: 100%;
  top: -9999px;
  left: -9999px;
  z-index: 9999;
  overflow: hidden;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 0px 0px 0px rgba(0, 0, 0, 0.05);
}

.suggestion-item {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 12px !important;
  color: #8d8a89 !important;
  font-size: 0.8125rem;
  font-weight: 400;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  transition: background 0.3s ease;
}
.suggestion-item--active:hover {
  color: #ef3e27 !important;
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 12px !important;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  background-color: #ced4da;
  cursor: pointer;
  transition: background 0.3s ease;
}

.ant-select-item-option-content:hover {
  color: #ef3e27 !important;
  font-size: 0.8125rem;
  font-weight: 400;
}

.autocomplete-dropdown-container :hover {
  background-color: #f5f5f5 !important;
}
.text-primary,
.task-list-wrapper .completed .remove {
  color: #ef3e27 !important;
}

.filled-success {
  fill: #00cb8e;
  font-size: 20px;
}
.event-list .event-item .event-schedule .event-icon {
  stroke-width: 1;
  width: 18px;
  font-size: 18px;
  height: 18px;
  margin: 0.6875rem 1rem 0 0;
}

.cm_col2 {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: 306px !important;
}
.ql-editor {
  box-sizing: border-box;
  line-height: 1.42;
  height: 100%;
  outline: 0;
  overflow-y: auto;
  padding: 12px 15px;
  tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.ql-editor.ql-blank::before {
  color: #0000006e !important;
  content: attr(data-placeholder);
  font-style: italic;
  left: 15px;
  font-size: 14px;
  pointer-events: none;
  position: absolute;
  right: 15px;
}

.ql-container {
  color: #3f3f3f;
}

.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow.ql-toolbar button:focus,
.ql-snow.ql-toolbar button:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow.ql-toolbar button:focus,
.ql-snow.ql-toolbar button:hover {
  color: #ef3e27 !important;
}

label.checkbox-checkoutFields {
  color: #3f3f3f;
  font-size: 13px;
  display: inline-block;
  margin-bottom: 0.5rem;
  margin-left: 0.2rem;
}
.event-location.mt-5 {
  height: 600px !important;
}
.gm-style .gm-style-cc span,
.gm-style .gm-style-cc a,
.gm-style .gm-style-mtc div {
  font-size: 10px;
  box-sizing: border-box;
}

img.event-banner-img {
  width: 100%;
  /* height: 100%; */
}
h2.text-uppercase.font-weight-bold.mb-0 {
  font-size: 2rem;
}
/* .event-card-img {
  height: 200px;
} */

.break {
  word-wrap: break-word;
}
.prefix {
  font-size: 15px;
  left: 21px;
  top: 31px;
  position: absolute;
  z-index: 99;
}

[id='heart'] {
  position: absolute;
  left: -100vw;
}

[for='heart'] {
  color: #aab8c2;
  cursor: pointer;
  font-size: 1em;
  align-self: center;
  transition: color 0.2s ease-in-out;
}

[for='heart']:hover {
  color: grey;
}

[for='heart']::selection {
  color: none;
  background: transparent;
}

[for='heart']::moz-selection {
  color: none;
  background: transparent;
}

[id='heart']:checked + label {
  color: #e2264d;
  will-change: font-size;
  -webkit-animation: heart 1s cubic-bezier(0.17, 0.89, 0.32, 1.49);
          animation: heart 1s cubic-bezier(0.17, 0.89, 0.32, 1.49);
}

@-webkit-keyframes heart {
  0%,
  17.5% {
    font-size: 0;
  }
}

@keyframes heart {
  0%,
  17.5% {
    font-size: 0;
  }
}
span.action-link.pl-3.pr-3 {
  cursor: pointer;
}

span.post-btn.mt-2 {
  color: #ef3e27 !important;
  cursor: pointer;
}
span.cancel-btn.mt-2 {
  color: #9e9e9e;
  cursor: pointer;
}
span.cancel-btn.mt-2:hover {
  color: #404040 !important;
}
button.btn.btn-link:hover {
  color: #404040 !important;
  cursor: pointer;
}

button.btn.btn-link {
  font-weight: 100 !important;
  color: #ef3e27 !important;
  text-decoration: none;
}

a.btn.btn-block.btn-lg.btn-brand.pt-3.pb-3.pl-5.pr-5.font-weight-bold {
  background: #ef3e27 !important;
  font-size: 20px;
  border: none;
  text-decoration: none;
}

a.btn.btn-block.btn-lg.btn-brand.pt-3.pb-3.pl-5.pr-5.font-weight-bold:hover {
  background-color: #e1250d !important;
  transition: all 0.3s;
  font-size: 20px;
  color: #fff;
  border: none;
  text-decoration: none;
  border: red;
}

a.btn.btn-block.btn-brand.h-100.font-weight-bold.font-size-20:hover {
  background-color: #e1250d !important;
  transition: all 0.3s;
  color: #fff;
}

.dropdown-menu.cart-container.show {
  position: absolute;
  top: 0;
  -webkit-transform: translate3d(0px, -26px, 0px);
          transform: translate3d(0px, -26px, 0px);
  left: 0px;
  will-change: transform;
}

span#dropdownMenuButton {
  cursor: pointer;
}
span.footer-li {
  color: #aeaeae;
  text-decoration: none;
  line-height: 1.7;
}

span.footer-li:hover {
  color: #ef3e27 !important;
  transition: all 0.3s;
}

.upload_doc.imagePreview {
  background-repeat: no-repeat !important;
  background-position: 50% 50% !important;
  background-size: contain !important;
}
.dropify-wrapper:hover {
  background-image: linear-gradient(
    -45deg,
    #f6f6f6 25%,
    transparent 25%,
    transparent 50%,
    #f6f6f6 50%,
    #f6f6f6 75%,
    transparent 75%,
    transparent
  ) !important;
  -webkit-animation: stripes 2s linear infinite;
          animation: stripes 2s linear infinite;
}

.upload_img {
  background-repeat: no-repeat !important;
  background-position: 50% 50% !important;
  background-size: contain !important;
  border: 1px solid #e6e4e9;
  height: 200px;
  text-align: center;
  border-radius: 5px;
  position: relative;
  outline: none;
  cursor: pointer;
}

.upload_img p {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  word-wrap: break-word;
}

.nesweed-crop img.ReactCrop__image {
  background-repeat: no-repeat !important;
  background-position: 50% 50% !important;
  background-size: contain !important;
  height: 200px !important;
  border-radius: 5px !important;
}
.reply-list {
  list-style: none;
}

ul.reply-list.close {
  display: none;
}

span.text-brand.mr-3.font-size-11 {
  cursor: pointer;
}
span.text-brand.mr-2.font-size-11 {
  cursor: pointer;
}

.btn.btn-block.btn-brand:not(:disabled):not(.disabled) {
  cursor: pointer;
  color: #fff;
}
input.form-control.create-account {
  padding: 12px;
  border: 1px solid #e6e4e9 !important;
  position: relative;
}
.eye_icon1 {
  position: absolute;
  width: 22px;
  top: 31px;
  right: 15px;
  z-index: 99;
  cursor: pointer;
}
.eye_icon1 svg {
  fill: #ced4da;
}

.eye_icon1.slesh:after {
  content: '';
  position: absolute;
  width: 26px;
  height: 3px;
  background: #ced4da;
  left: -2px;
  top: 8px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  border-radius: 3px;
}
.word-break {
  word-wrap: break-word;
}

.btn-flash-primary:not(.btn-flash-light) {
  color: #8d8a89;
}

.sc-bdVaJa.ixTfPP {
  width: 100%;
  border: none !important;
}

img.QRCodeImage {
  width: 150px;
}

.columns {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.uplodeImgContainer {
  position: relative;
  display: flex;
}

div#player {
  height: 100% !important;
}

.vp-center .player {
  height: 100% !important;
}

.uplodeImgContainer-item {
  width: 170px;
  position: relative;
  height: 135px;
  border-radius: 8px !important;
  border: 2px solid #e6e4e9 !important;
  margin-right: 6px;
  display: inline-block !important;
}

.uplodeImgContainer p {
  line-height: 1.5 !important;
  width: 100px;
  color: #ef3e27 !important;
  margin-top: 52px;
  font-weight: bolder;
  font-size: 0.875rem;
  cursor: pointer;
}

div#addeventatc1 {
  z-index: 0 !important;
}
div#addeventatc2 {
  z-index: 0 !important;
}
.uplodeImgContainer p:hover {
  color: #242a34 !important;
}

img.uplodeImg {
  border-radius: 8px;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.overlayImage {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5); /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  transition: 0.5s ease;
  opacity: 0;
  color: white;
  font-size: 20px;
  padding: 20px;
  text-align: center;
}
.uplodeImgContainer-item:hover .overlayImage {
  opacity: 1;
}
.scroll-item:hover .overlayImage {
  opacity: 1;
}
.scroll-item .overlayImage {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgb(0 0 0 / 62%);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 24px;
  text-align: center;
}

.scroll {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}
li.dropdown-item {
  display: block;
  height: 31px !important;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #8d8a89;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-menu .dropdown-item .dropdown-title {
  color: #ef3e27;
  font-weight: 500;
  line-height: 1.8 !important;
}
.btn-flash-border-primary:hover:not(.btn-flash-border-light) {
  color: #ef3e27;
}
.btn-flash-border-primary.disabled,
.btn-flash-border-primary:disabled {
  color: #8d8a89 !important;
  border: 1px solid #8d8a89 !important;
}

ul.dropdown-menu.show {
  max-height: 300px;
  overflow-y: auto;
}
img.rounded {
  max-width: 173px !important;
  min-height: 173px !important;
}

.sortingASC::before {
  content: '\2191';
}
.sortingASC,
.sortingDESC {
  cursor: pointer;
}
.sortingASC::after {
  content: '\2193';
  opacity: 0.3;
}
.sortingDESC::before {
  content: '\2191';
  opacity: 0.3;
}
.sortingDESC::after {
  content: '\2193';
}

.dropdow:hover {
  background-color: #ececec;
  color: #070a1a;
  border: none;
}

.widget-13 .widget-13-body .widget-13-left-section img {
  width: 130px;
  height: 130px;
}
.dropdown-show button.dropdown-toggle.btn.btn-primary {
  background-color: #ffffff !important;
  border: none;
  color: #a09fa7;
  box-shadow: 0px 0px 2px 2px rgba(160, 159, 167, 0.3);
}

.dropdown-show .dropdown-toggle::after {
  display: none;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-add .dropdown-toggle::after {
  display: none;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.title.event-title-name {
  text-transform: uppercase;
}
svg.feather.feather-calendar {
  color: #8d8a89;
}
button.btn.btn-block.btn-brand.h-100.font-weight-bold.font-size-20.disable-checkout {
  color: #fff;
}
.table.table-striped.table-bordered tr {
  height: 55px;
}

img.react-images__view-image.react-images__view-image--isModal {
  display: flex;
  align-items: center;
  -webkit-transform: 0 !important;
          transform: 0 !important;
  width: 800%;
}
main.react-images__pager {
  flex: 1 1 auto;
  position: inherit !important;
}

img.react-images__view-image.react-images__view-image--isModal {
  width: 100%;
  height: 100%;
}
.react-images__view.react-images__view--isModal.css-4o9ng5.css-1ycyyax {
  background: #f7f7f7;
}

.css-9s8aw7 {
  display: inline-block;
  fill: currentColor;
  height: 22px !important;
  stroke: currentColor;
  stroke-width: 0;
  width: 22px !important;
}

.css-rg177k *:focus {
  outline: none !important;
}

button.css-xfk84m.css-1ycyyax,
button.css-1h82jk3.css-1ycyyax {
  outline: none !important;
}

button.react-images__header_button.react-images__header_button--close.css-umpcke.css-1ycyyax {
  outline: none !important;
}

span.action-link {
  cursor: pointer;
}
.dropdown-menu .dropdown-item {
  cursor: pointer;
  font-size: 0.8125rem;
  font-weight: 400;
  padding: 0.25rem 1.5rem;
}

.dropdown-menu .dropdown-item {
  font-size: 0.8125rem;
  font-weight: 400;
  padding: 0.25rem 0.5rem !important;
}
input#checkbox {
  cursor: pointer;
}
span.remove-icon {
  margin-top: 40px !important;
  position: absolute;
  font-size: 18px;
}
.coupon-popup .modal-dialog.modal-lg {
  max-width: 825px !important;
  width: auto;
  border-radius: none;
  background: #fff;
}

span.cm_col_tickets {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: 400px;
}
.cm_col_show::after {
  content: '  ,  ';
}

.cm_col_show:last-child::after {
  content: '';
}

.popup-youtube {
  position: relative !important;
}

.modal-dialog.modal-md.modal-dialog-centered {
  max-width: 840px !important;
}

.modal-content .modal-dialog-centered {
  outline: none !important;
  background: transparent;
  border-radius: 0;
  border: none;
}

.modal .modal-dialog-centered .modal-content {
  background-color: transparent;
  border: none !important;
}

.modal .modal-dialog-centered .modal-content .modal-header .close span {
  font-size: 28px;
  font-weight: 400;
  color: #fff !important;
}
iframe {
  border: none;
}
.react-images__dialog.css-18xmup8.css-1ycyyax {
  width: 100%;
  box-sizing: border-box;
  height: 640px;
  width: 570px;
}

.event-price-container {
  height: 76px;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fade-in {
  -webkit-animation: fadeIn 0.5s ease-in;
          animation: fadeIn 0.5s ease-in;
}

.select-limit.ant-select-single.ant-select-show-arrow
  .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  color: #8f8c8b !important;
  font-size: 13px;
  padding-top: 5px;
  opacity: 1;
}

.placeholder {
  color: #8f8c8b !important;
  font-size: 13px;
  padding-top: 4px;
  opacity: 1;
}

.ql-container {
  box-sizing: border-box;
  font-family: ChivoLight !important;
  font-size: 13px;
  height: 100%;
  margin: 0px;
  position: relative;
}

.quill > .ql-container > .ql-editor.ql-blank::before {
  /* font-family: ChivoLight !important; */
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.btn-sm.dropdown button.dropdown-toggle.btn.btn-primary {
  padding: 0.40625rem 0.8125rem;
  font-size: 0.71875rem;
  outline-color: none !important;
  font-size: 0.71875rem;
  cursor: pointer;
  line-height: 1.5;
  border-radius: 0;
}

.btn-primary:not(:disabled):not(.disabled):active,
.wizard > .actions a:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.wizard > .actions a:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle,
.wizard > .actions .show > a.dropdown-toggle {
  color: #ffffff;
  background-color: #14181d;
  border-color: #14181d !important;
}
img.widget-8-img {
  border: 1px solid #8f8c8b;
}

ul.ant-dropdown-menu.ant-dropdown-menu-light.ant-dropdown-menu-root.ant-dropdown-menu-vertical {
  margin-top: 0;
  font-size: 0.875rem !important;
  box-shadow: 0px 0px 40px 0px rgba(79, 61, 101, 0.15) !important;
  border-radius: 0.25rem !important;
  background-color: #ffffff !important;
}

.ant-dropdown.ant-dropdown-placement-bottomLeft {
  margin-top: 0;
  font-size: 0.875rem !important;
  box-shadow: 0px 0px 40px 0px rgba(79, 61, 101, 0.15) !important;
  border-radius: 0.25rem !important;
  background-color: #ffffff !important;
}
li.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child {
  color: #8d8a89;
  display: block;
  padding: 5px 30px;
  font-size: 13px !important;
}
li.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child:hover {
  color: #ef3e27 !important;
  display: block;
}

.special-label {
  display: none !important;
}

.event-owl.owl-carousel .event-card {
  background-color: #262626;
  text-align: left !important;
}

.event-owl.owl-carousel .event-card .event-date {
  position: absolute;
  font-size: 1rem !important;
  font-family: chivoBold !important;
  background-color: #fff;
  font-weight: 500 !important;
  width: 70px;
  height: 70px;
  color: #000;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  top: 0;
  left: 0;
}

a.link-home {
  color: #8d8a89;
}

.select-Quantity .ant-select-selector {
  background-color: #ef3e27 !important;
}

.select-Quantity span.ant-select-arrow {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: absolute;
  left: 7px;
  font-weight: 900 !important;
  width: 10px;
  color: #fff;
}

.ant-select-dropdown.ant-select-dropdown-placement-bottomLeft
  ::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.ant-select-dropdown.ant-select-dropdown-placement-bottomLeft
  ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.ant-select-dropdown.ant-select-dropdown-placement-bottomLeft
  ::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}
.content {
  transition: max-height 0.3s ease-out;
}

.ant-collapse-header {
  color: #fff !important;
  font-size: 0.8125rem !important;
  vertical-align: middle !important;
  text-transform: uppercase !important;
  font-weight: 700 !important;
}

.ant-collapse-ghost {
  background-color: transparent;
  border: 0;
  padding-left: 20px;
}
.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content {
  background-color: transparent;
  padding-left: 35px;
  border-top: 0;
}
.ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box {
  padding-top: 0px !important;
  padding-bottom: 12px;
}

.ant-collapse-icon-position-right
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  right: 12% !important;
  left: auto;
}
span.ant-input-password.ant-input-password-large.ant-input-affix-wrapper.ant-input-affix-wrapper-lg {
  outline: none;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #ef3e27;
  border-right-width: 1px !important;
  outline: 0 !important;
  box-shadow: none !important;
}

.ant-input-affix-wrapper:hover {
  border-color: #ef3e27;
  border-right-width: 1px !important;
}

.event .event-banner {
  position: relative;
  margin-top: 2rem !important;
  height: 530px;
  z-index: 22;
  overflow: hidden;
  background-size: 100% !important;
}

.event-title {
  opacity: 1;
  z-index: 999;
  color: #fff;
  text-align: center;
  padding-top: 25px;
  padding-bottom: 20px;
  position: relative;
}
.event-title h2.text-uppercase.font-weight-bold.mb-0 {
  font-size: 2rem;
  margin-top: 16px !important;
  color: #fff;
}

.event-title {
  opacity: 1;
  z-index: 999;
  color: #000;
  text-align: center;
  margin-top: 43px;
  position: relative;
}

.widget-8 .widget-8-desc {
  width: 438px;
  display: flex;
  align-items: center;
}

.promoCode label {
  display: block;
  width: 100%;
  font-style: italic;
  font-size: 1.15em;
  margin-bottom: 0.5em;
  letter-spacing: -0.025em;
}
.promoCode input {
  width: 70%;
  float: left;
  border-radius: 0 !important;
}

.promoCode a.btnApply, button.btnApply {
  float: left;
  width: 30%;
  padding: 0.85em 3px !important;
  border-radius: 0 1.5em 1.5em 0;
  text-align: center;
  color: #fff;
  border: 1px solid #242a34 !important;
  background: #242a34 !important;
}
.row.promoCode.mt-5 {
  margin-top: 5rem !important;
}

.promoCode a.btnApply:hover, button.btnApply:hover {
  border: 1px solid #ef3e27 !important;
  background: #ef3e27 !important;
  color: #fff;
}

.btnApply:after {
  content: '\276f';
  padding: 0.5em;
  position: relative;
  right: 0;
  transition: all 0.15s linear;
}

.btnApply:hover:after,
.btnApply:focus:after,
.btnApply:active:after {
  right: -13px;
}
.promoCode .btnApply {
  font-size: 0.9em;
  padding: 0.5em 2em;
}
.video-input input {
  width: 82%;
  float: left;
  border-radius: 0 !important;
}

.video-input .btn {
  float: left;
  width: 11%;
  text-align: center;
  color: #fff;
  border: 1px solid #242a34 !important;
  background: #242a34 !important;
}
.video-input .btn:hover {
  border: 1px solid #ef3e27 !important;
  background: #ef3e27 !important;
  color: #fff;
}
.video-input label {
  display: block;
  width: 100%;
  font-size: 0.8125rem;
  line-height: 1;
  font-weight: bolder;
  margin-bottom: 0.5rem;
  color: #3c4b5c;
}

iframe.ql-video {
  background: #000;
  border: none;
  width: 100% !important;
  height: 300px;
  /* max-width: 228px; */
  margin: auto;
  border: 1px solid rgb(255 255 255 / 32%);
  max-height: 100% !important;
  /* border-radius: 7px !important; */
}
iframe.qlh-video {
  width: 100% !important;
}

.text-gray-colour {
  color: #8d8a89;
}
.text-overflow {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
p.event-content.dashboar {
  margin: 0px;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.event-address-container.bg-white {
  background: #f4f4f4 !important;
}

.ant-collapse-header:hover {
  color: #ef3e27 !important;
  font-size: 0.8125rem !important;
  vertical-align: middle !important;
  text-transform: uppercase !important;
  font-weight: 700 !important;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  display: table-cell;
  background-color: #ef3e27 !important;
  color: #f0f8ff;
  border-radius: 0 !important;
  border: 3px solid #fff;
}
.DayPicker-Day {
  display: table-cell;
  padding: 0.4em !important;
  border-radius: 0 !important;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}
.DayPicker-Day:hover {
  display: table-cell;
  background-color: #ef3e2728 !important;
}
.bootstrap-timepicker-widget.time_pic_wrapper,
.DayPicker,
.DayPicker-wrapper,
.DayPicker-Months,
.DayPicker-Caption,
.DayPicker-Weekdays,
.DayPicker-Body,
.DayPicker-Week {
  outline: none !important;
}

.DayPicker-NavButton--prev {
  margin-right: 1.5em;
  outline: none !important;
}
.DayPicker-NavButton--next {
  outline: none !important;
}

p.text-muted {
  word-wrap: break-word;
}

.addeventatc {
  display: inherit !important;
  font-family: inherit !important;
  font-weight: 100 !important;
  background: transparent !important;
  border: 0 !important;
  padding: 0 !important;
  border-radius: 0 !important;
  font-size: 14px !important;
  padding-top: 12px !important;
  outline: none !important;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  background-image: url(https://www.addevent.com/gfx/icon-calendar-t5.png),
    url(https://www.addevent.com/gfx/icon-calendar-t1.svg),
    url(https://www.addevent.com/gfx/icon-apple-t5.svg),
    url(https://www.addevent.com/gfx/icon-facebook-t5.svg),
    url(https://www.addevent.com/gfx/icon-google-t5.svg),
    url(https://www.addevent.com/gfx/icon-office365-t5.svg),
    url(https://www.addevent.com/gfx/icon-outlook-t5.svg),
    url(https://www.addevent.com/gfx/icon-outlookcom-t5.svg),
    url(https://www.addevent.com/gfx/icon-yahoo-t5.svg);
  background-position: none !important;
}

div#addeventatc1,
div#addeventatc2 {
  box-shadow: none !important;
}

.addeventatc:hover {
  background-color: transparent !important;
  color: #ef3e27 !important;
  box-shadow: none !important;
  font-weight: 100 !important;
  font-size: 14px !important;
  text-decoration: none;
}

span.addeventatc_icon.atc_node.notranslate {
  display: none !important;
}

.addeventatc .start, .addeventatc .end, .addeventatc .timezone, .addeventatc .title, .addeventatc .description, .addeventatc .location, .addeventatc .organizer, .addeventatc .organizer_email, .addeventatc .facebook_event, .addeventatc .all_day_event, .addeventatc .date_format, .addeventatc .alarm_reminder, .addeventatc .recurring, .addeventatc .attendees, .addeventatc .calname, .addeventatc .uid, .addeventatc .sequence, .addeventatc .status, .addeventatc .method, .addeventatc .client, .addeventatc .transp {
  display: none!important;
}
/* .navbar .navbar-menu-container .navbar-nav .nav-item.nav-profile img {
  width: 35px;
  height: 35px;
  border-radius: 100%;
} */

img.user-img.topbar {
  width: 38px;
  height: 38px;
  border-radius: 100%;
}

.add-Calendar {
  box-shadow: none !important;
}

.order-filter.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  color: #8d8a89;
  border: 1px solid #8d8a89;
  font-size: 0.8125rem;
  line-height: 1.5;
  font-weight: 700;
  border-radius: 0 !important;
}
.order-filter.ant-select-single:not(.ant-select-customize-input):hover
  .ant-select-selector:hover {
  color: #ef3e27 !important;
  background-color: #ececec;
  border: 1px solid transparent !important;
}
.order-filter .ant-select-selector:hover .ant-select-selection-placeholder {
  color: #ef3e27 !important;
  font-size: 13px;
  padding-top: 5px;
  opacity: 1;
}

.select-limit.ant-select-single.ant-select-show-arrow
  .ant-select-selection-item {
  color: #8f8c8b !important;
  font-size: 13px;
  padding-top: 0px;
  opacity: 1;
}

.order-filter span.ant-select-arrow {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-weight: 900 !important;
  width: 10px;
  color: #8d8a89;
}

.order-filter span.ant-select-arrow:hover {
  color: #ef3e27 !important;
}

.privacy-terms-page h1 {
  font-size: 46px;
  text-align: center;
  color: rgb(44, 48, 52);
  margin: 0;
  padding: 0 0 6px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.privacy-terms-page h2 {
  font-size: 26px;
  line-height: 20px;
  text-align: left;
  color: rgb(44, 48, 52);
  margin: 0;
  padding: 20px 0 0 0;
}

.privacy-terms-page p {
  font-size: 14px;
  letter-spacing: 1px;
  color: #8d8a89;
  margin: 0;
  line-height: 30px !important;
}

.privacy-terms-page span {
  color: #242a34;
  font-weight: bold;
}

.term-hr {
  width: 63%;
  color: #ef3e27;
  border: none;
  border-top: 2px solid;
  margin: 1em auto;
  margin-left: 17px;
}
.privacy-terms-page {
  text-align: justify;
  text-justify: inter-word;
}
.website-link:hover {
  background-color: #ef3e2710;
  color: #242a34;
  text-decoration: none;
}

.modal .modal-dialog .modal-content .modal-body-popup {
  padding: 5rem 4rem;
  font-weight: 300;
  color: #3f3f3f;
  height: 500px;
}
.modal-body-popup img {
  max-width: 80% !important;
  max-height: 85% !important;
}
a.website-link-open {
  color: #fff;
  text-decoration: none;
}

a.website-link-open:hover {
  color: #fff;
  text-decoration: none;
}

.uplodeImgContainer-myfeed {
  width: 181px;
  position: relative;
  height: 191px;
  border-radius: 8px;
  border: 2px solid #e6e4e9;
  margin-right: 20px;
  display: inline-block;
}

.fb-btn {
  display: flex;
  width: 100%;
}
.reset-card {
  width: 30%;
  padding: 20px;
}

.page-msg h5,
.page-msg h4,
.page-msg h3,
.page-msg h2,
.page-msg h6,
.page-msg h1,
.page-msg p {
  display: block;
  -webkit-margin-before: 0.5em;
          margin-block-start: 0.5em;
  -webkit-margin-after: 1em;
          margin-block-end: 1em;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  line-height: 1.5;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  font-family: 'Chivo', sans-serif;
  letter-spacing: 0.3px;
  color: #404040;
}
/* ........................cart page add or remove button.......................... */

.CartItem-count {
  margin: 0px;
  padding: 9px 18px;
  border: 1px solid #ccc;
  background: #f4f4f4;
}
.CartItem-addOne,
.CartItem-removeOne {
  padding: 9px 15px;
  color: #ef3e27;
  border: 1px solid #ccc;
  background: #fff;
}
.CartItem-addOne {
  border-left: 0;
}
.CartItem-removeOne {
  border-right: 0;
}

/* ..............................................media.............................. */

@media (max-width: 991px) {
  .d-hide-img {
    display: none;
  }
}

@media (max-width: 1450px) {
  .owl-carousel .owl-item img {
    height: 130px;
  }
  .success-content {
    margin: 0px 250px;
    min-height: calc(100vh - 80px - 320px);
  }
}
@media (max-width: 995px) {
  .success-content {
    margin: 0px 162px;
    min-height: calc(100vh - 80px - 320px);
  }
}
@media (max-width: 795px) {
  .success-content {
    margin: 0px 62px;
    min-height: calc(100vh - 80px - 320px);
  }
}

@media (min-width: 1160px) and (max-width: 1320px) {
  .content-event {
    margin: 0 80px;
    min-height: calc(100vh - 80px - 320px);
  }
}
@media (min-width: 992px) and (max-width: 1382px) {
  .promoCode a.btnApply {
    float: left;
    width: 30%;
    padding: 0.85em 0;
    border-radius: 0 1.5em 1.5em 0;
    text-align: center;
    color: #fff;
    border: 1px solid #242a34;
    background: #242a34;
  }
  .promoCode input {
    width: 70%;
    float: left;
    border-radius: 0;
  }
}

@media (min-width: 1320px) and (max-width: 1420px) {
  .content-event {
    margin: 0 162px;
    min-height: calc(100vh - 80px - 320px);
  }
}
@media (max-width: 575px) {
  .event-title h2.text-uppercase.font-weight-bold.mb-0 {
    font-size: 1.25rem;
  }
}
@media (min-width: 1390px) and (max-width: 1497px) {
  .event-list .event-item .event-schedule .event-day {
    font-size: 50px;
    font-weight: 100;
    font-family: chivoLight !important;
  }
  .event-list .event-item .event-schedule .event-month-time {
    font-weight: 200;
    font-size: 20px;
    display: flex;
    line-height: 118%;
    flex-direction: column;
    justify-content: center;
  }
}
@media (min-width: 1200px) {
  .container-fluid {
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (max-width: 1600px) {
  .reset-card {
    width: 50%;
    padding: 20px;
  }
}
@media (max-width: 700px) {
  reset-card {
    width: 90%;
    padding: 20px;
  }
}
@media only screen and (max-width: 1025px) {
  .event-address-container {
    padding: 0 100px;
  }
}
@media (max-width: 575px) {
  .user-login.login-v2 .login-left-section {
    padding: 1rem;
    min-height: 190px !important;
  }
}

/* Extra small devices Portrait */
@media (max-width: 575.98px) {
  .card-header-block {
    display: block !important;
  }
  body.event-bdy {
    background: none;
  }
  .overlay-bg {
    display: none;
  }
  .event .event-banner .event-title {
    margin: 0 2rem;
  }
  .event .event-banner {
    margin: 0;
    position: absolute;
    left: 0;
    z-index: 1;
  }
  .event .event-info {
    margin-left: 0;
  }
  .event-address-container {
    padding: 0;
  }

  .mb-cart-info {
    padding: 1.25rem;
  }
  .mb-cart-info .cart .count {
    position: absolute;
    top: 12px;
    left: 35px;
  }
  .success-content,
  .content {
    margin: 0 1.25rem;
  }
  .footer {
    padding: 0 4.25rem;
  }
  .banner-front {
    padding: 1rem;
    text-align: center;
  }
  .banner-front form {
    width: 90%;
  }
  .navbar {
    padding: 0 0 0 0rem;
    height: 60px;
  }
}
.event-owl.owl-carousel .owl-item img {
  /* height: 100%; */
  width: 100%;
  object-fit: fill;
}
.owl-carousel.owl-banner .owl-item img {
  display: block;
  width: 100%;
  height: auto;
  max-width: 60%;
  right: 0;
  left: auto;
  object-fit: cover;
}
.owl-carousel.owl-theme.owl-banner.owl-loaded.owl-drag {
  position: relative;
}

.owl-carousel.owl-theme.owl-banner.owl-loaded.owl-drag
  .owl-nav.disabled
  + .owl-dots {
  margin-top: 10px;
  position: absolute;
  right: 10%;
  bottom: 6%;
  color: #fff;
}
.owl-carousel.owl-theme.owl-banner.owl-loaded.owl-drag
  .owl-dots
  .owl-dot.active
  span,
.owl-carousel.owl-theme.owl-banner.owl-loaded.owl-drag
  .owl-dots
  .owl-dot:hover
  span {
  background: #ef3e27;
}
.overlay-bg-home {
  position: absolute;
  width: 100%;
  background-image: linear-gradient(to right, #000 39%, transparent 78%);
  /* height: 630px; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.157);
  cursor: pointer;
}
@media (max-width: 700px) {
  .overlay-bg-home {
    position: absolute;
    width: 100%;
    background-image: linear-gradient(to right, #000 39%, transparent 78%);
    /* height: 630px; */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.157);
    cursor: pointer;
  }
}
.banner-item {
  width: 100%;
  height: 630px;
}
.overlay-bg-home2 {
  position: absolute;
  width: 100%;
  height: 630px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}
.btn-event-info {
  letter-spacing: 1.2px;
  padding: 10px 18px;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
}
.d-block-banner {
  width: 470px;
}

.date-dash .anticon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  color: #ef3e27;
  font-size: 25px;
  text-transform: none;
  vertical-align: -1px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.about-feature-event {
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.event-card-content {
  min-height: 70px;
}
@media only screen and (max-width: 1025px) {
  .content {
    margin: 0px 0px !important;
  }
}

.widget-8-img {
  width: 80px;
  height: 41px;
  border: 1px solid #8f8c8b;
}
.widget-8 .widget-8-desc {
  width: 100% !important;
  display: flex;
}

img.ticket-page-qr {
  max-width: 100%;
}

span.website-link {
  color: #ef3e27 !important;
}
.widget-13-left-section.profile {
  background: #f4f4f4;
}
.mt-05 {
  margin-top: 1.5rem !important;
}

.view_page svg {
  fill: #9e9e9e;
  width: 18px;
  color: #9e9e9e !important;
  cursor: pointer;
}
iframe.ql-video.colsize-6 {
  background: #000;
  border: none;
  width: 100% !important;
  height: 250px !important;

  margin: auto;
  border: 1px solid rgb(255 255 255 / 32%);
  max-height: 100% !important;
  /* border-radius: 7px !important; */
}

img.placeholder-gallery {
  max-width: 200px;
  max-width: 100%;
  max-height: 170px;
  width: auto;
  margin-top: 26px;
}
.btn-down-arrow:after {
  content: '';
  position: absolute;
  height: 9.2px;
  width: 9.2px;
  border: none;
  right: 11px;
  border-left: 1.9px solid #8d8a89;
  border-bottom: 1.9px solid #8d8a89;
  top: 12px;
  -webkit-transform: scale(0.75, 1) rotate(-45deg);
          transform: scale(0.75, 1) rotate(-45deg);
}
.btn-down-arrow {
  padding: 0.53125rem 2.5rem 0.53125rem 0.8125rem;
}
span.activeAccountsetting {
  color: #ef3e27;
}
.ant-collapse-header .activeAccountsetting:hover {
  color: #ef3e27 !important;
  /* font-size: 0.8125rem !important; */
  /* vertical-align: middle !important; */
  text-transform: uppercase !important;
  font-weight: 700 !important;
}
.ant-select.order-filter.ant-select-single.ant-select-show-arrow.ant-select-disabled.ant-select-show-search:hover {
  background: rgb(236 236 236);
}

.ant-select-disabled.order-filter span.ant-select-arrow {
  display: block !important;
}

.ant-select-disabled.order-filter.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  cursor: not-allowed;
  color: #8d8a89 !important;
}
li.nav-header-li {
  padding-left: 20px !important;
  color: rgba(210, 213, 228, 0.5) !important;
  margin-bottom: 22px;
  list-style: none;
}
.social-anchor span {
  display: flex;
  align-items: center;
  min-width: 36px;
  justify-content: center;
  border: 1px solid #e6e4e9 !important;
}
.social-anchor a {
  color: inherit !important;
  text-decoration: none;
  background-color: transparent;
}
.related-events .owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  text-align: -webkit-center;
  -webkit-transform: translate3d(0px, 0px, 0px);
}
.mxw-100 {
  max-width: 100%;
  max-height: 100%;
}
.content.content-event.event.event-auto-hg.auto-height {
  overflow: inherit !important;
}
.social-icon-btn {
  background-color: transparent;
  border: none;
  padding: 0px;
  font: inherit;
  color: inherit;
  cursor: pointer;
}

.social-icon-btn a:hover {
  text-decoration: none;
  color: inherit;
}
.preview-size {
  width: 100%;
  height: 50px;
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 10px;
}

.preview-size img {
  height: 100%;
  display: block;
  margin-right: 10px;
  width: 80px;
  object-fit: cover;
  border-radius: 3px;
}

.preview-size span {
  display: inline-block;
  font-size: 12px;
}

.preview-size span.cross-dropzone-icon {
  margin-left: auto;
  margin-right: 10px;
  color: #cc2105;
}
.color-orng {
  color: #ef3e27;
}
.overlaylayer {
  position: absolute;
  height: 100%;
  width: 100%;
  background: transparent;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 24px;
}
.upload-img-list {
  position: relative;
  background: #f7f7f7;
  margin: auto;
  text-align: center;
  cursor: pointer;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.upload-img-list img {
  height: 100%;
  width: auto;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.event-img-gallery.row {
  margin-right: -2px;
  margin-left: -2px;
}
.event-img-gallery.row > [class*='col-'] {
  padding-left: 2px;
  padding-right: 2px;
  margin-bottom: 4px;
}

svg.svg-inline--fa.fa-plus.fa-w-12.fa-2x,
svg.svg-inline--fa.fa-minus.fa-w-12.fa-2x {
  width: 10px !important;
}

.navbar-mb-container .secondary .nav-wrapper .nav .nav-item .nav-link.active {
  background-color: #ef3e27;
  color: #fff !important;
}

.banner-main {
  position: relative;
  z-index: 1;
  margin-top: -43px;
}

.event-auto-hg {
  min-height: 0 !important;
  min-height: initial !important;
  /* max-height: 530px; */
  overflow: hidden;
}
img.event-banner-img {
  width: 100%;
  max-height: 530px;
  /* height: 100%; */
}
.banner-main:after {
  content: '';
  position: absolute;
  height: 85%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  z-index: -1;
}
.banner-main:before {
  content: '';
  position: absolute;
  height: 85%;
  width: 100%;
  background: url(/static/media/event-list.c8351b5a.jpg) no-repeat;
  background-size: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}
.user-login.login-v2.reset-card .card {
  box-shadow: 0 7px 34px 1px #c1bed1;
  -webkit-box-shadow: 0 7px 34px 1px #c1bed1;
  -moz-box-shadow: 0 7px 34px 1px #c1bed1;
  -ms-box-shadow: 0 7px 34px 1px #c1bed1;
  width: 100%;
  max-width: 536px;
}
.table-overflow .table-responsive {
  min-width: 650px;
}
a.btn.btn-sm.btn-dark {
  cursor: pointer;
}
.table-overflow {
  overflow: auto;
}
/* .gallery-view {
  max-width: 330px;
} */
.viewmore-list {
  position: relative;
}
.more-list {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgb(0 0 0 / 44%);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  color: #fff;
  font-size: 24px;
}

@media (min-width: 992px) {
  .navbar-mb-more.show {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  .order-select {
    display: block !important;
  }
  /* .ant-picker-panel-container {
    max-width: 280px;
  } */
}

.select-rang {
  align-items: baseline;
}

/* .ant-picker-panels {
  overflow: auto !important;
  width: 100% !important;
} */

/* @media (max-width: 400px){
  .ant-picker-dropdown-placement-bottomLeft {
    max-width: 280px;
  }
} */
.card-new-header {
  display: block !important;
}
/* .at-expanding-share-button {
  position: absolute !important;
  left: 12% !important;
  bottom: -11% !important;
} */
.addthis_toolbox {
  display: inline-block;
}
.addthis_toolbox a {
  display: inline-block;
  vertical-align: middle;
}
.addthis_toolbox a svg {
  width: 23px !important;
}
.at-expanding-share-button-toggle-bg {
  border-radius: 50%;
  box-shadow: 0px 0px 1px 1px #0000001a;
  -webkit-animation: pulse-animation 1s infinite !important;
          animation: pulse-animation 1s infinite !important;
  margin-left: 20px !important;
  margin-bottom: 20px !important;
}
.at-expanding-share-button-services-list {
  margin-left: 25px !important;
  margin-bottom: 10px !important;
}
@-webkit-keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(190, 0, 0, 0.5);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}
@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(190, 0, 0, 0.5);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}
@media (max-width: 670px) {
  .ant-picker-panels {
    display: block !important;
  }

  .ant-picker-panel {
    width: 100%;
  }

  .ant-picker-date-panel {
    width: 100%;
  }

  table.ant-picker-content {
    width: 100% !important;
  }

  .ant-picker-dropdown.ant-picker-dropdown-range.ant-picker-dropdown-placement-bottomLeft {
    left: 55px;
    right: 55px;
  }
}

@media only screen and (max-width: 1025px) {
  .content {
    margin: 0px 100px !important;
  }
}
@media only screen and (max-width: 1025px) {
  .content-create-form {
    margin: 0px 20px !important;
  }
}

.share {
  right: 20px;
  bottom: 20px;
}
@media (min-width: 768px) {
  .share {
    right: unset;
    bottom: unset;
  }
}
.share__toggle:checked ~ .share__button {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.share__toggle:checked ~ .share__icon--facebook {
  -webkit-transform: translateX(55px) rotate(0);
          transform: translateX(55px) rotate(0);
}
.share__toggle:checked ~ .share__icon--twitter {
  -webkit-transform: translateX(110px) rotate(0);
          transform: translateX(110px) rotate(0);
}
.share__toggle:checked ~ .share__icon--pinterest {
  -webkit-transform: translateX(165px) rotate(0);
          transform: translateX(165px) rotate(0);
}
.share__toggle:checked ~ .share__icon--linkedin {
  -webkit-transform: translateX(220px) rotate(0);
          transform: translateX(220px) rotate(0);
}
.share__button {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 53px;
  height: 52px;
  color: white;
  background-color: #ef3e27;
  border-radius: 50px;

  cursor: pointer;
  transition: 0.3s ease;
  margin-left: 1px;
  margin-top: 1px;
}
.share__icon {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  -webkit-transform: translateX(0) rotate(180deg);
          transform: translateX(0) rotate(180deg);
  cursor: pointer;
  transition: 0.3s ease-in-out;
  margin-left: 2px;
  margin-top: 2px;
}
.share__icon--facebook {
  background-color: #3b5998;
}
.share__icon--twitter {
  background-color: #1da1f2;
}
.share__icon--pinterest {
  background-color: #bd081c;
}
.share__icon--linkedin {
  background-color: #0077b5;
}

a.downloadIcon {
  color: #fff;
  text-decoration: none;
}

.galler-main {
  height: 290px !important;
}

.galler-main {
  margin: auto !important;
  text-align: center !important ;
  background: #fff !important;
}

.gallery-view img {
  max-width: 100% !important;
}

.galler-main {
  margin: auto !important;

  max-height: 250px;
  text-align: center !important;
  background: #fff !important;
}

@media (max-width: 1650px) {
  .upload-img-list {
    height: 210px !important;
  }

  .galler-main {
    max-height: 210px !important;
  }
  .galler-list img {
    object-fit: cover;
    min-height: 210px !important;
    height: 100% !important;
    width: 100% !important;
  }
  iframe.ql-video.colsize-6 {
    background: #000;
    border: none;
    width: 100% !important;
    height: 210px !important;

    margin: auto;
    border: 1px solid rgb(255 255 255 / 32%);
    max-height: 100% !important;
    /* border-radius: 7px !important; */
  }
}
@media (max-width: 1200px) {
  iframe.ql-video.colsize-6 {
    background: #000;
    border: none;
    width: 100% !important;
    height: 200px !important;

    margin: auto;
    border: 1px solid rgb(255 255 255 / 32%);
    max-height: 100% !important;
    /* border-radius: 7px !important; */
  }

  .galler-main {
    margin: auto !important;
    max-height: 200px !important;
    text-align: center !important;
    background: #fff !important;
  }
  .upload-img-list {
    height: 180px !important;
  }
  .content-event {
    margin: 0 65px;
    min-height: calc(100vh - 80px - 320px);
  }
}
@media (max-width: 1020px) {
  .update-feed-image {
    height: 219px !important;
  }

  .upload-img-list {
    height: 138px !important;
  }
}

@media (max-width: 985px) {
  .upload-img-list {
    height: 238px !important;
  }
}
@media (max-width: 885px) {
  .upload-img-list {
    height: 178px !important;
  }
}
@media (max-width: 768px) {
  .upload-img-list {
    height: 180px !important;
  }
}
@media (max-width: 639px) {
  .upload-img-list {
    height: 139px !important;
  }
}
@media (max-width: 578px) {
  .upload-img-list {
    height: 129px !important;
  }
}
@media screen and (max-width: 991px) {
  .event-header-home {
    padding-left: 20px;
  }
}
@media (max-width: 500px) {
 
  .update-feed-image {
    height: 200px !important;
  }
}
@media (max-width: 700px) {
  .mb-view .d-block {
    padding: 4px 20px 10px 0px !important;
  }
  .mb-view .event-header-home {
    flex-direction: row !important;
    padding-bottom: 12px !important;
    padding-top: 0px !important;
  }
  .mb-view .about-feature-event {
    max-width: 100% !important;
  }
  .mb-view .font-weight-bold {
    max-width: 100% !important;
  }

  .owl-stage-outer.owl-height {
    height: auto !important;
  }
  .mb-view .overlay-bg-home {
    
    background-image: linear-gradient(0deg, #000 57%, #000000cf 63%,#000000c5 46%, #00000000 40%);
  }
  .bg-change{
    background:black !important;
    /* height: 270px; */
  
    display: -webkit-box;
  }
  .bg-change {
    /* height: 270px; */
}
  p.text-white.font-size-16.about-feature-event {
    max-width: 75% !important;
  }
  h1.text-uppercase.text-white.font-weight-bold {
    max-width: 100% !important;
  }

  .content-home {
    margin: 0px !important;
  }

  .content-home-inner img {
    position: inherit !important;
    left: 0;
    max-width: 100% !important;
  }
  .owl-carousel.owl-banner .owl-item img {
    max-width: 100% !important;
  }
  .update-feed-image {
    height: 200px !important;
  }
}
@media (max-width: 420px) {
  .update-feed-image {
    height: 168px !important;
  }
  .upload-img-list {
    height: 106px !important;
  }
}
.cm-select-box-2 .ant-select-selector {
  font-size: 13px !important;
  border-width: 0 0 1px 0 !important;
  background-color: transparent !important;
}

.ant-select:not(.cm-select-box-2 .ant-select-disabled):hover
  .cm-select-box-2
  .ant-select-selector {
  border-width: 0 0 1px 0 !important;
}
.ant-select.ant-select-borderless.cm-select-box-2.ant-select-single.ant-select-show-arrow:hover {
  border: none !important;
}

.ant-select.ant-select-borderless.cm-select-box-2.ant-select-single.ant-select-show-arrow {
  outline: #e6e4e9 !important;
  border-bottom: 1px solid #e6e4e9 !important;
}
.ant-select.ant-select-borderless.cm-select-box-2.ant-select-single.ant-select-show-arrow:hover {
  outline: #e6e4e9 !important;
  border-bottom: 1px solid #ef3e27 !important;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #e6e4e9 !important;
  border-right-width: 1px !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: #e6e4e9 !important;
  border-right-width: 1px !important;
}

.csm-check .string-check-label {
  display: inline-flex !important;
}

p.text-white.font-size-16.about-feature-event {
  max-width: 100%;
}
.home-page p.about-feature-event.default-event {
  max-width: 278px !important;
  -webkit-line-clamp:initial;
}
@media screen and (max-width:330px){
  .home-page p.about-feature-event.default-event {
    max-width: 220px !important;
  }
}


.event-question-form {
  display: flex;
  margin: 116px auto;
  flex-direction: column;
}
.event-question-form form {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 438px;
  padding: 20px 36px;
  width: 100%;
  box-shadow: 0 7px 34px 1px #c1bed1;
}

.event-question-form h4 {
  text-align: center;
  margin-bottom: 20px;
}
.event-question-form input {
  width: 100%;
  margin-bottom: 20px;
  height: 45px;
  border-radius: 20px;
  border: 1px solid #ced4da;
  padding: 0.53125rem 0.8125rem;
}
.event-question-form textarea {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 20px;
  border: 1px solid #ced4da;
  min-height: 213px;
  padding: 0.53125rem 0.8125rem;
}
.event-question-form select {
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid #ced4da;
  padding: 0.53125rem 0.8125rem;
  color: rgba(0, 0, 0,0.65);
}
.event-bdy .content-event a.event-info {
  text-decoration: none;
  color:#404040;
}

.content-event .event-detail-wrapper a.btn {
  padding-left:52px !important;
}

.content-event .event-detail-wrapper a.btn .fa-envelope {
  margin: 0px !important;
}

.hidden {
  display:none !important;
}

.card.quick-stats {
  padding:20px;
  
}
.card.quick-stats .quick-stats-title {
  display:flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}
.card.quick-stats .quick-stats-title h3 {
  display:flex;
  justify-content: center;
  align-items: center;
}
.card.quick-stats .left-arrow,.card.quick-stats .right-arrow {
  padding: 8px 12px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
}
.card.quick-stats .left-arrow:active,.card.quick-stats .right-arrow:active {
  box-shadow: 1px 1px 4px 0px rgba(0,0,0,0.1);
}
.quick-stats-body {
  padding-top:20px;
  display:flex;
  justify-content: flex-start;
}
.quick-stats-body .image {
  display:flex;
  justify-content:center;
  width:50%;
  margin-right:20px;
  height:auto;
}
.quick-stats-body .stats {
  display:flex;
  flex-direction: column;
  width:50%;
}
.quick-stats-body .image img {
  width: 100% !important;
  height: auto !important;
  margin-bottom: 20px;
  object-fit: cover;

}
.quick-stats-body ul li,.quick-stats-body ul {
  margin-left:0px;
  padding-left:0px;
  list-style: none;
}
.quick-stats-body ul {
  display:flex;
  flex-wrap:wrap;
}

.quick-stats-body ul li {
  margin-bottom:6px;
  padding-right:10px;
  display:flex;
  justify-content: space-between;
  width:calc( 100% );
}
.quick-stats-body ul li div {
  display:flex;
  justify-content: space-between;
}
.quick-stats-section h3 {
  display:flex;
  justify-content:space-between;
}
.stat-section-export {
  font-size:16px;
}
.stat-scroll-box {
  display: flex;
  flex-wrap: wrap;
  max-height: 200px;
  overflow-y: auto;
}
.datepicker {
  width: 260px;
  padding: 10px;
}
.delete-cart-item {
  text-align: center;
  justify-content: center;
 
}
.ant-picker-input > input[disabled] {
  background: transparent;
  color: rgba(0, 0, 0, 0.85) !important;
}
.ant-picker.ant-picker-disabled {
  background: #f5f5f5;
  
  border: 1px solid #e6e4e9 !important;
  cursor: not-allowed;
}

.col-4.mt-1.scroll-item {
  height: 160px !important;
  
}
.videoPoster:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
  margin: -40px 0 0 -40px;
  background: #fff;
  cursor: pointer;
  border-radius: 100%;
  transition: border-color 300ms;
}

.videoPoster:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  width: 0;
  justify-content: left;
  height: 0;
  cursor: pointer;
  margin: -12px 0 0 -6px;
  border-left: 15px solid #ef3e27;
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  transition: border-color 300ms;
}

.owl-carousel .owl-stage,
.owl-carousel.owl-drag .owl-item {
  touch-action: auto !important;
}
.update-feed-image {
  position: relative;
  background: #f7f7f7;
  margin: auto;
  text-align: center;
  cursor: pointer;
  height: 280px;
  display: flex;
  align-items: center;
  justify-content: center;

}
.cm-list-col {
  width: 20%;
  padding: 5px;
}

.col-sm-4.col-md-4.col-lg-3.col-xl-2.mt-1.scroll-item {
  max-height: 202px;
}
.row.mb-4.scroll.main-update {
  max-height: 100% !important;
}
/* .gallery-view img {
  width: 100%;
} */
img.placeholder-gallery {
  max-width: 200px;
}
.galler-main {
  height: 300px !important;
}
.galler-list img {
  max-height: 150px;
}
.galler-main img {
  max-height: 300px !important;
}
html {
  position: relative;
  min-height: 100%;
  font-family: 'Chivo', sans-serif;
}
.css-1vmnjpn-skeletonStyles-Skeleton {
  background-color: #eee;
  background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 0px !important;
  display: inline-block;
  line-height: 1;
  width: 100%;
  -webkit-animation: animation-16jpnkj 1.2s ease-in-out infinite;
  animation: animation-16jpnkj 1.2s ease-in-out infinite;
}
.css-1ykbi8a-SkeletonTheme .react-loading-skeleton {
  border-radius: 0px !important;
  background-color: #cccccc9c !important;
  background-image: linear-gradient(
    90deg,
    #cccccc00,
    #dddddd00,
    #cccccc08
  ) !important;
}
.invoice.my-ticket .table-responsive {
  overflow-x: hidden !important;
}
.status_button.ant-select-single.ant-select-show-arrow
  .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  text-align: left !important;
  top: 0px !important;
}
.badge-brand {
  background-color: #ef3e27;
  color: #fff;
}
.badge-count {
  padding: 7px 7px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
h1.text-white.font-weight-bold {
  font-size: 43px;
  font-weight: 800;
  letter-spacing: 2.9px;
  font-family: chivoBold;
  margin-bottom: 1.5rem;
  font-weight: 500 !important;
  line-height: 1.2;
}
.home-form .form-control:focus {
  box-shadow: none;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-color: #ef3e27;
  background-color: transparent;
}
h2.react-reveal {
  line-height: 1.2;
}
.event-owl.owl-carousel .owl-item img {
  /* height: auto; */
  width: 100%;
  object-fit: fill;
}
.datepicker.datepicker-orient-top {
  margin-top: 8px;
}

.datepicker table {
  width: 100%;
}

.datepicker td,
.datepicker th {
  font-weight: 300;
  width: 35px;
  height: 35px;
  border-radius: 4px;
}

.datepicker thead th {
  color: #394044;
}

.datepicker thead th.prev,
.datepicker thead th.datepicker-switch,
.datepicker thead th.next {
  font-weight: 500;
  color: #727686;
}

.datepicker thead th.prev i,
.datepicker thead th.datepicker-switch i,
.datepicker thead th.next i {
  font-size: 1.2rem;
  color: #727686;
}

.datepicker thead th.prev i:before,
.datepicker thead th.datepicker-switch i:before,
.datepicker thead th.next i:before {
  line-height: 0;
  vertical-align: middle;
}

.datepicker thead th.prev:hover,
.datepicker thead th.datepicker-switch:hover,
.datepicker thead th.next:hover {
  background: #e9f8ff !important;
}

.datepicker thead th.dow {
  color: #394044;
  font-weight: 500;
}

.datepicker tbody tr > td.highlighted {
  background-color: #ffdcda;
}

.datepicker tbody tr > td.day {
  color: #394044;
}

.datepicker tbody tr > td.day:hover {
  background: #ffdcda;
  color: #394044;
}

.datepicker tbody tr > td.day.old {
  color: #394044;
}

.datepicker tbody tr > td.day.new {
  color: #394044;
}

.datepicker tbody tr > td.day.selected,
.datepicker tbody tr > td.day.selected:hover,
.datepicker tbody tr > td.day.active,
.datepicker tbody tr > td.day.active:hover {
  background: #ef3e27;
  color: #ffffff;
}
.navbar .navbar-menu-container .navbar-nav .nav-item.nav-profile img {
  width: 30px;
  height: 30px;
  z-index: 99 !important;
  border-radius: 100%;
}
.css-yk16xz-control:active {
  border: 1px solid #e6e4e9 !important;
  box-shadow: none !important;
}
.css-2b097c-container {
  position: relative;
  box-shadow: none !important;
  box-sizing: border-box;
  border-radius: 0.25rem;
  border: 0 !important;
}
.css-yk16xz-control {
  display: block;
  width: 100%;
  box-shadow: none !important;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3f3f3f;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #e6e4e9 !important;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.css-yk16xz-control:active {
  box-shadow: none !important;
  border: 1px solid #e6e4e9 !important;
}
.css-yk16xz-control:hover,
.css-1pahdxg-control:hover {
  border-color: #ef3e27 !important;
}
.datepicker tbody tr > td.day.today {
  position: relative;
  background: rgba(239, 62, 39, 0.7) !important;
  color: #ffffff;
}
.css-1n7v3ny-option {
  font-weight: 100 !important;
  background-color: #ececec !important;
}

.datepicker tbody tr > td.day.today:before {
  content: '';
  display: inline-block;
  border: solid transparent;
  border-width: 0 0 7px 7px;
  border-bottom-color: #ffffff;
  border-top-color: kt-base-color(grey, 2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}

.datepicker tbody tr > td.day.range {
  background: kt-base-color(grey, 1);
}

.datepicker tbody tr > td span.year,
.datepicker tbody tr > td span.hour,
.datepicker tbody tr > td span.minute,
.datepicker tbody tr > td span.month {
  color: #394044;
}

.datepicker tbody tr > td span.year:hover,
.datepicker tbody tr > td span.hour:hover,
.datepicker tbody tr > td span.minute:hover,
.datepicker tbody tr > td span.month:hover {
  background: #e9f8ff;
}

.datepicker tbody tr > td span.year.focused,
.datepicker tbody tr > td span.year.focused:hover,
.datepicker tbody tr > td span.year.active:hover,
.datepicker tbody tr > td span.year.active.focused:hover,
.datepicker tbody tr > td span.year.active,
.datepicker tbody tr > td span.hour.focused,
.datepicker tbody tr > td span.hour.focused:hover,
.datepicker tbody tr > td span.hour.active:hover,
.datepicker tbody tr > td span.hour.active.focused:hover,
.datepicker tbody tr > td span.hour.active,
.datepicker tbody tr > td span.minute.focused,
.datepicker tbody tr > td span.minute.focused:hover,
.datepicker tbody tr > td span.minute.active:hover,
.datepicker tbody tr > td span.minute.active.focused:hover,
.datepicker tbody tr > td span.minute.active,
.datepicker tbody tr > td span.month.focused,
.datepicker tbody tr > td span.month.focused:hover,
.datepicker tbody tr > td span.month.active:hover,
.datepicker tbody tr > td span.month.active.focused:hover,
.datepicker tbody tr > td span.month.active {
  background: #22b9ff;
  color: #ffffff;
}

.datepicker tfoot tr > th.today,
.datepicker tfoot tr > th.clear {
  border-radius: 3px;
  font-weight: 500;
}

.datepicker tfoot tr > th.today:hover,
.datepicker tfoot tr > th.clear:hover {
  background: #e9f8ff;
}

.datepicker.datepicker-inline {
  border: 1px solid kt-base-color(grey, 2);
}

.input-daterange .input-group-addon {
  min-width: 44px;
}

.input-daterange input {
  border-radius: 0px;
  text-align: left;
}
.blog-bg {
  background: #fff;
}
/* .event-bdy {
  background: url('../../assets/images/EventPages/event-list.jpg') no-repeat;
  background-size: 100% 630px;
} */
.event-list-bdy {
  background: url(/static/media/event-list.c8351b5a.jpg) no-repeat;
  background-size: 100% 630px;
  background-color: #1c1c1c;
}
.content-home-inner .overlay-bg-home {
  z-index: 1;
}

.content-home-inner .event-header-home {
  z-index: 9;
}


.base-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
}


.navbar-home {
  display: block;
  padding: 0 0 0 50px;
  height: 80px;
  z-index: 12;
  position: relative;
  width: 100%;
  background-color: #fff;
}
.navbar-mb-menu-container {
  z-index: 12;
  position: relative;
  width: 100%;
  height: 80px;
}
.navbar-mb-more {
  position: absolute;
  top: -1000px;
  width: 280px;
  z-index: 99;
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}
.navbar-mb-more.show {
  top: 80px;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  right: 0;
}

.navbar-home .logo-container-home {
  position: absolute;
  z-index: 12;
  top: 0;
  width: 180px;
  height: 120px;
  background-color: #ef3e27;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar-home .logo-container-home img {
  position: relative;
  width: 80%;
}
.navbar-home .navbar-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  line-height: 80px;
}
.navbar-home .navbar-nav > .nav-item form {
  height: 80px;
}
.navbar-home .navbar-nav > .nav-item input {
  color: #808080;
  border: none;
  padding: 0;
  font-size: 14px;
  box-shadow: none;
}
.navbar-home .navbar-nav > .nav-item a {
  padding: 30px 30px;

  color: #808080;
  font-size: 14px;
}

.navbar-home .navbar-nav > .nav-item a:hover {
  background-color: #ef3e27;
  color: #fff;
  transition: all 0.2s;
}
.navbar-home .navbar-nav > .nav-item a.text-brand:hover {
  background-color: #ef3e27;
  color: #fff !important;
  transition: all 0.2s;
}
.navbar-home .navbar-nav .nav-item button {
  color: #434343;
}
.navbar-home .navbar-nav > .nav-item.nav-user {
  background-color: #ef3e27;
  color: #fff;
}
.navbar-home .navbar-nav > .nav-item.nav-user a {
  /* font-weight: 700; */
  font-family: 'Chivo', sans-serif;
}
.navbar-home .navbar-nav > .nav-item a.general {
  font-family: 'Chivo', sans-serif;
  color: #404040;
  padding: 0;
  margin: 0 30px;
  line-height: 78px;
}
.navbar-home .navbar-nav > .nav-item a.general:hover {
  background-color: #fff;
}
.navbar-home .navbar-nav > .nav-item a.general.active {
  background-color: #fff;
}
.navbar-home .navbar-nav > .nav-item a.general::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background-color: #ef3e27 !important;
  transition: width 0.3s;
}
.navbar-home .navbar-nav > .nav-item a.general:hover::after,
.navbar .navbar-nav > .nav-item a.general.active::after {
  width: 100%;
}
.banner-front {
  background-color: #000;
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.banner-front form {
  width: 500px;
}
.banner-front button {
  position: absolute;
  left: 0;
  padding: 0;
  bottom: 1rem;
}
.banner-front .form-control:focus {
  color: #808080;
}
.banner-front input {
  padding: 1rem 2rem;
  color: #808080;
}
.accordion .card-header {
  padding: 1.1rem 0;
}
.accordion .card-header .card-title {
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  color: #404040;
}
.accordion .card-header:after {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: '\f068';
  float: right;
  color: #ef3e27;
}
.accordion .card-header.collapsed:after {
  content: '\f067';
}
.success-content {
  margin: 0 400px;
  min-height: calc(100vh - 80px - 320px);
}
.success-content .content-title {
  font-weight: 700;
  font-size: 30px;
  text-transform: uppercase;
  padding: 40px 0;
  margin: 0;
  text-align: center;
  color: #404040;
}
.content-home {
  margin: 0 230px;
  /* min-height: calc(100vh - 80px - 320px); */
}
.content-home .content-title {
  font-weight: 700;
  font-size: 30px;
  text-transform: uppercase;
  padding: 40px 0;
  margin: 0;
  text-align: center;
  color: #404040;
}

.card {
  border-radius: 0px;
}
/* .mb-cart-info{
            display: flex;
            align-items: center;
            position: relative;
            height: 80px;
            -webkit-box-shadow: 0px -6px 34px -14px rgba(0,0,0,0.75);
            -moz-box-shadow: 0px -6px 34px -14px rgba(0,0,0,0.75);
            box-shadow: 0px -6px 34px -14px rgba(0,0,0,0.75);
        } */
.mb-cart-info .cart,
.mb-cart-info .checkout {
  padding: 1.25rem;
}
.mb-cart-info .cart .cart-toggle::after {
  content: none;
}
.mb-cart-info .cart .count {
  position: absolute;
  top: 12px;
  left: 35px;
}
.mb-cart-info .cart .cart-container {
  top: -375px !important;
  border-radius: 0;
  width: 300px;
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
.navbar-mb-container {
  transition: all 0.3s;
  min-height: 100vh;
  padding: 0;
  position: fixed;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: -300px;
  z-index: 999;
}
.navbar-mb-container .secondary {
  background: #fff;
  width: 250px;
}
.navbar-mb-container .secondary .nav-wrapper {
  height: 100vh;
  position: relative;
  overflow: auto;
  padding-top: 3em;
}
.navbar-mb-container .secondary .nav-wrapper .nav {
  overflow: hidden;
  flex-wrap: nowrap;
  flex-direction: column;
}
.navbar-mb-container .secondary .nav-wrapper .nav .nav-item {
  transition: all 0.3s;
}
.navbar-mb-container .secondary .nav-wrapper .nav .nav-item .nav-link {
  display: flex;
  white-space: nowrap;
  padding: 0.5625rem 0.875rem;
  color: #ef3e27;
  transition: all 0.3s;
  align-items: center;
  font-weight: 700;
  font-size: 1rem;
}
.navbar-mb-container .secondary .nav-wrapper .nav .nav-item .nav-link.active {
  background-color: #ef3e27;
  color: #fff;
}
.navbar-mb-container .secondary .nav-wrapper .nav .nav-item.collapse {
  z-index: 999;
}
.sidebar-overlay-home {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 99;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}
.sidebar-overlay-home.active {
  display: block;
  opacity: 0.1;
}
.footer {
  background-color: #000;
  padding: 0 230px;
  bottom: 0;
  position: relative;
  width: 100%;
}

.footer .footer-body {
  padding: 40px 0;
  border-bottom: 2px solid #1c1c1c;
}
.footer .footer-body ul li a:hover,
.footer .footer-privacy a:hover {
  color: #ef3e27 !important;
  transition: all 0.3s;
}
.footer .footer-body ul li a {
  color: #aeaeae;
  text-decoration: none;
  line-height: 1.7;
}

.footer .footer-privacy span {
  color: #aeaeae;
}
.footer .footer-privacy i {
  font-size: 14px;
}
.footer-support {
  background-color: #f4f4f4;
  padding: 3rem;
}
@media only screen and (max-width: 1225px) {
  .footer {
    background-color: #000;
    padding: 0px 25px;
    bottom: 0;
    position: relative;
    width: 100%;
  }}


@media only screen and (max-width: 1025px) {
  .footer {
    background-color: #000;
    padding: 0px 25px;
    bottom: 0;
    position: relative;
    width: 100%;
  }
 
  .navbar-home .logo-container-home {
    width: 160px;
    height: 100px;
  }
 
  .success-content,
  .content-home {
    margin: 0 100px;
  }
  .mb-cart-info {
    padding: 0 100px;
  }
  .mb-cart-info .cart {
    padding-left: 0;
  }
  .mb-cart-info .cart .count {
    position: absolute;
    top: 12px;
    left: 114px;
  }
  .navbar-mb-container.active {
    right: 0;
  }
  .footer {
    padding: 0 100px;
  }
}

/* Extra small devices Portrait */
@media (max-width: 575.98px) {
  a.brand-logo.login-page img {
    margin: 25px 0 !important;
  }
  .navbar-home {
    padding: 0 0 0 1.25rem;
    height: 60px;
  }
  .navbar-home .logo-container-home {
    width: 100px;
    height: 70px;
  }
  .navbar-home .navbar-nav {
    line-height: 60px;
  }
  .navbar-home .navbar-nav .nav-item {
    margin-right: 15px;
  }
  .navbar-home .navbar-nav .nav-item button {
    font-size: 15px;
  }
  .navbar-mb-menu-container {
    height: 60px;
  }
  .navbar-mb-more.show {
    top: 60px;
  }
  .mb-cart-info {
    padding: 1.25rem;
  }
  .mb-cart-info .cart .count {
    position: absolute;
    top: 12px;
    left: 35px;
  }
  .success-content,
  .content-home {
    margin: 0 1.25rem;
  }
  .footer {
    padding: 0 1.25rem;
  }
  .banner-front {
    padding: 1rem;
    text-align: center;
  }
  .banner-front form {
    width: 90%;
  }
}
p.text-white.font-size-16.about-feature-event {
  max-width: 45%;
}
.event-content {
  margin: 0 0 0 230px;
}
.fa-user-headset {
  width: 15px;
  height: 15px;
}
.select {
  background: transparent !important;
}
.event-header-home {
  min-height: auto;
  z-index: 0;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}
@font-face {
  font-family: chivofont;
  src: url(/static/media/Chivo-Bold.44169c43.ttf);
}
@font-face {
  font-family: chivoLight;
  src: url(/static/media/Chivo-Light.f2b6049b.ttf);
}
i.fa.fa-search.text-dark {
  font-size: 16px !important;
}

.event-form-home {
  position: absolute;
  right: 0 !important;
  bottom: 0px;
  border: 0;
  width: 400px;
}

.event-body .event-list:first-child {
  margin-top: 100px;
}
.event-body .event-list {
  margin: 0 0 4rem 0;
}
.event-body .event-list .event-list-title {
  margin: 0 230px 30px 0;
  border-bottom: 1px solid #404040;
}
.event-body .event-list .event-owl.owl-carousel .event-card {
  background-color: #262626;
}

.card-p {
  line-height: 1.5 !important;
}
h5.text-white.font-weight-bold.mb-3 {
  font-size: 1.25rem !important;
}
/* Owl Carousel Navigation */
.event-owl.owl-theme .owl-nav {
  position: absolute;
  top: -80px;
  right: 12%;
}
.event-owl.owl-carousel .owl-nav button.owl-prev,
.event-owl.owl-carousel .owl-nav button.owl-next {
  padding: 0.5rem 1rem !important;
  width: 40px;
  height: 40px;
  border: 1px solid #404040 !important;
  border-radius: 0;
  margin: 0;
  outline: none !important;
}
.event-owl.owl-carousel .owl-nav button.owl-prev:hover,
.event-owl.owl-carousel .owl-nav button.owl-next:hover {
  background: #ef3e27 !important;
  transition: all 0.3s;
}
.owl-theme.event-owl .owl-nav [class*='owl-'] {
  background: none !important;
}
@media only screen and (max-width: 1025px) {
  .event-content {
    margin: 0 0 0 100px;
  }
  .event-body .event-list .event-list-title {
    margin: 0 100px 30px 0;
  }

  .event-header-home {
    /* flex-direction: column; */
    padding-top: 45px;
  }
  .event-form-home {
    position: relative;
    width: 100%;
  }
}

@media only screen and (min-width: 1280px) {
  .home-bg-wrapper {
    height: 500px !important;
  }
}
@media only screen and (max-width: 1280px) {
  .home-bg-wrapper {
    height: 400px !important;
  }
  .feature-event-title{
    font-size: 1.5rem !important;
    font-weight: bold;
    text-transform: uppercase;
  }
}

@media screen and (max-width: 1000px) and (min-width: 700px) {
  .home-bg-wrapper {
    height: 300px !important;
  }
  .feature-event-title{
    font-size: 1rem !important;
    font-weight: bold;
    text-transform: uppercase;
  }
}
@media only screen and (max-width: 1500px) {
  .event-owl.owl-theme .owl-nav {
    right: 20% !important;
  }
}
@media (max-width: 575.98px) {
  .event-content {
    margin: 0 0 0 1.25rem;
  }
  .event-body .event-list .event-list-title {
    margin-right: 1.25rem;
  }
  .event-owl.owl-theme .owl-nav {
    right: 10% !important;
  }
  .event-header-home {
    /* flex-direction: column; */
    padding-top: 40px;
  }
  .event-form-home {
    position: relative;
    width: 100%;
  }
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.content-home-inner img {
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}
body.home-bg {
  background: none;
}
.home-banner,
.discover-event,
.create-event {
  min-height: 600px;
}
.discover-event {
  background-size: 100% 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}
.discover-event a {
  border-radius: 0;
}
.create-event {
  background-size: 100% 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}
.create-event a {
  color: white;
}
.create-event a:hover {
  background-color: #fff;
  color: #ef3e27;
  transition: all 0.3s;
}
.content-slot-1 {
  margin: 50px 0;
  height: 400px;
  position: relative;
}
.content-slot-1 img {
  width: 145%;
}
.content-float-title {
  position: absolute;
  color: #f7f7f7;
  font-size: 150px;
  font-weight: 700;
  letter-spacing: -16px;
  bottom: -98px;
  left: -20px;
}
.content-faq {
  padding: 50px 230px;
  background-color: #f7f7f7;
}
.blog a {
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  color: #404040;
}
.blog a:hover {
  color: #ef3e27;
  transition: all 0.3s;
}
.testimonials .card {
  border-top: 2px solid #ef3e27;
}
.testimonials .card .card-footer {
  padding: 1.4rem 1rem;
}
.testimonials .card .card-footer a {
  color: #404040;
}
.testimonials .card .card-footer a:hover {
  color: #ef3e27 !important;
  transition: all 0.3s;
  text-decoration: none;
}
.testimonials .card .card-footer.dark {
  background-color: #000;
}
.testimonials .card .card-footer img {
  width: 45px;
  height: 45px;
}
.testimonials .card .card-footer .location {
  color: #808080;
}
.owl-theme.event-owl .owl-dots .owl-dot.active span,
.owl-theme.event-owl .owl-dots .owl-dot:hover span {
  background: #ef3e27 !important;
}
.featured {
  padding-bottom: 100px;
  position: relative;
  bottom: 0;
}
.content-contact {
  padding: 0 230px;
  margin-top: 50px;
  background: linear-gradient(90deg, #ffffff 10.4%, #f4f4f4 10.4%) no-repeat;
  background-size: 100% 70%;
}

@media only screen and (max-width: 1025px) {
  .cm-list-col {
    width: 25%;
    
  }
  .content {
    min-height: 100%;
  }
  .content-slot-1 {
    height: 100%;
  }
  .content-slot-1 img {
    width: 100%;
    height: auto;
  }
  .content-faq {
    padding: 50px 100px;
  }
  .content-contact {
    padding: 0 100px;
  }
}

@media (max-width: 575.98px) {
  .content {
    min-height: 100%;
  }
  .content-slot-1 {
    height: 100%;
  }
  .create-event,
  .discover-event {
    padding: 0 1rem;
  }
  .content-slot-1 img {
    width: 100%;
    height: auto;
  }
  .content-faq {
    padding: 1.25rem;
  }
  .content-contact {
    margin-top: 0;
    padding: 0 1.25rem;
  }
  .featured {
    padding-bottom: 50px;
  }
}

.btn-event {
  letter-spacing: 1.2px;
  padding: 0.7rem 1.6rem;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
}
.btn-event:hover {
  color: #404040;
}
.text-brand {
  color: #ef3e27 !important;
}

.btn.btn-brand.btn-event.text-white {
  background: #ef3e27;
}
.btn.btn-brand.btn-event.text-white:hover {
  background: red;
}
.event-owl.owl-carousel .owl-nav button.owl-next,
.event-owl.owl-carousel .owl-nav button.owl-prev,
.event-owl.owl-carousel button.owl-dot {
  background: 0 0;
  color: inherit;
  color: #fff;
  border: none;
  padding: 0 !important;
  font: inherit;
}
/* .content-wrapper-home {
    overflow: hidden;
    min-height: 100vh;
} */
.owl-theme .owl-nav [class*='owl-'] {
  color: #fff;
  font-size: 14px;
  margin: 0px;

  padding: 11px 7px !important;
  background: #d6d6d6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}
.owl-theme .owl-nav [class*='owl-']:hover {
  background: #ef3e27 !important;
  color: #fff;
  text-decoration: none;
}

label.home-form {
  color: #737373;
  font-size: 15px;
}
.home-form .form-control:focus {
  box-shadow: none;
  border-color: #ef3e27;
  background-color: transparent;
}

.home-form .form-control {
  border-radius: 0;
  box-shadow: none;
  padding: 0.53125rem 0.8125rem;
  font-size: 14px !important;
  border-width: 0 0 1px 0;
  background-color: transparent;
}

.btn-brand {
  background-color: #ef3e27;
  color: #fff;
  border-radius: 0px;
}
.menu {
  font-size: 13px !important;
}

.btn-back {
  border-color: #dddddd !important;
}
.modal-dialog.modal-lg {
  max-width: 700px !important;
  width: auto;
  border-radius: none;
  background: #fff;
}

.mr-1px {
  margin-right: 1px !important;
}
.mt-1px {
  margin-top: 0.5px !important;
}
.btn-gray {
  background: #3a3a3a;
  position: relative;
}
.btn-gray:after {
  content: '';
  position: absolute;
  height: 2px;
  width: 16px;
  background: #ef3e27;
  top: 47%;
  right: 0;
}
span.CartItem-count.bg-white {
  color: #ef3e27;
  cursor: pointer;
}
.CartItem-count svg {
  height: auto;
}
@media (max-width: 575.98px) {
  .content-event-cart {
    min-height: 100%;
    margin: 0 20px !important;
  }
}
.invoice.my-ticket .invoice-body table tbody td {
  font-weight: 500;
  color: #88939d;
  font-size: 14px;
  line-height: 3.5;
}

p.mb-3.card-p {
  font-size: 1rem;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.event-card-content{
 max-height: 200px;

}
.home-gray-bg {
  background: #1c1c1c;
}
.card-new-header {
  padding: 1.1rem 1.25rem;
}
.uplodeImgContainer-cutsom {
  width: 200px;
  vertical-align: top;
}
.uplodeImgContainer-custom {
  display: block;
  vertical-align: top;
}
.ql-editor {
  min-height: 70px;
}
.ql-editor strong {
  color: #000;
  font-weight: bolder !important;
}
@media screen and (max-width: 991px) {

  .widget-20 .widget-20-header {
    display: block;
  }
  .home-banner-main .overlay-bg-home {
    /* height: 100%; */
  }
  .home-banner-main .event-header-home {
    min-height: auto;
    padding-bottom: 40px;
  }
  .home-banner-main .event-list-bdy {
    background-size: cover;
  }
  .home-banner-main {
    position: relative;
  }
}
@media screen and (max-width: 479px) {
  .event-owl.owl-carousel .event-card {
    background-color: #262626;
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  .home-page .event-owl.owl-carousel .event-card {
    background-color: #262626;
    margin-left: 0px !important;
    margin-right: 20px !important;
  }
}
@media screen and (max-width: 767px) {
  .popup-youtube i {
    font-size: 55px;
  }
  .content .content-title {
    font-weight: 600;
    font-size: 25px;
    text-transform: uppercase;
    padding: 30px 0;
    margin: 0;
    text-align: center;
    color: #404040;
  }
  h4.mt-2.price {
    font-size: 17px;
  }
  .name-ticket {
    color: #404040;
    font-size: 1.22rem;
  }

  .widget-20 .widget-20-header {
    display: flex;
  }
  h4.text-white.text-uppercase.mb-md-4 {
    font-size: 14px;
  }
  h1.text-uppercase.text-white.font-weight-bold {
    font-size: 1.25rem;
  }
  .font-size-16,
  p.mb-3.card-p {
    font-size: 14px !important;
  }
  .home-gray-bg .event-list {
    margin-top: 40px !important;
  }
  h3.text-white.text-uppercase.font-weight-bold.pb-3.event-list-title {
    font-size: 1.1rem !important;
  }
  h5.text-white.font-weight-bold.mb-3 {
    font-size: 1rem !important;
  }
  .footer-body img,
  a.brand-logo.login-page img {
    max-width: 120px;
  }
  .creat-event-form .dstepper-block {
    margin: 0px !important;
  }
  .bs-stepper-content {
    padding: 12px !important;
  }
  .bs-stepper-content h3 {
    font-size: 1.25rem;
  }
  .dashboard-owl .owl-prev,
  .dashboard-owl .owl-next {
    background: none !important;
  }
  .event-list .event-item .event-schedule .event-day {
    font-size: 40px;
  }
  .event-list .event-item .event-schedule .event-month-time {
    font-size: 15px;
  }
  .order-select .ant-select {
    width: 100% !important;
  }
  .uplodeImgContainer-cutsom {
    width: 120px;
    height: auto;
    margin-bottom: 10px;
    vertical-align: top;
  }
  .uplodeImgContainer-cutsom .uplodeImg {
    height: auto;
  }
}

@media screen and (max-width: 575px) {

  .content-event {
    margin: 0px 10px !important;
    min-height: calc(100vh - 80px - 320px);
  }
  .event-owl.owl-carousel .event-card {
    background-color: #262626;
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  .content,
  .success-content {
    margin: 0px 25px !important;
  }
  /* p.text-white.font-size-16.about-feature-event {
    max-width: 88%;
  } */
  .step.col-lg-3.col-sm-6.mb-3.d-flex.justify-content-center .step-trigger {
    display: inline-block;
  }
  input.form-control.search-box {
    float: none;
    margin-top: 10px;
  }
  .btn-event {
    letter-spacing: 1.2px;
    padding: 0.5rem 1.2rem;
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
  }
  .font-size-12 {
    font-size: 11px !important;
  }
  .font-size-20 {
    font-size: 18px !important;
  }
  .event-price-container {
    height: 52px;
  }
  a.btn.btn-block.btn-lg.btn-brand.pt-3.pb-3.pl-5.pr-5.font-weight-bold {
    font-size: 18px;
  }
  h4.font-weight-bold.text-capitalize {
    font-size: 1.2rem !important;
    color: #404040;
  }
  h3.text-uppercase {
    font-size: 1.5rem !important ;
  }
  .btn,
  .wizard > .actions a,
  .fc button {
    font-size: 0.8125rem;
    line-height: 1rem !important;
    font-weight: 700;
  }
  .btn-lg,
  .btn-group-lg > .btn,
  .wizard > .actions .btn-group-lg > a,
  .fc .btn-group-lg > button {
    padding: -0.125rem 0.3125rem;
  }
  .map-container-div {
    height: 540px !important;
  }
 
  nav.pagination-nav {
    text-align: center;
    width: 265px;
  }
  .tab-content {
    padding: 0.5rem !important;
  }
}
@media screen and (max-width: 1440px) {
  .cm-list-col {
    width: 25%;
 
  }
  .update-feed-image {
    height: 195px;
    position: relative !important;
  }
}

@media screen and (max-width: 1200px) {
  .cm-list-col {
    width: 32%;
    
  }
}
@media screen and (max-width: 700px) {
  .cm-list-col {
    width: 50%;
  
  }
}

